import { Create, CreateProps, SimpleForm, BooleanInput } from 'react-admin';
import {
  Row,
  Col,
  Container,
  ImageInput,
  TextInput,
  NumberInput,
  MoneyInput,
  ManyInput,
  OneInput,
  LoadAutocompleteInput,
  PdfLinkInput,
  Forbidden,
  EditorInput,
} from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';

export const ProductCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps}>
      <SimpleForm>
        <Container max>
          <Row>
            <Col>
              <TextInput source="name" required />
              <OneInput source="divisionId" reference="division" label="Category" required />
              <LoadAutocompleteInput source="form" />
              <TextInput source="composition" multiline />
            </Col>
            <Col>
              <OneInput source="brandId" reference="product-brand" label="Brand" />
              <OneInput source="typeId" reference="product-type" label="Subcategory" />
              <LoadAutocompleteInput source="pack" />
              <TextInput source="description" multiline />
            </Col>
            <Col>
              <MoneyInput source="price" />
              <BooleanInput source="showPrice" />
              <LoadAutocompleteInput source="application" />
              <LoadAutocompleteInput source="dosePerHa" />
              <BooleanInput source="isEnabled" defaultValue={true} />
              <BooleanInput source="isNew" defaultValue={false} label="New product label" />
              <NumberInput max={100} min={0} source="discount" label="Discount %" />
            </Col>
            <Col>
              <ImageInput source="image" />
            </Col>
          </Row>
          <Row>
            <Col>
              <ManyInput source="crops" reference="crop" label="Only for crops" />
              <BooleanInput source="allCrops" defaultValue={true} />
            </Col>
            <Col><PdfLinkInput source="pdfLink" /></Col>
          </Row>
          <Row>
            <Col>
              <EditorInput source="recommendations" label="Recomendations" height={150} />
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};
