import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';

export const theme = merge({}, defaultTheme, {
  palette: {
    primary: {
      main: '#005F39',
    },
    secondary: {
      main: '#002A77',
    },
  },
  typography: {
    fontFamily: ['Outfit', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'html, body': {
          padding: 0,
          scrollbarWidth: 'thin',
        },
      },
    },
  },
  sidebar: {
    width: 250,
    closedWidth: 70,
  },
});
