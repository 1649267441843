import { clsx } from 'clsx';
import { useState } from 'react';
import { showAxiosError } from '@utils';
import { DealerContract } from '@types';
import { useRefresh } from 'react-admin';
import { RcFile } from 'antd/lib/upload';
import { dealerHttp, s3http } from '@network';

import { FileControlled, FileUpload } from '@components';
import cls from './dealer-contracts.module.css';

interface Props {
  className?: string;
  dealerContracts: DealerContract[];
  dealerId: number;
  userId: number;
  editable: boolean;
}

export const DealerContracts = (
  { className, dealerContracts, dealerId, userId, editable }: Props,
) => {
  const [contracts, setContracts] = useState<DealerContract[]>(
    dealerContracts.filter(c => c.dealerId === dealerId),
  );
  const refresh = useRefresh();

  const uploadAction = async (rcFile: RcFile) => {
    const { id: s3id, adminUrl } = await s3http.uploadFile(rcFile as File);
    const s3Ids = contracts.map(contract => contract.fileId);
    const nextContracts = await dealerHttp.saveDealerContracts({
      userId,
      dealerId,
      fileIds: [...s3Ids, s3id],
    });
    setContracts(nextContracts);
    refresh();

    return adminUrl;
  };

  const deleteS3File = async (s3FileId: number) => {
    const s3Ids = contracts.map(contract => contract.fileId).filter(id => id !== s3FileId);
    try {
      const nextContracts = await dealerHttp.saveDealerContracts({
        userId: userId,
        dealerId,
        fileIds: s3Ids,
      });
      setContracts(nextContracts);
      refresh();
    } catch (err: any) {
      showAxiosError(err);
    }
  };

  return (
    <div className={clsx(cls.files, className)}>
      {contracts.sort((a, b) => a.id - b.id).map((file, i) => {
        const ext = file.isPdf ? 'pdf' : 'jpg';
        let downloadFilename = 'Contract';
        if (contracts.length > 1) {
          downloadFilename = `${downloadFilename} #${i + 1}`;
        }
        downloadFilename = `${downloadFilename}.${ext}`;
        const removeFile = () => void deleteS3File(file.fileId);

        return (
          <div className={cls.file} key={file.id}>
            <FileControlled
              adminUrl={file.adminUrl}
              downloadFilename={downloadFilename}
              onDelete={removeFile}
            />
          </div>
        );
      })}

      {editable ? (
        <div className={cls.file}>
          <FileUpload uploadAction={uploadAction} />
        </div>
      ) : null}
    </div>
  );
};
