import { clsx } from 'clsx';
import { Modal } from 'antd';
import { Button } from '@mui/material';
import { useController } from 'react-hook-form';
import React, { useEffect, useState } from 'react';

import { useModalState } from '@hooks';
import { ScoreEdit } from '@pages/risk/score';
import { InitialCard, ScoreWidget } from '@components';
import cls from './Score.module.css';

interface Props {
  defaultValue: number;
  readonly?: boolean;
}

const Footer = ({ defaultValue, readonly }: Props) => {
  const [score, setScore] = useState<number>(defaultValue);
  const { is: isOpened, show, hide } = useModalState();
  const { field: { onChange } } = useController({ name: 'successPercent' });

  const onCancel = () => {
    if (!readonly) {
      setScore(defaultValue);
    }
    hide();
  };

  useEffect(() => {
    if (readonly) return;
    onChange(score);
  }, [score]);

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={show}
        className={cls.btn}
      >Edit</Button>
      <Modal
        width={800}
        title={false}
        open={isOpened}
        footer={false}
        onCancel={onCancel}
        centered
        closable={false}
        maskClosable={false}
        className={clsx(cls.modal, cls.scoreModal)}
      >
        <ScoreEdit
          setScore={setScore}
          defaultValue={defaultValue}
          value={score}
          onCancel={onCancel}
          onHide={hide}
          readonly={readonly}
        />
      </Modal>
    </>
  );
};

export const Score = ({ value, readonly }: {value: number, readonly?: boolean}) => {
  return (
    <InitialCard
      center="Score Approval Parameters"
      mediumPadding
      contentClass={cls.page}
      wrapperClass={cls.pageWrap}
    >
      <div className={cls.wrap}>
        <ScoreWidget
          value={value}
          percents={value}
          title="Risk manager"
          className={cls.score}
          readonly={readonly}
        >
          <Footer defaultValue={value} readonly={readonly} />
        </ScoreWidget>
      </div>
    </InitialCard>
  );
};
