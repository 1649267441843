import { ListProps, TextField } from 'react-admin';

import { TextInput, Grid, GridList, DateField, Forbidden } from '@components';
import { useRbacList } from '@hooks';

export const PermissionList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'categoryId', order: 'ASC' }}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'id name method path module createdAt'.split(' ')}
      >
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="method" />
        <TextField source="path" />
        <TextField source="module" />
        <DateField source="createdAt" showTime />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
];
