import { Admin, DocumentFile } from '@types';

export type SupportPlatform = 'Client' | 'Admin' | 'Partners';

export enum AdminTicketStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'work_in_progress',
  SOLVED = 'solved',
}

export interface SupportDocument {
  file: DocumentFile;
  fileId?: number;
  id?: number;
}

export interface SupportManual {
  id: number;
  name: string;
  platform: string;
  team: string;
  description: string;
  fileIds?: number[];
  documents: SupportDocument[];
}

export interface SupportFaq {
  id: number;
  externalId: number | null;
  question: string;
  answer: string;
  fileIds: number[] | null;
  documents: SupportDocument[];
  nextId: number | null;
  previousId: number | null;
  createdAt: string;
  updatedAt: string;
}

export interface SupportCreateTicket {
  platform: SupportPlatform;
  issue: string;
  fileIds: number[],
  links: string[],
}

export interface SupportTicket {
  id: number;
  platform: SupportPlatform;
  issue: string;
  fileIds: number[];
  links: string[];
  answer: string | null;
  answerDocuments?: SupportDocument[];
  createdAt: Date | null;
  documents?: SupportDocument[];
  status: AdminTicketStatus;
  admin: Admin;
  statusEn: string;
  updatedAt: Date | null;
}
