import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const LightTooltip = styled(
  ({ className, extraWidth, ...props }: TooltipProps & {extraWidth?: boolean}) => (
    <Tooltip placement="bottom-end" {...props} arrow classes={{ popper: className }} />
  ))(({ style, extraWidth }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#E1E5ED',
    fontSize: 18,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#E1E5ED',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '14px 18px',
    fontFamily: 'Outfit, sans-serif',
    backgroundColor: '#E1E5ED',
    color: '#042E6B',
    maxWidth: extraWidth ? 500 : 200,
    lineHeight: 1.2,
    fontSize: 12,
    fontWeight: 400,
    border: '1px solid #dadde9',
    filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))',
  },
}));
