import { User } from '@types';
import { InitialCard } from '@components';
import { UserStatusField } from '@pages/user/UserStatusField';
import { UserContacts } from './user-contacts';
import { UserCompany } from './user-company';
import cls from './user-initial.module.css';

export const UserCard = ({ user }: {user: User}) => (
  <InitialCard link={`/user/${user.id}`} left="Identity" right={user.code}>
    <div className={cls.userAvatar}>
      <div className={cls.avatar}>
        {user.avatar ? (
          <img alt="avatar" src={user.avatar} className={cls.avatarImg} />
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none">
            <path d="M50 0C22.3929 0 0 22.3929 0 50C0 77.6071 22.3929 100 50 100C77.6071 100 100 77.6071 100 50C100 22.3929 77.6071 0 50 0ZM50 25C61.25 25 70.3929 34.1429 70.3929 45.3929C70.3929 56.6429 61.25 65.7857 50 65.7857C38.75 65.7857 29.6071 56.6429 29.6071 45.3929C29.6071 34.1429 38.75 25 50 25ZM50 93.5714C39.8571 93.5714 30.5 90.0714 23.0714 84.2143C25.7143 80.4286 30.0714 78.1429 34.7143 78.1429H65.2857C69.9286 78.1429 74.2857 80.4286 76.9286 84.2143C69.5 90.0714 60.1429 93.5714 50 93.5714Z"
              fill="#042E6B" />
          </svg>
        )}
      </div>
    </div>
    <div className={cls.userName}>
      {user.fullName}
    </div>
    <div className={cls.userStatus}>
      <UserStatusField source="status" autoWidth />
    </div>
    <UserContacts user={user} />
    <UserCompany user={user} />
  </InitialCard>
);
