import { useRecordContext } from 'react-admin';
import { MoneyField } from '@components';
import { PricesUpdateModal } from './PricesUpdateModal';

export const PricesField = (props: any) => {
  const record = useRecordContext(props);
  if (!record) {
    return null;
  }

  return (
    <div onClick={e => void e.stopPropagation()}>
      <div>
        <MoneyField source="price" />
      </div>
      <PricesUpdateModal
        productId={+record.id}
        price={record?.price}
      />
    </div>
  );
};

PricesField.defaultProps = { label: 'Price' };
