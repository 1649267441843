import { makeStyles } from '@mui/styles';

import { Credit } from '@types';
import { InitialCard, InitialRow } from '@components';

export const CreditUser = ({ credit }: {credit: Credit}) => {
  const user = credit.user;
  const locations = user.location;
  const [firstLocation] = locations || [];
  const cls = useStyles();

  return (
    <InitialCard left="User" autoHeight extraPadding>
      <div className={cls.box}>
        <InitialRow title="Accredited:">{credit.accreditedName}</InitialRow>
        <InitialRow title="Phone:">{user.phone}</InitialRow>
        <InitialRow title="State:">{user.state?.name || '–'}</InitialRow>
        <InitialRow title="City:">{firstLocation?.city || '–'}</InitialRow>
        <InitialRow title="CIEC:">{user.ciec || '–'}</InitialRow>
        <InitialRow title="Responsible Manager:">{user.responsibleAdmin?.name || '–'}</InitialRow>
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    marginTop: 15,
    fontFamily: 'Outfit, sans-serif',
    color: '#272727',
  },
});
