import { Create, CreateProps, SimpleForm } from 'react-admin';

import { useCreateProps, useRbacCreate } from '@hooks';
import { Forbidden, InitialPage } from '@components';
import { FinanceCreditForm, validate } from './FinanceCreditForm';

export const FinanceCreditCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps} title="Create Credit">
      <InitialPage
        title="Credit"
        backTo="/finance-credit"
        backText="Back to other Credits"
      >
        <SimpleForm validate={validate}>
          <FinanceCreditForm />
        </SimpleForm>
      </InitialPage>
    </Create>
  );
};
