import { clsx } from 'clsx';
import { Link } from 'react-admin';
import { CheckCircleFilled, CloseCircleFilled, RightCircleFilled } from '@ant-design/icons';

import { User } from '@types';
import { dateFormatter } from '@utils';
import { InitialCard } from '@components';
import { ProfileLink } from '@pages/user/ProfileLink';
import cls from './UserHubspot.module.css';

export const UserHubspot = ({ user }: { user: User }) => {
  const hasError = (req?: boolean): boolean => req
    ? !user.hubspotRequest
    : !!(!user.hubspotId || user.hubspotError || !user.hubspotResponse);

  return (
    <InitialCard
      left={
        <Link to={`/user/${user.id}?hubspot`} className={cls.cardTitle}>
          <span>Hubspot</span>
          <RightCircleFilled />
        </Link>
      }
      contentClass={clsx(cls.content)}
      wrapperClass={clsx(cls.wrap)}
      extraPadding
    >
      <div className={cls.top}>
        <div className={cls.title}>Profile</div>
        <div className={cls.link}>
          <ProfileLink
            url={user.hubspotLink}
            text={user.hubspotLink ? `Link to Profile # ${user.hubspotId}` : '–'}
            className={cls.link}
          />
        </div>
        <div className={cls.info}>
          <div className={cls.infoRow}>
            <span className={cls.infoTitle}>Hubspot response</span>
            {hasError()
              ? <CloseCircleFilled className={clsx(cls.infoIcon, cls.error)}/>
              : <CheckCircleFilled className={cls.infoIcon}/>}
          </div>
          <div className={cls.infoRow}>
            <span className={cls.infoTitle}>Hubspot request</span>
            {hasError(true)
              ? <CloseCircleFilled className={clsx(cls.infoIcon, cls.error)}/>
              : <CheckCircleFilled className={cls.infoIcon}/>}
          </div>
        </div>
      </div>

      <div className={cls.footer}>
        <div>Hubspot exported at:</div>
        <div className={cls.footerDate}>
          {user.hubspotAt ? dateFormatter.toDateTime(user.hubspotAt, true) : '–'}
        </div>
      </div>
    </InitialCard>
  );
};
