import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { TextFieldProps, useRecordContext } from 'react-admin';

import { AntdDynamicIcon } from '../AntdDynamicIcon';

const useStyles = makeStyles({
  icon: {
    fontSize: 34,
    color: '#333',
  },
});

export const AntdIconField: FC<TextFieldProps> = ({ source }) => {
  const cls = useStyles();
  const record = useRecordContext();
  const value = source && record[source];

  return <AntdDynamicIcon type={value} className={cls.icon} />;
};
