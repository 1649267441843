import { clsx } from 'clsx';
import { Popconfirm } from 'antd';
import { PropsWithChildren } from 'react';

import cls from './confirm-delete.module.css';

interface Props {
  onCancel?: (e: any) => void;
  className?: string;
  overlayClassName?: string;
  disabled?: boolean;
  cb: (e: any) => void;
  title?: string;
  placement?: 'topLeft' | 'top' | 'topRight' | 'leftTop' | 'left' | 'leftBottom' | 'rightTop' | 'right' | 'rightBottom'
    | 'bottomLeft' | 'bottom' | 'bottomRight';
}

export const ConfirmDelete = (
  { cb,
    onCancel,
    title = 'Are you sure?',
    className,
    overlayClassName,
    placement,
    children,
    disabled,
  }: PropsWithChildren<Props>,
) => (
  <Popconfirm
    title={title}
    onConfirm={cb}
    onCancel={onCancel}
    placement={placement || 'top'}
    icon={null}
    disabled={disabled}
    cancelButtonProps={{
      size: 'small',
      type: 'ghost',
      className: cls.cancelBtn,
    }}
    cancelText="No"
    okButtonProps={{
      size: 'small',
      type: 'ghost',
      className: cls.okBtn,
    }}
    okText="Yes"
    className={clsx(cls.confirm, className, placement ? cls[placement] : '')}
    overlayClassName={clsx(cls.overlay, overlayClassName, placement ? cls[placement] : '')}
  >
    {children}
  </Popconfirm>
);
