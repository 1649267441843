import { useEffect } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';

import { moneyFormatter } from '@utils';
import { getQuarter } from '@utils/date-convert';
import {
  Container,
  TextInput,
  InitialCard,
  InitialRow,
  SelectInput,
  AntdDateInput,
} from '@components';
import { useFinanceActivationSettings } from './use-finance-activation-settings';

export const validate = (values: any) => {
  const errors: any = {};
  ['activation', 'originOptionId', 'activationStatusId', 'launchDate']
    .forEach((key: string) => {
      if (!values[key]) {
        errors[key] = 'This field is required';
      } else if (values[key]?.length > 100) {
        errors[key] = 'Max length is 30 characters';
      }
    });

  return errors;
};

export const FinanceActivationForm = () => {
  const cls = useStyles();
  const settings = useFinanceActivationSettings();

  const launchDate = useWatch({ name: 'launchDate' });
  const { field: { onChange: setChecked } } = useController({ name: 'quarter' });
  const budget = useWatch({ name: 'budget' });
  const mql = useWatch({ name: 'mql' });
  const cpl = useWatch({ name: 'cpl' });

  const labelSize = 7;

  useEffect(() => {
    setChecked(getQuarter(launchDate));
  }, [launchDate]);

  return (
    <Container max>
      <InitialCard left="Complete information" extraPadding>
        <Row gutter={[20, 20]}>
          <Col xs={12} sm={11}>
            <InitialRow title="Activation*" fontLg labelSize={labelSize}>
              <TextInput source="activation" label="" inputProps={{ maxLength: 60 }} />
            </InitialRow>
            <InitialRow title="Origin*" fontLg labelSize={labelSize}>
              <SelectInput
                label=""
                source="originOptionId"
                choices={(settings?.originOptions || []).map(opt => ({
                  id: opt.id,
                  name: opt.name,
                }))}
                required
              />
            </InitialRow>
            <InitialRow title="Status*" fontLg labelSize={labelSize}>
              <SelectInput
                label=""
                source="activationStatusId"
                choices={(settings?.activationStatuses || []).map(opt => ({
                  id: opt.id,
                  name: opt.name,
                }))}
              />
            </InitialRow>
            <InitialRow title="Launch Date*" fontLg labelSize={labelSize}>
              <AntdDateInput source="launchDate" allowClear={false} />
            </InitialRow>
            <InitialRow title="Close Date" fontLg labelSize={labelSize}>
              <AntdDateInput source="closeDate" />
            </InitialRow>
            <InitialRow title="Quarter" fontLg labelSize={labelSize}>
              <TextInput source="quarter" label="" name="quarter"
                onBeforeInputCapture={e => e.preventDefault()} />
            </InitialRow>
            <InitialRow title="Description" fontLg labelSize={labelSize}>
              <TextInput
                className={cls.textarea}
                source="description"
                placeholder="Describe the details"
                multiline
                maxRows={3}
                rows={3}
                label=""
                inputProps={{ maxLength: 300 }}
              />
            </InitialRow>
          </Col>
          <Col xs={12} sm={12} offset={1}>
            <div className={cls.numbers}>
              <div className={cls.numbersRow}>
                <div className={cls.budget}>
                  <div className={cls.budgetTitle}>Budget</div>
                  <div className={cls.budgetValue}>{moneyFormatter.format(budget || 0)}</div>
                </div>
              </div>
              <div className={cls.numbersRow}>
                <div className={cls.circle}>
                  <div className={cls.circleTitle}>MQL’s</div>
                  <div className={cls.circleValue}>{mql || 0}</div>
                </div>
                <div className={cls.circle}>
                  <div className={cls.circleTitle}>CPL</div>
                  <div className={cls.circleValue}>{moneyFormatter.format(cpl || 0)}</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </InitialCard>
    </Container>
  );
};

const useStyles = makeStyles({
  textarea: {
    padding: '5px 10px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#E1E5ED',
    fontSize: 13,
  },
  numbers: {
    marginTop: 20,
    padding: 40,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: 50,
  },
  budget: {
    width: '100%',
    border: '5px solid #042E6B',
    borderRadius: 5,
    position: 'relative',
    minHeight: 91,
    maxWidth: 418,
  },
  budgetTitle: {
    background: '#fff',
    fontSize: 18,
    position: 'absolute',
    top: -10,
    left: -5,
    paddingRight: 9,
    paddingBottom: 2,
  },
  budgetValue: {
    width: '100%',
    lineHeight: '91px',
    fontSize: 40,
    textAlign: 'center',
  },
  numbersRow: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    gap: 69,
  },
  circle: {
    width: 134,
    height: 134,
    border: '5px solid #042E6B',
    borderRadius: '50%',
    position: 'relative',
  },
  circleTitle: {
    background: '#fff',
    fontSize: 18,
    position: 'absolute',
    top: -10,
    left: 'calc(50% - 36px)',
    paddingLeft: 12,
    paddingRight: 12,
    width: 72,
    textAlign: 'center',
  },
  circleValue: {
    fontSize: 20,
    lineHeight: '128px',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: 12,
    paddingRight: 12,
  },
});
