import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { Select } from 'antd';
import { makeStyles } from '@mui/styles';

import { http } from '@network';
import { ErrorMessage } from '@components/ErrorMessage';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface ListOption {
  value: number;
  label: string;
  disabled?: boolean;
}

interface Props {
  source: string;
  reference: string;
  optionKey?: string;
  optionLabel?: string;
  placeholder?: string;
  disabledProp?: string;
}

export const SelectAutocompleteInput = ({
  source, reference, optionKey, optionLabel, placeholder, disabledProp,
}: Props) => {
  const cls = useStyles();
  const {
    fieldState: { error },
    field: { value, onChange },
  } = useController({ name: source });
  const [options, setOptions] = useState<ListOption[]>([]);

  useEffect(() => {
    (async () => {
      const response = await http.get<any>(reference || source, {
        params: { _start: 0, _end: 9999999 },
      });
      const nextOptions: ListOption[] = [];
      if (response.data && Array.isArray(response.data)) {
        (response.data ?? []).forEach(model => nextOptions.push({
          value: +`${model[optionKey ?? 'id']}`,
          label: model[optionLabel ?? 'nameMx'] || model['name']
            || model['title'] || model['activation'],
          disabled: disabledProp ? !!model[disabledProp] : undefined,
        }));
      }
      setOptions(nextOptions);
    })();
  }, []);

  const errorMessage = (error as any)?.message?.message;

  return (
    <>
      <Select
        showSearch
        className={cls.autocompleteField}
        placeholder={placeholder}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
        options={options}
        value={value}
        onChange={onChange}
        suffixIcon={<ArrowDropDownIcon className={cls.suffixIcon} />}
      />
      {errorMessage && <ErrorMessage text={errorMessage} />}
    </>
  );
};

const useStyles = makeStyles({
  autocompleteField: {
    width: '100%',
    '& .ant-select-selector': {
      paddingLeft: '0px !important',
      fontSize: 18,
      borderColor: 'transparent',
      boxShadow: 'none !important',
      padding: 0,
      border: 'none !important',
      borderBottom: '1px solid rgba(0, 42, 119, 0.10) !important',
      outline: 'none',
    },
    '& .ant-select-selection-search': {
      left: '0 !important',
    },
  },
  suffixIcon: {
    display: 'inline-block',
    position: 'absolute',
    right: -22,
    top: -6,
    color: '#777',
    marginRight: 5,
  },
});
