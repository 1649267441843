import React from 'react';
import { EditProps, Edit, BooleanInput, TabbedForm, FormTab } from 'react-admin';
import {
  Row,
  Col,
  Container,
  TextInput,
  ImageInput,
  NumberInput,
  ManyInput,
  OneInput,
  LoadAutocompleteInput,
  PdfLinkInput,
  Forbidden,
  EditorInput,
  JsonField,
  DateTimeField,
  ExternalUrlField,
} from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Product: {record.nameMx}</span>) : null;

export const ProductEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <TabbedForm toolbar={rbacEdit ? undefined : false}>
        <FormTab label="Info" key="info">
          <Container max>
            <Row>
              <Col>
                <TextInput source="name" required />
                <OneInput source="divisionId" reference="division" label="Category" required />
                <LoadAutocompleteInput source="form" />
                <TextInput source="composition" multiline />
              </Col>
              <Col>
                <OneInput source="brandId" reference="product-brand" label="Brand" />
                <OneInput source="typeId" reference="product-type" label="Subcategory" />
                <LoadAutocompleteInput source="pack" />
                <TextInput source="description" multiline />
              </Col>
              <Col>
                <LoadAutocompleteInput source="application" />
                <LoadAutocompleteInput source="dosePerHa" />
                <NumberInput source="price" />
                <BooleanInput source="isEnabled" />
                <BooleanInput source="showPrice" />
                <BooleanInput source="isNew" defaultValue={false} label="New product label" />
                <NumberInput max={100} min={0} source="discount" label="Discount %" />
              </Col>
              <Col>
                <BooleanInput source="isExternal" label="For External Shop" />
                <TextInput source="externalShop" label="External Shop name" />
                <ImageInput source="image" />
              </Col>
            </Row>
            <Row>
              <Col>
                <ManyInput source="crops" reference="crop" label="Only for crops" />
                <BooleanInput source="allCrops" />
              </Col>
              <Col><PdfLinkInput source="pdfLink" /></Col>
            </Row>
            <Row>
              <Col>
                <TextInput source="keywords" />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorInput source="recommendations" label="Recommendations" height={150} />
              </Col>
            </Row>
          </Container>
        </FormTab>

        <FormTab label="Airtable Sync" key="airtableSync">
          <Container max>
            <Row>
              <Col>
                <BooleanInput source="airtableNeedExport" label="Airtable Need Export" />
              </Col>
              <Col>
                <TextInput source="airtableInternoId" label="Airtable INTERNO ID" disabled />
              </Col>
              <Col flex={2}>
                <ExternalUrlField source="airtableProductLink" label="Airtable Link" />
              </Col>
            </Row>
            <Row>
              <Col flex={1}>
                <DateTimeField source="airtableAt" label="Export At" disabled />
              </Col>
              <Col flex={1}>
                <JsonField source="airtableError" label="Export Error" fullWidth />
              </Col>
            </Row>
            <Row>
              <Col flex={1}>
                <JsonField source="airtableRequest" label="Export Request" fullWidth />
              </Col>
              <Col flex={1}>
                <JsonField source="airtableResponse" label="Export Response" fullWidth />
              </Col>
            </Row>
          </Container>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
