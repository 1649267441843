import { clsx } from 'clsx';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { ReplyQuestion } from '@types';
import cls from './DropdownTable.module.css';

interface Props {
  data: ReplyQuestion[];
  onChange: (value: ReplyQuestion[]) => void;
  isFixedReplies?: boolean;
}

export const DropdownTable = ({ data, onChange, isFixedReplies }: Props) => {
  const handleChange = (row: ReplyQuestion, answerId: number) => {
    onChange(data.map(i => (i.questionId === row.questionId) ? { ...i, answerId } : i));
  };

  const getAnswer = (row: ReplyQuestion) => row.answers
    .find((answer) => answer.id === row.answerId)?.title || '';

  if (!data || !data.length) {
    return null;
  }

  return (
    <div className={cls.block}>
      {data.map((r, idx) => (
        <div className={cls.row} key={idx}>
          <div className={clsx(cls.col, cls.colTitle)}>{r.title}</div>
          <div className={clsx(cls.col, cls.colMenu)}>
            {isFixedReplies ? (
              <span className={cls.fixed}>{r.value}</span>
            ) : (
              <Dropdown
                className={cls.dropdown}
                menu={{
                  items: r.answers.map(o => ({
                    label: <div
                      className={cls.selected}
                      onClick={() => handleChange(r, o.id)}
                    >{o.title}</div>,
                    key: o.title,
                  })), selectable: true,
                }}
                overlayClassName={cls.dropdownOverlay}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className={cls.selectButton}
                  onClick={e => e.preventDefault()}
                >
                  <div className={clsx(cls.selectedText, !getAnswer(r) && cls.empty)}>
                    {getAnswer(r) || '---'}
                  </div>
                  <div className={cls.selectedIcon}><DownOutlined className={cls.arrowIcon} /></div>
                </a>
              </Dropdown>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
