import { clsx } from 'clsx';
import { useState } from 'react';
import { Dropdown, InputNumber, type MenuProps } from 'antd';

import { moneyFormatter } from '@utils';
import { IFinancialTableRow, IFinancialTable, FinancialResType, FinancialResult } from '@types';
import cls from './FinancialTable.module.css';

interface Props {
  data: IFinancialTable;
  calcData: IFinancialTable;
  onChange: any;
}

interface OptionItemProps {
  id: FinancialResType;
  label: string;
  bg: 'red' | 'blue';
  onChange: (key: FinancialResType) => void;
}

const OptionItem = ({ id, label, bg, onChange }: OptionItemProps): JSX.Element => (
  <div className={clsx(cls.selected, cls[bg])} onClick={() => onChange(id)}>{label}</div>
);

export const FinancialTable = ({ data, calcData, onChange }: Props) => {
  const [isFieldActive, setFieldActive] = useState(false);
  const calc = calcData.values.find(f => f.key === 'operationCostsCalculated');

  const setSelected = (id: FinancialResType) => {
    const { result } = data;
    result.selected = id;
    onChange({ ...data, result });
  };

  const items: MenuProps['items'] = data.result.options.map(o => ({
    label: <OptionItem id={o.key}
      label={o.label}
      bg={o.key === FinancialResult.VERIFIABLE ? 'blue' : 'red'}
      onChange={setSelected} />,
    key: o.label,
  }));

  const getCalcValue = (id: string, getValue = true): number => {
    const field = calcData.values.find((f: IFinancialTableRow) => f.key === id);

    return getValue ? field?.value || 0 : field?.percent || 0;
  };

  const checkInputCol = (key: string): boolean => ['area', 'tons', 'price'].includes(key);

  const onFieldChange = (val: number | null, key: string) => {
    if (val === null) return;
    const values = data.values
      .map(f => (f.key === key) ? { ...f, value: val } : f);
    const newValue = { ...data, values };
    onChange(newValue);
  };

  const getValue = (row: IFinancialTableRow): JSX.Element => {
    if (row.key === 'totalTons') {
      return <span className={cls.text}>
        {getCalcValue(row.key) !== undefined ? getCalcValue(row.key) : row.value}
      </span>;
    }
    if (row.key === 'operationCosts') {
      return (
        <span className={cls.hybridField} onClick={() => setFieldActive(true)}>
          <span className={clsx(cls.text, !isFieldActive ? cls.show : cls.hide)}>
            {moneyFormatter.format(calc?.value || 0)}
          </span>
          <span className={clsx(cls.inputWrap, isFieldActive ? cls.show : cls.hide)}>
            <InputNumber
              min={0}
              max={1000000000}
              formatter={(value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value ? +value.replace(/\$\s?|(,*)/g, '') : 0}
              defaultValue={row.value}
              bordered={false}
              onChange={e => onFieldChange(e, row.key)}
              className={cls.input}
              onBlur={() => setFieldActive(false)}
            />
          </span>
        </span>
      );
    }
    if (!checkInputCol(row.key)) {
      return <span className={cls.text}>
        {moneyFormatter.format(
          getCalcValue(row.key) !== undefined ? getCalcValue(row.key) : row.value,
        )}
      </span>;
    }
    return (
      <span className={cls.inputWrap}>
        <InputNumber
          min={0}
          max={1000000000}
          formatter={row.key === 'price'
            ? (value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : undefined}
          parser={row.key === 'price'
            ? (value) => value ? +value.replace(/\$\s?|(,*)/g, '') : 0
            : undefined}
          defaultValue={row.value}
          bordered={false}
          onChange={e => onFieldChange(e, row.key)}
          className={cls.input}
        />
      </span>
    );
  };

  const getResultLabel = (): string => {
    const currentOption = data.result.options
      .find(o => o.key === data.result.selected);
    return !currentOption ? '' : currentOption.label;
  };

  if (!data || !data.values || !data.values.length) {
    return null;
  }

  return (
    <div className={cls.block}>
      <div className={cls.table}>
        <div className={cls.header}>{data.title}</div>
        <div className={cls.body}>
          {data.values.map((v: IFinancialTableRow) => v.key === 'operationCostsCalculated' ? null : (
            <div className={cls.row} key={v.key}>
              <div className={clsx(cls.col, cls.colLabel)}>{v.title}</div>
              <div className={clsx(
                cls.col,
                cls.colValue,
                checkInputCol(v.key) || v.key === 'operationCosts' ? cls.colInput : '',
              )}>{getValue(v)}</div>
              {v.percent !== undefined ? (
                <div className={clsx(cls.col, cls.colPercent, cls.centered)}>
                  {v.key === 'operationCosts'
                    ? getCalcValue('operationCostsCalculated', false)
                    : getCalcValue(v.key, false)}%
                </div>
              ) : null}
            </div>
          ))}
        </div>
        <div className={cls.footer}>
          <div className={cls.footerRow}>
            <div className={clsx(cls.col, cls.colLabel)}>Result</div>
            <div className={clsx(cls.col, cls.colValue, cls.centered)}>
              <Dropdown
                className={cls.dropdown}
                menu={{ items, selectable: true }}
                overlayClassName={cls.dropdownOverlay}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className={clsx(
                    cls.selectButton,
                    cls[data.result.selected === FinancialResult.VERIFIABLE ? 'blue' : 'red'],
                  )}
                  onClick={e => e.preventDefault()}>
                  <div className={clsx(
                    cls.selectedText,
                    cls[data.result.selected === FinancialResult.VERIFIABLE ? 'blue' : 'red'],
                  )}>{getResultLabel()}</div>
                </a>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
