import { useRecordContext } from 'react-admin';
import Chip from '@mui/material/Chip';
import blue from '@mui/material/colors/blue';
import blueGrey from '@mui/material/colors/blueGrey';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import purple from '@mui/material/colors/purple';

import { OrderStatus } from '@types';

interface OrderStatusOption {
  id: OrderStatus,
  name: string,
  color: any,
}

export const orderStatusOptions: OrderStatusOption[] = [
  { id: 'waiting_for_payment', name: 'Quotation in process', color: blue },
  { id: 'request_price', name: 'Quotation is ready', color: blueGrey },
  { id: 'confirmed', name: 'Confirmed', color: purple },
  { id: 'paid', name: 'Paid', color: green },
  { id: 'cancelled', name: 'Cancelled', color: red },
];

export const OrderStatusField = ({ source }: {source: string}) => {
  const record = useRecordContext();
  const status = record[source];
  const statusName = orderStatusOptions.find(o => o.id === status)?.name;
  const color: any = orderStatusOptions.find(o => o.id === status)?.color;

  if (!status || !color) {
    return null;
  }

  return (
    <Chip
      label={statusName || status}
      size="small"
      style={{ backgroundColor: color && color[500] ? color[500] : undefined, color: 'white' }}
    />
  );
};
