import { useEffect, useState } from 'react';
import { useResourceContext, SelectArrayInputProps } from 'react-admin';

import { http } from '@network';
import { SelectArrayInput } from '../inputs';

interface Props {
  entity?: string;
}

export const OptionsField = (props: SelectArrayInputProps & Props) => {
  const resource = useResourceContext();
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const url = `${props.entity || resource}/options/${props.source}`;
      const response = await http.get<string[]>(url);
      setOptions(response.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const choices = options.map(o => ({
    id: o,
    name: o,
  }));

  return (
    <SelectArrayInput
      source={props.source}
      choices={choices}
      {...props}
      style={{ width: 180 }}
      className="MS-field"
    />
  );
};
