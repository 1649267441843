import { useNotify, useRedirect, useResourceContext } from 'react-admin';

interface UseCreatedParams {
  label?: string;
}

export const useCreated = (params: UseCreatedParams = {}) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const resource = useResourceContext();
  const pascalCase = resource.slice(0, 1).toUpperCase() + resource.slice(1);

  const onCreated = (data: Partial<{id: number}>) => {
    notify(`${params.label || pascalCase} is created`);
    redirect(`/${resource}/${data.id}`);
  };

  return onCreated;
};
