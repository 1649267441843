import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOne, useNotify, useRedirect } from 'react-admin';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

import { axiosErrorToString } from '@utils';
import { blogHttp } from '@network/blog-http';
import { useRbacEdit } from '@hooks';
import { BlogForm } from '@pages/blog/BlogForm';
import { BlogFormValues } from '@pages/blog/BlogCreate';
import { Forbidden, InitialPage } from '@components';

export const BlogEdit = () => {
  const rbacEdit = useRbacEdit();
  const notify = useNotify();
  const redirect = useRedirect();

  const { id } = useParams();

  const { data: record } = useGetOne('blog', { id });

  const [formValues, setFormValues] = useState<BlogFormValues | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const submit = async () => {
    if (formValues) {
      try {
        const result = await blogHttp.update({ id: record.id, ...formValues });

        if (result) {
          notify('Article has been updated');
        }
      } catch (e) {
        notify(axiosErrorToString(e));
      }
    }
  };

  const removeHandler = async () => {
    try {
      const result = await blogHttp.remove(record.id);

      if (result) {
        notify('Article has been deleted');
        redirect('/blog');
      }
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  useEffect(() => {
    if (record) {
      setFormValues(record);
    }
  }, [record]);

  if (!rbacEdit) {
    return <Forbidden />;
  }

  const backTo = '/blog';

  return (
    <InitialPage title="Blog post edit" backTo={backTo} backText="Back to all pages">
      <BlogForm
        type="edit"
        formValues={formValues}
        setFormValues={setFormValues}
        submit={submit}
        setIsDialogOpen={setIsDialogOpen}
        link={record?.link}
        image={record?.image}
      />

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>
            No
          </Button>
          <Button onClick={removeHandler} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </InitialPage>
  );
};
