import { Switch } from 'antd';
import { useRefresh } from 'react-admin';
import { useState } from 'react';

import { User } from '@types';
import { userHttp } from '@network';
import cls from './user-initial.module.css';

export const UserCompany = ({ user }: {user: User}) => {
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();

  const switchIsCompany = async (isCompany: boolean) => {
    try {
      setLoading(true);
      await userHttp.updateIsCompany(user.id, isCompany);
      refresh();
    } finally {
      setLoading(false);
    }
  };

  const switchClick = (_: boolean, e: any) => {
    if (loading) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const stopPropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div className={cls.company}>
      <div className={cls.companyText}>
        Not a company
      </div>
      <div onClick={stopPropagation}>
        <Switch
          defaultChecked={user.isCompany}
          onClick={switchClick}
          onChange={switchIsCompany}
          className={user.isCompany ? cls.companySwitchChecked : cls.companySwitch}
        />
      </div>
      <div className={cls.companyText}>
        Is company
      </div>
    </div>
  );
};
