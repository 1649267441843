import React from 'react';
import { Edit, EditProps, SimpleForm, useRefresh } from 'react-admin';

import { useEditProps, useRbacEdit, useRecord } from '@hooks';
import { Forbidden, InitialPage } from '@components';
import { FinanceActivationForm, validate } from './FinanceActivationForm';
import { FinanceActivationTables } from './FinanceActivationTables';

export const FinanceActivationEdit = (props: EditProps) => {
  const editProps = useEditProps('The changes have been saved');
  const activation = useRecord(props);
  const rbacEdit = useRbacEdit();
  const refresh = useRefresh();

  if (!rbacEdit) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} {...editProps} title="Update Activation">
      <InitialPage
        title="Activation"
        backTo="/finance-activation"
        backText="Back to all Activations"
      >
        <SimpleForm validate={validate}>
          <FinanceActivationForm />
        </SimpleForm>

        {activation && <FinanceActivationTables activation={activation} refresh={refresh} />}
      </InitialPage>
    </Edit>
  );
};
