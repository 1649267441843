import { EditProps, Edit, SimpleForm, useRecordContext } from 'react-admin';

import { Row, Col, Container, TextInput, DateTimeInput, Forbidden } from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';

const Title = () => {
  const record = useRecordContext();
  return (<span>Subcategory # {record?.name}</span>);
};

export const ProductTypeEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <SimpleForm toolbar={rbacEdit ? undefined : false}>
        <Container max>
          <Row>
            <Col><TextInput source="name" label="Subcategory" required /></Col>
            <Col><TextInput source="synonym" label="URL" disabled /></Col>
            <Col><DateTimeInput source="updatedAt" disabled /></Col>
            <Col><TextInput source="id" label="ID" /></Col>
          </Row>
        </Container>
      </SimpleForm>
    </Edit>
  );
};
