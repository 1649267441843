import React, { useRef } from 'react';
import { DatePicker, DatePickerProps } from 'antd';
import moment from 'moment-timezone';
import IMask from 'imask';
import { makeStyles } from '@mui/styles';
import { CalendarFilled } from '@ant-design/icons';

const DATE_FORMAT = 'DD.MM.YYYY';
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: new Date().getFullYear() },
  },
  format: (date: Date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});

interface ExtraProps {
  onUpdate: (nextDate: string | null) => void;
  valueStr: string | null;
  minDate?: string | null;
  maxDate?: string | null;
  width?: number;
}

export const AntdDateControlledInput = (props: DatePickerProps & ExtraProps) => {
  const cls = useStyles();
  const {
    suffixIcon, width, placeholder, valueStr, onChange, onUpdate,
    minDate, maxDate,
  } = props;

  return (
    <div className={'ant-form-item-control-input--without-label'}>
      <DatePicker
        className={cls.picker}
        bordered={false}
        value={valueStr ? moment(valueStr) : null}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          const input = event.target as HTMLInputElement;
          input.value = MASKED.resolve(input.value);
          setTimeout(() => {
            if (!input.value) {
              onChange && onChange(null, DATE_FORMAT);
              onUpdate(null);
              return;
            }
            const momentDate = moment(input.value, DATE_FORMAT, true);
            if (momentDate.isValid()) {
              onChange && onChange(momentDate, DATE_FORMAT);
              onUpdate && onUpdate(momentDate.format('YYYY-MM-DD'));
            }
          }, 200);
        }}
        onChange={selectedValue => {
          if (selectedValue === null) {
            onChange && onChange(null, DATE_FORMAT);
            onUpdate(null);
            return;
          }
          const momentDate = moment(selectedValue, DATE_FORMAT, true);
          if (momentDate.isValid()) {
            onChange && onChange(momentDate, DATE_FORMAT);
            onUpdate && onUpdate(momentDate.format('YYYY-MM-DD'));
          }
        }}
        picker="date"
        format={DATE_FORMAT}
        disabledDate={minDate && maxDate ? (current) => {
          const minMoment = moment(minDate);
          const maxMoment = moment(maxDate);
          return !(current >= minMoment && current <= maxMoment);
        } : undefined}
        suffixIcon={suffixIcon ? suffixIcon : (
          <CalendarFilled className={cls.calendarIcon} />
        )}
        placeholder={placeholder || ''}
        style={{ width }}
        {...props}
      />
    </div>
  );
};

const useStyles = makeStyles({
  picker: {
    '& .ant-picker-input': {
      borderBottom: '1px solid rgba(0, 42, 119, 0.10) !important',
    },
  },
  calendarIcon: {
    color: '#777',
  },
});
