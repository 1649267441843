import { Button } from '@mui/material';
import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import { Row, Col, Badge, Modal } from 'antd';

import { rbac } from '@utils';
import { Credit } from '@types';
import { useModalState } from '@hooks';
import { CreditCard } from './CreditCard';
import { CreditOrders } from './CreditOrders';
import { useSettingsContext } from '@providers';
import { CreditContracts } from './CreditContracts';
import { CreditGuarantors } from './CreditGuarantors';
import { ExportRisk } from '@pages/credit-risk/export';
import { CreditTimeTracker } from './CreditTimeTracker';
import { CreditCallsWidget } from '@pages/credit/CreditEdit/CreditCallsWidget';
import { InitialCard, InitialPage, ScoreWidget, CreditChat } from '@components';
import { ExportCreditTerms } from '@pages/credit-risk/export/ExportCreditTerms';
import { CreditInvoiceWidget } from '@pages/credit/CreditInitial/CreditInvoiceWidget';
import { CreditContractsDocuments } from '@pages/credit/CreditInitial/CreditContractsDocuments';
import cls from './credit-initial.module.css';

interface ScoreFooterProps {
  credit: Credit;
  setOpened: (nextOpened: boolean) => void;
}

const ScoreFooter = ({ credit, setOpened }: ScoreFooterProps) => {
  const { unreaded } = useSettingsContext();
  const { is: isChatOpened, show: showChat, hide: hideChat } = useModalState();
  const unreadedMessages = credit.chat && unreaded && unreaded.chats[credit.chat.id]?.unreaded;

  return (
    <>
      <div className={cls.scoreFooter}>
        <Button
          color="secondary"
          variant="contained"
          className={cls.scoreBtn}
          onClick={() => setOpened(true)}
          disabled={!credit.risk}
        >
          Credit Terms
        </Button>
        {credit.chat && unreadedMessages && unreadedMessages > 0 ? (
          <Badge count={unreadedMessages}>
            <Button color="secondary" variant="contained"
              className={cls.scoreBtn} onClick={showChat}>
              Chat
            </Button>
          </Badge>
        ) : (
          <Button
            color="secondary"
            variant="contained"
            className={cls.scoreBtn}
            onClick={showChat}
          >Chat</Button>
        )}
      </div>
      <Modal
        title="Chat"
        open={isChatOpened}
        footer={false}
        onCancel={hideChat}
        closable
        closeIcon={<Close style={{ marginTop: 14 }} />}
      >
        {isChatOpened && (
          <CreditChat creditId={credit.id} height={338} closeChat={hideChat} />
        )}
      </Modal>
    </>
  );
};

export const CreditInitial = ({ credit }: {credit: Credit}) => {
  const [isReportOpened, setReportOpened] = useState(false);
  const [isTermsOpened, setTermsOpened] = useState(false);
  const canReportOpen = rbac.hasPermissionOrSuperadmin('credit-risk|ALL');

  const openReport = credit.risk ? () => setReportOpened(true) : undefined;
  const showContracts = (credit.user.contracts || []).length > 0;
  const score = credit.riskScore || 0;

  return (
    <InitialPage title="Credit" backTo="/credit" backText="Back to all credits">
      <Row gutter={[20, 20]} className={cls.infoline}>
        <Col xs={24} lg={12} xl={8} className={cls.infolineCard}>
          <CreditCard credit={credit} />
        </Col>
        <Col xs={24} lg={12} xl={8} className={cls.infolineCard}>
          <InitialCard left="Risk manager" wrapperClass={cls.risk}>
            <ScoreWidget
              value={score}
              percents={score > (credit?.risk?.successPercent || 0) ? 90 : 10}
              title="Risk manager"
              className={cls.score}
              onClick={canReportOpen ? openReport : undefined}
            >
              <ScoreFooter credit={credit} setOpened={setTermsOpened} />
            </ScoreWidget>
          </InitialCard>
          <ExportCreditTerms
            creditId={credit.id}
            isOpened={isTermsOpened}
            setOpened={setTermsOpened}
          />
          <ExportRisk
            creditId={credit.id}
            isOpened={isReportOpened}
            setOpened={setReportOpened}
          />
        </Col>
        <Col xs={24} lg={12} xl={8} className={cls.infolineCard}>
          <CreditContractsDocuments credit={credit} />
        </Col>
        <Col xs={24} lg={12} xl={8} className={cls.infolineCard}>
          <CreditInvoiceWidget credit={credit} />
        </Col>
        <Col xs={24} lg={12} xl={8} className={cls.infolineCard}>
          <CreditCallsWidget
            credit={credit}
            wrapClass={cls.taskManagerWidget}
            contentClass={cls.taskManagerContent}
            title="Task Manager - by Credit"
          />
        </Col>
        <Col xs={24} lg={12} xl={8} className={cls.infolineCard}>
          <CreditTimeTracker credit={credit} />
        </Col>
      </Row>
      {showContracts && (
        <Row gutter={[20, 20]} className={cls.infoline}>
          <Col xs={24} lg={12} xl={8} className={cls.infolineCard}>
            <CreditContracts credit={credit} />
          </Col>
        </Row>
      )}
      <CreditGuarantors credit={credit} />

      <Row className={cls.infoline}>
        <Col xs={24} className={cls.infolineCard}>
          <CreditOrders credit={credit} />
        </Col>
      </Row>
    </InitialPage>
  );
};
