import { makeStyles } from '@mui/styles';
import { Col, Row } from 'antd';

import { CreditRisk } from '@types';
import { Pill } from './Pill';

export const RiskScore = ({ risk }: {risk: CreditRisk}) => {
  const cls = useStyles();
  const { scoreTerm, scoreAmount, scoreCashRatio } = risk;
  const scoreDscr = (scoreCashRatio.values || []).find(score => score.key === 'dscr');
  const scoreCash = (scoreCashRatio.values || []).find(score => score.key === 'cashRatio');

  return (
    <div>
      <table className={cls.table}>
        <thead>
          <tr>
            <th>Variable</th>
            <th>Con financieminto</th>
            <th>Sin financiamiento</th>
            <th>Puntuación</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>DSCR</td>
            <td>{scoreDscr?.withCredit || 0}</td>
            <td>{scoreDscr?.withoutCredit || 0}</td>
            <td className={cls.score}>
              <div className={cls.scoreRow}>
                <div className={cls.scoreCol}>
                  {scoreDscr?.score?.isPositive &&
                    <div className={cls.scorePositive}>Positive</div>}
                  {!scoreDscr?.score?.isPositive &&
                    <div className={cls.scoreNegative}>Negative</div>}
                </div>
                <div className={cls.scoreCol}>{scoreDscr?.score?.percent || 0} %</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cash ratio</td>
            <td>{scoreCash?.withCredit || 0}</td>
            <td>{scoreCash?.withoutCredit || 0}</td>
            <td className={cls.score}>
              <div className={cls.scoreRow}>
                <div className={cls.scoreCol}>
                  {scoreCash?.score?.isPositive &&
                    <div className={cls.scorePositive}>Positive</div>}
                  {!scoreCash?.score?.isPositive &&
                    <div className={cls.scoreNegative}>Negative</div>}
                </div>
                <div className={cls.scoreCol}>{scoreCash?.score?.percent || 0} %</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <Row gutter={[20, 20]}>
        <Col md={12} sm={24}>
          <Pill title="Plazo de credito" info={scoreTerm} mt={20} />
        </Col>
        <Col md={12} sm={24}>
          <Pill title="% Monto del crédito sobre la venta pactada con retenedor"
            info={scoreAmount} mt={20} />
        </Col>
      </Row>
    </div>
  );
};

const useStyles = makeStyles({
  score: {
    padding: '0 !important',
  },
  scoreRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100% !important',
    height: '25px',
  },
  scoreCol: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '&:last-child': {
      borderLeft: '1px solid #042E6B',
    },
  },
  scorePositive: {
    borderRadius: 10,
    padding: '0px 10px',
    height: 17,
    textAlign: 'center',
    fontSize: 10,
    border: '1px solid #52C41A',
    background: '#B7EB8F',
    color: '#52C41A',
  },
  scoreNegative: {
    borderRadius: 10,
    padding: '0px 10px',
    height: 17,
    textAlign: 'center',
    fontSize: 10,
    border: '1px solid #ff0000',
    background: '#f55',
    color: '#fff',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& th': {
      backgroundColor: '#042E6B',
      color: '#FFF',
      fontSize: 12,
      fontWeight: 500,
      borderLeft: '1px solid white',
      '&:first-child': {
        borderTopLeftRadius: 5,
        borderLeft: '1px solid #042E6B',
      },
      '&:last-child': {
        borderTopRightRadius: 5,
      },
    },
    '& td': {
      borderTop: '1px solid #042E6B',
      borderLeft: '1px solid #042E6B',
      fontSize: 12,
      fontWeight: 400,
      color: '#042E6B',
      width: '25%',
      padding: '3px 8px',
      backgroundColor: '#F0F3FB',
      '&:first-child': {
        backgroundColor: 'transparent',
      },
      '&:last-child': {
        borderRight: '1px solid #042E6B',
      },
      '&[rowspan]': {
        textAlign: 'center',
        backgroundColor: '#F0F3FB',
      },
    },
    '& tr:last-child td, & td[rowspan]': {
      borderBottom: '1px solid #042E6B',
      '&:first-child': {
        borderBottomLeftRadius: 5,
      },
      '&:last-child': {
        borderBottomRightRadius: 5,
      },
    },
  },
  noborder: {
    borderRight: 'none !important',
    borderRadius: '0px !important',
  },
});
