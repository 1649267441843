import { useEffect, useState } from 'react';

import { FinanceCostOptions, financeSettingsHttp } from '@network/finance-settings-http';

type WithSubcategories = {subcategories: {id: number, name: string}[]};

export const useFinanceCostSettings = () => {
  const [settings, setSettings] = useState<FinanceCostOptions & WithSubcategories>();

  useEffect(() => {
    (async () => {
      const nextSettings = await financeSettingsHttp.getCostOptions();
      const subcategories: Array<{id: number, name: string}> = [];

      for (const category of nextSettings.categories) {
        for (const subcategory of category.subcategories) {
          subcategories.push({
            id: subcategory.id ?? 0,
            name: `${subcategory.name} (${category.name})`,
          });
        }
      }
      setSettings({ ...nextSettings, subcategories });
    })();
  }, []);

  return settings;
};
