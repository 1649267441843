import { EditProps, Edit, SimpleForm } from 'react-admin';

import {
  Row,
  Col,
  Container,
  TextInput,
  PhoneInput,
  EditorInput,
  FileField,
  Forbidden,
} from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Resume: {record.fullName}</span>) : null;

export const ResumeEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <SimpleForm toolbar={rbacEdit ? undefined : false}>
        <Container max>
          <Row>
            <Col><TextInput source="fullName" required /></Col>
            <Col><PhoneInput source="phone" required /></Col>
            <Col flex={2}><TextInput source="email" required /></Col>
          </Row>
          <Row>
            <Col><TextInput source="experience" /></Col>
            <Col><TextInput source="english" /></Col>
            <Col flex={2}><TextInput source="linkedIn" /></Col>
          </Row>
          <Row>
            <Col><TextInput source="job.title" disabled /></Col>
            <Col><TextInput source="job.location" disabled /></Col>
            <Col><TextInput source="job.employment" disabled /></Col>
            <Col><TextInput source="job.department" disabled /></Col>
          </Row>
          <Row>
            <Col title="CV">
              <FileField source="cv1File" prefix="CV - " />
            </Col>
            <Col title="Why are you interested in working with us *">
              <EditorInput source="motivation" label={false} required />
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </Edit>
  );
};
