import { useEffect, useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

import {
  InitialPage,
  InitialCard,
  OrangeOutlineButton,
  InitialJson,
  InitialLine,
} from '@components';
import { MoffinProfile, User } from '@types';
import { ProfileLink } from '@pages/user/ProfileLink';
import { moffinHttp } from '@network';
import { moneyFormatter } from '@utils';

export const UserMoffinPage = ({ user }: {user: User}) => {
  const refresh = useRefresh();
  const [profiles, setProfiles] = useState<MoffinProfile[]>([]);

  const backTo = `/user/${user.id}?initial`;

  const loadProfiles = async () => {
    if (!user) return;
    const nextProfiles = await moffinHttp.getMoffinProfiles(user.id);
    setProfiles(nextProfiles);
  };

  useEffect(() => void loadProfiles(), [user]);

  const profilesBlank: Partial<MoffinProfile>[] = profiles;
  if (!profiles.find(p => p.accountType === 'PM')) {
    profilesBlank.push({ id: 0, accountType: 'PM', isCurrent: user.isCompany });
  }
  if (!profiles.find(p => p.accountType === 'PF')) {
    profilesBlank.push({ id: 0, accountType: 'PF', isCurrent: !user.isCompany });
  }
  profilesBlank.sort((a, b) => (b.isCurrent ? 1 : 0) - (a.isCurrent ? 1 : 0));

  const afterUpdate = async () => {
    refresh();
    await loadProfiles();
  };

  return (
    <InitialPage title="Moffin Score" backTo={backTo} backText="Back to Individual User" gap={24}>
      <Row gutter={[20, 20]}>
        {profilesBlank.map(profile => (
          <Col xs={24} sm={24} md={12} key={profile.accountType}>
            <MoffinProfileWidget
              user={user}
              profile={profile}
              afterUpdate={afterUpdate}
            />
          </Col>
        ))}
      </Row>
    </InitialPage>
  );
};

interface MoffinProfileWidgetProps {
  user: User;
  profile: Partial<MoffinProfile>;
  afterUpdate: () => Promise<void>;
}

const MoffinProfileWidget = ({ user, profile, afterUpdate }: MoffinProfileWidgetProps) => {
  const cls = useStyles();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const defaultAccountType = user.isCompany ? 'PM' : 'PF';

  const requestScore = async (accountType?: 'PM' | 'PF') => {
    setLoading(true);
    try {
      await moffinHttp.requestScore(user.id, accountType || defaultAccountType);
      await afterUpdate();
      notify('Reports was requested');
    } catch (err: any) {

    } finally {
      setLoading(false);
    }
  };

  const resetProfile = async (profileId?: number) => {
    setLoading(true);
    try {
      await moffinHttp.resetProfile(profileId || 0);
      await afterUpdate();
      notify('Reports are cleared');
    } catch (err: any) {

    } finally {
      setLoading(false);
    }
  };

  const name = profile.accountType === 'PM' ? 'Company (PM)' : 'User (PF)';
  const title = `Profile of ${name} – ${profile.rfc || ''}`;
  const { BureauScore, NumeroMop, CurrentDebt } = profile.reportExtractions || {};

  return (
    <div className={cls.card}>
      <InitialCard extraPadding autoHeight left={title} right={profile.isCurrent && 'MAIN'}>
        <div className={cls.block}>
          <ProfileLink url={profile.moffinLink} />
        </div>
        <Row gutter={[30, 30]} className={cls.buttons}>
          <Col xs={24} sm={24} md={12}>
            <Button disabled={loading} onClick={() => requestScore(profile.accountType)}
              variant="contained" color="primary">
              Update profile score
            </Button>
          </Col>
          <Col xs={24} sm={24} md={12}>
            {profile && profile.id ? (
              <OrangeOutlineButton disabled={loading}
                onClick={() => resetProfile(profile.id)}>
                Reset reports
              </OrangeOutlineButton>
            ) : null}
          </Col>
        </Row>
      </InitialCard>

      <InitialCard extraPadding autoHeight left="Extractions">
        <InitialLine title="Bureau Score" widthTitle={10}>
          {BureauScore || '–'}
        </InitialLine>
        <InitialLine title="Highest Debt Indicator (MOP)" widthTitle={10}>
          {NumeroMop || '–'}
        </InitialLine>
        <InitialLine title="Current Debt" widthTitle={10}>
          {moneyFormatter.format(CurrentDebt || 0)}
        </InitialLine>
      </InitialCard>

      <InitialJson title="Create Profile request"
        json={profile.createdRequest} />

      {profile.createdError ? (
        <InitialJson title="Create Profile - ERROR"
          json={profile.createdError} mt={24} isError />
      ) : (
        <InitialJson title="Create Profile response"
          json={profile.createdResponse} mt={24} />
      )}

      {profile.reportError ? (
        <InitialJson title={`Bureau full report (bureau${profile.accountType}) - ERROR`}
          json={profile.reportError} mt={24} isError />
      ) : (
        <InitialJson title={`Bureau full report (bureau${profile.accountType})`}
          json={profile.reportResponse} mt={24} />
      )}

      {profile.accountType === 'PF' && profile.scoreError && (
        <InitialJson title="Score full report (prospectorPF) - ERROR"
          json={profile.scoreError} mt={24} isError />
      )}
      {profile.accountType === 'PF' && !profile.scoreError && (
        <InitialJson title="Score full report (prospectorPF)"
          json={profile.scoreResponse} mt={24} />
      )}

    </div>
  );
};

const useStyles = makeStyles({
  block: {
    marginTop: 15,
  },
  buttons: {
    marginTop: 25,
    display: 'flex',
    justifyContent: 'space-between',

    '& button': {
      width: '100%',
      textTransform: 'none',
      borderRadius: 8,
    },
  },
  card: {
    marginBottom: 30,
  },
});
