import { clsx } from 'clsx';
import { Button, Toolbar } from '@mui/material';
import { Edit, EditProps, Link, SimpleForm } from 'react-admin';

import { rbac } from '@utils';
import { SupportFaq } from '@types';
import { FaqView } from './FaqView';
import { InitialPage } from '@components';
import { FaqForm, validate } from './FaqForm';
import { useEditProps, useQuery, useRbacEdit, useRbacReadonly, useRecord } from '@hooks';
import cls from './Faq.module.css';

const Title = ({ record }: {record?: SupportFaq}) =>
  record ? (<span>FAQ: {record.id}</span>) : null;

export const SupportFaqEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const record = useRecord<SupportFaq>(props);
  const rbacEdit = useRbacEdit();
  const query = useQuery();
  const showView = query.get('view') === '';
  const rbacReadonly = useRbacReadonly();
  const isViewPage = rbacReadonly || showView;

  const noEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const ViewActions = () => {
    return rbacEdit ? (
      <Toolbar className={clsx(
        cls.toolbar, cls.viewToolbar, record.previousId && record.nextId && cls.withNaw,
      )}>
        {record.previousId ?
          <Link to={`/support-faq/${record.previousId ? record.previousId : record.id}?view`}>
            <Button
              variant="outlined"
              color="secondary"
              className={clsx(cls.viewBtn, cls.prevBtn)}
              disabled={!record.previousId}
            >Previous Question</Button>
          </Link> : null}
        <div className={cls.rightBtns}>
          <Link to={`/support-faq/${rbacEdit && rbac.isSuperadmin() ? record.id : ''}`}>
            <Button variant="outlined" color="secondary" className={cls.viewBtn}>Return</Button>
          </Link>
          {record.nextId ?
            <Link to={`/support-faq/${record.nextId ? record.nextId : record.id}?view`}>
              <Button
                variant="outlined"
                color="secondary"
                className={clsx(cls.viewBtn, cls.nextBtn)}
                disabled={!record.nextId}
              >Next Question</Button>
            </Link> : null}
        </div>
      </Toolbar>
    ) : null;
  };

  return (
    <Edit {...props} title={<Title />} {...editProps} className={cls.page}>
      <InitialPage
        title="Edit of FAQ"
        backTo="/support-faq"
        backText="Back to all FAQ´s"
      >
        {isViewPage ? (
          <SimpleForm validate={validate} toolbar={<ViewActions />} onKeyPress={noEnter}>
            <FaqView record={record} />
          </SimpleForm>
        ) : (
          <SimpleForm validate={validate} toolbar={false}>
            <FaqForm />
          </SimpleForm>
        )}
      </InitialPage>
    </Edit>
  );
};
