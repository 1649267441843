import * as React from 'react';
import {
  useState,
  useCallback,
  useRef,
  ReactNode,
  HtmlHTMLAttributes,
  useContext,
} from 'react';
import { Menu, styled } from '@mui/material';
import ContentFilter from '@mui/icons-material/FilterList';
import { useListContext, useResourceContext } from 'ra-core';
import { FilterContext } from 'ra-ui-materialui';

import { Button } from './Button';
import { FilterButtonMenuItem } from './FilterButtonMenuItem';

export const FilterButton = (props: FilterButtonProps): JSX.Element => {
  const {
    filters: filtersProp,
    className,
    disableSaveQuery,
    ...rest
  } = props;
  const filters = useContext(FilterContext) || filtersProp;
  const resource = useResourceContext(props);
  const {
    displayedFilters = {},
    showFilter,
    hideFilter,
  } = useListContext(props);
  const [open, setOpen] = useState(false);
  const anchorEl = useRef();

  if (filters === undefined) {
    throw new Error(
      'The <FilterButton> component requires the <List filters> prop to be set',
    );
  }

  const hiddenFilters = filters.filter((filterElement: any) => !filterElement.props.alwaysOn);

  const handleClickButton = useCallback(
    event => {
      // This prevents ghost click.
      event.preventDefault();
      setOpen(true);
      anchorEl.current = event.currentTarget;
    },
    [anchorEl, setOpen],
  );

  const handleRequestClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleToggleShow = ({ source, defaultValue }: {source: string, defaultValue?: string}) => {
    if (displayedFilters[source]) {
      hideFilter(source);
    } else {
      showFilter(source, defaultValue === '' ? undefined : defaultValue);
    }
  };

  return (
    <Root className={className} {...sanitizeRestProps(rest as any)}>
      <Button
        className="add-filter"
        label="ra.action.add_filter"
        aria-haspopup="true"
        onClick={handleClickButton}
      >
        <ContentFilter />
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleRequestClose}
      >
        {hiddenFilters.map((filterElement: any, index) => {
          const isChecked = displayedFilters[filterElement.props.source] ? true : false;
          return (
            <FilterButtonMenuItem
              key={filterElement.props.source}
              filter={filterElement}
              resource={resource}
              onShow={handleToggleShow}
              autoFocus={index === 0}
              isChecked={isChecked}
            />
          );
        })}
      </Menu>
    </Root>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
const sanitizeRestProps = ({
  displayedFilters = null,
  filterValues = null,
  showFilter = null,
  ...rest
}) => rest;
/* eslint-enable @typescript-eslint/no-unused-vars */

export interface FilterButtonProps extends HtmlHTMLAttributes<HTMLDivElement> {
  className?: string;
  resource?: string;
  filterValues?: any;
  showFilter?: (filterName: string, defaultValue: any) => void;
  displayedFilters?: any;
  filters?: ReactNode[];
  disableSaveQuery?: boolean;
}

const PREFIX = 'RaFilterButton';

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})({
  display: 'inline-block',
});
