import React from 'react';
import {
  Identifier,
  Link,
  List,
  RaRecord,
  RowClickFunction,
  TextField,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Typography } from '@mui/material';

import { Manager } from '@types';
import { Container, Grid, PhoneField } from '@components';
import cls from './IndividualManagers.module.css';

export const IndividualManagers = () => {
  const record = useRecordContext<Manager>();
  const redirect = useRedirect();

  if (!record || record.type === 'associate') {
    return (<></>);
  }

  const rowClick: RowClickFunction = (id: Identifier, resource: string, record: RaRecord) => {
    redirect(`/manager-associate/${id}`);
    return false;
  };

  return (
    <Container max className={cls.block}>
      <div className={cls.header}>
        <Typography variant="h6" component="h2" className={cls.subTitle}>
          Individuals managers
        </Typography>
        <Link to={`/manager-associate/create?dealerId=${record.dealerId}`}>
          <Button
            className={cls.btnAdd}
            variant="contained"
            color="secondary"
          >
            <PlusOutlined /> Add Individual
          </Button>
        </Link>
      </div>

      {record && (
        <List
          resource={`manager/${record.id}/individual-managers`}
          exporter={false}
          actions={false}
          className={cls.list}
          title={' '}
          empty={<div />}
        >
          <Grid
            defaultColumns={'name phone position email'.split(' ')}
            rowClick={rowClick}
          >
            <TextField source="name" />
            <PhoneField source="phone" />
            <TextField source="position" />
            <TextField source="email" />
          </Grid>
        </List>
      )}
    </Container>
  );
};
