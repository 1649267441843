import { useEffect, useState } from 'react';
import { useResourceContext, useNotify, useRedirect, useCreate } from 'react-admin';

import { Doctype } from '@types';
import { useRbacCreate } from '@hooks';
import { Forbidden, InitialPage } from '@components';
import { DoctypeForm } from '@pages/doctype/DoctypeForm';
import { axiosErrorToString } from '@utils';

export type NewDoctype = Omit<Doctype, 'id'>;

const initialFormValues: NewDoctype = {
  nameEn: '',
  nameMx: '',
  infoEn: '',
  infoMx: '',
  requirements: 'all',
  category: '',
  categoryName: '',
  image: '',
  position: 0,
  isRfc: false,
  isCurp: false,
  isOptional: false,
  isDefault: false,
  isGuarantor: false,
  isEnabled: true,
  isSendToCredit: false,
  isForLoanTape: false,
  isVisible: true,
  excludedTiers: [],
};

export const DoctypeCreate = () => {
  const resource = useResourceContext();
  const [create, { data, error, isError, isSuccess }] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const rbacCreate = useRbacCreate();

  const backTo = `/${resource}`;

  const [formValues, setFormValues] = useState<NewDoctype>(initialFormValues);

  const createHandler = async () => {
    try {
      await create(
        resource,
        {
          data: {
            ...formValues,
            requirements: formValues?.requirements ? formValues?.requirements : null,
          } },
      );
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      notify('Doctype has been created');
      redirect(`/${resource}/${data.id}`);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const { message } = error as any;
      notify(message || 'Something went wrong', { type: 'error' });
    }
  }, [isError]);

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <InitialPage
      title={resource === 'doctype-company' ? 'Doctype company edit' : 'Doctype individual edit'}
      backTo={backTo}
      backText="Back to all doctypes"
    >
      <DoctypeForm type="create" formValues={formValues} setFormValues={setFormValues} submit={createHandler} />
    </InitialPage>
  );
};
