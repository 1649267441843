import { ListProps, TextField, ReferenceField } from 'react-admin';

import {
  TextInput,
  PhoneField,
  Grid,
  GridList,
  ExternalUrlField,
  EmailField,
  ReferenceInput,
  SelectInput,
  FileField,
  Forbidden,
  DateField,
} from '@components';
import { useRbacList } from '@hooks';

export const ResumeList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'jobId fullName phone email experience english linkedIn cv1File'.split(' ')}
      >
        <TextField source="id" />
        <ReferenceField reference="job" source="jobId">
          <TextField source="title" />
        </ReferenceField>
        <TextField source="fullName" />
        <PhoneField source="phone" />
        <EmailField source="email" />
        <TextField source="experience" />
        <TextField source="english" />
        <ExternalUrlField source="linkedIn" />
        <TextField source="motivation" />
        <FileField source="cv1File" prefix="CV - " label="CV" />
        <DateField source="createdAt" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  <ReferenceInput
    source="jobId"
    key="jobId"
    reference="job"
    label="Job"
    allowEmpty
    alwaysOn
    perPage={1000}
    sort={{ field: 'title', order: 'ASC' }}
  >
    <SelectInput optionText="title" />
  </ReferenceInput>,
];
