import { useRef, useState } from 'react';
import {
  ListProps,
  TextField,
  ReferenceField,
  RowClickFunction,
  Identifier,
  RaRecord,
} from 'react-admin';

import {
  TextInput,
  ReferenceInput,
  SelectInput,
  Grid,
  GridList,
  Forbidden,
} from '@components';
import { useRbacList } from '@hooks';
import { Crop } from '@types';
import { CropCreator } from '@components/Crops/CropCreator';
import { useSettingsContext } from '@providers/settings-provider';

export const CropList = (props: ListProps) => {
  const rbacList = useRbacList();
  const {
    isCropCreatorActive, setIsCropCreatorActive, setIsToolbarShown,
  } = useSettingsContext();

  const [selectedCrop, setSelectedCategory] = useState<Crop | null>(null);

  const modalType = useRef<'create' | 'edit'>('create');

  const rowClick: RowClickFunction = (id: Identifier, resource: string, record: RaRecord) => {
    modalType.current = 'edit';
    setSelectedCategory(record as Crop);
    setIsCropCreatorActive(true);
    setIsToolbarShown(false);

    return false;
  };

  const openCropCreator = () => {
    modalType.current = 'create';
    setSelectedCategory(null);
    setIsCropCreatorActive(true);
    setIsToolbarShown(false);
  };

  const closeCropCategoryCreator = () => {
    modalType.current = 'create';
    setIsCropCreatorActive(false);
    setIsToolbarShown(true);
  };

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <>
      <GridList {...props}
        filters={Filters}
        sort={{ field: 'categoryId', order: 'ASC' }}
        customCreate={openCropCreator}
      >
        <Grid
          rowClick={rowClick}
          defaultColumns={'id categoryId nameMx nameEn'.split(' ')}
        >
          <TextField source="id" />
          <ReferenceField reference="crop-category" source="categoryId">
            <TextField source="nameMx" />
          </ReferenceField>
          <TextField source="nameMx" />
          <TextField source="nameEn" />
        </Grid>
      </GridList>

      {isCropCreatorActive && (
        <CropCreator
          type={modalType.current}
          close={closeCropCategoryCreator}
          values={selectedCrop}
        />
      )}
    </>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  <ReferenceInput
    reference="crop-category"
    source="categoryId"
    key="q"
    perPage={1000}
    sort={{ field: 'nameMx', order: 'ASC' }}
    alwaysOn
  >
    <SelectInput optionText="nameMx" />
  </ReferenceInput>,
];
