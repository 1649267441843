import { Http } from './http';
import { State } from '@types';

class StateHttp extends Http {
  prefix = 'state';

  async getAll () {
    const res = await this.get<State[]>('');

    return res.data;
  }

  async updateOne (id: number, params: UpdateStateParams) {
    const res = await this.put<State>(`/${id}`, params);

    return res.data;
  }
}

export const stateHttp = new StateHttp();

export type UpdateStateParams = Omit<State, 'id'>
