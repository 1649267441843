import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { CheckboxGroupInput } from 'ra-ui-materialui';
import { makeStyles } from '@mui/styles';

import { http } from '@network';

interface Props {
  source: string;
  label?: string;
  hideLabel?: boolean;
  reference?: string;
  optionKey?: string;
  optionLabel?: string;
  columns?: number;
  className?: string;
}

export const ManyCheckboxInput = ({
  source, label, reference, optionKey, optionLabel, columns, className,
}: Props) => {
  const useStyles = makeStyles({
    checkbox: {
      '& .MuiFormControlLabel-root': {
        width: columns === 4 ? '24%' : columns === 3 ? '33%' : columns === 2 ? '49%' : undefined,
      },
    },
  });
  const cls = useStyles();
  const [options, setOptions] = useState<CheckOption[]>([]);

  useEffect(() => {
    (async () => {
      const response = await http.get<any>(reference || source, {
        params: { _start: 0, _end: 9999999 },
      });
      const nextOptions: CheckOption[] = [];
      if (response.data && Array.isArray(response.data)) {
        (response.data ?? []).forEach((model: any) => {
          nextOptions.push({
            id: model[optionKey ?? 'id'],
            name: model[optionLabel ?? 'nameMx'] || model['name'] || model['title'],
          });
        });
      }
      setOptions(nextOptions);
    })();
  }, []);

  const inputLabel = label || source.slice(0, 1).toUpperCase().concat(source.slice(1));

  return (
    <CheckboxGroupInput
      name={source}
      source={source}
      label={inputLabel}
      choices={options}
      className={clsx(cls.checkbox, className)}
    />
  );
};

interface CheckOption {
  id: string,
  name: string,
}
