import { clsx } from 'clsx';
import { Link } from 'react-admin';

import { Credit } from '@types';
import { dateFormatter } from '@utils';
import { CallRiskField } from '@pages/credit-call/CallRiskField';
import { CallStatusField } from '@pages/credit-call/CallStatusField';
import { InitialCard, OrangeOutlineButton, Scroll } from '@components';
import cls from './CreditCallsWidget.module.css';

interface Props {
  credit: Credit;
  wrapClass?: string;
  contentClass?: string;
  title?: string;
}

export const CreditCallsWidget = ({ credit, wrapClass, contentClass, title }: Props) => {
  const showAll = false;
  const allItems = (credit.calls || []).sort((a, b) => b.id - a.id);
  const showItems = allItems.slice(0, showAll ? undefined : 2);

  const calcListHeight = (): number => {
    if (allItems.length < 2) return 179;

    const height = showAll ? 300 : (showItems.length * 75 + 40);

    return height;
  };

  return (
    <InitialCard
      left={title || 'Task by Credit'}
      autoHeight extraPadding
      wrapperClass={wrapClass}
      contentClass={contentClass}
    >
      <div className={cls.box}>
        <Scroll height={calcListHeight()}>
          {showItems.map(call => (
            <div className={cls.item} key={call.id}>
              <div className={cls.header}>
                <div className={cls.by}>
                  <Link to={`/credit-call/${call.id}`}>{call.title}</Link>
                </div>
                <div>
                  Contact by {call.contactBy ? dateFormatter.toDateSlash(call.contactBy) : '–'}
                </div>
              </div>
              <div className={cls.footer}>
                <CallStatusField source="status" callStatus={call.status} width={150}/>
                {call.risk && <CallRiskField source="risk" callRisk={call.risk} width={150}/>}
              </div>
            </div>
          ))}
        </Scroll>
        <div className={clsx(cls.actions, 'actions')}>
          <Link to={`/credit/${credit.id}?calls`}>
            <OrangeOutlineButton>
              Show More ({allItems.length})
            </OrangeOutlineButton>
          </Link>
        </div>
      </div>
    </InitialCard>
  );
};
