import { makeStyles } from '@mui/styles';
import { Col, Row } from 'antd';

import { User } from '@types';
import { InitialCard, InitialLine, PasswordInput } from '@components';
import { dateFormatter } from '@utils';

export const UserPassword = ({ user }: {user: User}) => {
  const cls = useStyles();

  return (
    <InitialCard autoHeight extraPadding>
      <Row gutter={[24, 24]} className={cls.box}>
        <Col sm={24} md={12}>
          {/* LEFT */}
          <div className={cls.leftBox}>
            <InitialLine title="Registration Date:" titleClass={cls.inputTitle}>
              {dateFormatter.toDateTime(user.createdAt, true)}
            </InitialLine>
            <InitialLine title="Updated at:" titleClass={cls.inputTitle}>
              {dateFormatter.toDateTime(user.updatedAt, true)}
            </InitialLine>
          </div>
        </Col>
        <Col sm={24} md={12}>
          <div className={cls.rightBox}>
            <InitialLine title="Password:" full
              titleClass={cls.inputTitle} contentClass={cls.passwordContent}>
              <PasswordInput source="password" label="" placeholder="****************"
                className={cls.passwordInput} />
            </InitialLine>
            <InitialLine title="Confirm Password:" full
              titleClass={cls.inputTitle} contentClass={cls.passwordContent}>
              <PasswordInput source="confirmPassword" label="" placeholder="****************"
                className={cls.passwordInput} />
            </InitialLine>
          </div>
        </Col>
      </Row>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    fontFamily: 'Outfit, sans-serif',
    color: '#272727',
  },
  inputTitle: {
    fontWeight: 500,
  },
  passwordContent: {
    paddingRight: '25%',
  },
  passwordInput: {
    '& svg path': {
      fill: '#002A77',
      d: 'path("M9.94521 12C7.95616 12 6.32877 10.2 6.32877 8C6.32877 5.8 7.95616 4 9.94521 4C11.9342 4 13.5616 5.8 13.5616 8C13.5616 10.2 11.9342 12 9.94521 12ZM9.94521 6C7.59452 6 7.59452 10 9.94521 10C12.2959 10 12.2959 6 9.94521 6ZM18.1726 7.3C18.0822 7.1 16.3644 2 9.94521 2C3.52603 2 1.80822 7.3 1.71781 7.3L0 6.7C0.090411 6.4 2.16986 0 9.94521 0C17.7205 0 19.8 6.4 19.8 6.7L18.1726 7.3Z")',
    },
  },
  leftBox: {},
  rightBox: {
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#E1E5ED',
    padding: '0 10px 22px 30px',
    position: 'relative',
  },
});
