import { clsx } from 'clsx';
import { InputNumber } from 'antd';
import { moneyFormatter } from '@utils';
import React, { Fragment } from 'react';

import cls from './TotalVariablesTable.module.css';

type FieldKeysType = 'totalAssets' | 'totalLiabilities' | 'stockholdersEquity'
  | 'netProfit' | 'debt' | 'leverage' | 'roe';

interface RowProps {
  key: FieldKeysType;
  title: string;
  value: number;
}

export interface TotalVariablesProps {
  values: RowProps[];
}

interface Props {
  data: TotalVariablesProps;
  calcData: TotalVariablesProps;
  onChange: (value: TotalVariablesProps) => void;
  readonly?: boolean;
}

export const TotalVariablesTable = ({ data, calcData, onChange, readonly }: Props) => {
  const percentageColumns: FieldKeysType[] = ['debt', 'leverage', 'roe'];
  const rows: FieldKeysType[][] = [
    ['totalAssets', 'debt'],
    ['totalLiabilities', 'leverage'],
    ['stockholdersEquity', 'roe'],
    ['netProfit'],
  ];
  const titleRenaming = (col: RowProps): string => {
    if (col.key === 'debt') return 'Asset Ratio';
    if (col.key === 'leverage') return 'Equity Ratio';
    return col.title;
  };

  const getField = (name: FieldKeysType): JSX.Element => {
    const col = data.values.find(v => v.key === name);
    const calcCol = (calcData?.values || []).find(v => v.key === name);

    if (!col || !calcCol) {
      return <div className={clsx(cls.col, cls.empty)} />;
    }

    return percentageColumns.includes(col.key) ? (
      <div className={cls.row}>
        <div className={clsx(cls.col, cls.colored, cls.colTitle)}>{titleRenaming(col)}</div>
        <div className={clsx(cls.col, cls.colored, cls.value)}>
          <span className={cls.textWrap}>{+(calcCol.value || 0).toFixed(2)}</span>
        </div>
      </div>
    ) : (
      <div className={cls.row}>
        <div className={clsx(cls.col, cls.colTitle)}>{col.title}</div>
        <div className={clsx(cls.col, cls.value, cls.colored)}>
          <span className={cls.inputWrap}>
            <InputNumber
              min={0}
              max={1000000000}
              formatter={(value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value ? +value.replace(/\$\s?|(,*)/g, '') : 0}
              defaultValue={col.value}
              bordered={false}
              onChange={e => handleChange(col, e)}
              className={cls.input}
              onBeforeInputCapture={readonly ? e => e.preventDefault() : undefined}
              disabled={readonly}
            />
          </span>
        </div>
      </div>
    );
  };

  const handleChange = (row: RowProps, val: number | null): void => {
    if (readonly) return;
    const res = val || 0;
    const newValues = data.values
      .map(v => (v.key === row.key) ? { ...row, value: res } : v);
    onChange({ ...data, values: newValues });
  };

  return (!data || !data.values.length) ? null : (
    <div className={cls.table}>
      {rows.map((row, idx) => (
        <div className={cls.tableRow} key={idx}>
          {row.map(col => (
            <Fragment key={col}>
              <div className={cls.tableCol} key={col}>{getField(col)}</div>
              {row.length === 1 ? <div className={clsx(cls.tableCol, cls.empty)} /> : null}
            </Fragment>
          ))}
        </div>
      ))}
    </div>
  );
};
