import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { Button } from '@mui/material';
import { SaveFilled } from '@ant-design/icons';
import {
  EditProps,
  Edit,
  SimpleForm,
  TopToolbar,
  SaveButton,
  Link,
  DeleteButton,
  TextField,
} from 'react-admin';

import { feedbackStatusOptions } from './feedback-status-options';
import {
  Container,
  TextInput,
  SelectInput,
  DateTimeInput,
  Forbidden,
} from '@components';
import { Feedback } from '@types';
import { FormRow } from '@components/FormRow';
import { PageHeader } from '@components/PageHeader';
import { useEditProps, useRbacEdit, useRbacView, useRecord } from '@hooks';
import cls from './FeedbackEdit.module.css';

const Title = ({ record }: {record?: any}) => {
  return record ? (<span>Retrieve Password #{record.id}</span>) : null;
};

export const FeedbackEdit = (props: EditProps) => {
  const initialFeedback = useRecord<Feedback>(props);
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();
  const infoColGrid = {
    sm: { title: 5, children: 19 },
    lg: { title: 6, children: 18 },
    xl: { title: 7, children: 17 },
    xxl: { title: 5, children: 19 },
  };
  const infoSelectGrid = {
    sm: { title: 5, children: 19 },
    lg: { title: 6, children: 18 },
    xl: { title: 7, children: 10 },
    xxl: { title: 5, children: 8 },
  };
  const footerColGrid = {
    sm: { title: 5, children: 19 },
    lg: { title: 4, children: 20 },
    xl: { title: 6, children: 18 },
    xxl: { title: 4, children: 20 },
  };

  if (!rbacView) {
    return <Forbidden />;
  }

  const EditActions = () => {
    return (
      <TopToolbar className={cls.actions}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon}/>}
          label="Save"
          type="button"
          className={clsx(cls.action, cls.actionSave)}
          variant="outlined"
        />
        <Link to="/feedback/contact">
          <Button variant="outlined" color="secondary" className={clsx(cls.action, cls.actionReturn)}>
            Cancel
          </Button>
        </Link>
        <DeleteButton
          className={clsx(cls.action, cls.actionDelete)}
        />
      </TopToolbar>
    );
  };

  return (
    <Edit {...props} title={<Title />} {...editProps} className={clsx(cls.page, 'details-page-wrapper')}>
      <PageHeader
        title="Individual Contact Us"
        linkText="Back to all contact us"
        linkUrl="/feedback/contact"
      />

      <SimpleForm toolbar={rbacEdit ? <EditActions /> : false} className={cls.form}>
        <Container max>
          <Row gutter={24} className={cls.wrapper}>
            <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColFirst)}>
              <div className={clsx(cls.block, cls.blockFirst)}>
                <div className={cls.blockHeader}>User</div>
                <div className={cls.blockMiddle}>
                  <div className={cls.blockMiddleIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
                      <path d="M27.5 0C12.3161 0 0 12.3161 0 27.5C0 42.6839 12.3161 55 27.5 55C42.6839 55 55 42.6839 55 27.5C55 12.3161 42.6839 0 27.5 0ZM27.5 13.75C33.6875 13.75 38.7161 18.7786 38.7161 24.9661C38.7161 31.1536 33.6875 36.1821 27.5 36.1821C21.3125 36.1821 16.2839 31.1536 16.2839 24.9661C16.2839 18.7786 21.3125 13.75 27.5 13.75ZM27.5 51.4643C21.9214 51.4643 16.775 49.5393 12.6893 46.3179C14.1429 44.2357 16.5393 42.9786 19.0929 42.9786H35.9071C38.4607 42.9786 40.8571 44.2357 42.3107 46.3179C38.225 49.5393 33.0786 51.4643 27.5 51.4643Z" fill="#042E6B"/>
                    </svg>
                  </div>
                  <div className={cls.blockMiddleInfo}>
                    <FormRow
                      title="Full name:"
                      width={infoColGrid}
                      rowClassName={clsx(cls.formRow, cls.infoFormRow)}
                      colTitleClassName={cls.formLabelCol}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formInfoValue)}
                    >
                      <TextField label={false} disabled source="fullName" />
                    </FormRow>
                    <FormRow
                      title="Phone:"
                      width={infoColGrid}
                      rowClassName={clsx(cls.formRow, cls.infoFormRow)}
                      colTitleClassName={cls.formLabelCol}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formInfoValue)}
                    >
                      <TextInput label={false} source="phone" disabled />
                    </FormRow>
                    <FormRow
                      title="Category:"
                      width={infoColGrid}
                      rowClassName={clsx(cls.formRow, cls.infoFormRow)}
                      colTitleClassName={cls.formLabelCol}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formInfoValue)}
                    >
                      <TextInput label={false} source="categoryEn" disabled />
                    </FormRow>
                    <FormRow
                      title="Status:"
                      width={infoSelectGrid}
                      rowClassName={clsx(cls.formRow, cls.infoFormRow)}
                      colTitleClassName={cls.formLabelCol}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formInfoValue)}
                    >
                      <SelectInput label={false} source="status" choices={feedbackStatusOptions} required />
                    </FormRow>
                  </div>
                </div>
                <div className={cls.blockFooter}>
                  <FormRow
                    title="Created at:"
                    width={footerColGrid}
                    rowClassName={cls.formRow}
                    colTitleClassName={clsx(cls.formLabelCol, cls.formFooterLabel)}
                    colChildrenClassName={clsx(cls.formValueCol, cls.formFooterValue)}
                  >
                    <DateTimeInput label={false} source="createdAt" disabled />
                  </FormRow>
                  {initialFeedback?.closedAt ? (
                    <FormRow
                      title="Closed at:"
                      width={footerColGrid}
                      rowClassName={cls.formRow}
                      colTitleClassName={clsx(cls.formLabelCol, cls.formFooterLabel)}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formFooterValue)}
                    >
                      <DateTimeInput label={false} source="closedAt" disabled />
                    </FormRow>
                  ) : null}
                </div>
              </div>
            </Col>

            <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColLast)}>
              <div className={clsx(cls.block, cls.blockLast)}>
                <FormRow
                  title="Message"
                  fullWidth
                  rowClassName={clsx(cls.formRow, cls.messageRow)}
                  colTitleClassName={clsx(cls.formLabelCol, cls.formTitleCol)}
                  colChildrenClassName={clsx(cls.formValueCol, cls.formValueColTextarea)}
                >
                  <TextInput source="message" label={false} placeholder="Message" multiline disabled />
                </FormRow>
                <FormRow
                  title="Notes"
                  fullWidth
                  rowClassName={cls.formRow}
                  colTitleClassName={clsx(cls.formLabelCol, cls.formTitleCol)}
                  colChildrenClassName={clsx(cls.formValueCol, cls.formValueColTextarea)}
                >
                  <TextInput source="notes" label={false} placeholder="Notes" multiline />
                </FormRow>
              </div>
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </Edit>
  );
};
