import { useEffect, useState } from 'react';

import { InitialPage } from '@components';
import { rbac } from '@utils';
import { FinanceSettingsData, financeSettingsHttp } from '@network/finance-settings-http';
import { FinanceCostCategories } from './components/FinanceCostCategories';
import { FinanceCostTypes } from './components/FinanceCostTypes';
import { FinanceActivationSettings } from './components/FinanceActivationSettings';

export const FinanceSettings = () => {
  const accessAll = rbac.hasFinanceSettingsAll();
  const accessReadonly = !accessAll && rbac.hasFinanceSettingsReadonly();
  const hasAccess = accessAll || accessReadonly;
  const [settings, setSettings] = useState<FinanceSettingsData>();

  useEffect(() => {
    (async () => {
      const nextSettings = await financeSettingsHttp.getSettings();
      setSettings(nextSettings);
    })();
  }, []);

  if (!hasAccess) {
    return null;
  }

  return (
    <InitialPage title="Finance Settings">
      <FinanceCostCategories
        categories={settings?.costCategories || []}
        readonly={accessReadonly}
      />
      <FinanceCostTypes costTypes={settings?.costTypes || []} />
      <FinanceActivationSettings settings={settings?.activationSettings} />
    </InitialPage>
  );
};
