import React from 'react';
import {
  FunctionField,
  Identifier,
  ListProps,
  RowClickFunction,
  TextField,
  useRedirect,
} from 'react-admin';

import { useExporter, useRbacList, useRbacReadonly } from '@hooks';
import { Forbidden, Grid, GridList, TextInput } from '@components';
import cls from './Faq.module.css';

export const SupportFaqList = (props: ListProps) => {
  const rbacList = useRbacList();
  const rbacReadonly = useRbacReadonly();
  const redirect = useRedirect();

  const stripTags = (text: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    return doc.body.textContent || '';
  };

  const exporter = useExporter({
    filename: 'Manuals',
    props: {
      'tag': {
        label: 'ID',
      },
      'question': {
        label: 'Question',
      },
      'answer': {
        label: 'Answer',
        transform: (value: string) => stripTags(value),
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  const filters = [
    <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  ];

  const rowClick: RowClickFunction = (id: Identifier, resource: string) => {
    if (rbacReadonly) {
      redirect(`/${resource}/${id}?view`);
      return false;
    }
    return 'edit';
  };

  return (
    <GridList
      {...props}
      filters={filters}
      sort={{ field: 'id', order: 'DESC' }}
      exporter={exporter}
    >
      <Grid
        rowClick={rowClick}
        defaultColumns={'question answer'.split(' ')}
        className={cls.grid}
      >
        <TextField source="tag" label="ID" />
        <TextField source="question" />
        <FunctionField
          resource="support-faq"
          source="answer"
          className={cls.ellipsis}
          render={(record: any) => stripTags(record.answer)}
        />
      </Grid>
    </GridList>
  );
};
