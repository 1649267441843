import { Spin, Space } from 'antd';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

export const Loader = (
  { center, mini, className }: {center?: boolean, mini?: boolean, className?: string },
) => {
  const cls = useStyles();

  if (center) {
    return (
      <div className={clsx(cls.center, className)}>
        <Spin size={mini ? 'small' : 'large'} />
      </div>
    );
  }

  return (
    <Space size="middle" className={className}>
      <Spin size={mini ? 'small' : 'large'} />
    </Space>
  );
};

const useStyles = makeStyles({
  center: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
