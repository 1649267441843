import React from 'react';
import { useWatch } from 'react-hook-form';
import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';

import { moneyFormatter } from '@utils';
import {
  Container,
  TextInput,
  InitialCard,
  InitialRow,
  AutoCompleteListInput,
} from '@components';

export const validate = (values: any) => {
  const errors: any = {};

  if (!values['name']) {
    errors['name'] = 'This field is required';
  }
  if (!Array.isArray(values['activationIds']) || values['activationIds'].length === 0) {
    errors['activationIds'] = 'This field is required';
  }

  return errors;
};

export const FinanceCampaignForm = () => {
  const cls = useStyles();
  const budget = useWatch({ name: 'budget' });
  const mql = useWatch({ name: 'mql' });
  const cpl = useWatch({ name: 'cpl' });
  const activationsTotal = useWatch({ name: 'activationsTotal' });
  const labelSize = 7;

  return (
    <Container max>
      <InitialCard left="Complete information" extraPadding>
        <Row gutter={[20, 20]}>
          <Col xs={12} sm={11}>
            <InitialRow title="Campaign*" fontLg labelSize={labelSize}>
              <TextInput source="name" label="" inputProps={{ maxLength: 60 }} />
            </InitialRow>
            <InitialRow title="Objective" fontLg labelSize={labelSize}>
              <TextInput
                className={cls.textarea}
                source="description"
                placeholder="Describe the details"
                multiline
                maxRows={3}
                rows={3}
                label=""
                inputProps={{ maxLength: 300 }}
              />
            </InitialRow>
            <AutoCompleteListInput
              title="Activation*" fontLg labelSize={labelSize}
              source="activationIds"
              reference="/finance-activation"
              placeholder="Search Activation"
              disabledProp="campaignId"
            />
          </Col>
          <Col xs={12} sm={12} offset={1}>
            <div className={cls.numbers}>
              <div className={cls.numbersRow}>
                <div className={cls.budget}>
                  <div className={cls.budgetTitle}>Budget</div>
                  <div className={cls.budgetValue}>{moneyFormatter.format(budget || 0)}</div>
                </div>
              </div>
              <div className={cls.numbersRow}>
                <div className={cls.circle}>
                  <div className={cls.circleTitle}>
                    <div>MQL’s</div>
                  </div>
                  <div className={cls.circleValue}>{mql || 0}</div>
                </div>
                <div className={cls.circle}>
                  <div className={cls.circleTitle}>
                    <div>AVG CPL</div>
                  </div>
                  <div className={cls.circleValue}>{moneyFormatter.format(cpl || 0)}</div>
                </div>
                <div className={cls.circle}>
                  <div className={cls.circleTitle}>
                    <div>Activation</div>
                  </div>
                  <div className={cls.circleValue}>{activationsTotal || 0}</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </InitialCard>
    </Container>
  );
};

const useStyles = makeStyles({
  autocompleteField: {
    width: '100%',
    '& .ant-select-selector': {
      borderColor: 'transparent',
      boxShadow: 'none !important',
      padding: 0,
      border: 'none !important',
      borderBottom: '1px solid #d9d9d9 !important',
      outline: 'none',
    },
  },
  textarea: {
    padding: '5px 10px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#E1E5ED',
    fontSize: 13,
  },
  numbers: {
    marginTop: 20,
    padding: 40,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: 50,
  },
  budget: {
    width: '100%',
    border: '5px solid #042E6B',
    borderRadius: 5,
    position: 'relative',
    minHeight: 91,
    maxWidth: 418,
  },
  budgetTitle: {
    background: '#fff',
    fontSize: 18,
    position: 'absolute',
    top: -10,
    left: -5,
    paddingRight: 9,
    paddingBottom: 2,
  },
  budgetValue: {
    width: '100%',
    lineHeight: '91px',
    fontSize: 40,
    textAlign: 'center',
  },
  numbersRow: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    gap: 10,
  },
  circle: {
    width: 134,
    height: 134,
    border: '5px solid #042E6B',
    borderRadius: '50%',
    position: 'relative',
  },
  circleTitle: {
    fontSize: 18,
    position: 'absolute',
    top: -10,
    left: 'calc(50% - 54px)',
    paddingLeft: 12,
    paddingRight: 12,
    width: 108,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      paddingLeft: 4,
      paddingRight: 4,
      background: '#fff',
    },
  },
  circleValue: {
    fontSize: 20,
    lineHeight: '128px',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: 12,
    paddingRight: 12,
  },
});
