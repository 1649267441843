import React from 'react';
import { Edit, EditProps, SimpleForm } from 'react-admin';
import { useEditProps } from '@hooks';
import { Forbidden, InitialPage } from '@components';

import { FinanceCostCategoryForm, validate } from './FinanceCostCategoryForm';
import { rbac } from '@utils';

export const FinanceCostCategoryEdit = (props: EditProps) => {
  const editProps = useEditProps();

  const accessAll = rbac.hasFinanceSettingsAll();
  const accessReadonly = !accessAll && rbac.hasFinanceSettingsReadonly();
  const hasAccess = accessAll || accessReadonly;

  if (!hasAccess) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} {...editProps} title="Individual Category">
      <InitialPage
        title="Individual Category"
        backTo="/finance-settings"
        backText="Back to Finance Settings"
      >
        <SimpleForm validate={validate} toolbar={accessReadonly ? false : undefined}>
          <FinanceCostCategoryForm readonly={accessReadonly} />
        </SimpleForm>
      </InitialPage>
    </Edit>
  );
};
