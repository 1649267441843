import { User } from '@types';
import { InitialCard, TimerWidget } from '@components';
import cls from './user-initial.module.css';

export const UserTimeTracker = ({ user }: {user: User}) => {
  return (
    <InitialCard left="User time tracker" contentClass={cls.tracker}>
      <TimerWidget
        title="Registered to Applied"
        url={`/user/${user.id}/timer/registered-to-applied`}
      />
    </InitialCard>
  );
};
