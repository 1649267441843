import { BooleanInput, Create, CreateProps, SimpleForm } from 'react-admin';
import { Row, Col, Container, TextInput, Forbidden } from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';

const Title = () => (<span>Create CreditRisk</span>);

export const CreditRiskCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} title={<Title />} {...createProps}>
      <SimpleForm>
        <Container max>
          <Row>
            <Col><TextInput source="title" label="Title" /></Col>
            <Col><BooleanInput source="isEnabled" label="Enabled" /></Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};
