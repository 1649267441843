import { Http } from './http';
import {
  CreateFinanceAccountParams,
  FinanceAccount,
  FinanceAccountOptions,
  FinanceDeposit,
  FinanceWithdrawal,
} from '@types';

class FinanceAccountHttp extends Http {
  async create(params: CreateFinanceAccountParams) {
    const res = await this.post<FinanceAccount>('/finance-account', params);

    return res.data;
  }

  async update(params: CreateFinanceAccountParams, id: number) {
    const res = await this.put<FinanceAccount>(`/finance-account/${id}`, params);

    return res.data;
  }

  async remove(id: number) {
    const res = await this.delete<FinanceAccount>(`/finance-account/${id}`);

    return res.data;
  }

  async options() {
    const res = await this.get<FinanceAccountOptions>('/finance-account/options');

    return res.data;
  }

  async findByCreditId(creditId: number) {
    const res = await this.get<FinanceDeposit | FinanceWithdrawal>(`/finance-account/credit/${creditId}`);

    return res.data;
  }

  async downloadDocuments({ fileIds, type, creditCode }: DownloadDocumentsParams) {
    await this.downloadZip(`/finance-account/download-documents/${creditCode}/${type}?fileIds=${fileIds.join(',')}`);
  }
}

export const financeAccountHttp = new FinanceAccountHttp();

interface DownloadDocumentsParams {
  fileIds: number[];
  type: 'invoice' | 'receipt';
  creditCode: string;
}
