import { LightTooltip } from '@components';
import { PropsWithChildren } from 'react';

interface Props {
  disabled?: boolean;
  hidden?: boolean;
}

export const FinanceRemoveInactive = ({
  disabled, hidden, children,
}: PropsWithChildren<Props>) => {
  if (hidden) {
    return null;
  }
  if (disabled) {
    return (
      <LightTooltip title="You can't remove the item because it's linked with the record in the database. Please make changes or remove the related records first">
        <div style={{ padding: 8 }}>
          <svg width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.5" cy="7.5" r="7.5" fill="#666666" />
            <path d="M9.38832 8.04606L10.7027 9.36236C11.0734 9.73362 11.1071 10.3749 10.7196 10.7462C10.5342 10.9149 10.2983 10.9993 10.0624 10.9993C9.82645 10.9993 9.57368 10.9149 9.38832 10.7293L8.04022 9.37924C7.7369 9.07547 7.26506 9.07547 6.96174 9.37924L5.61364 10.7293C5.42828 10.9149 5.19236 10.9993 4.93959 10.9993C4.70368 10.9993 4.46776 10.9149 4.2824 10.7462C3.89482 10.3749 3.91167 9.73362 4.29925 9.36236L5.61364 8.04606C5.91696 7.7423 5.91696 7.26979 5.61364 6.96602L4.33295 5.64973C3.96222 5.27846 3.92852 4.68782 4.2824 4.29968C4.65312 3.91154 5.25977 3.89467 5.63049 4.2828L6.97859 5.63285C7.28191 5.93661 7.75375 5.93661 8.05707 5.63285L9.40517 4.2828C9.7759 3.91154 10.3994 3.91154 10.7533 4.29968C11.1071 4.67094 11.0734 5.27846 10.7027 5.64973L9.38832 6.96602C9.10185 7.26979 9.10185 7.7423 9.38832 8.04606Z"
              fill="white" />
          </svg>
        </div>
      </LightTooltip>
    );
  }

  return (<>{children}</>);
};
