import React from 'react';
import { DateField as RaDateField, NumberField as RaNumberField } from 'react-admin';
import { isProdServer } from '@utils';

export const DateField = ((props: any) => {
  const options = props.options || {};
  options.timeZone = isProdServer() && props?.showTime
    ? 'America/Mexico_City'
    : 'Etc/UTC';

  return (
    <RaDateField locales="es-ES" {...props} options={options} />
  );
}) as typeof RaDateField;

export const NumberField = ((props: any) => {
  const options = props.options || {};

  return (
    <RaNumberField locales="es-ES" {...props} options={options} />
  );
}) as typeof RaNumberField;
