import {
  EllipsisOutlined,
  CheckOutlined,
  LockOutlined,
  MinusOutlined,
  InfoOutlined,
} from '@ant-design/icons';
import { clsx } from 'clsx';
import cls from './badge.module.css';

interface Props {
  status: 'ok' | 'info' | 'pending' | 'warn' | 'error' | 'default' | 'draft';
  label?: string;
  className?: string;
}

export const Badge = ({ status = 'default', label, className }: Props) => {
  let clsIcon = cls.defaultIcon;
  let clsText = cls.defaultText;
  let Icon = MinusOutlined;

  switch (status) {
    case 'ok':
      clsIcon = cls.okIcon;
      clsText = cls.okText;
      Icon = CheckOutlined;
      break;
    case 'info':
      clsIcon = cls.infoIcon;
      clsText = cls.infoText;
      Icon = InfoOutlined;
      break;
    case 'pending':
      clsIcon = cls.pendingIcon;
      clsText = cls.pendingText;
      Icon = InfoOutlined;
      break;
    case 'warn':
      clsIcon = cls.warnIcon;
      clsText = cls.warnText;
      Icon = EllipsisOutlined;
      break;
    case 'error':
      clsIcon = cls.errorIcon;
      clsText = cls.errorText;
      Icon = LockOutlined;
      break;
    case 'draft':
      clsIcon = cls.draftIcon;
      clsText = cls.draftText;
      Icon = InfoOutlined;
      break;
  }

  return (
    <div className={clsx(cls.box, className)}>
      <span className={clsx(cls.iconBox, clsIcon, 'badge-icon-box')}>
        <Icon className={cls.icon} />
      </span>
      <span className={clsx(cls.text, clsText, 'badge-text')}>
        {label || status}
      </span>
    </div>
  );
};
