import { FunctionField, FunctionFieldProps } from 'react-admin';
import Chip from '@mui/material/Chip';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';

import { Order, OrderDeliveryStatus } from '@types';

interface OrderDeliveryOption {
  id: OrderDeliveryStatus,
  name: string,
  color: any,
}

export const orderDeliveryOptions: OrderDeliveryOption[] = [
  { id: 'waiting', name: 'Waiting for delivery', color: grey },
  { id: 'delivery', name: 'Delivery', color: blue },
  { id: 'delivered', name: 'Delivered', color: green },
];

export const OrderDeliveryField = (props: Partial<FunctionFieldProps>) => (
  <FunctionField
    {...props}
    render={(record: Order | any) => <StatusChip status={record.deliveryStatus} />}
  />
);

const StatusChip = ({ status }: {status: string}) => {
  const statusName = orderDeliveryOptions.find(o => o.id === status)?.name;
  const color: any = orderDeliveryOptions.find(o => o.id === status)?.color;

  if (!status) {
    return null;
  }

  return (
    <Chip
      label={statusName || status}
      size="small"
      style={{ backgroundColor: color && color[500] ? color[500] : undefined, color: 'white' }}
    />
  );
};
