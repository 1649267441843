import { FunctionFieldProps, FunctionField } from 'react-admin';

import { PdfIcon, ImageZoom, ImageWithAuth, resolvePath } from '@components';
import { File } from '@types';
import cls from './file-field.module.css';

export const FileField = (props: Partial<FunctionFieldProps>) => {
  const { prefix, ...restProps } = props;

  return (
    <FunctionField
      {...restProps}
      render={(record: any) => (
        <FileWidget
          record={record}
          prefix={props?.prefix}
          file={resolvePath(record, props.source || '')}
        />
      )}
    />
  );
};

interface FileWidgetProps {
  file: File | null;
  record?: any;
  prefix?: string;
}

// PHONE
export const FileWidget = ({ file, record, prefix }: FileWidgetProps) => {
  if (!file) {
    return null;
  }

  const isPdf = file.url.includes('.pdf');
  let downloadFilename = file.url.split('/').pop() as string;
  const ext = downloadFilename.split('.').pop() as string;

  if (record?.fullName) {
    downloadFilename = `${record?.fullName}.${ext}`;
  }
  if (prefix) {
    downloadFilename = `${prefix}${downloadFilename}`;
  }

  return (
    <div className={cls.wrapper} onClick={e => e.stopPropagation()}>
      {isPdf ? (
        <div className={cls.pdfBox} title="download">
          <PdfIcon
            url={file.adminUrl}
            className={cls.pdfIcon}
            downloadFilename={downloadFilename}
          />
        </div>
      ) : (
        <ImageZoom>
          <ImageWithAuth
            url={file.adminUrl}
            className={cls.fileImg}
            width={104}
            height={60}
            downloadFilename={downloadFilename}
          />
        </ImageZoom>
      )}
    </div>
  );
};
