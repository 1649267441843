import { Create, CreateProps, SimpleForm } from 'react-admin';
import {
  Row,
  Col,
  Container,
  ReferenceInput,
  TextInput,
  SelectInput,
  Forbidden,
} from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';

export const CropCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps}>
      <SimpleForm>
        <Container max>
          <Row>
            <Col><TextInput source="nameMx" required autoFocus /></Col>
            <Col><TextInput source="nameEn" /></Col>
            <Col>
              <ReferenceInput
                reference="crop-category"
                source="categoryId"
                perPage={1000}
                sort={{ field: 'nameMx', order: 'ASC' }}
                isRequired
              >
                <SelectInput optionText="nameMx" required />
              </ReferenceInput>
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};
