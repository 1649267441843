import { clsx } from 'clsx';
import { InputNumber, Popconfirm, Select } from 'antd';
import { Button } from '@mui/material';

import { TableCropRow } from '@network';
import cls from './table-crop.module.css';

type Column = 'year' | 'incomeTotal' | 'profit';
type Row = TableCropRow;
type Header = {key: Column, label: string};

interface Props {
  data: {rows: Row[]} | null,
  onChange: any;
  isFixed?: boolean;
}

const headers: Header[] = [
  { key: 'year', label: 'Year' },
  { key: 'incomeTotal', label: 'Total' },
  { key: 'profit', label: 'Profit' },
];
const defaultData: {headers: Header[], rows: Row[]} = { headers, rows: [] };

export const TableCrop = ({ data, onChange, isFixed }: Props) => {
  const fields: Column[] = headers.map(header => header.key);

  const onFieldChange = (val: number | null | string, key: Column, index: number) => {
    if (val === null) return;
    const nextRows = (data || defaultData)
      .rows.map((row, i) => i === index ? { ...row, [key]: val } : row);
    const nextData = { ...data, rows: nextRows };
    onChange(nextData);
  };

  const addRow = () => {
    const newRow: Row = { year: null, incomeTotal: 0, profit: 0 };
    const nextData = data || defaultData;
    nextData.rows = (nextData.rows || []).concat([newRow]);
    onChange(nextData);
  };
  const removeRow = (index: number) => {
    const nextData = data || defaultData;
    nextData.rows = (nextData.rows || []).filter((_, i) => i !== index);
    onChange(nextData);
  };

  const rows = (data || defaultData).rows;
  let incomeTotal = 0;
  let profit = 0;

  for (const row of rows) {
    incomeTotal += +(row.incomeTotal || 0);
    profit += +(row.profit || 0);
  }

  const accumulatedRow: Row = {
    year: 'Accumulated',
    incomeTotal,
    profit,
  };
  const usedYears = rows.map(row => `${row.year}`).filter(Boolean) as string[];

  return (
    <div className={cls.block}>
      <div className={cls.table}>
        <div className={cls.header}>
          <div className={cls.headerRow}>
            <div className={cls.headerCol}>Income</div>
          </div>
        </div>
        <div className={cls.titles}>
          <div className={cls.row}>
            {headers.map(header => (
              <div className={cls.col} key={header.key}>{header.label}</div>
            ))}
          </div>
        </div>
        <div className={cls.body}>
          {rows.map((row, index) => (
            <div className={clsx(cls.row, cls.rowColored)} key={index}>
              {fields.map(field => (
                <div className={cls.col} key={field}>
                  <Input row={row} field={field} onChange={onFieldChange}
                    index={index} isFixed={isFixed} usedYears={usedYears} />
                </div>
              ))}
              {!isFixed && (
                <Popconfirm title="Remove row?" onConfirm={() => removeRow(index)}>
                  <Button color="secondary" variant="outlined" size="small"
                    className={cls.btnRemoveRow}>-</Button>
                </Popconfirm>
              )}
            </div>
          ))}
          <div className={clsx(cls.row, cls.rowAccumulated)}>
            <div className={cls.col}>Accumulated</div>
            <div className={cls.col}>
              <Input row={accumulatedRow} field={'incomeTotal'} isFixed={true} />
            </div>
            <div className={cls.col}>
              <Input row={accumulatedRow} field={'profit'} isFixed={true} />
            </div>
          </div>
        </div>
      </div>
      {!isFixed && (
        <Button color="secondary" variant="outlined" size="small"
          onClick={addRow} className={cls.btnAddRow}>+</Button>
      )}
    </div>
  );
};

interface InputProps {
  row: Row;
  field: Column;
  onChange?: any;
  index?: number;
  isFixed?: boolean;
  usedYears?: string[];
}

const shortYear = new Date().getFullYear() - 2000;
const yearsOptions: {value: string, label: string, disabled?: boolean}[] =
  Array.from(Array(shortYear + 1).keys()).reverse().map(year => ({
    value: (2000 + year).toString(),
    label: (2000 + year).toString(),
  }));

const Input = ({ row, field, onChange, index, isFixed, usedYears }: InputProps) => {
  const value = row[field];

  const yearsOptionsDisabled = yearsOptions.map(option => ({
    ...option,
    disabled: (usedYears || []).includes(option.value),
  }));

  return (
    field === 'year' ? (
      <Select
        defaultValue={value}
        bordered={false}
        style={{ width: 70, height: 28, color: '#042E6B', fontSize: 12 }}
        onChange={isFixed ? undefined : val => onChange(val, field, index)}
        disabled={isFixed}
        options={yearsOptionsDisabled}
        className={cls.selectInput}
      />
    ) : (
      <InputNumber
        min={0}
        max={1000000000}
        onBeforeInputCapture={e => {
          const value = e.currentTarget.value;
          if (value && value.includes('.')) {
            const [, second] = value.split('.');
            if (second.length >= 2) {
              e.stopPropagation();
              e.preventDefault();
            }
          }
        }}
        formatter={(val) => `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(val) => val ? +val.replace(/\$\s?|(,*)/g, '') : 0}
        value={value}
        bordered={false}
        onChange={val => onChange(val, field, index)}
        className={cls.input}
        disabled={isFixed}
        controls={false}
      />
    )
  );
};
