import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Form, FormInstance, Input } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { clsx } from 'clsx';

import { RiskAdminQuestion } from '@types';
import { getMaxValue, RemoveIcon, VariablesQuestions } from '@pages/risk/variables';
import cls from './VariablesSection.module.css';

interface VariablesSectionProps {
  form: FormInstance<any>;
  field: FormListFieldData;
  remove: (index: number | number[]) => void;
  setTotalPercents: Dispatch<SetStateAction<Record<number, number>>>;
  readonly?: boolean;
}

export const VariablesSection = ({
  form, field, remove, setTotalPercents, readonly,
}: VariablesSectionProps) => {
  const [_, setTotalValues] = useState<Record<number, number>>({});
  const [total, setTotal] = useState<number>(0);

  const questions = Form.useWatch(['sections', field.name, 'questions']);

  const addTotalValue = (questionIndex: number, value: number) => {
    setTotalValues(prev => ({ ...prev, [questionIndex]: value }));
  };

  useEffect(() => {
    setTotalPercents(prevState => ({
      ...prevState,
      [field.name]: total,
    }));
  }, [total]);

  useEffect(() => {
    const v = questions?.reduce(
      (sum: any, question: any) => sum + getMaxValue(question.answers), 0,
    );
    setTotal(v);
  }, [questions]);

  const onRemove = () => {
    remove(field.name);
    setTotalPercents(prevState => {
      const copy = { ...prevState };
      delete copy[field.name];

      return copy;
    });
  };

  return (
    <div className={cls._}>
      <div className={cls.head}>
        <Form.Item
          label="Variable category name:"
          name={[field.name, 'title']}
          className={cls.formItem}
          rules={[
            { required: true, message: 'Variable category name is mandatory field' },
            { max: 100, message: 'Variable category name should be less then 100 characters' },
          ]}
        >
          <Input
            className={cls.input}
            onBeforeInputCapture={readonly ? e => e.preventDefault() : undefined}
            disabled={readonly}
          />
        </Form.Item>

        <div className={cls.headActions}>
          <Button
            className={clsx(cls.remove, readonly && cls.removeReadonly)}
            disabled={readonly}
            onClick={onRemove}
          >
            <RemoveIcon />
            <span>Remove Section</span>
          </Button>
        </div>
      </div>

      <div className={cls.labels}>
        <div className={cls.col}>
          <div className={cls.colLabel}>Variable name</div>
        </div>
        <div className={cls.col}>
          <div className={cls.colLabel}>Options</div>
        </div>
        <div className={cls.col}>
          <div className={cls.colLabel}>Max score per variable</div>
        </div>
      </div>

      <Form.Item>
        <Form.List name={[field.name, 'questions']}>
          {(subFields, operation) => {
            return (
              <VariablesQuestions
                form={form}
                index={field.name}
                addTotalValue={addTotalValue}
                fields={subFields}
                operation={operation}
                readonly={readonly}
              />
            );
          }}
        </Form.List>
      </Form.Item>

      <div className={cls.total}>
        <div className={cls.totalLabel}>Total score</div>
        <div className={cls.totalValue}>{total}%</div>
      </div>
    </div>
  );
};

export interface IVariablesSection {
  id: number;
  title: string;
  questions: RiskAdminQuestion[];
}
