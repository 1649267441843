import { PropsWithChildren, createContext, useContext, useState, useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment-timezone';

import { Admin, ChatMessage } from '@types';
import { adminHttp, chatHttp, UnreadedResonse, UpdateAdminParams } from '@network';
import { isProdServer } from '@utils';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(isProdServer() ? 'America/Mexico_City' : 'Etc/UTC');
moment.tz.setDefault(isProdServer() ? 'America/Mexico_City' : 'Etc/UTC');

interface SettingsState {
  settingsOpened: boolean;
  setSettingsOpened: (isOpened: boolean) => void;
  reportsOpened: boolean;
  setReportsOpened: (isOpened: boolean) => void;
  ticketOpened: boolean;
  setTicketOpened: (isOpened: boolean) => void;
  admin: Admin | null,
  setAdmin: (nextAdmin: Admin | null) => void;
  refreshAdmin: () => Promise<void>;
  updateAdmin: (params: UpdateAdminParams) => Promise<void>;
  isToolbarShown: boolean;
  setIsToolbarShown: (isShown: boolean) => void;
  isCropCreatorActive: boolean,
  setIsCropCreatorActive: (isOpened: boolean) => void;
  isBuyersCreatorActive: boolean;
  setIsBuyersCreatorActive: (isOpened: boolean) => void;
  unreaded: UnreadedResonse;
  refreshUnreaded: () => Promise<void>;
  lastMessage: ChatMessage | undefined;
  panelName: string | null;
  setPanelName: (panelName: string | null) => void;
  websocketConnect: () => void;
}

const defaultState: SettingsState = {
  settingsOpened: false,
  setSettingsOpened: () => {},
  reportsOpened: false,
  setReportsOpened: () => {},
  ticketOpened: false,
  setTicketOpened: () => {},
  admin: null,
  setAdmin: () => {},
  refreshAdmin: async () => {},
  updateAdmin: async () => {},
  isToolbarShown: true,
  setIsToolbarShown: () => {},
  isCropCreatorActive: false,
  setIsCropCreatorActive: () => {},
  isBuyersCreatorActive: false,
  setIsBuyersCreatorActive: () => {},
  unreaded: null,
  lastMessage: undefined,
  refreshUnreaded: async () => {},
  panelName: null,
  setPanelName: () => {},
  websocketConnect: () => {},
};

export const SettingsContext = createContext<SettingsState>(defaultState);
const socketUrl = process.env.REACT_APP_SOCKET || 'localhost:5775';

export const SettingsProvider = ({ children }: PropsWithChildren<any>) => {
  const socket = useRef<Socket>();
  const [admin, setAdmin] = useState<Admin | null>(defaultState.admin);
  const [settingsOpened, setSettingsOpened] = useState<boolean>(false);
  const [reportsOpened, setReportsOpened] = useState<boolean>(false);
  const [ticketOpened, setTicketOpened] = useState<boolean>(false);
  const [isToolbarShown, setIsToolbarShown] = useState<boolean>(defaultState.isToolbarShown);
  const [
    isCropCreatorActive, setIsCropCreatorActive,
  ] = useState<boolean>(defaultState.isCropCreatorActive);
  const [
    isBuyersCreatorActive, setIsBuyersCreatorActive,
  ] = useState<boolean>(defaultState.isBuyersCreatorActive);
  const [unreaded, setUnreaded] = useState<UnreadedResonse>(null);
  const [lastMessage, setLastMessage] = useState<ChatMessage>();
  const [panelName, setPanelName] = useState<string | null>(null);

  function websocketConnect() {
    if (socket.current && socket.current.connected) {
      return;
    }
    socket.current = io(socketUrl, { autoConnect: true, transports: ['websocket'] });
    console.log(`[WEBSOCKET] attempts to connect @ ${socketUrl}`);
    socket.current.on('connect', () => {
      console.log(`[WEBSOCKET] is connected @ ${socketUrl}`);
      socket.current && socket.current.on('chatMessage', (message: ChatMessage) => {
        console.log('[WEBSOCKET] chatMessage', message);
        refreshUnreaded();
        setLastMessage(message);
      });
    });
  }

  useEffect(() => {
    if (!admin) {
      refreshAdmin();
      refreshUnreaded();
      return;
    } else if (admin && admin.isSalesRisk) {
      websocketConnect();
    }

    return () => {
      socket.current?.disconnect();
    };
  }, [admin]);

  const refreshAdmin = async () => {
    try {
      const nextAdmin = await adminHttp.me();
      setAdmin(nextAdmin);
    } catch (e) {}
  };
  const updateAdmin = async (params: UpdateAdminParams) => {
    try {
      const nextAdmin = await adminHttp.updateMe(params);
      setAdmin(nextAdmin);
    } catch (e) {}
  };
  const refreshUnreaded = async () => {
    try {
      const nextUnreaded = await chatHttp.getUnreaded();
      setUnreaded(nextUnreaded);
    } catch (e) {}
  };

  return (
    <SettingsContext.Provider value={{
      settingsOpened,
      setSettingsOpened,
      reportsOpened,
      setReportsOpened,
      ticketOpened,
      setTicketOpened,
      admin,
      setAdmin,
      refreshAdmin,
      updateAdmin,
      isToolbarShown,
      setIsToolbarShown,
      isCropCreatorActive,
      setIsCropCreatorActive,
      isBuyersCreatorActive,
      setIsBuyersCreatorActive,
      unreaded,
      lastMessage,
      refreshUnreaded,
      panelName,
      setPanelName,
      websocketConnect,
    }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => useContext(SettingsContext);
