import { Http } from './http';

class SmsHttp extends Http {

  async sendTestSms({ message, phones }: any) {
    const res = await this.post('/sms/test', { message, phones });

    return res.data;
  }
}

export const smsHttp = new SmsHttp();
