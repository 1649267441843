import { clsx } from 'clsx';
import cls from './order-status.module.css';

interface OrderStatusProps {
  status: {
    label: string;
    value: string;
  };
  isMinimal?: boolean;
}

const statusOptions: Record<string, string> = {
  'confirmed': '#9c27b0',
  'waiting_for_payment': '#39A1FF',
  'cancelled': '#FF4D4F',
  'paid': '#52C41A',
  'docs_sent': '#0C5541',
  'renewed': '#042E6B',
  'waiting_for_delivery': '#9E9E9E',
  'delivery': '#39A1FF',
  'delivered': '#52C41A',
  'registered': '#39A1FF',
};

export const OrderStatus = ({ status, isMinimal }: OrderStatusProps) => {
  return (
    <div className={clsx(cls._, isMinimal && cls.isMinimal)} style={{ backgroundColor: statusOptions[status.value] || '#9E9E9E' }}>{status.label}</div>
  );
};
