import { useEffect, useState } from 'react';
import { financeSettingsHttp } from '@network/finance-settings-http';

interface ActivationOption {
  id: number;
  name: string;
}

export const useLoadActivationOptions = () => {
  const [options, setOptions] = useState<ActivationOption[]>([]);

  useEffect(() => {
    (async () => {
      const nextActivations = await financeSettingsHttp.getActivations();
      const nextOptions: ActivationOption[] = nextActivations.map(activation => {
        return { id: activation.id, name: activation.activation };
      });
      setOptions(nextOptions);
    })();
  }, []);

  return options;
};
