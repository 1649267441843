import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { SaveFilled } from '@ant-design/icons';
import { EditProps, Edit, SimpleForm, TopToolbar, SaveButton, DeleteButton } from 'react-admin';

import {
  Container,
  TextInput,
  EditorInput,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  Forbidden,
  PageHeader,
  FormRow,
  SwitchInput,
} from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';
import cls from './WebinarSignupEdit.module.css';

const Title = ({ record }: { record?: any }) =>
  record ? (<span>Webinar Signup: {record.fullName}</span>) : null;

export const WebinarSignupEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();
  const editorToolbar = 'undo redo formatselect '
    + 'bold italic backcolor bullist numlist outdent indent table '
    + 'removeformat code help '
    + 'alignleft aligncenter alignright alignjustify ';
  const mainColGrid = {
    xs: { title: 24, children: 24 },
    lg: { title: 6, children: 18 },
    xl: { title: 7, children: 13 },
    xxl: { title: 5, children: 15 },
  };

  if (!rbacView) {
    return <Forbidden/>;
  }

  const Actions = () => {
    return (
      <TopToolbar className={cls.actions}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon}/>}
          label="Save"
          type="button"
          className={clsx(cls.action, cls.actionSave)}
          variant="outlined"
          alwaysEnable
        />
        <DeleteButton className={clsx(cls.action, cls.actionDelete)}/>
      </TopToolbar>
    );
  };

  const validate = (values: any) => {
    const errors: any = {};

    ['firstName', 'lastName', 'company', 'phone', 'email', 'middleName'].forEach((key: string) => {
      if (!values[key] && key !== 'middleName') {
        errors[key] = 'This field is required';
      } else if (values[key]?.length > 30) {
        errors[key] = 'Max length is 30 characters';
      }
    });

    return errors;
  };

  return (
    <Edit {...props} title={<Title/>} {...editProps} className={clsx(cls.page, 'details-page-wrapper')}>
      <>
        <PageHeader
          title="Identity user in webinar signups"
          linkText="Back to webinar signups"
          linkUrl="/webinar-signup"
        />

        <SimpleForm
          toolbar={rbacEdit ? <Actions/> : false}
          className={cls.form}
          validate={validate}
        >
          <Container max>
            <Row gutter={24} className={cls.wrapper}>
              <Col xs={24} className={cls.wrapperCol}>
                <div className={clsx(cls.block, cls.blockContent)}>
                  <Row gutter={24} className={cls.inner}>
                    <Col xs={24} xl={12} className={clsx(cls.innerCol, cls.innerColFirst)}>
                      <div className={cls.blockHeader}>User</div>
                      <div className={cls.blockMiddle}>
                        <div className={cls.blockMiddleIcon}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
                            <path
                              d="M27.5 0C12.3161 0 0 12.3161 0 27.5C0 42.6839 12.3161 55 27.5 55C42.6839 55 55 42.6839 55 27.5C55 12.3161 42.6839 0 27.5 0ZM27.5 13.75C33.6875 13.75 38.7161 18.7786 38.7161 24.9661C38.7161 31.1536 33.6875 36.1821 27.5 36.1821C21.3125 36.1821 16.2839 31.1536 16.2839 24.9661C16.2839 18.7786 21.3125 13.75 27.5 13.75ZM27.5 51.4643C21.9214 51.4643 16.775 49.5393 12.6893 46.3179C14.1429 44.2357 16.5393 42.9786 19.0929 42.9786H35.9071C38.4607 42.9786 40.8571 44.2357 42.3107 46.3179C38.225 49.5393 33.0786 51.4643 27.5 51.4643Z"
                              fill="#042E6B"/>
                          </svg>
                        </div>
                        <div className={cls.blockMiddleInfo}>
                          <FormRow
                            title="First name:"
                            width={mainColGrid}
                            rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                            colTitleClassName={cls.formLabelCol}
                            colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                          >
                            <TextInput name="firstName" source="firstName" required autoFocus label={false}/>
                          </FormRow>
                          <FormRow
                            title="Middle name:"
                            width={mainColGrid}
                            rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                            colTitleClassName={cls.formLabelCol}
                            colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                          >
                            <TextInput name="middleName" source="middleName" label={false}/>
                          </FormRow>
                          <FormRow
                            title="Last name:"
                            width={mainColGrid}
                            rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                            colTitleClassName={cls.formLabelCol}
                            colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                          >
                            <TextInput name="lastName" source="lastName" required label={false}/>
                          </FormRow>
                          <FormRow
                            title="Company:"
                            width={mainColGrid}
                            rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                            colTitleClassName={cls.formLabelCol}
                            colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                          >
                            <TextInput name="company" source="company" required label={false}/>
                          </FormRow>
                          <FormRow
                            title="Webinar:"
                            width={mainColGrid}
                            rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                            colTitleClassName={cls.formLabelCol}
                            colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                          >
                            <ReferenceInput
                              name="webinarId"
                              source="webinarId"
                              reference="webinar-list"
                              alwaysOn
                              perPage={1000}
                              sort={{ field: 'titleMx', order: 'ASC' }}
                            >
                              <SelectInput optionText="titleMx" label={false}/>
                            </ReferenceInput>
                          </FormRow>
                          <FormRow
                            title="Phone:"
                            width={mainColGrid}
                            rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                            colTitleClassName={cls.formLabelCol}
                            colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                          >
                            <TextInput name="phone" source="phone" required label={false} type="tel"/>
                          </FormRow>
                          <FormRow
                            title="Email:"
                            width={mainColGrid}
                            rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                            colTitleClassName={cls.formLabelCol}
                            colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                          >
                            <TextInput name="email" source="email" required label={false} type="email"/>
                          </FormRow>
                          <FormRow
                            title="Created at:"
                            width={mainColGrid}
                            rowClassName={clsx(cls.formRow, cls.linkDateRow)}
                            colTitleClassName={clsx(cls.formLabelCol, cls.linkDateLabel)}
                            colChildrenClassName={clsx(cls.formValueCol, cls.linkDateValue)}
                          >
                            <DateTimeInput name="createdAt" source="createdAt" disabled label={false}/>
                          </FormRow>
                          <FormRow
                            title="Visited at:"
                            width={mainColGrid}
                            rowClassName={clsx(cls.formRow, cls.linkDateRow)}
                            colTitleClassName={clsx(cls.formLabelCol, cls.linkDateLabel)}
                            colChildrenClassName={clsx(cls.formValueCol, cls.linkDateValue)}
                          >
                            <DateTimeInput name="visitedAt" source="visitedAt" disabled label={false}/>
                          </FormRow>
                          <FormRow
                            title="Visited"
                            width={mainColGrid}
                            rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                            colTitleClassName={cls.formLabelCol}
                            colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                          >
                            <SwitchInput name="visited" source="visited" label={false} disabled className={cls.switcherField}/>
                          </FormRow>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} xl={12} className={clsx(cls.innerCol, cls.innerColLast)}>
                      <div className={cls.blockHeader}>Questions for the speaker</div>
                      <FormRow
                        fullWidth
                        rowClassName={clsx(cls.formRow, cls.messageRow)}
                        colTitleClassName={clsx(cls.formLabelCol, cls.formTitleCol)}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formValueColTextarea)}
                      >
                        <EditorInput
                          source="questions"
                          label={false}
                          toolbar={editorToolbar}
                          height={350}
                          className={cls.editor}
                        />
                      </FormRow>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </SimpleForm>
      </>
    </Edit>
  );
};
