import { clsx } from 'clsx';
import { Dropdown, Input } from 'antd';
import { ChangeEvent, DragEvent, useEffect, useState } from 'react';

import { BlueOutlineButton } from '@components';
import { QuestionItemType, RiskAdminAnswer } from '@types';
import { CloseCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import cls from './Question.module.css';
import { read } from '@amcharts/amcharts5/.internal/bundled/xlsx';

interface Props {
  item: QuestionItemType;
  className?: string;
  onDragStart: (e: DragEvent<HTMLDivElement>, item: QuestionItemType) => void;
  onDragEnd: (e: DragEvent<HTMLDivElement>) => void;
  onDragOver: (e: DragEvent<HTMLDivElement>, item: QuestionItemType) => void;
  onDrop: (e: DragEvent<HTMLDivElement>, item: QuestionItemType) => void;
  onChange: (item: QuestionItemType) => void;
  onDelete: (item: QuestionItemType) => void;
  readonly?: boolean;
}

interface MenuItemProps extends RiskAdminAnswer {
  idx: string;
}

interface OptionsMenuProps {
  options: RiskAdminAnswer[] | undefined;
  onChange: (answers: MenuItemProps[]) => void;
  readonly?: boolean;
}

const OptionsMenu = ({ options, onChange, readonly }: OptionsMenuProps) => {
  const [answers, setAnswers] = useState<MenuItemProps[]>([]);

  const addOption = () => {
    if (readonly) return;
    const idx = Date.now().toString();
    const item: MenuItemProps = { idx, title: '' };
    setAnswers([...answers, item]);
  };

  const onDeleteOption = (item: MenuItemProps) => {
    if (readonly) return;
    setAnswers(answers.filter(i => i.idx !== item.idx));
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>, item: MenuItemProps) => {
    if (readonly) return;
    const newOption: MenuItemProps = { ...item, title: e.target.value.trimStart() };
    setAnswers(answers.map(i => i.idx === item.idx ? newOption : i));
  };

  const hasEmptyOptions = (): boolean => !!answers.filter(a => !a.title?.trim()).length;

  const validation = (): boolean => {
    return answers.length > 1 && !hasEmptyOptions();
  };

  useEffect(() => {
    onChange(answers);
  }, [answers]);

  useEffect(() => {
    options && options.length && setAnswers(options as MenuItemProps[]);
  }, []);

  const showAddBtn = answers.length < 10 && !readonly;

  const dropdownRender = (_: any) => {
    return (
      <div className={cls.ddMenu}>
        {answers.map((item) => (
          <div className={cls.menuItem} key={item.idx}>
            <span className={cls.optionTitle}>
              <Input
                placeholder="Option title"
                bordered={false}
                value={item.title}
                onChange={e => onInputChange(e, item)}
                className={cls.optionField}
                status={!item.title.trim() ? 'error' : undefined}
                required
                maxLength={100}
                disabled={readonly}
              />
            </span>
            {!readonly ? (
              <CloseCircleFilled
                className={cls.deleteIcon}
                onClick={() => onDeleteOption(item)}
              />
            ) : null}
          </div>
        ))}

        {showAddBtn ? (
          <div
            className={clsx(cls.menuItem, cls.addMenuItem, hasEmptyOptions() && cls.disabled)}
            onClick={hasEmptyOptions() ? undefined : addOption}
          >
            <span className={cls.optionTitle}>Option {answers.length + 1}</span>
            <PlusCircleFilled className={cls.optionIcon} />
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className={cls.ddWrap}>
      <Dropdown className={cls.dd}
        trigger={['click']}
        dropdownRender={dropdownRender}
        placement="bottomRight">
        <BlueOutlineButton
          className={clsx(cls.ddBtn, !validation() && cls.error)}
          disabled={readonly}
        >Edit options</BlueOutlineButton>
      </Dropdown>
    </div>
  );
};

export const Question = ({
  item, onDragStart, onDragEnd, onDragOver, onDrop, onChange, onDelete, className, readonly,
}: Props) => {
  const [draggable, setDraggable] = useState<boolean>(false);
  const onInputChange = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    const newItem: QuestionItemType = { ...item, [field]: e.target.value };
    onChange(newItem);
  };

  const onOptionsChange = (answers: MenuItemProps[]) => {
    const newItem: QuestionItemType = { ...item, answers };
    onChange(newItem);
  };

  return (
    <div
      key={item.position}
      draggable={draggable && !readonly}
      onDragStart={(e) => onDragStart(e, item)}
      onDragLeave={onDragEnd}
      onDragEnd={onDragEnd}
      onDragOver={(e) => onDragOver(e, item)}
      onDrop={(e) => onDrop(e, item)}
      className={clsx(cls.card, className)}
    >
      <div className={cls.row}>
        <div className={cls.col}>
          <Input
            placeholder="Title"
            bordered={false}
            value={item.title}
            onChange={(e) => onInputChange(e, 'title')}
            className={clsx(cls.textField, item.isFixed && cls.fixed, cls.titleField)}
            disabled={item.isFixed || readonly}
            status={!item.title.trim() ? 'error' : undefined}
            required
            maxLength={100}
            onBeforeInputCapture={readonly ? e => e.preventDefault() : undefined}
          />
        </div>
        <div className={cls.col}>
          <OptionsMenu options={item.answers} onChange={onOptionsChange} readonly={readonly} />
          <div
            className={cls.decor}
            onMouseEnter={() => setDraggable(true)}
            onMouseLeave={() => setDraggable(false)}
          >
            <span className={cls.decorPoint} />
            <span className={cls.decorPoint} />
            <span className={cls.decorPoint} />
          </div>
        </div>
        <div className={cls.col}>
          <div className={cls.actions}>
            {!readonly ? (
              <div className={cls.deleteItem}>
                <CloseCircleFilled
                  className={cls.deleteIcon}
                  onClick={() => onDelete(item)}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
