import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

import { ContactUsData, reportsHttp } from '@network';
import { InitialCard } from '@components';
import { querySerialize } from '@utils';
import { LinkBlockBtn } from '@pages/dashboard/LinkBlockBtn';

interface Props {
  range: string;
  setLoader: (num: number, value: boolean) => void;
}

export const ContactUsReport = ({ range, setLoader }: Props) => {
  const [numbers, setNumbers] = useState<ContactUsData>();
  const cls = useStyles();

  const loadNumbers = async () => {
    setLoader(12, true);
    try {
      const nextNumbers = await reportsHttp.contactUsReport({ range });
      setNumbers(nextNumbers);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(12, false);
    }
  };

  useEffect(() => void loadNumbers(), [range]);

  const openedLink = '/feedback/contact?' + querySerialize({
    filter: JSON.stringify({
      status: 'opened',
      createdAt: range || undefined,
    }),
  });
  const processingLink = '/feedback/contact?' + querySerialize({
    filter: JSON.stringify({
      status: 'processing',
      createdAt: range || undefined,
    }),
  });

  const blocks: {title: string, link: string, total: number, className: string}[] = [
    {
      title: 'New',
      total: numbers?.opened || 0,
      link: openedLink,
      className: cls.btnOpened,
    },
    {
      title: 'Processing',
      total: numbers?.processing || 0,
      link: processingLink,
      className: cls.btnProcessing,
    },
  ];

  return (
    <InitialCard center="Contact us entries" extraPadding fullHeight>
      {blocks.map(block => (
        <div key={block.title} className={cls.block}>
          <LinkBlockBtn key={block.title} whiteColor {...block} />
        </div>
      ))}
    </InitialCard>
  );
};

const useStyles = makeStyles({
  block: {
    marginTop: 20,
  },
  btnOpened: {
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#1677FF',
    '&:hover': {
      background: '#125dc7',
    },
  },
  btnProcessing: {
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#FAAD14',
    '&:hover': {
      background: '#be8410',
    },
  },
});
