import { makeStyles } from '@mui/styles';

import { Credit } from '@types';
import { InitialCard, OrangeOutlineButton, TextInput, UrlInput } from '@components';

export const CreditDocumentsLink = ({ credit }: {credit: Credit}) => {
  const cls = useStyles();

  return (
    <InitialCard autoHeight extraPadding>
      <div className={cls.box}>
        <div className={cls.left}>
          <div>Documents link (Google Drive):</div>
        </div>
        <div className={cls.right}>
          {credit.docsLink ? (
            <a href={credit.docsLink} target="_blank" rel="noreferrer">
              <OrangeOutlineButton>Folder</OrangeOutlineButton>
            </a>
          ) : (<OrangeOutlineButton disabled>Folder</OrangeOutlineButton>)}
        </div>
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    fontFamily: 'Outfit, sans-serif',
    fontSize: 15,
    fontWeight: 500,
    color: '#272727',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  left: {
    flex: 1,
  },
  right: {
    paddingLeft: 20,
  },
  input: {
    '& input': {
      fontSize: 13,
    },
    '& .MuiFormHelperText-root': {
      lineHeight: 0,
    },
  },
});
