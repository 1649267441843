import { FunctionField, FunctionFieldProps } from 'react-admin';
import { makeStyles } from '@mui/styles';

export const DeliveryArrayTextField = (props: Partial<FunctionFieldProps>) => {
  return (
    <FunctionField
      sortable={false}
      {...props}
      render={(record: any) => {
        const data: string[] = props.source ? record[props.source] : [];

        return (
          <DeliveryArrayText
            data={data}
          />
        );}}
    />
  );
};

export const DeliveryArrayText = ({ data }: {data: string[]}) => {
  const uniqData = Array.from(new Set(data));
  const cls = useStyles();

  if (!uniqData || !Array.isArray(uniqData) || !uniqData.length) {
    return null;
  }

  return (
    <>
      {uniqData.map((item: string, key: number) => (
        <span key={key}>
          <span className={cls.item}>{item}</span>{(key + 1) < uniqData.length ? ', ' : ''}
        </span>
      ))}
    </>
  );
};

const useStyles = makeStyles({
  item: {
    whiteSpace: 'nowrap',
    letterSpacing: -.8,
  },
});
