import { useState } from 'react';
import { makeStyles } from '@mui/styles';

import { Credit } from '@types';
import { InitialCard, OrangeOutlineButton, Scroll } from '@components';
import { dateFormatter } from '@utils';

export const CreditHistory = ({ credit }: {credit: Credit}) => {
  const cls = useStyles();
  const [showAll, setShowAll] = useState(false);
  const allItems = (credit.history || []).sort((a, b) => b.id - a.id);
  const showItems = allItems.slice(0, showAll ? undefined : 4);

  const showMoreBtn = !showAll && allItems.length > showItems.length;

  return (
    <InitialCard left="History" autoHeight extraPadding minHeight={370}>
      <div className={cls.box}>
        <Scroll height={showAll ? 284 : showItems.length * 74}>
          {showItems.map(history => (
            <div className={cls.item} key={history.id}>
              <div className={cls.header}>
                <div className={cls.by}>by {
                  history.title.includes('[AUTO]') ? '[auto]'
                    : history.managerName ? `[manager B2B] ${history.managerName}`
                      : history.adminName ? `[admin] ${history.adminName}`
                        : `[user] ${credit.user.fullName}`
                }</div>
                <div>{dateFormatter.toDateTime(history.createdAt)}</div>
              </div>
              <div className={cls.footer}>
                <div>{history.title}</div>
                <div>{history.statusBefore || null} → {history.statusAfter || null}</div>
              </div>
            </div>
          ))}

          {showMoreBtn ? (
            <OrangeOutlineButton onClick={() => setShowAll(true)}>
              Show Previous ({allItems.length - showItems.length})
            </OrangeOutlineButton>
          ) : null}
        </Scroll>
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    marginTop: 15,
    fontFamily: 'Outfit, sans-serif',
    color: '#272727',
  },
  item: {
    marginBottom: 18,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#002A77',
    fontSize: 12,
    fontWeight: 400,
    borderBottom: '1px solid rgba(4, 46, 107, 0.50)',
    paddingBottom: 6,
  },
  by: {},
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#000',
    fontSize: 13,
    fontWeight: 400,
  },
  showMoreBtn: {
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 8,
    textTransform: 'none',
    color: '#FD8A32',
    fontWeight: 400,
  },
});
