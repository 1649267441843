import { clsx } from 'clsx';
import { Col, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { FunctionField, Link, SaveButton, useRedirect } from 'react-admin';

import { ticketHttp } from '@network';
import { dateFormatter } from '@utils';
import { SupportTicket } from '@types';
import { Button } from '@mui/material';
import { SupportFiles } from '../../components';
import { ConfirmDelete, Container, EditorInput, InitialCard, InitialRow, SelectInput, TextInput } from '@components';
import cls from './TicketForm.module.css';

export const validate = (values: any) => {
  const reqList = ['answer'];
  const errors: any = {};

  reqList.forEach((key: string) => {
    if (!values[key]) {
      errors[key] = 'This field is required';
    }
  });

  return errors;
};

const statusOptions: { id: string; name: string }[] = [
  { id: 'pending', name: 'Pending' },
  { id: 'work_in_progress', name: 'Work in progress' },
  { id: 'solved', name: 'Solved' },
];

export const TicketForm = () => {
  const redirect = useRedirect();
  const [send, setSend] = useState<boolean>(false);
  const id = useWatch({ name: 'id' });
  const admin = useWatch({ name: 'admin' });
  const createdAt = useWatch({ name: 'createdAt' });
  const { field: { onChange: setFileIds }, fieldState: { error } } = useController({ name: 'fileIds' });
  const { field: { value: documents } } = useController({ name: 'documents' });
  const { field: { value: answerDocuments, onChange: setAnswerDocuments } } = useController({ name: 'answerDocuments' });
  const { fieldState: { error: answerError } } = useController({ name: 'answer' });
  const editorToolbar = 'undo redo formatselect bold italic backcolor bullist numlist outdent indent table ' +
    'removeformat code help alignleft aligncenter alignright alignjustify ';

  const sendEmail = async () => {
    try {
      await ticketHttp.sendEmail(id);
      message.success('Email sent');
      setSend(true);
    } catch (e) {
      message.error('Email was not sent');
    }
  };

  useEffect(() => {
    if (id && answerDocuments?.length) {
      setFileIds([...answerDocuments.map(d => d.file.id)]);
    }
  }, [id, answerDocuments]);

  return (
    <Container max>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <InitialCard
            extraPadding
            left="Issue"
            right={<span className={cls.subTitle}>
              {admin?.name ? `Created by ${admin.name} ` : ''}{dateFormatter.toDateSlash(createdAt) + ' ' + dateFormatter.toTime(createdAt)}
            </span>}
          >
            <Row gutter={{ xs: 24, lg: 24, xl: 40 }}>
              <Col xs={24} xl={12}>
                <Row gutter={{ xs: 24, lg: 24, xl: 40 }}>
                  <Col xs={24} md={8} xl={8}>
                    <InitialRow
                      title="ID:"
                      fontLg
                      labelGrid={{ xs: 5, md: 6 }}
                      className={clsx(cls.fieldRow, cls.txtField, cls.disabled)}
                    >
                      <TextInput source="id" disabled />
                    </InitialRow>
                  </Col>
                  <Col xs={24} md={16} xl={16}>
                    <InitialRow
                      title="Platform:"
                      labelGrid={{ xs: 5, md: 6, xl: 8 }}
                      fontLg
                      className={clsx(cls.fieldRow, cls.textField, cls.disabled)}
                    >
                      <TextInput source="platform" disabled />
                    </InitialRow>
                  </Col>
                </Row>
                <InitialRow
                  title="Issue Description:"
                  fontLg
                  fullWidth
                  valueGrid={{ xs: 24, sm: 24 }}
                  className={clsx(cls.fieldRow, cls.descField)}
                >
                  <TextInput source="issue" multiline disabled/>
                </InitialRow>
                <InitialRow
                  title="URL’s:"
                  fontLg
                  fullWidth
                  valueGrid={{ xs: 24, sm: 24 }}
                  className={clsx(cls.fieldRow, cls.linksField)}
                >
                  <FunctionField resource="support-ticket" source="links" render={(record: SupportTicket) => {
                    return record.links.length ? record.links.map((link: string, idx: number) => (
                      <span key={idx}>
                        <a
                          href={link.includes('https://') ? link : `https://${link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={cls.link}
                        >
                          {link.replace('https://', '').replace('http://', '').replace('www.', '')}
                        </a>
                        {idx + 1 !== record.links.length ? ', ' : ''}
                      </span>
                    )) : <></>;
                  }}/>
                </InitialRow>
                <InitialRow
                  title="Status of issue:"
                  fontLg
                  labelGrid={{ xs: 8, sm: 8 }}
                  valueGrid={{ xs: 16, sm: 16 }}
                  className={clsx(cls.fieldRow, cls.selectField)}
                >
                  <SelectInput source="status" choices={statusOptions} required label="" />
                </InitialRow>
                <InitialRow title="Answer*:" fontLg fullWidth className={clsx(cls.fieldRow, cls.textareaField)}>
                  <EditorInput
                    source="answer"
                    label={false}
                    toolbar={editorToolbar}
                    height={250}
                    maxCount={500}
                    className={clsx(cls.editor, answerError && cls.error)}
                  />
                </InitialRow>
              </Col>
              <Col xs={24} xl={12}>
                <InitialRow
                  title=""
                  fontLg
                  fullWidth
                  className={clsx(cls.fieldRow, cls.fileField, cls.issueFilesField)}
                >
                  {documents?.length ? <SupportFiles
                    files={documents}
                    setFiles={() => {}}
                    setDocuments={() => {}}
                    className={clsx(cls.files, cls.issueFiles)}
                    previewOnly
                  /> : <div className={cls.emptyFiles}>No files</div>}
                </InitialRow>
                <InitialRow
                  title="Upload visual aid"
                  fontLg
                  fullWidth
                  className={clsx(cls.fieldRow, cls.fileField, cls.answerFilesField)}
                >
                  <SupportFiles
                    files={answerDocuments}
                    setFiles={setFileIds}
                    setDocuments={setAnswerDocuments}
                    error={error}
                    className={clsx(cls.files, cls.answerFiles)}
                    maxFiles={3}
                  />
                </InitialRow>
              </Col>
            </Row>
          </InitialCard>
        </Col>
      </Row>

      <div className={cls.toolbar}>
        <div className={cls.topBtns}>
          <ConfirmDelete title="Are you sure?" cb={() => redirect('/support-ticket/')}>
            <Button variant="outlined" color="error" className={clsx(cls.btn, cls.cancelBtn)}>Cancel</Button>
          </ConfirmDelete>
          <Link to={`/support-ticket/${id}?view`}>
            <Button variant="outlined" color="secondary" className={clsx(cls.btn, cls.viewBtn)}>View</Button>
          </Link>
        </div>
        <div className={cls.bottomBtns}>
          <SaveButton label="Save" type="button" className={clsx(cls.btn, cls.saveBtn)} onClick={() => setSend(false)}/>
          <Button
            variant="contained"
            color="secondary"
            className={clsx(cls.btn, cls.sendBtn, send && cls.disabled)}
            onClick={sendEmail}
            disabled={send}
          >Send</Button>
        </div>
      </div>
    </Container>
  );
};
