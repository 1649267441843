import { clsx } from 'clsx';
import moment from 'moment-timezone';
import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { CreateProps, Create, useNotify, useRedirect } from 'react-admin';

import { axiosErrorToString } from '@utils';
import { financeWithdrawalHttp } from '@network';
import { useCreateProps, useQuery, useRbacCreate } from '@hooks';
import { Container, Forbidden, Loader, PageHeader } from '@components';
import { CreateFinanceWithdrawalParams, FinanceWithdrawalOptions } from '@types';
import { FinanceWithdrawalForm } from '../FinanceWithdrawalForm';
import cls from './finance-withdrawal-create.module.css';

export const FinanceWithdrawalCreate = (props: CreateProps) => {
  const query = useQuery();
  const notify = useNotify();
  const redirect = useRedirect();
  const rbacCreate = useRbacCreate();
  const createProps = useCreateProps();
  const [options, setOptions] = useState<FinanceWithdrawalOptions | null>(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<CreateFinanceWithdrawalParams>();
  const [submitted, setSubmitted] = useState(false);
  const [clientName, setClientName] = useState('');
  const accountId = query.get('id');
  const formValues: CreateFinanceWithdrawalParams = {
    concept: '',
    accountId: accountId && options?.accounts.find(a => a.id === +accountId) ? +accountId : null,
    creditId: null,
    amount: 0,
    paymentDate: moment(new Date()).format('DD-MM-YYYY'),
    purpose: '',
    details: null,
    sent: false,
    received: false,
  };

  const getOptions = async () => {
    try {
      const ops: FinanceWithdrawalOptions = await financeWithdrawalHttp.options();

      if (!ops) {
        throw new Error('The list of options has not been received');
      }
      setOptions(ops);
    } catch (e) {
      console.error(e);
    }
  };

  const submit = async (params: CreateFinanceWithdrawalParams) => {
    if (loading) return;
    try {
      setLoading(true);
      const newWithdrawal = await financeWithdrawalHttp.create(params);

      form.resetFields();
      setClientName('');
      setSubmitted(false);

      notify('Withdrawal has been created');
      redirect(`/finance-withdrawal/${newWithdrawal.id}`);
    } catch (e) {
      // @ts-ignore
      notify(axiosErrorToString(e), { type: 'error' });
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getOptions();
    })();
  }, []);

  if (!options) {
    return <Loader center />;
  }

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps} className={clsx(cls.page, 'details-page-wrapper')}>
      <PageHeader
        title="Withdrawal"
        linkText="Back to all withdrawals"
        linkUrl="/finance-withdrawal"
      />

      <Container max>
        <Row gutter={24} className={cls.wrapper}>
          <Col xs={24} className={cls.wrapperCol}>
            <div className={cls.block}>
              <div className={cls.blockHeader}>Complete information</div>
              <Form
                form={form}
                layout="horizontal"
                initialValues={formValues}
              >
                <FinanceWithdrawalForm
                  loading={loading}
                  setLoading={setLoading}
                  initial={formValues}
                  options={options}
                  onSubmit={submit}
                  form={form}
                  clientName={clientName}
                  setClientName={setClientName}
                  submitted={submitted}
                  setSubmitted={setSubmitted}
                />
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Create>
  );
};
