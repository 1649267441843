import { TextInputProps } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { useWatch } from 'react-hook-form';

import { TextInput } from './RA';

interface ExtraProps {
  required?: boolean;
}

export const PdfLinkInput = (props: TextInputProps & ExtraProps) => {
  const value = useWatch({ name: props.source });
  const cls = useStyles();

  const pdfLink = value;
  let googleDrivePdf = null;

  if (pdfLink && pdfLink.includes('/drive.google.com') && pdfLink.includes('/view')) {
    googleDrivePdf = pdfLink.split('/')
      .reverse()
      .map((part: string, i: number) => i === 0 ? 'preview' : part)
      .reverse()
      .join('/');
  }

  return (
    <>
      <TextInput InputLabelProps={{ shrink: true }} {...props} />

      {(googleDrivePdf || pdfLink) && (
        <div className={cls.box}>
          {googleDrivePdf && (
            <iframe src={googleDrivePdf} className={cls.pdf} title="Google drive PDF" />
          )}
          {!googleDrivePdf && pdfLink && (
            <object data={pdfLink} type="application/pdf" className={cls.pdf} aria-label="PDF" />
          )}
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles({
  box: {
    width: '100%',
  },
  pdf: {
    width: '100%',
    height: 400,
    border: 'none',
  },
});
