import { makeStyles } from '@mui/styles';
import { ClockCircleOutlined } from '@ant-design/icons';

import { LightTooltip } from '@components/LightTooltip';
import { dateFormatter } from '@utils';
import { useEffect, useState } from 'react';
import { adminHttp } from '@network';
import { Timer } from '@types';

interface Props {
  title: string;
  url: string;
}

export const TimerWidget = ({ title, url }: Props) => {
  const cls = useStyles();
  const [timer, setTimer] = useState<Timer>();

  useEffect(() => {
    (async () => {
      const nextTimer = await adminHttp.getTimer(url);
      setTimer(nextTimer);
    })();
  }, [url]);

  const { starts, ends, timerText } = timer || {};

  const Content = (
    <div className={cls.box}>
      <div className={cls.title}>{title}</div>
      <div className={cls.button}
        style={{ fontSize: `${timerText}`.length > 20 ? 14 : undefined }}>
        <ClockCircleOutlined /> {timerText || 'No data yet'}
      </div>
    </div>
  );

  if (starts || ends) {
    return (
      <LightTooltip extraWidth title={(
        <div className={cls.tooltip}>
          <div className={cls.tooltipCol}>
            <div className={cls.tooltipTitle}>{starts?.title}</div>
            <div>{starts?.date ? dateFormatter.toDateTime(starts?.date, true) : 'N/A'}</div>
          </div>
          <div className={cls.tooltipArrow}>→</div>
          <div className={cls.tooltipCol}>
            <div className={cls.tooltipTitle}>{ends?.title}</div>
            <div>{ends?.date ? dateFormatter.toDateTime(ends?.date, true) : 'N/A'}</div>
          </div>
        </div>
      )}>{Content}</LightTooltip>
    );
  }

  return Content;
};

const useStyles = makeStyles({
  tooltip: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tooltipCol: {},
  tooltipTitle: {
    color: '#333',
    fontStyle: 'italic',
  },
  tooltipArrow: {
    paddingLeft: 10,
    paddingRight: 15,
  },
  box: {
    fontFamily: 'Outfit, sans-serif;',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      color: 'white',
      display: 'inline-block',
      marginRight: 8,
    },
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 5,
    lineHeight: 1.05,
    textAlign: 'center',
    color: '#000',
  },
  button: {
    background: '#002A77',
    color: 'white',
    padding: '18px 30px',
    borderRadius: 8,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
    fontweight: 600,
    width: '100%',
    maxWidth: 300,
  },
});
