import { memo } from 'react';
import { clsx } from 'clsx';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CodepenCircleOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons';
import { Link } from 'react-admin';

import { moneyFormatter } from '@utils';
import { Credit, Order, OrderPosition } from '@types';
import { OrderTransactions } from './order-transactions';
import { ConfirmOrder } from '@components/ConfirmOrder';
import { DeliveryOfOrder } from '@pages/delivery/DeliveryOfOrder';
import cls from './order-widget.module.css';

const PositionWidget = ({ position }: {position: OrderPosition}) => {
  const { product, qty } = position;

  return (
    <div className={cls.position}>
      <Link className={cls.product} to={`/product/${product.id}`}>
        <div className={cls.productImage}
          style={{ backgroundImage: product.image ? `url('${product.image}')` : undefined }}>
          {!product.image && <CodepenCircleOutlined className={cls.productIcon} />}
        </div>
        <div className={cls.productInfo}>
          <div className={cls.productName}>{product.name}</div>
          <div className={cls.productBrand}>{product.distributor}</div>
          <div className={cls.productTotal}>
            <div className={cls.productPrice}>
              {moneyFormatter.format(position.productPrice)}
            </div>
            <div className={cls.productQty}>x {qty}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};

interface OrderWidgetProps {
  order: Order;
  credits: Credit[];
  showDetailsBtn?: boolean;
  editable: boolean;
}

const OrderWidgetInner = ({ order, credits, editable }: OrderWidgetProps) => {
  const { isPaid, isWaiting, isRejected, statusEn, positions } = order;
  const statusStyles = clsx(cls.status, {
    [cls.isWaiting]: isWaiting,
    [cls.isPaid]: isPaid,
    [cls.isRejected]: isRejected,
  });

  return (
    <div className={cls.box}>
      <div className={cls.order}>
        <div className={cls.header}>
          <div className={cls.title}>
            <Link to={`/order/${order.id}`}>Order#{order.id}</Link>
          </div>
          <div className={cls.created}>
            {new Date(order.createdAt.replace(' ', 'T')).toLocaleDateString()}
          </div>
          <div className={cls.cost}>
            {moneyFormatter.format(order.totalCost)}
          </div>
          <div className={statusStyles}>
            <div>{statusEn}</div>
            {isWaiting && <PauseCircleOutlined className={cls.isWaitingIcon} />}
            {isPaid && <CheckCircleOutlined className={cls.isPaidIcon} />}
            {isRejected && <CloseCircleOutlined className={cls.isRejectedIcon} />}
          </div>
        </div>
        <div className={cls.content}>
          <div className={cls.positions}>
            {positions.map(position => (
              <PositionWidget position={position} key={position.product.id} />
            ))}
          </div>
        </div>
      </div>

      <div>
        <ConfirmOrder order={order} credits={credits} editable={editable} />
        <OrderTransactions transactions={order.transactions || []} />
        <DeliveryOfOrder orderId={order.id} />
      </div>
    </div>
  );
};

export const OrderWidget = memo(OrderWidgetInner);
