import Chip from '@mui/material/Chip';
import { SupportTicket } from '@types';
import { FunctionField, FunctionFieldProps } from 'react-admin';

export const statusOptions = {
  pending: '#FD8A32',
  work_in_progress: '#4CAF50',
  solved: '#FDC83A',
};

interface Props {
  autoWidth?: boolean;
  value?: string;
}

export const StatusField = (props: Props & Partial<FunctionFieldProps>) => {
  const { autoWidth, ...restProps } = props;

  return (
    <FunctionField {...restProps} render={(record: SupportTicket) => {
      const status = record.status;
      const statusLabel = record.statusEn;

      return record ? (
        <Chip
          label={statusLabel}
          size="small"
          style={{
            backgroundColor: statusOptions[status] ?? undefined,
            width: autoWidth ? 'auto' : '100%',
            padding: autoWidth ? '5px 15px' : undefined,
            color: 'white',
          }}
        />
      ) : null;
    }} />
  );
};
