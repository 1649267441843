import { useEffect, useState } from 'react';

import { FinanceCreditOptions, financeSettingsHttp } from '@network/finance-settings-http';

export const useCreditOptions = () => {
  const [options, setOptions] = useState<FinanceCreditOptions>();

  useEffect(() => {
    (async () => {
      const nextOptions = await financeSettingsHttp.getCreditOptions();
      setOptions(nextOptions);
    })();
  }, []);

  return options;
};
