import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const defaultConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
};

export class Http {
  public baseURL: string;
  protected prefix = '';
  protected axiosInstance: AxiosInstance;
  protected formConfig: AxiosRequestConfig = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  protected static instances: {[key: string]: Http} = {};

  constructor(overrideConfig: AxiosRequestConfig = {}) {
    if ((window as any).env?.API_URL) { // runtime override for Cypress tests
      defaultConfig.baseURL = (window as any).env.API_URL;
    }
    const config: AxiosRequestConfig = Object.assign({}, defaultConfig, overrideConfig);
    this.baseURL = config.baseURL as string;
    this.axiosInstance = axios.create(config);

    if (config.baseURL && !Http.instances[config.baseURL]) {
      Http.instances[config.baseURL] = this;
    }
  }

  get<T = null>(path = '', config?: AxiosRequestConfig) {
    return this.client.get<T>(this.prefix + path, config);
  }

  post<T = null>(path = '', payload: any = undefined, config?: AxiosRequestConfig) {
    return this.client.post<T>(this.prefix + path, payload, config);
  }

  patch<T = null>(path = '', payload: any = undefined, config?: AxiosRequestConfig) {
    return this.client.patch<T>(this.prefix + path, payload, config);
  }

  put<T = null>(path = '', payload: any = undefined, config?: AxiosRequestConfig) {
    return this.client.put<T>(this.prefix + path, payload, config);
  }

  delete<T = null>(path = '') {
    return this.client.delete<T>(this.prefix + path);
  }

  async downloadZip(path: string, filename?: string) {
    const defaultFilename = 'document.zip';
    const res = await this.client.get<string>(this.prefix + path, { responseType: 'blob' });
    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename || res.headers['x-filename'] || defaultFilename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  get client(): AxiosInstance {
    return Http.instances[this.baseURL].axiosInstance;
  }
}

export const http = new Http();
