import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { SaveFilled } from '@ant-design/icons';
import { EditProps, Edit, SimpleForm, TopToolbar, SaveButton, DeleteButton } from 'react-admin';

import {
  Container,
  TextInput,
  EditorInput,
  DateTimeInput,
  Forbidden,
  PageHeader,
  FormRow,
  LinkInput,
} from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';
import cls from './PageEdit.module.css';

export const PageEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();
  const editorToolbar = 'undo redo formatselect '
    + 'bold italic backcolor bullist numlist outdent indent table '
    + 'removeformat code help '
    + 'alignleft aligncenter alignright alignjustify ';
  const mainColGrid = {
    xs: { title: 24, children: 24 },
    lg: { title: 3, children: 21 },
    xl: { title: 5, children: 19 },
    xxl: { title: 3, children: 21 },
  };
  const linksColGrid = {
    xs: { title: 24, children: 24 },
    md: { title: 10, children: 14 },
    lg: { title: 6, children: 18 },
    xl: { title: 9, children: 15 },
    xxl: { title: 5, children: 19 },
  };

  if (!rbacView) {
    return <Forbidden/>;
  }

  const Actions = () => {
    return (
      <TopToolbar className={cls.actions}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon}/>}
          label="Save"
          type="button"
          className={clsx(cls.action, cls.actionSave)}
          variant="outlined"
        />
        <DeleteButton
          className={clsx(cls.action, cls.actionDelete)}
        />
      </TopToolbar>
    );
  };

  const validate = (values: any) => {
    const errors: any = {};

    ['url', 'titleMx'].forEach((key: string) => {
      if (!values[key] && key !== 'titleMx') {
        errors[key] = 'This field is required';
      } else if (values[key]?.length > 100) {
        errors[key] = 'Max length is 100 characters';
      }
    });

    return errors;
  };

  return (
    <Edit {...props} {...editProps} className={clsx(cls.page, 'details-page-wrapper')}>
      <>
        <PageHeader
          title="Page create"
          linkText="Back to all pages"
          linkUrl="/page"
        />

        <SimpleForm
          toolbar={rbacEdit ? <Actions/> : false}
          className={cls.form}
          validate={validate}
        >
          <Container max>
            <Row gutter={24} className={cls.wrapper}>
              <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColFirst)}>
                <div className={clsx(cls.block, cls.blockMain)}>
                  <div className={cls.blockHeader}>Page title</div>
                  <div className={cls.blockMiddle}>
                    <div className={cls.blockMiddleIcon}>
                      <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="27.5" cy="27.5" r="27.5" fill="#002A77"/>
                        <g clipPath="url(#clip0_351_6645)">
                          <path
                            d="M33.4605 20.6501H37.4905L32.4805 15.0901V19.6701C32.4805 20.2101 32.9205 20.6501 33.4605 20.6501Z"
                            fill="white"/>
                          <path
                            d="M33.46 22.61C31.84 22.61 30.52 21.29 30.52 19.67V14H18.96C17.88 14 17 14.88 17 15.96V38.47C17 39.55 17.88 40.43 18.96 40.43H36.41C37.49 40.43 38.37 39.55 38.37 38.47V22.61H33.46Z"
                            fill="white"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_351_6645">
                            <rect width="21.37" height="26.43" fill="white" transform="translate(17 14)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className={cls.blockMiddleInfo}>
                      <FormRow
                        title="Title:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <TextInput source="titleMx" required autoFocus label={false} name="titleMx"/>
                      </FormRow>
                      <FormRow
                        title="Created at:"
                        width={linksColGrid}
                        rowClassName={clsx(cls.formRow, cls.linkDateRow)}
                        colTitleClassName={clsx(cls.formLabelCol, cls.linkDateLabel)}
                        colChildrenClassName={clsx(cls.formValueCol, cls.linkDateValue)}
                      >
                        <DateTimeInput source="createdAt" name="createdAt" disabled label={false}/>
                      </FormRow>
                      <FormRow
                        title="Updated at:"
                        width={linksColGrid}
                        rowClassName={clsx(cls.formRow, cls.linkDateRow)}
                        colTitleClassName={clsx(cls.formLabelCol, cls.linkDateLabel)}
                        colChildrenClassName={clsx(cls.formValueCol, cls.linkDateValue)}
                      >
                        <DateTimeInput source="updatedAt" name="updatedAt" disabled label={false}/>
                      </FormRow>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColLast)}>
                <div className={clsx(cls.block, cls.blockLinks)}>
                  <div className={cls.blockHeader}>Links</div>
                  <FormRow
                    title="Website link*:"
                    fullWidth
                    rowClassName={clsx(cls.formRow, cls.linkFormRow)}
                    colTitleClassName={clsx(cls.formLabelCol, cls.linkLabel)}
                    colChildrenClassName={clsx(cls.formValueCol, cls.formLinkValue)}
                  >
                    <LinkInput source="url" name="url" required label={false} placeholder="https://any.link"/>
                  </FormRow>
                </div>
              </Col>
            </Row>
            <Row gutter={24} className={cls.wrapper}>
              <Col xs={24} className={clsx(cls.wrapperCol, cls.wrapperColBottom)}>
                <div className={clsx(cls.block, cls.blockContent)}>
                  <FormRow
                    title="Content:"
                    fullWidth
                    rowClassName={clsx(cls.formRow, cls.messageRow)}
                    colTitleClassName={clsx(cls.formLabelCol, cls.formTitleCol)}
                    colChildrenClassName={clsx(cls.formValueCol, cls.formValueColTextarea)}
                  >
                    <EditorInput
                      source="contentMx"
                      label={false}
                      toolbar={editorToolbar}
                      height={250}
                      className={cls.editor}
                    />
                  </FormRow>
                </div>
              </Col>
            </Row>
          </Container>
        </SimpleForm>
      </>
    </Edit>
  );
};
