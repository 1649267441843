import { Button } from '@mui/material';
import { Link } from 'react-admin';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

import { moneyFormatter } from '@utils';
import { InfoTooltip } from '@components';

interface Props {
  title: string;
  link: string;
  total: number;
  amount?: string | number;
  className?: string;
  whiteColor?: boolean;
  tooltip?: string;
}

export const LinkBlockBtn = ({
  title, link, total, amount, className, whiteColor, tooltip,
}: Props) => {
  const cls = useStyles();

  return (
    <Link to={link} className={cls.link}>
      <Button className={cls.btn}>
        {tooltip && <InfoTooltip title={tooltip} className={cls.tooltip} />}
        <div className={clsx(cls.btnContent, className)}>
          <div className={clsx(cls.title, whiteColor && cls.whiteColor)}>{title}</div>
          <div className={clsx(cls.total, whiteColor && cls.whiteColor)}>{total}</div>
          {(typeof amount === 'string' || typeof amount === 'number') && (
            <div className={cls.amount}>{moneyFormatter.format(+amount || 0)}</div>
          )}
        </div>
      </Button>
    </Link>
  );
};

const useStyles = makeStyles({
  link: {
    display: 'block',
    width: '100%',
  },
  btn: {
    padding: 0,
    textTransform: 'none',
    display: 'block',
    width: '100%',
    position: 'relative',
  },
  tooltip: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  btnContent: {
    padding: '15px 2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#FFF',
    '&:hover': {
      backgroundColor: '#EFEFEF',
    },
  },
  title: {
    fontSize: 15,
    fontWeight: 400,
    color: '#000',
  },
  total: {
    fontSize: 22,
    fontWeight: 700,
    color: '#000',
  },
  whiteColor: {
    color: 'white',
  },
  amount: {
    color: '#002A77',
    fontSize: 18,
    fontWeight: 700,
  },
});
