import { TextInputProps } from 'react-admin';
import { useController } from 'react-hook-form';

import { InitialLabel, TextInput } from '@components';

interface Props {
  upperLabel?: string;
  tooltip?: string;
  maxLength?: number;
  fixed?: boolean;
}

const allowedChars = '0123456789,.'.split('');

export const MoneyMaskInput = (props: TextInputProps & Props) => {
  const {
    field: { onChange },
  } = useController({ name: props.source || '' });
  const { upperLabel, tooltip, maxLength, fixed, ...restProps } = props;

  return (
    <>
      {upperLabel && <InitialLabel tooltip={tooltip}>{upperLabel}</InitialLabel>}
      <TextInput {...restProps}
        format={(val) => `$ ${val || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        onBeforeInputCapture={(e: any) => {
          if (fixed || !allowedChars.includes(`${e.data}`)) e.preventDefault();
        }}
        parse={(val) => {
          const str = `${val}`;
          if (str.includes('.')) {
            const [left, right] = str.split('.');
            const number = [left.replace(/\$\s?|(,*)/g, ''), right.slice(0, 2)].join('.');
            onChange(+number);
            return number;
          }

          return val ? +`${val}`.replace(/\$\s?|(,*)/g, '') : 0.00;
        }}
        inputProps={{
          maxLength: maxLength ? (maxLength + 3) : 21,
        }}
        label={upperLabel ? '' : props.label}
      />
    </>
  );
};
