import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useController } from 'react-hook-form';
import { clsx } from 'clsx';
import { makeStyles } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { http } from '@network';

const initialValue = {
  value: '',
  label: '',
};

interface Props {
  source: string;
  reference?: string;
  optionKey?: string;
  optionLabel?: string;
  placeholder?: string;
  defaultValue?: CheckOption;
  className?: string;
  popupClassName?: string;
  mui?: boolean;
  nullable?: boolean;
}

export const SuggestsInput = ({
  source, reference, optionKey, optionLabel, placeholder,
  defaultValue, className, popupClassName, mui, nullable,
}: Props) => {
  const {
    field: { value, onChange },
  } = useController({ name: source });
  const cls = useStyles();

  const [selectedValue, setSelectedValue] = useState<CheckOption>(defaultValue || initialValue);
  const [options, setOptions] = useState<CheckOption[]>([]);

  useEffect(() => {
    (async () => {
      const response = await http.get<any>(reference || source, {
        params: { _start: 0, _end: 9999999 },
      });
      const nextOptions: CheckOption[] = [];
      if (response.data && Array.isArray(response.data)) {
        (response.data ?? []).forEach((model: any) => {
          nextOptions.push({
            value: `${model[optionKey ?? 'id']}`,
            label: model[optionLabel ?? 'nameMx']
              || model['name']
              || model['title']
              || model['activation'],
          });
        });
      }
      if (nullable) {
        nextOptions.unshift({ label: '', value: '' });
      }
      setOptions(nextOptions);
    })();
  }, [source]);

  useEffect(() => {
    const newValue = options.find(o => +(o.value || '') === +value);

    if (newValue) {
      setSelectedValue(newValue);
    }
  }, [value, options]);

  return (
    <Select
      showSearch
      className={clsx(className, mui && cls.mui)}
      popupClassName={popupClassName}
      style={{ width: '100%' }}
      placeholder={placeholder}
      value={selectedValue}
      onChange={onChange}
      options={options}
      suffixIcon={mui ? <ArrowDropDownIcon className={cls.muiSuffixIcon} /> : undefined}
      filterSort={(a, b) => a.label?.localeCompare(b.label)}
      filterOption={(input, option) =>
        `${option?.label}`.toLowerCase().includes(input.toLowerCase())}
    />
  );
};

const useStyles = makeStyles({
  mui: {
    fontSize: 16,
    '& .ant-select-selector': {
      paddingLeft: '0 !important',
      border: 'none !important',
      borderBottom: '1px solid #E1E1E1 !important',
      boxShadow: 'none !important',
    },
    '& .ant-select-selection-search': {
      left: '0 !important',
    },
  },
  muiSuffixIcon: {
    color: '#777',
    marginRight: -17,
  },
});

interface CheckOption {
  value: string | null;
  label: string;
}
