import React, { useEffect, useState } from 'react';
import { SelectArrayInputProps } from 'react-admin';

import { SelectArrayInput } from '@components';
import { stateHttp } from '@network/state-http';

export const CreditStatesFilter = (props: SelectArrayInputProps) => {
  const [options, setOptions] = useState<{id: number; name: string}[]>([]);

  useEffect(() => {
    (async () => {
      const states = await stateHttp.getAll();
      setOptions(states.map(state => ({
        id: state.id,
        name: state.name,
      })));
    })();
  }, []);

  return (
    <SelectArrayInput
      {...props}
      choices={options}
      className="MS-field"
      style={{ width: 280 }}
      alwaysOn
    />
  );
};
