import { ListProps, TextField, ReferenceField } from 'react-admin';

import {
  TextInput,
  SelectInput,
  ReferenceInput,
  Grid,
  GridList,
  Forbidden,
} from '@components';
import { checkSuperadmin } from '@providers';
import { useRbacList } from '@hooks';

export const StateList = (props: ListProps) => {
  const isSuperadmin = checkSuperadmin();
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Grid rowClick="edit" defaultColumns={'id name countryId country.name stateCode'.split(' ')}>
        <TextField source="id" />
        <TextField source="name" />
        {isSuperadmin ? (
          <ReferenceField source="countryId" reference="country" label="Country">
            <TextField source="name" />
          </ReferenceField>
        ) : (
          <TextField source="country.name" label="Country" />
        )}
        <TextField source="stateCode" />
        <TextField source="latitude" />
        <TextField source="longitude" />
        <TextField source="environmental" />
        <TextField source="economics" />
        <TextField source="governance" />
        <TextField source="financially" />
        <TextField source="socially" />
        <TextField source="ponderation" />
        <TextField source="grade" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  <ReferenceInput
    source="countryId"
    key="countryId"
    reference="country"
    label="Country"
    allowEmpty
    alwaysOn
    perPage={1000}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];
