import { clsx } from 'clsx';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { FunctionFieldProps, FunctionField } from 'react-admin';

import { Manager } from '@types';
import { useRequest } from '@hooks';
import { managerHttp } from '@network';
import cls from './ManagerLoginBtn.module.css';

interface ExtraProps {
  text?: string;
  color?: string;
  bg?: string;
  isSmall?: boolean;
}

export const ManagerLoginBtn = (props: Partial<FunctionFieldProps & ExtraProps>) => {
  const { text, color, bg, isSmall, ...rest } = props;

  return (
    <FunctionField
      {...rest}
      sortable={false}
      render={(record: any) => (
        <ManagerLoginWidget
          manager={record}
          text={text}
          color={color}
          bg={bg}
          isSmall={isSmall}
        />
      )}
    />
  );
};

const LoginIcon = ({ color, className }: { color?: string; className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="26"
    viewBox="0 0 19 26"
    fill="none"
    className={className}
  >
    <path fill={color || 'white'} d="M0 12.9487C0 13.5422 0.485587 14.0278 1.07908 14.0278H9.26393L6.79283 16.5043C6.33961 16.8928 6.28566 17.5726 6.67413 18.0258C7.0626 18.479 7.74242 18.533 8.19564 18.1445C8.2388 18.1068 8.27657 18.069 8.31433 18.0258L12.6307 13.7095C13.0515 13.2887 13.0515 12.6088 12.6307 12.188L8.31433 7.87166C7.86112 7.48319 7.1813 7.53714 6.79283 7.99036C6.44752 8.39501 6.44752 8.98851 6.79283 9.39316L9.26393 11.8697H1.07908C0.485587 11.8697 0 12.3552 0 12.9487Z"/>
    <path fill={color || 'white'} d="M18.3444 3.23725C18.3444 1.45137 16.893 0 15.1072 0H3.23725C1.45137 0 0 1.45137 0 3.23725V7.55358C0 8.14708 0.485587 8.63266 1.07908 8.63266C1.67258 8.63266 2.15817 8.14708 2.15817 7.55358V3.23725C2.15817 2.64375 2.64375 2.15817 3.23725 2.15817H15.1072C15.7007 2.15817 16.1862 2.64375 16.1862 3.23725V22.6607C16.1862 23.2542 15.7007 23.7398 15.1072 23.7398H3.23725C2.64375 23.7398 2.15817 23.2542 2.15817 22.6607V18.3444C2.15817 17.7509 1.67258 17.2653 1.07908 17.2653C0.485587 17.2653 0 17.7509 0 18.3444V22.6607C0 24.4466 1.45137 25.898 3.23725 25.898H15.1072C16.893 25.898 18.3444 24.4466 18.3444 22.6607V3.23725Z"/>
  </svg>
);

const ManagerLoginWidget = (
  { manager, text, color, bg, isSmall }: { manager: Manager } & ExtraProps,
) => {
  const { loading, submit } = useRequest(async () => {
    const loginUrl = await managerHttp.loginAdmin(manager.id);
    window.open(loginUrl, '_blank')?.focus();
  });

  return (
    <div onClick={e => e.stopPropagation()} className={cls.box}>
      <Button
        onClick={submit}
        type="ghost"
        className={clsx(cls.btn, isSmall ? cls.small : '')}
        style={{ backgroundColor: bg ? bg : 'transparent' }}
        loading={loading}
      >
        {loading ? (
          <ReloadOutlined className={cls.icon}/>
        ) : (
          <LoginIcon className={cls.icon} color={color}/>
        )}
        {text ? <span className={cls.text}>{text}</span> : null}
      </Button>
    </div>
  );
};
