import { clsx } from 'clsx';
import moment from 'moment-timezone';
import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Create, CreateProps, useNotify, useRedirect } from 'react-admin';

import { axiosErrorToString } from '@utils';
import { useCreateProps, useRbacCreate } from '@hooks';
import { financeCostHttp } from '@network/finance-cost-http';
import { CreateFinanceCostParams, FinanceCostOptions } from '@types';
import { FinanceCostForm } from '@pages/finance-cost/FinanceCostForm';
import { Container, Forbidden, Loader, PageHeader } from '@components';
import cls from './FinanceCostCreate.module.css';

export const FinanceCostCreate = (props: CreateProps) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const rbacCreate = useRbacCreate();
  const createProps = useCreateProps();
  const [form] = Form.useForm<CreateFinanceCostParams>();
  const [options, setOptions] = useState<FinanceCostOptions | null>(null);
  const [loading, setLoading] = useState(false);
  const formValues: CreateFinanceCostParams = {
    expense: 0,
    costCategoryId: null,
    costSubcategoryId: null,
    costTypeId: null,
    activationId: null,
    paymentDate: moment(new Date()).format('DD-MM-YYYY'),
    paymentBank: '',
  };

  const getOptions = async () => {
    try {
      const ops: FinanceCostOptions = await financeCostHttp.options();

      if (!ops) {
        throw new Error('The list of options has not been received');
      }
      setOptions(ops);
    } catch (e) {
      console.error(e);
    }
  };

  const submit = async (params: CreateFinanceCostParams) => {
    try {
      setLoading(true);
      const result = await financeCostHttp.create(params);

      form.resetFields();
      notify('Finance Cost has been created');
      redirect(`/finance-cost${result.id ? '/' + result.id : ''}`);
    } catch (e) {
      // @ts-ignore
      notify(axiosErrorToString(e), { type: 'error' });
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getOptions();
    })();
  }, []);

  if (!options) {
    return <Loader center />;
  }

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps} className={clsx(cls.page, 'details-page-wrapper')}>
      <PageHeader
        title="Cost Center"
        linkText="Back to all costs"
        linkUrl="/finance-cost"
      />

      <Container max>
        <Row gutter={24} className={cls.wrapper}>
          <Col xs={24} className={cls.wrapperCol}>
            <div className={cls.block}>
              <div className={cls.blockHeader}>Complete information</div>
              <Form
                form={form}
                layout="horizontal"
                initialValues={formValues}
              >
                <FinanceCostForm
                  initial={formValues}
                  options={options}
                  onSubmit={submit}
                  form={form}
                  loading={loading}
                  setLoading={setLoading}
                />
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Create>
  );
};
