import { FunctionFieldProps, FunctionField } from 'react-admin';

import {
  resolvePath,
  FilePdf,
  FileWithoutPreview,
  FileImage,
  FileHtml,
} from '@components';
import { FinanceDocument } from '@types';
import cls from './finance-docs-field.module.css';

export const FinanceDocsField = (props: Partial<FunctionFieldProps> & {modelName: string}) => {
  const { modelName, ...restProps } = props;

  return (
    <FunctionField
      {...restProps}
      render={(record: any) => (
        <FinanceDocs
          code={resolvePath(record, 'code')}
          modelName={modelName}
          documents={resolvePath(record, props.source || '')}
        />
      )}
    />
  );
};

interface Props {
  code: string;
  modelName: string;
  documents: FinanceDocument[];
}

const FinanceDocs = ({ modelName, code, documents }: Props) => {
  if (!Array.isArray(documents) || !documents.length) {
    return null;
  }

  return (
    <div className={cls.documents}>
      {documents.map((doc, i) => {
        const codeName = code ? ` ${code}` : '';
        const type = doc.type === 'receipt' ? 'Receipt' : 'Invoice';
        const counter = `${i + 1}`;
        const downloadFilename = `${modelName}${codeName} – ${type} ${counter}.${doc.extension}`;

        const isPdf = doc.isPdf;
        const isHtml = doc.extension === 'html';
        const isKml = doc.extension === 'kml';
        const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(doc.extension);

        return (
          <div key={doc.id} className={cls.doc} onClick={e => e.stopPropagation()}>
            {isPdf && (
              <FilePdf url={doc.adminUrl} downloadFilename={downloadFilename} />
            )}
            {isKml && (
              <FileWithoutPreview url={doc.adminUrl} downloadFilename={downloadFilename} />
            )}
            {isImage && (
              <FileImage url={doc.adminUrl} downloadFilename={downloadFilename} />
            )}
            {isHtml && (
              <FileHtml url={doc.adminUrl} downloadFilename={downloadFilename} />
            )}
            <div className={cls.counter}>{i + 1}</div>
          </div>
        );
      })}
    </div>
  );
};
