import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

import { CreditRisk } from '@types';
import { InitialCard, InitialRow, ScoreWidget, SelectInput } from '@components';
import { creditStatusOptions } from '@pages/credit/CreditStatusField';
import { ExportRisk } from '@pages/credit-risk/export';
import { useWatch } from 'react-hook-form';

const statusOptions = creditStatusOptions
  .filter(o => ['moderation', 'bank_revision', 'approved', 'denied'].includes(o.id));
const rejectReason = [
  { id: 'Default behavior', name: 'Default behavior' },
  { id: 'Overindebtedness', name: 'Overindebtedness' },
  { id: 'Tax Strategy', name: 'Tax Strategy' },
  { id: 'Cancelled Invoices', name: 'Cancelled Invoices' },
  { id: 'No Retention', name: 'No Retention' },
  { id: 'Less than one invoiced cycle', name: 'Less than one invoiced cycle' },
  { id: 'Multiple clients', name: 'Multiple clients' },
  { id: 'Low sales', name: 'Low sales' },
  { id: 'Conditions not accepted', name: 'Conditions not accepted' },
  { id: 'Unauthorized off taker', name: 'Unauthorized off taker' },
];

export const RiskProgressScore = ({ risk, readonly }: {risk: CreditRisk; readonly?: boolean}) => {
  const score = risk.riskScore || 0;
  const cls = useStyles();
  const [isReportOpened, setReportOpened] = useState(false);
  const openReport = risk ? () => setReportOpened(true) : undefined;
  const creditStatus = useWatch({ name: 'creditStatus' });

  const isDenied = risk?.credit?.isDenied;

  return (
    <>
      <InitialCard left="Score" autoHeight mediumPadding>
        <div className={cls.box}>
          <ScoreWidget
            value={score}
            percents={score > risk.successPercent ? 90 : 10}
            onClick={openReport}
          />
          <InitialRow title="Status" className={cls.statusRow}>
            <SelectInput
              label={false}
              source="creditStatus"
              choices={statusOptions}
              required
              disabled={readonly || isDenied}
            />
          </InitialRow>
          <InitialRow title="Reason" className={cls.reasonRow}>
            <SelectInput
              label={false}
              source="creditRejectReason"
              choices={rejectReason}
              required={isDenied}
              disabled={creditStatus !== 'denied'}
            />
          </InitialRow>
        </div>
      </InitialCard>
      <ExportRisk
        creditId={risk.creditId}
        isOpened={isReportOpened}
        setOpened={setReportOpened}
      />
    </>
  );
};

const useStyles = makeStyles({
  box: {
  },
  statusRow: {
    marginLeft: '15%',
    marginRight: '33%',
    marginTop: 28,
  },
  reasonRow: {
    marginLeft: '15%',
    marginRight: '33%',
    marginTop: 28,
  },
});
