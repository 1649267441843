import clsx from 'clsx';

import cls from './Progress.module.css';

export interface IProgressOptions {
  steps?: 2 | 3 | 4 | 5 | 6;
  labels?: string[];
}

interface Props {
  options: IProgressOptions;
  active: number;
}

export const Progress = ({ options, active = 0 }: Props) => {
  const { steps = 4, labels } = options;

  return (
    <div className={cls.wrap}>
      <div className={cls.steps}>
        {labels?.length !== steps ? (
          <div>Not correct options</div>
        ) : (
          labels.map((label, i) => (
            <div key={i} className={clsx(
              cls.step,
              cls[`step-${i}`],
              i <= active && cls.active,
            )}>{label}</div>
          ))
        )}
      </div>
    </div>
  );
};
