import React from 'react';
import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useDeleteMany, useListContext } from 'ra-core';
import { useNotify, useRefresh, useResourceContext } from 'react-admin';
import { Modal } from 'antd';

import { ConfirmDelete } from '@components';
import { useModalContext } from '@providers';
import IconButton from '@mui/material/IconButton';

interface Props {
  editComponent?: React.ReactElement<{closeModal: () => void}>;
  hideRemoveButton?: boolean;
}

export const GridActions = ({ editComponent, hideRemoveButton }: Props) => {
  const { selectedIds, onUnselectItems } = useListContext();
  const { isOpenedModal, showModal, hideModal, title } = useModalContext();
  const resource = useResourceContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const cls = useStyles();

  const [deleteMany, { isLoading }] = useDeleteMany(
    resource,
    { ids: selectedIds },
    {
      onSuccess: () => {
        refresh();
        notify(`Deleted items: ${selectedIds.length}`);
        onUnselectItems();
      },
      onError: (error: Error) => {
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          {
            type: 'error',
            messageArgs: {
              _:
                typeof error === 'string'
                  ? error
                  : error && error.message
                    ? error.message
                    : undefined,
            },
          },
        );
        refresh();
      },
    },
  );

  if (!selectedIds || selectedIds.length === 0) {
    return null;
  }
  const defaultModalTitle = `Edit ${selectedIds.length} records`;

  return (
    <>
      <div className={cls.box}>
        <IconButton size="small" onClick={onUnselectItems}
          title="unselect" className={cls.unselect}>
          <Close fontSize="small" />
        </IconButton>
        <div>{selectedIds.length} selected records</div>
        {editComponent && (
          <Button variant="text" className={cls.editBtn} onClick={showModal}>
            <svg xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none">
              <path d="M12 2.04973V2.49024C11.9039 2.92044 11.7286 3.31243 11.4375 3.64768C11.355 3.74263 11.2723 3.83782 11.183 3.9262C8.9041 6.18317 6.62384 8.43897 4.34217 10.6934C4.27138 10.7623 4.18607 10.8146 4.09232 10.8462C2.92816 11.2234 1.76307 11.5974 0.596571 11.9678C0.305707 12.0616 0.0621886 11.9462 0.00757848 11.678C-0.0111718 11.5866 0.00757848 11.4795 0.0342976 11.3873C0.367583 10.262 0.710947 9.1423 1.0379 8.01628C1.10166 7.79074 1.22377 7.58584 1.39228 7.4229C3.48997 5.33238 5.5839 3.23764 7.67362 1.13822C8.19323 0.614004 8.72176 0.140665 9.48419 0H9.95599C9.96419 0.0234442 9.97169 0.0185209 9.9806 0.0199276C10.717 0.140665 11.2251 0.592669 11.6428 1.17338C11.832 1.43619 11.9262 1.73933 12 2.04973Z"
                fill="#042E6B"
              />
            </svg>
            <span>Edit</span>
          </Button>
        )}

        {!hideRemoveButton && (
          <ConfirmDelete
            title="Are you sure?"
            cb={() => void deleteMany()}
            overlayClassName={cls.overlayClassName}
            placement="bottom"
          >
            <Button variant="text" className={cls.removeBtn} disabled={isLoading}>
              <svg xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none">
                <path d="M8.73038 1.90625C8.75639 2.01427 8.8664 2.10028 8.97842 2.10028H11.1087C11.4948 2.10028 11.8088 2.41432 11.8088 2.80037C11.8088 3.12042 11.5948 3.38845 11.3027 3.47246L10.9367 13.5258C10.9067 14.3439 10.2266 15 9.40647 15H2.80759C1.98948 15 1.30739 14.3419 1.27739 13.5258L0.911341 3.47246C0.619302 3.38845 0.405273 3.11842 0.405273 2.80037C0.405273 2.41432 0.719315 2.10028 1.10537 2.10028H3.23565C3.34567 2.10028 3.45568 2.01227 3.48368 1.90625L3.6337 1.30617C3.81973 0.566076 4.54383 0 5.30593 0H6.90814C7.67024 0 8.39634 0.566076 8.58036 1.30617L8.73038 1.90625ZM5.60597 5.30071V12.1016C5.60597 12.3777 5.83 12.6017 6.10603 12.6017C6.38207 12.6017 6.6061 12.3777 6.6061 12.1016V5.30071C6.6061 5.02467 6.38207 4.80064 6.10603 4.80064C5.83 4.80064 5.60597 5.02467 5.60597 5.30071ZM3.30566 5.31471L3.50569 12.1156C3.51369 12.3917 3.74372 12.6097 4.01976 12.6017C4.29579 12.5937 4.51382 12.3636 4.50582 12.0876L4.30579 5.28671C4.29779 5.01067 4.06776 4.79264 3.79172 4.80064C3.51569 4.80864 3.29766 5.03867 3.30566 5.31471ZM7.90627 5.2847L7.70625 12.0856C7.69825 12.3616 7.91627 12.5917 8.19231 12.5997C8.46835 12.6077 8.69838 12.3897 8.70638 12.1136L8.90641 5.31271C8.91441 5.03667 8.69638 4.80664 8.42034 4.79864C8.1443 4.79064 7.91427 5.00867 7.90627 5.2847ZM4.97588 2.09828H7.23418C7.28819 2.09828 7.3222 2.05427 7.31019 2.00227L7.22018 1.64422C7.19018 1.5262 7.02616 1.39819 6.90614 1.39819H5.30393C5.18391 1.39819 5.01989 1.5262 4.98988 1.64422L4.89987 2.00227C4.88587 2.05627 4.91988 2.09828 4.97588 2.09828Z"
                  fill="#D32F2F" />
              </svg>
              <span>Delete</span>
            </Button>
          </ConfirmDelete>
        )}
      </div>

      <Modal open={isOpenedModal} onCancel={hideModal}
        closeIcon={<Close style={{ marginTop: 14 }} />}
        width={540} footer={false} className={cls.modal} title={false}>
        <>
          <div className={cls.title}>
            {title || defaultModalTitle}
          </div>
          <div className={cls.modalContent}>
            {selectedIds.length > 0 && editComponent}
          </div>
        </>
      </Modal>
    </>
  );
};

const useStyles = makeStyles({
  box: {
    position: 'absolute',
    top: -30,
    left: 0,
    display: 'flex',
    padding: '5px 22px 22px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: 'transparent',
    color: '#042E6B',
    fontFamily: 'Outfit, sans-serif',
    fontSize: 12,
    fontWeight: 400,
    '& > *': {
      marginRight: 10,
    },
  },
  unselect: {
    margin: '-10px 0 0 -15px',
    padding: '8px !important',
  },
  modal: {
    '& .ant-modal-content': {
      padding: 0,
      borderRadius: 8,
      overflow: 'hidden',
    },
    '& .ant-modal-body': {
      padding: 0,
    },
    '& .ant-modal-close': {
      color: '#FFF',
    },
  },
  modalContent: {
    padding: '22px 35px',
    color: '#000',
    fontFamily: 'Outfit, sans-serif',
  },
  title: {
    padding: '15px 20px 13px',
    background: '#042E6B',
    color: '#FFF',
    fontFamily: 'Outfit, sans-serif',
    fontSize: 20,
    fontWeight: 400,
  },
  editBtn: {
    margin: '-7px 5px 5px',
    textTransform: 'none',
    color: '#042E6B',
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 12,
    fontWeight: 400,
    '& svg': {
      marginRight: 6,
    },
  },
  removeBtn: {
    margin: '-7px 5px 5px',
    textTransform: 'none',
    color: '#D32F2F',
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 12,
    fontWeight: 400,
    '& svg': {
      marginRight: 6,
    },
  },
  overlayClassName: {
    '& .ant-popover-placement-top .ant-popover-arrow': {
      transform: 'translateY(80%) !important',
    },
    '& .ant-popover-arrow-content': {
      backgroundColor: 'transparent',
    },
  },
});
