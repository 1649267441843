import { Http } from './http';

class AirtableHttp extends Http {

  prefix = 'airtable';

  async syncCredits(): Promise<string> {
    const res = await this.get<string>('/sync-credits');

    return res.data;
  }
}

export const airtableHttp = new AirtableHttp();
