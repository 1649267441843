import { User } from './User';
import { FinanceDeposit } from './FinanceDeposit';
import { FinanceWithdrawal } from './FinanceWithdrawal';

export interface FinanceAccount {
  id: number;
  userId: number | null;
  user: User | null;
  code: string;
  addressee: string;
  bank: string;
  type: string;
  account: string;
  branch: string;
  reference: string | null;
  phone: string;
  email: string;
  accountSetup: boolean;
  balance: number;
  createdAt: string;
  updatedAt: string;
  deposits: FinanceDeposit[];
  withdrawals: FinanceWithdrawal[];
}

export interface FinanceAccountUserOption {
  code: string;
  email: string;
  financeAccountId: number | null;
  fullName: string;
  id: number;
  phone: string;
}

export interface FinanceAccountOptions {
  banks: string[];
  types: string[];
  users: FinanceAccountUserOption[];
}

export interface CreateFinanceAccountParams {
  userId?: number | null;
  userCode?: string;
  code: string;
  addressee: string;
  bank: string;
  type: string;
  account: string;
  branch: string;
  reference?: string | null;
  phone: string;
  email: string;
  accountSetup: boolean;
  balance: number;
}

export interface UpdateFinanceAccountParams extends CreateFinanceAccountParams{
  id: number;
}

export const hasCodeOptions = [
  { id: 'done', name: 'Yes' },
  { id: 'missing', name: 'No' },
];
