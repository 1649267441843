import React from 'react';
import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';

import { CreditRisk } from '@types';
import { moneyFormatter } from '@utils';
import { TablePastCycleRow } from '@network';

export const RiskVariables = ({ risk }: {risk: CreditRisk}) => {
  const cls = useStyles();
  const { tablePastCycle, tableProjectedCycle } = risk;

  const getValue = (row: TablePastCycleRow | null): string => {
    if (!row) return '';
    if (row.format === 'number' || row.format === 'text') {
      return row.value?.toString() || '';
    }
    return typeof row.value === 'number' && row.format === 'money'
      ? moneyFormatter.format(row.value || 0).toString()
      : `${row.value || 0}%`;
  };

  return (
    <>
      {tablePastCycle?.rows || tableProjectedCycle?.rows
        ? <div className={cls.title}>Income Verification</div>
        : null}
      <Row gutter={[20, 20]}>
        <Col md={12} sm={24}>
          {tablePastCycle?.rows && Array.isArray(tablePastCycle.rows) ? (
            <div className={cls.wrap}>
              <div className={clsx(cls.header, cls.headerTop)}>Past Cycle</div>
              {tablePastCycle.rows.map((row, idx) => (
                <div key={idx} className={clsx(
                  cls.row,
                  typeof row.separator === 'string' && cls.rowSeparator,
                )}>
                  {typeof row.separator === 'string' ? (
                    <div className={cls.row}>
                      <div className={clsx(cls.col, cls.colSeparator)}>
                        {row.separator}
                      </div>
                    </div>
                  ) : null}
                  <div className={cls.col}>{row.title}</div>
                  <div className={clsx(cls.col, cls.colValue)}>
                    {getValue(row)}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </Col>
        <Col md={12} sm={24}>
          {tableProjectedCycle?.rows && Array.isArray(tableProjectedCycle.rows) ? (
            <div className={cls.wrap}>
              <div className={clsx(cls.header, cls.headerTop)}>Projected Cycle</div>
              {tableProjectedCycle.rows.map((row, idx) => (
                <div key={idx} className={clsx(
                  cls.row,
                  typeof row.separator === 'string' && cls.rowSeparator,
                )}>
                  {typeof row.separator === 'string' ? (
                    <div className={cls.row}>
                      <div className={clsx(cls.col, cls.colSeparator)}>
                        {row.separator}
                      </div>
                    </div>
                  ) : null}
                  <div className={cls.col}>{row.title}</div>
                  <div className={clsx(cls.col, cls.colValue)}>
                    {getValue(row)}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

const useStyles = makeStyles({
  title: {
    margin: '25px 0 5px',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: '#000',
  },
  wrap: {
    border: '1px solid #042E6B',
    borderRadius: 5,
    overflow: 'hidden',
    backgroundColor: '#FFF',
  },
  header: {},
  headerTop: {
    minHeight: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: '#042E6B',
  },
  row: {
    borderTop: '1px solid #042E6B',
    display: 'flex',
  },
  rowSeparator: {
    position: 'relative',
    paddingTop: 34,
  },
  col: {
    minHeight: 34,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 50%',
    maxWidth: '50%',
    color: '#042E6B',
    fontSize: 12,
    fontWeight: 400,
    padding: '3px 8px',
  },
  colSeparator: {
    height: 34,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    justifyContent: 'center',
    flex: '0 0 100%',
    maxWidth: '100%',
    color: '#FFF',
    fontSize: 12,
    fontWeight: 400,
    backgroundColor: '#042E6B',
  },
  colValue: {
    borderLeft: '1px solid #042E6B',
    backgroundColor: '#F0F3FB',
  },
});
