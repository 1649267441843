import { BooleanInput, Create, CreateProps, SimpleForm } from 'react-admin';
import { Row, Col, Container, TextInput, Forbidden } from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';

const Title = () => (<span>Create Subcategory</span>);

export const ProductTypeCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} title={<Title />} {...createProps}>
      <SimpleForm>
        <Container max>
          <Row>
            <Col><TextInput source="name" label="Subcategory" required /></Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};
