import React from 'react';
import { TextInputProps } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { useController } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import { clsx } from 'clsx';

export const CheckboxInput = (props: TextInputProps) => {
  const cls = useStyles();
  const { defaultValue, disabled } = props;
  const {
    field: { value, onChange: setChecked },
  } = useController({ name: props.source });

  const checked = (): boolean => {
    if (disabled) {
      return false;
    }
    if (value !== undefined) {
      return !!value;
    }
    return defaultValue;
  };

  const swapChecked = () => setChecked(!checked());
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <div className={clsx(cls.box, props.className, disabled && cls.disabled)}>
      <Checkbox
        checked={checked()}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        className={cls.checkbox}
        disabled={disabled}
      />
      <label className={cls.label} onClick={swapChecked}>
        <span>{props.label}</span>
      </label>
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Outfit, sans-serif',
    marginLeft: -5,
  },
  label: {
    display: 'block',
    color: '#000',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1,
    paddingLeft: 3,
    cursor: 'pointer',
  },
  checkbox: {
    width: 24,
    height: 24,
  },
  disabled: {
    opacity: 0.3,

    '& label': {
      cursor: 'default',
    },
  },
});
