import { Button } from 'antd';
import { useRedirect } from 'react-admin';

import { dateFormatter, moneyFormatter } from '@utils';
import { Credit } from '@types';
import cls from './credit-widget.module.css';

interface CreditWidgetProps {
  credit: Credit;
}

export const CreditWidget = ({ credit }: CreditWidgetProps) => {
  const redirect = useRedirect();
  const gotoCredit = () => redirect(`/credit/${credit.id}`);
  const gotoBank = () => redirect(`/bank/${credit.bank?.id}`);

  return (
    <div className={cls.box}>
      <div className={cls.credit}>
        <div className={cls.header}>
          <div className={cls.title}>Credit #{credit.code}</div>
          <div className={cls.date}>{dateFormatter.toDate(credit.createdAt)}</div>
        </div>
        <div className={cls.content}>
          <div className={cls.info}>
            <div className={cls.rows}>
              <div className={cls.row}>
                <div className={cls.label}>Status</div>
                <div className={cls.value}>
                  {credit.statusEn}
                </div>
              </div>
              <div className={cls.row}>
                <div className={cls.label}>Bank</div>
                <div className={cls.value}>
                  <span className={cls.link} onClick={gotoBank}>{credit.bank?.nameEn}</span>
                </div>
              </div>
              <div className={cls.row}>
                <div className={cls.label}>Credit amount</div>
                <div className={cls.value}>
                  {moneyFormatter.format(credit.bankApproval || 0)}
                </div>
              </div>
              <div className={cls.row}>
                <div className={cls.label}>External amount</div>
                <div className={cls.value}>
                  {moneyFormatter.format(credit.membership || 0)}
                </div>
              </div>
              <div className={cls.row}>
                <div className={cls.label}>Balance</div>
                <div className={cls.value}>
                  {moneyFormatter.format(credit.balance)}
                </div>
              </div>
              <div className={cls.row}>
                <div className={cls.label}>Request amount</div>
                <div className={cls.value}>
                  {moneyFormatter.format(credit.requestAmount)}
                </div>
              </div>
            </div>
            <div className={cls.details}>
              <Button type="primary" onClick={gotoCredit}>Details</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
