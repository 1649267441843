import { useEffect, useState } from 'react';
import { EditProps, useNotify, useRedirect } from 'react-admin';
import { clsx } from 'clsx';
import moment from 'moment-timezone';
import { Button, Dialog, DialogActions, DialogTitle, Input } from '@mui/material';
import { DatePicker, InputNumber, Modal, Select, Space } from 'antd';

import { axiosErrorToString, dateFormatter, moneyFormatter } from '@utils';
import { creditHttp, ordersHttp, UpdateOrderParams } from '@network';
import { useRecord } from '@hooks';
import { CreditHistory, Order, OrderCompositionProduct, OrderTransaction } from '@types';
import { InitialCard, InitialPage } from '@components';
import { OrderStatus } from '@pages/order/OrderStatus';
import { OrderProducts } from '@pages/order/OrderCreate/OrderProducts';
import cls from './order-edit.module.css';

const dateFormat = 'MM.DD.YYYY HH:MM';

export const OrderEdit = (props: EditProps) => {
  moment.locale('en');
  const order = useRecord<Order>(props);
  const redirect = useRedirect();
  const notify = useNotify();

  const [composition, setComposition] = useState<OrderCompositionProduct[]>([]);
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isQuotationModalOpen, setIsQuotationModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [creditsList, setCreditsList] = useState<{value: number, label: string}[]>([]);
  const [selectedCreditId, setSelectedCreditId] = useState<number | null>(null);
  const [requestHours, setRequestHours] = useState<number>(24);

  const backTo = '/order';

  const onCreditSelect = (creditId: number) => {
    setSelectedCreditId(creditId);
  };

  const changeHours = (num: any) => {
    setRequestHours(num);
  };

  const submit = async () => {
    const selectedComposition = composition.filter(c => c.productId);
    if (selectedComposition.length === 0) {
      notify('Add at least one product', { type: 'error' });

      return;
    }

    try {
      const params: UpdateOrderParams = {
        orderId: order.id,
        composition: selectedComposition,
        userId: order.userId,
        creditId: order.credit?.id,
      };

      if (requestHours) {
        params.requestHours = requestHours;
      }

      await ordersHttp.updateOrder(params);
      redirect(backTo);
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  const cancel = () => {
    redirect(backTo);
  };

  const remove = async () => {
    try {
      await ordersHttp.removeOrders([order.id]);
      redirect(backTo);
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  const requestQuotation = () => {
    setIsQuotationModalOpen(false);

    submit();
  };

  const confirm = async () => {
    setIsConfirmModalOpen(false);

    if (selectedCreditId) {
      try {
        await creditHttp.confirmOrderQuotation({
          creditId: selectedCreditId, orderId: order.id,
        });
        redirect(backTo);
      } catch (e) {
        notify(axiosErrorToString(e));
      }
    }
  };

  useEffect(() => {
    if (order) {
      const orderComposition: OrderCompositionProduct[] = [];

      order.composition.forEach((item) => {
        const compositionProduct =
          order.positions?.find(p => p.productId === item.productId)?.product;
        orderComposition.push({
          ...item,
          product: compositionProduct,
        });
      });
      setComposition(orderComposition);

      (async () => {
        const loadedCredits = await creditHttp.getCreditsByUser(order.userId);
        const newList = loadedCredits.map(c => ({
          value: c.id,
          label: `Credit # ${c.code} (${c.statusEn}). Balance: ${moneyFormatter.format(c.balance)}`,
        }));
        setCreditsList(newList);
      })();
    }
  }, [order]);

  if (!order) {
    return null;
  }

  const paymentPaidAt = order.paymentPaidAt ? moment(order.paymentPaidAt) : null;
  const paymentStartsAt = order.paymentStartsAt ? moment(order.paymentStartsAt) : null;
  const paymentExpiresAt = order.paymentExpiresAt ? moment(order.paymentExpiresAt) : null;
  const isOrderConfirmed = order.status === 'confirmed';
  const isOrderPaid = order.status === 'paid';

  return (
    <InitialPage title="Order" backTo={backTo} backText="Back to all orders">
      <div className={cls.wrap}>
        <div className={cls.card}>
          <InitialCard contentClass={cls.content}>
            <div className={cls.title}>Details</div>
            <div className={cls.cardInfo}>
              <div className={cls.icon}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <g clipPath="url(#clip0_4_249)">
                    <path d="M13.3301 6.70901H21.3302C21.9549 6.70901 21.9556 7.63585 21.3302 7.63585H13.3301C12.7054 7.63585 12.7047 6.70901 13.3301 6.70901Z" fill="white"/>
                    <path d="M21.3302 10.1185H13.3301C12.7054 10.1185 12.7047 11.0454 13.3301 11.0454H21.3302C21.9549 11.0454 21.9556 10.1185 21.3302 10.1185Z" fill="white"/>
                    <path d="M21.3302 13.5287H13.3301C12.7054 13.5287 12.7047 14.4555 13.3301 14.4555H21.3302C21.9549 14.4555 21.9556 13.5287 21.3302 13.5287Z" fill="white"/>
                    <path d="M9.05571 7.63585H11.0277C11.6524 7.63585 11.653 6.70901 11.0277 6.70901H9.05571C8.43096 6.70901 8.43033 7.63585 9.05571 7.63585Z" fill="white"/>
                    <path d="M9.05571 11.0089H11.0277C11.6524 11.0089 11.653 10.0821 11.0277 10.0821H9.05571C8.43096 10.0821 8.43033 11.0089 9.05571 11.0089Z" fill="white"/>
                    <path d="M9.05571 14.382H11.0277C11.6524 14.382 11.653 13.4551 11.0277 13.4551H9.05571C8.43096 13.4551 8.43033 14.382 9.05571 14.382Z" fill="white"/>
                    <path d="M13.33 16.8282H9.05571C8.43096 16.8282 8.43033 17.755 9.05571 17.755H13.33C13.9547 17.755 13.9553 16.8282 13.33 16.8282Z" fill="white"/>
                    <path d="M9.05571 4.22636H15.6323C16.257 4.22636 16.2576 3.29953 15.6323 3.29953H9.05571C8.43096 3.29953 8.43033 4.22636 9.05571 4.22636Z" fill="white"/>
                    <path d="M24.9905 15.4565V8.32666H24.995V1.97477C24.995 0.886051 24.0909 0 22.9801 0H7.40619C6.29539 0 5.39137 0.886051 5.39137 1.97477V11.3086H2.01483C0.904025 11.3086 0 12.1946 0 13.2834V20.8951C0 22.6233 1.4298 23.9994 3.16724 24C3.0897 24 12.4836 24 15.3943 24H22.2829V23.9944H23.8413C24.4799 23.9944 25 23.4847 25 22.8588V17.0846V15.4565H24.9905ZM24.0304 16.6916V22.0197C24.0304 22.5974 23.5507 23.0676 22.9612 23.0676H16.1199C16.1199 23.0676 16.1199 23.0713 16.1199 23.0732H5.42478C5.98838 22.5121 6.337 21.7429 6.337 20.8951V7.30282V1.97477C6.337 1.39704 6.81675 0.926832 7.40619 0.926832H22.9801C23.5696 0.926832 24.0493 1.39704 24.0493 1.97477V15.4565H24.0304V16.6916Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_4_249">
                      <rect width="25" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className={cls.cardValues}>
                <div className={cls.cardValuesRow}>
                  <div className={cls.cardLabel}>Status</div>
                  <div className={cls.cardValue}>
                    <OrderStatus status={{
                      label: order.statusEn,
                      value: order.status,
                    }} />
                  </div>
                </div>
                <div className={cls.cardValuesRow}>
                  <div className={cls.cardLabel}>Created at</div>
                  <div className={cls.cardValue}>{dateFormatter.toDateSlash(order.createdAt)}</div>
                </div>
                <div className={cls.cardValuesRow}>
                  <div className={cls.cardLabel}>Code</div>
                  <div className={cls.cardValue}>{order.code}</div>
                </div>
                <div className={cls.cardValuesRow}>
                  <div className={cls.cardLabel}>Delivery status</div>
                  <div className={cls.cardValue}>
                    <OrderStatus status={{
                      label: order.deliveryStatusEn,
                      value: order.deliveryStatus,
                    }} />
                  </div>
                </div>
                <div className={cls.cardValuesRow}>
                  <div className={cls.cardLabel}>Credit code</div>
                  <div className={cls.cardValue}>{order.credit?.code}</div>
                </div>
                <div className={cls.cardValuesRow}>
                  <div className={cls.cardLabel}>Total amount</div>
                  <div className={cls.cardValue}>
                    {moneyFormatter.format(order.needToPayAmount)}
                  </div>
                </div>
                <div className={cls.cardValuesRow}>
                  <div className={cls.cardLabel}>Paid amount</div>
                  <div className={cls.cardValue}>
                    {moneyFormatter.format(order.isPaid ? order.needToPayAmount : 0)}
                  </div>
                </div>
              </div>
            </div>
          </InitialCard>
        </div>
        <div className={clsx(cls.card, cls.withLink)} onClick={() => redirect(`/user/${order.userId}?initial`)}>
          <InitialCard contentClass={cls.content}>
            <div className={cls.title}>User</div>
            <div className={cls.cardInfo}>
              <div className={cls.icon}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <g clipPath="url(#clip0_4_249)">
                    <path d="M13.3301 6.70901H21.3302C21.9549 6.70901 21.9556 7.63585 21.3302 7.63585H13.3301C12.7054 7.63585 12.7047 6.70901 13.3301 6.70901Z" fill="white"/>
                    <path d="M21.3302 10.1185H13.3301C12.7054 10.1185 12.7047 11.0454 13.3301 11.0454H21.3302C21.9549 11.0454 21.9556 10.1185 21.3302 10.1185Z" fill="white"/>
                    <path d="M21.3302 13.5287H13.3301C12.7054 13.5287 12.7047 14.4555 13.3301 14.4555H21.3302C21.9549 14.4555 21.9556 13.5287 21.3302 13.5287Z" fill="white"/>
                    <path d="M9.05571 7.63585H11.0277C11.6524 7.63585 11.653 6.70901 11.0277 6.70901H9.05571C8.43096 6.70901 8.43033 7.63585 9.05571 7.63585Z" fill="white"/>
                    <path d="M9.05571 11.0089H11.0277C11.6524 11.0089 11.653 10.0821 11.0277 10.0821H9.05571C8.43096 10.0821 8.43033 11.0089 9.05571 11.0089Z" fill="white"/>
                    <path d="M9.05571 14.382H11.0277C11.6524 14.382 11.653 13.4551 11.0277 13.4551H9.05571C8.43096 13.4551 8.43033 14.382 9.05571 14.382Z" fill="white"/>
                    <path d="M13.33 16.8282H9.05571C8.43096 16.8282 8.43033 17.755 9.05571 17.755H13.33C13.9547 17.755 13.9553 16.8282 13.33 16.8282Z" fill="white"/>
                    <path d="M9.05571 4.22636H15.6323C16.257 4.22636 16.2576 3.29953 15.6323 3.29953H9.05571C8.43096 3.29953 8.43033 4.22636 9.05571 4.22636Z" fill="white"/>
                    <path d="M24.9905 15.4565V8.32666H24.995V1.97477C24.995 0.886051 24.0909 0 22.9801 0H7.40619C6.29539 0 5.39137 0.886051 5.39137 1.97477V11.3086H2.01483C0.904025 11.3086 0 12.1946 0 13.2834V20.8951C0 22.6233 1.4298 23.9994 3.16724 24C3.0897 24 12.4836 24 15.3943 24H22.2829V23.9944H23.8413C24.4799 23.9944 25 23.4847 25 22.8588V17.0846V15.4565H24.9905ZM24.0304 16.6916V22.0197C24.0304 22.5974 23.5507 23.0676 22.9612 23.0676H16.1199C16.1199 23.0676 16.1199 23.0713 16.1199 23.0732H5.42478C5.98838 22.5121 6.337 21.7429 6.337 20.8951V7.30282V1.97477C6.337 1.39704 6.81675 0.926832 7.40619 0.926832H22.9801C23.5696 0.926832 24.0493 1.39704 24.0493 1.97477V15.4565H24.0304V16.6916Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_4_249">
                      <rect width="25" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className={cls.cardValues}>
                <div className={cls.cardValuesRow}>
                  <div className={cls.cardLabel}>Full name</div>
                  <div className={cls.cardValue}>{order.user?.fullName}</div>
                </div>
                <div className={cls.cardValuesRow}>
                  <div className={cls.cardLabel}>E-mail</div>
                  <div className={cls.cardValue}>{order.user?.email}</div>
                </div>
                <div className={cls.cardValuesRow}>
                  <div className={cls.cardLabel}>Phone</div>
                  <div className={cls.cardValue}>{order.user?.phone}</div>
                </div>
                <div className={cls.cardValuesRow}>
                  <div className={cls.cardLabel}>User state</div>
                  <div className={cls.cardValue}>{order.user?.state?.name}</div>
                </div>
                <div className={cls.cardValuesRow}>
                  <div className={cls.cardLabel}>User status</div>
                  <div className={cls.cardValue}>
                    <OrderStatus
                      isMinimal
                      status={{
                        label: order.user?.statusEn || '',
                        value: order.user?.status || '',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </InitialCard>
        </div>
        <div className={clsx(cls.card, cls.fullWidth)}>
          <InitialCard contentClass={clsx(cls.content, cls.quotationCard)}>
            <div className={cls.title}>Request Quotation confirmation</div>
            <div className={cls.dates}>
              <div className={cls.datesCol}>
                <Input placeholder="Payment lives hours" className={cls.dateInput} value={order.paymentLivesHours || ''} disabled />
              </div>
              <div className={cls.datesCol}>
                <div className={cls.datesLabel}>Payment starts at</div>
                <DatePicker placeholder="dd.mm.yyy --:--" disabled value={paymentStartsAt} format={dateFormat} />
              </div>
              <div className={cls.datesCol}>
                <div className={cls.datesLabel}>Payment expires at</div>
                <DatePicker placeholder="dd.mm.yyy --:--" disabled value={paymentExpiresAt} format={dateFormat} />
              </div>
              <div className={cls.datesCol}>
                <div className={cls.datesLabel}>Payment paid at</div>
                <DatePicker placeholder="dd.mm.yyy --:--" disabled value={paymentPaidAt} format={dateFormat} />
              </div>
            </div>

            <div className={cls.composition}>
              <OrderProducts
                composition={composition}
                setComposition={setComposition}
                setInvoiceTotal={setInvoiceTotal}
              />
            </div>

            <div className={cls.quotationActions}>
              {(!isOrderConfirmed && !isOrderPaid) && (
                <Button variant="contained" className={cls.quotationSubmit} onClick={() => setIsConfirmModalOpen(true)}>
                  Confirm order
                </Button>
              )}
              <Button variant="contained" className={cls.quotationRequest} onClick={() => setIsQuotationModalOpen(true)}>
                Request quotation
              </Button>
            </div>
          </InitialCard>
        </div>
        <div className={cls.card}>
          <InitialCard contentClass={cls.content}>
            <div className={cls.title}>Transactions</div>
            <div className={cls.transactions}>
              {order.transactions?.map((transaction: OrderTransaction) => {
                const date = moment(transaction.createdAt).format('DD MMM YYYY, HH:mm');

                return (
                  <div key={transaction.id} className={cls.historyRow}>
                    <div className={cls.transactionsContent}>
                      <div className={cls.transactionsLabel}>{transaction.title}</div>
                      <div className={cls.transactionsDate}>{date}</div>
                    </div>
                    <div className={cls.transactionsSummary}>
                      <div className={cls.transactionsAmount}>
                        {transaction.type === 'income' ? '+' : '-'} {moneyFormatter.format(transaction.amount)}
                      </div>
                      <div className={cls.transactionsDiff}>
                        {moneyFormatter.format(transaction.balanceBefore)}
                        <span>{'->'}</span>
                        {moneyFormatter.format(transaction.balanceAfter)}
                      </div>
                    </div>
                  </div>
                );})}
            </div>
          </InitialCard>
        </div>
        <div className={cls.card}>
          <InitialCard contentClass={cls.content}>
            <div className={cls.title}>History</div>
            <div className={cls.history}>
              {order.history?.map((historyRow: CreditHistory) => {
                const date = moment(historyRow.createdAt).format('DD MMM YYYY, HH:mm');
                return (
                  <div key={historyRow.id} className={cls.historyRow}>
                    <div className={cls.historyContent}>
                      <div className={cls.historyLabel}>{historyRow.title}</div>
                      <div className={cls.historyDate}>{date}</div>
                    </div>
                    <div className={cls.historyDiff}>
                      {historyRow.statusBefore}
                      {(historyRow.statusBefore && historyRow.statusAfter) && <span>→</span>}
                      {historyRow.statusAfter}
                    </div>
                  </div>
                );})}
            </div>
          </InitialCard>
        </div>

        <div className={cls.actions}>
          <Button variant="contained" className={cls.actionSubmit} onClick={submit}>Save</Button>
          <Button variant="contained" className={cls.actionCancel} onClick={cancel}>Cancel</Button>
          <Button variant="contained" className={cls.actionDelete} onClick={() => setIsDialogOpen(true)}>Delete</Button>
        </div>

        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure?
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)}>
              No
            </Button>
            <Button onClick={remove} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Modal
        title="Request Quotation"
        open={isQuotationModalOpen}
        onOk={requestQuotation}
        onCancel={() => setIsQuotationModalOpen(false)}
        className={cls.modal}
        centered
        okText="Yes"
        cancelText="No"
        okButtonProps={{}}
      >
        <div className={cls.modalContent}>
          <Space size={24}>
            <div>Quotation prices available hours:</div>
            <InputNumber
              defaultValue={requestHours}
              className={cls.hoursInput}
              onChange={changeHours}
            />
          </Space>
          <div>
            {'Do you really want to save product prices and make a request for a quotation confirmation?'}
          </div>
        </div>
      </Modal>

      <Modal
        title="Confirm order"
        open={isConfirmModalOpen}
        onOk={confirm}
        onCancel={() => setIsConfirmModalOpen(false)}
        className={cls.modal}
        centered
        okText="Yes"
        cancelText="No"
      >
        <div className={cls.modalContent}>
          <Space size={24}>
            <div className={cls.modalSummary}>Order total:</div>
            <div className={cls.modalSummary}>{moneyFormatter.format(invoiceTotal)}</div>
          </Space>

          <div className={cls.modalSelect}>
            <Select
              options={creditsList}
              placeholder="Select a credit"
              onChange={(creditId) => onCreditSelect(+creditId)}
            />
          </div>
        </div>
      </Modal>
    </InitialPage>
  );
};
