import { Http } from './http';
import {
  CreateFinanceCostParams,
  FinanceCost,
  FinanceCostOptions,
  FinanceDocument,
} from '@types';

class FinanceCostHttp extends Http {
  async create(params: CreateFinanceCostParams) {
    const res = await this.post<FinanceCost>('/finance-cost', params);

    return res.data;
  }

  async update(params: CreateFinanceCostParams, id: number) {
    const res = await this.put<FinanceCost>(`/finance-cost/${id}`, params);

    return res.data;
  }

  async remove(id: number) {
    const res = await this.delete<FinanceCost>(`/finance-cost/${id}`);

    return res.data;
  }

  async options(id?: number | string) {
    const res = await this.get<FinanceCostOptions>('/finance-cost/options', {
      params: { id },
    });

    return res.data;
  }

  async addDocument(params: {id: number, fileIds: number[], type?: string}) {
    const { id } = params;
    const url = `/finance-cost/${id}/documents`;
    const res = await this.post<FinanceDocument[]>(url, params);

    return res.data;
  }

  async removeDocument(params: {id: number, documentId: number}) {
    const { id, documentId } = params;
    const url = `/finance-cost/${id}/documents/${documentId}`;
    const res = await this.delete<FinanceDocument[]>(url);

    return res.data;
  }
}

export const financeCostHttp = new FinanceCostHttp();
