import React, { useEffect, useState } from 'react';
import { SelectArrayInputProps } from 'react-admin';

import { SelectArrayInput } from '@components';
import { cropHttp } from '@network/crop-http';

interface OptionProps {
  id: number;
  name: string;
}

export const CreditCropsFilter = (props: SelectArrayInputProps) => {
  const [options, setOptions] = useState<OptionProps[]>([]);

  useEffect(() => {
    (async () => {
      const crops = await cropHttp.getAll();
      setOptions(crops.map(crop => ({
        id: crop.id,
        name: crop.nameMx,
      })).sort((a, b) => a.name > b.name ? 1 : -1));
    })();
  }, []);

  return (
    <SelectArrayInput
      {...props}
      choices={options}
      className="MS-field"
      style={{ width: 280 }}
      alwaysOn
    />
  );
};
