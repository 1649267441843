import { Http } from './http';

class DoctypeHttp extends Http {

  async move(doctypeId: number, direction: 'up' | 'down', resource: string) {
    const url = `/${resource}/${doctypeId}/move`;
    const res = await this.get<boolean>(url, { params: { direction } });

    return res.data;
  }

  async downloadDocuments(resource: string, creditId: number) {
    const url = `/${resource}/download/documents/${creditId}`;
    const res = await this.get(url);

    return res.data;
  }

  async remove(doctypeId: number) {
    const url = `/doctype/${doctypeId}`;
    const res = await this.delete(url);

    return res.data;
  }

  async getAll() {
    const url = '/doctype/';
    const res = await this.get(url);

    return res.data;
  }
}

export const doctypeHttp = new DoctypeHttp();
