import React, { ChangeEvent } from 'react';
import Checkbox from '@mui/material/Checkbox';

import { CreditInvoice, CreditInvoiceFile } from '@types';
import { FileControlled } from '@components';
import { SentBy } from '@pages/credit/SentBy';
import cls from './credit-invoice-note.module.css';

interface CreditInvoiceNoteProps {
  invoice: CreditInvoice;
  creditCode: string;
  file: CreditInvoiceFile;
  date: string;
  comment: string;
  updateValues: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const CreditInvoiceNote = ({
  invoice, creditCode, file, date, comment, updateValues,
}: CreditInvoiceNoteProps) => {
  const ext = file.isPdf ? 'pdf' : 'jpg';
  const downloadFilename = `Invoice#${invoice.id}_${creditCode}.${ext}`;

  return (
    <>
      <div className={cls.note}>
        <div className={cls.noteDate}>
          Update {date}
        </div>
        <div className={cls.noteExtracted}>
          <span>Extracted from XML</span>
          <Checkbox
            checked={invoice.isXml}
            size="large"
            disabled
            style={{ transform: 'scale(1.5)', marginTop: -5 }}
          />
        </div>
        <textarea
          name="comment"
          onChange={updateValues}
          className={cls.noteInput}
          placeholder="Comments"
          value={comment}
        />
      </div>
      <div className={cls.file}>
        <div className={cls.sendBy}>
          <SentBy
            type={invoice.managerId ? 'manager' : invoice.adminId ? 'admin' : 'user'}
          />
        </div>
        <FileControlled
          adminUrl={file.adminUrl}
          downloadFilename={downloadFilename}
        />
      </div>
    </>
  );
};
