import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-admin';
import { clsx } from 'clsx';
import { notification } from 'antd';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TablePagination,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PlusIcon from '@mui/icons-material/Add';

import { axiosErrorToString, dateFormatter } from '@utils';
import { CreditCall } from '@types';
import { creditCallHttp } from '@network';
import { CallStatusField } from '@pages/credit-call/CallStatusField';
import { CallRiskField } from '@pages/credit-call/CallRiskField';
import cls from './credit-calls-list.module.css';

interface Props {
  creditId: number;
  calls: CreditCall[];
}

export const CreditCallsList = (props: Props) => {
  const [calls, setCalls] = useState<CreditCall[]>(props.calls);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsCount, setRowsCount] = useState<number>(10);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const checkHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const orderId = +e.target.name;

    if (orderId === 0) {
      setSelectedIds(!e.target.checked ? [] : calls.map(o => o.id));

      return;
    }

    if (selectedIds.includes(orderId)) {
      setSelectedIds(prev => prev.filter(i => i !== orderId));
    } else {
      setSelectedIds([...selectedIds, orderId]);
    }
  };

  const fetchCalls = useCallback(async () => {
    try {
      const nextCalls = await creditCallHttp.getCallsByCredit(props.creditId);
      setCalls(nextCalls);
    } catch (err) {
      const errorMessage = axiosErrorToString(err);
      notification.error({ message: errorMessage, duration: 8 });
      setCalls([]);
    }
  }, [props.creditId]);

  const remove = async () => {
    setIsDialogOpen(false);

    try {
      await creditCallHttp.removeCalls(selectedIds);
      void fetchCalls();

      setSelectedIds([]);
    } catch (err) {
      const errorMessage = axiosErrorToString(err);
      notification.error({ message: errorMessage, duration: 8 });
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (e: any) => {
    setRowsCount(+e.target.value);
  };

  useEffect(() => void fetchCalls(), [props.creditId]);

  const rows = rowsCount > 0
    ? calls.slice(currentPage * rowsCount, currentPage * rowsCount + rowsCount)
    : calls;

  return (
    <div className={`${cls.table} RaDatagrid-tableWrapper`}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="user-orders-list"
        >
          <TableHead>
            <TableRow>
              <TableCell className={cls.checkCol}>
                <Checkbox name="0" onChange={checkHandler} />
              </TableCell>
              <TableCell className={cls.thead}>
                Task title/type
              </TableCell>
              <TableCell className={cls.thead}>
                Task admin
              </TableCell>
              <TableCell className={cls.thead}>
                Contact by
              </TableCell>
              <TableCell className={cls.thead}>
                Contact at
              </TableCell>
              <TableCell className={cls.thead}>
                Call Status
              </TableCell>
              <TableCell className={cls.thead}>
                Call Risk
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((call) => (
              <TableRow key={call.id}>
                <TableCell className={cls.checkCol}>
                  <Checkbox name={`${call.id}`}
                    onChange={checkHandler}
                    checked={selectedIds.includes(call.id)} />
                </TableCell>
                <TableCell className={clsx(cls.value, cls.code)}>
                  <Link to={`/credit-call/${call.id}`} className={cls.link}>
                    {call.title}
                  </Link>
                </TableCell>
                <TableCell
                  className={clsx(cls.value, cls.credit)}
                >
                  {call.responsibleAdmin ? (
                    <Link to={`/admin/${call.responsibleAdmin.id}`} className={cls.link}>
                      {call.responsibleAdmin.name}
                    </Link>
                  ) : '–'}
                </TableCell>
                <TableCell className={cls.value}>
                  {call.contactBy ? dateFormatter.toDateSlash(call.contactBy) : '–'}
                </TableCell>
                <TableCell className={cls.value}>
                  {call.contactAt ? dateFormatter.toDateSlash(call.contactAt) : '–'}
                </TableCell>
                <TableCell className={cls.value}>
                  <CallStatusField source="status" callStatus={call.status} />
                </TableCell>
                <TableCell className={cls.value}>
                  {call.risk ? (
                    <CallRiskField source="risk" callRisk={call.risk} />
                  ) : '–'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={calls?.length}
          page={currentPage}
          rowsPerPage={rowsCount}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <div className={cls.actions}>
        {selectedIds.length !== 0 ? (
          <button className={cls.delete} onClick={() => setIsDialogOpen(true)}>
            <DeleteIcon fontSize="small" /> Delete
          </button>
        ) : null}
        <Link to={`/credit-call/create?creditId=${props.creditId}`}>
          <button className={cls.addBtn} onClick={() => setIsDialogOpen(true)}>
            <PlusIcon fontSize="small" /> Add Task
          </button>
        </Link>
      </div>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to delete this calls?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>
            No
          </Button>
          <Button onClick={remove} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
