import { FunctionField, FunctionFieldProps } from 'react-admin';
import Chip from '@mui/material/Chip';
import { Color } from '@mui/material';

export interface ColoredOption {
  id: string;
  name: string;
  color: Color | string;
}

export const riskStateOptions: ColoredOption[] = [
  { id: 'pending', name: 'Pending', color: '#74706B' },
  { id: 'complete', name: 'Complete', color: '#042E6B' },
];

interface Props {
  source: string;
  callStatus?: string;
  className?: string;
  width?: number;
}

export const RiskStateField = (props: Props & Partial<FunctionFieldProps>) => {
  const { className, width, callStatus, ...restProps } = props;

  return (
    <FunctionField {...restProps} render={(record: any) => {
      const status = callStatus || record[props.source];
      const statusName = riskStateOptions.find(o => o.id === status)?.name;
      const color: any = riskStateOptions.find(o => o.id === status)?.color;
      if (!status || !color) {
        return null;
      }

      return (
        <Chip
          label={statusName || status}
          size="small"
          style={{
            backgroundColor: typeof color === 'string'
              ? color
              : (color && color[500] ? color[500] : '#74706B'),
            color: 'white',
            height: 20,
            fontSize: 11,
            width: width || '100%',
          }}
          className={className}
        />
      );
    }} />
  );
};
