import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

import { InitialCard } from './initial-card';

interface Props {
  title: string;
  json: any;
  mt?: number;
  isError?: boolean;
}

export const InitialJson = ({ title, json, mt, isError }: Props) => {
  const cls = useStyles();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => void setExpanded(json ? false : true), [json]);

  const swapExpanded = () => void setExpanded(!expanded);

  return (
    <InitialCard autoHeight mt={mt} wrapperClass={clsx(isError && cls.isError)}>
      <Accordion expanded={expanded} disableGutters={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={cls.summary}
          onClick={swapExpanded}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails className={cls.details}>
          <pre className={cls.json}>
            {typeof json === 'string' ? json : json ? JSON.stringify(json, null, 2) : '–'}
          </pre>
        </AccordionDetails>
      </Accordion>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  summary: {
    color: '#272727',
    fontFamily: 'Outfit, sans-serif',
    fontSize: 15,
    fontWeight: 700,
  },
  isError: {
    backgroundColor: '#ff8383',
  },
  details: {
    width: '100%',
    maxWidth: '100%',
    overflow: 'scroll',
  },
  json: {
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#F5F5DC',
    color: '#272727',
    fontFamily: 'Outfit, sans-serif',
    fontSize: 13,
    lineHeight: 1,
    fontWeight: 400,
    padding: '14px 20px',
    margin: 0,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
});
