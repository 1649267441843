import { Col, Row } from 'antd';

import { Card, FinanceDashboardCardProps } from '@pages/finance-dashboard/components/Card/Card';
import cls from './Card.module.css';

interface CardsProps {
  cplAvg: number;
  mqlAvg: number;
  budgetAvg: number;
  budgetTotal: number;
}

export const Cards = ({ cplAvg, mqlAvg, budgetAvg, budgetTotal }: CardsProps) => {
  const cplData: FinanceDashboardCardProps = {
    title: 'CPL',
    list: [{ label: 'Average', value: cplAvg, isMoney: true }],
  };
  const mqlData: FinanceDashboardCardProps = {
    title: 'MQL',
    list: [{ label: 'Average', value: mqlAvg, isMoney: false }],
  };
  const budgetData: FinanceDashboardCardProps = {
    title: 'Budget',
    list: [
      { label: 'Average', value: budgetAvg, isMoney: true },
      { label: 'Total Spent', value: budgetTotal, isMoney: true },
    ],
  };

  return (
    <Row className={cls.row} gutter={[20, 20]}>
      <Col xs={24} md={12} xl={8} className={cls.col}>
        <Card {...cplData} />
      </Col>
      <Col xs={24} md={12} xl={8} className={cls.col}>
        <Card {...mqlData}/>
      </Col>
      <Col xs={24} md={12} xl={8} className={cls.col}>
        <Card {...budgetData}/>
      </Col>
    </Row>
  );
};
