import React, { useState } from 'react';
import { FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Select } from 'antd';
import { clsx } from 'clsx';

import cls from './input-select.module.css';

type Option = {value: string | number | null, label: string};

interface Props {
  label: string;
  selectedValue: number | string | string[] | null;
  options: Option[];
  onChange: (nextValue: any) => void;
  labelWidth?: number;
  error?: string;
  className?: string;
  centered?: boolean;
  multiple?: boolean;
  displayEmpty?: boolean;
}

export const InputSelectAutocomplete = ({
  label,
  selectedValue,
  options,
  onChange,
  labelWidth,
  error,
  className,
  centered,
  displayEmpty,
}: Props) => {
  const [value, setValue] = useState<any>(selectedValue || '');
  const css = useStyles();

  const optionsWithEmpty: Option[] = displayEmpty
    ? [{ value: null, label: ' ' }, ...options]
    : options;

  return (
    <div className={cls._}>
      <label
        className={cls.label}
        style={{ minWidth: labelWidth ? labelWidth : 100 }}
      >
        {label}
      </label>
      <FormControl
        variant="standard"
        className={clsx(cls.form, className, centered && cls.centered)}
      >
        <Select
          showSearch
          className={css.autocompleteField}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase()
              .localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={optionsWithEmpty}
          value={value}
          onChange={nextValue => {
            setValue(nextValue);
            onChange && onChange(nextValue);
          }}
          suffixIcon={<ArrowDropDownIcon className={css.suffixIcon} />}
        />

        {error && <span className={cls.error}>{error}</span>}
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles({
  autocompleteField: {
    width: '100%',
    '& .ant-select-selector': {
      paddingLeft: '0px !important',
      fontSize: 16,
      borderColor: 'transparent',
      boxShadow: 'none !important',
      padding: 0,
      border: 'none !important',
      borderBottom: '1px solid rgba(0, 42, 119, 0.10) !important',
      outline: 'none',
    },
    '& .ant-select-selection-search': {
      left: '0 !important',
    },
  },
  suffixIcon: {
    display: 'inline-block',
    position: 'absolute',
    right: -22,
    top: -6,
    color: '#777',
    marginRight: 5,
  },
});
