import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { clsx } from 'clsx';
import dayjs from 'dayjs';
import { InputNumber } from 'antd';
import { makeStyles } from '@mui/styles';

import { moneyFormatter } from '@utils';
import { MonthData } from './AllocationRevenueGoals';

const MAX_AMOUNT = 10000000000;

interface QuarterProps {
  quarter: MonthData[];
  index: number;
  setYearData: Dispatch<SetStateAction<MonthData>>;
  isEditable?: boolean;
  setHasUpdated: Dispatch<SetStateAction<boolean>>;
}

const getMonthName = (index: number) => {
  return dayjs().month(index).format('MMMM');
};

export const QuarterGoals = ({
  quarter, index, isEditable, setYearData, setHasUpdated,
}: QuarterProps) => {
  const cls = useStyles();

  const [form, setForm] = useState({});

  const handleMonthChange = (value: any, month: number, key: string) => {
    setForm((prev) => ({
      ...prev,
      [month]: {
        ...prev[month],
        [key]: value > MAX_AMOUNT ? MAX_AMOUNT : value,
      },
    }));
    setHasUpdated(true);
  };

  const summary = useMemo(() => {
    const values = Object.values(form) as unknown as MonthData[];
    
    return values.reduce(
      (acc, month) => {
        acc.allocation += +month.allocation;
        acc.revenue += +month.revenue;
        return acc;
      },
      { allocation: 0, revenue: 0 },
    );
  }, [form]);

  useEffect(() => {
    const data = {};
    for (const item of quarter) {
      data[item.month] = item;
    }

    setForm(data);
  }, [quarter]);

  const quarterName = `Q${index + 1}`;

  useEffect(() => {
    setYearData(prev => ({
      ...prev,
      ...form,
    }));
  }, [form]);

  return (
    <div className={cls.quarter}>
      <div className={cls.head}>
        <div className={cls.col} />
        <div className={cls.col}>Allocation</div>
        <div className={cls.col}>Revenue</div>
      </div>

      <div className={cls.content}>
        {Object.values(form).map((item: any, index) => (
          <div key={index} className={cls.row}>
            <div className={cls.col}>
              {getMonthName(item.month || 0)}
            </div>

            <div className={cls.col}>
              <InputNumber
                controls={false}
                formatter={(value: any) => {
                  const maxValue = Math.min(value, MAX_AMOUNT);

                  return `$ ${maxValue.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                }}
                parser={(value: any) => +value.replace(/\$\s?|(,*)/g, '')}
                className={clsx(cls.input, isEditable && cls.isEditable)}
                value={item.allocation}
                disabled={!isEditable}
                onChange={(e) => {
                  handleMonthChange(e, item.month, 'allocation');
                }}
              />
            </div>

            <div className={cls.col}>
              <InputNumber
                controls={false}
                formatter={(value: any) => {
                  const maxValue = Math.min(value, MAX_AMOUNT);
                  
                  return `$ ${maxValue.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                }}
                parser={(value: any) => +value.replace(/\$\s?|(,*)/g, '')}
                className={clsx(cls.input, isEditable && cls.isEditable)}
                max={MAX_AMOUNT}
                value={item.revenue}
                disabled={!isEditable}
                onChange={(value) => {
                  handleMonthChange(value, item.month, 'revenue');
                }}
              />
            </div>
          </div>
        ))}
      </div>

      <div className={cls.summary}>
        <div className={cls.col}>
          {quarterName}
        </div>
        <div className={cls.col}>
          {moneyFormatter.format(summary.allocation)}
        </div>
        <div className={cls.col}>
          {moneyFormatter.format(summary.revenue)}
        </div>
      </div>
    </div>
  );
};



const useStyles = makeStyles({
  quarter: {
    fontSize: 15,
  },
  head: {
    display: 'flex',
    flexWrap: 'wrap',
    fontWeight: 700,

    '& div:not(:first-child)': {
      paddingLeft: 28,
    },
  },
  content: {
    '& > div:nth-child(odd)': {
      backgroundColor: 'rgba(4,46,107,0.28)',
    },
  },
  summary: {
    display: 'flex',
    flexWrap: 'wrap',
    fontWeight: 700,

    '& div:not(:first-child)': {
      paddingLeft: 28,
    },
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: 2,
  },
  col: {
    width: 'calc(100% / 3)',
    padding: 10,

    '&:first-child': {
      textAlign: 'center',
    },
  },
  input: {
    width: '100%',
    backgroundColor: 'transparent !important',
    position: 'relative',
    borderRadius: 0,
    paddingLeft: 18,
    border: 'none',

    '& input': {
      width: '100%',
      fontSize: 15,
      padding: 0,
    },

    '& input:disabled': {
      backgroundColor: 'transparent !important',
      color: '#000',
      cursor: 'default',
    },
  },
  isEditable: {
    borderBottom: '1px solid rgba(0,0,0,0.4)',

    '&:hover': {
      boxShadow: 'none',
      borderBottom: '1px solid rgba(0,0,0,0.4)',
    },

    '&:focus': {
      boxShadow: 'none',
      borderBottom: '1px solid rgba(0,0,0,0.4)',
    },
  },
});
