import React, { useState } from 'react';
import { Switch } from 'antd';
import { useRefresh } from 'react-admin';

import { Credit } from '@types';
import { InitialCard, InitialRow } from '@components';
import { creditHttp } from '@network';
import cls from './credit-accredited.module.css';

export const CreditAccredited = ({ credit }: {credit: Credit}) => {
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();

  const isCompany = credit.accredited === 'company';

  const switchIsIndividual = async (isIndividual: boolean) => {
    const nextAccredited = isIndividual ? 'company' : 'individual';
    try {
      setLoading(true);
      await creditHttp.updateAccredited(credit.id, nextAccredited);
      refresh();
    } finally {
      setLoading(false);
    }
  };

  const switchClick = (_: boolean, e: any) => {
    if (loading) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const stopPropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <InitialCard autoHeight wrapperClass={cls.wrapper}>
      <InitialRow title="Type of Accredited:" className={cls.row} labelCentered>
        <div className={cls.company}>
          <div className={cls.label}>Individual</div>
          <div onClick={stopPropagation}>
            <Switch
              defaultChecked={isCompany}
              onClick={switchClick}
              onChange={switchIsIndividual}
              className={isCompany ? cls.companySwitchChecked : cls.companySwitch}
            />
          </div>
          <div className={cls.label}>Company</div>
        </div>
      </InitialRow>
    </InitialCard>
  );
};
