import {
  TextInput as RaTextInput,
  NumberInput as RaNumberInput,
  SelectInput as RaSelectInput,
  DateInput as RaDateInput,
  DateTimeInput as RaDateTimeInput,
  BooleanInput as RaBooleanInput,
  NullableBooleanInput as RaNullBooleanInput,
  PasswordInput as RaPasswordInput,
  ReferenceInput as RaReferenceInput,
  SelectArrayInput as RaSelectArrayInput,
  required,
} from 'react-admin';

interface ExtraParams {
  isText?: boolean;
  isSelect?: boolean;
  isNullableBoolean?: boolean;
  isDate?: boolean;
  minWidth?: number;
}

const s = (Component: any, params: ExtraParams = {}) => (props: any) => {
  const extra: any = {};
  const classNames: string[] = [];

  if (params.isText) {
    extra.format = props.format ? props.format : (v: any) => v || '';
    extra.parse = props.parse ? props.parse : (v: any) => v || null;
  }
  if (params.isDate) {
    extra.value = null;
  }
  if (params.isSelect) {
    classNames.push('is-select');
    extra.parse = props.parse ? props.parse : (v: any) => v || '';
  }
  if (params.isNullableBoolean) {
    classNames.push('is-select');
  }
  if (!props.label) {
    classNames.push('without-label');
  }
  if (params.isSelect && props.required && !props.resettable) {
    extra.required = false;
    extra.validate = [required()];
  }
  if (params.isSelect && props.resettable) {
    classNames.push('resettable-select');
  }
  if (params.minWidth) {
    extra.style = { minWidth: params.minWidth };
  }

  return (
    <Component
      fullWidth
      variant="standard"
      size="small"
      className={classNames.join(' ')}
      {...props}
      {...extra}
    />
  );
};

export const TextInput = s(RaTextInput, { isText: true }) as typeof RaTextInput;
export const NumberInput = s(RaNumberInput) as typeof RaNumberInput;
export const SelectInput =
  s(RaSelectInput, { isSelect: true, minWidth: 165 }) as typeof RaSelectInput;
export const DateInput = s(RaDateInput, { isDate: true }) as typeof RaDateInput;
export const DateTimeInput = s(RaDateTimeInput) as typeof RaDateTimeInput;
export const BooleanInput = s(RaBooleanInput, { isSelect: true }) as typeof RaBooleanInput;
export const NullableBooleanInput =
  s(RaNullBooleanInput, { isNullableBoolean: true, minWidth: 165 }) as typeof RaNullBooleanInput;
export const PasswordInput = s(RaPasswordInput) as typeof RaPasswordInput;
export const ReferenceInput = s(RaReferenceInput) as typeof RaReferenceInput;
export const SelectArrayInput =
  s(RaSelectArrayInput, { isSelect: true }) as typeof RaSelectArrayInput;
