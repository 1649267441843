import { EditProps, Edit, SimpleForm } from 'react-admin';
import { Row, Col, Container, ImageInput, TextInput, Forbidden } from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Crop category: {record.nameMx}</span>) : null;

export const CropCategoryEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <SimpleForm toolbar={rbacEdit ? undefined : false}>
        <Container>
          <Row>
            <Col>
              <TextInput source="nameMx" required />
              <TextInput source="nameEn" />
            </Col>
            <Col>
              <TextInput source="id" disabled />
            </Col>
            <Col>
              <ImageInput source="image" required />
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </Edit>
  );
};
