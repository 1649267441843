import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { PropsWithChildren } from 'react';

import cls from './FormRow.module.css';

type ColSizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
type ColValuesType = 'title' | 'children';

type ColValues = {
  [key in ColValuesType]: number;
}

export type ColProps = {
  [key in ColSizeType]?: ColValues;
};

interface Props {
  title?: string | JSX.Element;
  fullWidth?: boolean;
  width?: ColProps;
  rowClassName?: string;
  colTitleClassName?: string;
  colChildrenClassName?: string;
}

export type AntColSize = {
  [key in ColSizeType]?: number;
}

export const FormRow = ({
  children,
  title,
  fullWidth,
  width,
  colTitleClassName,
  colChildrenClassName,
  rowClassName,
}: PropsWithChildren<Props>) => {
  const columnWidth = (column: ColValuesType): AntColSize => {
    if (fullWidth) {
      return { sm: 24 };
    }
    if (width && JSON.stringify(width) !== '{}') {
      const obj: AntColSize = {};
      Object.keys(width).forEach(k => {
        const key = k as ColSizeType;
        // @ts-ignore
        obj[key] = width[key][column];
      });
      return obj;
    }
    return column === 'title' ? { sm: 6 } : { sm: 18 };
  };

  return (
    <Row className={clsx(cls.row, rowClassName)}>
      <Col {...columnWidth('title')} className={clsx(
        cls.col,
        cls.Title,
        colTitleClassName,
      )}>
        {title ? title : ''}
      </Col>
      <Col {...columnWidth('children')} className={clsx(
        cls.col,
        cls.children,
        colChildrenClassName,
      )}>
        {children}
      </Col>
    </Row>
  );
};

