import { clsx } from 'clsx';
import { Col, Form, Row } from 'antd';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Edit, EditProps, useGetOne, useNotify, useRedirect } from 'react-admin';
import moment from 'moment-timezone';

import { AccountTableItemProps, Table } from '../components';
import { axiosErrorToString, moneyFormatter } from '@utils';
import { financeAccountHttp, financeDepositHttp, financeWithdrawalHttp } from '@network';
import { useEditProps, useRbacEdit } from '@hooks';
import { FinanceAccountForm } from '../FinanceAccountForm';
import { Container, Forbidden, PageHeader } from '@components';
import {
  CreateFinanceAccountParams,
  FinanceAccount,
  FinanceAccountUserOption,
  FinanceDeposit,
  FinanceDepositOptions,
  FinanceWithdrawal,
  FinanceWithdrawalOptions,
} from '@types';
import cls from './finance-account-edit.module.css';

export const FinanceAccountEdit = (props: EditProps) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const rbacEdit = useRbacEdit();
  const editProps = useEditProps();
  const [form] = Form.useForm<CreateFinanceAccountParams>();
  const [depositConcepts, setDepositConcepts] = useState<string[]>([]);
  const [withdrawalConcepts, setWithdrawalConcepts] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [selectedUser, setSelectedUser] = useState<FinanceAccountUserOption | null>(null);
  const [formValues, setFormValues] = useState<CreateFinanceAccountParams | null>(null);

  const Title = ({ record }: { record?: FinanceAccount }) =>
    record ? (<span>Account: {record.code}</span>) : null;

  const { id } = useParams();
  const { data: record } = useGetOne('finance-account', { id });

  const submit = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const params = form.getFieldsValue();
      params.userId = selectedUser?.id;
      const newAccount = await financeAccountHttp.update(params, record.id);
      setIsFirstRender(true);
      addFormData(newAccount);
      notify('Account has been updated successfully.');
    } catch (e) {
      // @ts-ignore
      if (e.errorFields?.length) {
        notify('Form is wrong', { type: 'error' });
      } else {
        notify(axiosErrorToString(e), { type: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const setUser = (data: FinanceAccountUserOption | null) => {
    setIsFirstRender(false);
    setSelectedUser(data);
  };

  const onDelete = async () => {
    try {
      const result = await financeAccountHttp.remove(record.id);

      if (result) {
        notify('Account has been deleted');
        redirect('/finance-account');
      }
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  const addFormData = (account: FinanceAccount) => {
    if (account.user) {
      setSelectedUser({
        code: account.user.code,
        email: account.user.email,
        financeAccountId: account.id,
        fullName: account.user.fullName,
        id: account.user.id,
        phone: account.user.phone || '',
      });
    }
    setFormValues({
      userId: account.userId,
      userCode: account.user?.code || '',
      code: account.code,
      addressee: account.addressee,
      bank: account.bank,
      type: account.type,
      account: account.account,
      branch: account.branch,
      reference: account.reference,
      phone: account.phone,
      email: account.email,
      accountSetup: account.accountSetup,
      balance: account.balance,
    });
  };

  const getOptions = async () => {
    try {
      const depositOptions: FinanceDepositOptions = await financeDepositHttp.options();
      const withdrawalOptions: FinanceWithdrawalOptions = await financeWithdrawalHttp.options();

      if (!depositOptions || !withdrawalOptions) {
        throw new Error('The lists of options has not been received');
      }

      setDepositConcepts(depositOptions.concept);
      setWithdrawalConcepts(withdrawalOptions.concept);
    } catch (e) {
      console.error(e);
    }
  };

  const getTableData = (list: FinanceDeposit[] | FinanceWithdrawal[]): AccountTableItemProps[] => {
    return list?.length ? list.map(item => ({
      code: item.code || '',
      concept: item.concept || '',
      creditCode: item.creditCode || '',
      amount: moneyFormatter.format(item.amount),
      paymentDate: moment(item.paymentDate).format('DD-MM-YYYY'),
    })) : [];
  };

  const addNewDeposit = () => {
    redirect(`/finance-deposit/create?id=${id}`);
  };

  const addNewWithdrawal = () => {
    redirect(`/finance-withdrawal/create?id=${id}`);
  };

  useEffect(() => {
    if (!isFirstRender) {
      form.setFieldValue('email', selectedUser ? selectedUser.email : '');
      form.setFieldValue('phone', selectedUser ? selectedUser.phone : '');
      form.setFieldValue('addressee', selectedUser ? selectedUser.fullName : '');
      form.setFieldValue('reference', selectedUser ? selectedUser.code : '');
    }
  }, [selectedUser]);

  useEffect(() => {
    (async () => {
      await getOptions();
    })();
    if (record) {
      addFormData(record);
    }
  }, [record]);

  if (!rbacEdit) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title/>} {...editProps} className={clsx(cls.page, 'details-page-wrapper')}>
      <>
        <PageHeader
          title="Account"
          linkText="Back to all information of banking manager"
          linkUrl="/finance-account"
        />
        <Container max className={cls.container}>
          <Row gutter={24} className={cls.wrapper}>
            <Col xs={24} className={cls.wrapperCol}>
              <div className={cls.block}>
                <div className={cls.blockHeader}>Complete information</div>
                {!formValues ? null : (
                  <Form
                    form={form}
                    layout="horizontal"
                    onFinish={submit}
                    initialValues={formValues}
                  >
                    <FinanceAccountForm
                      isEdit={true}
                      user={selectedUser}
                      setUser={setUser}
                      loading={loading}
                      setLoading={setLoading}
                      onDelete={onDelete}
                    />
                  </Form>
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={24} className={cls.wrapper}>
            <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColFirst)}>
              <div className={clsx(cls.block, cls.blockMain)}>
                <div className={cls.blockHeader}>Deposits</div>
                <div className={cls.blockMiddleInfo}>
                  {record && depositConcepts.length
                    ? <Table
                      list={getTableData(record.deposits)}
                      conceptCodes={depositConcepts}
                      addNewHandler={addNewDeposit}
                    /> : null}
                </div>
              </div>
            </Col>
            <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColLast)}>
              <div className={clsx(cls.block, cls.blockMain)}>
                <div className={cls.blockHeader}>Withdrawals</div>
                <div className={cls.blockMiddleInfo}>
                  {record && withdrawalConcepts.length
                    ? <Table
                      list={getTableData(record.withdrawals)}
                      conceptCodes={withdrawalConcepts}
                      addNewHandler={addNewWithdrawal}
                    /> : null}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    </Edit>
  );
};
