import { clsx } from 'clsx';
import React, { useRef } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import IconButton from '@mui/material/IconButton';

import { http } from '@network';
import { DownloadIcon } from '@components/FileImage';
import cls from './file-pdf.module.css';

type Props = {
  url: string;
  downloadFilename?: string;
  className?: string;
}

const downloadPdf = async (url: string) => {
  const res = await http.get(url, { responseType: 'blob' });
  const blobResponse = new Blob([res.data as any], { type: 'application/pdf' });
  const blobUrl = URL.createObjectURL(blobResponse);

  return blobUrl;
};

export const FilePdf = ({ url, downloadFilename, className }: Props) => {
  const blob = useRef<string>();

  const onPreview = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!blob.current) {
      blob.current = await downloadPdf(url);
    }
    setTimeout(() => {
      const win = window.open(blob.current, '_blank') as any;
      win.document.title = downloadFilename || 'Document preview';
    }, 0);
  };

  const onDownload = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!blob.current) {
      blob.current = await downloadPdf(url);
    }

    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = blob.current;
      link.download = downloadFilename || 'Document.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(blob.current);
    }
  };

  return (
    <div className={clsx(cls.box, className)} onClick={onPreview} title="preview">
      <div className={clsx(cls.pdfIcon, 'file-pdf-icon')}>
        <FilePdfOutlined />
      </div>

      {downloadFilename && (
        <div className={clsx(cls.download, 'file-pdf-download')} title="download" data-x="download">
          <IconButton onClick={onDownload}>
            <DownloadIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};
