import { FunctionField, FunctionFieldProps } from 'react-admin';
import Chip from '@mui/material/Chip';
import { Color } from '@mui/material';

export interface ColoredOption {
  id: string;
  name: string;
  color: Color | string;
}

export const riskScoreOptions: ColoredOption[] = [
  { id: 'denied', name: 'Denied', color: '#74706B' },
  { id: 'approved', name: 'Approved', color: '#042E6B' },
];

interface Props {
  source: string;
  callStatus?: string;
  className?: string;
  withStatus?: boolean;
  width?: number;
}

export const RiskScoreField = (props: Props & Partial<FunctionFieldProps>) => {
  const { className, width, callStatus, withStatus, ...restProps } = props;

  return (
    <FunctionField {...restProps} render={(record: any) => {
      const status = callStatus || record['riskStatus'];
      const score: number = record['riskScore'] ? +record['riskScore'] : 0;
      let color: Color | string = '#042E6B';

      if (withStatus) {
        color = riskScoreOptions
          .find(o => o.id === status)?.color || riskScoreOptions[0].color;
      }

      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Chip
            label={`${score}%`}
            size="small"
            style={{
              backgroundColor: typeof color === 'string'
                ? color
                : (color && color[500] ? color[500] : '#74706B'),
              color: 'white',
              height: 20,
              fontSize: 11,
              width: width || '100%',
            }}
            className={className}
          />
        </div>
      );
    }} />
  );
};
