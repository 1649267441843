import { makeStyles } from '@mui/styles';
import { CreditRisk } from '@types';
import { moneyFormatter } from '@utils';

export const RiskTermsLegacy = ({ risk }: {risk: CreditRisk}) => {
  const cls = useStyles();
  const credit = risk.credit;
  const user = credit?.user;

  return (
    <table className={cls.table}>
      <tbody>
        <tr>
          <td>Cliente</td>
          <td>{user?.fullName}</td>
          <td>Membresía de Verqor</td>
          <td>{risk.membership || 0}%</td>
        </tr>
        <tr>
          <td>Comprador</td>
          <td>{risk.buyer}</td>
          <td>Comisión de Verqor Paga</td>
          <td>{risk.pagaComission || 0}%</td>
        </tr>
        <tr>
          <td>Suministrado</td>
          <td>{risk.supplier}</td>
          <td>Honorarios de notario</td>
          <td>{moneyFormatter.format(risk.notaryFees || 0)}</td>
        </tr>
        <tr>
          <td>Monto aprobado</td>
          <td>{moneyFormatter.format(risk.approvedAmount || 0)}</td>
          <td>Seguro</td>
          <td>{moneyFormatter.format(risk.insurance || 0)}</td>
        </tr>
        <tr>
          <td>Plazo de credito</td>
          <td>{risk.creditTerm}</td>
          <td rowSpan={2}>Condiciones especiales</td>
          <td rowSpan={2}>{risk.specialConditions}</td>
        </tr>
        <tr>
          <td>Tasa de interés</td>
          <td className={cls.noborder}>{risk.interestRate}%</td>
        </tr>
      </tbody>
    </table>
  );
};

const useStyles = makeStyles({
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& td': {
      borderTop: '1px solid #042E6B',
      borderLeft: '1px solid #042E6B',
      fontSize: 10,
      fontWeight: 400,
      color: '#042E6B',
      width: '20%',
      padding: '3px 8px',
      backgroundColor: 'transparent',
      '&:last-child': {
        borderRight: '1px solid #042E6B',
      },
      '&[rowspan]': {
        borderBottom: '1px solid #042E6B',
        '&:last-child': {
          borderBottomRightRadius: 5,
        },
      },
      '&:nth-child(even)': {
        width: '30%',
        backgroundColor: '#F0F3FB',
      },
    },
    '& tr:first-child td': {
      '&:first-child': {
        borderTopLeftRadius: 5,
      },
      '&:last-child': {
        borderTopRightRadius: 5,
      },
    },
    '& tr:last-child td': {
      borderBottom: '1px solid #042E6B',
      '&:first-child': {
        borderBottomLeftRadius: 5,
      },
      '&:last-child': {
        borderBottomRightRadius: 5,
      },
    },
  },
  noborder: {
    borderRadius: '0 !important',
    borderRight: 'none !important',
  },
});
