import { clsx } from 'clsx';
import { useState } from 'react';
import { Col, Input, Row, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { SupportFaq } from '@types';
import { dateFormatter } from '@utils';
import { FilePreview } from '@pages/support/components';
import { Container, InitialCard, InitialRow, RawHtml } from '@components';
import cls from './FaqView.module.css';

const { Paragraph } = Typography;

export const FaqView = ({ record }: { record?: SupportFaq }) => {
  const labelSize = 7;
  const [q, setQ] = useState('');

  if (!record?.id) {
    return null;
  }

  const file = record.documents?.length ? record.documents[0].file : null;

  return (
    <Container max>
      {file?.extension === 'pdf' ? <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} lg={12}>
          <Input
            placeholder="Search"
            className={cls.search}
            suffix={<SearchOutlined className={cls.iconSearch} />}
            onChange={e => setQ(e.target.value)}
            allowClear
          />
        </Col>
      </Row> : null}
      <Row gutter={[24, 24]} className={cls.row}>
        <Col xs={24} sm={24}>
          <InitialCard extraPadding wrapperClass={cls.card} contentClass={cls.cardContent} left="Frequently Asked Questions">
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24}>
                <InitialRow title="Question" fontLg fullWidth labelSize={labelSize} className={clsx(cls.fieldRow, cls.txtField)}>
                  <Paragraph ellipsis className={cls.value}>{record?.question}</Paragraph>
                </InitialRow>
                <InitialRow title="Answer" fontLg fullWidth labelSize={labelSize} className={clsx(cls.fieldRow, cls.htmlField)}>
                  <RawHtml className={cls.html}>{record?.answer}</RawHtml>
                </InitialRow>
                {file ? <div className={cls.file}>
                  <FilePreview file={file} q={q} />
                </div> : null}
                <div className={cls.dateRow}>
                  <div className={cls.dateCol}>
                    Updated at {dateFormatter.toDateSlash(record.createdAt)}&nbsp;
                    {dateFormatter.toTime(record.createdAt)}
                  </div>
                  <div className={cls.dateCol}>
                    Created at {dateFormatter.toDateSlash(record.updatedAt)}&nbsp;
                    {dateFormatter.toTime(record.updatedAt)}
                  </div>
                </div>
              </Col>
            </Row>
          </InitialCard>
        </Col>
      </Row>
    </Container>
  );
};
