import React from 'react';
import { Col, Row } from 'antd';
import {
  EditProps,
  Edit,
  SaveButton,
  DeleteButton,
  SimpleForm, TopToolbar,
} from 'react-admin';
import { clsx } from 'clsx';

import {
  Container,
  MoneyInput,
  TextInput,
  SelectInput,
  HistoryField,
  Forbidden, PageHeader, FormRow,
} from '@components';
import { useEditProps, useRbacEdit, useRbacView, useRecord } from '@hooks';
import { Delivery } from '@types';
import { DeliveryForm } from '../DeliveryForm';
import { DeliveryCalculation } from '../DeliveryCalculation';
import { deliveryStatusOptions } from '../DeliveryStatusField';
import { DeliveryDocuments } from '../DeliveryDocuments';
import { SaveFilled } from '@ant-design/icons';
import cls from './DeliveryEdit.module.css';

const Title = ({ record }: { record?: any }) =>
  record ? (<span>Delivery # { record.id }</span>) : null;

export const DeliveryEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const delivery = useRecord<Delivery>(props);
  const canBeDeleted = delivery?.status !== 'paid';
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();
  const mainColGrid = {
    xs: { title: 24, children: 24 },
    lg: { title: 6, children: 18 },
    xl: { title: 7, children: 13 },
    xxl: { title: 5, children: 15 },
  };

  if (!delivery) {
    return null;
  }

  if (!rbacView) {
    return <Forbidden/>;
  }

  const Actions = () => {
    return (
      <TopToolbar className={cls.actions}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon}/>}
          label="Save"
          type="button"
          className={clsx(cls.action, cls.actionSave)}
          variant="outlined"
          alwaysEnable
        />
        <DeleteButton className={clsx(cls.action, cls.actionDelete)} disabled={!canBeDeleted}/>
      </TopToolbar>
    );
  };

  const DlIcon = (): JSX.Element => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      className={cls.fileDlIcon}
    >
      <g clipPath="url(#clip0_503_6513)">
        <path d="M23.0802 5.21267H28.8436C28.7713 5.09534 28.6752 4.98934 28.5608 4.89934L22.6551 0.306004C22.4954 0.18067 22.3023 0.0893372 22.0918 0.0393372L22.1935 4.47667C22.2022 4.88334 22.5955 5.21 23.0802 5.21267Z" fill="#042E6B"/>
        <path d="M23.0808 6.316C21.8827 6.31 20.9078 5.50533 20.88 4.50067L20.7767 0H2.958C1.32208 0.004 -0.00158761 1.11867 1.42921e-06 2.49133V29.5087C-0.00158761 30.8813 1.32208 31.996 2.958 32H26.042C27.6779 31.996 29.0016 30.8813 29 29.5087V6.316H23.0808Z" fill="#042E6B"/>
        <path d="M19.8035 19.514L16.3227 22.426C15.9302 22.754 15.4098 22.9507 14.8568 22.98C14.8083 22.9853 14.7599 22.988 14.7114 22.9887C14.6693 22.9907 14.6272 22.9907 14.5851 22.9887C14.0313 22.968 13.5061 22.7787 13.1065 22.4567L9.56766 19.802C9.07108 19.4313 9.02659 18.7933 9.46834 18.3767C9.9101 17.96 10.6705 17.9227 11.167 18.2933L13.5212 20.05V11.67C13.4982 11.1127 14.0178 10.6447 14.6828 10.6253C15.347 10.606 15.9048 11.042 15.9278 11.6C15.9286 11.6233 15.9286 11.6467 15.9278 11.67V19.9107L18.1088 18.0807C18.5807 17.688 19.3426 17.69 19.8106 18.086C20.2786 18.482 20.2754 19.1213 19.8043 19.514H19.8035Z" fill="#E1E5ED"/>
      </g>
      <defs>
        <clipPath id="clip0_503_6513">
          <rect width="29" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <Edit {...props} title={<Title/>} {...editProps} className={clsx(cls.page, 'details-page-wrapper')}>
      <>
        <PageHeader
          title="Individual shipping"
          linkText="Back to all task"
          linkUrl="/delivery"
        />

        <SimpleForm toolbar={rbacEdit ? <Actions/> : false} className={cls.form}>
          <Container max>
            <Row className={cls.wrapper} gutter={24}>
              <Col xs={24} md={12}>
                <div className={clsx(cls.block, cls.blockMain)}>
                  <div className={cls.blockHeader}>Shipping</div>
                  <FormRow
                    title="Status:"
                    width={mainColGrid}
                    rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                    colTitleClassName={cls.formLabelCol}
                    colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                  >
                    <Col><SelectInput source="status" label={false} choices={deliveryStatusOptions}/></Col>
                  </FormRow>
                  <FormRow
                    title="Exchange rate:"
                    width={mainColGrid}
                    rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                    colTitleClassName={cls.formLabelCol}
                    colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                  >
                    <MoneyInput source="exchangeRate" name="exchangeRate" label={false} min={0} max={999999999} />
                  </FormRow>
                  <FormRow
                    title="Shiping cost:"
                    width={mainColGrid}
                    rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                    colTitleClassName={cls.formLabelCol}
                    colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                  >
                    <MoneyInput source="shippingCost" name="shippingCost" label={false} min={0} max={999999999} />
                  </FormRow>
                  <FormRow
                    title="Extra cost:"
                    width={mainColGrid}
                    rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                    colTitleClassName={cls.formLabelCol}
                    colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                  >
                    <MoneyInput source="extraCost" name="extraCost" label={false} min={0} max={999999999} />
                  </FormRow>
                  <FormRow
                    fullWidth
                    rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                    colTitleClassName={cls.formLabelCol}
                    colChildrenClassName={clsx(cls.formValueCol, cls.formMainMultilineValue)}
                  >
                    <TextInput
                      source="notes"
                      name="notes"
                      label={false}
                      placeholder="Any notes about delivery"
                      multiline
                      fullWidth
                    />
                  </FormRow>
                  <FormRow
                    fullWidth
                    rowClassName={clsx(cls.formRow, cls.mainFormRow, cls.mainHistoryFormRow)}
                    colTitleClassName={cls.formLabelCol}
                    colChildrenClassName={clsx(cls.formValueCol, cls.formMainHistoryValue)}
                  >
                    <HistoryField source="history"/>
                  </FormRow>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className={clsx(cls.block, cls.blockMain)}>
                  <div className={cls.blockHeader}>
                    Invoices and payment receipts ({(delivery?.documents || []).length || 0})
                  </div>
                  <Row gutter={24}>
                    <Col xs={12}>
                      <div className={cls.fileLabel}>Invoices</div>
                      <DeliveryDocuments
                        type="invoice"
                        editable={rbacEdit}
                        downloadIcon={<DlIcon />}
                      />
                    </Col>
                    <Col xs={12}>
                      <div className={cls.fileLabel}>Payment receipts</div>
                      <DeliveryDocuments
                        type="receipt"
                        editable={rbacEdit}
                        downloadIcon={<DlIcon />}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className={cls.wrapper}>
              <Col xs={24}>
                <div className={clsx(cls.block, cls.blockMain)}>
                  <div className={cls.blockHeader}>Products</div>
                  <Row gutter={24}>
                    <Col xs={24}>
                      <DeliveryForm editable={rbacEdit}/>
                      <DeliveryCalculation/>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </SimpleForm>
      </>
    </Edit>
  );
};
