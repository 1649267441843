import { Button, ButtonProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

interface Props {
  className?: string;
  extraPadding?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  counter?: number;
}

export const BlueButton = (props: ButtonProps & Props) => {
  const { className, extraPadding, outlined, counter, ...restProps } = props;
  const cls = useStyles();

  return (
    <Button
      className={clsx(
        cls.btn,
        outlined && cls.outlined,
        extraPadding && cls.extraPadding,
        className,
      )}
      variant={outlined ? 'outlined' : 'contained'}
      color="secondary"
      {...restProps}
    >
      {props.children}
      {counter && counter > 0 ? (<span className={cls.counter}>{counter}</span>) : null}
    </Button>
  );
};


const useStyles = makeStyles({
  btn: {
    fontFamily: 'Outfit, sans-serif;',
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 8,
    textTransform: 'none',
    paddingLeft: 29,
    paddingRight: 29,

    '& svg': {
      display: 'inline-block',
      marginRight: 8,
    },
  },
  extraPadding: {
    paddingLeft: 60,
    paddingRight: 60,
  },
  outlined: {
    border: '1px solid #042E6B',
  },
  counter: {
    backgroundColor: 'white',
    width: 23,
    height: 23,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
    fontSize: 13,
    marginLeft: 7,
  },
});
