import { FunctionField, FunctionFieldProps } from 'react-admin';

import { resolvePath } from './resolve-path';

export const PercentField = (props: Partial<FunctionFieldProps>) => (
  <FunctionField
    {...props}
    render={(record: any) => {
      const percent = resolvePath(record, props.source || '');
      if (percent === null || percent === undefined) return null;

      return (<span>{percent} %</span>);
    }}
  />
);
