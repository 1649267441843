import { Create, CreateProps, SimpleForm } from 'react-admin';
import { Row, Col, Container, TextInput, Forbidden } from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';

export const PermissionCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps}>
      <SimpleForm>
        <Container max>
          <Row>
            <Col><TextInput source="name" required autoFocus /></Col>
            <Col><TextInput source="path" required /></Col>
            <Col><TextInput source="method" required /></Col>
            <Col><TextInput source="module" required /></Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};
