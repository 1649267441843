import { clsx } from 'clsx';
import { Button } from '@mui/material';
import { useRedirect } from 'react-admin';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Keyboard, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { ordersHttp } from '@network';
import { Credit, Order } from '@types';
import { InitialCard } from '@components';
import { moneyFormatter, dateFormatter } from '@utils';
import cls from './credit-orders.module.css';

const OrderItem = ({ order, className }: { order: Order, className?: string }) => {
  const redirect = useRedirect();

  return (
    <div className={clsx(cls.card, className)}>
      <InitialCard wrapperClass={cls.cardWrapper} contentClass={cls.cardContent}>
        <div className={cls.item}>
          <div className={cls.status}>
            <CheckCircleFilled className={cls.icon} /> {order.statusEn}
          </div>
          <div className={cls.content}>
            <div className={cls.info}>
              <div className={cls.title}># {order.id}</div>
            </div>
            <div className={cls.info}>
              <div className={cls.date}>
                {dateFormatter.toDateSlash(order.createdAt)} {dateFormatter.toTime(order.createdAt)}
              </div>
            </div>
            {order.creditId ? <div className={cls.info}>
              <div className={cls.subtitle}>Used Credit</div>
              <div className={cls.value}>{order.credit?.code}</div>
            </div> : null}
            <div className={cls.info}>
              <div className={cls.subtitle}>Total</div>
              <div className={cls.value}>{moneyFormatter.format(order.totalCost || 0)}</div>
            </div>
          </div>
          <div className={cls.actions}>
            <Button
              color="secondary"
              variant="contained"
              className={cls.orderBtn}
              onClick={() => redirect(`/order/${order.id}`)}
            >View details</Button>
          </div>
        </div>
      </InitialCard>
    </div>
  );
};

export const CreditOrders = ({ credit }: {credit: Credit}) => {
  const redirect = useRedirect();
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const nextOrders = await ordersHttp.getOrdersByUser(credit.userId);
        setOrders(nextOrders.filter((o: Order) => o.creditId === credit.id || !o.creditId)
          .sort((a, b) => b.id - a.id));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [credit.userId]);

  return orders.length ? (
    <div className={cls.wrap}>
      <div className={cls.header}>
        <div className={cls.headerTitle}>Orders</div>
        <div className={cls.headerTotal}>{orders.length} Total Orders</div>
      </div>

      {orders.length > 4 ? (
        <Swiper
          keyboard={{ enabled: true }}
          navigation={true}
          modules={[Keyboard, Navigation, Pagination]}
          className={cls.carousel}
          pagination
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 2, spaceBetween: 20 },
            1024: { slidesPerView: 3, spaceBetween: 20 },
            1200: { slidesPerView: 4, spaceBetween: 20 },
          }}
        >
          {orders.map((order: Order) => (
            <SwiperSlide key={order.id} virtualIndex={order.id} className={cls.slide}>
              <OrderItem order={order} />
            </SwiperSlide>
          ))}
          <Button
            color="secondary"
            variant="contained"
            className={cls.ordersBtn}
            onClick={() => redirect(`/user/${credit.userId}?orders`)}
          >View all orders</Button>
        </Swiper>
      ) : (
        <div className={clsx(cls.cards, cls[`cards_${orders.length}`])}>
          {orders.map((order: Order) => (
            <OrderItem key={order.id} order={order} className={cls.cardNoSlide} />
          ))}
          <Button
            color="secondary"
            variant="contained"
            className={cls.ordersBtn}
            onClick={() => redirect(`/user/${credit.userId}?orders`)}
          >View all orders</Button>
        </div>
      )}
    </div>
  ) : null;
};
