import { useEffect, useState } from 'react';
import {
  ListProps, ReferenceField, TextField,
} from 'react-admin';

import { showAxiosError } from '@utils';
import { FinanceAccountOptions, hasCodeOptions } from '@types';
import { financeAccountHttp } from '@network';
import { useRbacList } from '@hooks';
import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  CheckboxField,
  SelectInput,
} from '@components';
import cls from './finance-account.module.css';

export const FinanceAccountList = (props: ListProps) => {
  const [typeOptions, setTypeOptions] = useState<{ id: string; name: string }[]>([]);
  const rbacList = useRbacList();

  useEffect(() => {
    (async () => {
      try {
        const financeOptions: FinanceAccountOptions = await financeAccountHttp.options();
        setTypeOptions((financeOptions?.types || []).map(option => ({ id: option, name: option })));
      } catch (e: any) {
        showAxiosError(e);
      }
    })();
  }, []);

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={[
        <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 220 }} />,
        <SelectInput
          key="accountSetup"
          source="accountSetup"
          label="Account Setup"
          choices={[
            { id: 'true', name: 'Yes' },
            { id: 'false', name: 'No' },
          ]}
          style={{ width: 200 }}
        />,
        <SelectInput
          key="type"
          source="type"
          label="Type"
          choices={typeOptions}
          style={{ width: 200 }}
        />,
        <SelectInput
          key="hasCode"
          source="hasCode"
          label="Client"
          choices={hasCodeOptions}
          style={{ width: 200 }}
        />,
      ]}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick="edit"
        className={cls.grid}
        defaultColumns={'type code addressee reference accountSetup'.split(' ')}
      >
        <TextField source="code" />
        <TextField source="addressee" />
        <ReferenceField reference="user" source="userId" label="User Code">
          <TextField source="code" />
        </ReferenceField>
        <TextField source="type" />
        <TextField source="bank" />
        <TextField source="account" />
        <TextField source="branch" />
        <TextField source="reference" />
        <TextField source="phone" />
        <TextField source="email" />
        <CheckboxField source="accountSetup" />
      </Grid>
    </GridList>
  );
};
