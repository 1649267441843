import React, { useCallback, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { Button, Form } from 'antd';
import clsx from 'clsx';
import { SaveFilled } from '@ant-design/icons';

import { showAxiosError } from '@utils';
import { QuestionItemType, RiskAdminQuestion, RiskQuestionType } from '@types';
import { riskAdminHttp } from '@network/risk-admin-http';
import { useIsFirstRenderTimeout } from '@hooks';
import { InitialCard } from '@components';
import { getMaxValue, IVariablesSection, VariablesSection } from '@pages/risk/variables';
import { OtherGroup } from './index';
import cls from './Variables.module.css';

export const RemoveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <circle cx="10" cy="10" r="10" fill="#D32F2F" />
    <path
      d="M12.5184 10.7307L14.2709 12.4858C14.7652 12.9808 14.8102 13.8358 14.2934 14.3308C14.0463 14.5558 13.7317 14.6683 13.4171 14.6683C13.1026 14.6683 12.7656 14.5558 12.5184 14.3083L10.7209 12.5083C10.3165 12.1032 9.6874 12.1032 9.28297 12.5083L7.48551 14.3083C7.23836 14.5558 6.9238 14.6683 6.58678 14.6683C6.27222 14.6683 5.95766 14.5558 5.71051 14.3308C5.19374 13.8358 5.21621 12.9808 5.73298 12.4858L7.48551 10.7307C7.88994 10.3257 7.88994 9.69565 7.48551 9.29064L5.77792 7.53557C5.28361 7.04056 5.23868 6.25303 5.71051 5.73551C6.20481 5.21799 7.01367 5.19549 7.50798 5.71301L9.30544 7.51307C9.70987 7.91809 10.339 7.91809 10.7434 7.51307L12.5409 5.71301C13.0352 5.21799 13.8665 5.21799 14.3383 5.73551C14.8102 6.23053 14.7652 7.04056 14.2709 7.53557L12.5184 9.29064C12.1364 9.69565 12.1364 10.3257 12.5184 10.7307Z"
      fill="white" />
  </svg>
);

interface Props {
  items: RiskAdminQuestion[];
  other: RiskAdminQuestion[];
  onOtherChange: () => Promise<void>;
  getScore: () => Promise<void>;
  readonly?: boolean;
}

export const VariablesEdit = ({ items, other, onOtherChange, getScore, readonly }: Props) => {
  const notify = useNotify();
  const [form] = Form.useForm();
  const isFirstRender = useIsFirstRenderTimeout(1000);

  const [sectionsList, setSectionsList] = useState<IVariablesSection[]>([]);
  const [_, setTotalPercents] = useState<Record<number, number>>({});

  const sections: IVariablesSection[] = form.getFieldValue('sections');

  const values: number[] = [];
  const sectionsQuestions = sections?.map(section => (section?.questions || [])) || [];
  sectionsQuestions.forEach(section => {
    const value = section?.reduce((sum, item) => sum + getMaxValue(item.answers || []), 0);
    values.push(value);
  });

  const getTotalPercent = () => Object.values(values)
    .reduce((prev: number, curr: number) => prev + curr, 0);

  const groupByCategoryId = useCallback(() => {
    return items.reduce((sections: Record<string, any>, item) => {
      const categoryId = item.categoryId;

      if (categoryId) {
        if (!sections[categoryId]) {
          sections[categoryId] = {};
          sections[categoryId].questions = [];
          sections[categoryId].title = item.category?.title;
          sections[categoryId].id = item.category?.id;
        }
        sections[categoryId].questions.push(item);
      }

      return sections;
    }, {});
  }, [items]);

  const submit = async () => {
    if (readonly) return;
    const questions: QuestionItemType[] = [];

    const formValues = form.getFieldsValue();

    if (formValues?.sections) {
      formValues.sections.forEach((section: IVariablesSection) => {
        const items = section?.questions?.map(question => ({
          ...question,
          category: {
            id: section.id,
            title: section.title,
          },
        }));

        if (Array.isArray(items)) {
          questions.push(...items as QuestionItemType[]);
        }
      });
      const params = {
        questions: questions.map(question => ({
          ...question,
          type: RiskQuestionType.SINGLE_CHOICE,
          division: 'score',
        })),
      };

      if (params) {
        try {
          await form.validateFields();
          const response = await riskAdminHttp.updateRiskScore(params);

          if (response) {
            notify('Variables updated');
            void getScore();
          }
        } catch (err: any) {
          if (!err.values) {
            showAxiosError(err);
          }
        }
      }
    }
  };

  useEffect(() => {
    setSectionsList(Object.values(groupByCategoryId()));
  }, [items, groupByCategoryId]);

  useEffect(() => {
    form.resetFields();
  }, [sectionsList]);

  const isValid = getTotalPercent() === 100;

  return (
    <div className={cls.variablesEdit}>
      <div className={clsx(
        cls.totalPercent,
        (getTotalPercent() === 100 || isFirstRender) && cls.valid,
      )}>
        Total: <span className={cls.totalPercentVal}>{getTotalPercent() || 0}%</span>
      </div>
      <InitialCard center="Score variables" autoHeight extraPadding wrapperClass={cls.variables}>
        <div className={cls.wrap}>
          <Form
            form={form}
            name="variables"
            initialValues={{ sections: sectionsList }}
          >
            <Form.List name="sections">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map((field) => (
                    <div
                      key={field.key}
                      className={cls.section}
                    >
                      <VariablesSection
                        form={form}
                        field={field}
                        remove={remove}
                        setTotalPercents={setTotalPercents}
                        readonly={readonly}
                      />
                    </div>
                  ))}

                  <div className={cls.actions}>
                    <Button
                      className={cls.save}
                      onClick={submit}
                      disabled={!isValid || readonly}
                    >
                      <SaveFilled className={cls.btnIcon} />
                      Save
                    </Button>
                    <Button type="default" disabled={readonly}
                      onClick={() => add()} className={cls.addSection}>
                      Add section
                    </Button>
                  </div>

                  <div className={cls.errors}>
                    {(!isValid && !isFirstRender)
                      && 'The sum of total score should be equal to 100%'
                    }
                  </div>
                </div>
              )}
            </Form.List>
          </Form>
        </div>
      </InitialCard>

      <InitialCard center="Other variables" autoHeight extraPadding wrapperClass={cls.variables}>
        <div className={cls.wrap}>
          <OtherGroup items={other} onChange={onOtherChange} readonly={readonly} />
        </div>
      </InitialCard>
    </div>
  );
};
