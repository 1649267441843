import { clsx } from 'clsx';
import { Col, Row, Modal } from 'antd';
import { Button } from '@mui/material';
import { RcFile } from 'antd/lib/upload';
import { useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useNotify, useRedirect, useRefresh } from 'react-admin';
import { FilePdfFilled, SaveFilled } from '@ant-design/icons';

import { contractHttp, s3http } from '@network';
import { GuarantorsForm } from '../GuarantorsForm';
import { Contract, ContractStatus as StatusType } from '@types';
import { dateFormatter, inputParser, moneyFormatter } from '@utils';
import {
  ConfirmDelete,
  Container,
  DateInput,
  FileControlled,
  FileUpload,
  InitialCard,
  InitialRow,
  Loader,
  NumberInput,
  TextInput,
} from '@components';
import { ContractStatus } from '@pages/contracts/Components/ContractStatus';
import cls from './ContractsForm.module.css';

export const ContractsForm = ({ record }: { record?: Contract }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [file, setFile] = useState<{ id: number; adminUrl: string; ext: string } | null>(null);
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const { getValues } = useFormContext<Contract>();
  const values = getValues();
  const labelSize = 9;
  const editable = Boolean(record && (record.status === StatusType.PENDING
    || record.status === StatusType.COMPLETED));

  const handleUpdateContract = async (): Promise<void> => {
    try {
      await contractHttp.update(values);
      notify('Contract was updated successfully.');
      refresh();
    } catch (e) {
      console.error(e);
    }
  };

  const setStatus = async (status: StatusType): Promise<void> => {
    try {
      if (!record) throw new Error('Record not found');
      await contractHttp.setStatus({ id: record.id, status });
      notify('Contract status has been updated.');
      refresh();
    } catch (e) {
      console.error(e);
    }
  };

  const openModal = () => {
    setDialogIsOpen(true);
  };

  const handleDialogClose = () => {
    setDialogIsOpen(false);
    setFile(null);
  };

  const handleCancel = () => {
    redirect('/contract/');
  };

  const uploadAction = async (rcFile: RcFile): Promise<string> => {
    const name = rcFile.name.split('.');
    const ext = name.length ? name[name.length - 1] : 'jpeg';
    const { id, adminUrl } = await s3http.uploadFile(rcFile as File);
    setFile({ id, adminUrl, ext });

    return adminUrl;
  };

  const signAction = async (): Promise<void> => {
    if (!record || !file) return;
    await contractHttp.sendSign({
      fileId: file.id,
      id: record.id,
    });
    handleDialogClose();
    refresh();
  };

  useEffect(() => {
    if (record) {
      setLoading(false);
    }
  }, [record]);

  return !loading && record ? (
    <Container max>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24}>
          <InitialCard left="General Info" extraPadding>
            <Row gutter={[24, 24]}>
              <Col xs={24} lg={12}>
                <InitialRow title="Credit" fontLg labelSize={labelSize}>
                  {record.creditCode}
                </InitialRow>
                <InitialRow title="Accredited" fontLg labelSize={labelSize}>
                  {record.accredited}
                </InitialRow>
                <InitialRow title="Birthdate/DOC" fontLg labelSize={labelSize}>
                  {dateFormatter.toDateSlash(record.userBirthday)}
                </InitialRow>
                <InitialRow title="No. Filing Cert" fontLg labelSize={labelSize}>
                  {!editable ? (record.noFillingCert) : (
                    <TextInput source="noFillingCert" inputProps={{ maxLength: 100 }} autoFocus/>
                  )}
                </InitialRow>
                <InitialRow title="Legal Rep*" fontLg labelSize={labelSize}>
                  {!editable ? (record.userFullname) : (
                    <TextInput source="userFullname" inputProps={{ maxLength: 100 }}/>
                  )}
                </InitialRow>
                <InitialRow title="RFC" fontLg labelSize={labelSize}>
                  {record.rfc}
                </InitialRow>
              </Col>
              <Col xs={24} lg={12}>
                <InitialRow title="Accredited Bank" fontLg labelSize={labelSize}>
                  {!editable ? (record.accreditedBank) : (
                    <TextInput source="accreditedBank" inputProps={{ maxLength: 30 }}/>
                  )}
                </InitialRow>
                <InitialRow title="Account holder" fontLg labelSize={labelSize}>
                  {!editable ? (record.accountHolder) : (
                    <TextInput source="accountHolder" inputProps={{ maxLength: 100 }}/>
                  )}
                </InitialRow>
                <InitialRow title="CLABE" fontLg labelSize={labelSize}>
                  {!editable ? (record.clabe) : (
                    <TextInput
                      source="clabe"
                      inputProps={{ maxLength: 18 }}
                      parse={inputParser.onlyNumbers}
                    />
                  )}
                </InitialRow>
                <InitialRow title="Address" fontLg labelSize={labelSize}>
                  {record.userAddress}
                </InitialRow>
                <InitialRow title="Phone" fontLg labelSize={labelSize}>
                  {record.userPhone}
                </InitialRow>
                <InitialRow title="Email" fontLg labelSize={labelSize}>
                  {record.userEmail}
                </InitialRow>
              </Col>
            </Row>
          </InitialCard>
        </Col>
        <Col xs={24} xl={12}>
          <GuarantorsForm data={record} editable={editable} />
        </Col>
        <Col xs={24} xl={12}>
          <InitialCard left="Other Data" extraPadding>
            <InitialRow title="Membership Rate" fontLg labelSize={labelSize}>
              {record.membershipRate}
            </InitialRow>
            <InitialRow title="Disposal Fee" fontLg labelSize={labelSize}>
              {!editable ? (record.disposalFee) : (
                <NumberInput source="disposalFee" />
              )}
            </InitialRow>
            <InitialRow title="Date" fontLg labelSize={labelSize}>
              {!editable ? (dateFormatter.toDateSlash(record.date || undefined)) : (
                <DateInput source="date" label="" />
              )}
            </InitialRow>
            <InitialRow title="Place" fontLg labelSize={labelSize}>
              {!editable ? (record.place) : (
                <TextInput source="place" inputProps={{ maxLength: 100 }} />
              )}
            </InitialRow>
            <InitialRow title="Description of pledged assets" fontLg labelSize={labelSize}>
              {!editable ? (record.description) : (
                <TextInput source="description" inputProps={{ maxLength: 150 }} />
              )}
            </InitialRow>
            <InitialRow title="Contract Status" fontLg labelSize={labelSize}>
              <ContractStatus status={record.status} />
            </InitialRow>
          </InitialCard>
        </Col>
        <Col xs={24} sm={24}>
          <InitialCard left="Credit Info" extraPadding>
            <Row gutter={[24, 24]}>
              <Col xs={24} lg={12}>
                <InitialRow title="CAT" fontLg labelSize={labelSize}>
                  {record.interestRateCat}
                </InitialRow>
                <InitialRow title="Interest Rate" fontLg labelSize={labelSize}>
                  {record.interestRate}
                </InitialRow>
                <InitialRow title="Moratory Rate" fontLg labelSize={labelSize}>
                  {record.moratoryRate}
                </InitialRow>
                <InitialRow title="Credit Amount" fontLg labelSize={labelSize}>
                  {!editable ? (moneyFormatter.format(record.creditAmount)) : (
                    <NumberInput source="creditAmount" />
                  )}
                  {/*<NumberInput source="creditAmount" validate={validateRequestAmount} />*/}
                </InitialRow>
                <InitialRow title="Payment Amount" fontLg labelSize={labelSize}>
                  {moneyFormatter.format(record.paymentAmount)}
                </InitialRow>
                <InitialRow title="Credit Term" fontLg labelSize={labelSize}>
                  {record.creditTerms}
                </InitialRow>
              </Col>
              <Col xs={24} lg={12}>
                <InitialRow title="Signature Date" fontLg labelSize={labelSize}>
                  {!editable ? (dateFormatter.toDateSlash(record.signatureDate || undefined)) : (
                    <DateInput source="signatureDate" label="" />
                  )}
                </InitialRow>
                <InitialRow title="Contract Term" fontLg labelSize={labelSize}>
                  {record.contractTerms}
                </InitialRow>
                <InitialRow title="Payment Date" fontLg labelSize={labelSize}>
                  {!editable ? (dateFormatter.toDateSlash(record.paymentDate || undefined)) : (
                    <DateInput source="paymentDate" label="" />
                  )}
                </InitialRow>
                <InitialRow title="Use of Credit" fontLg labelSize={labelSize}>
                  {!editable ? (record.useOfCredit) : (
                    <TextInput source="useOfCredit" inputProps={{ maxLength: 100 }} />
                  )}
                </InitialRow>
                <InitialRow title="Drawdowns" fontLg labelSize={labelSize}>
                  {!editable ? (record.drawdowns) : (
                    <TextInput
                      source="drawdowns"
                      inputProps={{ maxLength: 18 }}
                      parse={inputParser.onlyNumbers}
                    />
                  )}
                </InitialRow>
                <InitialRow title="Promissory Rate" fontLg labelSize={labelSize}>
                  {record.promissoryRate}
                </InitialRow>
              </Col>
            </Row>
          </InitialCard>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col xs={12} md={8} className={cls.actionsLeft}>
          <ConfirmDelete title="Are you sure?" cb={handleCancel}>
            <Button
              variant="outlined"
              color="inherit"
              className={clsx(cls.action, cls.cancelBtn)}
              onClick={undefined}
            >Go back</Button>
          </ConfirmDelete>
        </Col>
        {!record.status || record.status === StatusType.PENDING
          ? <Col xs={12} md={16} className={cls.actionsRight}>
            <Button
              variant="outlined"
              color="inherit"
              className={clsx(cls.action, cls.saveBtn)}
              onClick={handleUpdateContract}
            ><SaveFilled className={cls.actionIcon}/>Save and Sync</Button>
            <Button
              variant="outlined"
              color="inherit"
              className={clsx(cls.action, cls.completeBtn)}
              onClick={() => setStatus(StatusType.COMPLETED)}
            ><FilePdfFilled className={cls.actionIcon}/>Complete Contract</Button>
          </Col> : null}
        {record.status === StatusType.COMPLETED
          ? <Col xs={12} md={16} className={cls.actionsRight}>
            <Button
              variant="outlined"
              color="inherit"
              className={clsx(cls.action, cls.saveBtn)}
              onClick={handleUpdateContract}
            >Update</Button>
            <Button
              variant="outlined"
              color="inherit"
              className={clsx(cls.action, cls.saveBtn)}
              onClick={() => {}}
            ><FilePdfFilled className={cls.actionIcon}/>Download</Button>
            <Button
              variant="outlined"
              color="inherit"
              className={clsx(cls.action, cls.toSignBtn)}
              onClick={() => setStatus(StatusType.SIGNATURE_IN_PROCESS)}
            >Ready to Sign</Button>
          </Col> : null}
        {record.status === StatusType.SIGNATURE_IN_PROCESS
          ? <Col xs={12} md={16} className={cls.actionsRight}>
            <Button
              variant="outlined"
              color="inherit"
              className={clsx(cls.action, cls.cancelSignBtn)}
              onClick={() => setStatus(StatusType.COMPLETED)}
            >Cancel Signature</Button>
            <Button
              variant="outlined"
              color="inherit"
              className={clsx(cls.action, cls.signedBtn)}
              onClick={openModal}
            >Contract is Signed</Button>
          </Col> : null}
        {record.status === StatusType.SIGNED
          ? <Col xs={12} md={16} className={cls.actionsRight}>
            <Button
              variant="outlined"
              color="inherit"
              className={clsx(cls.action, cls.previewActionBtn)}
              onClick={() => console.log('PREVIEW')}
            >Preview Contract</Button>
          </Col> : null}
      </Row>

      <Modal
        width={470}
        title={false}
        open={dialogIsOpen}
        footer={false}
        onCancel={handleDialogClose}
        centered
        closable={false}
        maskClosable={false}
        className={clsx(cls.modal, cls.scoreModal)}
      >
        <div className={cls.dialogTitle}>Upload Signed Contract, please</div>
        <div className={cls.file}>
          {file ? (
            <FileControlled
              wrapClass={clsx(cls.filePreview, file.ext === 'pdf' && cls.filePdf)}
              adminUrl={file.adminUrl}
              isPdf={file.ext === 'pdf'}
              downloadFilename={`Contract_${record.id}_signed.${file.ext}`}
              onDelete={() => setFile(null)}
            />
          ) : (
            <FileUpload
              uploadAction={uploadAction}
              className={cls.uploadBtn}
              wrapperClassName={cls.uploader}
            />
          )}
        </div>
        <div className={cls.dialogBtns}>
          <Button onClick={handleDialogClose} className={clsx(cls.action, cls.cancelBtn)} variant="outlined" color="inherit">
            Cancel
          </Button>
          <Button
            onClick={signAction}
            className={clsx(cls.action, cls.signedBtn, !file && cls.actionDisabled)}
            disabled={!file}
            variant="outlined"
            color="inherit"
          >
            Send and Finish
          </Button>
        </div>
      </Modal>
    </Container>
  ) : <Loader center/>;
};
