import { InitialCard } from '@components';
import { TickWithLabel } from './credit-report';
import cls from './credit-report.module.css';

interface CreditReportUserProps {
  isClientValid: boolean;
  hasClientGuarantor: boolean;
  isSurveyCompleted: boolean;
  isReportsCompleted: boolean;
  isGuarantorsRequired?: boolean;
}

export const CreditReportUser = ({
  isClientValid, hasClientGuarantor, isSurveyCompleted, isReportsCompleted, isGuarantorsRequired,
}: CreditReportUserProps) => {
  return (
    <InitialCard
      left="Verification"
      boxClass={cls.verificationUser}
      contentClass={cls.userVerification}
      extraPadding
      autoHeight
    >
      <TickWithLabel label="Client" isValid={isClientValid} tooltip={!isClientValid ? 'Please check the credits documents in admin platform.' : undefined} />
      <TickWithLabel label="Guarantor" isValid={hasClientGuarantor || !!isGuarantorsRequired} />
      <TickWithLabel label="Survey key questions" isValid={isSurveyCompleted} />
      <TickWithLabel label="Reports Uploaded" isValid={isReportsCompleted} />
    </InitialCard>
  );
};
