import * as React from 'react';
import { useCallback } from 'react';
import DownloadIcon from '@mui/icons-material/GetApp';
import {
  fetchRelatedRecords,
  useDataProvider,
  useNotify,
  useListContext,
  SortPayload,
  Exporter,
  FilterPayload,
  useResourceContext,
} from 'ra-core';
import { Button, ButtonProps } from '@mui/material';

import { ColumnType, useDefaultExporter } from './use-default-exporter';

export const CustomExportButton = (props: ExportButtonProps & CustomExportProps) => {
  const {
    maxResults = 1000,
    onClick,
    label = 'ra.action.export',
    icon = defaultIcon,
    exporter: customExporter,
    columns,
    meta,
    onExport,
    ...rest
  } = props;
  const {
    filter,
    filterValues,
    sort,
    total,
    selectedIds,
  } = useListContext(props);
  const resource = useResourceContext(props);
  const defaultExporter = useDefaultExporter(columns);
  const exporter = customExporter || defaultExporter;
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleClick = useCallback(
    event => {
      const queryParams = {
        sort,
        filter: filter
          ? { ...filterValues, ...filter }
          : filterValues,
        pagination: { page: 1, perPage: maxResults },
        meta,
      };
      onExport && onExport({ ...queryParams, id: selectedIds });
      dataProvider
        .getList(resource, {
          sort,
          filter: filter
            ? { ...filterValues, ...filter }
            : filterValues,
          pagination: { page: 1, perPage: maxResults },
          meta,
        })
        .then(
          ({ data }) => {
            if (selectedIds.length > 0) {
              const hiddenRow = data.find(row => selectedIds.includes(row.id));
              if (hiddenRow) {
                data = data.filter(row => selectedIds.includes(row.id));
              }
            }
            return exporter && exporter(
              data,
              fetchRelatedRecords(dataProvider),
              dataProvider,
              resource,
            );
          },
        )
        .catch(error => {
          console.error(error);
          notify('ra.notification.http_error', { type: 'error' });
        });
      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [
      dataProvider,
      exporter,
      filter,
      filterValues,
      maxResults,
      notify,
      onClick,
      resource,
      sort,
      meta,
      selectedIds,
    ],
  );

  return (
    <Button
      onClick={handleClick}
      disabled={total === 0}
      {...sanitizeRestProps(rest) as any}
      className="MuiButtonBase-root"
      aria-label="Export"
      size="small"
    >
      {icon} Export
    </Button>
  );
};

const defaultIcon = <DownloadIcon />;

const sanitizeRestProps = ({
  filterValues,
  resource,
  ...rest
}: Omit<
  ExportButtonProps,
  'sort' | 'maxResults' | 'label' | 'exporter' | 'meta'
>) => rest;

interface Props {
  exporter?: Exporter;
  filterValues?: FilterPayload;
  icon?: JSX.Element;
  label?: string;
  maxResults?: number;
  onClick?: (e: Event) => void;
  resource?: string;
  sort?: SortPayload;
  meta?: any;
}

interface CustomExportProps {
  columns: ColumnType[];
  onExport?: (queryParams: any) => Promise<void>;
}

export type ExportButtonProps = Props & ButtonProps;
