import { useEffect, useState } from 'react';
import { FinanceActivationOptions, financeSettingsHttp } from '@network/finance-settings-http';

export const useFinanceActivationSettings = () => {
  const [settings, setSettings] = useState<FinanceActivationOptions>();

  useEffect(() => {
    (async () => {
      const nextSettings = await financeSettingsHttp.getActivationOptions();
      setSettings(nextSettings);
    })();
  }, []);

  return settings;
};
