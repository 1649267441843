import React from 'react';
import { Edit, EditProps, SimpleForm } from 'react-admin';
import { Col, Row } from 'antd';

import { useEditProps } from '@hooks';
import { Container, Forbidden, InitialCard, InitialPage } from '@components';
import { FinanceOptionsForm } from './components/FinanceOptionsForm';
import { FinanceCostType } from '@types';
import { rbac } from '@utils';

const validate = (values: any) => {
  const errors: any = {};
  const types = (values.types || []) as FinanceCostType[];

  if (Array.isArray(types) && types.length > 0) {
    const uniqueNames: Record<string, boolean> = {};
    types.forEach(type => {
      const name = `${type.name || ''}`.trim();
      if (!name && !errors['types']) {
        errors['types'] = 'Description is required';
      }
      if (name && uniqueNames[name] && !errors['types']) {
        errors['types'] = `Description is duplicated: ${name}`;
      }
      uniqueNames[name] = true;
    });
  }

  return errors;
};

export const FinanceCostTypeEdit = (props: EditProps) => {
  const editProps = useEditProps();

  const accessAll = rbac.hasFinanceSettingsAll();
  const accessReadonly = !accessAll && rbac.hasFinanceSettingsReadonly();
  const hasAccess = accessAll || accessReadonly;

  if (!hasAccess) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} {...editProps} title="Type Options">
      <InitialPage
        title="Type Options"
        backTo="/finance-settings"
        backText="Back to Finance Settings"
      >
        <SimpleForm validate={validate} toolbar={accessReadonly ? false : undefined}>
          <Container max>
            <InitialCard left="Current elements" extraPadding>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={11}>
                  <FinanceOptionsForm fieldName="types" readonly={accessReadonly} />
                </Col>
              </Row>
            </InitialCard>
          </Container>
        </SimpleForm>
      </InitialPage>
    </Edit>
  );
};
