import { Http } from './http';
import { CreditFarm } from '@types';

class CreditFarmHttp extends Http {
  prefix = 'credit-farm';

  async getFarmsOfUser(userId: number): Promise<CreditFarm[]> {
    const res = await this.get<CreditFarm[]>(`/of-user/${userId}`);

    return res.data;
  }

  async getFarmsOfCredit(creditId: number): Promise<CreditFarm[]> {
    const res = await this.get<CreditFarm[]>(`/of-credit/${creditId}`);

    return res.data;
  }

  async addFarm(params: FarmParams): Promise<CreditFarm> {
    const res = await this.post<CreditFarm>('', params);

    return res.data;
  }

  async updateFarm(farm: CreditFarm): Promise<CreditFarm> {
    const res = await this.put<CreditFarm>(`/${farm.id}`, farm);

    return res.data;
  }

  async removeFarm(farmId: number): Promise<CreditFarm> {
    const res = await this.delete<CreditFarm>(`/${farmId}`);

    return res.data;
  }
}

export const creditFarmHttp = new CreditFarmHttp();

export interface FarmParams {
  creditId: number;
  latitude: string;
  longitude: string;
}
