import { FunctionField, FunctionFieldProps } from 'react-admin';
import Chip from '@mui/material/Chip';
import { Color } from '@mui/material';

interface ColoredOption {
  id: string;
  name: string;
  color: Color | string;
}

export const callRiskOptions: ColoredOption[] = [
  { id: 'low', name: 'Low', color: '#88D256' },
  { id: 'medium', name: 'Medium', color: '#FFC82D' },
  { id: 'high', name: 'High', color: '#FD492D' },
];

interface Props {
  source: string;
  callRisk?: string;
  className?: string;
  width?: number;
  badgeMode?: boolean;
}

export const CallRiskField = (props: Props & Partial<FunctionFieldProps>) => {
  const { className, callRisk, width, badgeMode, ...restProps } = props;

  if (badgeMode) {
    const value = callRisk;
    const valueName = callRiskOptions.find(o => o.id === value)?.name;
    const color: any = callRiskOptions.find(o => o.id === value)?.color;

    if (!value || !color) {
      return null;
    }

    return (
      <Chip
        label={valueName || value}
        size="small"
        style={{
          backgroundColor: typeof color === 'string'
            ? color
            : (color && color[500] ? color[500] : '#74706B'),
          color: 'white',
          height: 20,
          fontSize: 11,
          width: width || '100%',
        }}
        className={className}
      />
    );
  }

  return (
    <FunctionField {...restProps} render={(record: any) => {
      const value = callRisk || record[props.source];
      const valueName = callRiskOptions.find(o => o.id === value)?.name;
      const color: any = callRiskOptions.find(o => o.id === value)?.color;

      if (!value || !color) {
        return null;
      }

      return (
        <Chip
          label={valueName || value}
          size="small"
          style={{
            backgroundColor: typeof color === 'string'
              ? color
              : (color && color[500] ? color[500] : '#74706B'),
            color: 'white',
            height: 20,
            fontSize: 11,
            width: width || '100%',
          }}
          className={className}
        />
      );
    }} />
  );
};
