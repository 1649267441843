import { ListProps, TextField } from 'react-admin';

import { TextInput, Grid, GridList, DateField, Forbidden } from '@components';
import { useRbacList } from '@hooks';

export const DivisionList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick="edit"
        defaultColumns={['name', 'synonym', 'updatedAt']}
      >
        <TextField source="name" label="Category" />
        <TextField source="synonym" label="URL" />
        <DateField source="updatedAt" label="Updated" showTime />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
];
