import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';

import { InitialPage, InitialHistory, HistoryItem } from '@components';
import { Credit, User } from '@types';
import { useEffect, useState } from 'react';
import { creditHttp, loginTryHttp } from '@network';

export const UserHistoryPage = ({ user }: {user: User}) => {
  const cls = useStyles();
  const backTo = `/user/${user.id}?initial`;
  const [loginHistory, setLoginHistory] = useState<HistoryItem[]>([]);
  const [credits, setCredits] = useState<Credit[]>([]);

  useEffect(() => {
    (async () => {
      const nextLoginHistory = await loginTryHttp.findByUser(user.id);
      setLoginHistory(nextLoginHistory.map(loginTry => ({
        ...loginTry,
        title: `Login try by ${loginTry.login}`,
        statusBefore: loginTry.isSucceed ? 'SUCCESS' : 'FAIL',
      })));

      const nextCredits = await creditHttp.getCreditsWithHistory(user.id);
      setCredits(nextCredits);
    })();
  }, [user.id]);

  return (
    <InitialPage title="History" backTo={backTo} backText="Back to Individual User" gap={24}>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={12} md={12}>
          <InitialHistory title="User history"
            history={user.history || []}
            author={user.fullName}
          />
          <InitialHistory title="Login tries"
            history={loginHistory}
            author={user.fullName}
            mt={24}
          />
        </Col>

        <Col xs={24} sm={12} md={12}>
          {credits.map((credit, i) => (
            <InitialHistory
              key={credit.id}
              title={`Credit ${credit.code}`}
              history={credit.history}
              author={user.fullName}
              mt={i ? 24 : 0}
            />
          ))}
        </Col>
      </Row>
    </InitialPage>
  );
};

const useStyles = makeStyles({});
