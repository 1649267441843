import { makeStyles } from '@mui/styles';
import { useController } from 'react-hook-form';
import { CheckboxInput } from '@components';
import { useEffect } from 'react';
import { UserStatus } from '@types';
import WarningIcon from '@mui/icons-material/Warning';

const userStatuses = ['active', 'inactive'];

interface Props {
  hasLogged?: boolean;
}

export const UserStatusForm = ({ hasLogged }: Props) => {
  const cls = useStyles();

  const {
    field: { value: status, onChange: setStatus },
  } = useController({ name: 'status' });
  const {
    field: { value: isActive, onChange: setActive },
  } = useController({ name: 'isActive' });
  const {
    field: { value: isInactive, onChange: setInactive },
  } = useController({ name: 'isInactive' });

  useEffect(() => {
    if (isActive && status !== UserStatus.ACTIVE) {
      setStatus(UserStatus.ACTIVE);
      setInactive(false);
    }
    if (isInactive && status !== UserStatus.INACTIVE) {
      setStatus(UserStatus.INACTIVE);
      setActive(false);
    }
  }, [isActive, isInactive]);

  return (
    <div className={cls.form}>
      {userStatuses.includes(status) && (
        <>
          <CheckboxInput source="isActive" label="Active" />
          <CheckboxInput source="isInactive" label="Inactive" />
        </>
      )}

      {status === 'not_available' && (
        <>
          <CheckboxInput source="isActive" label="Active" disabled />
          <CheckboxInput source="isInactive" label="Inactive" disabled />

          {!hasLogged && (
            <div className={cls.warning}>
              <WarningIcon style={{ color: 'rgb(4, 46, 107)' }} />
                Client has not logged in yet
            </div>
          )}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  form: {
    marginTop: 15,
    display: 'flex',
    '& > *': {
      marginRight: 40,
    },
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
});


