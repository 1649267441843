import { useRef, useState } from 'react';
import { Identifier, ListProps, RaRecord, RowClickFunction, TextField } from 'react-admin';
import { TextInput, ImageField, Grid, GridList, Forbidden } from '@components';
import { useRbacList } from '@hooks';
import { CropCategoryCreator } from '@components/Crops';
import { CropCategory } from '@types';
import { useSettingsContext } from '@providers/settings-provider';

export const CropCategoryList = (props: ListProps) => {
  const rbacList = useRbacList();
  const {
    isCropCreatorActive, setIsCropCreatorActive, setIsToolbarShown,
  } = useSettingsContext();

  const [selectedCategory, setSelectedCategory] = useState<CropCategory | null>(null);

  const modalType = useRef<'create'| 'edit'>('create');

  const rowClick: RowClickFunction = (id: Identifier, resource: string, record: RaRecord) => {
    modalType.current = 'edit';
    setSelectedCategory(record as CropCategory);
    setIsCropCreatorActive(true);
    setIsToolbarShown(false);

    return false;
  };

  const openCropCategoryCreator = () => {
    modalType.current = 'create';
    setSelectedCategory(null);
    setIsCropCreatorActive(true);
    setIsToolbarShown(false);
  };

  const closeCropCategoryCreator = () => {
    modalType.current = 'create';
    setIsCropCreatorActive(false);
    setIsToolbarShown(true);
  };

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <>
      <GridList {...props}
        filters={Filters}
        sort={{ field: 'id', order: 'DESC' }}
        customCreate={openCropCategoryCreator}
      >
        <Grid rowClick={rowClick}>
          <TextField source="id" />
          <TextField source="nameMx" />
          <TextField source="nameEn" />
          <ImageField source="image" width={40} maxHeight={40} />
        </Grid>
      </GridList>

      {isCropCreatorActive && (
        <CropCategoryCreator
          type={modalType.current}
          close={closeCropCategoryCreator}
          values={selectedCategory}
        />
      )}
    </>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
];
