import React, { DragEvent, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { Dropdown, Form, FormInstance, FormListOperation, Input } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';

import { RiskAdminAnswer, RiskAdminQuestion } from '@types';
import { RemoveIcon, VariablesAnswer } from '@pages/risk/variables';
import cls from './VariablesQuestion.module.css';

const MIN_ANSWERS_COUNT = 2;

interface VariablesQuestionProps {
  form: FormInstance<any>;
  question: RiskAdminQuestion | null;
  sectionIndex: number;
  index: number | null;
  addTotalValue: (questionIndex: number, value: number) => void;
  field: FormListFieldData;
  operation: FormListOperation;
  onDragStart: (event: DragEvent<HTMLDivElement>, index: number) => void;
  onDragOver: (e: DragEvent<HTMLDivElement>) => void;
  onDrop: (event: DragEvent<HTMLDivElement>, dropIndex: number) => void;
  readonly?: boolean;
}

export const getMaxValue = (list: RiskAdminAnswer[]) => {
  const answersValues = list?.reduce((sum: number[], item: RiskAdminAnswer) =>
    ([...sum, Number(item?.progressPercent || 0)]), []);

  if (answersValues) {
    return Math.max(...answersValues);
  }

  return 0;
};

export const VariablesQuestion = ({
  question,
  sectionIndex,
  addTotalValue,
  field,
  operation,
  onDragStart,
  onDragOver,
  onDrop,
  readonly,
}: VariablesQuestionProps) => {
  const [percentValues, setPercentValues] = useState<Record<number, number>>({});
  const [optionsError, setOptionsError] = useState<string>('');
  const [isDraggable, setIsDraggable] = useState<boolean>(false);

  const answers = Form.useWatch(['sections', sectionIndex, 'questions', field.name, 'answers']);

  const maxAnswerValue = getMaxValue(answers);

  const onQuestionChange = (answerIndex: number, value: string) => {
    const newValues = {
      ...percentValues,
      [answerIndex]: +value,
    };
    if (!value) {
      delete newValues[answerIndex];
    }

    setPercentValues(newValues);
  };

  const onQuestionRemove = () => {
    operation.remove(field.name);
  };

  useEffect(() => {
    const values: Record<number, number> = {};
    question?.answers?.forEach((answer) => {
      if (answer?.position) {
        values[answer.position] = answer?.progressPercent || 0;
      }
    });
    if (values) {
      setPercentValues(values);
    }
  }, [question, field.name]);

  useEffect(() => {
    if (question) {
      addTotalValue(question?.position, getMaxValue(answers));
    }
  }, [maxAnswerValue, JSON.stringify(percentValues)]);

  return (
    <div
      className={cls.item}
      draggable={isDraggable && !readonly}
      onDragStart={(e) => onDragStart(e, field.name)}
      onDragOver={(e) => onDragOver(e)}
      onDrop={(e) => onDrop(e, field.name)}
    >
      <Form.Item
        name={[field.name, 'title']}
        className={clsx(cls.itemCol, cls.title)}
        rules={[{ required: true, message: 'Variable title is mandatory field' }, {
          max: 100,
          message: 'Variable title should be less then 100 characters',
        }]}
      >
        <Input
          placeholder="Variable title"
          className={cls.input}
          maxLength={100}
          onBeforeInputCapture={readonly ? e => e.preventDefault() : undefined}
          disabled={readonly}
        />
      </Form.Item>

      <Form.Item className={clsx(cls.itemCol, cls.answers)}>
        <Dropdown
          dropdownRender={() => (
            <div className="dropdown-content">
              <Form.List
                name={[field.name, 'answers']}
                rules={[{
                  validator: (_, value) => {
                    if (value.length < MIN_ANSWERS_COUNT) {
                      const error = `Question must have at least ${MIN_ANSWERS_COUNT} answers`;
                      setOptionsError(error);
                      return Promise.reject(new Error(error));
                    }

                    setOptionsError('');
                    return Promise.resolve();
                  },
                }]}
              >
                {(subFields, operation) => (
                  <VariablesAnswer
                    key={`answer-${field.name}`}
                    fields={subFields}
                    operation={operation}
                    onChange={onQuestionChange}
                    readonly={readonly}
                  />
                )}
              </Form.List>
            </div>
          )}
        >
          <div className={cls.answers}>Edit options</div>
        </Dropdown>
        {optionsError && <div className={cls.answersError}>{optionsError}</div>}
      </Form.Item>

      <div className={clsx(cls.itemCol, cls.percent)}>
        {maxAnswerValue}%
      </div>

      {!readonly ? (
        <div className={cls.remove} onClick={onQuestionRemove}>
          <RemoveIcon />
        </div>
      ) : null}

      <div
        className={cls.decor}
        onMouseEnter={() => setIsDraggable(true)}
        onMouseLeave={() => setIsDraggable(false)}
      >
        <span className={cls.decorPoint} />
        <span className={cls.decorPoint} />
        <span className={cls.decorPoint} />
      </div>
    </div>
  );
};
