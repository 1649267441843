import Chip from '@mui/material/Chip';

import { CreditStatus, UserStatus } from '@types';
import { creditStatusOptions } from '@pages/credit/CreditStatusField';
import { userStatusOptions } from '@pages/user/UserStatusField';

import cls from './FinanceActivationValues.module.css';

interface FinanceActivationLeadStageProps {
    stage: string;
}

export const FinanceActivationLeadStage = ({ stage }: FinanceActivationLeadStageProps) => {
  return (
    <div className={cls.stage}>{stage}</div>
  );
};

interface FinanceActivationCreditStatusProps {
    status: CreditStatus
}

export const FinanceActivationCreditStatus = ({ status }: FinanceActivationCreditStatusProps) => {
  const color: any = creditStatusOptions.find(o => o.id === status)?.color;
  const statusName = creditStatusOptions.find(o => o.id === status)?.name;
    
  return (
    <Chip
      label={statusName || status}
      size="small"
      style={{
        backgroundColor: typeof color === 'string'
          ? color
          : (color && color[500] ? color[500] : '#74706B'),
        color: 'white',
        height: 20,
        fontSize: 11,
        width: '100%',
      }}
    />
  );
};

interface FinanceActivationUserStatusProps {
    status: UserStatus;
}

export const FinanceActivationUserStatus = ({ status }: FinanceActivationUserStatusProps) => {
  const statusName = userStatusOptions.find(o => o.id === status)?.name;
  const color: any = userStatusOptions.find(o => o.id === status)?.color;
    
  return (
    <Chip
      label={statusName || status}
      size="small"
      style={{
        backgroundColor: typeof color === 'string'
          ? color
          : (color && color[500] ? color[500] : '#74706B'),
        color: 'white',
        height: 20,
        fontSize: 11,
        width: '100%',
      }}
    />
  );
};
