import { clsx } from 'clsx';
import { useState } from 'react';
import { Link } from 'react-admin';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper/types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Keyboard, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { CreditFarm } from '@types';
import { BlueButton, InitialCard } from '@components';
import cls from './Farms.module.css';

interface Props {
  farms: CreditFarm[];
  noLink?: boolean;
  uid: number;
}

interface SlideProps {
  farm: CreditFarm;
  isActive: boolean;
}

interface FarmProps {
  farm: CreditFarm;
  uid: number;
  className?: string;
}

const Item = ({ farm, uid, className }: FarmProps) => {
  const getCoords = (): string => {
    if (!farm.latitude || !farm.longitude) return '-';
    return `lat: ${farm.latitude}, long: ${farm.longitude}`;
  };

  return (
    <Link to={`/user/${uid}?farms`}>
      <div className={className}>
        <div className={cls.itemContent}>
          <div className={cls.info}>Crops: {farm.cropsTitle || '-'}</div>
          <div className={cls.info}>Coordinate: {getCoords()}</div>
          <div className={cls.info}>State: {farm.state || '-'}</div>
          <div className={cls.info}>Total area: {farm.totalArea ? `${farm.totalArea} Ha` : '-'}</div>
        </div>
      </div>
    </Link>
  );
};

const Dots = ({ slides }: {slides: SlideProps[]}) => {
  return slides.length > 1 ? <div className={cls.dots}>
    {slides.map((slide: SlideProps, i: number) => (
      <div key={i} className={clsx(cls.dot, slide.isActive && cls.active)} />
    ))}
  </div> : null;
};

export const Farms = ({ farms, uid, noLink }: Props) => {
  const [slides, setSlides] = useState<SlideProps[]>(
    farms.map((farm: CreditFarm, idx: number) => ({ farm, isActive: !idx })),
  );

  return (
    <InitialCard
      left="Farms"
      contentClass={clsx(cls.content, !farms.length && cls.centered)}
      wrapperClass={clsx(cls.wrap, !farms.length && cls.centered)}
      extraPadding
    >
      {farms.length > 0 ? (
        <Swiper
          centeredSlides={true}
          spaceBetween={0}
          slidesPerView={1}
          keyboard={{ enabled: true }}
          navigation={true}
          modules={[Keyboard, Navigation, Pagination]}
          className={cls.carousel}
          pagination={{ type: 'fraction' }}
          onSlideChange={(sw: SwiperClass) => {
            setSlides(slides.map((s: SlideProps, idx: number) => {
              return idx === sw.snapIndex ? { ...s, isActive: true } : { ...s, isActive: false };
            }));
          }}
        >
          {farms.map((farm: CreditFarm) => (
            <SwiperSlide key={farm.id} virtualIndex={farm.id} className={cls.slide}>
              <Item
                farm={farm}
                uid={uid}
                className={clsx(cls.item, slides.length > 1 && cls.hasNeighbors)}
              />
            </SwiperSlide>
          ))}
          <Dots slides={slides} />
        </Swiper>
      ) : (
        <div className={cls.noDataText}>No farms yet
          {noLink ? null : (
            <div className={cls.farmsEmpty}>
              <Link to={`/user/${uid}?farms`}>
                <BlueButton className={cls.createFarmBtn}>
                  Create farm <ChevronRightIcon/>
                </BlueButton>
              </Link>
            </div>
          )}
        </div>
      )}
    </InitialCard>
  );
};
