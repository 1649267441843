import React from 'react';
import { EditProps, Edit } from 'react-admin';

import { Forbidden } from '@components';
import { useEditProps, useRbacEdit, useRbacView, useRecord } from '@hooks';
import { CreditCall } from '@types';
import { CallWidget } from '@pages/credit-call/CallWidget';

const Title = () => (<span>Task by credit</span>);

export const CreditCallEdit = (props: EditProps) => {
  const call = useRecord<CreditCall>(props);
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }
  if (!call) {
    return null;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <CallWidget creditId={call.creditId} call={call} editable={rbacEdit} />
    </Edit>
  );
};
