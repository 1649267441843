export const VerqorLogo = () => (
  <div style={{ width: 100, height: 35, position: 'absolute', left: '50%', marginLeft: -50, top: 5 }}>
    <svg width="100" height="35" viewBox="0 0 100 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.3357 18.3965C33.7537 18.3965 30.4297 21.7787 30.4297 26.4342C30.4297 31.0898 33.803 34.472 38.4474 34.472C41.3477 34.472 43.7783 33.192 45.1085 30.9638L45.4009 30.4763L43.1706 29.1466L42.8947 29.6573C42.1097 31.1063 40.4576 31.9718 38.4769 31.9718C35.7343 31.9718 33.6486 30.2973 33.1165 27.744H45.8706V27.1803C45.8706 27.0775 45.8771 26.9747 45.8837 26.8719C45.8903 26.7459 45.9001 26.6199 45.9001 26.4939C45.9001 22.5712 43.2495 18.3998 38.3357 18.3998V18.3965ZM33.1033 25.2107C33.5895 22.6044 35.6259 20.8967 38.3357 20.8967C40.451 20.8967 42.7075 22.2363 43.2297 25.2107H33.1033Z" fill="white"/>
      <path d="M81.6163 18.3965C77.1197 18.3965 73.5986 21.9279 73.5986 26.4342C73.5986 30.9405 77.1197 34.472 81.6163 34.472C86.1129 34.472 89.6045 30.9405 89.6045 26.4342C89.6045 21.9279 86.0965 18.3965 81.6163 18.3965ZM87.0425 26.4342C87.0425 29.5379 84.6579 31.9718 81.6163 31.9718C78.5748 31.9718 76.1606 29.5412 76.1606 26.4342C76.1606 23.3272 78.5584 20.8967 81.6163 20.8967C84.6743 20.8967 87.0425 23.3305 87.0425 26.4342Z" fill="white"/>
      <path d="M98.6079 18.5098C96.8539 18.5098 95.4382 19.0768 94.4299 20.171V18.7087H91.8975V34.1542H94.4299V25.629C94.4299 21.3415 97.6291 21.01 98.6079 21.01H99.1663V18.5098H98.6079Z" fill="white"/>
      <path d="M71.3255 34.47L69.4303 31.6814C70.616 30.282 71.3255 28.4583 71.3255 26.4323C71.3255 21.926 67.8176 18.3945 63.3341 18.3945C58.8506 18.3945 55.3164 21.926 55.3164 26.4323C55.3164 30.9386 58.8375 34.47 63.3341 34.47C65.0125 34.47 66.553 33.976 67.8274 33.1171L68.7438 34.47H71.3222H71.3255ZM66.1588 26.8633H63.5772L66.4052 31.0248C65.5381 31.6217 64.4837 31.9698 63.3341 31.9698C60.2761 31.9698 57.8784 29.5393 57.8784 26.4323C57.8784 23.3253 60.2761 20.8947 63.3341 20.8947C66.3921 20.8947 68.7635 23.3286 68.7635 26.4323C68.7635 27.5531 68.4515 28.581 67.9128 29.4431L66.1621 26.8633H66.1588Z" fill="white"/>
      <path d="M12.7231 31.5141L11.3502 28.8381C10.9462 28.3673 10.529 27.9097 10.0889 27.4686C9.8524 27.2299 9.60605 26.9978 9.35642 26.7657C9.09694 26.5236 8.8276 26.2882 8.55827 26.0561C7.19844 24.8922 5.72038 23.8676 4.14049 23.0088C3.43758 22.6274 2.71497 22.2759 1.97266 21.9609C3.78247 26.0163 6.59736 29.5079 10.102 32.1209C11.2549 32.9432 12.4834 33.6594 13.7742 34.2696C13.7808 34.2729 13.7906 34.2762 13.7972 34.2828C13.945 34.3525 14.0928 34.4188 14.2439 34.4851L12.7166 31.5141H12.7231Z" fill="#E1A77E"/>
      <path d="M14.6742 34.7272H14.8121C14.753 34.704 14.6939 34.6775 14.6348 34.6543L14.6742 34.7272Z" fill="white"/>
      <path d="M23.5652 25.4034C23.2959 25.6157 23.0298 25.8312 22.7703 26.0567C22.501 26.2888 22.2317 26.5242 21.9722 26.7663C21.7258 26.9951 21.4795 27.2272 21.2397 27.4692C20.8029 27.9103 20.3824 28.3679 19.9784 28.8387L18.6055 31.5146L17.0781 34.4857C17.2292 34.4194 17.377 34.3531 17.5248 34.2834C17.5314 34.2801 17.5413 34.2768 17.5478 34.2735C18.8387 33.6633 20.0671 32.9438 21.22 32.1248C24.7214 29.5118 27.5396 26.0202 29.3494 21.9648C28.607 22.2799 27.8844 22.6313 27.1815 23.0127C26.2487 23.52 25.3487 24.087 24.4882 24.7071C24.2024 24.9127 23.9232 25.1216 23.6473 25.3371C23.6177 25.3603 23.5882 25.3835 23.5586 25.4068" fill="#E1A77E"/>
      <path d="M16.5156 34.7272H16.6536L16.693 34.6543C16.6339 34.6808 16.5747 34.704 16.5156 34.7272Z" fill="white"/>
      <path d="M14.8104 34.7279H14.6724L14.633 34.6549L13.329 32.1149L12.163 29.8469L2.60154 11.2314C2.53585 11.1949 2.46687 11.1618 2.40118 11.1319C2.33549 11.0987 2.26979 11.0689 2.2041 11.0391L2.21067 11.049L11.3517 28.8421L12.7247 31.5181L14.252 34.4891L14.4786 34.9268L14.5969 34.9998H15.6578V34.7312H14.8169L14.8104 34.7279Z" fill="#F38336"/>
      <path d="M2.20876 11.0463C1.49272 10.718 1.02959 10.529 0.940906 10.4627C0.205157 9.9222 0.297126 9.56409 0.113189 10.3732C0.0836272 10.4992 0.0639196 10.6351 0.0507812 10.7711C0.280703 16.2125 2.82955 21.12 6.83675 24.7045C7.43126 25.1322 8.00935 25.5832 8.56445 26.0574C8.66298 26.1436 8.76152 26.2298 8.86006 26.316C9.02757 26.4652 9.19837 26.6145 9.3626 26.767C9.60895 26.9991 9.85529 27.2279 10.0951 27.47C10.5319 27.911 10.9523 28.3686 11.3564 28.8394L10.6469 27.4567L2.20876 11.0463Z" fill="#C06437"/>
      <path d="M23.109 14.5732C22.2254 15.2298 21.3254 15.9725 20.4484 16.8081C19.959 17.2757 19.4762 17.7731 19.0098 18.3003C17.8963 19.7626 16.8354 21.467 15.8993 23.4565C15.8237 23.619 15.7482 23.7815 15.6726 23.9473L15.6529 23.8511C15.6529 23.8511 15.6529 23.8544 15.6529 23.8578V23.9473C15.5774 23.7815 15.5018 23.619 15.4263 23.4565C14.4902 21.467 13.4293 19.7659 12.3158 18.3003C11.8494 17.7731 11.3665 17.2757 10.8771 16.8081C10.0001 15.9725 9.10345 15.2298 8.21661 14.5732C8.15092 14.5202 8.08522 14.4671 8.01953 14.4141L8.03267 14.434L11.8329 21.3542L15.6332 28.2745H15.6529V28.2016L15.6759 28.2745H15.6956L19.4959 21.3542L23.2962 14.434L23.3027 14.424L23.3093 14.4141C23.2436 14.4671 23.1779 14.5202 23.1122 14.5732" fill="#C06437"/>
      <path d="M11.832 21.3555L15.6323 28.2791H15.652H15.6323L11.832 21.3555Z" fill="#C06437"/>
      <path d="M23.3069 14.4141L23.2988 14.4262L23.3069 14.4141Z" fill="#C06437"/>
      <path d="M19.4958 21.3555L15.6955 28.2791H15.6758H15.6955L19.4958 21.3555Z" fill="#C06437"/>
      <path d="M31.2809 10.7711C31.2677 10.6351 31.248 10.4992 31.2185 10.3732C31.0345 9.56409 31.1265 9.9222 30.3908 10.4627C30.2988 10.529 29.8389 10.718 29.1229 11.0496L29.1295 11.0397C29.0638 11.0695 28.9981 11.1027 28.9291 11.1325C28.8634 11.1657 28.7944 11.1955 28.7288 11.2287L23.9464 20.5364L19.164 29.8441L17.998 32.1122L16.694 34.6522L16.6546 34.7252H15.6758V34.9937H16.7367L16.855 34.9208L17.0816 34.4831L18.6089 31.512L19.9819 28.8361C20.3859 28.3653 20.8063 27.9077 21.2432 27.4666C21.4797 27.2279 21.726 26.9958 21.9756 26.7637C22.1399 26.6111 22.3074 26.4586 22.4782 26.3094C22.5767 26.2232 22.6752 26.137 22.7738 26.0508C23.0333 25.8286 23.2993 25.6097 23.5687 25.3975C23.5982 25.3743 23.6278 25.3511 23.6573 25.3279C23.9332 25.1124 24.2124 24.9001 24.4982 24.6979C28.5021 21.1101 31.0542 16.2058 31.2842 10.7644" fill="#C06437"/>
      <path d="M24.4942 24.7031C24.2084 24.9087 23.9292 25.1176 23.6533 25.3331C23.9292 25.1176 24.2084 24.9054 24.4942 24.7031Z" fill="#C06437"/>
      <path d="M23.5644 25.4043C23.2951 25.6165 23.029 25.832 22.7695 26.0575C23.029 25.8354 23.2951 25.6165 23.5644 25.4043Z" fill="#C06437"/>
      <path d="M19.1602 29.8435L23.9425 20.5391L19.1602 29.8435Z" fill="#C06437"/>
      <path d="M17.9971 32.1151L19.1631 29.8438L19.1598 29.8471L17.9971 32.1151Z" fill="#C06437"/>
      <path d="M15.6563 23.8444L15.63 23.8908C15.5644 23.7449 15.4954 23.599 15.4297 23.4531C15.5052 23.6156 15.5808 23.7781 15.6563 23.9439V23.8543C15.6563 23.8543 15.6563 23.851 15.6563 23.8477L15.676 23.9439C15.7516 23.7781 15.8271 23.6156 15.9027 23.4531C15.8337 23.6023 15.7647 23.7516 15.6957 23.9041L15.6596 23.8444H15.6563Z" fill="#F38336"/>
      <path d="M15.6294 23.8916L15.6556 23.8452L15.695 23.9049C15.764 23.7524 15.833 23.6031 15.902 23.4539C16.8381 21.4644 17.899 19.7633 19.0125 18.2977C20.4807 16.3712 22.0409 14.8558 23.5419 13.6422C27.4506 10.4788 30.9421 9.35803 31.2575 8.30025C31.5596 7.27896 30.8305 4.89814 30.144 3.03128C29.9732 2.46758 29.4312 0.607359 29.2998 0.123238C29.2801 0.0536036 29.2112 -0.0558212 29.1225 0.0337081C29.0404 0.11329 28.725 0.391826 28.5871 0.484671C28.4031 0.610675 28.0254 0.799682 27.7068 0.948897C26.7674 1.32691 24.6817 2.32168 21.9982 4.70913C20.0537 6.43672 18.4245 8.98996 17.3078 11.0425C16.6903 12.0936 16.145 13.1945 15.6786 14.3352L15.6556 14.4081V14.3352C15.1892 13.1945 14.644 12.0936 14.0265 11.0425C12.9097 8.98996 11.2773 6.43672 9.33608 4.70913C6.64599 2.32168 4.56028 1.32359 3.62088 0.948897C3.30228 0.799682 2.92783 0.610675 2.74061 0.484671C2.60266 0.391826 2.28734 0.116606 2.20522 0.0337081C2.11654 -0.0558212 2.04756 0.0536036 2.02786 0.123238C1.89647 0.607359 1.35123 2.4709 1.18372 3.03128C0.497236 4.90145 -0.231944 7.28227 0.0702385 8.30357C0.385559 9.36134 3.87708 10.4821 7.78575 13.6455C9.28681 14.8591 10.847 16.3712 12.3152 18.301C13.4287 19.7666 14.4896 21.4677 15.4257 23.4572C15.4947 23.5998 15.5604 23.7457 15.6261 23.8949" fill="#F38336"/>
      <path d="M28.6007 11.2915C28.2098 11.4872 27.8256 11.6762 27.4544 11.8652C26.2588 12.4886 24.8037 13.3374 23.2961 14.4383L19.4958 21.3586L15.6955 28.2789H15.6758V28.3684L15.6561 28.322V28.2789H15.6364L11.8362 21.3586L8.03588 14.4383C6.52825 13.3374 5.07317 12.4886 3.87758 11.8652C3.50314 11.6762 3.49329 11.6463 3.10242 11.4507C3.05972 11.4308 2.64586 11.2517 2.60645 11.2318L12.1679 29.844L13.3339 32.1121L14.6379 34.6521C14.697 34.6786 14.7562 34.7018 14.8153 34.725H15.6561L15.6758 34.6388V34.725H16.5167C16.5758 34.7018 16.6349 34.6753 16.6941 34.6521L17.998 32.1121L19.1641 29.844L23.9464 20.5363L28.7288 11.2285C28.6894 11.2484 28.6467 11.2683 28.604 11.2882" fill="white"/>
      <path d="M54.8143 18.5098C53.0603 18.5098 51.6447 19.0768 50.633 20.171V18.7087H48.1006V34.1542H50.633V25.629C50.633 21.3415 53.8322 21.01 54.8143 21.01H55.3727V18.5098H54.8143Z" fill="white"/>
    </svg>
  </div>
);
