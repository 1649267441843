import React, { ChangeEvent, useEffect, useState } from 'react';
import { EditProps, Edit, useNotify, useRedirect } from 'react-admin';
import { Button, MenuItem, Select } from '@mui/material';
import { clsx } from 'clsx';

import { useEditProps, useRbacView, useRecord } from '@hooks';
import { StateGrade, stateGrades, StateScore, StateWithScore } from '@types';
import { stateHttp } from '@network/state-http';
import { axiosErrorToString } from '@utils';
import { Forbidden, InitialPage, InitialCard } from '@components';

import cls from './state-edit.module.css';

const defaultScore: StateScore = {
  environmental: 0,
  economics: 0,
  governance: 0,
  financially: 0,
  socially: 0,
  ponderation: 0,
};

export const StateEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const record = useRecord<StateWithScore>(props);
  const notify = useNotify();
  const redirect = useRedirect();

  const [score, setScore] = useState<StateScore>(defaultScore);
  const [grade, setGrade] = useState<StateGrade | ''>('');

  useEffect(() => {
    if (record) {
      setGrade(record.grade || '');

      setScore({
        environmental: record.environmental || 0,
        economics: record.economics || 0,
        governance: record.governance || 0,
        financially: record.financially || 0,
        socially: record.socially || 0,
        ponderation: record.ponderation || 0,
      });
    }
  }, [record]);

  const scoreChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.replace(/\D/g, '');

    const newValue = value === ''
      ? 0
      : +value <= 100
        ? +value
        : 100;

    setScore({
      ...score,
      [e.target.name]: newValue,
    });
  };

  const gradeChangeHandler = (e: any) => {
    const value = e.target.value;
    setGrade(value);
  };

  if (!rbacView) {
    return <Forbidden />;
  }

  if (!record) {
    return null;
  }

  const { id, name, stateCode, latitude, longitude, countryId } = record;
  const scoreFields: [string, number][] = Object.entries(score);

  const submit = async () => {
    const params = {
      name,
      countryId,
      stateCode,
      latitude,
      longitude,
      score,
      grade,
    };

    try {
      await stateHttp.updateOne(id, params);
      notify('State has been updated');
    } catch (e) {
      notify(axiosErrorToString(e), { type: 'error' });
    }
  };

  const cancel = () => {
    redirect('/state');
  };

  return (
    <InitialPage title="Individual State" backTo="/state" backText="Back to all states">
      <Edit {...props} {...editProps}>
        <div className={cls.row}>
          <div className={cls.col}>
            <InitialCard extraPadding left="General information">
              <div className={cls.values}>
                <div className={cls.property}>
                  <div className={cls.label}>Id</div>
                  <input className={cls.value} value={id} disabled />
                </div>
                <div className={cls.property}>
                  <div className={cls.label}>Name</div>
                  <input className={cls.value} value={name} disabled />
                </div>
                <div className={cls.property}>
                  <div className={cls.label}>Country Mexico</div>
                  <input className={cls.value} value="Mexico" disabled />
                </div>
                <div className={cls.property}>
                  <div className={cls.label}>Latitude</div>
                  <input className={cls.value} value={latitude} disabled />
                </div>
                <div className={cls.property}>
                  <div className={cls.label}>Longitude</div>
                  <input className={cls.value} value={longitude} disabled />
                </div>
                <div className={cls.property}>
                  <div className={cls.label}>State code</div>
                  <input className={cls.value} value={stateCode} disabled />
                </div>
              </div>
            </InitialCard>
          </div>

          <div className={cls.col}>
            <InitialCard extraPadding left="Score variables">
              <div className={cls.values}>
                {scoreFields.map(([name, value]) => (
                  <div key={name} className={cls.property}>
                    <div className={cls.label}>{name}</div>
                    <input
                      className={clsx(cls.value, cls.centered)}
                      name={name}
                      value={value}
                      onChange={scoreChangeHandler}
                    />
                  </div>
                ))}
                <div className={clsx(cls.property, 'stateSelect')}>
                  <div className={cls.label}>Grade</div>
                  <Select
                    name={name}
                    value={grade}
                    onChange={gradeChangeHandler}
                    className={clsx(cls.value, cls.centered)}
                  >
                    {stateGrades.map(value =>
                      <MenuItem key={value} value={value}>{value}</MenuItem>,
                    )}
                  </Select>
                </div>
              </div>
            </InitialCard>
          </div>
        </div>
        <div className={cls.actions}>
          <Button className={cls.save} variant="contained" onClick={submit}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
              <g clipPath="url(#clip0_1101_4722)">
                <path d="M14.8964 1.75077L13.2321 0.10257C13.1643 0.0389059 13.075 0 12.9786 0H2.25C2.21429 0 2.18571 0 2.15 0C2.15 0 2.14643 0 2.14286 0H0.357143C0.160714 0 0 0.159161 0 0.35369V14.6463C0 14.8408 0.160714 15 0.357143 15H14.6429C14.8393 15 15 14.8408 15 14.6463V2.00189C15 1.90639 14.9607 1.81797 14.8964 1.75077ZM7.5 11.8486C6.11786 11.8486 5 10.7416 5 9.37279C5 8.00401 6.11786 6.89696 7.5 6.89696C8.88214 6.89696 10 8.00401 10 9.37279C10 10.7416 8.88214 11.8486 7.5 11.8486ZM11.4286 3.90474C11.4286 4.49187 10.95 4.96581 10.3571 4.96581H4.64286C4.05 4.96581 3.57143 4.49187 3.57143 3.90474V1.40769C3.57143 1.22377 3.72143 1.07522 3.90714 1.07522L7.91071 1.06814C8.07857 1.06814 8.21429 1.20255 8.21429 1.36878V3.47324C8.21429 3.70667 8.40357 3.89413 8.63929 3.89413H9.575C9.81071 3.89413 10 3.70667 10 3.47324V1.36171C10 1.19547 10.1357 1.06107 10.3036 1.06107H11.0929C11.2786 1.06107 11.4286 1.20962 11.4286 1.39354V3.90474Z" fill="#E1E5ED"/>
              </g>
              <defs>
                <clipPath id="clip0_1101_4722">
                  <rect width="15" height="15" fill="white"/>
                </clipPath>
              </defs>
            </svg>
              Save
          </Button>
          <Button className={cls.cancel} variant="contained" onClick={cancel}>Cancel</Button>
        </div>
      </Edit>
    </InitialPage>
  );
};
