import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

import cls from './score-widget.module.css';

interface Props {
  className?: string;
  footerClassName?: string;
  title?: string;
  value: number;
  percents?: number;
  deniedText?: string;
  approvedText?: string;
  avatarUrl?: string;
  onClick?: () => void;
  readonly?: boolean;
}

export const ScoreWidget = (props: PropsWithChildren<Props>) => {
  const {
    className,
    footerClassName,
    value,
    children,
    percents,
    deniedText,
    approvedText,
    avatarUrl,
    onClick,
    readonly,
  } = props;

  /** 0% - rotate(0deg)
   * 100% - rotate(180deg)
   * 1% = 1.8deg */

  const getValue = () => percents ? percents : value;

  return (
    <>
      <div
        className={clsx(
          cls.body, 'sw-body',
          className,
          avatarUrl && cls.withAvatar,
          onClick && cls.withClick,
        )}
        onClick={onClick}
        title={onClick ? 'Open report' : undefined}
      >
        <div className={clsx(cls.bodyLeft, 'sw-body-left')}>
          {deniedText || 'Denied'}
        </div>
        <div className={clsx(cls.bodyCenter, cls.widget, 'sw-body-center')}>
          <div
            className={clsx(cls.widgetArrow, 'sw-widget-arrow')}
            style={{ transform: `rotate(${getValue() * 1.8}deg)` }}
          >
            <div className={clsx(cls.blackArrow, 'sw-black-arrow')} />
            <div className={clsx(cls.point, 'sw-point')} />
          </div>
          <div className={clsx(cls.widgetPercents, 'sw-widget-percents')}>
            <div className={clsx(cls.widgetPercentsTitle, 'sw-widget-percents-title')}>
              Score
            </div>
            <div className={clsx(cls.widgetPercentsValue, 'sw-widget-percents-value')}>
              {value}%
            </div>
          </div>
          {avatarUrl && (
            <div className={cls.avatar}>
              <img alt="avatar" src={avatarUrl} />
            </div>
          )}
        </div>
        <div className={clsx(cls.bodyRight, 'sw-body-right')}>
          {approvedText || 'Approved'}
        </div>
      </div>
      {children && (
        <div className={clsx(cls.footer, footerClassName, 'sw-footer')}>{children}</div>
      )}
    </>
  );
};
