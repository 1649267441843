import { Http } from './http';
import {
  CreateFinanceDepositParams,
  FinanceDeposit,
  FinanceDepositOptions,
  FinanceDocument,
} from '@types';

class FinanceDepositHttp extends Http {
  async create(params: CreateFinanceDepositParams) {
    const res = await this.post<FinanceDeposit>('/finance-deposit', params);

    return res.data;
  }

  async update(params: CreateFinanceDepositParams, id: number) {
    const res = await this.put<FinanceDeposit>(`/finance-deposit/${id}`, params);

    return res.data;
  }

  async remove(id: number) {
    const res = await this.delete<FinanceDeposit>(`/finance-deposit/${id}`);

    return res.data;
  }

  async options(id?: number | string) {
    const res = await this.get<FinanceDepositOptions>('/finance-deposit/options', {
      params: { id },
    });

    return res.data;
  }

  async addDocument(params: {id: number, fileIds: number[], type: string}) {
    const { id } = params;
    const url = `/finance-deposit/${id}/documents`;
    const res = await this.post<FinanceDocument[]>(url, params);

    return res.data;
  }

  async removeDocument(params: {id: number, documentId: number}) {
    const { id, documentId } = params;
    const url = `/finance-deposit/${id}/documents/${documentId}`;
    const res = await this.delete<FinanceDocument[]>(url);

    return res.data;
  }
}

export const financeDepositHttp = new FinanceDepositHttp();
