import { PropsWithChildren, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Badge } from 'antd';
import { Menu, MenuItemLink, useResourceDefinitions, useSidebarState } from 'react-admin';
import { KeyboardArrowDown, ViewList, KeyboardArrowRight } from '@mui/icons-material';
import { clsx } from 'clsx';

import { rbac } from '@utils/rbac';
import { IMenuItem } from './interfaces';
import { MenuIcon } from '../MenuIcon';
import cls from './app-menu.module.css';

export const AppMenu = (props: any) => {
  const location = useLocation();
  const [active, setActive] = useState<string>('');
  const resourcesByKey: any = useResourceDefinitions();
  const [open] = useSidebarState();

  const admin = rbac.getAdmin() as any;
  const path = location.pathname;
  const resources = Object.values(resourcesByKey);

  /** Function transformer for menu list hierarchy */
  const getMenuList = () => {
    const menuList: IMenuItem[] = [];

    resources.forEach((item: any) => {
      if (!item.options.menuParent) {
        menuList.push(item);
      } else {
        const idx = menuList.findIndex((i: IMenuItem) => i.name === item.options.menuParent);
        if (idx !== -1) {
          if (!menuList[idx].childrensList) {
            menuList[idx].childrensList = [item];
          } else {
            const child = menuList[idx].childrensList?.find((i: IMenuItem) => i.name === item.name);
            if (!child) {
              menuList[idx].childrensList?.push(item);
            }
          }
        }
      }
    });

    return menuList;
  };

  /** Parent`s menu item handler */
  const handleParentItem = (name: string) => {
    (active === name) ? setActive('') : setActive(name);
  };

  /** Get parent`s menu icon */
  const ItemIcon = (resource: IMenuItem) => (<Icon resource={resource.name}>
    <resource.icon />
  </Icon>);

  /** Get menu link text */
  const menuItemLinkText = (resource: IMenuItem) => (
    <span className={cls.menuText}>
      {(resource.options && resource.options.label) || resource.name}
    </span>
  );

  /** Added notification badge */
  const MenuNotificationWrapper = (
    { resource, children }: {resource: IMenuItem; children: any},
  ) => {
    const notification = resource.options?.notification || '_';
    if (resource.options?.notification && admin && admin[notification]) {
      return (
        <Badge
          className={cls.badge}
          count={admin[notification]}
          offset={[-15, 15]}
        >
          {children}
        </Badge>
      );
    }

    return children;
  };

  /** Create parent`s menu component */
  const menuItemParent = (resource: IMenuItem) => {
    const isActive = resource.name === active;
    const isSelected = path === `/${resource.name}` || path.includes(`/${resource.name}/`);

    return (
      <div key={resource.name} className={isSelected || isActive ? cls.parentSelected : undefined}>
        <MenuItemLink to=""
          className={cls.parent}
          leftIcon={ItemIcon(resource)}
          primaryText={(
            <div className={cls.parentText} onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleParentItem(resource.name);
            }}>
              <span className={cls.menuText}>
                {resource.options?.label || resource.name}
              </span>
              {isActive && <KeyboardArrowDown className={cls.parentIcon} />}
              {!isActive && <KeyboardArrowRight className={cls.parentIcon} />}
            </div>
          )}
        />
        <div className={clsx(cls.children, isActive ? cls.active : undefined)}>
          {resource.childrensList?.map((child: IMenuItem) => {
            const isSelected = path === `/${child.name}` || path.includes(`/${child.name}/`);
            const notification = child.options?.notification || '_';

            if (!rbac.canShowList(child.name, admin)) {
              return null;
            }
            if (child.options?.notification && admin && admin[notification]) {
              return (
                <MenuNotificationWrapper
                  resource={child}
                  key={`${child.name}-${admin[notification]}`}
                >
                  <MenuItemLink
                    to={`/${child.name}`}
                    primaryText={menuItemLinkText(child)}
                    leftIcon={<Icon resource={child.name}>
                      <child.icon />
                    </Icon>}
                    onClick={props.onMenuClick}
                    className={clsx(isSelected ? cls.menuSelected : '', child.options?.iconInverse && cls.inverse)}
                    sidebarIsOpen={open}
                  />
                </MenuNotificationWrapper>
              );
            } else {
              return (
                <MenuItemLink
                  key={child.name}
                  to={`/${child.name}`}
                  primaryText={menuItemLinkText(child)}
                  leftIcon={<Icon resource={child.name}>
                    <child.icon />
                  </Icon>}
                  onClick={props.onMenuClick}
                  className={clsx(isSelected ? cls.menuSelected : '', child.options?.iconInverse && cls.inverse)}
                  sidebarIsOpen={open}
                />
              );
            }
          })}
        </div>
      </div>
    );
  };

  /** Create menu component */
  const menuItem = (resource: IMenuItem) => {
    if (resource?.options?.isHidden) {
      return null;
    }
    if (!resource.childrensList) {
      if (!rbac.canShowList(resource.name, admin)) return null;
      const isSelected = path === `/${resource.name}` || path.includes(`/${resource.name}/`);

      return (
        <MenuNotificationWrapper resource={resource} key={resource.name}>
          <MenuItemLink
            to={`/${resource.name}`}
            primaryText={menuItemLinkText(resource)}
            leftIcon={<Icon resource={resource.name}>
              <resource.icon />
            </Icon>}
            onClick={props.onMenuClick}
            className={isSelected ? cls.menuSelected : undefined}
            sidebarIsOpen={open}
          />
        </MenuNotificationWrapper>
      );
    }

    const canShowChildren = (resource.childrensList || []).filter(
      (childResource: IMenuItem) => rbac.canShowList(childResource.name, admin),
    ).length > 0;

    if (!canShowChildren) return null;

    return menuItemParent(resource);
  };

  return (
    <Menu className={open ? cls.menuOpened : cls.menuClosed} {...props}>
      <div className={cls.menu}>
        <MenuItemLink
          key={'dashboard'}
          to={'/'}
          primaryText={<span className={cls.menuText}>Dashboard</span>}
          leftIcon={<Icon resource={'/'}><MenuIcon name="dashboard" /></Icon>}
          onClick={props.onMenuClick}
          className={location.pathname === '/' ? cls.menuSelected : ''}
          sidebarIsOpen={open}
        />
        {getMenuList().map(resource => menuItem(resource))}
      </div>
    </Menu>
  );
};

const Icon = ({ children, resource }: PropsWithChildren<{resource: string}>): any => {
  const isTransparent = ['users', 'credit-risk'].includes(resource);
  return (
    <div className={isTransparent ? cls.menuIconTransparent : cls.menuIcon}>
      {children ? children : <ViewList />}
    </div>
  );
};
