import { Http } from './http';
import { Chat, ChatManagers, ChatMessage } from '@types';

class ChatHttp extends Http {

  async postMessage(params: PostMessageParams): Promise<ChatMessage> {
    const res = await this.post<ChatMessage>(`/chat/${params.chatId}/post-message`, params);

    return res.data;
  }

  async getUnreaded(): Promise<UnreadedResonse> {
    const res = await this.get<UnreadedResonse>('/chat/unreaded');

    return res.data;
  }

  async leaveChat(chatId: number): Promise<ChatManagers> {
    const res = await this.get<ChatManagers>(`/chat/${chatId}/leave`);

    return res.data;
  }

  async readChat(chatId: number): Promise<boolean> {
    const res = await this.get<boolean>(`/chat/${chatId}/read`);

    return res.data;
  }

  async getCreditChat(creditId: number): Promise<Chat> {
    const res = await this.get<Chat>(`/credit/${creditId}/chat`);

    return res.data;
  }

  async makeCreditChat(creditId: number): Promise<Chat> {
    const res = await this.post<Chat>(`/credit/${creditId}/chat`);

    return res.data;
  }
}

export const chatHttp = new ChatHttp();

interface PostMessageParams {
  chatId: number;
  message: string;
}

export type UnreadedResonse = {
  chats: Record<number, UnreadedChat>;
  unreadedChats: number;
} | false | null;

export interface UnreadedChat {
  unreaded: number;
  lastMessage: ChatMessage | null;
  skip: boolean;
  createdAt: string;
  title?: string;
  creditId?: number;
  creditCode?: string;
}
