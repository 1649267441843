import { useMemo, useState } from 'react';
import { Col, Row, Space } from 'antd';

import { moneyFormatter } from '@utils';
import { CreditStatus, Dealer, Lead, User, UserStatus } from '@types';
import { dealerHttp, leadHttp, userHttp } from '@network';
import { InitialCard } from '@components';
import {
  FinanceActivationCreditStatus,
  FinanceActivationLeadStage,
  FinanceActivationUserStatus,
} from './FinanceActivationValues';
import { FinancialActivationLabel } from './FinancialActivationLabel';
import { FinancialGoTo } from './FinancialGoTo';
import { FinanceActivationTable } from './FinanceActivationTable';
import { FinanceActivationAddForm } from './FinanceActivationAddForm';
import cls from './FinanceActivationTables.module.css';

export type ActivationTableType = 'credits' | 'leads' | 'users' | 'associates' | 'retailers' | 'buyers';

interface Props {
  activation: any;
  refresh: () => void;
}

export const FinanceActivationTables = ({ activation, refresh }: Props) => {
  const [type, setType] = useState<ActivationTableType | null>(null);

  const columns = useMemo(() => ({
    credits: [
      {
        key: 'code',
        title: 'Credit Code',
        dataIndex: 'code',
        render: (value: string) => <div style={{ minHeight: 32, lineHeight: '32px' }}>{value}</div>,
        width: 100,
      },
      {
        key: 'fullName',
        title: 'Accredited',
        dataIndex: 'fullName',
        render: (value: string) => <FinancialActivationLabel type="credits" label={value} />,
        align: 'center',
        width: 260,
      },
      {
        key: 'bank_approval',
        title: 'Credit',
        dataIndex: 'bank_approval',
        render: (value: string) => <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{moneyFormatter.format(+value)}</span>,
        align: 'center',
        width: 160,
      },
      {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        render: (status: CreditStatus) => (
          <FinanceActivationCreditStatus status={status} />
        ),
        width: 120,
      },
    ],
    leads: [
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        render: (value: string, record: Lead) =>
          <FinancialActivationLabel type="leads" label={value} id={record.id} refresh={refresh} canRemove />,
        width: 300,
      },
      {
        key: 'stage',
        title: 'Stage',
        dataIndex: 'stage',
        render: (stage: string) => <FinanceActivationLeadStage stage={stage} />,
        align: 'center',
        width: 90,
      },
      {
        key: 'phone',
        title: 'Phone',
        dataIndex: 'phone',
        render: (value: string) => <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</span>,
        align: 'center',
        width: 120,
      },
      {
        key: 'gotoLead',
        title: 'Go to Lead',
        dataIndex: 'gotoLead',
        render: (_: unknown, record: Lead) => <FinancialGoTo url={`/lead/${record.id}`} />,
        align: 'center',
        width: 120,
      },
    ],
    users: [
      {
        key: 'fullName',
        title: 'Name',
        dataIndex: 'fullName',
        render: (value: string, record: Lead) =>
          <FinancialActivationLabel type="users" label={value} id={record.id} refresh={refresh} canRemove />,
        width: 300,
      },
      {
        key: 'code',
        title: 'User Code',
        dataIndex: 'code',
        align: 'center',
        width: 90,
      },
      {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        render: (status: UserStatus) => <FinanceActivationUserStatus status={status} />,
        align: 'center',
        width: 90,
      },
      {
        key: 'gotoUser',
        title: 'Go to User',
        dataIndex: 'gotoUser',
        width: 120,
        align: 'center',
        render: (_: unknown, record: User) => <FinancialGoTo url={`/user/${record.id}`} />,
      },
    ],
    associates: [
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        render: (value: string, record: Lead) =>
          <FinancialActivationLabel type="associates" label={value} id={record.id} refresh={refresh} canRemove />,
        width: 300,
      },
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        align: 'center',
        width: 90,
      },
      {
        key: 'phone',
        title: 'Phone',
        dataIndex: 'phone',
        render: (value: string) => <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</span>,
        width: 120,
      },
      {
        key: 'gotoAssociate',
        title: 'Go to Associate',
        dataIndex: 'gotoAssociate',
        render: (_: unknown, record: Dealer) => <FinancialGoTo url={`/dealer-associate?dealerId=${record.id}&displayedFilters={"activationId":true}`} />,
        align: 'center',
        width: 120,
      },
    ],
    retailers: [
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        render: (value: string, record: Lead) =>
          <FinancialActivationLabel type="retailers" label={value} id={record.id} refresh={refresh} canRemove />,
        width: 300,
      },
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        align: 'center',
        width: 90,
      },
      {
        key: 'gotoRetailer',
        title: 'Go to Retailer',
        dataIndex: 'gotoRetailer',
        render: (_: unknown, record: Dealer) => <FinancialGoTo url={`/dealer-retailer?dealerId=${record.id}&displayedFilters={"activationId":true}`} />,
        align: 'center',
        width: 120,
      },
    ],
    buyers: [
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        render: (value: string, record: Lead) =>
          <FinancialActivationLabel type="buyers" label={value} id={record.id} refresh={refresh} canRemove />,
        width: 300,
      },
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        align: 'center',
        width: 90,
      },
      {
        key: 'tier',
        title: 'Tier',
        dataIndex: 'tier',
        align: 'center',
        width: 90,
      },
      {
        key: 'gotoBuyer',
        title: 'Go to Buyer',
        dataIndex: 'gotoBuyer',
        render: (_: unknown, record: Dealer) => <FinancialGoTo url={`/dealer?dealerId=${record.id}&displayedFilters={"activationId":true}`} />,
        align: 'center',
        width: 120,
      },
    ],
  }), [activation]);

  const submitAddActivation = async (ids: number[]) => {
    setType(null);

    try {
      if (type === 'users') {
        await userHttp.addBulkActivation(ids, activation.id);
      }
      if (type === 'leads') {
        await leadHttp.addBulkActivation(ids, activation.id);
      }
      if (type === 'associates' || type === 'retailers' || type === 'buyers') {
        await dealerHttp.addBulkActivation(ids, activation.id);
      }
    } catch (e) {
      console.error(e);
    } finally {
      refresh();
    }

  };

  const cancelAddActivation = () => {
    setType(null);
  };

  return (
    <div>
      <Space size={24} direction="vertical" className={cls.list}>
        <Row gutter={[24, 24]}>
          <Col lg={24} xl={12}>
            <InitialCard left={`Credits - ${activation.credits?.length || 0}`} extraPadding>
              <FinanceActivationTable
                key={activation.credits?.length || 'credits'}
                list={activation.credits || []}
                columns={columns?.credits || []}
                type="credits"
              />
            </InitialCard>
          </Col>
          <Col lg={24} xl={12}>
            <InitialCard left={`Leads - ${activation.leads?.length || 0}`} extraPadding>
              <FinanceActivationTable
                key={activation.leads?.length || 'leads'}
                list={activation.leads || []}
                columns={columns?.leads || []}
                add={() => setType('leads')}
                type="leads"
              />
            </InitialCard>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col lg={24} xl={12}>
            <InitialCard left={`Users - ${activation.users?.length || 0}`} extraPadding>
              <FinanceActivationTable
                key={activation.users?.length || 'users'}
                list={activation.users || []}
                columns={columns?.users || []}
                add={() => setType('users')}
                type="users"
              />
            </InitialCard>
          </Col>
          <Col lg={24} xl={12}>
            <InitialCard left={`Associates - ${activation.dealers?.associate?.length || 0}`} extraPadding>
              <FinanceActivationTable
                key={activation.dealers?.associate?.length || 'associate'}
                list={activation.dealers?.associate || []}
                columns={columns?.associates || []}
                add={() => setType('associates')}
                type="associates"
              />
            </InitialCard>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col lg={24} xl={12}>
            <InitialCard left={`Retailers - ${activation.dealers?.retailer?.length || 0}`} extraPadding>
              <FinanceActivationTable
                key={activation.dealers?.retailer?.length || 'retailer'}
                list={activation.dealers?.retailer || []}
                columns={columns?.retailers || []}
                add={() => setType('retailers')}
                type="retailers"
              />
            </InitialCard>
          </Col>
          <Col lg={24} xl={12}>
            <InitialCard left={`Buyers - ${activation.dealers?.buyer?.length || 0}`} extraPadding>
              <FinanceActivationTable
                key={activation.dealers?.buyer?.length || 'dealer'}
                list={activation.dealers?.buyer || []}
                columns={columns?.buyers || []}
                add={() => setType('buyers')}
                type="buyers"
              />
            </InitialCard>
          </Col>
        </Row>
      </Space>

      {!!type && (
        <FinanceActivationAddForm
          type={type}
          submit={submitAddActivation}
          cancel={cancelAddActivation}
        />
      )}
    </div>
  );
};
