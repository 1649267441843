import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Col, Progress, Row } from 'antd';
import { Link } from 'react-admin';

import { CallsReportNumbers, reportsHttp } from '@network';
import { InitialCard } from '@components';
import { querySerialize } from '@utils';
import { useWindowSize } from '@hooks';

interface Props {
  range: string;
  setLoader: (num: number, value: boolean) => void;
}

export const CallsReport = ({ range, setLoader }: Props) => {
  const [numbers, setNumbers] = useState<CallsReportNumbers>();
  const { width } = useWindowSize();
  const cls = useStyles();

  const loadNumbers = async () => {
    setLoader(11, true);
    try {
      const nextNumbers = await reportsHttp.callsReport({ range });
      setNumbers(nextNumbers);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(11, false);
    }
  };

  useEffect(() => void loadNumbers(), [range]);

  if (!numbers) {
    return null;
  }

  const overdue = numbers.overdue;
  const future = numbers.future;

  const overdueColor = overdue >= 41 ? 'red' : (overdue >= 11 ? 'yellow' : '#8FD14F');
  const futureColor = future >= 20 ? 'red' : (future >= 15 ? 'yellow' : '#8FD14F');

  const overdueLink = '/credit-call?' + querySerialize({ filter: JSON.stringify({ callDate: 'overdue' }) });
  const futureLink = '/credit-call?' + querySerialize({ filter: JSON.stringify({ callDate: 'future' }) });

  return (
    <InitialCard center="Overdue Calls" extraPadding fullHeight
      boxClass={cls.box} contentClass={cls.content}>
      <Row gutter={[20, 20]}>
        <Col sm={24} md={12} className={cls.col}>
          <Link to={overdueLink}>
            <div className={cls.title}>Overdue Calls</div>
            <Progress
              type="circle"
              width={width && width >= 768 && width < 1400 ? 120 : 190}
              percent={100}
              style={{ marginTop: 8 }}
              strokeColor={overdueColor}
              trailColor={'black'}
              className={cls.progress}
              format={() => <span className={cls.counter}>{overdue}</span>}
            />
          </Link>
        </Col>
        <Col sm={24} md={12} className={cls.col}>
          <Link to={futureLink}>
            <div className={cls.title}>Future Calls</div>
            <Progress
              type="circle"
              width={width && width >= 768 && width < 1400 ? 120 : 190}
              percent={100}
              style={{ marginTop: 8 }}
              strokeColor={futureColor}
              trailColor={'black'}
              className={cls.progress}
              format={() => <span className={cls.counter}>{future >= 20 ? '20+' : future}</span>}
            />
          </Link>
        </Col>
      </Row>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    paddingBottom: '10px !important',
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn: {
    padding: 0,
    textTransform: 'none',
    display: 'block',
    width: '100%',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    color: '#000',
  },
  content: {
    paddingTop: 20,
    paddingBottom: 17,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  counter: {
    marginTop: 5,
    fontSize: 50,
    fontWeight: 700,
    color: '#000',
  },
});
