import React, { memo, useEffect, useState } from 'react';
import { Button, Divider, Modal, Select, Typography } from 'antd';
import { DollarCircleOutlined } from '@ant-design/icons';

import { creditHttp } from '@network';
import { moneyFormatter } from '@utils';
import { Credit, Order } from '@types';
import { useRequest, useUpdated } from '@hooks';
import cls from './confirm-order.module.css';

interface Props {
  order: Order;
  credits: Credit[];
  editable: boolean;
}

const ConfirmOrderInner = ({ order, credits, editable }: Props) => {
  const [selectedCredit, setSelectedCredit] = useState<Credit>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const updated = useUpdated();

  useEffect(() => {
    if (credits.length === 1) {
      setSelectedCredit(credits[0]);
    }
  }, [credits]);

  const showModal = () => void setIsModalVisible(true);
  const hideModal = () => void setIsModalVisible(false);

  const { loading, submit } = useRequest(async () => {
    if (!selectedCredit) {
      return;
    }
    await creditHttp.payOrder({
      creditId: selectedCredit.id,
      orderId: order.id,
    });
    updated();
  });

  const orderCost = moneyFormatter.format(order.totalCost);
  const selectCredit = (creditId: number) => {
    const nextCredit = credits.find(credit => credit.id === creditId);
    setSelectedCredit(nextCredit);
  };

  if (!editable || order.isConfirmed || order.isPaid) {
    return null;
  }

  return (
    <>
      <div className={cls.box}>
        <Button
          className={cls.btn}
          onClick={showModal}
          icon={<DollarCircleOutlined className={cls.btnIcon} />}
          type="primary"
        >Confirm order</Button>
      </div>
      <Modal
        open={isModalVisible}
        title="Confirm order"
        okText="Confirm"
        onOk={submit}
        confirmLoading={loading}
        onCancel={hideModal}
      >
        <Typography.Title level={3}>
          Order total: {orderCost}
        </Typography.Title>
        <Divider />
        <Select onChange={selectCredit} value={selectedCredit?.id} className={cls.select}>
          {credits.map(credit => (
            <Select.Option value={credit.id} key={credit.id}>
              Credit # {credit.code} ({credit.statusEn}).
              Balance: {moneyFormatter.format(credit.balance)}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};

export const ConfirmOrder = memo(ConfirmOrderInner);
