import { ListProps, TextField } from 'react-admin';

import {
  TextInput,
  Grid,
  GridList,
  BooleanField,
  ManyField,
  Forbidden,
  DateField,
} from '@components';
import { useRbacList } from '@hooks';
import cls from './RoleList.module.css';

export const RoleList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick="edit"
        className={cls.grid}
        defaultColumns={'id name permissions isEnabled createdAt'.split(' ')}
      >
        <TextField source="id" />
        <TextField source="name" />
        <ManyField source="permissions" reference="permission" label="Permissions" isColumn={false} sortable={false} />
        <BooleanField source="isEnabled" />
        <DateField source="createdAt" showTime />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
];
