import { useResourceContext } from 'react-admin';

export const useDealerReference = () => {
  const resource = useResourceContext();

  const dealerReference = resource === 'manager-retailer' ? 'dealer-retailer' :
    (resource === 'manager-associate' ? 'dealer-associate' : 'dealer-buyer');

  return dealerReference;
};
