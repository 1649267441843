import React from 'react';
import { ListProps, TextField } from 'react-admin';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  DateRangeInput,
  DateField,
  MoneyField,
} from '@components';
import { useExporter, useRbacEdit, useRbacList } from '@hooks';
import { LoanPaymentListActions } from './LoanPaymentListActions';

export const LoanPaymentList = (props: ListProps) => {
  const rbacList = useRbacList();
  const rbacEdit = useRbacEdit();
  const exporter = useExporter({
    filename: 'Loan Expected Payments',
    props: {
      'id': {
        label: 'ID',
      },
      'creditCode': {
        label: 'Credit Code',
      },
      'expectedDate': {
        label: 'Expected Date',
        transform: 'date',
      },
      'disposalFee': {
        label: 'Dispose Fee',
        transform: 'money',
      },
      'moratoryInterests': {
        label: 'Moratory Interests',
        transform: 'money',
      },
      'ordinaryInterests': {
        label: 'Ordinary Interests',
        transform: 'money',
      },
      'downPayment': {
        label: 'Down Payment',
        transform: 'money',
      },
      'guaranteeDeposits': {
        label: 'Guarantee Deposits',
        transform: 'money',
      },
      'principalAmortization': {
        label: 'Principal Amortization',
        transform: 'money',
      },
      'totalPayment': {
        label: 'Total Payment',
        transform: 'money',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      editComponent={rbacEdit ? <LoanPaymentListActions /> : undefined}
      filters={Filters}
      sort={{ field: 'signatureDate', order: 'DESC' }}
      exporter={exporter}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'creditCode expectedDate disposalFee moratoryInterests ordinaryInterests downPayment guaranteeDeposits principalAmortization totalPayment'.split(' ')}
      >
        <TextField source="id" />
        <TextField source="creditCode" label="Credit Code" />
        <DateField source="expectedDate" label="Expected Date" />
        <MoneyField source="disposalFee" label="Disposal Fee" />
        <MoneyField source="moratoryInterests" label="Moratory Interests" />
        <MoneyField source="ordinaryInterests" label="Ordinary Interests" />
        <MoneyField source="downPayment" label="Down Payment" />
        <MoneyField source="guaranteeDeposits" label="Guarantee Deposits" />
        <MoneyField source="principalAmortization" label="Principal Amortization" />
        <MoneyField source="totalPayment" label="Total Payment" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  <DateRangeInput key="expectedDate" source="expectedDate" label="Expected Date"
    defaultValue={false} alwaysOn />,
];
