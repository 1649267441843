import React, { useEffect, useState } from 'react';
import { DatePicker, Form, FormInstance, Input, Select, Switch } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { Guarantor, marriageOptions } from '@types';
import { GuarantorParams } from '@network';
import { useDebounce } from '@hooks';

import cls from '@pages/credit/CreditEdit/CreditGuarantor/credit.guarantor.module.css';

export const PHONE_CODE_REG_EXP = /^\+52|^\+7/;
const google_api_key = process.env.REACT_APP_GOOGLE_MAPS_API as string;

interface CreditGuarantorFormProps {
  onRemove: () => void;
  form: FormInstance<GuarantorParams>;
  guarantor?: Guarantor;
}

export const CreditGuarantorForm = ({ form, onRemove, guarantor }: CreditGuarantorFormProps) => {
  const isCompany = Form.useWatch('isCompany', form);
  const postalCode = Form.useWatch(['location', 'postalCode'], form);

  const [isPostalCodeValid, setIsPostalCodeValid] = useState<boolean>(false);

  const rfcLength = isCompany ? 12 : 13;

  const debouncedPostalCode = useDebounce(postalCode, 500);

  const isCompanyChangeHandler = () => {
    const rfc = form.getFieldValue('rfc');

    if (rfc.length > 0) {
      void form.validateFields(['rfc']);
    }
  };

  useEffect(() => {
    const getLocationByPostalCode = async () => {
      const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=mexico%20${postalCode}&key=${google_api_key}&region=MX&language=es`);
      const response = await res.json();

      if (response.status !== 'OK') {
        return null;
      }
      const [location] = response.results;

      return location;
    };

    if (postalCode) {
      (async () => {
        const location = await getLocationByPostalCode();

        const { address_components } = location || {};

        const filtered = address_components.filter((i) => !i.types.includes('country'));
        if (!filtered.length) {
          setIsPostalCodeValid(false);
          form.setFields([{
            name: ['location', 'postalCode'],
            errors: ['This postal code is not valid'],
          }]);

          return;
        }

        const country = address_components?.find((i) => i.types.includes('country'));
        const state = address_components?.find((i) => i.types.includes('administrative_area_level_1'));
        const city = address_components?.find((i) => i.types.includes('locality'));
        const neighbourhood = address_components?.find((i) => i.types.includes('sublocality'));

        form.setFieldValue(['location', 'country'], country?.long_name || '');
        form.setFieldValue(['location', 'state'], state?.long_name || '');
        form.setFieldValue(['location', 'city'], city?.long_name || '');
        form.setFieldValue(['location', 'neighbourhood'], neighbourhood?.long_name || '');

        setIsPostalCodeValid(true);
      })();
    }
  }, [debouncedPostalCode]);

  const isLocationMandatory = !guarantor || postalCode?.length > 0;

  return (
    <div className={cls.info}>
      <div className={cls.infoBody}>
        <div className={cls.infoHead}>
          <div className={cls.infoTitle}>Information</div>
          <div className={cls.infoSwitcher}>
            <div>Individual</div>
            <Form.Item
              name="isCompany"
              className={cls.row}
            >
              <Switch checked={isCompany} onChange={isCompanyChangeHandler} />
            </Form.Item>
            <div>Company</div>
          </div>
        </div>
        <div className={cls.infoProps}>
          <Form.Item
            label="Full Name*"
            name="fullName"
            className={cls.row}
            rules={[
              { required: true, message: 'Full Name is mandatory field' },
              { max: 80, message: 'First name should be less then 80 characters' },
            ]}
          >
            <Input className={cls.input} />
          </Form.Item>
          <Form.Item
            label="Phone*"
            name="phone"
            className={cls.row}
            rules={[
              () => ({
                validator(_, value) {
                  const phone = value?.replace(PHONE_CODE_REG_EXP, '').replace(/\D/g, '') || '';
                  if (phone.length < 10) {
                    return Promise.reject(new Error('Type in a correct phone'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <MaskedInput mask="+52 (000) 000-0000" className={cls.input} />
          </Form.Item>
          <Form.Item
            label="Email*"
            name="email"
            className={cls.row}
            rules={[
              () => ({
                validator(_, value) {
                  const reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/gi;
                  if (!reg.test(value)) {
                    return Promise.reject(new Error('Type in a correct email'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input className={cls.input} />
          </Form.Item>
          <Form.Item
            label="Birthday*"
            name="birthdate"
            className={cls.row}
            rules={[
              { required: true, message: 'Birthday is mandatory field' },
            ]}
          >
            <DatePicker className={cls.input} placeholder="DD / MM / YYYY" />
          </Form.Item>
          <Form.Item
            label="RFC*"
            name="rfc"
            className={cls.row}
            rules={[
              { required: true, len: rfcLength, message: `RFC should be ${rfcLength} characters` },
            ]}
          >
            <Input className={cls.input} />
          </Form.Item>
          {!isCompany && (
            <Form.Item
              label="Civil status*"
              name="marriage"
              className={cls.row}
              rules={[
                { required: true, message: 'Civil status is mandatory field' },
              ]}
            >
              <Select>
                {marriageOptions.map(opt => (
                  <Select.Option key={opt.id} value={opt.id}>{opt.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </div>
      </div>
      <div className={cls.aside}>
        <div className={cls.asideProps}>
          <Form.Item
            label="ZIP Code*"
            name={['location', 'postalCode']}
            className={cls.row}
            rules={[
              { required: isLocationMandatory, message: 'ZIP Code is mandatory field' },
            ]}
          >
            <Input className={cls.input} maxLength={5} />
          </Form.Item>
          <Form.Item
            label="Street*"
            name={['location', 'street']}
            className={cls.row}
            rules={[
              { required: isLocationMandatory, message: 'Street is mandatory field' },
            ]}
          >
            <Input className={cls.input} disabled={!isPostalCodeValid} />
          </Form.Item>
          <Form.Item
            label="Ext. Number*"
            name={['location', 'house']}
            className={cls.row}
            rules={[
              { required: isLocationMandatory, message: 'Ext. Number is mandatory field' },
            ]}
          >
            <Input className={cls.input} disabled={!isPostalCodeValid} />
          </Form.Item>
          <Form.Item
            label="Int. Number"
            name={['location', 'apartment']}
            className={cls.row}
          >
            <Input className={cls.input} disabled={!isPostalCodeValid} />
          </Form.Item>
          <Form.Item
            label="State*"
            name={['location', 'state']}
            className={cls.row}
            rules={[
              { required: isLocationMandatory, message: 'State is mandatory field' },
            ]}
          >
            <Input className={cls.input} disabled={!isPostalCodeValid} />
          </Form.Item>
          <Form.Item
            label="Colony*"
            name={['location', 'neighbourhood']}
            className={cls.row}
            rules={[
              { required: isLocationMandatory, message: 'Colony is mandatory field' },
            ]}
          >
            <Input className={cls.input} disabled={!isPostalCodeValid} />
          </Form.Item>
          <Form.Item
            label="Municipality*"
            name={['location', 'city']}
            className={cls.row}
            rules={[
              { required: isLocationMandatory, message: 'Municipality is mandatory field' },
            ]}
          >
            <Input className={cls.input} disabled={!isPostalCodeValid} />
          </Form.Item>
        </div>
        <div className={cls.asideActions}>
          <Button variant="contained" color="secondary" className={cls.remove} onClick={onRemove}>
            Delete
          </Button>
          <Button variant="contained" color="secondary" className={cls.submit} type="submit">
            <SaveIcon /> Save
          </Button>
        </div>
      </div>
    </div>
  );
};
