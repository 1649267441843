import { TextField, EmailField, ListProps } from 'react-admin';

import {
  PhoneField,
  TextInput,
  Grid,
  GridList,
  BooleanField,
  ManyField,
  Forbidden,
  DateField, NullableBooleanInput,
} from '@components';
import { rbac } from '@utils';
import { useRbacList } from '@hooks';
import cls from './AdminList.module.css';
import React from 'react';

export const AdminList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  const filters = [
    <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  ];

  if (rbac.isSuperadmin()) {
    filters.push(<NullableBooleanInput
      key="showRemoved" source="showRemoved" label="Show Removed" alwaysOn />,
    );
  }

  return (
    <GridList {...props}
      filters={filters}
      sort={{ field: 'id', order: 'DESC' }}
      className={cls.list}
    >
      <Grid
        rowClick="edit"
        className={cls.grid}
        defaultColumns={'username name email phone roles isEnabled isSuperadmin'.split(' ')}
      >
        <TextField source="username" />
        <TextField source="name" />
        <PhoneField source="phone" />
        <EmailField source="email" />
        <ManyField source="roles"
          reference="role"
          label="Roles"
          isColumn={false}
          sortable={false} />
        <BooleanField source="isSuperadmin" label="Superadmin" />
        <BooleanField source="isEnabled" label="Enabled" />
        <BooleanField source="isRemoved" label="Removed" />
        <DateField source="createdAt" label="Created" />
      </Grid>
    </GridList>
  );
};
