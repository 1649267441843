import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useController, useWatch } from 'react-hook-form';

import { SupportFiles } from '../../components';
import { Container, EditorInput, InitialCard, InitialRow, TextInput } from '@components';
import cls from './FaqForm.module.css';
import { DeleteButton, Link, SaveButton } from 'react-admin';
import { Button } from '@mui/material';

export const validate = (values: any) => {
  const reqList = ['question', 'answer'];
  const errors: any = {};

  reqList.forEach((key: string) => {
    if (!values[key]) {
      errors[key] = 'This field is required';
    }
  });
  // if (values['externalId'] > 999 || values['question'] <= 0) {
  //   errors['externalId'] = 'Min value 1 and max value 999';
  // }

  return errors;
};

export const FaqForm = () => {
  const id = useWatch({ name: 'id' });
  const { field: { onChange: setFileIds }, fieldState: { error } } = useController({ name: 'fileIds' });
  const { field: { value: documents, onChange: setDocuments } } = useController({ name: 'documents' });
  const { fieldState: { error: answerError } } = useController({ name: 'answer' });
  const editorToolbar = 'undo redo formatselect bold italic backcolor bullist numlist outdent indent table ' +
    'removeformat code help alignleft aligncenter alignright alignjustify ';

  useEffect(() => {
    if (id && documents?.length) {
      setFileIds([...documents.map(d => d.file.id)]);
    }
  }, [id, documents]);

  return (
    <Container max>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <InitialCard extraPadding left="Frequently Asked Questions">
            <Row gutter={{ xs: 24, lg: 24, xl: 40 }}>
              <Col xs={24} xl={12}>
                {/*<InitialRow title="ID" fontLg labelSize={labelSize} className={clsx(cls.fieldRow, cls.txtField)}>*/}
                {/*  <NumberInput source="externalId" autoFocus />*/}
                {/*</InitialRow>*/}
                <InitialRow title="Question" fontLg fullWidth className={clsx(cls.fieldRow, cls.descField)}>
                  <TextInput source="question" multiline inputProps={{ maxLength: 100 }}/>
                </InitialRow>
                <InitialRow title="Upload visual aid" fontLg fullWidth className={clsx(cls.fieldRow, cls.fileField)}>
                  <SupportFiles
                    files={documents}
                    setFiles={setFileIds}
                    setDocuments={setDocuments}
                    error={error}
                    className={cls.files}
                  />
                </InitialRow>
              </Col>
              <Col xs={24} xl={12}>
                <InitialRow title="Answer" fontLg fullWidth className={clsx(cls.fieldRow, cls.textareaField)}>
                  <EditorInput
                    source="answer"
                    label={false}
                    toolbar={editorToolbar}
                    height={250}
                    maxCount={1000}
                    className={clsx(cls.editor, answerError && cls.error)}
                  />
                </InitialRow>
              </Col>
            </Row>
          </InitialCard>
        </Col>
      </Row>

      <div className={cls.toolbar}>
        <Link to={`/support-faq/${id}?view`}>
          <Button variant="outlined" color="secondary" className={clsx(cls.btn, cls.viewBtn)}>View</Button>
        </Link>
        <DeleteButton className={clsx(cls.btn, cls.removeBtn)} />
        <SaveButton label="Save" type="button" className={clsx(cls.btn, cls.saveBtn)} />
      </div>
    </Container>
  );
};
