import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { RightCircleFilled } from '@ant-design/icons';

import { doctypeHttp } from '@network';
import { Credit, Doctype } from '@types';
import { InitialCard } from '@components';
import cls from './credit-contracts-documents.module.css';

interface CreditContractsDocumentsProps {
  credit: Credit;
}

export const CreditContractsDocuments = ({ credit }: CreditContractsDocumentsProps) => {
  const [doctypes, setDoctypes] = useState<Doctype[]>([]);
  const [contractsStatus, setContractsStatus] = useState({
    creditContract: false,
    amortizationTable: false,
    retentionLetter: false,
  });

  useEffect(() => {
    credit && (async () => {
      const res = await doctypeHttp.getAll();
      if (res) {
        setDoctypes(res);
      }
    })();
  }, [credit]);

  useEffect(() => {
    if (doctypes.length) {
      const creditContract = doctypes.find(d => d.isCreditContract);
      const amortizationTable = doctypes.find(d => d.isAmortizationTable);
      const retentionLetter = doctypes.find(d => d.isRetentionLetter);

      const isCreditContractUploaded = credit.documents
        .find(d => d.doctypeId === creditContract?.id);
      const isAmortizationTableUploaded = credit.documents
        .find(d => d.doctypeId === amortizationTable?.id);
      const isRetentionLetterUploaded = credit.documents
        .find(d => d.doctypeId === retentionLetter?.id);

      setContractsStatus({
        creditContract: !!isCreditContractUploaded?.files?.length,
        amortizationTable: !!isAmortizationTableUploaded?.files?.length,
        retentionLetter: !!isRetentionLetterUploaded?.files?.length,
      });
    }
  }, [doctypes]);

  const renderStatusIcon = (value: boolean) => {
    if (!value) return <CloseIcon style={{ fontSize: 14 }} />;

    return <CheckIcon style={{ fontSize: 14 }} />;
  };

  return (
    <InitialCard
      left="Contracts"
      right={<RightCircleFilled className={cls.headerIcon} />}
      contentClass={cls._}
      link={`/credit/${credit.id}/?contracts`}
      wrapperClass={cls.cardWrapper}
    >
      <div className={cls.title}>
        {credit.user?.isCompany && (
          <div className={cls.accredited}>{credit.accreditedName}</div>
        )}
        <div>{credit.code}</div>
      </div>
      <div className={cls.content}>
        <div className={cls.item}>
          <div className={cls.itemTitle}>Credit Contract</div>
          <div className={clsx(cls.itemIcon, contractsStatus.creditContract && cls.success)}>
            {renderStatusIcon(contractsStatus.creditContract)}
          </div>
        </div>
        <div className={cls.item}>
          <div className={cls.itemTitle}>Amortization table</div>
          <div className={clsx(cls.itemIcon, contractsStatus.amortizationTable && cls.success)}>
            {renderStatusIcon(contractsStatus.amortizationTable)}
          </div>
        </div>
        <div className={cls.item}>
          <div className={cls.itemTitle}>Retention Letter</div>
          <div className={clsx(cls.itemIcon, contractsStatus.retentionLetter && cls.success)}>
            {renderStatusIcon(contractsStatus.retentionLetter)}
          </div>
        </div>
      </div>
    </InitialCard>
  );
};
