import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { clsx } from 'clsx';

import { creditHttp, CreditReportTypes } from '@network';
import { useRequest } from '@hooks';
import { Credit, Doctype } from '@types';
import { DoctypeDocument } from '../DoctypeDocument';
import { CreditReportFiles, CreditReportsType } from '@pages/credit/CreditReport';
import cls from './credit-widget.module.css';

interface Props {
  credit: Credit;
  setCredit: Dispatch<SetStateAction<Credit>>;
  doctypes: Doctype[];
  isSuperadmin: boolean;
  editable: boolean;
}

export const CreditWidget = ({ credit, setCredit, doctypes, isSuperadmin, editable }: Props) => {
  const [tab, setTab] = React.useState(0);
  const [generalFilled, setGeneralFilled] = React.useState(0);
  const [compFilled, setCompFilled] = React.useState(0);

  const [reportFiles, setReportFiles] = useState<CreditReportsType>({
    [CreditReportTypes.EKATENA_REPORT]: [],
    [CreditReportTypes.BUREAU_REPORT]: [],
    [CreditReportTypes.SINECTA_REPORT]: [],
  });

  const changeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);

    credit && (async () => {
      const nextCredit = await creditHttp.getCredit(credit.id);
      setCredit(nextCredit);
    })();
  };

  const general: number[] = [];
  const comp: number[] = [];
  for (let i = 0; i < doctypes.length; i++) {
    if (doctypes[i].category === 'Aplicación') {
      general.push(doctypes[i].id);
    } else if (doctypes[i].category === 'Complementos para firma') {
      comp.push(doctypes[i].id);
    }
  }

  const setCounters = () => {
    const generalDcs = credit.documents.filter(d =>
      general.includes(d.doctypeId) && (d.files.length || d.number || d.isOptionalChecked),
    );
    const generalDcsUniqueIds = new Set(generalDcs.map(i => i.doctypeId));
    const compDcs = credit.documents.filter(d =>
      comp.includes(d.doctypeId) && (d.files.length || d.number || d.isOptionalChecked),
    );
    setGeneralFilled(generalDcsUniqueIds.size);
    setCompFilled(compDcs.length);
  };

  const filteredDoctypes = doctypes.filter(doctype => {
    if (tab === 0 && doctype.category === 'Aplicación') {
      return true;
    }
    if (tab === 1 && doctype.category === 'Complementos para firma') {
      return true;
    }
    return false;
  });

  const { submit: downloadDocuments, loading: downloadLoading } = useRequest(async () => {
    await creditHttp.downloadCreditDocuments(credit.id);
  });

  useEffect(() => {
    if (credit && general.length && comp.length) {
      setCounters();
    }

    (async () => {
      try {
        const loadedFiles = await creditHttp.getReportFiles(credit.id);

        if (loadedFiles) {
          setReportFiles(loadedFiles);
        }

      } catch (e) {}
    })();
  }, [credit.id, general.length, comp.length]);

  const reportsValidationList = useMemo(() => {
    return [
      !!reportFiles.EKATENA_REPORT.length,
      !!reportFiles.BUREAU_REPORT.length,
      !!reportFiles.SINECTA_REPORT.length,
    ];
  }, [reportFiles]);

  const reportsUploadedCount = reportsValidationList.filter(Boolean).length;

  return (
    <div className={cls.container}>
      <div className={cls.header}>
        <Tabs value={tab} onChange={changeTab} className={cls.tabs}>
          <Tab label={(
            <div className={cls.tabLabel}>
              Aplicación
              <span className={cls.tabCounter}>{generalFilled}/{general.length}</span>
            </div>
          )} {...tabProps(0, tab)} />
          <Tab label={(
            <div className={cls.tabLabel}>
              Complementos para firma
              <span className={cls.tabCounter}>{compFilled}/{comp.length}</span>
            </div>
          )} {...tabProps(1, tab)} />
          <Tab label={(
            <div className={cls.tabLabel}>
                Reports
              <span className={cls.tabCounter}>
                {reportsUploadedCount}/{reportsValidationList.length}
              </span>
            </div>
          )} {...tabProps(2, tab)} />
        </Tabs>
        <Button
          variant="outlined"
          color="primary"
          onClick={downloadDocuments}
          disabled={downloadLoading}
          className={cls.downloadBtn}>
          {downloadLoading ? <CircularProgress color="inherit" size={20} /> : <DownloadIcon />}
          &nbsp;Download all documents
        </Button>
      </div>

      <div className={cls.documents}>
        {tab === 2 && (
          <div className={cls.reports}>
            <CreditReportFiles
              creditId={credit.id}
              files={reportFiles}
              setReportFiles={setReportFiles}
              hideStatus
            />
          </div>
        )}

        {tab !== 2 && filteredDoctypes.map(doctype => (
          <div className={cls.document} key={doctype.id}>
            <DoctypeDocument
              doctype={doctype}
              credit={credit}
              setCredit={setCredit}
              isSuperadmin={isSuperadmin}
              editable={editable}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

function tabProps(index: number, selectedIndex: number) {
  return {
    id: `credit-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: clsx(cls.tab, index === selectedIndex && cls.tabSelected),
  };
}

export const DownloadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
    <g clipPath="url(#clip0_3168_6193)">
      <path d="M13.9198 3.25872H17.3957C17.3521 3.18539 17.2941 3.11914 17.2251 3.06289L13.6635 0.192057C13.5671 0.113724 13.4507 0.0566406 13.3237 0.0253906L13.3851 2.79872C13.3903 3.05289 13.6275 3.25706 13.9198 3.25872Z"
        fill="#042E6B" />
      <path d="M13.9198 3.9475C13.1972 3.94375 12.6093 3.44083 12.5925 2.81292L12.5302 0H1.78394C0.797334 0.0025 -0.000957471 0.699167 8.61942e-07 1.55708V18.4429C-0.000957471 19.3008 0.797334 19.9975 1.78394 20H15.7056C16.6923 19.9975 17.4905 19.3008 17.4896 18.4429V3.9475H13.9198Z"
        fill="#042E6B" />
      <path d="M11.9434 12.1964L9.84413 14.0164C9.60743 14.2214 9.29357 14.3443 8.96007 14.3627C8.93084 14.366 8.90161 14.3677 8.87238 14.3681C8.84699 14.3693 8.82159 14.3693 8.7962 14.3681C8.46222 14.3552 8.14549 14.2368 7.90447 14.0356L5.77026 12.3764C5.47078 12.1448 5.44395 11.746 5.71036 11.4856C5.97678 11.2252 6.43534 11.2018 6.73482 11.4335L8.15459 12.5314V7.29393C8.1407 6.9456 8.45407 6.6531 8.85513 6.64101C9.25572 6.62893 9.59209 6.90143 9.60599 7.25018C9.60647 7.26476 9.60647 7.27935 9.60599 7.29393V12.4443L10.9213 11.3006C11.2059 11.0552 11.6654 11.0564 11.9477 11.3039C12.2299 11.5514 12.228 11.951 11.9438 12.1964H11.9434Z"
        fill="#E1E5ED" />
    </g>
    <defs>
      <clipPath id="clip0_3168_6193">
        <rect width="17.4896" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
