import React from 'react';
import { ListProps, TextField } from 'react-admin';

import {
  feedbackStatusFieldOptions,
  feedbackStatusOptions,
} from './feedback-status-options';
import {
  ReferenceInput,
  SelectInput,
  TextInput,
  Grid,
  GridList,
  Forbidden,
  DateRangeInput,
  DateField,
} from '@components';
import { useExporter, useRbacList } from '@hooks';
import { StatusField } from '@components/StatusField';
import cls from './FeedbackList.module.css';

export const FeedbackList = (props: ListProps) => {
  const rbacList = useRbacList();
  const exporter = useExporter({
    filename: 'Contact Us list',
    props: {
      'id': {
        label: 'ID',
      },
      'fullName': {
        label: 'Full name',
      },
      'phone': {
        label: 'Phone',
        transform: 'phone',
      },
      'message': {
        label: 'Message',
      },
      'status': {
        label: 'Status',
        transform: 'uppercase',
      },
      'createdAt': {
        label: 'Created at',
        transform: 'date',
      },
      'closedAt': {
        label: 'Closed At',
        transform: 'date',
      },
      'categoryEn': {
        label: 'Category',
      },
      'userName': {
        label: 'User',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      exporter={exporter}
      className={cls.list}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'id fullName status phone userName file createdAt'.split(' ')}
        className={cls.grid}
      >
        <TextField source="id" />
        <TextField source="fullName" label="Full name" />
        <TextField source="phone" />
        <TextField source="message" />
        <StatusField options={feedbackStatusFieldOptions} source="status" />
        <DateField source="createdAt" />
        <DateField source="closedAt" />
        <TextField source="categoryEn" sortBy="category" label="Category" />
        <TextField source="userName" label="User" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  <SelectInput source="status" key="status" choices={feedbackStatusOptions} alwaysOn />,
  <DateRangeInput key="createdAt" source="createdAt" label="Created At"
    defaultValue={false} alwaysOn />,
  <ReferenceInput
    source="userId"
    key="userId"
    filter={{ forFilter: 'true' }}
    reference="user"
    label="User"
    allowEmpty
    perPage={1000}
    sort={{ field: 'fullName', order: 'ASC' }}
  >
    <SelectInput optionText="fullName" />
  </ReferenceInput>,
];
