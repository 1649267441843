import { ListProps, TextField, ReferenceField } from 'react-admin';

import {
  BooleanField,
  TextInput,
  ImageField,
  OptionsField,
  ExternalUrlField,
  ReferenceInput,
  SelectInput,
  Grid,
  GridList,
  ManyFilter,
  Forbidden,
  NullableBooleanInput,
} from '@components';
import { PricesField } from './PricesField';
import { CropsField } from './CropsField';
import { useRbacList } from '@hooks';

export const ProductList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'name price image brandId divisionId typeId crops isEnabled isExternal'.split(' ')}
      >
        <TextField source="name" />
        <PricesField />
        <ImageField source="image" />
        <ReferenceField reference="product-brand" source="brandId" label="Brand">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="division" source="divisionId" label="Category">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="product-type" source="typeId" label="Subcategory">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="pack" />
        <TextField source="application" />
        <TextField source="form" />
        <TextField source="dosePerHa" />
        <TextField source="composition" />
        <TextField source="description" />
        <ExternalUrlField source="pdfLink" />
        <CropsField />
        <TextField source="position" />
        <TextField source="discount" />
        <BooleanField source="isEnabled" label="ON" />
        <BooleanField source="isExternal" label="External" />
        <TextField source="externalShop" label="External Shop" />
        <BooleanField source="isNew" label="New" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput key="q" source="q" variant="outlined" label="Search by name" alwaysOn />,
  <TextInput key="s" source="s" label="Search by content" />,
  <OptionsField key="distributor" source="distributor" label="Brand" alwaysOn />,
  <ManyFilter key="crops" source="crops" reference="crop" alwaysOn />,
  <NullableBooleanInput key="isExternal" source="isExternal" label="External" />,
  <ReferenceInput
    key="division"
    reference="division"
    source="divisionId"
    perPage={1000}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <OptionsField key="category" source="category" />,
  <OptionsField key="subcategory" source="subcategory" />,
  <OptionsField key="pack" source="pack" />,
  <OptionsField key="application" source="application" />,
  <OptionsField key="form" source="form" />,
  <NullableBooleanInput key="isEnabled" source="isEnabled" />,
  <TextInput key="dosePerHa" source="dosePerHa" />,
  <TextInput key="externalShop" source="externalShop" />,
];

