import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { useState } from 'react';
import { SaveFilled } from '@ant-design/icons';
import { Create, CreateProps, SaveButton, SimpleForm, TopToolbar } from 'react-admin';

import { Container, TextInput, EditorInput, Forbidden, PageHeader, FormRow } from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';
import cls from './FaqCreate.module.css';

export const FaqCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const [empty, setEmpty] = useState(false);
  const rbacCreate = useRbacCreate();
  const editorToolbar = 'undo redo formatselect '
    + 'bold italic backcolor bullist numlist outdent indent table '
    + 'removeformat code help '
    + 'alignleft aligncenter alignright alignjustify ';

  const validate = (values: any) => {
    const errors: any = {};
    setEmpty(false);

    ['question', 'answer'].forEach((key: string) => {
      if (!values[key]) {
        errors[key] = 'This field is required';
      }
      if (key === 'answer' && !values[key]) {
        setEmpty(true);
      }
    });

    return errors;
  };

  if (!rbacCreate) {
    return <Forbidden/>;
  }

  const Actions = () => {
    return (
      <TopToolbar className={cls.actions}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon}/>}
          label="Save"
          type="button"
          className={clsx(cls.action, cls.actionSave)}
          variant="outlined"
          alwaysEnable
        />
      </TopToolbar>
    );
  };

  return (
    <Create {...props} {...createProps} className={clsx(cls.page, 'faq-page-wrapper')}>
      <>
        <PageHeader
          title="Individual FAQ"
          linkText="Back to all FAQ"
          linkUrl="/faq"
        />

        <SimpleForm
          toolbar={<Actions/>}
          className={cls.form}
          validate={validate}
        >
          <Container max>
            <Row gutter={24} className={cls.wrapper}>
              <Col xs={24} className={cls.wrapperCol}>
                <div className={clsx(cls.block, cls.blockContent)}>
                  <Row gutter={24} className={cls.inner}>
                    <Col xs={24} xl={12} className={clsx(cls.innerCol, cls.innerColFirst)}>
                      <div className={cls.blockHeader}>Frequently Asked Questions</div>
                      <div className={cls.blockSubHeader}>Question*</div>
                      <FormRow
                        fullWidth
                        rowClassName={cls.formRow}
                        colTitleClassName={clsx(cls.formLabelCol, cls.formTitleCol)}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formValueColTextarea)}
                      >
                        <TextInput name="question" source="question" required autoFocus multiline label={false}/>
                      </FormRow>
                    </Col>
                    <Col xs={24} xl={12} className={clsx(cls.innerCol, cls.innerColLast)}>
                      <div className={cls.blockSubHeader}>Answer*</div>
                      <FormRow
                        fullWidth
                        rowClassName={clsx(cls.formRow, cls.messageRow)}
                        colTitleClassName={clsx(cls.formLabelCol, cls.formTitleCol)}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formValueColTextarea)}
                      >
                        <EditorInput
                          source="answer"
                          label={false}
                          toolbar={editorToolbar}
                          height={250}
                          className={clsx(cls.editor, empty ? cls.empty : '')}
                          required
                        />
                        {empty
                          ? <span className={cls.errorEditor}>This field is required</span>
                          : null}
                      </FormRow>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </SimpleForm>
      </>
    </Create>
  );
};
