import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { FieldTitle, useResourceContext } from 'ra-core';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

export const FilterButtonMenuItem = (props: FilterButtonMenuItemProps) => {
  const cls = useStyles();
  const { filter, onShow, autoFocus, isChecked } = props;
  const resource = useResourceContext(props);

  const handleShow = () => onShow({
    source: filter.props.source,
    defaultValue: filter.props.defaultValue,
  });

  return (
    <MenuItem
      className={clsx('new-filter-item', cls.menuItem)}
      data-key={filter.props.source}
      data-default-value={filter.props.defaultValue}
      key={filter.props.source}
      onClick={handleShow}
      autoFocus={autoFocus}
      disabled={filter.props.disabled}
    >
      <FormControlLabel
        value={isChecked}
        control={<Checkbox checked={isChecked} />}
        label={(
          <FieldTitle
            label={filter.props.label}
            source={filter.props.source}
            resource={resource}
          />
        )}
      />
    </MenuItem>
  );
};

const useStyles = makeStyles({
  menuItem: {
    padding: '0 15px',
  },
});

export interface FilterButtonMenuItemProps {
  filter: JSX.Element;
  onShow: (params: {source: string; defaultValue: any}) => void;
  resource: string;
  autoFocus?: boolean;
  isChecked?: boolean;
}
