import { EditProps, Edit, SimpleForm } from 'react-admin';
import {
  Row,
  Col,
  Container,
  TextInput,
  ReferenceInput,
  SelectInput,
  Forbidden,
} from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Crop: {record.nameMx}</span>) : null;

export const CropEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <SimpleForm toolbar={rbacEdit ? undefined : false}>
        <Container max>
          <Row>
            <Col><TextInput source="nameMx" required /></Col>
            <Col><TextInput source="nameEn" /></Col>
            <Col>
              <ReferenceInput
                reference="crop-category"
                source="categoryId"
                perPage={1000}
                sort={{ field: 'nameMx', order: 'ASC' }}
                isRequired
              >
                <SelectInput optionText="nameMx" required />
              </ReferenceInput>
            </Col>
            <Col><TextInput source="id" disabled /></Col>
          </Row>
        </Container>
      </SimpleForm>
    </Edit>
  );
};
