import { clsx } from 'clsx';
import type { MenuProps } from 'antd';
import { Dropdown, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';

import cls from './DropdownInfoWidget.module.css';

export type DropdownInfoType = 'LOW' | 'MED' | 'HIGH';
export type DropdownInfoColor = 'orange' | 'blue' | 'green';
export type TooltipPosition = 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight'
  | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom';

export enum DropdownInfoColors {
  LOW = 'orange',
  MED = 'blue',
  HIGH = 'green',
}

interface OptionProps {
  label: DropdownInfoType;
  value: number;
}

export interface DropdownInfoDataProps {
  options: OptionProps[];
  selected: DropdownInfoType;
  value: number;
  info: string[][];
  extra?: number;
}

interface DropdownInfoProps {
  title: string;
  data: DropdownInfoDataProps;
  calcData?: DropdownInfoDataProps;
  onChange: (data: DropdownInfoDataProps) => void;
  infoPosition?: TooltipPosition;
}

interface OptionItemProps {
  id: DropdownInfoType;
  bg: DropdownInfoColor;
  onChange: (key: DropdownInfoType) => void;
}

const OptionItem = ({ id, bg, onChange }: OptionItemProps): JSX.Element => (
  <div className={clsx(cls.selected, cls[bg])} onClick={() => onChange(id)}>{id}</div>
);

const Info = ({ value }: { value: string[][] }) => value.length ? (
  <div className={cls.infoBlock}>
    {value.map((r, idx) => (
      <div className={cls.infoRow} key={idx}>
        {r.map((c, key) => (
          <div className={cls.infoCol} key={key}>{c}</div>
        ))}
      </div>
    ))}
  </div>
) : <></>;

export const DropdownInfoWidget = ({ title, data, calcData, onChange, infoPosition = 'top' }: DropdownInfoProps) => {
  const [selected, setSelected] = useState<DropdownInfoType | null>(data.selected);

  const items: MenuProps['items'] = data.options.map(o => ({
    label: <OptionItem id={o.label} bg={DropdownInfoColors[o.label]} onChange={setSelected} />,
    key: o.label,
  }));

  const getValue = (): number => {
    const currentOption = data.options.find(o => o.label === selected);
    return !currentOption ? 0 : currentOption.value;
  };

  useEffect(() => {
    if (selected) {
      const newData = { ...data, selected, value: getValue() };
      onChange(newData);
    }
  }, [selected]);

  return (
    <div className={cls.block}>
      <div className={cls.row}>
        <div className={clsx(cls.col, cls.title)}>{title}</div>
        {calcData && calcData.extra !== undefined ? (
          <div className={clsx(cls.col, cls.extra, cls.colored)}>{calcData.extra || 0}%</div>
        ) : null}
        <div className={clsx(cls.col, cls.select, cls.colored)}>
          <div className={cls.dropdownBlock}>
            <Dropdown
              className={cls.dropdown}
              menu={{ items, selectable: true }}
              overlayClassName={cls.dropdownOverlay}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={clsx(
                  cls.selectButton,
                  selected ? cls[DropdownInfoColors[selected]] : cls.empty,
                )}
                onClick={e => e.preventDefault()}
              >
                <div
                  className={clsx(cls.selectedText, selected ? cls[DropdownInfoColors[selected]] : '')}>{selected || ''}</div>
                <div className={cls.selectedIcon}><DownOutlined className={cls.arrowIcon}/></div>
              </a>
            </Dropdown>
          </div>
          <div className={cls.info}>
            <Tooltip
              placement={infoPosition}
              title={<Info value={data.info}/>}
              className={cls.tooltip}
              overlayClassName={clsx(cls.tooltipOverlay, !data.info.length ? cls.empty : '')}
            >
              <svg className={cls.infoIcon} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill="#042E6B" d="M10 0C4.48421 0 0 4.48421 0 10C0 15.5158 4.48421 20 10 20C15.5158 20 20 15.5158 20 10C20 4.48421 15.5158 0 10 0ZM11.8105 14.0421C11.6 14.5684 11.1158 15.4737 10.1474 16C9.55789 16.3158 9.13684 16.2947 9.03158 16.2947C8.90526 16.2947 8.06316 16.2526 7.70526 15.5789C7.53684 15.2211 7.57895 14.8632 7.6 14.7158L9.17895 8.90526H8.37895C8.27368 8.90526 8.18947 8.8421 8.16842 8.73684L8.12632 8.4C8.10526 8.29474 8.18947 8.18947 8.29474 8.16842L11.5579 7.62105C11.7053 7.6 11.8316 7.72632 11.7895 7.87368L10.0632 14.2947C10 14.5684 10.3368 14.8 10.5684 14.5895C10.8842 14.2947 11.0947 13.9789 11.2211 13.7263C11.2842 13.6211 11.4105 13.5789 11.5158 13.6421C11.6 13.6842 11.6632 13.7474 11.7474 13.7895C11.8316 13.8316 11.8526 13.9368 11.8105 14.0421ZM11.0105 6.52632C10.2316 6.52632 9.6 5.89474 9.6 5.11579C9.6 4.33684 10.2316 3.70526 11.0105 3.70526C11.7895 3.70526 12.4211 4.33684 12.4211 5.11579C12.4211 5.89474 11.7895 6.52632 11.0105 6.52632Z"/>
              </svg>
            </Tooltip>
          </div>
        </div>
        <div className={clsx(cls.col, cls.percent)}>{getValue()}%</div>
      </div>
    </div>
  );
};
