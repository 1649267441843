import { clsx } from 'clsx';
import { Col, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { SaveFilled } from '@ant-design/icons';
import {
  EditProps,
  Edit,
  SimpleForm,
  TopToolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin';

import { useEditProps, useRbacEdit, useRbacView, useRecord } from '@hooks';
import {
  Container,
  TextInput,
  Forbidden,
  SelectInput,
  PageHeader,
  FormRow,
  SwitchInput, InputMultiple,
} from '@components';
import { dealerTiers, SurveyQuestion } from '@types';
import { AnswersInput } from '../AnswersInput';
import { requirementsOptions } from '../requirements-options';
import { PlannerInput } from '@pages/question/PlannerInput';
import cls from './QuestionEdit.module.css';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Question: {record.nameMx}</span>) : null;

export const QuestionEdit = (props: EditProps) => {
  const page = useRecord<SurveyQuestion>(props);
  const maxNameLength = 500;
  const question = useRecord<SurveyQuestion>(props);
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();
  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isTable, setIsTable] = useState(false);
  const [position, setPosition] = useState<number>(0);
  const [withCustomAnswer, setWithCustomAnswer] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [isMoney, setIsMoney] = useState(false);
  const [isPlanner, setIsPlanner] = useState(false);
  const mainColGrid = {
    xs: { title: 24, children: 24 },
    sm: { title: 12, children: 12 },
    md: { title: 10, children: 14 },
    lg: { title: 8, children: 16 },
    xl: { title: 10, children: 14 },
    xxl: { title: 8, children: 16 },
  };

  useEffect(() => {
    if (question) {
      setIsTable(question.isTable || false);
      setWithCustomAnswer(question.withCustomAnswer || false);
      setIsNumber(question.isNumber || false);
      setIsMoney(question.isMoney || false);
      setIsPlanner(question.isPlanner || false);
      setName(question.nameMx || '');
    }
  }, [question]);

  useEffect(() => {
    setDisabled(name.length > 500 || name.length === 0);
  }, [name]);

  useEffect(() => {
    setDisabled(!validatePosition().status);
  }, [position]);

  useEffect(() => {
    page && setPosition(page.position);
  }, [page]);

  if (!rbacView) {
    return <Forbidden />;
  }

  const validatePosition = () => {
    if (position > 100) {
      return { status: false, msg: '100 is maximum position' };
    }
    if (!Number.isInteger(position)) {
      return { status: false, msg: 'The number must be an integer' };
    }
    if (!position) {
      return { status: false, msg: 'The position field is required' };
    }

    return { status: true, msg: '' };
  };

  const Actions = ({ disabled }: {disabled: boolean | undefined}) => {
    return (
      <TopToolbar className={cls.actions}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon} />}
          label="Save"
          type="button"
          className={clsx(cls.action, cls.actionSave)}
          variant="outlined"
          disabled={disabled}
        />
        <DeleteButton className={clsx(cls.action, cls.actionDelete)} />
      </TopToolbar>
    );
  };

  return question ? (
    <Edit {...props} title={<Title />} {...editProps}
      className={clsx(cls.page, 'details-page-wrapper')}>
      <>
        <PageHeader
          title="Edit a question"
          linkText="Back to all questions"
          linkUrl="/question"
        />

        <SimpleForm
          toolbar={rbacEdit ? <Actions disabled={disabled} /> : false}
          className={cls.form}
        >
          <Container max>
            <Row gutter={24} className={cls.wrapper}>
              <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColFirst)}>
                <div className={clsx(cls.block, cls.blockMain)}>
                  <div className={cls.blockHeader}>Question</div>
                  <FormRow
                    title="Name mx*:"
                    fullWidth
                    rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                    colTitleClassName={cls.formLabelCol}
                    colChildrenClassName={clsx(
                      cls.formValueCol,
                      cls.formMainValue,
                      cls.nameInput,
                      name.length > 500 || !name.length ? cls.error : '',
                    )}
                  >
                    <span className={cls.counter}>{name.length}/{maxNameLength}</span>
                    <TextInput
                      name="nameMx"
                      source="nameMx"
                      required
                      autoFocus
                      multiline
                      label={false}
                      onInput={(e: any) => setName(e.target.value)}
                      className={cls.nameWrapper}
                      placeholder="Write your question"
                    />
                  </FormRow>
                  <FormRow
                    title="Applies to type of User"
                    width={mainColGrid}
                    rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                    colTitleClassName={cls.formLabelCol}
                    colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                  >
                    <SelectInput
                      source="requirements"
                      label={false}
                      choices={requirementsOptions}
                      required
                      defaultValue="all"
                    />
                  </FormRow>
                  <FormRow
                    title="Max input length"
                    width={mainColGrid}
                    rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                    colTitleClassName={cls.formLabelCol}
                    colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                  >
                    <TextInput
                      label={false}
                      disabled={!withCustomAnswer}
                      name="maxInputLength"
                      source="maxInputLength"
                    />
                  </FormRow>
                  <FormRow
                    title="Excluded for Tier"
                    width={mainColGrid}
                    rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                    colTitleClassName={cls.formLabelCol}
                    colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                  >
                    <InputMultiple
                      multiple
                      options={dealerTiers as string[]}
                      source="excludedTiers"
                      name="excludedTiers"
                    />
                  </FormRow>
                </div>
              </Col>

              <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColLast)}>
                <div className={clsx(cls.block, cls.blockMain)}>
                  <div className={cls.blockHeader}>Field type</div>
                  <div className={cls.switchers}>
                    <div className={cls.switcher}>
                      <SwitchInput
                        name="isEnabled"
                        source="isEnabled"
                        label="Is enabled"
                        className={cls.switcherField} />
                      <SwitchInput
                        name="isOptional"
                        source="isOptional"
                        label="Is optional"
                        className={cls.switcherField} />
                      <SwitchInput
                        name="isTable"
                        source="isTable"
                        label="Is table"
                        onChange={setIsTable}
                        disabled={withCustomAnswer || isMultiple || isNumber}
                        value={isTable}
                        className={cls.switcherField} />
                      <SwitchInput
                        name="isMultiple"
                        source="isMultiple"
                        label="Is multiple"
                        onChange={setIsMultiple}
                        disabled={isTable || withCustomAnswer}
                        className={cls.switcherField} />
                      <SwitchInput
                        name="isNumber"
                        source="isNumber"
                        label="Is number"
                        onChange={setIsNumber}
                        disabled={!withCustomAnswer}
                        className={cls.switcherField} />
                      <SwitchInput
                        name="isMoney"
                        source="isMoney"
                        label="Is money"
                        onChange={setIsMoney}
                        disabled={!withCustomAnswer}
                        className={cls.switcherField} />
                      <SwitchInput
                        name="withCustomAnswer"
                        source="withCustomAnswer"
                        label="With custom answer"
                        onChange={setWithCustomAnswer}
                        disabled={isTable}
                        className={cls.switcherField} />
                      <SwitchInput
                        name="isPlanner"
                        source="isPlanner"
                        label="Is Planner"
                        value={isPlanner}
                        onChange={setIsPlanner}
                        // disabled={!withCustomAnswer || isTable}
                        className={cls.switcherField} />
                      <SwitchInput
                        name="isVisible"
                        source="isVisible"
                        label="Is visible"
                        className={cls.switcherField} />
                      <SwitchInput
                        name="isSendToCredit"
                        source="isSendToCredit"
                        label="Send to Analysis"
                        className={cls.switcherField} />
                      <SwitchInput
                        name="isEsgData"
                        source="isEsgData"
                        label="Is Esg Data"
                        disabled
                        className={cls.switcherField} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row gutter={24} className={cls.wrapper}>
              <Col xs={24} className={clsx(cls.wrapperCol, cls.wrapperFull)}>
                <div className={clsx(cls.block, cls.blockMain)}>
                  <div className={cls.blockHeader}>
                    Answers
                    {isPlanner && (
                      <Tooltip title="Max. 6 options">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
                            fill="#042E6B"/>
                          <path
                            d="M8.66 17.0003V7.34027H11.3V17.0003H8.66ZM9.98 5.78027C9.55333 5.78027 9.2 5.64027 8.92 5.36027C8.64 5.06694 8.5 4.70694 8.5 4.28027C8.5 3.86694 8.64 3.51361 8.92 3.22027C9.2 2.92694 9.55333 2.78027 9.98 2.78027C10.42 2.78027 10.7733 2.92694 11.04 3.22027C11.32 3.51361 11.46 3.86694 11.46 4.28027C11.46 4.70694 11.32 5.06694 11.04 5.36027C10.7733 5.64027 10.42 5.78027 9.98 5.78027Z"
                            fill="white"/>
                        </svg>
                      </Tooltip>
                    )}
                  </div>
                  {withCustomAnswer && (
                    <div>User will type in his custom answer</div>
                  )}
                  {!withCustomAnswer && !isPlanner && (
                    <AnswersInput source="answers"/>
                  )}
                  {!withCustomAnswer && isPlanner && (
                    <PlannerInput source="answers" setDisabled={setDisabled}/>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </SimpleForm>
      </>
    </Edit>
  ) : null;
};
