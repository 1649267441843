import { useRedirect } from 'react-admin';
import { Button } from '@mui/material';
import moment from 'moment-timezone';

import { Credit } from '@types';
import { InitialCard } from '@components';
import {
  CreditInvoicesStatusField,
} from '@pages/credit/CreditEdit/CreditInvoices/credit-invoices-status-field';
import cls from './credit-invoices.module.css';
import { SentBy } from '@pages/credit/SentBy';

const MAX_VISIBLE_INVOICES = 3;

export const CreditInvoicesCard = ({ credit }: {credit: Credit}) => {
  const redirect = useRedirect();

  const invoices = credit?.invoices?.slice(0, MAX_VISIBLE_INVOICES) || [];
  const invoicesLength = credit?.invoices?.length || 0;
  const restInvoicesCount = invoicesLength > 3 ? invoicesLength - MAX_VISIBLE_INVOICES : 0;

  return (
    <InitialCard left="Invoice and Credit Manager" autoHeight extraPadding>
      <div className={cls.cardList}>
        {invoices.map(invoice => {
          const date = moment(invoice.createdAt).format('DD MMM YYYY, HH:mm');
          const name = `Invoice#${invoice.id}_${credit.code}`;
          const type = invoice.managerId ? 'manager' : invoice.adminId ? 'admin' : 'user';

          return (
            <div key={invoice.id} className={cls.cardItem}>
              <div className={cls.cardItemHead}>
                <div className={cls.cardItemTitle}>{name}</div>
                <div className={cls.cardItemDate}>{date}</div>
              </div>
              <div className={cls.cardItemBottom}>
                <div className={cls.cardItemStatus}>
                  <CreditInvoicesStatusField invoiceStatus={invoice.status} />
                </div>
                <SentBy type={type} />
                <div className={cls.cardItemNumber}>#{invoice.id}</div>
              </div>
            </div>
          );
        })}
      </div>

      <div className={cls.cartActions}>
        <Button
          variant="outlined"
          className={cls.cartButton}
          onClick={() => redirect(`/credit/${credit.id}?invoices`)}
        >
          Show more {restInvoicesCount !== 0 && `(${restInvoicesCount})`}
        </Button>
      </div>
    </InitialCard>
  );
};
