import React from 'react';
import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';

import {
  Container,
  InitialCard,
  InitialRow,
  SelectInput,
  TextInput,
  AntdDateInput,
  ManyTagsInput,
  SelectAutocompleteInput,
  NumberSuffixInput,
  MoneyMaskInput,
  InitialTitle,
} from '@components';
import { useCreditOptions } from './use-credit-options';
import { useCalculation } from './use-calculation';

export const validate = (values: any) => {
  const errors: any = {};
  ['codeType'].forEach((key: string) => {
    if (!values[key]) {
      errors[key] = 'This field is required';
    }
  });

  return errors;
};

const opts = (options: any) => (options || []).map((opt: any) => ({
  id: typeof opt === 'string' ? opt : opt.id,
  name: typeof opt === 'string' ? opt : (opt.name || opt.title),
}));

export const FinanceCreditForm = () => {
  const cls = useStyles();
  const options = useCreditOptions();
  useCalculation();

  const labelSize = 7;

  return (
    <Container max>
      <InitialCard left="Complete information" extraPadding>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={11}>
            <InitialRow title="Code*" fontLg labelSize={labelSize}>
              <Row gutter={[20, 0]}>
                <Col xs={24} sm={12}>
                  <SelectInput source="codeType" choices={opts(options?.codeTypes)} />
                </Col>
                <Col xs={24} sm={12}>
                  <TextInput source="codeNumber" placeholder="Number"
                    inputProps={{ maxLength: 10 }} />
                </Col>
              </Row>
            </InitialRow>
            <InitialRow title="Accredited" fontLg labelSize={labelSize}>
              <TextInput source="accredited" inputProps={{ maxLength: 80 }} />
            </InitialRow>
            <InitialRow title="NBFI" fontLg labelSize={labelSize}>
              <SelectInput source="bankId" choices={opts(options?.banks)} />
            </InitialRow>
            <InitialRow title="Status" fontLg labelSize={labelSize}>
              <SelectInput source="status" choices={opts(options?.statuses)} />
            </InitialRow>
            <InitialRow title="Signature Date" fontLg labelSize={labelSize}>
              <AntdDateInput source="signatureDate" />
            </InitialRow>
            <InitialRow title="Payment Date" fontLg labelSize={labelSize}>
              <AntdDateInput source="paymentDate" />
            </InitialRow>
          </Col>
          <Col xs={24} sm={12} offset={1}>
            <InitialRow title="Region" fontLg labelSize={labelSize}>
              <SelectInput source="region" choices={opts(options?.regions)} />
            </InitialRow>
            <InitialRow title="State" fontLg labelSize={labelSize}>
              <SelectAutocompleteInput source="stateId" reference="/state" />
            </InitialRow>
            <InitialRow title="Financed Ha" fontLg labelSize={labelSize}>
              <NumberSuffixInput source="financedHa" suffix="Ha" fractional />
            </InitialRow>
            <InitialRow title="Crops" fontLg labelSize={labelSize}>
              <ManyTagsInput source="cropIds" reference="crop" />
            </InitialRow>
            <InitialRow title="Insurance C." fontLg labelSize={labelSize}>
              <TextInput source="insuranceCompany" inputProps={{ maxLength: 80 }} />
            </InitialRow>
            <InitialRow title="Delay days" fontLg labelSize={labelSize}>
              <NumberSuffixInput source="delayDays" suffix="days" maxLength={4} />
            </InitialRow>
          </Col>
        </Row>
        <div className={cls.amounts}>
          <Row gutter={[40, 20]}>
            <Col xs={24} sm={6} md={4}>
              <MoneyMaskInput source="creditAmount" upperLabel="Credit" maxLength={11} />
            </Col>
            <Col xs={24} sm={6} md={4}>
              <MoneyMaskInput source="verqorPayAmount" upperLabel="Verqor Paga" maxLength={11} />
            </Col>
            <Col xs={24} sm={6} md={4}>
              <MoneyMaskInput source="ordersAmount" upperLabel="Orders" maxLength={11} />
            </Col>
            <Col xs={24} sm={6} md={4}>
              <MoneyMaskInput source="membershipAmount" upperLabel="Membership" maxLength={11} />
            </Col>
            <Col xs={24} sm={6} md={4}>
              <MoneyMaskInput source="cogsAmount" upperLabel="COGS" maxLength={11} />
            </Col>
            <Col xs={24} sm={6} md={4}>
              <MoneyMaskInput source="commissionsAmount" upperLabel="Commissions" maxLength={11} />
            </Col>
            {/* NEXT ROW */}
            <Col xs={24} sm={6} md={4}>
              <MoneyMaskInput source="initialBalanceAmount" upperLabel="Initial Balance"
                maxLength={11} tooltip="Credit - Verqor Paga - Membership - Commissions" fixed />
            </Col>
            <Col xs={24} sm={6} md={4}>
              <MoneyMaskInput source="salesMarginAmount" upperLabel="Sales Margin"
                maxLength={11} tooltip="Orders - COGS" fixed />
            </Col>
            <Col xs={24} sm={6} md={4}>
              <MoneyMaskInput source="profitAmount" upperLabel="Profit"
                maxLength={11} tooltip="Membership + Sales Margin + Commissions" fixed />
            </Col>
            <Col xs={24} sm={6} md={4}>
              <MoneyMaskInput source="revenueAmount" upperLabel="Revenue"
                maxLength={11} tooltip="Membership + Orders + Commissions" fixed />
            </Col>
            <Col xs={24} sm={6} md={4}>
              <MoneyMaskInput source="currentBalanceAmount" upperLabel="Current Balance"
                maxLength={11} tooltip="Initial Balance - Orders" fixed />
            </Col>
            <Col xs={24} sm={6} md={4}>
              <NumberSuffixInput source="marginPercent" upperLabel="Margin" suffix="%"
                maxLength={5} fractional tooltip="(Profit / Credit) * 100 = %" fixed />
            </Col>
          </Row>
        </div>
        <div className={cls.debts}>
          <Row gutter={[80, 40]}>
            <Col xs={24} sm={12}>
              <InitialTitle title="Main Debt" className={cls.marginLeftAuto}>
                <MoneyMaskInput source="mainDebtAmount" />
              </InitialTitle>
            </Col>
            <Col xs={24} sm={12}>
              <InitialTitle title="Debt with FI">
                <MoneyMaskInput source="debtWithFiAmount" />
              </InitialTitle>
            </Col>
          </Row>
        </div>
      </InitialCard>
    </Container>
  );
};

const useStyles = makeStyles({
  amounts: {
    marginTop: 35,
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#FFF',
    padding: '10px 25px 20px',
  },
  debts: {
    marginTop: 25,
    padding: '0 75px',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
});
