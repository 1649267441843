import moment from 'moment-timezone';
import { clsx } from 'clsx';
import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Edit, EditProps, useNotify, useRedirect, useRefresh } from 'react-admin';

import {
  CreateFinanceCostParams,
  FinanceCost,
  FinanceCostOptions,
} from '@types';
import { axiosErrorToString } from '@utils';
import { financeCostHttp } from '@network/finance-cost-http';
import { useEditProps, useRbacEdit, useRecord } from '@hooks';
import { FinanceCostForm } from '@pages/finance-cost/FinanceCostForm';
import { Container, Forbidden, Loader, PageHeader } from '@components';
import cls from './FinanceCostEdit.module.css';

export const FinanceCostEdit = (props: EditProps) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const rbacEdit = useRbacEdit();
  const refresh = useRefresh();
  const financeCost = useRecord<FinanceCost>(props);
  const Title = ({ record }: {record?: FinanceCost}) =>
    record ? (<span>Finance cost: {record.code}</span>) : null;
  const editProps = useEditProps();
  const [form] = Form.useForm<CreateFinanceCostParams>();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<CreateFinanceCostParams | null>(null);
  const [options, setOptions] = useState<FinanceCostOptions | null>(null);

  const addFormData = (data: FinanceCost) => {
    setFormValues({
      expense: data?.expense || 0,
      costCategoryId: data?.costCategory.id || null,
      costSubcategoryId: data?.costSubcategoryId || null,
      costTypeId: data?.costTypeId || null,
      activationId: data?.activationId || null,
      paymentDate: moment(data?.paymentDate || new Date()).format('DD-MM-YYYY'),
      paymentBank: data?.paymentBank || '',
    });
  };

  const getOptions = async () => {
    try {
      const ops: FinanceCostOptions = await financeCostHttp.options();

      if (!ops) {
        throw new Error('The list of options has not been received');
      }
      setOptions(ops);
    } catch (e) {
      console.error(e);
    }
  };

  const submit = async (params: CreateFinanceCostParams) => {
    try {
      setLoading(true);
      const model = await financeCostHttp.update(params, financeCost.id);
      addFormData(model);
      notify('Finance cost has been updated successfully');
      refresh();
    } catch (e) {
      // @ts-ignore
      notify(axiosErrorToString(e), { type: 'error' });
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      const result = await financeCostHttp.remove(financeCost.id);

      if (result) {
        notify('Finance cost has been deleted');
        redirect('/finance-cost');
      }
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  useEffect(() => {
    if (financeCost) {
      addFormData(financeCost);
    }
  }, [financeCost]);

  useEffect(() => {
    (async () => {
      await getOptions();
    })();
  }, []);

  if (!options) {
    return <Loader center />;
  }

  if (!rbacEdit) {
    return <Forbidden />;
  }

  return (
    <Edit
      {...props}
      title={<Title />}
      {...editProps}
      className={clsx(cls.page, 'details-page-wrapper')}>
      <>
        <PageHeader
          title="Cost Information"
          linkText="Back to all costs"
          linkUrl="/finance-cost"
        />
        <Container max>
          <Row gutter={24} className={cls.wrapper}>
            <Col xs={24} className={cls.wrapperCol}>
              <div className={cls.block}>
                <div className={cls.blockHeader}>Complete information</div>
                {!formValues ? null : (
                  <Form
                    form={form}
                    layout="horizontal"
                    initialValues={formValues}
                  >
                    <FinanceCostForm
                      financeCost={financeCost}
                      initial={formValues}
                      options={options}
                      onSubmit={submit}
                      form={form}
                      loading={loading}
                      setLoading={setLoading}
                      onDelete={onDelete}
                    />
                  </Form>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    </Edit>
  );
};
