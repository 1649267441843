import { Create, CreateProps, SimpleForm } from 'react-admin';
import { Row, Col, Container, TextInput, Forbidden } from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';

export const DivisionCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps}>
      <SimpleForm>
        <Container>
          <Row>
            <Col>
              <Col>
                <TextInput source="name" label="Category" required autoFocus />
              </Col>
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};
