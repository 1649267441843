import { Col, Row } from 'antd';
import { CreditRisk } from '@types';
import { Pill } from './Pill';

export const RiskSupplier = ({ risk }: {risk: CreditRisk}) => {
  const {
    supplierScore,
    supplierDpo,
    supplierLegalIncidences,
    supplierTimeWorking,
  } = risk;

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col md={12} sm={24}>
          <Pill title="Score proveedores" info={supplierScore} />
        </Col>
        <Col md={12} sm={24}>
          <Pill title="Legal incidences" info={supplierLegalIncidences} />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col md={12} sm={24}>
          <Pill title="DPO" info={supplierDpo} mt={10} />
        </Col>
        <Col md={12} sm={24}>
          <Pill title="Tiempo trabajando con el proveedor"
            info={supplierTimeWorking} mt={10} />
        </Col>
      </Row>
    </>
  );
};
