import { clsx } from 'clsx';
import { notification } from 'antd';
import React, { useState } from 'react';
import {
  Create,
  CreateProps,
  maxLength,
  required,
  SimpleForm,
  TopToolbar,
  SaveButton,
} from 'react-admin';
import { SaveFilled } from '@ant-design/icons';
import { Button, Typography, Alert } from '@mui/material';

import { smsHttp } from '@network';
import { SmsListStatus } from '@types';
import { UsersList } from '@components/Users';
import { FormRow } from '@components/FormRow';
import { PageHeader } from '@components/PageHeader';
import { useCreateProps, useRbacCreate } from '@hooks';
import { SmsCreateInstructions } from '@components/Sms';
import { Col, Container, Forbidden, Row, TextInput } from '@components';
import cls from './SmsCreate.module.css';

const initialSmsForm = { message: '', phones: '' };

const MAX_MESSAGE_LENGTH = 160;

export const SmsCreate = (props: CreateProps) => {
  const [smsForm, setSmsForm] = useState(initialSmsForm);
  const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>([]);
  const [testStatus, setTestStatus] = useState<string>('');
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  const onChange = (e: any) => {
    setSmsForm({
      ...smsForm,
      [e.target.name]: e.target.value,
    });
  };

  const sendTestSms = async () => {
    await smsHttp.sendTestSms({ message: smsForm.message, phones: smsForm.phones })
      .then((res: any) => {
        const result = (Object.values(res) || []).reduce((acc: any, sum) => {
          return sum === 'success' ? acc + 1 : acc;
        }, 0);

        setTestStatus(`${result} of ${smsForm.phones.split(',').length || 0}`);
      }).catch(() => {
        notification.error({ message: 'Test Error', duration: 5 });
      });
  };

  const PostCreateActions = () => (
    <TopToolbar className={cls.actionsToolbar}>
      <SaveButton
        disabled={smsForm.message.length === 0}
        icon={<SaveFilled className={cls.actionIcon}/>}
        label="Save"
        type="button"
        transform={(data: any) => ({
          ...data,
          status: SmsListStatus.WAITING,
          usersIds: selectedUsersIds,
        })}
        variant="outlined"
        className={clsx(cls.action, cls.saveBtn)}
      />

      <SaveButton
        disabled={
          smsForm.phones.length === 0 ||
          smsForm.message.length === 0 ||
          selectedUsersIds.length === 0
        }
        icon={<></>}
        label="Start"
        type="button"
        transform={(data: any) => ({
          ...data,
          status: SmsListStatus.SENDING,
          usersIds: selectedUsersIds,
        })}
        variant="contained"
        className={clsx(cls.action, cls.startBtn)}
      />
    </TopToolbar>
  );

  return (
    <div className={cls.wrapper}>
      <Create {...props} {...createProps} className="details-page-wrapper">
        <PageHeader
          title="New SMS mailing list"
          linkText="Back to all SMS Registered"
          linkUrl="/sms"
        />

        <div className={cls.row}>
          <div className={clsx(cls.col, cls.colFirst)}>
            <SimpleForm toolbar={<PostCreateActions />} className={cls.form}>
              <Container max className={clsx(cls.block, cls.blockForm)}>
                <FormRow
                  title="Phone numbers for testing"
                  width={{ sm: { title: 8, children: 16 } }}
                >
                  <TextInput
                    name="phones"
                    source="phones"
                    multiline
                    onChange={onChange}
                    helperText="Only for testing the message"
                    label={false}
                  />
                </FormRow>
                <FormRow
                  title="Comment"
                  width={{ sm: { title: 8, children: 16 } }}
                >
                  <TextInput
                    name="comment"
                    source="comment"
                    multiline
                    maxRows={10}
                    helperText="Short info about this mailing list"
                    label={false}
                  />
                </FormRow>
                <FormRow
                  title="Message text*"
                  fullWidth
                  colChildrenClassName={clsx(
                    cls.message,
                    smsForm.message?.length > MAX_MESSAGE_LENGTH && cls.error,
                  )}
                >
                  <TextInput
                    name="message"
                    source="message"
                    multiline
                    helperText={`${smsForm.message?.length || 0} / ${MAX_MESSAGE_LENGTH}`}
                    onChange={onChange}
                    validate={[
                      required(),
                      maxLength(MAX_MESSAGE_LENGTH, `Maximum length of the message is ${MAX_MESSAGE_LENGTH} symbols.`),
                    ]}
                    maxRows={3}
                    rows={3}
                    label={false}
                    placeholder="Message*"
                    className={cls.error}
                  />
                </FormRow>

                <div className={cls.test}>
                  <Button
                    color="primary"
                    disabled={smsForm.phones.length === 0 || smsForm.message.length === 0}
                    onClick={sendTestSms}
                    type="button"
                    variant="outlined"
                    className={clsx(cls.testBtn, testStatus ? cls.half : cls.full)}
                  >Send test</Button>

                  {testStatus && (
                    <Alert severity="success" color="success" className={cls.testAlert}>
                      Test {testStatus} is OK!
                    </Alert>
                  )}
                </div>
              </Container>

              <Container max className={clsx(cls.block, cls.blockList)}>
                <Row className={cls.listRow}>
                  <Col className={cls.listCol}>
                    <Typography variant="h6" component="h2" className={cls.listTitle}>Recipients list</Typography>
                    <UsersList
                      selectedUsersIds={selectedUsersIds}
                      setSelectedUsersIds={setSelectedUsersIds}
                      className={cls.listWrapper}
                      listClassName={cls.list}
                    />
                  </Col>
                </Row>
              </Container>
            </SimpleForm>
          </div>
          <div className={clsx(cls.col, cls.colLast)}>
            <SmsCreateInstructions />
          </div>
        </div>
      </Create>
    </div>
  );
};
