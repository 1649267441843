import { Http } from './http';
import { RiskAdminQuestion, RiskAdminDashboard, QuestionItemType } from '@types';

export interface RiskTermsParams {
  questions: QuestionItemType[];
}

export interface RiskScoreParams {
  questions: QuestionItemType[];
}

export interface RiskOtherParams {
  questions: QuestionItemType[];
}

class RiskAdminHttp extends Http {
  prefix = 'risk';

  async getRiskTerms(): Promise<RiskAdminQuestion[]> {
    const res = await this.get<RiskAdminQuestion[]>('/terms');

    return res.data;
  }

  async updateRiskTerms(params: RiskTermsParams): Promise<RiskAdminQuestion[]> {
    const res = await this.put<RiskAdminQuestion[]>('/terms', params);

    return res.data;
  }

  async getRiskScore(): Promise<RiskAdminQuestion[]> {
    const res = await this.get<RiskAdminQuestion[]>('/score');

    return res.data;
  }

  async updateRiskScore(params: RiskScoreParams): Promise<RiskAdminQuestion[]> {
    const res = await this.put<RiskAdminQuestion[]>('/score', params);

    return res.data;
  }

  async getRiskOther(): Promise<RiskAdminQuestion[]> {
    const res = await this.get<RiskAdminQuestion[]>('/other');

    return res.data;
  }

  async updateRiskOther(params: RiskOtherParams): Promise<RiskAdminQuestion[]> {
    const res = await this.put<RiskAdminQuestion[]>('/other', params);

    return res.data;
  }

  async getRiskDashboard(): Promise<RiskAdminDashboard> {
    const res = await this.get<RiskAdminDashboard>('/dashboard');

    return res.data;
  }
}

export const riskAdminHttp = new RiskAdminHttp();
