import { Http } from './http';
import {
  Delivery,
  DeliveryDocument,
  DeliveryDocumentType,
  DeliveryPosition,
  Document,
  OrderPosition,
} from '@types';

class DeliveryHttp extends Http {

  prefix = 'delivery';

  async deleteDocumentFile(fileId: number) {
    await this.delete(`/delete-document-file/${fileId}`);
  }

  async saveDocuments({ deliveryId, fileIds, type }: SaveDocumentsParams)
    : Promise<DeliveryDocument[]> {
    const res = await this.post<DeliveryDocument[]>(`/${deliveryId}/documents`, { fileIds, type });

    return res.data;
  }

  async getPositions(deliveryId?: number): Promise<OrderPosition[]> {
    const res = await this.get<OrderPosition[]>('/positions', {
      params: { deliveryId },
    });

    return res.data;
  }

  async findDeliveryOfOrder(orderId: number): Promise<Delivery[]> {
    const res = await this.get<Delivery[]>(undefined, { params: { orderId } });

    return res.data;
  }

  async create(params: CreateDeliveryParams): Promise<Delivery> {
    const res = await this.post<Delivery>(undefined, params);

    return res.data;
  }

  async saveDocument(params: DocumentParams) {
    const { deliveryId, fileId, documentType } = params;
    const url = `/${deliveryId}/uploadDocument`;
    const res = await this.post<Document>(url, { fileId, documentType });

    return res.data;
  }

  async removeDocument(params: {deliveryId: number, documentType: string}) {
    const { deliveryId, documentType } = params;
    const url = `/${deliveryId}/removeDocument`;
    const res = await this.post<Document>(url, { documentType });

    return res.data;
  }

  async calculate(params: CreateDeliveryParams): Promise<Delivery> {
    const res = await this.post<Delivery>('/calculate', params);

    return res.data;
  }

  async getDeliveryOfCredit(creditId: number): Promise<Delivery[]> {
    const res = await this.get<Delivery[]>(`/of-credit/${creditId}`);

    return res.data;
  }
}

export const deliveryHttp = new DeliveryHttp();

interface SaveDocumentsParams {
  deliveryId: number;
  fileIds: number[];
  type: DeliveryDocumentType;
}

export interface CreateDeliveryParams {
  exchangeRate: number;
  shippingCost: number;
  extraCost: number;
  notes?: string;
  deliveryPositions: DeliveryPosition[];
}

interface DocumentParams {
  deliveryId: number;
  fileId: number;
  documentType: DocumentTypes;
}

export enum DocumentTypes {
  INVOICE = 'invoice',
  RECEIPT = 'receipt',
}
