import { User } from '@types';
import IconButton from '@mui/material/IconButton';
import cls from './user-initial.module.css';
import { useNotify } from 'react-admin';

export const UserContacts = ({ user }: {user: User}) => {
  const notify = useNotify();

  const onPhoneClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (user.phone) {
      navigator.clipboard.writeText(user.phone);
      notify(`${user.phone} is copied to Clipboard`);
    }
  };

  const onEmailClick = (e: any) => {
    e.stopPropagation();
    if (user.email) {
      navigator.clipboard.writeText(user.email);
      notify(`${user.email} is copied to Clipboard`);
    }
  };

  const onMessageClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div className={cls.contacts}>
      <IconButton className={cls.contact} onClick={onPhoneClick}>
        <svg xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none">
          <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
            fill="#042E6B" />
          <path d="M17.7808 22.2701H17.6394C12.2899 22.1876 7.98901 17.8279 7.98901 12.4783V12.2662C7.98901 12.2662 7.98901 12.2309 7.98901 12.2191C7.98901 12.1366 7.98901 12.0659 7.98901 11.9834V11.8891C8.1422 10.428 9.37943 9.30862 10.8523 9.3204C11.088 9.3204 11.3119 9.34397 11.5358 9.40289C11.5829 9.40289 11.6418 9.42645 11.6889 9.45002C11.7479 9.4618 11.795 9.52072 11.795 9.57964L12.5491 14.399C12.5491 14.4579 12.5373 14.5168 12.4902 14.5521C12.1838 14.7642 11.8421 14.9174 11.4886 14.9999C11.8303 15.8483 12.337 16.6142 12.9851 17.2623C13.5625 17.8397 14.2459 18.311 14.9882 18.6409C15.0825 18.2992 15.2357 17.981 15.436 17.6983C15.4713 17.6511 15.5303 17.6276 15.5892 17.6393L20.3731 18.3935C20.3731 18.3935 20.4792 18.4406 20.491 18.4995C20.5263 18.6056 20.5617 18.7234 20.5735 18.8294C20.6088 19.018 20.6324 19.2065 20.6324 19.395C20.6324 20.9386 19.4305 22.1994 17.8869 22.2701H17.7455H17.7808Z"
            fill="white" />
          <path d="M17.8279 14.7172C17.7454 14.7172 17.6747 14.6465 17.6747 14.564C17.6747 13.1971 16.5671 12.1013 15.212 12.1013C15.1295 12.1013 15.0588 12.0306 15.0588 11.9481C15.0588 11.8656 15.1295 11.7949 15.212 11.7949C16.7438 11.7949 17.9929 13.0439 17.9929 14.5758C17.9929 14.6582 17.9222 14.7289 17.8397 14.7289L17.8279 14.7172Z"
            fill="white"
            stroke="white"
            strokeWidth="0.25"
            strokeMiterlimit="10" />
          <path d="M19.9253 14.7174C19.8428 14.7174 19.7721 14.6467 19.7721 14.5642C19.7721 12.0426 17.7218 10.0041 15.212 10.0041C15.1295 10.0041 15.0588 9.93342 15.0588 9.85094C15.0588 9.76845 15.1295 9.69775 15.212 9.69775C17.9104 9.69775 20.0903 11.8776 20.0903 14.576C20.0903 14.6585 20.0196 14.7292 19.9371 14.7292L19.9253 14.7174Z"
            fill="white"
            stroke="white"
            strokeWidth="0.25"
            strokeMiterlimit="10" />
          <path d="M21.8695 14.7173C21.787 14.7173 21.7163 14.6466 21.7163 14.5641C21.7163 10.9702 18.8059 8.07156 15.212 8.05978C15.1295 8.05978 15.0588 7.98908 15.0588 7.9066C15.0588 7.82412 15.1295 7.75342 15.212 7.75342C18.9709 7.75342 22.0227 10.8053 22.0345 14.5759C22.0345 14.6584 21.9638 14.7291 21.8813 14.7291L21.8695 14.7173Z"
            fill="white"
            stroke="white"
            strokeWidth="0.25"
            strokeMiterlimit="10" />
        </svg>
      </IconButton>

      <a href={`mailto:${user.email}`} onClick={onEmailClick}>
        <IconButton className={cls.contact}>
          <svg width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
              fill="#042E6B" />
            <g clipPath="url(#clip0_4008_19)">
              <path fillRule="evenodd"
                clipRule="evenodd"
                d="M8.05385 19.0378C8.08077 19.0896 8.11308 19.1363 8.15615 19.1778C8.25846 19.2763 8.39308 19.3281 8.53846 19.3281H21.4615C21.6015 19.3281 21.7415 19.2763 21.8438 19.1778C21.8869 19.1363 21.9246 19.0896 21.9462 19.0378L16.6531 15.2941L16.4538 15.4185C15.5654 15.9681 14.4292 15.9681 13.5408 15.4185L13.3415 15.2941L8.05385 19.0378ZM8 11.9859V17.7881L12.3777 14.6926L8 11.9859ZM17.6277 14.6926L22 17.7881V11.9859L17.6277 14.6926ZM22 10.7519V10.5185C22 10.3837 21.9462 10.2489 21.8438 10.1504C21.7415 10.0519 21.6069 10 21.4615 10H8.53846C8.39846 10 8.25846 10.057 8.15615 10.1504C8.05923 10.2489 8 10.3837 8 10.5185V10.7519L14.1277 14.5422C14.6608 14.8741 15.3446 14.8741 15.8723 14.5422L22 10.7519Z"
                fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_4008_19">
                <rect width="14" height="9.33333" fill="white" transform="translate(8 10)" />
              </clipPath>
            </defs>
          </svg>
        </IconButton>
      </a>

      <a target="_blank" rel="noreferrer" onClick={onMessageClick}
        href="https://wa.me/525636044223">
        <IconButton className={cls.contact}>
          <svg width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
              fill="#042E6B" />
            <path d="M22 14.5C22 10.3666 18.6334 7 14.5 7C10.3666 7 7 10.3666 7 14.5C7 18.6334 10.3843 22 14.5176 22C15.3901 22 16.2362 21.859 17.0734 21.5505L21.2509 22.282H21.2949C21.3566 22.282 21.4183 22.2644 21.4536 22.2027C21.4976 22.141 21.5153 22.0617 21.4712 22L20.2991 19.2679C20.8014 18.6422 21.2068 17.9724 21.4977 17.2409C21.8414 16.3684 22 15.4606 22 14.5088V14.5ZM16.9501 17.3026H12.0852C11.8913 17.3026 11.7591 17.1616 11.7591 16.9765C11.7591 16.7914 11.9001 16.6504 12.0852 16.6504H16.9501C17.1351 16.6504 17.2761 16.7914 17.2761 16.9765C17.2761 17.1616 17.1351 17.3026 16.9501 17.3026ZM16.9501 14.8702H12.0852C11.8913 14.8702 11.7591 14.7291 11.7591 14.5441C11.7591 14.359 11.9001 14.218 12.0852 14.218H16.9501C17.1351 14.218 17.2761 14.359 17.2761 14.5441C17.2761 14.7291 17.1351 14.8702 16.9501 14.8702ZM16.9501 12.4377H12.0852C11.8913 12.4377 11.7591 12.2967 11.7591 12.1116C11.7591 11.9266 11.9001 11.7855 12.0852 11.7855H16.9501C17.1351 11.7855 17.2761 11.9266 17.2761 12.1116C17.2761 12.2967 17.1351 12.4377 16.9501 12.4377Z"
              fill="white" />
          </svg>
        </IconButton>
      </a>
    </div>
  );
};
