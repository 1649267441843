import { Create, CreateProps, SimpleForm } from 'react-admin';
import { Row, Col, Container, ImageInput, TextInput, Forbidden } from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';

export const CropCategoryCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps}>
      <SimpleForm>
        <Container>
          <Row>
            <Col><TextInput source="nameMx" required autoFocus /></Col>
            <Col><TextInput source="nameEn" /></Col>
            <Col><ImageInput source="image" required /></Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};
