import { QuestionItemShort, QuestionItemType, RiskAdminAnswer, RiskAdminQuestion, RiskQuestionType } from '@types';
import cls from './OtherGroup.module.css';
import { BlueButton, BlueOutlineButton } from '@components';
import { clsx } from 'clsx';
import { SaveFilled } from '@ant-design/icons';
import { DragEvent, useEffect, useState } from 'react';
import { Question } from '@pages/risk/variables/OtherGroup/question';
import { useNotify } from 'react-admin';
import { riskAdminHttp } from '@network/risk-admin-http';
import { axiosErrorToString } from '@utils';

interface Props {
  items: RiskAdminQuestion[];
  onChange: () => Promise<void>;
  readonly?: boolean;
}

interface MenuItemProps extends RiskAdminAnswer{
  idx: string;
}

export const OtherGroup = ({ items, onChange, readonly }: Props) => {
  const notify = useNotify();
  const [list, setList] = useState<QuestionItemType[]>([]);
  const [defaultList, setDefaultList] = useState<QuestionItemType[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState<QuestionItemType | null>(null);
  const [targetItem, setTargetItem] = useState<number | null>(null);

  const onDragStart = (_: DragEvent<HTMLDivElement>, item: QuestionItemType) => {
    if (readonly) return;
    setCurrentItem(item);
  };

  const onDragEnd = (_: DragEvent<HTMLDivElement>) => {
    if (readonly) return;
    setTargetItem(null);
  };

  const onDragOver = (e: DragEvent<HTMLDivElement>, item: QuestionItemType) => {
    e.preventDefault();
    if (readonly) return;
    !item.isFixed && setTargetItem(item.idx);
  };

  const onDrop = (e: DragEvent<HTMLDivElement>, item: QuestionItemType) => {
    e.preventDefault();
    if (readonly) return;
    setTargetItem(null);
    if (item.isFixed) return;

    setList(list.map((i: QuestionItemType) => {
      if (i.idx === item.idx && currentItem) {
        return { ...i, position: currentItem.position };
      }
      if (i.idx === currentItem?.idx) {
        return { ...i, position: item.position };
      }
      return i;
    }));
  };

  const setItemsList = () => {
    const updatedList: QuestionItemType[] = items
      .map((i: RiskAdminQuestion, idx: number) => ({
        ...i,
        idx,
        answers: i.answers
          ? i.answers.map((a: RiskAdminAnswer, key: number) => ({ ...a, idx: key }))
          : undefined,
      }));
    setList(updatedList);
    setDefaultList(updatedList);
  };

  const onAddItem = () => {
    if (readonly) return;
    if (list.length === 20 || !validation()) return;

    const idx = Date.now().toString();
    const answers: MenuItemProps[] = [
      { idx: `${idx}yes`, title: 'Yes' },
      { idx: `${idx}no`, title: 'No' },
    ];

    const newItem: QuestionItemShort = {
      idx: Date.now(),
      type: RiskQuestionType.SINGLE_CHOICE,
      title: '',
      titleMx: '',
      position: Date.now(),
      isFixed: false,
      answers,
    };
    setList(list => ([
      ...list,
      newItem,
    ]));
  };

  const onSave = async () => {
    if (readonly) return;
    if (!validation()) {
      throw new Error('Questions are not valid!');
    }

    const questions: QuestionItemType[] = list.map(i => ({
      ...i,
      title: i.title?.trim(),
      titleMx: i.titleMx?.trim(),
      answers: !i.answers
        ? undefined
        : i.answers.map(a => ({
          id: a.id,
          title: a.title?.trim(),
        })),
    }));

    setLoading(true);
    try {
      await riskAdminHttp.updateRiskOther({ questions });
      await onChange();
      notify('Updated');
    } catch (e) {
      notify(axiosErrorToString(e));
    } finally {
      setLoading(false);
    }
  };

  const validation = (): boolean => {
    const errors = list.filter(i => (
      !i.title.trim() || (Array.isArray(i.answers) && (!i.answers.length || i.answers.length < 2)))
      || (Array.isArray(i.answers) && !!i.answers
        .filter((a: RiskAdminAnswer) => !a.title.trim()).length),
    );
    return !errors.length;
  };

  const sortItems = (a: QuestionItemType, b: QuestionItemType): number =>
    a.position > b.position ? 1 : -1;

  const onItemChange = (item: QuestionItemType) => {
    setList(prev => prev.map(i => i.idx === item.idx ? item : i));
  };

  const onItemDelete = (item: QuestionItemType) => {
    if (readonly) return;
    setList(prev => prev.filter(i => i.idx !== item.idx));
  };

  const hasChanges = (): boolean => {
    return JSON.stringify(defaultList) !== JSON.stringify(list);
  };

  useEffect(() => {
    if (items.length) {
      setItemsList();
    }
  }, [items]);

  return (
    <div className={cls.wrap}>
      <div className={cls.header}>Variable category name: Other variables</div>
      <div className={clsx(cls.row, cls.tHeader)}>
        <div className={cls.col}>{list.length ? 'Variable name' : null}</div>
        <div className={cls.col}>{list.length ? 'Options' : null}</div>
      </div>
      <div className={cls.list}>
        {!list.length ? null : list.sort(sortItems).map((i: QuestionItemType) => (
          <Question
            item={i}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onChange={onItemChange}
            onDelete={onItemDelete}
            key={i.position}
            className={clsx(targetItem === i.idx && cls.target)}
            readonly={readonly}
          />
        ))}
      </div>
      <div className={cls.tableActions}>
        <BlueOutlineButton
          className={clsx(cls.btn, cls.btnOutline, cls.addBtn)}
          onClick={onAddItem}
          disabled={readonly || loading || list.length === 20 || !validation()}
        >Add variable</BlueOutlineButton>
      </div>
      <div className={cls.pageActions}>
        <BlueButton
          className={clsx(cls.btn, cls.saveBtn)}
          onClick={onSave}
          disabled={readonly || loading || !validation() || !hasChanges()}
        >
          <SaveFilled className={cls.btnIcon}/>
          Save
        </BlueButton>
      </div>
    </div>
  );
};
