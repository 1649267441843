import { Link, useRefresh } from 'react-admin';
import { CopyOutlined } from '@ant-design/icons';
import { Col, notification, Popconfirm, Row, message } from 'antd';

import { User } from '@types';
import { userHttp } from '@network';
import { axiosErrorToString } from '@utils';
import { ConfirmDelete } from '@components/ConfirmDelete';
import cls from './user-addresses.module.css';

const MAX_LOCATIONS_COUNT = 10;

export const UserAddresses = ({ user }: { user: User }) => {
  const refresh = useRefresh();

  const remove = async (locationId: number | null) => {
    if (locationId) {
      try {
        await userHttp.removeAddress(locationId);
        notification.success({ message: 'Address has been removed', duration: 5 });
        refresh();
      } catch (err: any) {
        axiosErrorToString(err);
      }
    }
  };

  const onCopyAddr = async (e, val: string): Promise<void> => {
    e.stopPropagation();
    e.preventDefault();
    await navigator.clipboard.writeText(val);
    message.info('The data is copied to clipboard');
  };

  const isLocationsCountMax = (user?.location?.length || 0) < MAX_LOCATIONS_COUNT;

  return (
    <div className={cls.wrap}>
      <div className={cls.title}>Addresses</div>
      <Row gutter={[20, 20]} className={cls._}>
        {user?.location?.map((location, index) => {
          const addressValues = [
            location.state,
            location.neighbourhood,
            location.city,
            location.street,
            location.house,
            location.apartment,
            location.extended,
          ];
          const addr = location.formatted || addressValues.filter(Boolean).join(', ');

          return (
            <Col xs={12} sm={12} xl={6} key={location.id}>
              <div className={cls.item}>
                <Link to={`/user/${user.id}?addresses`} className={cls.itemLink}>
                  <div className={cls.itemTitle}>Address #{index + 1}</div>
                  <div className={cls.itemAddress}>
                    <span>{addr}</span>
                    <span>
                      <CopyOutlined
                        onClick={(e) => onCopyAddr(e, addr)}
                        className={cls.copyIcon}
                      />
                    </span>
                  </div>
                </Link>
                <div className={cls.itemRemove}>
                  <ConfirmDelete title="Are you sure?" cb={() => remove(location.id || null)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M9 0C4.03021 0 0 4.03021 0 9C0 13.9698 4.03021 18 9 18C13.9698 18 18 13.9698 18 9C17.992 4.03021 13.9658 0.00399822 9 0Z" fill="#002A77" />
                      <path d="M12.3863 5.60962C12.2303 5.45369 11.9785 5.45369 11.8225 5.60962L8.99978 8.43237L6.17704 5.60962C6.0211 5.45369 5.76922 5.45369 5.61329 5.60962C5.45736 5.76555 5.45736 6.01744 5.61329 6.17337L8.43603 8.99612L5.61329 11.8189C5.45736 11.9748 5.45736 12.2267 5.61329 12.3826C5.76922 12.5385 6.0211 12.5385 6.17704 12.3826L8.99978 9.55987L11.8225 12.3826C11.9785 12.5385 12.2303 12.5385 12.3863 12.3826C12.5422 12.2267 12.5422 11.9748 12.3863 11.8189L9.56353 8.99612L12.3863 6.17337C12.5422 6.01744 12.5422 5.76555 12.3863 5.60962Z" fill="white" />
                    </svg>
                  </ConfirmDelete>
                </div>
              </div>
            </Col>
          );
        })}
        {isLocationsCountMax && (
          <Col xs={12} sm={8} md={6}>
            <Link to={`/user/${user.id}?addresses=new`} className={cls.newItem}>
              <span className={cls.iconPlus}>+</span>
            </Link>
          </Col>
        )}
      </Row>
    </div>
  );
};
