import { Layout } from 'react-admin';
import { ConfigProvider } from 'antd';

import { AppMenu } from './AppMenu';
import { AppBar } from './AppBar';

export const AppLayout = (props: any) => {
  return (
    <ConfigProvider>
      <Layout {...props} appBar={AppBar} menu={AppMenu} />
    </ConfigProvider>
  );
};
