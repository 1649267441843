import { Http } from './http';
import { AdminComment, Admin, Timer } from '@types';
import { NotificationType } from '@components/Notifications';

export interface UpdateAdminParams {
  reports?: string[];
  reportRange?: string;
  reportType?: string;
}

class AdminHttp extends Http {

  async login(params: LoginParams): Promise<Admin> {
    const res = await this.post<{admin: Admin}>('login', params);

    return res.data.admin;
  }

  async updateMe(params: UpdateAdminParams): Promise<Admin> {
    const res = await this.put<{admin: Admin}>('me', params);

    return res.data.admin;
  }

  async me(): Promise<Admin> {
    const res = await this.get<{admin: Admin}>('me');
    const { admin } = res.data;

    if (admin && admin.permissionKeys && admin.permissionKeys['finance-dashboard|ALL']) {
      admin.permissionKeys['finance-balance|ALL'] = true;
    }

    return admin;
  }

  async getAll(): Promise<Admin[]> {
    const res = await this.get<Admin[]>('admin');

    return res.data;
  }

  async logout() {
    return await this.get('logout');
  }

  async getResponsibleManagers(): Promise<Admin[]> {
    const res = await this.get<Admin[]>('admin/managers');

    return res.data;
  }

  async addComment(params: AddCommentParams): Promise<AdminComment> {
    const res = await this.post<AdminComment>('comment', params);

    return res.data;
  }

  async removeComment(id: number): Promise<AdminComment> {
    const res = await this.delete<AdminComment>(`comment/${id}`);

    return res.data;
  }

  async getAllNotifications(): Promise<NotificationType> {
    const res = await this.post<NotificationType>('notifications', {
      date: new Date(),
    });

    return res.data;
  }

  async readNotifications(entity: NotificationEntityTypes): Promise<any[]> {
    const res = await this.get<any[]>(`notifications/${entity}`);

    return res.data;
  }

  async getTaskAdmins(): Promise<Admin[]> {
    const res = await this.get<Admin[]>('admin/task-admin');

    return res.data;
  }

  async getTimer(url: string) {
    const res = await this.get<Timer>(url);

    return res.data;
  }
}

export const adminHttp = new AdminHttp();

interface LoginParams {
  username: string;
  password: string;
}

interface AddCommentParams {
  comment: string;
  partnerClass: string;
  partnerId: number;
  showToUser?: boolean;
}

export enum NotificationEntityTypes {
  USER_ASSIGNED = 'USER_ASSIGNED',
  USER_CONTACT_OWNER_UPDATED = 'USER_CONTACT_OWNER_UPDATED',
  CREDIT_FILES_PROGRESS = 'CREDIT_FILES_PROGRESS',
  CREDIT_STATUS_MODERATION_SIGNED = 'CREDIT_STATUS_MODERATION_SIGNED',
  NEW_CREDIT = 'NEW_CREDIT',
  NEW_CONTACT_US = 'NEW_CONTACT_US',
  CREDIT_STATUS_CX = 'CREDIT_STATUS_CX',
  CREDIT_STATUS_DELAY_CX = 'CREDIT_STATUS_DELAY_CX',
  NEW_RESUME = 'NEW_RESUME',
  NEW_QUOTATION = 'NEW_QUOTATION',
  INVOICE_UPLOADED = 'INVOICE_UPLOADED',
  CALL_OF_THE_DAY = 'CALL_OF_THE_DAY',
  CONTACT_NEXT_CYCLE = 'CONTACT_NEXT_CYCLE',
  USER_UPLOADED_DOCUMENTS = 'USER_UPLOADED_DOCUMENTS',
  FINANCE_COST_CREATED = 'FINANCE_COST_CREATED',
  FINANCE_WITHDRAWAL_CREATED = 'FINANCE_WITHDRAWAL_CREATED',
  FINANCE_DEPOSIT_CREATED = 'FINANCE_DEPOSIT_CREATED',
}
