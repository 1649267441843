import { Credit } from '@types';
import { InitialCard, TimerWidget } from '@components';

import cls from './credit-time-tracker.module.css';

export const CreditTimeTracker = ({ credit }: {credit: Credit}) => {
  return (
    <InitialCard left="Credit time tracker" contentClass={cls.content}>
      <TimerWidget
        title="Credit created to “Analysis”"
        url={`/credit/${credit.id}/timer/created-to-analysis`}
      />
      <TimerWidget
        title="Credit “Analysis” to “Approved” or “Denied”"
        url={`/credit/${credit.id}/timer/analysis-to-approved-or-denied`}
      />
    </InitialCard>
  );
};
