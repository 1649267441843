import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { AdminNotification } from '@types';
import { adminHttp, NotificationEntityTypes } from '@network';
import { useSettingsContext } from '@providers';
import { Sidepanel } from '../Sidepanel';
import { NotificationsItem } from './notifications-item';
import cls from './notifications.module.css';

export type NotificationType = Record<NotificationEntityTypes, AdminNotification[]>;

export const Notifications = () => {
  const location = useLocation();
  const { panelName, setPanelName } = useSettingsContext();
  const [notificationsMap, setNotificationsMap] = useState<NotificationType | null>(null);

  useEffect(() => {
    (async () => {
      const loadedNotifications = await adminHttp.getAllNotifications();

      setNotificationsMap(loadedNotifications);
    })();
  }, [location.pathname]);

  const sidepanelName = 'Notifications';
  const togglePanel = () => setPanelName(panelName === sidepanelName ? null : sidepanelName);
  const notifications = Object.keys(notificationsMap || {}) as any;

  const count = useMemo(() => {
    return notifications.length >= 10 ? '9+' : notifications.length;
  }, [notificationsMap]);

  const notificationsList
    = Object.entries(notificationsMap || {}) as [NotificationEntityTypes, AdminNotification[]][];

  return (
    <>
      <div className={cls.icon} onClick={togglePanel}>
        <NotificationsIcon style={{ fill: '#002a77' }} />
        {notifications.length !== 0 && (
          <div className={cls.counter}>{count}</div>
        )}
      </div>

      <Sidepanel name={sidepanelName} title="Notifications">
        {notificationsList.length === 0 && (
          <div className={cls.empty}>You have no notifications</div>
        )}
        {notificationsList.map(([entity, items], index) => (
          <NotificationsItem
            key={index}
            entity={entity}
            items={items}
            setNotificationsMap={setNotificationsMap}
          />
        ))}
      </Sidepanel>
    </>
  );
};
