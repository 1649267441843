import { Create, CreateProps } from 'react-admin';
import { Forbidden } from '@components';
import { useCreateProps, useQuery, useRbacCreate } from '@hooks';
import { CallWidget } from '@pages/credit-call/CallWidget';

const Title = () => (<span>Create CreditCall</span>);

export const CreditCallCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();
  const query = useQuery();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} title={<Title />} {...createProps}>
      <CallWidget creditId={+(query.get('creditId') || 0)} editable={rbacCreate} />
    </Create>
  );
};
