import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';
import React, { PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import { LightTooltip } from '@components/LightTooltip';

export interface IGrid {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
}

export interface InitialRowProps {
  title: string | JSX.Element;
  fullWidth?: boolean;
  className?: string;
  titleClassName?: string;
  labelCentered?: boolean;
  fontLg?: boolean;
  labelGrid?: IGrid;
  valueGrid?: IGrid;
  tip?: string;
  labelSize?: number;
}

export const InitialRow = ({
  children, title, fullWidth, className, labelGrid, labelCentered,
  fontLg, valueGrid, tip, titleClassName, labelSize = 10,
}: PropsWithChildren<InitialRowProps>) => {
  const cls = useStyles();
  const lGrid = labelGrid || { sm: fullWidth ? 24 : labelSize };
  const vGrid = valueGrid || { sm: fullWidth ? 24 : (24 - labelSize) };
  const rowClassName = clsx(
    cls.row,
    className,
    fontLg && cls.fontLg,
  );
  const colClassName = clsx(
    'ir-label',
    cls.title,
    labelCentered && cls.labelCentered,
    fontLg && cls.fontLg,
    fullWidth && cls.fullWidth,
    titleClassName,
  );

  if (tip) {
    return (
      <LightTooltip title={tip}>
        <Row className={rowClassName}>
          <Col className={colClassName} {...lGrid}>{title}</Col>
          <Col className="ir-value" {...vGrid}>{children}</Col>
        </Row>
      </LightTooltip>
    );
  }

  return (
    <Row className={rowClassName}>
      <Col className={colClassName} {...lGrid}>{title}</Col>
      <Col className="ir-value" {...vGrid}>{children}</Col>
    </Row>
  );
};

const useStyles = makeStyles({
  box: {
    marginTop: 15,
    fontFamily: 'Outfit, sans-serif',
    color: '#272727',
  },
  row: {
    marginTop: 20,
    fontSize: 15,
    lineHeight: 1.05,
    fontWeight: 400,
    minHeight: 24,
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputLabel-standard': {
      display: 'none !important',
    },
  },
  title: {
    fontSize: 15,
    fontWeight: 500,
  },
  labelCentered: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
  },
  fontLg: {
    fontSize: 18,
    fontWeight: 400,
    '& input, & select, & .MuiSelect-select': {
      fontSize: 18,
    },
  },
  fullWidth: {
    width: '100% !important',
    marginBottom: 10,
  },
});
