import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import clsx from 'clsx';

import { FinanceActivation, financeSettingsHttp } from '@network/finance-settings-http';
import { moneyFormatter } from '@utils';
import { makeStyles } from '@mui/styles';

interface ActivationCplProps {
  className?: string;
}

export const ActivationCpl = ({ className }: ActivationCplProps) => {
  const activationId = useWatch({ name: 'activationId' });

  return <ActivationIdCpl activationId={activationId} className={className} />;
};

interface ActivationIdCplProps {
  activationId?: number | null;
  className?: string;
}

export const ActivationIdCpl = ({ activationId, className }: ActivationIdCplProps) => {
  const [activation, setActivation] = useState<FinanceActivation>();
  const cls = useStyles();

  useEffect(() => {
    if (activationId) {
      (async () => {
        const nextActivation = await financeSettingsHttp.getActivationById(activationId);
        setActivation(nextActivation);
      })();
    } else {
      setActivation(undefined);
    }
  }, [activationId]);

  return (
    <div className={clsx(cls.box, className)}>
      <div className={cls.title}>CPL</div>
      {activation ? (
        <div className={cls.value}>{moneyFormatter.format(activation.cpl)}</div>
      ) : (
        <div className={cls.value}>No activation</div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '5px solid #042E6B',
    borderRadius: 5,
    width: 306,
    height: 59,
    fontSize: 18,
    fontWeight: 400,
    color: '#000',
  },
  title: {
    position: 'absolute',
    backgroundColor: 'white',
    top: -11,
    left: -5,
    paddingRight: 6,
  },
  value: {
    fontSize: 22,
  },
});
