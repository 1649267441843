import { FunctionFieldProps, FunctionField } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { resolvePath } from './resolve-path';

export const PhoneField = (props: Partial<FunctionFieldProps> & { isText?: boolean }) => {
  const fieldProps = { ...props };
  delete fieldProps.isText;

  return (
    <FunctionField
      {...fieldProps}
      render={(record: any) => <PhoneLink phone={resolvePath(record, props.source || '')} isText={props.isText} />}
    />
  );
};

// PHONE
const PhoneLink = ({ phone, isText }: {phone?: string | null; isText?: boolean}) => {
  const classes = useStyles();
  if (!phone || phone.length < 10) {
    return null;
  }

  if (phone.length === 10) {
    let f = phone;
    f = `(${ f[0] }${f[1] }${f[2] }) ${ f[3] }${f[4] }${f[5] }-${f[6] }${f[7] }${f[8] }${f[9]}`;
    return <span>{f}</span>;
  }

  const href = `tel:${phone}`;
  const countryCode = phone.slice(0, phone.length - 10);
  let f = phone.replace(/\D/g, '').slice(phone.length - 11);

  if (f[9] !== undefined) {
    f = `${countryCode} (${ f[0] }${f[1] }${f[2] }) ${ f[3] }${f[4] }${f[5] }-${f[6] }${f[7] }${f[8] }${f[9]}`;
  }

  return isText ? (
    <span>{f}</span>
  ) : (
    <a href={href} className={classes.link} onClick={e => e.stopPropagation()}>{f}</a>
  );
};

const useStyles = makeStyles({
  link: {
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    color: '#002A77',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});
