import { Http } from './http';
import { Bank } from '@types';

class BankHttp extends Http {
  prefix = '/bank';

  async getAll() {
    const res = await this.get<Bank[]>('');

    return res.data;
  }
}

export const bankHttp = new BankHttp();
