import {
  SimpleForm, Edit, EditProps,
} from 'react-admin';

import { useEditProps } from '@hooks';
import {
  Container, Row, Col, TextInput,
} from '@components';

export const CommentEdit = (props: EditProps) => {
  const editProps = useEditProps();

  return (
    <Edit {...props} {...editProps}>
      <SimpleForm>
        <Container max>
          <Row>
            <Col>
              <TextInput source="text" required />
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </Edit>
  );
};
