import { clsx } from 'clsx';
import { Dropdown, Input, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ChangeEvent, DragEvent, useEffect, useState } from 'react';
import { CloseCircleFilled, EditFilled, PlusCircleFilled } from '@ant-design/icons';

import { BlueOutlineButton } from '@components';
import {
  QuestionItemType,
  RiskAdminAnswer,
  RiskQuestionType,
  RiskQuestionTypeTitles,
} from '@types';
import cls from './RiskQuestion.module.css';

interface Props {
  item: QuestionItemType;
  className?: string;
  onDragStart: (e: DragEvent<HTMLDivElement>, item: QuestionItemType) => void;
  onDragEnd: (e: DragEvent<HTMLDivElement>) => void;
  onDragOver: (e: DragEvent<HTMLDivElement>, item: QuestionItemType) => void;
  onDrop: (e: DragEvent<HTMLDivElement>, item: QuestionItemType) => void;
  onChange: (item: QuestionItemType) => void;
  onDelete: (item: QuestionItemType) => void;
  readonly?: boolean;
}

interface MenuItemProps extends RiskAdminAnswer {
  idx: number;
}

interface OptionsMenuProps {
  options: RiskAdminAnswer[] | undefined;
  onChange: (answers: MenuItemProps[]) => void;
  readonly?: boolean;
}

const OptionsMenu = ({ options, onChange, readonly }: OptionsMenuProps) => {
  const [answers, setAnswers] = useState<MenuItemProps[]>([]);

  const addOption = () => {
    if (readonly) return;
    const idx = Date.now();
    const item: MenuItemProps = { idx, title: '' };
    setAnswers([...answers, item]);
  };

  const onDeleteOption = (item: MenuItemProps) => {
    setAnswers(answers.filter(i => i.idx !== item.idx));
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>, item: MenuItemProps) => {
    const newOption: MenuItemProps = { ...item, title: e.target.value.trimStart() };
    setAnswers(answers.map(i => i.idx === item.idx ? newOption : i));
  };

  const hasEmptyOptions = (): boolean => !!answers.filter(a => !a.title?.trim()).length;

  useEffect(() => {
    onChange(answers);
  }, [answers]);

  useEffect(() => {
    options && options.length && setAnswers(options as MenuItemProps[]);
  }, []);

  const dropdownRender = (_: any) => {
    return (
      <div className={cls.ddMenu}>
        {answers.map((item) => (
          <div className={cls.menuItem} key={item.idx}>
            <span className={cls.optionTitle}>
              <Input
                placeholder="Option title"
                bordered={false}
                value={item.title}
                onChange={e => onInputChange(e, item)}
                className={cls.optionField}
                status={!item.title.trim() ? 'error' : undefined}
                required
                maxLength={100}
              />
            </span>
            <CloseCircleFilled className={cls.deleteIcon} onClick={() => onDeleteOption(item)} />
          </div>
        ))}
        {answers.length < 10
          ? <div
            className={clsx(cls.menuItem, cls.addMenuItem, hasEmptyOptions() && cls.disabled)}
            onClick={hasEmptyOptions() ? undefined : addOption}
          >
            <span className={cls.optionTitle}>Option {answers.length + 1}</span>
            <PlusCircleFilled className={cls.optionIcon} />
          </div>
          : null}
      </div>
    );
  };

  return (
    <div className={cls.ddWrap}>
      <Dropdown className={cls.dd}
        trigger={['click']}
        dropdownRender={dropdownRender}
        placement="bottomRight"
        disabled={readonly}
      >
        <BlueOutlineButton className={cls.btn} disabled={readonly}>
          <EditFilled className={cls.btnIcon} />
          <span className={cls.btnText}>Edit options</span>
        </BlueOutlineButton>
      </Dropdown>
    </div>
  );
};

export const RiskQuestion = ({
  item, className, onDragStart, onDragEnd, onDragOver, onDrop, onChange, onDelete, readonly,
}: Props) => {
  const [draggable, setDraggable] = useState<boolean>(false);
  const typeOptions: DefaultOptionType[] = [
    { value: RiskQuestionType.LONG_ANSWER, label: RiskQuestionTypeTitles.LONG_ANSWER },
    { value: RiskQuestionType.SHORT_ANSWER, label: RiskQuestionTypeTitles.SHORT_ANSWER },
    { value: RiskQuestionType.MONEY, label: RiskQuestionTypeTitles.MONEY },
    { value: RiskQuestionType.PERCENTAGE, label: RiskQuestionTypeTitles.PERCENTAGE },
    { value: RiskQuestionType.SINGLE_CHOICE, label: RiskQuestionTypeTitles.SINGLE_CHOICE },
  ];

  const onInputChange = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    const newItem: QuestionItemType = { ...item, [field]: e.target.value };
    onChange(newItem);
  };

  const handleChange = (type: RiskQuestionType) => {
    const newItem: QuestionItemType = { ...item, type };
    onChange(newItem);
  };

  const onOptionsChange = (answers: MenuItemProps[]) => {
    const newItem: QuestionItemType = { ...item, answers };
    onChange(newItem);
  };

  return (
    <div
      key={item.position}
      draggable={!item.isFixed && draggable && !readonly}
      onDragStart={(e) => onDragStart(e, item)}
      onDragLeave={onDragEnd}
      onDragEnd={onDragEnd}
      onDragOver={(e) => onDragOver(e, item)}
      onDrop={(e) => onDrop(e, item)}
      className={clsx(cls.card, className)}
    >
      <div className={cls.row}>
        <div className={clsx(cls.col, 'dropable')}>
          <Input
            placeholder="Title"
            bordered={false}
            value={item.title}
            onChange={(e) => onInputChange(e, 'title')}
            className={clsx(cls.textField, item.isFixed && cls.fixed, cls.titleField)}
            disabled={item.isFixed}
            status={!item.title.trim() ? 'error' : undefined}
            required
            maxLength={100}
          />
        </div>
        <div className={clsx(cls.col, 'dropable')}>
          <Input
            placeholder="title Mx"
            bordered={false}
            value={item.titleMx}
            onChange={(e) => onInputChange(e, 'titleMx')}
            className={clsx(cls.textField, item.isFixed && cls.fixed, cls.titleMxField)}
            disabled={item.isFixed}
            status={!item.titleMx.trim() ? 'error' : undefined}
            required
            maxLength={100}
          />
        </div>
        <div className={clsx(cls.col, 'dropable')}>
          <Select
            defaultValue={RiskQuestionType.SHORT_ANSWER}
            onChange={handleChange}
            options={typeOptions}
            value={item.type}
            className={clsx(cls.selectField, item.isFixed && cls.fixed, cls.typeField)}
            disabled={item.isFixed}
            popupClassName={cls.selectPopupClassName}
          />
          {!item.isFixed ? (
            <div
              className={cls.decor}
              onMouseEnter={() => setDraggable(true)}
              onMouseLeave={() => setDraggable(false)}
            >
              <span className={cls.decorPoint} />
              <span className={cls.decorPoint} />
              <span className={cls.decorPoint} />
            </div>
          ) : null}
        </div>
        <div className={cls.col}>
          {!item.isFixed ? (
            <div className={cls.actions}>
              <div className={cls.deleteItem}>
                {!readonly ? (
                  <CloseCircleFilled className={cls.deleteIcon} onClick={() => onDelete(item)} />
                ) : null}
              </div>
              {item.type === RiskQuestionType.SINGLE_CHOICE ? (
                <OptionsMenu
                  options={item.answers}
                  onChange={onOptionsChange}
                  readonly={readonly}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
