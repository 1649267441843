import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-admin';
import { clsx } from 'clsx';
import { notification } from 'antd';
import { Button, Checkbox, Dialog, DialogActions, DialogTitle, Table, TableCell, TableHead, TableBody, TableContainer, TableRow, TablePagination } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { moneyFormatter } from '@utils/money-formatter';
import { axiosErrorToString, dateFormatter } from '@utils';
import { Order, User } from '@types';
import { InitialPage } from '@components';
import { ordersHttp } from '@network';
import { UserOrderDetail } from '@pages/user/UserOrders';
import cls from './user-orders-list.module.css';

interface UserOrdersListProps {
  user?: User;
}

export const UserOrdersList = ({ user }: UserOrdersListProps) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsCount, setRowsCount] = useState<number>(10);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const checkHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedOrder(null);
    const orderId = +e.target.name;

    if (orderId === 0) {
      setSelectedIds(!e.target.checked ? [] : orders.map(o => o.id));

      return;
    }

    if (selectedIds.includes(orderId)) {
      setSelectedIds(prev => prev.filter(i => i !== orderId));
    } else {
      setSelectedIds([...selectedIds, orderId]);
    }
  };

  const selectOrder = (data: Order | null) => {
    setSelectedOrder(data);
  };

  const fetchOrders = useCallback(async () => {
    if (user?.id) {
      try {
        const nextOrders = await ordersHttp.getOrdersByUser(user.id);
        setOrders(nextOrders);
      } catch (err) {
        const errorMessage = axiosErrorToString(err);
        notification.error({ message: errorMessage, duration: 8 });
        setOrders([]);
      }
    }
  }, [user]);

  const remove = async () => {
    setIsDialogOpen(false);

    try {
      await ordersHttp.removeOrders(selectedIds);
      void fetchOrders();

      setSelectedIds([]);
    } catch (err) {
      const errorMessage = axiosErrorToString(err);
      notification.error({ message: errorMessage, duration: 8 });
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (e: any) => {
    setRowsCount(+e.target.value);
  };

  useEffect(() => {
    void fetchOrders();
  }, [user?.id, fetchOrders]);

  const rows = (rowsCount > 0
    ? orders.slice(currentPage * rowsCount, currentPage * rowsCount + rowsCount)
    : orders
  );

  return (
    <InitialPage title="Orders" backTo={`/user/${user?.id || ''}?initial`} backText="Back to Individual User">
      <div className={`${cls.table} RaDatagrid-tableWrapper`}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="user-orders-list"
          >
            <TableHead>
              <TableRow>
                <TableCell className={cls.checkCol}>
                  <Checkbox name="0" onChange={checkHandler} />
                </TableCell>
                <TableCell className={cls.thead}>
                  Order No.
                </TableCell>
                <TableCell className={cls.thead}>
                  Credit
                </TableCell>
                <TableCell className={cls.thead}>
                  Date
                </TableCell>
                <TableCell className={cls.thead}>
                  Total
                </TableCell>
                <TableCell className={cls.thead}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((order) => (
                <TableRow key={order.id}>
                  <TableCell className={cls.checkCol}>
                    <Checkbox name={`${order.id}`} onChange={checkHandler} checked={selectedIds.includes(order.id)} />
                  </TableCell>
                  <TableCell
                    className={clsx(cls.value, cls.code)}
                    onClick={() => selectOrder(selectedOrder?.id !== order.id ? order : null)}
                  >
                    {`Order # ${order.id}`}
                  </TableCell>
                  <TableCell
                    className={clsx(cls.value, cls.credit)}
                  >
                    {order.credit
                      ? (
                        <Link to={`/credit/${order.credit.id}`} className={cls.link}>
                          {order.credit.code}
                        </Link>
                      ) : '–'
                    }
                  </TableCell>
                  <TableCell className={cls.value}>
                    {`${dateFormatter.toDateSlash(order.createdAt)} ${dateFormatter.toTime(order.createdAt)}`}
                  </TableCell>
                  <TableCell className={cls.value}>
                    {moneyFormatter.format(order.totalCost)}
                  </TableCell>
                  <TableCell className={cls.value}>
                    <span className={cls.status}>
                      {order.statusEn}
                      {order.status === 'paid' && <div className={cls.paid} />}
                      {order.status === 'cancelled' && <div className={cls.canceled} />}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={orders?.length}
            page={currentPage}
            rowsPerPage={rowsCount}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        {selectedIds.length !== 0 && (
          <>
            <div className={cls.actions}>
              <button className={cls.delete} onClick={() => setIsDialogOpen(true)}>
                <DeleteIcon fontSize="small" /> Delete
              </button>
            </div>

            <Dialog
              open={isDialogOpen}
              onClose={() => setIsDialogOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure to delete this orders?
              </DialogTitle>
              <DialogActions>
                <Button onClick={() => setIsDialogOpen(false)}>
                  No
                </Button>
                <Button onClick={remove} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}

        {selectedOrder && (
          <UserOrderDetail order={selectedOrder} selectOrder={selectOrder} />
        )}
      </div>
    </InitialPage>
  );
};
