import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { InputNumber, Select } from 'antd';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import clsx from 'clsx';

import { moneyFormatter } from '@utils';
import { CreditInvoice, OrderCompositionProduct, Product } from '@types';
import { productInvoiceHttp } from '@network/product-invoice-http';
import { ProductCreator } from '@components/ProductCreator';
import cls from './credit-invoice-products.module.css';

interface CreditInvoiceProductProps {
  index: number;
  product: OrderCompositionProduct | null;
  productsSuggests: Product[];
  removeProduct: (arg: number) => void;
  onProductSelect: (index: number, productId: number | string, option: any) => void;
  setIsProductCreatorActive: (arg: boolean) => void;
  onQtyChange: (index: number, qty: number) => void;
  onProductPriceChange: (index: number, productPrice: any) => void;
  addProduct: (index: number) => void;
}

const CreditInvoiceProduct = ({
  product,
  index,
  productsSuggests,
  removeProduct,
  onProductSelect,
  onQtyChange,
  onProductPriceChange,
  addProduct,
}: CreditInvoiceProductProps) => {
  const options = productsSuggests.map(p => ({
    value: p.id.toString(),
    label: p.pack ? `${p.name} - ${p.pack}` : p.name,
  }));

  const filterOption = (input: string, option?: {label: string; value: string}) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const isXml = !!product?.xmlName;

  return (
    <div className={cls.product}>
      <div className={cls.productContent}>
        <div className={cls.productHead}>
          <div className={cls.name}>Product</div>
          <div className={cls.quantity}>Quantity (1 or 1.225)</div>
          <div className={cls.price}>Requested Price</div>
          {!isXml && (<div className={cls.remove}>Remove</div>)}
        </div>
        <div className={cls.productInfo}>
          <div className={cls.number}>{index + 1}</div>
          <div className={cls.name}>
            {isXml && (
              <div className={cls.extraction}><b>XML-extraction:</b>{product?.xmlName}</div>
            )}
            <Select
              onChange={(productId, option) => onProductSelect(index, productId, option)}
              value={product?.productId?.toString()}
              className={cls.input}
              showSearch
              placeholder="Add product"
              options={[
                { value: 'Add product', label: 'Add product' },
                ...options,
              ]}
              notFoundContent={(
                <div
                  style={{ margin: '-5px -12px' }}
                  onClick={() => addProduct(index)}
                  aria-selected="false"
                  className="ant-select-item ant-select-item-option ant-select-item-option-active"
                  title="Add product"
                >
                  <div className="ant-select-item-option-content">Add product</div>
                </div>
              )}
              filterOption={filterOption}
            />
          </div>
          <div className={cls.quantity}>
            <InputNumber
              disabled={!product?.productId || isXml}
              className={cls.input}
              value={product?.qty}
              onChange={qty => onQtyChange(index, qty || 1)}
              defaultValue={1}
              step={1}
            />
          </div>
          <div className={cls.price}>
            <InputNumber
              disabled={!product?.productId || isXml}
              className={cls.input}
              value={product?.productPrice || 0}
              onChange={price => onProductPriceChange(index, price)}
              defaultValue={0}
              min={0}
              step={100}
            />
          </div>
          {!isXml ? (
            <div className={cls.remove} onClick={() => removeProduct(index)}>
              <div className={cls.removeIcon}>
                <svg xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                  fill="none">
                  <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                    fill="#FF4D4F" />
                </svg>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {product?.product?.image ? (
        <div className={cls.productImage}>
          <img src={product?.product.image} alt="" />
        </div>
      ) : (
        <div className={cls.productImageEmpty}>
          no image
        </div>
      )}
    </div>
  );
};

interface CreditInvoiceProductsProps {
  invoice: CreditInvoice;
  composition: OrderCompositionProduct[];
  setComposition: Dispatch<SetStateAction<OrderCompositionProduct[]>>;
  externalShop: string;
  setInvoiceTotal: Dispatch<SetStateAction<number>>;
  isIva: boolean;
  setIsIva: (iva: boolean) => void;
}

export const CreditInvoiceProducts = ({
  invoice, composition, setComposition, externalShop, setInvoiceTotal, isIva, setIsIva,
}: CreditInvoiceProductsProps) => {
  const notify = useNotify();
  const [isProductCreatorActive, setIsProductCreatorActive] = useState<boolean>(false);
  const [productsSuggests, setProductsSuggests] = useState<Product[]>([]);
  const xmlProducts = invoice.xmlProducts || [];
  const isXml = invoice.isXml;

  const addPosition = (position: OrderCompositionProduct) => {
    const addedPosition: OrderCompositionProduct = {
      externalProduct: position.externalProduct,
      id: position?.id || null,
      productId: position?.productId || null,
      qty: position.qty ?? 1,
      productPrice: position.productPrice ?? null,
      isExternal: true,
      xmlName: position.xmlName,
      xmlQty: position.xmlQty,
      xmlPrice: position.xmlPrice,
    };
    if (composition.find(c => c.id === null) && !position.xmlName) {
      notify('Fill the last product info before you add the new one.');
      return;
    }
    setComposition([...composition, addedPosition]);
  };

  const removePosition = (index: number) => {
    const nextComposition = composition.filter((p, i) => i !== index);
    setComposition(nextComposition);
  };

  const addProduct = (index: number) => {
    removePosition(index);
    setIsProductCreatorActive(true);
  };

  const onProductSelect = (
    index: number,
    productId: number | string,
    option: {value: string; label: string},
  ) => {
    if (productId === 'Add product') {
      return addProduct(index);
    }
    if (composition.find(position => position.productId === productId)) {
      return removePosition(index);
    }
    const nextComposition = composition.slice();
    nextComposition[index].id = index;
    nextComposition[index].productId = +productId;
    nextComposition[index].externalProduct = option?.label || '';
    nextComposition[index].product = productsSuggests.find((product) => product.id === +productId);

    if (!isXml) {
      nextComposition[index].productPrice =
        productsSuggests.find((product) => product.id === +productId)?.price || 0;
    }

    setComposition(nextComposition);
  };

  const onQtyChange = (index: number, qty: any) => {
    const nextComposition = composition.slice();
    nextComposition[index].qty = qty;
    setComposition(nextComposition);
  };

  const onProductPriceChange = (index: number, productPrice: any) => {
    const nextComposition = composition.slice();
    nextComposition[index].productPrice = productPrice;
    setComposition(nextComposition);
  };

  const fetchProducts = async () => {
    try {
      const loadedProducts = await productInvoiceHttp.getProducts();
      setProductsSuggests(loadedProducts);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    void fetchProducts();
  }, []);

  const total = composition.reduce((acc, item) => acc + ((item.productPrice || 0) * item.qty), 0);
  const totalIva = isIva ? +(total * 1.16).toFixed(0) : total;

  useEffect(() => {
    setInvoiceTotal(total);
  }, [total]);

  useEffect(() => {
    if (xmlProducts.length > 0 && !composition[0]?.xmlName) {
      setComposition([]);
      for (const xmlProduct of xmlProducts) {
        const [xmlName, xmlQty, xmlPrice] = xmlProduct.split('|');
        const qty = xmlQty ? +parseFloat(xmlQty).toFixed(3) : 1;
        const productPrice = xmlPrice ? +parseFloat(xmlPrice).toFixed(3) : 0;
        addPosition({
          id: null,
          productId: null,
          qty: qty,
          productPrice: productPrice,
          isExternal: true,
          xmlName,
          xmlQty,
          xmlPrice,
        });
      }
    }
  }, [xmlProducts]);

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (isProductCreatorActive) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isProductCreatorActive]);

  const onAddProduct = () => {
    if (isXml) return;
    addPosition({
      id: null,
      productId: null,
      qty: 1,
      productPrice: null,
      isExternal: true,
    });
  };

  return (
    <div className={cls._}>
      <div className={cls.list}>
        {composition.map((product, index) => (
          <CreditInvoiceProduct
            key={product.id}
            product={product}
            index={index}
            productsSuggests={productsSuggests}
            removeProduct={removePosition}
            onProductSelect={onProductSelect}
            setIsProductCreatorActive={setIsProductCreatorActive}
            onQtyChange={onQtyChange}
            onProductPriceChange={onProductPriceChange}
            addProduct={addProduct}
          />
        ))}
        {isProductCreatorActive && (
          <CreditInvoiceProduct
            product={null}
            index={composition.length}
            productsSuggests={productsSuggests}
            removeProduct={removePosition}
            onProductSelect={onProductSelect}
            setIsProductCreatorActive={setIsProductCreatorActive}
            onQtyChange={onQtyChange}
            onProductPriceChange={onProductPriceChange}
            addProduct={addProduct}
          />
        )}
      </div>
      <div className={cls.add}>
        <Button
          variant="contained"
          className={cls.addButton}
          onClick={onAddProduct}
          disabled={isXml}
        >
          Add product
        </Button>
      </div>
      <div className={cls.total}>
        <div className={cls.totalLabel}>Sub-total</div>
        <div className={cls.totalValue}>{moneyFormatter.format(total)}</div>
      </div>
      <div className={clsx(cls.total, cls.totalIva)}>
        <div className={cls.totalLabel}>
          <span>Total + IVA (16%)</span>
          <Checkbox
            checked={isIva}
            onChange={() => setIsIva(!isIva)}
            className={cls.totalCheckbox}
            size="large"
          />
        </div>
        <div className={cls.totalValue}>{moneyFormatter.format(totalIva)}</div>
      </div>

      {isProductCreatorActive && (
        <ProductCreator
          setIsActive={setIsProductCreatorActive}
          addPosition={addPosition}
          fetchProducts={fetchProducts}
          externalShop={externalShop}
        />
      )}
    </div>
  );
};
