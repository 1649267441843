import { useEffect, useState } from 'react';
import { Delivery } from '@types';
import { deliveryHttp } from '@network';
import { DeliveryPositions } from '@pages/delivery/DeliveryPositions';
import { Link } from 'react-admin';

export const DeliveryOfOrder = ({ orderId }: {orderId: number}) => {
  const [deliveries, setDeliveries] = useState<Delivery[]>([]);

  useEffect(() => {
    (async () => {
      const nextDeliveries = await deliveryHttp.findDeliveryOfOrder(orderId);
      setDeliveries(nextDeliveries);
    })();
  }, [orderId]);

  if (deliveries.length === 0) {
    return null;
  }

  return (
    <div style={{ paddingLeft: 20 }}>
      {deliveries.map(delivery => (
        <div key={delivery.id}>
          {delivery.id > 0 ? (
            <>
              <Link to={`/delivery/${delivery.id}`}>
                Shipping #{delivery.id}
              </Link>
              <DeliveryPositions key={delivery.id} delivery={delivery} />
            </>
          ) : null}
        </div>
      ))}
    </div>
  );
};
