import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';
import { RiskInfo } from '@types';
import { DropdownInfoDataProps } from '@pages/credit-risk/analysis/components/DropdownInfoWidget';

interface Props {
  title: string;
  info?: RiskInfo | DropdownInfoDataProps;
  mt?: number;
}

export const Pill = ({ title, info, mt }: Props) => {
  const cls = useStyles();
  const { selected, value } = info || {};
  const selectedLower = `${selected || ''}`.toLowerCase();
  const selectedUpper = `${selected || ''}`.toUpperCase();
  const marginTop = mt || undefined;
  const withExtra = info && 'extra' in info;

  return (
    <div className={cls.box} style={{ marginTop }}>
      <div className={clsx(cls.title, withExtra && cls.titleExtra)}>
        {title}
      </div>
      {withExtra && (
        <div className={cls.extra}>{info.extra || 0}{' '}%</div>
      )}
      <div className={clsx(
        cls.value,
        selectedLower === 'low' && cls.low,
        selectedLower === 'medium' && cls.medium,
        selectedLower === 'high' && cls.high,
      )}>{selectedUpper}</div>
      <div className={cls.percent}>{value || 0}{' '}%</div>
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    border: '1px solid #042E6B',
    overflow: 'hidden',
    width: '100%',
    fontSize: 10,
    lineHeight: 1.1,
    fontWeight: 400,
    fontFamily: 'Outfit, sans-serif',
    color: '#042E6B',
    height: 35,

    '& > div': {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      padding: '2px 4px 4px',
    },
  },
  title: {
    backgroundColor: '#042E6B',
    color: '#FFF',
    lineHeight: 1,
    textAlign: 'center',
  },
  titleExtra: {
    flex: '3 !important',
  },
  extra: {
    borderRight: '1px solid #042E6B',
    flex: '2 !important',
  },
  value: {
    borderRight: '1px solid #042E6B',
  },
  low: {
    color: '#FF7A45',
  },
  medium: {
    color: '#1677FF',
  },
  high: {
    color: '#7CB305',
  },
  percent: {
    color: '#042E6B',
  },
});
