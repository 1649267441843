import { Http } from './http';

class ProductInvoiceHttp extends Http {
  async getProducts() {
    const url = 'product?_start=0&_end=1000';
    const res = await this.get<any[]>(url);

    return res.data;
  }

  async getCategories() {
    const url = 'division?_start=0&_end=1000';
    const res = await this.get<any[]>(url);

    return res.data;
  }

  async getSubCategories() {
    const url = 'product-type?_start=0&_end=1000';
    const res = await this.get<any[]>(url);

    return res.data;
  }

  async getBrands() {
    const url = 'product-brand?_start=0&_end=1000';
    const res = await this.get<any[]>(url);

    return res.data;
  }
}

export const productInvoiceHttp = new ProductInvoiceHttp();
