import { useEffect, useRef, useState } from 'react';
import { FunctionFieldProps, FunctionField, useNotify } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { Input, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { AdminComment } from '@types';
import { dateFormatter } from '@utils';
import { useRequest } from '@hooks';
import { adminHttp } from '@network';
import { Scroll, ConfirmDelete } from '@components';

interface ExtraFields {
  partnerClass: string;
  editable?: boolean;
  comments?: AdminComment[];
}

export const UserCommentsField = (props: Partial<FunctionFieldProps> & ExtraFields) => {
  const { partnerClass, editable, ...restProps } = props;

  return (
    <FunctionField
      sortable={false}
      {...restProps}
      render={(record: any) => (
        <CommentsWidget
          partnerClass={partnerClass}
          partnerId={record?.id || 0}
          comments={props.comments || record[props.source || 'comments']}
          editable={editable}
        />
      )}
    />
  );
};

interface CommentsWidgetProps {
  comments: AdminComment[];
  partnerClass: string;
  partnerId: number;
  editable?: boolean;
}

export const CommentsWidget = (props: CommentsWidgetProps) => {
  const { partnerClass, partnerId, editable } = props;
  const notify = useNotify();
  const [comments, setComments] = useState<AdminComment[]>(props.comments);
  const cls = useStyles();
  const textRef = useRef<any>();
  const [text, setText] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setComments(props.comments);
  }, [props.comments]);

  const showModal = () => {
    setIsModalVisible(true);
    setTimeout(() => void textRef.current?.focus(), 500);
  };
  const hideModal = () => setIsModalVisible(false);

  const { loading, submit } = useRequest(async () => {
    if (!text) {
      textRef.current?.focus();

      return;
    }
    const newComment = await adminHttp.addComment({ comment: text, partnerClass, partnerId });
    setComments([...comments, newComment]);
    setText('');
    hideModal();
    notify('Comment added');
  });

  const { submit: remove } = useRequest(async (id: number) => {
    if (!id) {
      return;
    }
    await adminHttp.removeComment(id);
    setComments(comments.filter(comment => comment.id !== id));
    notify('Comment deleted');
  });

  return (
    <div className={cls.wrap} onClick={e => e.stopPropagation()}>
      <Scroll height={200} paddingRight={25}>
        {Array.isArray(comments) && comments.map(comment => (
          <div className={cls.item} key={comment.id}>
            <div className={cls.comment}>{comment.comment}</div>
            <div className={cls.row}>
              <b>{comment.adminName}</b>
              <span>{dateFormatter.toDateTime(comment.createdAt)}</span>
            </div>
            <IconButton className={cls.deleteBtn}>
              <ConfirmDelete title="Do you really want to delete the comment?"
                cb={() => remove(comment.id)}>
                <svg xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none">
                  <path d="M9 0C4.03021 0 0 4.03021 0 9C0 13.9698 4.03021 18 9 18C13.9698 18 18 13.9698 18 9C17.992 4.03021 13.9658 0.00399822 9 0Z"
                    fill="#002A77" />
                  <path d="M12.3863 5.60962C12.2303 5.45369 11.9785 5.45369 11.8225 5.60962L8.99978 8.43237L6.17704 5.60962C6.0211 5.45369 5.76922 5.45369 5.61329 5.60962C5.45736 5.76555 5.45736 6.01744 5.61329 6.17337L8.43603 8.99612L5.61329 11.8189C5.45736 11.9748 5.45736 12.2267 5.61329 12.3826C5.76922 12.5385 6.0211 12.5385 6.17704 12.3826L8.99978 9.55987L11.8225 12.3826C11.9785 12.5385 12.2303 12.5385 12.3863 12.3826C12.5422 12.2267 12.5422 11.9748 12.3863 11.8189L9.56353 8.99612L12.3863 6.17337C12.5422 6.01744 12.5422 5.76555 12.3863 5.60962Z"
                    fill="white" />
                </svg>
              </ConfirmDelete>
            </IconButton>
          </div>
        ))}
      </Scroll>
      {editable ? (
        <div className={cls.plusRow}>
          <Button
            className={cls.plusBtn}
            onClick={showModal}
            variant="contained"
            color="secondary"
          >
            <PlusCircleOutlined /> Admin Notes
          </Button>
        </div>
      ) : null}
      <Modal
        open={isModalVisible}
        title={'Add note'}
        okText={'Save'}
        onOk={submit}
        confirmLoading={loading}
        onCancel={hideModal}
      >
        <Input.TextArea
          ref={textRef}
          value={text}
          onChange={e => setText(e.target.value)}
          autoFocus
        />
      </Modal>
    </div>
  );
};

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -15,
    fontFamily: 'Outfil, sans-serif',
  },
  deleteBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  item: {
    position: 'relative',
    width: '100%',
    marginLeft: 15,
    marginBottom: 10,
  },
  comment: {
    borderRadius: 8,
    border: '1px solid #042E6B',
    fontSize: 13,
    lineHeight: 1.05,
    padding: '12px 20px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 5px 0',
    fontSize: 12,
  },
  plusRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  plusBtn: {
    marginLeft: 15,
    textTransform: 'none',
    '& svg': {
      marginRight: 10,
    },
  },
  closeBtn: {
    position: 'absolute',
    top: -8,
    right: -8,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  closeBtnIcon: {
    fontSize: 18,
    color: '#f5222d',
  },
});
