import { clsx } from 'clsx';
import React, { useEffect, useState } from 'react';
import { Input, Checkbox, Radio, RadioChangeEvent, InputNumber, Select } from 'antd';

import { moneyFormatter } from '@utils';
import { AnswerCol, AnswerRow, SurveyAnswer, SurveyOptionProps, SurveyQuestion } from '@types';
import cls from './credit-survey-detail.module.css';

const { TextArea } = Input;

interface CreditSurveyAnswerProps {
  question: SurveyQuestion;
  setQuestions: React.Dispatch<React.SetStateAction<SurveyQuestion[]>>;
  isValid: boolean;
  isFixedSurvey: boolean;
}

interface TableRowProps {
  header: SurveyAnswer;
  item: AnswerCol;
  onChange: (val: string) => void;
  isFixedSurvey: boolean;
}

interface TableFieldProps {
  item: AnswerCol;
  value: number | string;
  options: SurveyOptionProps[];
  onChange: (value: string) => void;
  onBlur: () => void;
  disabled?: boolean;
}

const NumberField = ({
  value, item, onChange, onBlur, disabled,
}: Omit<TableFieldProps, 'options'>) => {
  const handleChange = (e: number | null) => {
    onChange(e === null ? '' : e.toString());
  };

  return typeof value === 'number' ? (
    <div className={cls.tableFieldWrap}>
      <InputNumber
        min={0}
        max={999999999999}
        formatter={item?.answerType === 'money'
          ? (value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={item?.answerType === 'money'
          ? (value) => value ? +value.replace(/\$\s?|(,*)/g, '') : 0
          : (value) => value ? +value.replace(/\$\s?|(,*)/g, '') : 0}
        defaultValue={value}
        bordered={false}
        disabled={disabled}
        onChange={disabled ? undefined : handleChange}
        className={clsx(cls.tableField, cls.numberField)}
        onBlur={onBlur}
        autoFocus
      />
    </div>
  ) : <></>;
};

const OptionField = ({
  value, options, onChange, onBlur, disabled,
}: Omit<TableFieldProps, 'item'>) => {
  const handleChange = (val: string) => {
    onChange(val);
  };

  return typeof value === 'string' ? (
    <div className={cls.tableFieldWrap}>
      <Select
        placeholder="Borderless"
        className={clsx(cls.tableField, cls.optionField)}
        onChange={disabled ? undefined : handleChange}
        value={value}
        onBlur={onBlur}
        autoFocus
        options={options.map((o) => ({
          value: o.nameMx, label: o.nameMx,
        }))}
        disabled={disabled}
      />
    </div>
  ) : <></>;
};

const TextField = ({
  value, onChange, onBlur, disabled,
}: Omit<TableFieldProps, 'options' | 'item'>) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={cls.tableFieldWrap}>
      <TextArea
        onChange={disabled ? undefined : handleChange}
        value={value === null ? '' : value}
        defaultValue=""
        autoSize={true}
        className={clsx(cls.textField)}
        onBlur={onBlur}
        maxLength={100}
        autoFocus
        disabled={disabled}
      />
    </div>
  );
};

const TableRow = ({ header, item, onChange, isFixedSurvey }: TableRowProps) => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(item?.customAnswer);

  const handleClose = () => {
    setActive(false);
  };

  const handleChange = (e: string | null) => {
    onChange(e || '');
  };

  const showTableValue = (val: string): string => {
    if (item?.answerType === 'money') return moneyFormatter.format(+val);
    if (item?.answerType === 'number') {
      return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return val === null ? '' : val;
  };

  useEffect(() => {
    setValue(item?.customAnswer);
  }, [item]);

  return (
    <div className={cls.row}>
      <div className={cls.col}>{header ? `${header.nameMx}${item.isRequired ? '*' : ''}` : ''}</div>
      <div
        className={clsx(cls.col, cls.break, !active && cls.cursorText)}
        onClick={() => setActive(true)}
      >
        {!active && value !== undefined ? showTableValue(value === null ? '' : value) : null}
        {active && (item?.answerType === 'money' || item?.answerType === 'number') ? (
          <NumberField
            value={item.customAnswer ? +item.customAnswer : 0}
            item={item}
            onChange={handleChange}
            onBlur={handleClose}
            disabled={isFixedSurvey}
          />
        ) : null}
        {active && (item?.answerType === 'text') ? (
          <TextField
            value={item.customAnswer || ''}
            onChange={handleChange}
            onBlur={handleClose}
            disabled={isFixedSurvey}
          />
        ) : null}
        {active && (item?.answerType === 'percent') ? (
          <TextField
            value={item.customAnswer || ''}
            onChange={handleChange}
            onBlur={handleClose}
            disabled={isFixedSurvey}
          />
        ) : null}
        {active && (item?.answerType === 'option') ? (
          <OptionField
            value={item.customAnswer || ''}
            options={header.options}
            onChange={handleChange}
            onBlur={handleClose}
            disabled={isFixedSurvey}
          />
        ) : null}
      </div>
    </div>
  );
};

export const CreditSurveyAnswer = ({
  question, setQuestions, isFixedSurvey,
}: CreditSurveyAnswerProps) => {
  const withCustomAnswer = question.withCustomAnswer || question.isMoney || question.isNumber;
  const withCheckAnswer = !question.withCustomAnswer && !question.isTable && !question.isPlanner;
  const withTableAnswer = question.isTable;
  const withPlannerAnswer = question.isPlanner;

  const onCheckRadio = (e: RadioChangeEvent) => {
    setQuestions((list: SurveyQuestion[]) =>
      list.map((q: SurveyQuestion) => q.id !== question.id ? q : ({
        ...q,
        answerIds: [e.target.value],
      })),
    );
  };

  const onCheckBox = (id: number) => {
    setQuestions((list: SurveyQuestion[]) =>
      list.map((q: SurveyQuestion) => q.id !== question.id ? q : ({
        ...q,
        answerIds: q.answerIds.includes(id)
          ? q.answerIds.filter(item => item !== id) : [...q.answerIds, id],
      })),
    );
  };

  const onChangeCustomField = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestions((list: SurveyQuestion[]) =>
      list.map((q: SurveyQuestion) => q.id !== question.id ? q : ({
        ...q,
        customAnswer: e.target.value,
      })),
    );
  };

  const onChangeTable = (val: string, row: number, answerId: number) => {
    setQuestions((list: SurveyQuestion[]) =>
      list.map((q: SurveyQuestion) => {
        return q.id !== question.id ? q : ({
          ...q,
          rows: q.rows.map((r: AnswerRow) => {
            return r.tableRow !== row ? r : ({
              ...r,
              cols: r.cols.map((c: AnswerCol) => {
                if (c.answerId !== answerId) return c;
                return {
                  ...c,
                  customAnswer: val,
                };
              }),
            });
          }),
        });
      }),
    );
  };

  const addRow = () => {
    const tableRow = !question.rows.length
      ? 0
      : Math.max(...question.rows.map(r => r.tableRow)) + 1;
    const newRow: AnswerRow = {
      tableRow,
      cols: question.answers.map((a: SurveyAnswer): AnswerCol => ({
        answerId: a.id,
        answerType: a.type,
        customAnswer: '',
        isRequired: a.isRequired,
        nameMx: a.nameMx,
        position: a.position,
        isEnabled: a.isEnabled,
      })),
    };
    setQuestions((list: SurveyQuestion[]) =>
      list.map((q: SurveyQuestion) => q.id !== question.id ? q : ({
        ...q,
        rows: [...q.rows, newRow],
      })),
    );
  };

  const removeRow = (index: number) => {
    setQuestions((list: SurveyQuestion[]) =>
      list.map((q: SurveyQuestion) => q.id !== question.id ? q : ({
        ...q,
        rows: q.rows.filter((r: AnswerRow) => index !== r.tableRow),
      })),
    );
  };

  return (
    <div className={cls.wrap}>
      {withCustomAnswer && (
        <TextArea
          onChange={onChangeCustomField}
          value={question.customAnswer || ''}
          defaultValue=""
          autoSize={true}
          className={clsx(cls.answerCustomField)}
          maxLength={100}
          disabled={isFixedSurvey}
        />
      )}

      {withPlannerAnswer && (
        <div className={cls.tableWrap}>
          <>
            {(question.rows || []).map((row: AnswerRow, index) => {
              if (!Array.isArray(row.cols) || row.cols.length === 0 || index > 0) {
                return null;
              }

              return (
                <div key={index} className={clsx(
                  cls.answerContent,
                  cls.answerTable,
                  cls.fullWidth,
                )}>
                  {row.cols.map((col: AnswerCol, idx) => {
                    const header = question.answers.find(a => a.id === col.answerId);

                    return header ? (
                      <TableRow
                        header={header}
                        item={col}
                        key={idx}
                        onChange={(val: string) => onChangeTable(val, row.tableRow, header.id)}
                        isFixedSurvey={isFixedSurvey}
                      />
                    ) : null;
                  })}
                </div>
              );
            })}

            {question.rows.length === 0 && addRow()}
          </>
        </div>
      )}

      {withCheckAnswer && (
        question.isMultiple ? (
          <div className={clsx(cls.answerContent, cls.answerCheckboxes)}>
            {question.answers.map(answer => (
              <Checkbox
                onChange={() => onCheckBox(answer.id)}
                key={answer.id}
                checked={question.answerIds.includes(answer.id)}
                className={cls.checkbox}
                disabled={isFixedSurvey}
              >{answer.nameMx}</Checkbox>
            ))}
          </div>
        ) : (
          <Radio.Group
            onChange={onCheckRadio}
            value={question.answerIds[0]}
            className={cls.answerRadioGroup}
            disabled={isFixedSurvey}
          >
            {question.answers.map(answer => (
              <Radio
                value={answer.id}
                key={answer.id}
                className={cls.radio}
                disabled={isFixedSurvey}
              >{answer.nameMx}</Radio>
            ))}
          </Radio.Group>
        )
      )}

      {withTableAnswer && (
        <div className={cls.tableWrap}>
          <>
            {(question.rows || []).map((row: AnswerRow, index) => {
              if (!Array.isArray(row.cols) || row.cols.length === 0) {
                return null;
              }
              const showAddButton = question.rows.length
                    && question.rows.length < 10
                    && question.rows.length === (index + 1)
                    && !isFixedSurvey;
              const showRemoveButton = question.rows.length > 1 && !isFixedSurvey;
              return (
                <div key={index} className={clsx(
                  cls.answerContent,
                  cls.answerTable,
                  cls.fullWidth,
                )}>
                  {row.cols.map((col: AnswerCol, idx) => {
                    const header = question.answers.find(a => a.id === col.answerId);

                    return header ? (
                      <TableRow
                        header={header}
                        item={col}
                        key={idx}
                        onChange={(val: string) => {
                          console.log('col', col);
                          console.log('val', val);
                          console.log('row', row);
                          console.log('header.id', header.id);
                          onChangeTable(val, row.tableRow, header.id);
                        }}
                        isFixedSurvey={isFixedSurvey}
                      />
                    ) : null;
                  })}
                  {showAddButton && (
                    <div className={cls.addRow} onClick={addRow}>+</div>
                  )}
                  {showRemoveButton && (
                    <div className={cls.removeRow} onClick={() => removeRow(row.tableRow)}>-</div>
                  )}
                </div>
              );
            })}

            {question.rows.length === 0 && addRow()}
          </>
        </div>
      )}
    </div>
  );
};
