import { FunctionField, FunctionFieldProps } from 'react-admin';
import Chip from '@mui/material/Chip';

import { Credit, CreditStatus } from '@types';

interface Props {
  className?: string;
}

export const RiskScoreField = (props: Props & Partial<FunctionFieldProps>) => {
  const { className, ...restProps } = props;

  return (
    <FunctionField {...restProps} render={(record: Credit) => {
      const score = record['riskScore'] ?? null;

      if (score === null) {
        return null;
      }

      return (
        <Chip
          label={`${score} %`}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#042E6B',
            color: 'white',
            height: 20,
            width: 80,
            margin: '0 auto',
          }}
          className={className}
        />
      );
    }} />
  );
};
