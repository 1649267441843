import { EditProps, Edit, SimpleForm } from 'react-admin';
import { Container, Row, Col, TextInput, Forbidden } from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Country: {record.name}</span>) : null;

export const CountryEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <SimpleForm toolbar={rbacEdit ? undefined : false}>
        <Container>
          <Row>
            <Col><TextInput source="id" disabled /></Col>
            <Col><TextInput source="name" required /></Col>
            <Col><TextInput source="iso3" /></Col>
            <Col><TextInput source="iso2" /></Col>
          </Row>
          <Row>
            <Col><TextInput source="phoneCode" /></Col>
            <Col><TextInput source="capital" /></Col>
            <Col><TextInput source="currency" /></Col>
            <Col><TextInput source="currencySymbol" /></Col>
          </Row>
          <Row>
            <Col><TextInput source="tld" /></Col>
            <Col><TextInput source="native" /></Col>
            <Col><TextInput source="region" /></Col>
            <Col><TextInput source="subregion" /></Col>
          </Row>
          <Row>
            <Col><TextInput source="latitude" /></Col>
            <Col><TextInput source="longitude" /></Col>
            <Col><TextInput source="emoji" /></Col>
            <Col><TextInput source="emojiU" /></Col>
          </Row>
        </Container>
      </SimpleForm>
    </Edit>
  );
};
