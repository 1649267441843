import { Http } from './http';
import { Product } from '@types';

class ProductHttp extends Http {
  prefix = 'product';

  async getList(): Promise<Product[]> {
    const res = await this.get<Product[]>();

    return res.data;
  }

  async getListWithoutLimit(): Promise<Product[]> {
    const res = await this.get<Product[]>('/all');

    return res.data;
  }

  async update(productId: number, values: {[key: string]: any}) {
    await this.put(`/${productId}`, values);
  }

  async create(params: CreateProductParams): Promise<Product> {
    const res = await this.post<Product>('/external', params);

    return res.data;
  }
}

export const productHttp = new ProductHttp();

export interface CreateProductParams {
  name: string;
  brandId: number;
  categoryId: number;
  subcategoryId: number;
  externalShop: string;
  description?: string | null;
  pdfLink?: string | null;
  image?: string;
}
