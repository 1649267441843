import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { creditHttp } from '@network';
import { Credit, Doctype } from '@types';
import { CreditWidget } from './credit-widget';

interface Props {
  credit: Credit;
  isSuperadmin: boolean;
  editable: boolean;
  isCompany: boolean;
}

export const CreditDocuments = (props: Props) => {
  const [credit, setCredit] = useState<Credit>(props.credit);
  const [doctypes, setDoctypes] = useState<Doctype[]>([]);
  const location = useLocation();

  useEffect(() => {
    credit && (async () => {
      const nextCreditReq = creditHttp.getCredit(credit.id);
      const doctypesReq = creditHttp.getCreditDoctypes(credit.id);
      const [nextCredit, doctypes] = await Promise.all([nextCreditReq, doctypesReq]);
      setCredit(nextCredit);
      setDoctypes(doctypes.doctypes);
    })();
  }, [location]);

  useEffect(() => {
    credit && (async () => {
      const res = await creditHttp.getCreditDoctypes(credit.id);
      setDoctypes(res.doctypes);
    })();
  }, [credit]);

  return (
    <>
      <CreditWidget
        credit={credit}
        setCredit={setCredit}
        doctypes={doctypes}
        isSuperadmin={props.isSuperadmin}
        editable={props.editable}
      />
    </>
  );
};
