import { useRecordContext } from 'react-admin';
import { Manager } from '@types';
import { DateTimeInput } from '@components';

export const ManagerCompanyCreated = () => {
  const manager = useRecordContext<Manager>();

  return (
    <>
      {manager && manager.dealer && (
        <DateTimeInput source="dealer.createdAt" label={false} disabled />
      )}
      {manager && !manager.dealer && manager.associate && (
        <DateTimeInput source="associate.createdAt" label={false} disabled />
      )}
    </>
  );
};
