import { FunctionFieldProps, FunctionField } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

import { resolvePath } from './resolve-path';

interface ExtraProps {
  text: string;
  center?: boolean;
}

export const ButtonLinkField = (props: Partial<FunctionFieldProps> & ExtraProps) => {
  const cls = useStyles();
  const { text, center, ...restProps } = props;

  return (
    <FunctionField
      {...restProps}
      className={clsx(props.className, center && cls.center)}
      render={(record: any) => (
        <ButtonLinkWidget
          link={resolvePath(record, props.source || '')}
          text={text}
          className={props.className}
        />
      )}
    />
  );
};

interface ButtonLinkProps {
  link?: string | null;
  text: string;
  className?: string;
}

export const ButtonLinkWidget = ({ link, text, className }: ButtonLinkProps) => {
  const classes = useStyles();
  if (!link) {
    return null;
  }

  return (
    <a
      href={link}
      className={clsx(classes.link, className)}
      onClick={e => e.stopPropagation()}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  );
};

const useStyles = makeStyles({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    display: 'inline-block',
    margin: '0 auto',
    padding: '4px 15px',
    borderRadius: 5,
    background: '#FD8A32',
    textDecoration: 'none',
    color: 'white',
    fontSize: 11,
    lineHeight: 1.1,

    '&:hover': {
      textDecoration: 'none',
      color: 'white',
      background: '#ef7415',
    },
  },
});
