import { clsx } from 'clsx';
import { Button, InputNumber } from 'antd';
import { useEffect, useState } from 'react';

import cls from './CreditBureau.module.css';

export type CreditBureauKeys = 'name' | 'bsScore' | 'currentDebt' | 'score';

interface HeaderProps {
  key: CreditBureauKeys;
  label: string;
}

interface ValueProps {
  name: string;
  label: string;
  bsScore: number;
  currentDebt: number;
}

export interface CreditBureauData {
  header: HeaderProps[];
  values: ValueProps[];
  score: number;
}

interface Props {
  data: CreditBureauData;
  calcData: CreditBureauData;
  onChange: (value: CreditBureauData) => void;
}

interface TitleProps {
  row: ValueProps;
  cb: (name: string) => void;
}

const Title = ({ row, cb }: TitleProps) => {
  const fixed = !row.name.includes('guarantor_') || row.name === 'guarantor_1';

  return (
    <span className={cls.titleRow}>
      <span className={cls.titleLabel}>{row.label}</span>
      {!fixed ? <span className={cls.titleRemove} onClick={() => cb(row.name)}>+</span> : null}
    </span>
  );
};

export const CreditBureau = ({ data, calcData, onChange }: Props) => {
  const [values, setValues] = useState<ValueProps[]>(data?.values || []);

  const guarantorsCount = (): number => values.filter(r => r.name.includes('guarantor')).length;

  const maxGuarantorNumber = (): number => {
    const list: number[] = values
      .filter(r => r.name.includes('guarantor'))
      .map(r => +r.name.split('_')[1]);
    // @ts-ignore
    const uniqList = [...new Set(list)];
    return Math.max(...uniqList);
  };

  const add = () => {
    const count = guarantorsCount();
    if (count < 5) {
      const id = maxGuarantorNumber();

      const newRow: ValueProps = {
        name: `guarantor_${id + 1}`,
        label: `Guarantor #${id + 1}`,
        bsScore: 0,
        currentDebt: 0,
      };
      setValues([...values, newRow]);
    }
  };

  const changeField = (val: number | null, name: string, field: 'bsScore' | 'currentDebt'): void => {
    const res = val || 0;
    const newValues = values
      .map(v => (v.name === name) ? { ...v, [field]: res } : v);
    setValues(newValues);
  };

  const remove = (name: string): void => {
    const list = values.filter(f => f.name !== name);
    setValues(list);
  };

  useEffect(() => {
    values.length && onChange({ ...data, values });
  }, [values]);

  if (!values.length) {
    return null;
  }

  return (
    <div className={cls.block}>
      <div className={cls.table}>
        <div className={cls.header}>
          <div className={cls.row}>
            {data.header.map((r, idx) => (
              <div className={cls.col} key={idx}>
                <span className={cls.headerTitle}>{r.label}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={cls.body}>
          <div className={cls.bodyRow}>
            <div className={cls.bodyCol}>
              {values.map((r, idx) => (
                <div className={cls.row} key={idx}>
                  <div className={cls.col}>
                    <Title row={r} cb={remove} />
                  </div>
                  <div className={clsx(cls.col, cls.colored)}>
                    <span className={cls.inputWrap}>
                      <InputNumber
                        min={0}
                        max={1000000000}
                        value={r.bsScore}
                        bordered={false}
                        onChange={e => changeField(e, r.name, 'bsScore')}
                        className={cls.input}
                      />
                    </span>
                  </div>
                  <div className={clsx(cls.col, cls.colored)}>
                    <span className={cls.inputWrap}>
                      <InputNumber
                        min={0}
                        max={1000000000}
                        formatter={(value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value ? +value.replace(/\$\s?|(,*)/g, '') : 0}
                        value={r.currentDebt}
                        bordered={false}
                        onChange={e => changeField(e, r.name, 'currentDebt')}
                        className={cls.input}
                      />
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className={cls.bodyCol}>{calcData.score || 0}%</div>
          </div>
        </div>
        <div className={cls.footer}>
          <Button className={cls.btn} onClick={add} disabled={guarantorsCount() >= 5}>+Add</Button>
        </div>
      </div>
    </div>
  );
};
