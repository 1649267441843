import { clsx } from 'clsx';
import { Slider } from 'antd';
import { SaveButton } from 'react-admin';
import { SliderMarks } from 'antd/es/slider';

import { ConfirmDelete, OrangeOutlineButton } from '@components';
import cls from './Score.module.css';

interface Props {
  defaultValue: number;
  value: number;
  setScore: (value: number) => void;
  onCancel: () => void;
  onHide: () => void;
  readonly?: boolean;
}

export const ScoreEdit = ({
  value, defaultValue, setScore, onCancel, onHide, readonly,
}: Props) => {
  const markStyle = {
    fontSize: 16,
    fontWeight: 500,
    paddingTop: 20,
  };

  const marksGen = (): SliderMarks => {
    const marks: SliderMarks = {};
    for (let i = 0; i <= 100; i += 5) {
      marks[i] = {
        label: i % 10 === 0 ? `${i}%` : ' ',
        style: markStyle,
      };
    }

    return marks;
  };

  return (
    <div className={cls.editWrap}>
      <div className={cls.header}>Score Approval Parameters</div>
      <div className={cls.body}>
        <div className={cls.grid}>
          <div className={clsx(cls.gridCol, cls.gridStart)}>Denied</div>
          <div className={clsx(cls.gridCol, cls.gridSlider)}>
            <Slider
              step={5}
              defaultValue={defaultValue}
              value={value}
              dots
              marks={marksGen()}
              onChange={setScore}
              disabled={readonly}
            />
          </div>
          <div className={clsx(cls.gridCol, cls.gridEnd)}>Approved</div>
        </div>
      </div>
      <div className={cls.actions}>
        <SaveButton
          className={clsx(cls.action, cls.saveAction)}
          color="secondary"
          label="Save"
          type="button"
          onClick={onHide}
          disabled={readonly}
        />
        <ConfirmDelete
          title="Are you sure?"
          cb={onCancel}
          overlayClassName={cls.overlayClassName}
          placement="bottom"
          disabled={value === defaultValue}
        >
          <OrangeOutlineButton
            className={cls.action}
            onClick={value === defaultValue ? onCancel : undefined}
          >Cancel</OrangeOutlineButton>
        </ConfirmDelete>
      </div>
    </div>
  );
};
