import { SaveButton } from 'react-admin';
import { useSwiper } from 'swiper/react';
import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, Col, Input, InputNumber, Row } from 'antd';

import { showAxiosError } from '@utils';
import { B2BFormProps } from '../CreditB2B';
import { useRbacEdit, useRequest } from '@hooks';
import { Credit, CreditCrop, Crop } from '@types';
import { CreditInsurances } from '../../CreditInsurances';
import { AntdDateInput, ConfirmDelete, InitialRow } from '@components';
import cls from './CropForm.module.css';

interface OptionsProps {
  value: string;
}

interface CropProps {
  crop: CreditCrop | B2BFormProps;
  crops: Crop[];
  onFormSave: (form: CreditCrop | B2BFormProps) => void;
  onFormDelete: (key: number) => void;
  onFormAdd: () => void;
  canAdd: boolean;
  canDelete: boolean;
  credit: Credit;
}

export const CropForm = (
  { crop, crops, onFormSave, onFormDelete, onFormAdd, canAdd, canDelete, credit }: CropProps,
) => {
  const swiper = useSwiper();
  const [options, setOptions] = useState<OptionsProps[]>([]);
  const [selectedCrop, setSelectedCrop] = useState<string>('');
  const [form, setForm] = useState<CreditCrop | B2BFormProps>(crop);
  const rbacEdit = useRbacEdit();

  const { submit } = useRequest(async () => {
    try {
      onFormSave(form);
    } catch (err: any) {
      showAxiosError(err);
    }
  });

  const onSearch = (q: string) => {
    setOptions(crops
      .filter((c: Crop) => c.nameMx.toLowerCase().includes(q.toLowerCase()))
      .map(c => ({ value: c.nameMx })));
    setSelectedCrop(q);
  };

  const onSelect = (val: string) => {
    const current = crops.find(c => c.nameMx === val);
    if (current) {
      setSelectedCrop(val);
      setForm({ ...form, financedCropId: current.id });
    }
    setOptions(crops.map((c: Crop) => ({ value: c.nameMx })));
  };

  const onChangeNumber = (name: string, e: any) => {
    setForm({ ...form, [name]: e });
  };

  const onChangeText = (name: string, e: any) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const onAddSlide = () => {
    onFormAdd();
    setTimeout(() => {
      const len = swiper.slides.length;
      swiper.slideTo(len);
    }, 300);
  };

  useEffect(() => {
    if (crops.length) {
      setOptions(crops.map((c: Crop) => ({ value: c.nameMx })));
      const current = crops.find(c => c.id === crop.financedCropId)?.nameMx || '';
      setSelectedCrop(current);
    }
  }, [crops]);

  useEffect(() => {
    submit();
  }, [form]);

  return (
    <div className={cls.form}>
      <InitialRow title="Financed crops:">
        <AutoComplete
          options={options}
          onSelect={onSelect}
          onSearch={onSearch}
          placeholder="Select Crops"
          className={cls.formSelect}
          value={selectedCrop}
        />
      </InitialRow>
      <Row gutter={[40, 0]}>
        <Col xs={24} sm={12}>
          <InitialRow title="Financed Ha:" labelSize={12}>
            <InputNumber
              min={0}
              max={1000000}
              className={cls.formInput}
              controls={false}
              value={form?.financedHa || 0}
              onChange={(e) => onChangeNumber('financedHa', e)}
            />
          </InitialRow>
        </Col>
        <Col xs={24} sm={12}>
          <InitialRow title="Expected tons:" labelSize={12}>
            <InputNumber
              min={0}
              max={1000000}
              className={cls.formInput}
              controls={false}
              value={form?.expectedWeight || 0}
              onChange={(e) => onChangeNumber('expectedWeight', e)}
            />
          </InitialRow>
        </Col>
      </Row>
      <div className={cls.subtitle}>Insurance</div>
      <InitialRow title="Insurance Company:">
        <Input
          placeholder="Company name"
          className={cls.formInput}
          value={form?.insuranceCompany || ''}
          onChange={(e) => onChangeText('insuranceCompany', e)}
        />
      </InitialRow>
      <InitialRow title="Credit insurance documents:" fullWidth>
        <CreditInsurances credit={credit} form={form} setForm={setForm} />
      </InitialRow>
      <Row gutter={[40, 0]}>
        <Col xs={24} sm={12}>
          <InitialRow title="Signature at:">
            <AntdDateInput label="" source="signatureAt" style={{ width: 80 }} />
          </InitialRow>
        </Col>
        <Col xs={24} sm={12}>
          <InitialRow title="Payment at:">
            <AntdDateInput label="" source="paymentAt" style={{ width: 80 }} />
          </InitialRow>
        </Col>
      </Row>
      <InitialRow title="Tons delivered:">
        <InputNumber
          min={0}
          max={1000000}
          className={cls.formInput}
          controls={false}
          value={form?.deliveredWeight || 0}
          onChange={(e) => onChangeNumber('deliveredWeight', e)}
        />
      </InitialRow>

      {rbacEdit ? (
        <div className={cls.actions}>
          <div className={cls.actionsGroup}>
            <div className={cls.action}>
              <SaveButton
                label="Save"
                type="button"
                disabled={!form.financedCropId ? true : undefined}
                icon={<></>}
                className={cls.submitBtn}
              />
            </div>
            <div className={cls.action}>
              <Button
                type="primary"
                onClick={onAddSlide}
                className={cls.addBtn}
                disabled={!canAdd}
              >Add</Button>
            </div>
          </div>
          <div className={cls.actionsGroup}>
            <div className={cls.action}>
              <ConfirmDelete
                title="Are you sure?"
                cb={() => onFormDelete(form.key)}
                disabled={!canDelete}
              >
                <Button
                  type="primary"
                  className={cls.removeBtn}
                  disabled={!canDelete}
                  icon={
                    <svg xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="15"
                      viewBox="0 0 12 15"
                      fill="none">
                      <path d="M8.32511 1.90625C8.35111 2.01427 8.46113 2.10028 8.57314 2.10028H10.7034C11.0895 2.10028 11.4035 2.41432 11.4035 2.80037C11.4035 3.12042 11.1895 3.38845 10.8975 3.47246L10.5314 13.5258C10.5014 14.3439 9.82131 15 9.0012 15H2.40232C1.58421 15 0.90212 14.3419 0.872116 13.5258L0.506067 3.47246C0.214029 3.38845 0 3.11842 0 2.80037C0 2.41432 0.314042 2.10028 0.700093 2.10028H2.83038C2.94039 2.10028 3.05041 2.01227 3.07841 1.90625L3.22843 1.30617C3.41445 0.566076 4.13855 0 4.90065 0H6.50287C7.26497 0 7.99107 0.566076 8.17509 1.30617L8.32511 1.90625ZM5.20069 5.30071V12.1016C5.20069 12.3777 5.42472 12.6017 5.70076 12.6017C5.9768 12.6017 6.20083 12.3777 6.20083 12.1016V5.30071C6.20083 5.02467 5.9768 4.80064 5.70076 4.80064C5.42472 4.80064 5.20069 5.02467 5.20069 5.30071ZM2.90039 5.31471L3.10041 12.1156C3.10841 12.3917 3.33844 12.6097 3.61448 12.6017C3.89052 12.5937 4.10855 12.3636 4.10055 12.0876L3.90052 5.28671C3.89252 5.01067 3.66249 4.79264 3.38645 4.80064C3.11041 4.80864 2.89239 5.03867 2.90039 5.31471ZM7.501 5.2847L7.30097 12.0856C7.29297 12.3616 7.511 12.5917 7.78704 12.5997C8.06308 12.6077 8.29311 12.3897 8.30111 12.1136L8.50113 5.31271C8.50913 5.03667 8.29111 4.80664 8.01507 4.79864C7.73903 4.79064 7.509 5.00867 7.501 5.2847ZM4.57061 2.09828H6.82891C6.88292 2.09828 6.91692 2.05427 6.90492 2.00227L6.81491 1.64422C6.7849 1.5262 6.62088 1.39819 6.50087 1.39819H4.89865C4.77864 1.39819 4.61461 1.5262 4.58461 1.64422L4.4946 2.00227C4.4806 2.05627 4.5146 2.09828 4.57061 2.09828Z"
                        fill="white" />
                    </svg>
                  }
                >Delete</Button>
              </ConfirmDelete>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
