import { Button, ButtonProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

export const BlueOutlineButton = (props: ButtonProps) => {
  const cls = useStyles();
  const isSmall = props.size === 'small';

  return (
    <Button className={clsx(cls.btn, isSmall && cls.isSmall)}
      variant="outlined"
      color="secondary"
      {...props}
    >
      {props.children}
    </Button>
  );
};


const useStyles = makeStyles({
  btn: {
    padding: '16px 22px',
    height: 50,
    borderRadius: 8,
    textTransform: 'none',
    color: '#042E6B !important',
    borderColor: '#042E6B',
    fontWeight: 400,
    fontFamily: 'Outfit, sans-serif;',
    fontSize: 14,

    '& svg': {
      display: 'inline-block',
      marginRight: 8,
    },
  },
  isSmall: {
    height: 37,
    padding: '8px 12px',
    '& svg': {
      fontSize: 18,
    },
  },
});
