import { DataProvider, fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.credentials = 'include';

  return fetchUtils.fetchJson(url, options);
};

function makeDataProvider(): DataProvider {
  const API_URL = (window as any).env?.API_URL
    ? (window as any).env.API_URL
    : process.env.REACT_APP_API_URL;
  const dataProvider = jsonServerProvider(API_URL, httpClient);

  return {
    ...dataProvider,
    create: async (resource: any, params: any) => {
      return dataProvider.create(resource, await transformParams(params));
    },
    update: async (resource: any, params: any) => {
      return dataProvider.update(resource, await transformParams(params));
    },
  };
}

export const customDataProvider = makeDataProvider();

const transformParams = async (params: any) => {
  if (params?.data?.image?.rawFile instanceof File) {
    const image = await convertFileToBase64(params.data.image.rawFile);

    return { ...params, data: { ...params.data, image } };
  }
  if (params?.data?.adminUrl?.rawFile instanceof File) {
    const adminUrl = await convertFileToBase64(params.data.adminUrl.rawFile);

    return { ...params, data: { ...params.data, adminUrl } };
  }

  return params;
};

const convertFileToBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
  reader.readAsDataURL(file);
});
