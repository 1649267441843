import { Http } from './http';
import {
  FinanceActivationStatus,
  FinanceCostCategory,
  FinanceCostType,
  FinanceCredit,
  FinanceOriginOption,
} from '@types';

class FinanceSettingsHttp extends Http {

  async calculateCreditAmounts(params: Record<string, number>) {
    const res = await this.post<FinanceCredit>('/finance-credit/calculate-amounts', params);

    return res.data;
  }

  async getSettings() {
    const res = await this.get<FinanceSettingsData>('/finance-settings');

    return res.data;
  }

  async getActivationById(id: number) {
    const res = await this.get<FinanceActivation>(`/finance-activation/${id}`);

    return res.data;
  }

  async getActivations() {
    const res = await this.get<FinanceActivation[]>('/finance-activation', {
      params: { _order: 'ASC', _sort: 'activation', _start: 0, _end: 9999999 },
    });
    const activations = (res.data || [])
      .sort((a, b) => a.activation.toLowerCase().localeCompare(b.activation.toLowerCase()));

    return activations;
  }

  async getActivationOptions() {
    const res = await this.get<FinanceActivationOptions>('/finance-activation/options');

    return res.data;
  }

  async getCostOptions() {
    const res = await this.get<FinanceCostOptions>('/finance-cost/options');

    return res.data;
  }

  async getCreditOptions() {
    const res = await this.get<FinanceCreditOptions>('/finance-credit/options');

    return res.data;
  }
}

export const financeSettingsHttp = new FinanceSettingsHttp();

export interface FinanceSettingsData {
  costCategories: FinanceCostCategory[];
  costCategoryNextPosition: string;
  costTypes: FinanceCostType[];
  activationSettings: ActivationSettingsResponse;
}

export interface ActivationSettingsResponse {
  id: number;
  statuses: FinanceActivationStatus[];
  options: FinanceOriginOption[];
}

export interface FinanceActivation {
  id: number;
  status: string;
  activation: string;
  budget: number;
  cpl: number;
  mql: number;
}

export interface FinanceActivationOptions {
  originOptions: FinanceOriginOption[];
  activationStatuses: FinanceActivationStatus[];
}

export interface FinanceCostOptions {
  categories: FinanceCostCategory[];
  types: FinanceCostType[];
  activations: FinanceActivation[];
  paymentBanks: string[];
}

export interface FinanceCreditOptions {
  codeTypes: string[];
  statuses: string[];
  regions: string[];
  states: {id: number, name: string}[];
  banks: {id: number, name: string}[];
  crops: {id: number, name: string}[];
}
