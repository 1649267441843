import type { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { Table as AntTable, Input, Select, Button } from 'antd';

import cls from './Table.module.css';
import { clsx } from 'clsx';

const { Search } = Input;

interface Props {
  list: AccountTableItemProps[];
  conceptCodes: string[];
  addNewHandler: () => void;
}

export interface AccountTableItemProps {
  code: string;
  concept: string;
  creditCode: string;
  amount: string;
  paymentDate: string;
}

interface DataType extends AccountTableItemProps {
  key: React.Key;
}

export interface FilterProps {
  conceptCodes: string[];
  onFilterChange: (q: string, concept: string) => void;
  addNewHandler: () => void;
}

const Filter = ({ conceptCodes, onFilterChange, addNewHandler }: FilterProps) => {
  const [query, setQuery] = useState('');
  const [concept, setConcept] = useState('');

  useEffect(() => {
    onFilterChange(query, concept);
  }, [query, concept]);

  const showAddButton = false;

  return (
    <div className={cls.filterWrap}>
      <div className={clsx(cls.filterField, cls.firstCol)}>
        <Search
          placeholder="Search"
          onSearch={undefined}
          onChange={(e) => setQuery(e.target.value)}
          className={cls.searchField}
          value={query}
        />
      </div>
      <div className={clsx(cls.filterField, cls.secondCol)}>
        <Select
          className={cls.selectField}
          placeholder="Concept"
          style={{ width: 120 }}
          onChange={setConcept}
          allowClear
          bordered
          value={concept}
          options={conceptCodes.map(value => ({ value, label: value }))}
        />
      </div>
      {showAddButton && (
        <div className={clsx(cls.filterField, cls.lastCol)}>
          <Button
            type="primary"
            onClick={addNewHandler}
            className={cls.filterBtn}
          >Add</Button>
        </div>
      )}
    </div>

  );
};

export const Table = ({ list, conceptCodes, addNewHandler }: Props) => {
  const [tableData, setDepositConcepts] = useState<DataType[]>(transformTableData(list));
  const columns: ColumnsType<AccountTableItemProps> = [
    {
      title: 'Concept',
      dataIndex: 'code',
    },
    {
      title: 'Credit Code',
      dataIndex: 'creditCode',
      width: 90,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Payment date',
      dataIndex: 'paymentDate',
      width: 90,
    },
  ];

  const onFilterChange = (q: string, concept: string) => {
    let items: DataType[] = transformTableData(list);
    if (concept) {
      items = transformTableData(list).filter(i => i.concept === concept);
    } else {
      items = transformTableData(list);
    }
    if (q.trim().length) {
      items = items.filter(i => (
        i.code.toLowerCase().includes(q.toLowerCase()))
        || i.creditCode.toLowerCase().includes(q.toLowerCase()),
      );
    } else {
      items = !concept ? transformTableData(list) : items;
    }
    setDepositConcepts(items);
  };

  return (
    <div className={cls.wrap}>
      <Filter
        conceptCodes={conceptCodes}
        onFilterChange={onFilterChange}
        addNewHandler={addNewHandler}
      />
      <AntTable
        className={cls.table}
        rowClassName={cls.tableRow}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{ y: 200 }}
      />
    </div>
  );
};

function transformTableData(list: AccountTableItemProps[]): DataType[] {
  return list.map((item: AccountTableItemProps, key: number): DataType => ({ key, ...item }));
}
