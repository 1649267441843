import { Button } from 'antd';

import cls from './FinanceActivationExport.module.css';

interface Props {
  onClick: () => void
}

export const FinanceActivationExport = ({ onClick }: Props) => {
  return (
    <div>
      <Button className={cls.button} onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
          <g clipPath="url(#clip0_1951_8914)">
            <path d="M12.1043 3.25775H15.1268C15.0889 3.18441 15.0384 3.11816 14.9784 3.06191L11.8814 0.191081C11.7976 0.112747 11.6964 0.0556641 11.5859 0.0244141L11.6393 2.79775C11.6439 3.05191 11.8501 3.25608 12.1043 3.25775Z" fill="#042E6B"/>
            <path d="M12.1042 3.9475C11.4758 3.94375 10.9646 3.44083 10.95 2.81292L10.8958 0H1.55125C0.693334 0.0025 -0.000832584 0.699167 7.49515e-07 1.55708V18.4429C-0.000832584 19.3008 0.693334 19.9975 1.55125 20H13.6571C14.515 19.9975 15.2092 19.3008 15.2083 18.4429V3.9475H12.1042Z" fill="#042E6B"/>
            <path d="M10.3843 12.1964L8.55884 14.0164C8.35301 14.2214 8.08009 14.3443 7.79009 14.3627C7.76468 14.366 7.73926 14.3677 7.71384 14.3681C7.69176 14.3693 7.66968 14.3693 7.64759 14.3681C7.35718 14.3552 7.08176 14.2368 6.87218 14.0356L5.01634 12.3764C4.75593 12.1448 4.73259 11.746 4.96426 11.4856C5.19593 11.2252 5.59468 11.2018 5.85509 11.4335L7.08968 12.5314V7.29393C7.07759 6.9456 7.35009 6.6531 7.69884 6.64101C8.04718 6.62893 8.33968 6.90143 8.35176 7.25018C8.35218 7.26476 8.35218 7.27935 8.35176 7.29393V12.4443L9.49551 11.3006C9.74301 11.0552 10.1426 11.0564 10.388 11.3039C10.6334 11.5514 10.6318 11.951 10.3847 12.1964H10.3843Z" fill="#E1E5ED"/>
          </g>
          <defs>
            <clipPath id="clip0_1951_8914">
              <rect width="15.2083" height="20" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <span>Export</span>
      </Button>
    </div>
  );
};
