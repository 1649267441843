import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { SaveFilled } from '@ant-design/icons';
import { Create, CreateProps, SaveButton, SimpleForm, TopToolbar } from 'react-admin';

import { DeliveryForm } from '../DeliveryForm';
import { useCreateProps, useRbacCreate } from '@hooks';
import { DeliveryCalculation } from '../DeliveryCalculation';
import { Container, MoneyInput, TextInput, Forbidden, PageHeader, FormRow } from '@components';
import cls from './DeliveryCreate.module.css';

export const DeliveryCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();
  const mainColGrid = {
    xs: { title: 24, children: 24 },
    lg: { title: 6, children: 18 },
    xl: { title: 7, children: 13 },
    xxl: { title: 5, children: 15 },
  };

  if (!rbacCreate) {
    return <Forbidden />;
  }

  const Actions = () => {
    return (
      <TopToolbar className={cls.actions}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon}/>}
          label="Save"
          type="button"
          className={clsx(cls.action, cls.actionSave)}
          variant="outlined"
          alwaysEnable
        />
      </TopToolbar>
    );
  };

  return (
    <Create {...props} {...createProps} className={clsx(cls.page, 'details-page-wrapper')}>
      <PageHeader
        title="Create a shipping"
        linkText="Back to the list"
        linkUrl="/delivery"
      />

      <SimpleForm
        toolbar={<Actions/>}
        className={cls.form}
      >
        <Container max>
          <Row className={cls.wrapper}>
            <Col xs={24}>
              <div className={clsx(cls.block, cls.blockMain)}>
                <div className={cls.blockHeader}>Shipping</div>
                <Row gutter={24}>
                  <Col xs={24} sm={12}>
                    <FormRow
                      title="Exchange rate:"
                      width={mainColGrid}
                      rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                      colTitleClassName={cls.formLabelCol}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                    >
                      <MoneyInput source="exchangeRate" name="exchangeRate" label={false} min={0} max={999999999} />
                    </FormRow>
                    <FormRow
                      title="Shiping cost:"
                      width={mainColGrid}
                      rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                      colTitleClassName={cls.formLabelCol}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                    >
                      <MoneyInput source="shippingCost" name="shippingCost" label={false} min={0} max={999999999} />
                    </FormRow>
                    <FormRow
                      title="Extra cost:"
                      width={mainColGrid}
                      rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                      colTitleClassName={cls.formLabelCol}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                    >
                      <MoneyInput source="extraCost" name="extraCost" label={false} min={0} max={999999999} />
                    </FormRow>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FormRow
                      fullWidth
                      rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                      colTitleClassName={cls.formLabelCol}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formMainMultilineValue)}
                    >
                      <TextInput
                        source="notes"
                        name="notes"
                        label={false}
                        placeholder="Any notes about delivery"
                        multiline
                        fullWidth
                      />
                    </FormRow>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row gutter={24} className={cls.wrapper}>
            <Col xs={24}>
              <div className={clsx(cls.block, cls.blockProduct)}>
                <div className={cls.blockHeader}>Product</div>
                <DeliveryForm editable={rbacCreate} />
                <DeliveryCalculation />
              </div>
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};
