import React from 'react';
import { Tag } from 'antd';
import {
  FunctionField, Identifier,
  Link,
  ListProps,
  RowClickFunction,
  TextField,
  useRedirect,
} from 'react-admin';

import { useExporter, useRbacList, useRbacReadonly } from '@hooks';
import { DateField, DateRangeInput, Forbidden, Grid, GridList, TextInput } from '@components';
import { StatusField } from '../components';
import cls from './Ticket.module.css';
import { Admin, SupportTicket } from '@types';

export const SupportTicketList = (props: ListProps) => {
  const rbacList = useRbacList();
  const rbacReadonly = useRbacReadonly();
  const redirect = useRedirect();

  const exporter = useExporter({
    filename: 'Manuals',
    props: {
      'id': {
        label: 'ID',
      },
      'issue': {
        label: 'Ticket Description',
      },
      'admin': {
        label: 'Sent by',
        transform: (v: Admin) => v ? v.name : '',
      },
      'statusEn': {
        label: 'Status of issue',
      },
      'platform': {
        label: 'Platform',
      },
      'links': {
        label: 'Links',
        transform: (v: string[]) => v.join(', '),
      },
      'createdAt': {
        label: 'Date',
        transform: 'date',
      },
      'updatedAt': {
        label: 'Updated Date',
        transform: 'date',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  const filters = [
    <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
    <DateRangeInput
      key="createdAt"
      source="createdAt"
      label="Date"
      defaultValue={false}
    />,
  ];

  const rowClick: RowClickFunction = (id: Identifier, resource: string) => {
    if (rbacReadonly) {
      redirect(`/${resource}/${id}?view`);
      return false;
    }
    return 'edit';
  };

  return (
    <GridList
      {...props}
      filters={filters}
      sort={{ field: 'id', order: 'DESC' }}
      exporter={exporter}
      hideRemoveButton
    >
      <Grid
        defaultColumns={'id issue admin platform statusEn links createdAt'.split(' ')}
        className={cls.grid}
        rowClick={rowClick}
      >
        <TextField source="id" label="ID" />
        <TextField source="issue" label="Ticket Description" />
        <FunctionField source="admin"
          label="Sent by"
          render={(record: SupportTicket) => record.admin ? (
            <Link to={`/admin/${record.admin.id}`}
              className={cls.link}
              onClick={e => e.stopPropagation()}>
              {record.admin.name}
            </Link>
          ) : ''} />
        <StatusField source="statusEn" label="Status of issue" />
        <TextField source="platform" label="Platform" />
        <FunctionField resource="support-ticket" source="links" render={(record: SupportTicket) => {
          return record.links.length ? record.links.map((link: string, idx: number) => (
            <Tag key={idx} className={cls.tag}>
              <a
                href={link.includes('https://') ? link : `https://${link}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                {link.replace('https://', '').replace('www.', '')}
              </a>
            </Tag>
          )) : <></>;
        }} />
        <DateField source="createdAt" label="Date" />
        <DateField source="updatedAt" label="Updated Date" />
      </Grid>
    </GridList>
  );
};
