import { useRef, useState } from 'react';

export const useIsFirstRender = (): boolean => {
  const isFirstRender = useRef(true);

  if (isFirstRender.current) {
    isFirstRender.current = false;

    return true;
  }

  return false;
};

export const useIsFirstRenderTimeout = (ms = 1000): boolean => {
  const [isFirstRender, setFirstRender] = useState(true);

  if (isFirstRender) {
    setTimeout(() => void setFirstRender(false), ms);
  }

  return isFirstRender;
};
