export interface FarmLocation {
  id?: number;
  created: number;
  latDegrees?: number;
  latMinutes?: number;
  latSeconds?: number;
  latDirection?: GeoLocationDirection;
  lonDegrees?: number;
  lonMinutes?: number;
  lonSeconds?: number;
  lonDirection?: GeoLocationDirection;
  latitude?: string;
  longitude?: string;
  original?: string;
  extra?: string;
  formatted?: string;
  country?: string;
  city?: string;
  postalCode?: string;
  placeId?: string;
}

export type FarmLocationProp = keyof FarmLocation;

export enum GeoLocationDirection {
  N = 'N',
  W = 'W',
  E = 'E',
  S = 'S',
}
