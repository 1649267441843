import { rbac } from '@utils';
import { useEffect, useState } from 'react';

import { InitialPage } from '@components';
import { Cards } from './components/Card';
import { Tables } from './components/Table';
import { Balance } from './components/Balance';
import { financeDashboardHttp } from '@network';
import { FinanceDashboardActivationsProps } from '@types';
import { VerticalDoubleGraph } from './components/VerticalDoubleGraph';

export const FinanceDashboardPage = () => {
  const hasAccess = rbac.hasFinanceBalancePerm();
  const [activations, setActivations] = useState<FinanceDashboardActivationsProps | null>(null);

  const getActivations = async () => {
    try {
      const result: FinanceDashboardActivationsProps = await financeDashboardHttp.getActivations();

      if (!result) {
        throw new Error('The activations has not been received');
      }

      setActivations(result);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getActivations();
    })();
  }, []);

  if (!hasAccess) {
    return null;
  }

  return (
    <InitialPage title="Balance">
      <Balance />
      {activations ? (
        <>
          <Cards
            cplAvg={activations.cplAvg}
            mqlAvg={activations.mqlAvg}
            budgetAvg={activations.budgetAvg}
            budgetTotal={activations.budgetTotal}
          />
          <Tables
            cplTop={activations.cplTop}
            cplBottom={activations.cplBottom}
            mqlTop={activations.mqlTop}
            mqlBottom={activations.mqlBottom}
            budgetTop={activations.budgetTop}
            budgetBottom={activations.budgetBottom}
          />
        </>
      ) : null}

      <VerticalDoubleGraph />
      {/*<HorizontalDoubleGraph />*/}
    </InitialPage>
  );
};
