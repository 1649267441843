import { clsx } from 'clsx';
import React, { PropsWithChildren } from 'react';

import cls from './raw-html.module.css';

export const RawHtml = ({ children, className }: PropsWithChildren<any>) => (
  <div
    className={clsx(cls.html, className || '')}
    dangerouslySetInnerHTML={{ __html: children }}
  />
);
