import { Create, CreateProps, SimpleForm } from 'react-admin';

import { useCreateProps, useRbacCreate } from '@hooks';
import { Forbidden, InitialPage } from '@components';
import { FinanceCampaignForm, validate } from './FinanceCampaignForm';

export const FinanceCampaignCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps} title="Create Activation">
      <InitialPage
        title="Campaign"
        backTo="/finance-campaign"
        backText="Back to all Campaigns"
      >
        <SimpleForm validate={validate}>
          <FinanceCampaignForm />
        </SimpleForm>
      </InitialPage>
    </Create>
  );
};
