import { useEffect, useState } from 'react';

import { ReplyQuestion } from '@types';
import { InitialCard } from '@components';
import cls from './ScoreFromAdminRisk.module.css';
import { clsx } from 'clsx';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';

interface Props {
  data: ReplyQuestion[];
  onChange: (value: ReplyQuestion[]) => void;
  isFixedReplies?: boolean;
}

interface CategoryProps {
  list: ReplyQuestion[];
  title: string;
  onChange: (item: ReplyQuestion, answerId: number) => void;
  isFixedReplies?: boolean;
}

interface ItemProps {
  item: ReplyQuestion;
  onChange: (item: ReplyQuestion, answerId: number) => void;
  isFixedReplies?: boolean;
}

const Item = ({ item, onChange, isFixedReplies }: ItemProps) => {
  const getAnswer = () => item.answers
    .find((answer) => answer.id === item.answerId)?.title || '';
  const getPercent = () => isFixedReplies
    ? item.progressPercent || ''
    : item.answers.find(a => a.id === item.answerId)?.progressPercent || '';

  return (
    <div className={cls.item}>
      <div className={cls.itemTitle}>{item.title}</div>
      {isFixedReplies
        ? (
          <div className={clsx(cls.itemBody, cls.fixed)}>{item.value}</div>
        ) : (
          <div className={cls.itemBody}>
            <Dropdown
              className={cls.dropdown}
              menu={{ items: item.answers.map(o => ({
                label: <div className={cls.selected} onClick={() => onChange(item, o.id)}>
                  {o.title}
                </div>,
                key: o.title,
              })), selectable: true }}
              overlayClassName={cls.dropdownOverlay}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={cls.selectButton}
                onClick={e => e.preventDefault()}
              >
                <div className={clsx(cls.selectedText, !getAnswer() && cls.empty)}>
                  {getAnswer() || '---'}
                </div>
                <div className={cls.selectedIcon}><DownOutlined className={cls.arrowIcon}/></div>
              </a>
            </Dropdown>
          </div>
        )}
      <div className={cls.itemPercents}>{getPercent() ? `${getPercent()}%` : '---'}</div>
    </div>
  );
};

const Category = ({ list, title, onChange, isFixedReplies }: CategoryProps) => (
  <InitialCard left={title} mt={20}>
    <div className={cls.category}>
      {list.filter(i => i.category?.title === title)
        .map((i) => <Item
          item={i}
          onChange={onChange}
          isFixedReplies={isFixedReplies}
          key={i.position}
        />)}
    </div>
  </InitialCard>
);

export const ScoreFromAdminRisk = ({ data, onChange, isFixedReplies }: Props) => {
  const [catNames, setCatNames] = useState<string[]>([]);

  const loadCategories = (): string[] => {
    const catTitles: string[] = [];
    data.forEach((i: ReplyQuestion) => {
      if (i.category?.title) {
        catTitles[i.category.position] = i.category.title;
      }
    });

    return catTitles.filter(t => t);
  };

  const handleChange = (item: ReplyQuestion, answerId: number) => {
    onChange(data.map(i => (i.questionId === item.questionId) ? { ...i, answerId } : i));
  };

  useEffect(() => {
    if (data && data.length) {
      setCatNames(loadCategories());
    }
  }, []);

  return !data || !data.length ? null : (
    <div className={cls.wrap}>
      {catNames.map((c: string, idx: number) => (
        <Category
          list={data}
          title={c}
          onChange={handleChange}
          isFixedReplies={isFixedReplies}
          key={idx}
        />
      ))}
    </div>
  );
};
