import { clsx } from 'clsx';
import { RiskGeography } from '@types';
import cls from './Geography.module.css';

export const Geography = ({ geo, tiny }: {geo: RiskGeography, tiny?: boolean}) => {
  return (
    <div className={clsx(cls.block, tiny && cls.blockTiny)}>
      <div className={cls.row}>
        <div className={cls.col}>
          <div className={cls.label}>State:</div>
          <div className={cls.value}>{geo.name || ''}</div>
        </div>
        <div className={cls.col}>
          <div className={cls.label}>Category:</div>
          <div className={cls.value}>{geo.category || ''}</div>
        </div>
        <div className={cls.col}>
          <div className={cls.value}>{geo.grade + '%' || '0%'}</div>
        </div>
      </div>
    </div>
  );
};
