import { PropsWithChildren } from 'react';
import { makeStyles } from '@mui/styles';

export const InitialFilters = (props: PropsWithChildren<object>) => {
  const cls = useStyles();

  return (
    <div className={cls.filters}>
      {props.children}
    </div>
  );
};

const useStyles = makeStyles({
  filters: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '14px 0 20px',
    '& > *': {
      marginRight: 15,
    },
  },
});
