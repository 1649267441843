import { FC, useState } from 'react';
import { Button } from 'antd';
import { Chip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { dateFormatter, moneyFormatter } from '@utils';
import { OrderTransaction } from '@types';

const useStyles = makeStyles({
  box: {
    padding: '0 15px',
  },
  item: {
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 15,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  timestamp: {
    marginLeft: 10,
    fontSize: '13px',
    lineHeight: '13px',
  },
  statuses: {
    marginLeft: 10,
    fontSize: '13px',
    lineHeight: '13px',
  },
});

const TransactionItem: FC<{item: OrderTransaction}> = ({ item }) => {
  const cls = useStyles();

  return (
    <div className={cls.item}>
      <div className={cls.row}>
        {item.type === 'income' && (
          <Chip color="primary" label={`+ ${ moneyFormatter.format(item.amount)}`} />)
        }
        {item.type === 'outcome' && (
          <Chip label={`- ${ moneyFormatter.format(item.amount)}`} />)
        }
        <div className={cls.statuses}>
          {moneyFormatter.format(item.balanceBefore)} → {moneyFormatter.format(item.balanceAfter)}
        </div>
      </div>
      <div>
      </div>
      <div className={cls.row}>
        <div>{item.title}</div>
        <div className={cls.timestamp}>
          {dateFormatter.toDateTime(item.createdAt)}
        </div>
      </div>
    </div>
  );
};

export const OrderTransactions: FC<{transactions: OrderTransaction[]}> = ({ transactions }) => {
  const cls = useStyles();
  const [isAll, setIsAll] = useState(false);
  const show = () => setIsAll(true);

  if (!Array.isArray(transactions) || transactions.length === 0) {
    return null;
  }

  const maxVisible = 3;
  const visibleTransactions = transactions.slice(0, maxVisible);
  const hiddenTransactions = transactions.slice(maxVisible);

  return (
    <div className={cls.box}>
      <Typography variant="h5">Transactions</Typography>
      {visibleTransactions.map(item => <TransactionItem key={item.id} item={item} />)}
      {hiddenTransactions.length > 0 && (
        <>
          {!isAll && <Button onClick={show}>show previous ({hiddenTransactions.length})</Button>}
          {isAll && hiddenTransactions.map(item => <TransactionItem key={item.id} item={item} />)}
        </>
      )}
    </div>
  );
};
