import { dealerHttp, leadHttp, userHttp } from '@network';
import { ConfirmDelete } from '@components';
import cls from './FinancialActivationLabel.module.css';

interface Props {
    label: string;
    type: 'credits' | 'leads' | 'users' | 'associates' | 'retailers' | 'buyers';
    refresh?: () => void;
    id?: number;
    canRemove?: boolean;
}

export const FinancialActivationLabel = ({
  label, type, refresh, id, canRemove = false,
}: Props) => {

  const onDelete = () => {
    if (!id) {
      return;
    }

    try {
      (async () => {
        switch (type) {
          case 'leads': await leadHttp.removeActivation(id);
            break;
          case 'users': await userHttp.removeActivation(id);
            break;
          case 'associates': await dealerHttp.removeActivation(id);
            break;
          case 'retailers': await dealerHttp.removeActivation(id);
            break;
          case 'buyers': await dealerHttp.removeActivation(id);
            break;
          default: break;
        }

        if (refresh) {
          refresh();
        }
      })();
    } catch (e) {
      console.error(e);
    }
  };
    
  return (
    <div className={cls._}>
      <span className={cls.text}>{label}</span>
      {canRemove && (
        <ConfirmDelete title="Are you sure?" cb={onDelete}>
          <div className={cls.icon}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
              <path
                d="M8.32511 1.90625C8.35111 2.01427 8.46113 2.10028 8.57314 2.10028H10.7034C11.0895 2.10028 11.4035 2.41432 11.4035 2.80037C11.4035 3.12042 11.1895 3.38845 10.8975 3.47246L10.5314 13.5258C10.5014 14.3439 9.82131 15 9.0012 15H2.40232C1.58421 15 0.90212 14.3419 0.872116 13.5258L0.506067 3.47246C0.214029 3.38845 0 3.11842 0 2.80037C0 2.41432 0.314042 2.10028 0.700093 2.10028H2.83038C2.94039 2.10028 3.05041 2.01227 3.07841 1.90625L3.22843 1.30617C3.41446 0.566076 4.13855 0 4.90065 0H6.50287C7.26497 0 7.99107 0.566076 8.17509 1.30617L8.32511 1.90625ZM5.20069 5.30071V12.1016C5.20069 12.3777 5.42472 12.6017 5.70076 12.6017C5.9768 12.6017 6.20083 12.3777 6.20083 12.1016V5.30071C6.20083 5.02467 5.9768 4.80064 5.70076 4.80064C5.42472 4.80064 5.20069 5.02467 5.20069 5.30071ZM2.90039 5.31471L3.10041 12.1156C3.10841 12.3917 3.33844 12.6097 3.61448 12.6017C3.89052 12.5937 4.10855 12.3636 4.10055 12.0876L3.90052 5.28671C3.89252 5.01067 3.66249 4.79264 3.38645 4.80064C3.11041 4.80864 2.89239 5.03867 2.90039 5.31471ZM7.501 5.2847L7.30097 12.0856C7.29297 12.3616 7.511 12.5917 7.78704 12.5997C8.06307 12.6077 8.29311 12.3897 8.30111 12.1136L8.50113 5.31271C8.50913 5.03667 8.2911 4.80664 8.01507 4.79864C7.73903 4.79064 7.509 5.00867 7.501 5.2847ZM4.57061 2.09828H6.82891C6.88292 2.09828 6.91692 2.05427 6.90492 2.00227L6.81491 1.64422C6.7849 1.5262 6.62088 1.39819 6.50087 1.39819H4.89865C4.77864 1.39819 4.61461 1.5262 4.58461 1.64422L4.4946 2.00227C4.4806 2.05627 4.5146 2.09828 4.57061 2.09828Z"
                fill="#FF0000"/>
            </svg>
          </div>
        </ConfirmDelete>
      )}
    </div>
  );
};
