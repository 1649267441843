import { clsx } from 'clsx';
import { Col, Empty, Row } from 'antd';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import { InitialCard, Loader } from '@components';
import { PieReport, RangeParams, reportsHttp } from '@network';
import cls from './CropsReportGraph.module.css';

interface Props {
  range: string;
  src: 'userCrops' | 'creditCrops';
  loadKey: number;
  id?: string;
  title: string;
  setLoader: (num: number, value: boolean) => void;
}

export const CropsReportGraph = ({ range, title, src, id, setLoader, loadKey }: Props) => {
  const [numbers, setNumbers] = useState<PieReport[]>();
  const [loading, setLoading] = useState(true);
  const chartId = id || src;
  const colors = ['#B44436', '#204BCE', '#1E2C5C', '#225236', '#FD8A32',
    '#3E8B5B', '#400E14', '#B54446', '#214BDE', '#1F2C6C', '#235246', '#FE8A42', '#3F8B6B', '#410E14',
    '#B64456', '#224BEE', '#1A2C7C', '#245256', '#FF8A52', '#3A8B7B', '#420E24',
    '#B61156', '#2211EE', '#1A117C', '#241156', '#FF6652', '#3A667B', '#421124',
    '#B64456', '#2244EE', '#1A447C', '#244456', '#FF4452', '#3A447B', '#424424',
    '#B61E56', '#221EEE', '#1A1E7C', '#241E56', '#FF6E52', '#3A6E7B', '#421E24',
    '#B64E56', '#224EEE', '#1A4E7C', '#244E56', '#FF4E52', '#3A4E7B', '#424E24',
    '#B67E56', '#227EEE', '#1A7E7C', '#247E56', '#FF7E52', '#3A7E7B', '#427E24',
    '#B67E56', '#227EEE', '#1A7E7C', '#247E56', '#FF7E52', '#3A7E7B', '#427E24'];

  const loadNumbers = async () => {
    setLoader(loadKey, true);
    setLoading(true);
    const data: RangeParams & { detailed?: boolean } = { range };

    if (id) {
      data.detailed = true;
    }

    try {
      const nextNumbers = await reportsHttp[src](data);
      setNumbers(nextNumbers);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(loadKey, false);
      setLoading(false);
    }
  };

  useEffect(() => void loadNumbers(), [range]);

  useLayoutEffect(() => {
    if (!numbers?.length || loading) {
      return;
    }
    const root = am5.Root.new(chartId);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
        innerRadius: 80,
      }),
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: id ? 'name' : 'category',
        radius: 40,
      }),
    );

    series.set('colors', am5.ColorSet.new(root, {
      colors: [
        am5.color(0xB44436),
        am5.color(0x204BCE),
        am5.color(0x1E2C5C),
        am5.color(0x225236),
        am5.color(0xFD8A32),
        am5.color(0x3E8B5B),
        am5.color(0x400E14),
        am5.color(0xB54446),
        am5.color(0x214BDE),
        am5.color(0x1F2C6C),
        am5.color(0x235246),
        am5.color(0xFE8A42),
        am5.color(0x3F8B6B),
        am5.color(0x410E14),
        am5.color(0xB64456),
        am5.color(0x224BEE),
        am5.color(0x1A2C7C),
        am5.color(0x245256),
        am5.color(0xFF8A52),
        am5.color(0x3A8B7B),
        am5.color(0x420E24),
        am5.color(0xB61156),
        am5.color(0x2211EE),
        am5.color(0x1A117C),
        am5.color(0x241156),
        am5.color(0xFF6652),
        am5.color(0x3A667B),
        am5.color(0x421124),
        am5.color(0xB64456),
        am5.color(0x2244EE),
        am5.color(0x1A447C),
        am5.color(0x244456),
        am5.color(0xFF4452),
        am5.color(0x3A447B),
        am5.color(0x424424),
        am5.color(0xB61E56),
        am5.color(0x221EEE),
        am5.color(0x1A1E7C),
        am5.color(0x241E56),
        am5.color(0xFF6E52),
        am5.color(0x3A6E7B),
        am5.color(0x421E24),
        am5.color(0xB64E56),
        am5.color(0x224EEE),
        am5.color(0x1A4E7C),
        am5.color(0x244E56),
        am5.color(0xFF4E52),
        am5.color(0x3A4E7B),
        am5.color(0x424E24),
        am5.color(0xB67E56),
        am5.color(0x227EEE),
        am5.color(0x1A7E7C),
        am5.color(0x247E56),
        am5.color(0xFF7E52),
        am5.color(0x3A7E7B),
        am5.color(0x427E24),
        am5.color(0xB67E56),
        am5.color(0x227EEE),
        am5.color(0x1A7E7C),
        am5.color(0x247E56),
        am5.color(0xFF7E52),
        am5.color(0x3A7E7B),
        am5.color(0x427E24),
      ],
    }));

    series.slices.template.setAll({
      strokeWidth: 2,
      stroke: am5.color(0xffffff),
      cornerRadius: 4,
    });

    series.slices.template.states.create('hover', {
      shadowOpacity: 1,
      shadowBlur: 10,
    });

    series.ticks.template.setAll({
      strokeOpacity: 0.4,
      strokeDasharray: [2, 2],
    });

    series.states.create('hidden', {
      endAngle: -90,
    });

    series.data.setAll(numbers);
    series.labels.template.set('visible', false);
    series.ticks.template.set('visible', false);
    series.slices.template.setAll({
      tooltipText: id ? '[bold]Crop:[/] {name}\n[bold]Values:[/] {value}\n[bold]Percent:[/] {percent}%'
        : '[bold]Crop:[/] {category}\n[bold]Values:[/] {value}\n[bold]Percent:[/] {percent}%',
    });

    // center text
    chart.seriesContainer.children.push(am5.Label.new(root, {
      textAlign: 'center',
      centerY: am5.p50,
      centerX: am5.p50,
      text: numbers && numbers.length > 0 ? '[bold fontSize:28px]Crops[/]' : undefined,
    }));

    // show/dispose
    series.appear(1000, 100);

    return () => void root.dispose();
  }, [chartId, numbers]);

  return (
    <InitialCard left={title} extraPadding fullHeight boxClass={cls.box} wrapperClass={cls.wrap}>
      {loading ? <Loader className={cls.loader} center /> : !numbers?.length ? <Empty /> : <Row>
        <Col sm={24} md={9} xl={id ? 15 : 15} xxl={id ? 18 : 12}>
          <div className={clsx(cls.labels, numbers.length < 7 ? cls.oneRow : cls.multiRows)}>
            {numbers.map((row, idx) => (
              <div key={idx} className={cls.label}>
                <div
                  className={cls.labelColor}
                  style={{ backgroundColor: colors[idx] || colors[0] }}
                />
                <div className={cls.labelText}>{id ? row.name : row.category}</div>
                <div className={cls.labelValue}>{row.percent}%</div>
              </div>
            ))}
          </div>
        </Col>
        <Col sm={24} md={15} xl={id ? 9 : 9} xxl={id ? 6 : 12}>
          <div className={cls.chart}>
            <div id={chartId} style={{ height: 300 }}/>
          </div>
        </Col>
      </Row>}
    </InitialCard>
  );
};
