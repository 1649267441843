import { Http } from './http';
import {
  CreateFinanceWithdrawalParams,
  FinanceDocument,
  FinanceWithdrawal,
  FinanceWithdrawalOptions,
} from '@types';

class FinanceWithdrawalHttp extends Http {
  async create(params: CreateFinanceWithdrawalParams) {
    const res = await this.post<FinanceWithdrawal>('/finance-withdrawal', params);

    return res.data;
  }

  async update(params: CreateFinanceWithdrawalParams, id: number) {
    const res = await this.put<FinanceWithdrawal>(`/finance-withdrawal/${id}`, params);

    return res.data;
  }

  async remove(id: number) {
    const res = await this.delete<FinanceWithdrawal>(`/finance-withdrawal/${id}`);

    return res.data;
  }

  async options(id?: number | string) {
    const res = await this.get<FinanceWithdrawalOptions>('/finance-withdrawal/options', {
      params: { id },
    });

    return res.data;
  }

  async addDocument(params: {id: number, fileIds: number[], type: string}) {
    const { id } = params;
    const url = `/finance-withdrawal/${id}/documents`;
    const res = await this.post<FinanceDocument[]>(url, params);

    return res.data;
  }

  async removeDocument(params: {id: number, documentId: number}) {
    const { id, documentId } = params;
    const url = `/finance-withdrawal/${id}/documents/${documentId}`;
    const res = await this.delete<FinanceDocument[]>(url);

    return res.data;
  }
}

export const financeWithdrawalHttp = new FinanceWithdrawalHttp();
