import { FunctionFieldProps, FunctionField, Link } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { Button } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import { useRequest } from '@hooks';
import { userHttp } from '@network';
import { Dealer, DealerType, User } from '@types';
import { querySerialize } from '@utils';

export const CountUsersBtn = (props: Partial<FunctionFieldProps>) => {
  return (
    <FunctionField
      {...props}
      sortable={false}
      render={(record: Dealer) => (
        <UserLoginWidget dealer={record} />
      )}
    />
  );
};

const UserLoginWidget = ({ dealer }: {dealer: Dealer}) => {
  const cls = useStyles();
  const linkToUsers = '/user?' + querySerialize({
    filter: JSON.stringify({
      buyer: dealer.type === DealerType.BUYER ? dealer.name : undefined,
      retailer: dealer.type === DealerType.RETAILER ? dealer.name : undefined,
      associate: dealer.type === DealerType.ASSOCIATE ? dealer.name : undefined,
    }),
    displayedFilters: JSON.stringify({
      buyer: dealer.type === DealerType.BUYER ? true : undefined,
      retailer: dealer.type === DealerType.RETAILER ? true : undefined,
      associate: dealer.type === DealerType.ASSOCIATE ? true : undefined,
    }),
  });

  return (
    <div onClick={e => e.stopPropagation()} className={cls.box}>
      <div className={cls.counter}>{dealer.countUsers ?? 0}</div>
      <Link to={linkToUsers}>
        <Button
          icon={<LoginOutlined />}
          type="ghost"
          className={cls.btn}
          title="Go to Users"
        />
      </Link>
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    width: 45,
    height: 32,
  },
  counter: {
    marginRight: 8,
  },
  btn: {
    padding: '0',
    width: 32,
    height: 32,
  },
});
