import React, { useEffect } from 'react';
import { EditProps, useRedirect } from 'react-admin';

export const RiskList = (props: EditProps) => {
  const redirect = useRedirect();

  useEffect(() => {
    redirect('/risk/1');
  }, []);

  return (
    <></>
  );
};
