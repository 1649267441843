import { clsx } from 'clsx';
import { useController } from 'react-hook-form';
import { useCallback, useState, memo } from 'react';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import { ImageInputProps, ImageInput as RaImageInput } from 'react-admin';
import 'react-medium-image-zoom/dist/styles.css';

import { convertFileToBase64 } from '@components/inputs';
import cls from './ImageStyledInput.module.css';

type InputWithExtraProps = ImageInputProps & {
  width?: number;
  required?: boolean;
  value?: string;
  withZoom?: boolean;
};

const FILE_MAX_MEGABYTE_SIZE = 5;
const FILE_MAX_BYTE_SIZE = FILE_MAX_MEGABYTE_SIZE * 1024 * 1024;

const UploadPlace = ({ value }: { value: any }) => (
  <div className={clsx(cls.uploadPlace, value ? cls.preview : '')}>
    <div className={cls.uploadTitle}>Upload Image</div>
    <div className={cls.uploadIcon}>
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="32" viewBox="0 0 26 32" fill="none">
        <path d="M21.5 15.27C21.5 13.34 19.93 11.77 18 11.77C16.07 11.77 14.5 13.34 14.5 15.27C14.5 17.2 16.07 18.77 18 18.77C19.93 18.77 21.5 17.2 21.5 15.27ZM18 13.77C18.83 13.77 19.5 14.44 19.5 15.27C19.5 16.1 18.83 16.77 18 16.77C17.17 16.77 16.5 16.1 16.5 15.27C16.5 14.44 17.17 13.77 18 13.77Z" fill="#042E6B"/>
        <path d="M23 8.27002H17C16.45 8.27002 16 8.72002 16 9.27002C16 9.82002 16.45 10.27 17 10.27H23C23.55 10.27 24 10.72 24 11.27V25.48L20.08 21.56C18.32 19.89 15.54 19.93 13.83 21.65L12.73 22.75L11.82 21.83L8.1 18.14C6.5 16.56 4.19 15.91 2 16.43V11.27C2 10.72 2.45 10.27 3 10.27H9C9.55 10.27 10 9.82002 10 9.27002C10 8.72002 9.55 8.27002 9 8.27002H3C1.34 8.27002 0 9.61002 0 11.27V28.27C0 29.93 1.34 31.27 3 31.27H22C24.21 31.27 26 29.48 26 27.27V11.27C26 9.61002 24.66 8.27002 23 8.27002ZM22 29.27H3C2.45 29.27 2 28.82 2 28.27V18.53L2.27 18.41C3.84 17.97 5.53 18.41 6.68 19.56L10.39 23.27L11.39 24.27H11.44L11.59 24.42C11.9 24.69 12.31 24.83 12.72 24.83C13.12 24.83 13.51 24.69 13.82 24.45L14 24.27L15.22 23.05C16.21 22.11 17.77 22.11 18.76 23.05L23.76 28.05C23.46 28.76 22.77 29.24 22 29.27Z" fill="#042E6B"/>
        <path d="M10.5228 4.69986L12.0028 3.26986V11.2699C12.0028 11.8199 12.4528 12.2699 13.0028 12.2699C13.5528 12.2699 14.0028 11.8199 14.0028 11.2699V3.19986L15.4828 4.67986C15.8728 5.06986 16.5128 5.06986 16.9028 4.67986C17.2928 4.28986 17.2928 3.65986 16.9028 3.26986L14.0728 0.449864C13.5128 -0.120136 12.5928 -0.150136 12.0028 0.389864L11.9328 0.449864L9.11281 3.26986C8.72281 3.65986 8.72281 4.29986 9.11281 4.68986C9.50281 5.07986 10.1328 5.08986 10.5328 4.69986H10.5228Z" fill="#042E6B"/>
      </svg>
    </div>
    <div className={cls.uploadNotion}>
      *File size should not exceed {FILE_MAX_MEGABYTE_SIZE}MB
    </div>
  </div>
);

const PreviewImage = (props: InputWithExtraProps) => {
  const { value, source, width, withZoom } = props;
  const [isZoomed, setIsZoomed] = useState(false);
  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom);
  }, []);

  if (!value) {
    return null;
  }

  return withZoom ? (
    <div onClick={() => setIsZoomed(!isZoomed)} className={clsx(cls.preview, value ? 'hasPreview' : 'empty')}>
      <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
        <img src={value} className={cls.img} style={{ maxWidth: width }} alt={source}/>
      </ControlledZoom>
    </div>
  ) : (
    <div className={clsx(cls.preview, value ? 'hasPreview' : 'empty')}>
      <img src={value} className={cls.img} style={{ maxWidth: width }} alt={source}/>
    </div>
  );
};

const RemoveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
    <path fill="#D72B2C" d="M17.5039 0C7.83826 0 0 7.83826 0 17.5039C0 27.1695 7.83826 35.0078 17.5039 35.0078C27.1695 35.0078 35.0078 27.1695 35.0078 17.5039C34.9922 7.83826 27.1617 0.00777605 17.5039 0Z"/>
    <path fill="white" d="M24.0895 10.9101C23.7863 10.6068 23.2964 10.6068 22.9931 10.9101L17.5032 16.4L12.0133 10.9101C11.7101 10.6068 11.2202 10.6068 10.9169 10.9101C10.6136 11.2133 10.6136 11.7032 10.9169 12.0065L16.4068 17.4964L10.9169 22.9863C10.6136 23.2895 10.6136 23.7794 10.9169 24.0827C11.2202 24.386 11.7101 24.386 12.0133 24.0827L17.5032 18.5928L22.9931 24.0827C23.2964 24.386 23.7863 24.386 24.0895 24.0827C24.3928 23.7794 24.3928 23.2895 24.0895 22.9863L18.5996 17.4964L24.0895 12.0065C24.3928 11.7032 24.3928 11.2133 24.0895 10.9101Z"/>
  </svg>
);

export const ImageStyledInput = (props: InputWithExtraProps) => {
  const { required, ...restProps } = props;
  const { field: { value, onChange } } = useController({ name: props.source });

  const changeImage = useCallback(async (file: File | null) => {
    if (file) {
      const base64img = await convertFileToBase64(file);
      onChange(base64img);
    }
  }, [onChange]);
  const onRemove = useCallback(() => void onChange(null), [onChange]);

  const format = (value: any) => (!value || typeof value === 'string') ? { url: value } : value;

  const MemoPreviewImage = memo(PreviewImage, (prev, next) => prev.value === next.value);

  return (
    <RaImageInput
      label=" "
      accept="image/png, image/jpg, image/jpeg"
      maxSize={FILE_MAX_BYTE_SIZE}
      placeholder={<UploadPlace value={value}/>}
      {...restProps}
      format={format}
      className={clsx(cls.field, value ? 'hasPreview' : 'empty')}
      isRequired={required}
      onChange={changeImage}
      onRemove={onRemove}
      removeIcon={RemoveIcon}
    >
      <MemoPreviewImage {...props} value={value}/>
    </RaImageInput>
  );
};
