import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useWatch } from 'react-hook-form';

import { deliveryHttp } from '@network';
import { Delivery, DeliveryPosition } from '@types';
import { useRequest } from '@hooks';
import { DeliveryPositions } from './DeliveryPositions';

export const DeliveryCalculation = () => {
  const cls = useStyles();
  const deliveryPositions = useWatch({ name: 'deliveryPositions' }) as DeliveryPosition[];
  const exchangeRate = useWatch({ name: 'exchangeRate' }) as number;
  const shippingCost = useWatch({ name: 'shippingCost' }) as number;
  const extraCost = useWatch({ name: 'extraCost' }) as number;
  const [delivery, setDelivery] = useState<Delivery>();

  const { submit: calculate } = useRequest(async () => {
    if (!deliveryPositions) {
      return;
    }
    const filteredPositions = deliveryPositions.filter(pos => !!pos.positionId) || [];
    if (filteredPositions.length === 0) {
      return;
    }
    const nextDelivery = await deliveryHttp.calculate({
      deliveryPositions: filteredPositions || [],
      exchangeRate: exchangeRate || 1,
      shippingCost: shippingCost || 0,
      extraCost: extraCost || 0,
    });
    setDelivery(nextDelivery);
  });

  useEffect(() => {
    calculate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calculate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryPositions, exchangeRate, shippingCost, extraCost]);

  return (
    <div className={cls.box}>
      {delivery && <DeliveryPositions delivery={delivery} />}
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    marginTop: 15,
  },
});
