import { clsx } from 'clsx';
import { InputNumber } from 'antd';

import { TableCreditBureauRow } from '@network';
import cls from './table-credit-bureau.module.css';
import { LightTooltip } from '@components';
import { InfoCircleFilled } from '@ant-design/icons';

type Column = 'title' | 'accredited' | 'ppdc';
type Row = TableCreditBureauRow;
type Header = {key: Column, label: string};

interface Props {
  data: {rows: Row[]} | null,
  onChange: any;
  isFixed?: boolean;
}

const headers: Header[] = [
  { key: 'title', label: '' },
  { key: 'accredited', label: 'Accredited' },
  { key: 'ppdc', label: 'PPDC' },
];
const defaultData: {headers: Header[], rows: Row[]} = {
  headers,
  rows: [
    { title: 'Current', accredited: 0, ppdc: 0 },
    { title: 'Verqor Debt', accredited: 0, ppdc: 0 },
  ],
};

export const TableCreditBureau = ({ data, onChange, isFixed }: Props) => {
  const fields: Column[] = headers.map(header => header.key);

  const onFieldChange = (val: number | null | string, key: Column, index: number) => {
    if (val === null) return;
    const nextRows = (data || defaultData)
      .rows.map((row, i) => i === index ? { ...row, [key]: val } : row);
    const nextData = { ...data, rows: nextRows };
    onChange(nextData);
  };

  if (!Array.isArray(data?.rows)) {
    setTimeout(() => void onChange(defaultData), 500);
  }

  const rows = data?.rows || [] as Row[];

  return (
    <div className={cls.block}>
      <div className={cls.table}>
        <div className={cls.header}>
          <div className={cls.headerRow}>
            <div className={cls.headerCol}>Credit Bureau</div>
          </div>
        </div>
        <div className={cls.titles}>
          <div className={cls.row}>
            {headers.map(header => (
              <div className={clsx(
                cls.col,
                header.label.length > 10 && cls.colTiny,
              )} key={header.key}>{header.label}</div>
            ))}
          </div>
        </div>
        <div className={cls.body}>
          {rows.map((row, index) => (
            <div className={clsx(cls.row, cls.rowColored)} key={index}>
              {fields.map(field => {
                const isAccreditedDebt = field === 'accredited' && row.title === 'Verqor Debt';
                const isPpdcDebt = field === 'ppdc' && row.title === 'Verqor Debt';
                return (
                  <div className={cls.col} key={field}>
                    {isAccreditedDebt && (
                      <LightTooltip extraWidth
                        title="Accredited (Current) + Requested amount of credit">
                        <InfoCircleFilled className={cls.tooltipIcon} />
                      </LightTooltip>
                    )}
                    {isPpdcDebt && (
                      <LightTooltip extraWidth
                        title="PPDC (Current) + Requested amount of credit">
                        <InfoCircleFilled className={cls.tooltipIcon} />
                      </LightTooltip>
                    )}
                    {field === 'title' ? (
                      <span>{row[field]}</span>
                    ) : (
                      <Input row={row} field={field} onChange={onFieldChange}
                        index={index} isFixed={isFixed || isAccreditedDebt || isPpdcDebt} />
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface InputProps {
  row: Row;
  field: Column;
  onChange?: any;
  index?: number;
  isFixed?: boolean;
}

const Input = ({ row, field, onChange, index, isFixed }: InputProps) => {
  const value = row[field];

  return (
    <InputNumber
      min={0}
      max={1000000000}
      onBeforeInputCapture={e => {
        const value = e.currentTarget.value;
        if (value && value.includes('.')) {
          const [, second] = value.split('.');
          if (second.length >= 2) {
            e.stopPropagation();
            e.preventDefault();
          }
        }
      }}
      formatter={(val) => `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(val) => val ? +val.replace(/\$\s?|(,*)/g, '') : 0}
      value={value}
      bordered={false}
      onChange={val => onChange(val, field, index)}
      className={cls.input}
      disabled={isFixed}
      controls={false}
    />
  );
};
