import { Button, Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';

import { InitialPage, InitialCard, InitialLine, InitialRow } from '@components';
import { Credit } from '@types';
import { dateFormatter, moneyFormatter } from '@utils';
import { CreditStatusField } from '@pages/credit/CreditStatusField';
import { useNotify } from 'react-admin';
import { CreditCallsList } from '@pages/credit/CreditEdit/CreditCallsList';

export const CreditCallsPage = ({ credit }: {credit: Credit}) => {
  const cls = useStyles();
  const notify = useNotify();

  const user = credit.user;
  const backTo = `/credit/${credit.id}?initial`;

  const onPhoneClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (user.phone) {
      navigator.clipboard.writeText(user.phone);
      notify(`${user.phone} is copied to Clipboard`);
    }
  };

  return (
    <InitialPage title="Task by credit" backTo={backTo} backText="Back to credit">
      <InitialCard mt={24} extraPadding>
        <Row gutter={[20, 20]}>
          <Col sm={24} md={12}>
            <div className={cls.card}>
              <div className={cls.cardHeader}>
                <div className={cls.cardTitle}>User</div>
                <div className={cls.cardRow}>
                  <div className={cls.cardLabel}>Code:</div>
                  <div className={cls.cardValue}>{credit.code}</div>
                </div>
              </div>
              <InitialRow title="Full name:">
                {user.fullName}
              </InitialRow>
              <InitialRow title="Request amount:">
                {moneyFormatter.format(credit.requestAmount || 0)}
              </InitialRow>
              <InitialRow title="Credit status:">
                <CreditStatusField source="status" />
              </InitialRow>
              <InitialRow title="State:">
                {user.state?.name}
              </InitialRow>
              <InitialRow title="Financed crop:">
                {credit.financedCrop?.nameMx}
              </InitialRow>
              <InitialRow title="Financed Ha:">
                {credit.financedHa}
              </InitialRow>
            </div>
          </Col>

          <Col sm={24} md={12}>
            <div className={cls.info}>
              <div className={cls.infoBox}>
                <InitialRow title="Signature at:">
                  {credit.signatureAt ? dateFormatter.toDateSlash(credit.signatureAt) : '-'}
                </InitialRow>
                <InitialRow title="Payment at:">
                  {credit.paymentAt ? dateFormatter.toDateSlash(credit.paymentAt) : '-'}
                </InitialRow>
                <Button className={cls.callBtn} onClick={onPhoneClick}>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none">
                    <path d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z"
                      fill="#042E6B" />
                    <path d="M26.0786 32.663H25.8713C18.0252 32.542 11.7173 26.1477 11.7173 18.3016V17.9906C11.7173 17.9906 11.7173 17.9387 11.7173 17.9214C11.7173 17.8005 11.7173 17.6968 11.7173 17.5758V17.4375C11.942 15.2946 13.7566 13.6528 15.9168 13.6701C16.2625 13.6701 16.5908 13.7046 16.9192 13.791C16.9883 13.791 17.0747 13.8256 17.1438 13.8602C17.2302 13.8774 17.2994 13.9639 17.2994 14.0503L18.4054 21.1186C18.4054 21.205 18.3881 21.2914 18.319 21.3433C17.8697 21.6543 17.3685 21.879 16.85 22C17.3512 23.2443 18.0943 24.3676 19.0449 25.3181C19.8917 26.165 20.894 26.8562 21.9828 27.3401C22.1211 26.839 22.3457 26.3723 22.6395 25.9576C22.6914 25.8884 22.7778 25.8539 22.8642 25.8712L29.8807 26.9772C29.8807 26.9772 30.0362 27.0463 30.0535 27.1327C30.1053 27.2883 30.1572 27.4611 30.1745 27.6166C30.2263 27.8932 30.2609 28.1697 30.2609 28.4462C30.2609 30.7101 28.4981 32.5593 26.2342 32.663H26.0268H26.0786Z"
                      fill="white" />
                    <path d="M26.1477 21.5848C26.0267 21.5848 25.923 21.4811 25.923 21.3601C25.923 19.3554 24.2985 17.7482 22.3111 17.7482C22.1901 17.7482 22.0864 17.6445 22.0864 17.5235C22.0864 17.4025 22.1901 17.2988 22.3111 17.2988C24.5578 17.2988 26.3896 19.1307 26.3896 21.3774C26.3896 21.4984 26.286 21.602 26.165 21.602L26.1477 21.5848Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="0.25"
                      strokeMiterlimit="10" />
                    <path d="M29.2239 21.5848C29.1029 21.5848 28.9992 21.4811 28.9992 21.3601C28.9992 17.6618 25.9922 14.672 22.3111 14.672C22.1901 14.672 22.0864 14.5683 22.0864 14.4473C22.0864 14.3263 22.1901 14.2227 22.3111 14.2227C26.2687 14.2227 29.4658 17.4198 29.4658 21.3774C29.4658 21.4984 29.3622 21.6021 29.2412 21.6021L29.2239 21.5848Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="0.25"
                      strokeMiterlimit="10" />
                    <path d="M32.0754 21.5848C31.9545 21.5848 31.8508 21.4811 31.8508 21.3601C31.8508 16.0891 27.5821 11.8377 22.3111 11.8204C22.1901 11.8204 22.0864 11.7167 22.0864 11.5958C22.0864 11.4748 22.1901 11.3711 22.3111 11.3711C27.8241 11.3711 32.3001 15.8471 32.3174 21.3774C32.3174 21.4984 32.2137 21.602 32.0927 21.602L32.0754 21.5848Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="0.25"
                      strokeMiterlimit="10"
                    />
                  </svg>
                  <span className={cls.callBtnText}>{user.phone}</span>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </InitialCard>

      <div className={cls.callsTable}>
        <CreditCallsList creditId={credit.id} calls={credit.calls || []} />
      </div>
    </InitialPage>
  );
};

const useStyles = makeStyles({
  card: {
    marginTop: 6,
    padding: '18px 30px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#E1E5ED',
    color: '#000',
    fontFamily: 'Outfit, sans-serif',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  cardLabel: {
    fontSize: 15,
    fontWeight: 500,
    marginRight: 10,
  },
  cardValue: {
    fontSize: 15,
    fontWeight: 400,
  },
  info: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  infoBox: {
    width: 240,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > div': {
      marginLeft: 20,
      width: 220,
    },
  },
  callBtn: {
    marginTop: 30,
    width: '100%',
    height: 46,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 23,
    border: '1px solid #042E6B',
    background: '#E1E5ED',
  },
  callBtnText: {
    marginLeft: 25,
    color: '#042E6B',
    fontSize: 16,
    fontWeight: 700,
  },
  callsTable: {
    paddingTop: 12,
    position: 'relative',
  },
});
