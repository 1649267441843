import { moneyFormatter } from '@utils';
import { InitialCard } from '@components';
import cls from './Card.module.css';

interface CardItem {
  label: string;
  value: number;
  isMoney?: boolean;
}

export interface FinanceDashboardCardProps {
  title: string;
  list: CardItem[];
}

const Info = ({ label, value, isMoney }: CardItem) => (
  <div className={cls.info}>
    <div className={cls.label}>{label}</div>
    <div className={cls.content}>
      {isMoney ? moneyFormatter.format(value) : value}
    </div>
  </div>
);

export const Card = ({ title, list }: FinanceDashboardCardProps) => {
  return (
    <InitialCard
      left={title}
      mediumPadding
      contentClass={cls.card}
      wrapperClass={cls.cardWrap}
      minHeight={350}
    >
      {list.map((item, idx) => <Info {...item} key={idx} />)}
    </InitialCard>
  );
};
