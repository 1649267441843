import { makeStyles } from '@mui/styles';
import { Col, DatePicker, InputNumber, notification, Row } from 'antd';
import { DeleteOutlined, DollarCircleOutlined, PlusSquareFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import moment from 'moment-timezone';
import IMask from 'imask';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { creditHttp, InterestCalculateResponse } from '@network';
import { dateFormatter, moneyFormatter, rbac } from '@utils';
import { ConfirmDelete } from '@components';
import { useQuery, useRequest } from '@hooks';
import { useLocation } from 'react-router-dom';

interface Props {
  creditId: number;
  isModalVisible: boolean;
  refreshCredit: () => Promise<void>;
  group?: null | string;
}

interface CalcHeaderProps {
  calc?: InterestCalculateResponse;
  error?: string;
}

const tz = 'Etc/UTC';

const CalcHeader = ({ calc, error }: CalcHeaderProps) => {
  const cls = useStyes();

  return (
    <div className={cls.box}>
      <Row>
        <Col xs={4} className={cls.col}>
          <div className={cls.title}>Credit</div>
          <div>{money(calc?.credit || 0)}</div>
        </Col>
        <Col xs={4} className={cls.col}>
          <div className={cls.title}>Interest Rate</div>
          <div>{calc?.interestRate || 0} %</div>
        </Col>
        <Col xs={4} className={cls.col}>
          <div className={cls.title}>Moratory Rate</div>
          <div>{calc?.moratoryRate || 0} %</div>
        </Col>
        <Col xs={4} className={cls.col}>
          <div className={cls.title}>Signature Date</div>
          <div>{calc?.signatureDate && dateFormatter.toDateSlash(calc.signatureDate)}</div>
        </Col>
        <Col xs={4} className={cls.col}>
          <div className={cls.title}>Payment Date</div>
          <div>{calc?.paymentDate && dateFormatter.toDateSlash(calc.paymentDate)}</div>
        </Col>
        <Col xs={4} className={cls.col}>
          <div className={cls.title}>Final Payment</div>
          <div>{money(calc?.finalPayment || 0)}</div>
        </Col>
      </Row>
      {error && <div className={cls.error}>{error}</div>}
    </div>
  );
};

export const CreditInterestWidget = ({
  creditId, group, isModalVisible, refreshCredit,
}: Props) => {
  const { search } = useLocation();
  const query = useQuery();
  const todayDate = query.get('today') ?? undefined;
  const cls = useStyes();
  const [calc, setCalc] = useState<InterestCalculateResponse>();
  const isSuperAdmin = rbac.isSuperadmin();

  useEffect(() => {
    isModalVisible && group === 'interest' && (async () => {
      const nextCalculation = await creditHttp.interestCalculation(creditId, {
        todayDate,
        creditAmount: query.has('creditAmount') ? +(query.get('creditAmount') || 0) : undefined,
        interestRate: query.has('interestRate') ? +(query.get('interestRate') || 0) : undefined,
        moratoryRate: query.has('moratoryRate') ? +(query.get('moratoryRate') || 0) : undefined,
        signatureDate: query.get('signatureDate') ?? undefined,
        paymentDate: query.get('paymentDate') ?? undefined,
      });
      setCalc(nextCalculation);
    })();
  }, [creditId, group, isModalVisible]);

  useEffect(() => {
    (async () => {
      const payments = calc && Array.isArray(calc.payments) ? calc.payments : undefined;
      const nextCalculation = await creditHttp.interestCalculation(creditId, {
        payments, todayDate,
      });
      setCalc(nextCalculation);
    })();
  }, [search]);

  const rows = calc?.rows || [];

  const { submit: onAddRow, loading: onAddLoading } = useRequest(async () => {
    const payments = calc && Array.isArray(calc.payments) ? calc.payments : undefined;
    Array.isArray(payments) && payments.push({
      paymentAmount: 0,
      paymentDate: calc?.nextDate || dayjs(new Date()).tz('Europe/London').format('YYYY-MM-DD'),
    });
    const nextCalculation = await creditHttp.interestCalculation(creditId, {
      payments, todayDate,
    });
    setCalc(nextCalculation);
  });

  const { submit: onSave, loading: onSaveLoading } = useRequest(async () => {
    const payments = calc?.payments || [];
    const isSave = true;
    const nextCalculation = await creditHttp.interestCalculation(creditId, {
      payments, todayDate, isSave,
    });
    setCalc(nextCalculation);
    notification.success({ message: 'Interests saved!', placement: 'bottom' });
    await refreshCredit();
  });

  const onDelete = async (title: string) => {
    const payments = (calc?.payments || []).filter(p => p.paymentTitle !== title);
    const nextCalculation = await creditHttp.interestCalculation(creditId, {
      payments, todayDate,
    });
    setCalc(nextCalculation);
  };

  const onChange = debounce((title: string, field: string, value: string | number) => {
    if (!Array.isArray(calc?.payments)) return;
    const payments = (calc?.payments || []).map(payment => {
      if (payment.paymentTitle === title) {
        payment[field] = value;
      }
      return payment;
    });
    creditHttp.interestCalculation(creditId, {
      payments, todayDate,
    }).then(nextCalc => setCalc(nextCalc));
  });

  if (calc && !calc.riskExists) {
    return <CalcHeader calc={calc} error="Risk Manager not found" />;
  }
  if (calc && !calc.signatureDate) {
    return <CalcHeader calc={calc} error="Missing Signature date" />;
  }
  if (calc && !calc.paymentDate) {
    return <CalcHeader calc={calc} error="Missing Payment date" />;
  }
  if (calc && calc.interestRate === 0 && calc.moratoryRate === 0) {
    return <CalcHeader calc={calc} error="Missing Interest/Moratory Rate" />;
  }

  const showAddButton = isSuperAdmin && !calc?.isPaid;

  return (
    <>
      <CalcHeader calc={calc} />

      <TableContainer>
        <Table sx={{ minWidth: 750 }} className={cls.table}>
          <TableHead>
            <TableRow className={cls.rowGroups}>
              <TableCell className={cls.plusCell}>
                {showAddButton && (
                  <IconButton onClick={onAddRow} disabled={onAddLoading}>
                    <PlusSquareFilled className={cls.plusIcon} />
                  </IconButton>
                )}
              </TableCell>
              <TableCell colSpan={3}>Payments Info</TableCell>
              <TableCell colSpan={4}>{' '}</TableCell>
              <TableCell colSpan={2}>Pre-Payment</TableCell>
              <TableCell colSpan={3}>Post-Payment</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={cls.deleteCell}>{' '}</TableCell>
              <TableCell className={clsx(cls.grayBg, cls.numCell)}>No.</TableCell>
              <TableCell className={clsx(cls.grayBg, cls.inputCell)}>Amount</TableCell>
              <TableCell className={clsx(cls.grayBg, cls.inputCell)}>Date</TableCell>
              <TableCell>Prior Capital Balance</TableCell>
              <TableCell>Prior Interests</TableCell>
              <TableCell>Total Prior Balance</TableCell>
              <TableCell>Period Interests</TableCell>
              <TableCell className={cls.sandBg}>Interests Balance</TableCell>
              <TableCell className={cls.sandBg}>Balance Inquiry</TableCell>
              <TableCell className={cls.greenBg}>Interest Balance</TableCell>
              <TableCell className={cls.greenBg}>Capital Balance</TableCell>
              <TableCell className={cls.greenBg}>Final Balance</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, idx) => {
              const {
                paymentTitle, paymentAmount, paymentDate, isPaid, withdrawalId,
                priorCapitalBalance, priorInterests, priorTotalBalance, periodInterests,
                prepeymentInterestsBalance, prepeymentBalanceInquiry,
                postpaymentInterestsBalance, postpaymentCapitalBalance, postpaymentFinalBalance,
              } = row;
              let removable = (paymentTitle.includes('P-') || paymentTitle.includes('PD-'))
                && !['P-0', 'P-T', 'P-F', 'PD-T'].includes(paymentTitle);

              const locked = !!withdrawalId;
              if (locked) {
                removable = false;
              }

              return (
                <TableRow key={idx} className={clsx(
                  paymentTitle === 'P-T' && cls.rowToday,
                  (paymentTitle === 'P-F' || isPaid) && cls.rowFinal,
                  paymentTitle.includes('PD-') && paymentTitle !== 'PD-T' && cls.rowDelay,
                  paymentTitle === 'PD-T' && cls.rowDelayToToday,
                  cls.numCell,
                )}>
                  <TableCell className={cls.deleteCell}>
                    {removable && <ConfirmDelete cb={() => onDelete(paymentTitle)}>
                      <IconButton><DeleteOutlined className={cls.deleteIcon} /></IconButton>
                    </ConfirmDelete>}
                  </TableCell>

                  <TableCell>{row.paymentPaidTitle || row.paymentTitle}</TableCell>

                  <TableCell className={cls.inputCell}>
                    {typeof paymentAmount === 'number' ? (
                      <InputNumber
                        disabled={locked}
                        prefix={<DollarCircleOutlined className={cls.amountIcon} />}
                        bordered={+paymentAmount < 0}
                        value={paymentAmount}
                        onChange={cost => onChange(paymentTitle, 'paymentAmount', +`${cost}`)}
                        controls={false}
                        onKeyDown={locked ? e => e.preventDefault() : undefined}
                        className={cls.amountInput}
                        min={0} max={999999999}
                        status={+paymentAmount >= 0 ? undefined : 'error'}
                        maxLength={10}
                      />
                    ) : null}
                  </TableCell>

                  <TableCell className={cls.inputCell}>
                    {removable ? (
                      <DatePicker
                        className={cls.dateInput}
                        bordered={!paymentDate}
                        status={paymentDate ? undefined : 'error'}
                        allowClear={false}
                        disabled={locked}
                        value={paymentDate ? moment(paymentDate).tz(tz) : null}
                        onKeyDown={event => {
                          if (locked) return event.preventDefault();
                          const input = event.target as HTMLInputElement;
                          input.value = MASKED.resolve(input.value);
                          setTimeout(() => {
                            const momentDate = moment(input.value, DATE_FORMAT, true).tz(tz);
                            if (momentDate.isValid()) {
                              onChange(paymentTitle, 'paymentDate', momentDate.format('YYYY-MM-DD'));
                            }
                          }, 200);
                        }}
                        onChange={selectedValue => {
                          if (locked) return;
                          const momentDate = moment(selectedValue, DATE_FORMAT, true).tz(tz);
                          if (momentDate.isValid()) {
                            onChange(paymentTitle, 'paymentDate', momentDate.format('YYYY-MM-DD'));
                          }
                        }}
                        picker="date"
                        format={DATE_FORMAT}
                        disabledDate={(current) => current && current > moment().tz(tz).endOf('day')}
                      />
                    ) : null}
                    {typeof paymentDate === 'string' && !removable
                      ? dateFormatter.toDateSlash(paymentDate)
                      : null}
                  </TableCell>

                  <TableCell>
                    {typeof priorCapitalBalance === 'number' && money(priorCapitalBalance)}
                  </TableCell>
                  <TableCell>
                    {typeof priorInterests === 'number' && money(priorInterests)}
                  </TableCell>
                  <TableCell>
                    {typeof priorTotalBalance === 'number' && money(priorTotalBalance)}
                  </TableCell>
                  <TableCell>
                    {typeof periodInterests === 'number' && money(periodInterests)}
                  </TableCell>
                  <TableCell>
                    {typeof prepeymentInterestsBalance === 'number' && money(prepeymentInterestsBalance)}
                  </TableCell>
                  <TableCell>
                    {typeof prepeymentBalanceInquiry === 'number' && money(prepeymentBalanceInquiry)}
                  </TableCell>
                  <TableCell>
                    {typeof postpaymentInterestsBalance === 'number' && money(postpaymentInterestsBalance)}
                  </TableCell>
                  <TableCell>
                    {typeof postpaymentCapitalBalance === 'number' && money(postpaymentCapitalBalance)}
                  </TableCell>
                  <TableCell>
                    {typeof postpaymentFinalBalance === 'number' && money(postpaymentFinalBalance)}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow className={cls.rowTotal}>
              <TableCell colSpan={2}>{' '}</TableCell>
              <TableCell colSpan={2}>
                {money(calc?.paymentAmount) || 0} – Payment Amount
              </TableCell>
              <TableCell colSpan={3}>
                {calc?.savingsAmount && calc?.savingsAmount > 0 ? (
                  <>{money(calc?.savingsAmount) || 0} – Early payment savings</>
                ) : null}
              </TableCell>
              <TableCell colSpan={7}>
                {money(calc?.interestsAmount ?? 0) || 0} – Interests Amount
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div className={cls.actions}>
        <Button variant="contained" color="secondary" disabled={onSaveLoading}
          onClick={onSave} className={cls.btn}>
          <SaveIcon /><span>Save</span>
        </Button>
      </div>
    </>
  );
};

const useStyes = makeStyles({
  numCell: {
    minWidth: 40,
  },
  actions: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  btn: {
    borderRadius: 8,
    paddingLeft: 50,
    paddingRight: 50,
    marginRight: 15,
    textTransform: 'none',
    '& svg': {
      marginRight: 10,
    },
  },
  box: {
    margin: '20px auto 0',
  },
  error: {
    fontSize: 25,
    color: '#272727',
    textAlign: 'center',
    backgroundColor: '#DFDFDF',
    padding: '20px 40px',
    border: '1px solid #AAA',
    borderRadius: 8,
    marginTop: 20,
  },
  title: {
    paddingBottom: 4,
    color: '#272727',
    fontSize: 16,
    fontWeight: 700,
    marginRight: 10,
  },
  col: {
    paddingLeft: 15,
  },
  // TABLE
  table: {
    marginTop: 20,
    '& th': {
      height: 40,
      textAlign: 'center',
      backgroundColor: '#dac3ea',
      padding: 5,
      fontSize: 12,
      color: '#000',
      lineHeight: 1.1,
    },
    '& td': {
      height: 40,
      textAlign: 'center',
      padding: 5,
      fontSize: 11,
      color: '#000',
      lineHeight: 1.1,
    },
  },
  rowGroups: {
    '& th': {
      height: 20,
      backgroundColor: 'transparent !important',
      fontSize: 15,
    },
  },
  rowTotal: {
    '& td': {
      textAlign: 'left',
    },
  },
  grayBg: {
    backgroundColor: '#d6d6d6 !important',
  },
  sandBg: {
    backgroundColor: '#e5ae91 !important',
  },
  greenBg: {
    backgroundColor: '#97c1b2 !important',
  },
  deleteCell: {
    backgroundColor: '#d6d6d6 !important',
    minWidth: '20px !important',
    maxWidth: '20px !important',
    padding: '0 !important',
    '& button': {
      padding: '4px !important',
    },
  },
  plusCell: {
    minWidth: '20px !important',
    maxWidth: '20px !important',
    padding: '0 !important',
    '& button': {
      padding: '0px !important',
    },
  },
  deleteIcon: {
    color: 'maroon',
    fontSize: 15,
  },
  plusIcon: {
    color: '#042E6B',
    fontSize: 20,
  },
  inputCell: {
    minWidth: '110px !important',
    maxWidth: '110px !important',
    padding: '0 !important',
    '& input': {
      fontSize: 12,
    },
    '& input:disabled': {
      backgroundColor: 'transparent',
      color: '#000',
    },
  },
  rowToday: {
    backgroundColor: '#042E6B',
    '& td': {
      color: 'white !important',
    },
  },
  rowFinal: {
    backgroundColor: '#b3e7d5',
  },
  rowDelay: {
    backgroundColor: '#f1894e',
  },
  rowDelayToToday: {
    backgroundColor: '#f13514',
    '& td': {
      color: 'white !important',
    },
  },
  amountIcon: {
    color: '#CCC',
    marginRight: 2,
  },
  amountInput: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  dateInput: {
    '& .ant-picker-input': {
      borderBottom: 'none !important',
    },
    '& input': {
      textAlign: 'center',
      marginRight: -16,
    },
  },
});

const money = (amount: number | undefined) => amount && amount > 0
  ? moneyFormatter.format(amount)
  : null;

const DATE_FORMAT = 'DD/MM/YYYY';
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: new Date().getFullYear() },
  },
  format: (date: Date) => moment(date).tz(tz).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT).tz(tz),
  pattern: DATE_FORMAT,
});

const SaveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'inline-block' }}
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none">
    <g clipPath="url(#clip0_3843_4772)">
      <path d="M14.8964 1.75077L13.2321 0.10257C13.1643 0.0389059 13.075 0 12.9786 0H2.25C2.21429 0 2.18571 0 2.15 0C2.15 0 2.14643 0 2.14286 0H0.357143C0.160714 0 0 0.159161 0 0.35369V14.6463C0 14.8408 0.160714 15 0.357143 15H14.6429C14.8393 15 15 14.8408 15 14.6463V2.00189C15 1.90639 14.9607 1.81797 14.8964 1.75077ZM7.5 11.8486C6.11786 11.8486 5 10.7416 5 9.37279C5 8.00401 6.11786 6.89696 7.5 6.89696C8.88214 6.89696 10 8.00401 10 9.37279C10 10.7416 8.88214 11.8486 7.5 11.8486ZM11.4286 3.90474C11.4286 4.49187 10.95 4.96581 10.3571 4.96581H4.64286C4.05 4.96581 3.57143 4.49187 3.57143 3.90474V1.40769C3.57143 1.22377 3.72143 1.07522 3.90714 1.07522L7.91071 1.06814C8.07857 1.06814 8.21429 1.20255 8.21429 1.36878V3.47324C8.21429 3.70667 8.40357 3.89413 8.63929 3.89413H9.575C9.81071 3.89413 10 3.70667 10 3.47324V1.36171C10 1.19547 10.1357 1.06107 10.3036 1.06107H11.0929C11.2786 1.06107 11.4286 1.20962 11.4286 1.39354V3.90474Z"
        fill="#E1E5ED" />
    </g>
    <defs>
      <clipPath id="clip0_3843_4772">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const debounce = (callback: any, wait = 300) => {
  let timeoutId: any;

  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};
