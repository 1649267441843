import { useRecordContext } from 'react-admin';
import { ManyField } from '@components';

export const CropsField = (props: any) => {
  const record = useRecordContext(props);

  if (!record) {
    return null;
  }

  if (record['allCrops']) {
    return <small>ALL CROPS</small>;
  }

  return <ManyField source="crops" reference="crop" />;
};

CropsField.defaultProps = { label: 'Crops' };
