import { clsx } from 'clsx';
import { Input as AntdInput } from 'antd';

import { TableCreditBureauScoreRow } from '@network';
import cls from './table-credit-bureau-score.module.css';

type Column = 'entity' | 'score';
type Row = TableCreditBureauScoreRow;
type Header = {key: Column, label: string};

interface Props {
  data: {rows: Row[]} | null,
  onChange: any;
  isFixed?: boolean;
}

const headers: Header[] = [
  { key: 'entity', label: 'Entity' },
  { key: 'score', label: 'Score' },
];
const defaultData: {headers: Header[], rows: Row[]} = {
  headers,
  rows: [
    { entity: 1, score: '0' },
    { entity: 2, score: '0' },
    { entity: 3, score: '0' },
  ],
};

export const TableCreditBureauScore = ({ data, onChange, isFixed }: Props) => {
  const fields: Column[] = headers.map(header => header.key);

  const onFieldChange = (val: number | null | string, key: Column, index: number) => {
    const nextRows = (data || defaultData)
      .rows.map((row, i) => i === index ? { ...row, [key]: val } : row);
    const nextData = { ...data, rows: nextRows };
    onChange(nextData);
  };

  if (!Array.isArray(data?.rows)) {
    setTimeout(() => void onChange(defaultData), 500);
  }

  const rows = data?.rows || [] as Row[];

  return (
    <div className={cls.block}>
      <div className={cls.table}>
        <div className={cls.header}>
          <div className={cls.headerRow}>
            <div className={cls.headerCol}>Credit Bureau Score</div>
          </div>
        </div>
        <div className={cls.titles}>
          <div className={cls.row}>
            {headers.map(header => (
              <div className={clsx(
                cls.col,
                header.label.length > 10 && cls.colTiny,
              )} key={header.key}>{header.label}</div>
            ))}
          </div>
        </div>
        <div className={cls.body}>
          {rows.map((row, index) => (
            <div className={clsx(cls.row, cls.rowColored)} key={index}>
              {fields.map(field => (
                <div className={cls.col} key={field}>
                  <Input row={row} field={field} onChange={onFieldChange}
                    index={index} isFixed={isFixed || row.isFixed || field === 'entity'} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface InputProps {
  row: Row;
  field: Column;
  onChange?: any;
  index?: number;
  isFixed?: boolean;
}

const Input = ({ row, field, onChange, index, isFixed }: InputProps) => {
  const value = row[field];
  const allowedChars = '0123456789-+'.split('');

  if (isFixed) {
    return (<span>{value}</span>);
  }

  return (
    <AntdInput
      onBeforeInputCapture={(e: any) => {
        if (isFixed || !allowedChars.includes(`${e.data}`)) e.preventDefault();
      }}
      value={value || ''}
      bordered={false}
      onChange={e => onChange(e.target.value, field, index)}
      className={cls.input}
      disabled={isFixed}
      maxLength={5}
    />
  );
};
