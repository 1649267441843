import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { RcFile } from 'antd/lib/upload';
import { useNotify } from 'react-admin';

import { CreditFarm, CreditFarmFile } from '@types';
import { creditFarmHttp, s3http } from '@network';
import { showAxiosError } from '@utils';
import { FileControlled, FileUpload } from '@components';

interface Props {
  farm: CreditFarm;
  onChange?: () => Promise<void>;
}

export const FarmPicture = ({ farm, onChange }: Props) => {
  const [loading, setLoading] = useState(false);
  const [picture, setPicture] = useState<CreditFarmFile | null>(null);
  const notify = useNotify();
  const cls = useStyles();

  useEffect(() => {
    setPicture(farm.picture || null);
  }, [farm]);

  const uploadPicture = async (rcFile: RcFile) => {
    setLoading(true);
    try {
      const file = await s3http.uploadFile(rcFile as File);
      await creditFarmHttp.updateFarm({ ...farm, pictureId: file.id });
      setPicture(file);
      notify('Satellite Picture is updated');
      onChange && onChange();

      return file.adminUrl;
    } catch (err: any) {
      showAxiosError(err);

      return '';
    } finally {
      setLoading(false);
    }
  };

  const removePicture = async () => {
    await creditFarmHttp.updateFarm({ ...farm, pictureId: null });
    notify('Satellite Picture is removed');
    setPicture(null);
  };

  return (
    <div>
      <div className={cls.box}>
        {picture ? (
          <FileControlled
            adminUrl={picture.adminUrl}
            downloadFilename={'Satellite Picture.jpg'}
            onDelete={removePicture}
            wrapClass={cls.fileWrap}
            zoom
            fullWidth
            height={220}
          />
        ) : (
          <FileUpload uploadAction={uploadPicture} className={cls.uploadBox} type="image" />
        )}
      </div>
      <div className={cls.title}>
        Satellite Picture
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    marginTop: 18,
  },
  uploadBox: {
    height: 220,
    width: '100%',
  },
  title: {
    marginTop: 5,
    color: '#272727',
    fontFamily: 'Outfit, sans-serif',
    fontSize: 15,
    fontWeight: 600,
  },
  fileWrap: {
    justifyContent: 'flex-start',
  },
});
