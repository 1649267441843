import { NumberFieldProps, NumberField } from 'react-admin';

export const MoneyField = (props: NumberFieldProps, { locales = 'en-ES' }) => (
  <NumberField
    style={{ whiteSpace: 'nowrap' }}
    options={{ style: 'currency', currency: 'USD' }}
    locales={locales}
    emptyText="$ 0"
    {...props}
  />
);
