export interface OrderTransaction {
  id: number;
  title: string;
  type: OrderTransactionType;
  createdAt: string;
  amount: number;
  partnerClass: string;
  partnerId?: number;
  balanceBefore: number;
  balanceAfter: number;
}

export type OrderTransactionType = 'income' | 'outcome';

export enum OrderTransactionPartner {
  PAID_WITH_BANK = 'paid_with_bank',
  MANUAL = 'manual',
}

export const orderPartnerMap = {
  [OrderTransactionPartner.PAID_WITH_BANK]: 'Paid with bank',
  [OrderTransactionPartner.MANUAL]: 'Manual',
};
