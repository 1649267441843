import React from 'react';
import { Identifier, ListProps, RowClickFunction, TextField, useRedirect } from 'react-admin';

import { useExporter, useRbacEdit, useRbacList, useRbacReadonly } from '@hooks';
import { Forbidden, Grid, GridList, TextInput } from '@components';
import cls from './ManualsList.module.css';

export const ManualsList = (props: ListProps) => {
  const rbacList = useRbacList();
  const rbacReadonly = useRbacReadonly();
  const redirect = useRedirect();
  const exporter = useExporter({
    filename: 'Manuals',
    props: {
      'id': {
        label: 'ID',
      },
      'name': {
        label: 'Title',
      },
      'platform': {
        label: 'Platform',
      },
      'team': {
        label: 'Team',
      },
      'description': {
        label: 'Description',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  const filters = [
    <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  ];

  const rowClick: RowClickFunction = (id: Identifier, resource: string) => {
    if (rbacReadonly) {
      redirect(`/${resource}/${id}?view`);
      return false;
    }
    return 'edit';
  };

  return (
    <GridList
      {...props}
      filters={filters}
      sort={{ field: 'id', order: 'DESC' }}
      exporter={exporter}
    >
      <Grid
        rowClick={rowClick}
        defaultColumns={'id name platform team'.split(' ')}
        className={cls.grid}
      >
        <TextField source="id" label="ID" />
        <TextField source="name" label="Title" />
        <TextField source="platform" />
        <TextField source="team" />
        <TextField source="description" />
      </Grid>
    </GridList>
  );
};
