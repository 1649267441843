import { useNotify, useRefresh } from 'react-admin';

export const useUpdated = (message = 'Updated') => {
  const refresh = useRefresh();
  const notify = useNotify();

  const onUpdated = () => {
    refresh();
    notify(message);
  };

  return onUpdated;
};
