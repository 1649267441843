import React, { useEffect, useState } from 'react';
import { ListProps, TextField } from 'react-admin';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  CheckboxField,
  MoneyField,
  SelectInput,
  DateField,
  DateRangeInput, DateRangeSelect,
} from '@components';
import { useExporter, useRbacList } from '@hooks';
import { FinanceDepositOptions, purposeOptions } from '@types';
import { financeDepositHttp } from '@network';
import { FinanceDocsField } from '@pages/finance-withdrawal/FinanceDocsField';
import cls from './finance-deposit-list.module.css';

const clientFilterOptions = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
];

const receivedFilterOptions = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
];

export const FinanceDepositList = (props: ListProps) => {
  const rbacList = useRbacList();
  const exporter = useExporter({
    filename: 'Deposits',
    props: {
      'id': {
        label: 'ID',
      },
      'code': {
        label: 'Concept',
      },
      'accountAccount': {
        label: 'Account',
      },
      'creditCode': {
        label: 'Credit Code',
      },
      'amount': {
        label: 'Amount',
        transform: 'money',
      },
      'paymentDate': {
        label: 'Payment Date',
        transform: 'date',
      },
      'creditAccredited': {
        label: 'Accredited',
      },
      'purpose': {
        label: 'Purpose',
      },
      'details': {
        label: 'Details',
      },
      'received': {
        label: 'Received',
        transform: 'boolean',
      },
      'receipts': {
        label: 'Receipts',
        transform: 'files',
      },
      'invoices': {
        label: 'Invoices',
        transform: 'files',
      },
    },
  });

  const [depositConceptsOptions, setDepositConceptsOptions] =
    useState<{id: string; name: string;}[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const depositOptions: FinanceDepositOptions = await financeDepositHttp.options();

        if (!depositOptions) {
          throw new Error('The lists of options has not been received');
        }

        setDepositConceptsOptions((depositOptions?.concept || []).map(option => ({
          id: option,
          name: option,
        })));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      exporter={exporter}
      filters={[
        <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 220 }} />,
        <SelectInput key="purpose" source="purpose" choices={purposeOptions} label="Purpose" />,
        <SelectInput key="concept"
          source="concept"
          choices={depositConceptsOptions}
          label="Concept" />,
        <SelectInput key="creditCode"
          source="creditCode"
          choices={clientFilterOptions}
          label="Client" />,
        <SelectInput key="received"
          source="received"
          choices={receivedFilterOptions}
          label="Received" />,
        <DateRangeSelect
          key="createdAt"
          source="createdAt"
          label="Created/Payment date"
          alwaysOn
          choices={[
            { id: 'createdAt', name: 'Created At' },
            { id: 'paymentDate', name: 'Payment Date' },
          ]}
        />,
      ]}
      sort={{ field: 'id', order: 'DESC' }}
      className={cls.page}
    >
      <Grid
        rowClick="edit"
        className={cls.grid}
        defaultColumns={'code amount paymentDate purpose received receipts'.split(' ')}
      >
        <TextField source="id" label="ID" />
        <TextField source="code" label="Concept" />
        <TextField source="accountAccount" label="Account" />
        <TextField source="creditCode" label="Credit code" />
        <MoneyField source="amount" label="Amount" />
        <DateField source="paymentDate" label="Payment Date" />
        <TextField source="creditAccredited" label="Accredited" />
        <TextField source="purpose" />
        <TextField source="details" />
        <CheckboxField source="received" />
        <FinanceDocsField source="receipts" label="Receipts" modelName="Deposit" />
        <FinanceDocsField source="invoices" label="Invoices" modelName="Deposit" />
        <DateField source="createdAt" label="Created at" />
      </Grid>
    </GridList>
  );
};
