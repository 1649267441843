import { StatusFieldOptions } from '@components/StatusField';

export const smsStatusFieldOptions: StatusFieldOptions[] = [
  { id: 'done', name: 'Done', type: 'success' },
  { id: 'sending', name: 'Sending', type: 'info' },
  { id: 'waiting', name: 'Waiting', type: 'warning' },
  { id: 'stop', name: 'Stop', type: 'error' },
];

export const messageStatusFieldOptions: StatusFieldOptions[] = [
  { id: 'success', name: 'Success', type: 'success' },
  { id: 'sending', name: 'Sending', type: 'info' },
  { id: 'waiting', name: 'Waiting', type: 'warning' },
  { id: 'error', name: 'Error', type: 'error' },
];


