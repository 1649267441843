import { TextInputProps } from 'react-admin';

import { TextInput } from '@components';

export const JsonInput = (props: TextInputProps) => (
  <TextInput
    {...props}
    multiline={true}
    format={(value: Record<string, any>) => JSON.stringify(value, null, 2)}
    parse={(value: string) => {
      try {
        const nextValue = JSON.parse(value);
        return nextValue;
      } catch (e: any) {
        return null;
      }
    }}
  />
);
