import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';

import { reportsHttp } from '@network';
import { InitialCard } from '@components';
import { useSettingsContext } from '@providers/settings-provider';

interface Props {
  range: string;
  type: string;
  setLoader: (num: number, value: boolean) => void;
}

const colors = {
  repaid: '#3F51B5',
  active: '#673AB7',
  delay: '#CDDC39',
  signed: '#4CAF50',
};
type Field = keyof typeof colors;

export const GeneralNumbersGraphReport = ({ range, type, setLoader }: Props) => {
  const { admin } = useSettingsContext();
  const [numbers, setNumbers] = useState<any[]>([]);
  const id = 'general-numbers-graph-report';

  const loadNumbers = async () => {
    setLoader(2, true);
    try {
      const nextNumbers = await reportsHttp.generalNumbersGraph({ range, type });
      setNumbers(nextNumbers);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(2, false);
    }
  };

  useEffect(() => {
    if (admin) {
      loadNumbers();
    }
  }, [range, type]);

  useLayoutEffect(() => {
    const root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'panX',
      wheelY: 'zoomX',
      layout: root.verticalLayout,
    }));

    numbers && numbers.length > 0 && chart.set('scrollbarX', am5.Scrollbar.new(root, {
      orientation: 'horizontal',
    }));

    const xRenderer = am5xy.AxisRendererX.new(root, {});
    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: 'period',
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {}),
    }));
    xAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'wrap',
      textAlign: 'center',
      fontSize: 12,
      paddingTop: 10,
      paddingBottom: 10,
    });
    xAxis.data.setAll(numbers);

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      numberFormat: '[fontSize:12px]#     [/]',
      renderer: am5xy.AxisRendererY.new(root, { strokeOpacity: 0.1 }),
    }));

    const legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50,
    }));

    function setColor(fieldName: Field) {
      const color = colors[fieldName];
      return am5.color(color);
    }

    function makeSeries(name: string, fieldName: Field) {
      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        categoryXField: 'period',
        fill: setColor(fieldName),
        stroke: setColor(fieldName),
      }));

      series.columns.template.setAll({
        tooltipText: '{name}: {valueY}',
        tooltipY: am5.percent(10),
      });

      series.data.setAll(numbers);
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: '',
            fontSize: 10,
            fill: root.interfaceColors.get('alternativeText'),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    }

    makeSeries('Repaid', 'repaid');
    makeSeries('Active', 'active');
    makeSeries('Delayed and Restructured', 'delay');
    makeSeries('Signed', 'signed');

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [id, numbers]);
  const height = numbers && numbers.length > 0 ? 500 : 80;

  return (
    <InitialCard left="Credits graph" extraPadding autoHeight mt={22}>
      <br />
      <div id={id} style={{ marginTop: -40, width: '100%', height, marginBottom: -30 }} />
    </InitialCard>
  );
};
