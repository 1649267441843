import { ListProps, TextField } from 'react-admin';

import {
  Grid,
  GridList,
  Forbidden,
  TextInput,
  SelectArrayInput,
  DateRangeInput,
} from '@components';
import { useExporter, useRbacList } from '@hooks';
import { StatusField } from '@components/StatusField';
import { smsStatusFieldOptions } from './sms-status-options';
import cls from './SmsList.module.css';

export const SmsList = (props: ListProps) => {
  const rbacList = useRbacList();
  const exporter = useExporter({
    filename: 'Verqor SMS list',
    props: {
      'id': {
        label: 'ID',
      },
      'message': {
        label: 'SMS Message',
      },
      'status': {
        label: 'Send status',
      },
      'createdAt': {
        label: 'Created At',
        transform: 'date',
      },
      'comment': {
        label: 'Comment',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <div className={cls.wrapper}>
      <GridList {...props}
        exporter={exporter}
        filters={Filters}
        sort={{ field: 'id', order: 'DESC' }}
      >
        <Grid
          rowClick="edit"
          defaultColumns={'id'.split(' ')}
          className="sms-list-table"
        >
          <TextField source="id" label="ID" />
          <TextField source="message" label="Message" sortable={false} />
          <StatusField source="status" options={smsStatusFieldOptions} />
          <TextField source="statusDetail" label="Total status" sortable={false} />
          <TextField source="createdAt" label="Created at" />
          <TextField source="comment" label="Comment" sortable={false} />
        </Grid>
      </GridList>
    </div>
  );
};

const Filters = [
  <TextInput
    source="q"
    name="q"
    key="q"
    label="Search"
    alwaysOn
    className={cls.search}
  />,
  <SelectArrayInput
    key="status"
    source="status"
    choices={smsStatusFieldOptions}
    className="MS-field"
    style={{ width: 280 }}
    alwaysOn
  />,
  <DateRangeInput
    key="createdAt"
    source="createdAt"
    name="createdAt"
    label="Created At"
    defaultValue={false}
  />,
];
