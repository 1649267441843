import { Checkbox } from 'antd';
import { makeStyles } from '@mui/styles';
import {
  BooleanFieldProps,
  FunctionField,
  FunctionFieldProps,
} from 'react-admin';

const useStyles = makeStyles({
  box: {
    textAlign: 'center',
  },
  checkbox: {
    '& .ant-checkbox-inner': {
      border: '1px solid #042E6B',
      borderRadius: 4,
    },
    '& .ant-checkbox-checked .ant-checkbox-inner': {
      backgroundColor: '#E1E5ED',
    },
    '& .ant-checkbox-checked .ant-checkbox-inner::after': {
      borderBottom: '1px solid #042E6B',
      borderRight: '1px solid #042E6B',
    },
  },
});

interface Props extends BooleanFieldProps {
  disabled?: boolean;
  width?: number;
}

export const CheckboxField = (props: Partial<FunctionFieldProps> & Props) => {
  const cls = useStyles();
  const source = props.source as string;
  const { width, disabled, ...restProps } = props;

  return (
    <FunctionField
      {...restProps}
      width={width}
      render={(record: any) => (
        <div className={cls.box} style={{ width: props.width }}>
          <Checkbox className={cls.checkbox} checked={!!(record[source] || null)} />
        </div>
      )}
    />
  );
};
