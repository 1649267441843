import { FunctionFieldProps, FunctionField } from 'react-admin';

import { Delivery } from '@types';

export const DeliveryProviderField = (props: Partial<FunctionFieldProps>) => (
  <FunctionField
    {...props}
    render={(record: Delivery | any) => {
      const positions = (record?.positions || []);
      const map: Record<string, {distributor: string, qty: number}> = {};

      for (let i = 0; i < positions.length; i++) {
        const distributor = positions[i].distributor;
        if (!map[distributor]) {
          map[distributor] = { distributor: distributor, qty: 0 };
        }
        map[distributor].qty = map[distributor].qty + positions[i].qty;
      }

      const providers = Object.values(map);

      return (
        <>
          {providers.map(provider => (
            <div key={provider.distributor}>
              <b>{provider.distributor}</b>&nbsp;{<span>x&nbsp;{provider.qty}</span>}
            </div>
          ))}
        </>
      );
    }}
  />
);
