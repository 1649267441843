import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';
import { CreditRisk } from '@types';
import { moneyFormatter } from '@utils';

export const RiskVariablesLegacy = ({ risk }: {risk: CreditRisk}) => {
  const cls = useStyles();
  const { financialPastTable, financialProjectedTable } = risk;

  const past = (financialPastTable?.values || []).reduce((acc, cur) => {
    acc[cur.key] = cur;
    return acc;
  }, {} as any);

  const projected = (financialProjectedTable?.values || []).reduce((acc, cur) => {
    acc[cur.key] = cur;
    return acc;
  }, {} as any);

  return (
    <Row gutter={[20, 20]}>
      <Col md={12} sm={24}>
        <table className={cls.table}>
          <thead>
            <tr>
              <th colSpan={2}>Ciclo anterior</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Superficie</td>
              <td>{past.area.value}</td>
            </tr>
            <tr>
              <td>Rendimiento</td>
              <td>{past.tons.value}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{past.totalTons.value}</td>
            </tr>
            <tr>
              <td>Precio de venta</td>
              <td>{moneyFormatter.format(past.price.value || 0)}</td>
            </tr>
            <tr>
              <td>Ventas</td>
              <td>
                {moneyFormatter.format(past.operationCosts.value || 0)}
                <div className={cls.tablePercent}>{past.operationCosts.percent || 0}%</div>
              </td>
            </tr>
            <tr>
              <td>Gastos de operación</td>
              <td>
                {moneyFormatter.format(past.operationCostsCalculated.value || 0)}
                <div className={cls.tablePercent}>
                  {past.operationCostsCalculated.percent || 0}%
                </div>
              </td>
            </tr>
            <tr>
              <td>Neto</td>
              <td>
                {moneyFormatter.format(past.netProfit.value || 0)}
                <div className={cls.tablePercent}>{past.netProfit.percent || 0}%</div>
              </td>
            </tr>
            <tr>
              <td>Ingresos Comprobables</td>
              <td>
                {moneyFormatter.format(past.verifiedIncome.value || 0)}
                <div className={cls.tablePercent}>{past.verifiedIncome.percent || 0}%</div>
              </td>
            </tr>
            <tr>
              <td>Ingresos Netos Ajustados</td>
              <td>
                {moneyFormatter.format(past.fixedNetProfit.value || 0)}
                <div className={cls.tablePercent}>{past.fixedNetProfit.percent || 0}%</div>
              </td>
            </tr>
            <tr>
              <td>Result</td>
              <td className={cls.tableResult}>No comprobable</td>
            </tr>
          </tbody>
        </table>
      </Col>
      <Col md={12} sm={24}>
        <table className={cls.table}>
          <thead>
            <tr>
              <th colSpan={2}>Ciclo proyectoad</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Superficie</td>
              <td>{projected.area.value}</td>
            </tr>
            <tr>
              <td>Rendimiento</td>
              <td>{projected.tons.value}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{projected.totalTons.value}</td>
            </tr>
            <tr>
              <td>Precio de venta</td>
              <td>{moneyFormatter.format(projected.price.value || 0)}</td>
            </tr>
            <tr>
              <td>Ventas</td>
              <td>
                {moneyFormatter.format(projected.operationCosts.value || 0)}
                <div className={cls.tablePercent}>{projected.operationCosts.percent || 0}%</div>
              </td>
            </tr>
            <tr>
              <td>Gastos de operación</td>
              <td>
                {moneyFormatter.format(projected.operationCostsCalculated.value || 0)}
                <div className={cls.tablePercent}>
                  {projected.operationCostsCalculated.percent || 0}%
                </div>
              </td>
            </tr>
            <tr>
              <td>Neto</td>
              <td>
                {moneyFormatter.format(projected.netProfit.value || 0)}
                <div className={cls.tablePercent}>{projected.netProfit.percent || 0}%</div>
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: 11 }}>Ingresos Proyectados Ajustados</td>
              <td>
                {moneyFormatter.format(projected.adjustedVerifiedIncome.value || 0)}
                <div className={cls.tablePercent}>
                  {projected.adjustedVerifiedIncome.percent || 0}%
                </div>
              </td>
            </tr>
            <tr>
              <td>Ingresos Netos Ajustados</td>
              <td>
                {moneyFormatter.format(projected.adjustedNetProfit.value || 0)}
                <div className={cls.tablePercent}>{projected.adjustedNetProfit.percent || 0}%</div>
              </td>
            </tr>
            <tr>
              <td>Result</td>
              <td className={cls.tableResult}>No comprobable</td>
            </tr>
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

const useStyles = makeStyles({
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& th, & td': {
      borderTop: '1px solid #042E6B',
      borderLeft: '1px solid #042E6B',
    },
    '& th': {
      padding: '3px 12px',
      background: '#042E6B',
      color: '#FFF',
      fontSize: 12,
      fontWeight: 500,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    '& td': {
      width: '50%',
      position: 'relative',
      padding: '3px 8px',
      fontSize: 12,
      fontWeight: 500,
      color: '#042E6B',
      '&:last-child': {
        fontWeight: 400,
        backgroundColor: '#F0F3FB',
        borderRight: '1px solid #042E6B',
      },
    },
    '& tr:last-child td:first-child': {
      borderBottomLeftRadius: 5,
      borderBottom: '1px solid #042E6B',
    },
    '& tr:last-child td:last-child': {
      borderBottomRightRadius: 5,
      borderBottom: '1px solid #042E6B',
    },
  },
  tablePercent: {
    position: 'absolute',
    width: 60,
    height: '100%',
    right: 0,
    top: 0,
    fontSize: 11,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: '1px solid #042E6B',
  },
  tableResult: {
    textAlign: 'center',
    color: '#F65434 !important',
  },
});
