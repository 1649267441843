import { Http } from './http';
import { CreditRisk, IFinancialTable } from '@types';
import { RevenueDataProps } from '@pages/credit-risk/analysis/components/RevenueTable';
import { CreditBureauData } from '@pages/credit-risk/analysis/components/CreditBureau';
import { TaxCropDataProps } from '@pages/credit-risk/analysis/components/TaxCropTable';
import { ScoreTableData } from '@pages/credit-risk/analysis/components/ScoreTable';
import { DropdownInfoDataProps } from '@pages/credit-risk/analysis/components/DropdownInfoWidget';
import { TotalVariablesProps } from '@pages/credit-risk/analysis/components/TotalVariablesTable';

class CreditRiskHttp extends Http {
  prefix = 'credit-risk';

  async calculate(riskId: number, params: CalculateParams): Promise<CreditRisk> {
    const res = await this.post<CreditRisk>(`/${riskId}/calculate`, params);

    return res.data;
  }

  async calculateLegacy(dto: CalculateLegacyParams): Promise<CreditRisk> {
    const res = await this.post<CreditRisk>('/calculate-legacy', dto);

    return res.data;
  }

  async complete(id: number): Promise<CreditRisk> {
    const res = await this.put<CreditRisk>(`/complete/${id}`);

    return res.data;
  }

  async downloadFiles(params: DownloadFiles) {
    const { riskId } = params;
    await this.downloadZip(`/${riskId}/download-files`, 'Loan Tape File.zip');
  }

  async downloadFilesCounter(params: DownloadFilesCounter) {
    const { riskId } = params;
    const res = await this.get(`/${riskId}/download-files-counter`);

    return res.data;
  }
}

export const creditRiskHttp = new CreditRiskHttp();

interface CalculateParams {
  tablePastCycle?: {rows: TablePastCycleRow[]};
  tableProjectedCycle?: {rows: TableProjectedCycleRow[]};
  tableCrop?: {rows: TableCropRow[]};
  tableFiscal?: {rows: TableFiscalRow[]};
  tableCreditBureau?: {rows: TableCreditBureauRow[]};
  tableCreditBureauScore?: {rows: TableCreditBureauScoreRow[]};
  totalVariables?: TotalVariablesProps;
}

export type TablePastCycleColumn = 'Hectares' | 'Yield per Ton' | 'Total Tons' | 'Price per Ton'
  | 'Total Income' | 'Operative Costs per Hectare' | 'Total Costs' | 'Net Profit'
  | 'Verified Income' | '% verifiable' | 'Adjusting Variable' | 'Adj. Net Profit';

export interface TablePastCycleRow {
  title: string | TablePastCycleColumn;
  value: string | number | null;
  format: 'number' | 'percent' | 'money' | 'text';
  separator?: string;
  disabled?: boolean;
  tooltip?: string;
}

export type TableProjectedCycleColumn = 'Hectares' | 'Yield per Ton' | 'Total Tons'
  | 'Price per Ton' | 'Total Income' | 'Operative Costs per Hectare' | 'Total Costs'
  | 'Net Profit' | 'Adjusted Price per Ton' | 'Adjusted Net Sales' | 'Adjusting Variable'
  | 'Adjusted Net Income' | 'Global Sales' | 'Adjusted Global Sales' | 'Adjusted Global Net Income';

export interface TableProjectedCycleRow {
  title: string;
  value: string | number | null;
  format: 'number' | 'percent' | 'money' | 'text';
  for?: string;
  separator?: string;
  disabled?: boolean;
  tooltip?: string;
}

export type TableFiscalKey = 'year' | 'incomeTotal' | 'expensesTotal' | 'expensesProfit' | 'declarationIncome'
  | 'declarationProfit';

export interface TableFiscalHeader {
  key: TableFiscalKey;
  label: string;
}

export type TableFiscalRow = {
  [identifier in TableFiscalKey]: string | number | null;
};

export type TableCropKey = 'year' | 'incomeTotal' | 'profit';

export interface TableCropHeader {
  key: TableCropKey;
  label: string;
}

export type TableCropRow = {
  [identifier in TableCropKey]: string | number | null;
};

export type TableBureauKeys = 'title' | 'accredited' | 'ppdc';

export interface TableBureauHeader {
  key: TableBureauKeys;
  label: string;
}

export type TableBureauScoreKeys = 'entity' | 'score' | 'isFixed';

export interface TableBureauScoreHeader {
  key: TableBureauScoreKeys;
  label: string;
}

export type TableCreditBureauRow = {
  [identifier in TableBureauKeys]: string | number | null;
}

export type TableCreditBureauScoreRow = {
  entity: string | number | null;
  score: string | number | null;
  isFixed?: boolean;
}

interface CalculateLegacyParams {
  financialPastTable?: IFinancialTable;
  financialProjectedTable?: IFinancialTable;
  taxExpenditures?: RevenueDataProps;
  creditBureau?: CreditBureauData;
  scoreCashRatio?: ScoreTableData;
  taxCrop?: TaxCropDataProps;
  scoreAmount?: DropdownInfoDataProps;
  totalVariables?: TotalVariablesProps;
  id: number;
}

export interface DownloadFiles {
  riskId: number;
  reportBlob?: Blob;
}

export interface DownloadFilesCounter {
  riskId: number;
}
