import { ListProps, TextField, SimpleList } from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import { TextInput, Grid, GridList, DateField } from '@components';
import { dateFormatter } from '@utils';

export const ProductBrandList = (props: ListProps) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (isSmall) {
    return (
      <GridList {...props}
        filters={Filters}
        sort={{ field: 'id', order: 'DESC' }}
      >
        <SimpleList
          primaryText={record => record.name}
          tertiaryText={record => dateFormatter.toDate(record.createdAt)}
        />
      </GridList>
    );
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick="edit"
        defaultColumns={['name', 'synonym', 'updatedAt']}
      >
        <TextField source="name" label="Brand" />
        <TextField source="synonym" label="URL" />
        <DateField source="updatedAt" label="Updated" showTime />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
];
