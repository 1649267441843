import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import { BalanceGraphData, financeDashboardHttp, PeriodType } from '@network';
import { DateRangeControlledInput, InitialCard, InitialFilters, SelectControlledInput } from '@components';
import cls from './vertical-double-graph.module.css';

const colors = {
  incomeCredit: '#002A77',
  outcomeCredit: '#FD8A32',
  incomeOperations: '#97BDDA',
  outcomeOperations: '#C06437',
};

type Field = keyof typeof colors;

interface DataProps {
  period: string;
  incomeOperations: number;
  incomeCredit: number;
  outcomeOperations: number;
  outcomeCredit: number;
}

const Graph = ({ data }: { data: DataProps[] }) => {
  const id = 'finance-vertical-graph';

  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'panX',
      wheelY: 'zoomX',
      paddingLeft: 0,
      layout: root.verticalLayout,
    }));

    function setColor(fieldName: Field) {
      const color = colors[fieldName];
      return am5.color(color);
    }

    const legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50,
    }));

    const xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.3,
      cellEndLocation: 0.7,
      minorGridEnabled: true,
    });

    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: 'period',
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {}),
    }));

    xRenderer.grid.template.setAll({ location: 1 });

    xAxis.data.setAll(data);

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1,
      }),
    }));

    function makeSeries(
      name: any,
      fieldName: any,
      onTop = false,
    ) {
      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        categoryXField: 'period',
        fill: setColor(fieldName),
        stroke: setColor(fieldName),
      }));

      series.columns.template.setAll({
        tooltipText: '[bold]{name}[/]: {valueY}$',
        width: am5.percent(100),
        tooltipX: am5.percent(50),
        tooltipY: am5.percent(onTop ? 100 : 0),
        strokeOpacity: 0,
      });

      series.data.setAll(data);
      series.appear();

      series.bullets.push(function() {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: '',
            fill: root.interfaceColors.get('alternativeText'),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerX: am5.p100,
            centerY: am5.p50,
            text: '',
            fill: am5.color(0xffffff),
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    }

    makeSeries('Inflows - operation', 'incomeOperations');
    makeSeries('Outflows - operation', 'outcomeOperations', true);
    makeSeries('Inflows - credits', 'incomeCredit');
    makeSeries('Outflows - credits', 'outcomeCredit', true);

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [id, data]);

  return (
    <div id={id} style={{ width: '100%', height: 500 }}/>
  );
};

export const VerticalDoubleGraph = () => {
  const [range, setRange] = useState<string>('');
  const [type, setType] = useState<PeriodType>('week');
  const [data, setData] = useState<DataProps[] | null>(null);

  const dataTransform = (value: BalanceGraphData[]): DataProps[] => {
    return value.map((item: BalanceGraphData) => ({
      period: item.period,
      incomeOperations: item.values.deposit.operations,
      incomeCredit: item.values.deposit.credits,
      outcomeOperations: 0 - item.values.withdrawal.operations,
      outcomeCredit: 0 - item.values.withdrawal.credits,
    }));
  };

  const getData = async () => {
    try {
      const dataResp: BalanceGraphData[] = await financeDashboardHttp.getBalanceGraph(range, type);

      if (!dataResp) {
        throw new Error('The balance has not been received');
      }
      setData(dataTransform(dataResp));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [range, type]);

  return (
    <InitialCard
      center="Inflows credits, operations and Outflows credits, operations"
      extraPadding
      autoHeight
    >
      <div className={cls.wrap}>
        <InitialFilters>
          <DateRangeControlledInput range={range} setRange={setRange} />
          <SelectControlledInput
            defaultValue={type}
            value={type}
            options={[
              { value: 'day', label: 'Day' },
              { value: 'week', label: 'Week' },
              { value: 'month', label: 'Month' },
              { value: 'year', label: 'Year' },
            ]}
            onChange={nextType => setType(nextType)}
          />
        </InitialFilters>

        {data ? <Graph data={data} /> : null}
      </div>
    </InitialCard>
  );
};
