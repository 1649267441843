import { Http } from './http';
import { Crop, CropCategory } from '@types';

class CropHttp extends Http {

  async getAll() {
    const res = await this.get<Crop[]>('/crop', { params: { _sort: 'nameMx', _order: 'ASC' } });

    return res.data;
  }

  async create(params: CreateCropRequest) {
    const res = await this.post<Crop>('/crop', params);

    return res.data;
  }

  async update(params: UpdateCropRequest) {
    const { id } = params;
    const res = await this.put<Crop>(`/crop/${id}`, params);

    return res.data;
  }

  async remove(id: number) {
    const res = await this.delete<Crop>(`/crop/${id}`);

    return res.data;
  }

  async createCategory(params: CreateCropCategoryRequest) {
    const res = await this.post<CropCategory>('/crop-category', params);

    return res.data;
  }

  async updateCategory(params: UpdateCropCategoryRequest) {
    const { id } = params;
    const res = await this.put<CropCategory>(`/crop-category/${id}`, params);

    return res.data;
  }

  async removeCategory(id: number) {
    const res = await this.delete<CropCategory>(`/crop-category/${id}`);

    return res.data;
  }
}

export const cropHttp = new CropHttp();

interface CreateCropRequest {
  nameMx: string;
  nameEn: string | null;
}

interface UpdateCropRequest extends CreateCropRequest {
  id: number;
}

interface CreateCropCategoryRequest {
  nameMx: string;
  image: string;
  nameEn: string | null;
}

interface UpdateCropCategoryRequest extends CreateCropCategoryRequest {
  id: number;
}
