import { clsx } from 'clsx';
import { Button } from 'antd';
import * as React from 'react';
import { TableOutlined } from '@ant-design/icons';

import { useSettingsContext } from '@providers';
import cls from './OptionsButton.module.css';

interface Props {
  text?: string;
  onClick?: (param: boolean) => void;
  className?: string;
}

export const OptionsButton = ({ text, onClick, className }: Props) => {
  const { setSettingsOpened } = useSettingsContext();

  return (
    <Button
      className={clsx(cls.btn, className, 'options-btn')}
      onClick={onClick ? () => onClick(true) : () => setSettingsOpened(true)}
    >
      <TableOutlined className={cls.icon} />
      <span className={cls.text}>{text ? text : 'Edit Table'}</span>
    </Button>
  );
};
