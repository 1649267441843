import { clsx } from 'clsx';
import { useRedirect } from 'react-admin';

import { BlueButton } from '@components';
import { RiskAdminDashboard, RiskAdminDashboardScore } from '@types';
import cls from './Variables.module.css';

interface SectionProps {
  item: RiskAdminDashboardScore | Omit<RiskAdminDashboardScore, 'categoryId'>;
}

const Section = ({ item: { categoryTitle, totalRecords, totalPercent } }: SectionProps) => {
  return (
    <div className={cls.section}>
      <div className={cls.sectionTitle}>{categoryTitle}</div>
      <div className={cls.sectionCounter}>
        <div className={cls.sectionCounterValue}>{totalRecords}</div>
        <div className={cls.sectionCounterLabel}>Variables</div>
      </div>
      <div className={clsx(
        cls.sectionPercent,
        !totalPercent && cls.empty,
      )}>{totalPercent ? `${totalPercent}%` : 'No Score'}</div>
    </div>
  );
};

export const Variables = ({ data, readonly }: {data: RiskAdminDashboard, readonly?: boolean}) => {
  const redirect = useRedirect();
  const { totalPercent, score, otherTitles } = data;

  const getOtherData = (): Omit<RiskAdminDashboardScore, 'categoryId'> => ({
    categoryTitle: 'Other variables',
    totalRecords: otherTitles.length,
    totalPercent: 0,
  });

  return (
    <div className={clsx(cls.wrap, cls.variablesView)}>
      <div className={cls.row}>
        <div className={cls.col}>
          <div className={cls.title}>Score variables</div>
          <div className={cls.sections}>
            {score.length ? score.map((i: RiskAdminDashboardScore, key: number) => (
              <Section item={i} key={key} />
            )) : null}
          </div>
          <div className={cls.actions}>
            <div className={clsx(cls.action, cls.actionBtn)}>
              <BlueButton onClick={() => redirect('/risk/1?variables')}
                extraPadding
                className={cls.action}>
                Edit
              </BlueButton>
            </div>
            <div className={clsx(cls.action, cls.actionTotal)}>Total Score {totalPercent}%</div>
          </div>
        </div>
        <div className={cls.col}>
          <Section item={getOtherData()} />
        </div>
      </div>
    </div>
  );
};
