import { useEffect, useState } from 'react';
import { Table } from 'antd';

import { exportToCsv } from '@utils/export-to-csv';
import { useDebounce } from '@hooks';
import { InputSearch } from '@components/inputs/InputSearch';
import { FinanceActivationAdd } from '../FinanceActivationAdd';
import { FinanceActivationExport } from '../FinanceActivationExport';
import { ActivationTableType } from '@pages/finance-activation/FinanceActivationTables';
import cls from './FinanceActivationTable.module.css';

interface Props {
    columns: any[];
    list: any[];
    type: ActivationTableType;
    add?: () => void;
}

export const FinanceActivationTable = ({ columns, list, type, add }: Props) => {
  const [filteredList, setFilteredList] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState('');

  const debouncedSearchInput = useDebounce(searchInput, 250);

  const handleExport = () => {
    exportToCsv(filteredList, type);
  };

  useEffect(() => {
    setFilteredList(list);
  }, []);

  useEffect(() => {
    let newList;
    switch (type) {
      case 'credits':
        newList = list.filter(item =>
          item.code?.includes(searchInput)
            || item.fullName?.toLowerCase().includes(searchInput.toLowerCase()));
        break;
      case 'leads':
        newList = list.filter(item =>
          item.phone?.includes(searchInput)
            || item.name?.toLowerCase().includes(searchInput.toLowerCase()));
        break;
      case 'users':
        newList = list.filter(item =>
          item.code?.includes(searchInput)
            || item.fullName?.toLowerCase().includes(searchInput.toLowerCase()));
        break;
      case 'associates':
        newList = list.filter(item =>
          item.phone?.includes(searchInput)
            || item.id?.toString().includes(searchInput)
            || item.name?.toLowerCase().includes(searchInput.toLowerCase()));
        break;
      case 'retailers':
        newList = list.filter(item =>
          item.id?.toString().includes(searchInput)
            || item.name?.toLowerCase().includes(searchInput.toLowerCase()));
        break;
      case 'buyers':
        newList = list.filter(item =>
          item.id?.toString().includes(searchInput)
            || item.name?.toLowerCase().includes(searchInput.toLowerCase()));
        break;
      default:
        newList = list;
    }

    setFilteredList(newList);
  }, [debouncedSearchInput, type]);

  return (
    <div className={cls._}>
      <div className={cls.actions}>
        <InputSearch value={searchInput} setValue={setSearchInput} />
        <FinanceActivationExport onClick={handleExport} />
        {add && <FinanceActivationAdd add={add} />}
      </div>

      <div className={cls.body}>
        <Table
          columns={columns}
          dataSource={filteredList}
          bordered
          pagination={false}
          scroll={{ y: 244, x: 660 }}
          rowKey="id"
        />
      </div>
    </div>
  );
};
