import React, { MutableRefObject, ReactNode, useCallback, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { makeStyles } from '@mui/styles';

import { CreditRisk } from '@types';
import { BlueButton, InitialCard } from '@components';
import { creditRiskHttp } from '@network';
import { CreditSurveyView } from '@pages/credit/CreditEdit/CreditSurveyView';
import cls from '@pages/credit/CreditEdit/CreditSurveyView/CreditSurveyView.module.css';

interface Props {
  risk: CreditRisk;
  forwardRef: MutableRefObject<ReactNode>;
}

export const RiskTapeFile = ({ risk, forwardRef }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [counter, setCounter] = useState<number | null>(null);

  const cls = useStyles();

  const downloadHandler = useCallback(async () => {
    setIsLoading(true);

    const div = forwardRef.current;

    const pdfFilename = `credit_analysis_${risk?.credit?.code}.pdf`;
    await new Promise((resolve) => setTimeout(resolve, 250));

    const downloadReport = async (div: any) => {
      if (div) {
        const canvas = await html2canvas(div);

        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jsPDF('p', 'mm');
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }

        // blob = doc.output('blob');
        // TODO отправить blob на сервер, чтобы упаковать в zip

        doc.save(pdfFilename);
      }
    };

    try {
      await downloadReport(div);
      await creditRiskHttp.downloadFiles({ riskId: risk.id });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [forwardRef]);

  useEffect(() => {
    if (risk) {
      (async () => {
        try {
          const response = await creditRiskHttp.downloadFilesCounter({ riskId: risk.id });

          if (response) {
            setCounter(response);
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [risk]);

  return (
    <InitialCard center="Loan Tape File" contentClass={cls.content} wrapperClass={cls.wrapper}>
      <CreditSurveyView
        credit={risk.credit}
        hide
        loading={isLoading}
        cb={downloadHandler}
        btnText={<>
          <span className={cls.btnText}>Download Files</span>
          <span className={cls.counter}>{counter ? counter + 2 : 1}</span>
        </>}
        btnClass={cls.button}
      />
    </InitialCard>
  );
};

const useStyles = makeStyles({
  wrapper: {
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  button: {
    width: '100%!important',
    padding: '29px 15px 29px!important',
    marginTop: '16px!important',
    display: 'flex!important',
    gap: '8px !important',
    alignItems: 'center!important',
    background: '#002A77!important',
  },
  content: {
    display: 'flex',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  info: {
    borderRadius: 7,
    backgroundColor: '#042E6B',
    marginTop: -44,
    height: 87,
    width: '100%',
    padding: 25,
    paddingRight: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoText: {
    color: '#FFF',
  },
  counter: {
    color: '#042E6B',
    backgroundColor: '#fff',
    width: 20,
    height: 20,
    borderRadius: '50%',
    lineHeight: '20px',
  },
  btnText: {
    color: '#FFF!important',
    fontSize: '16px!important',
  },
});
