import { EditProps, Edit, SimpleForm, useRecordContext } from 'react-admin';
import { Row, Col, Container, TextInput, Forbidden } from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';

const Title = () => {
  const record = useRecordContext();

  return <span>Permission: {record?.name}</span>;
};

export const PermissionEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <SimpleForm toolbar={rbacEdit ? undefined : false}>
        <Container max>
          <Row>
            <Col><TextInput source="name" required /></Col>
            <Col><TextInput source="path" required /></Col>
            <Col><TextInput source="method" required /></Col>
            <Col><TextInput source="module" required /></Col>
          </Row>
        </Container>
      </SimpleForm>
    </Edit>
  );
};
