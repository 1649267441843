import { clsx } from 'clsx';
import { Col, Empty, Row } from 'antd';
import { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import { PaidReportProps } from '@network';
import { InitialCard, Loader } from '@components';
import cls from './Graph.module.css';

interface Props {
  data: PaidReportProps[];
  id: string;
  title: string;
  loading: boolean;
}

export const Graph = ({ data, title, id, loading }: Props) => {
  const colors = ['#B44436', '#204BCE', '#1E2C5C', '#225236', '#FD8A32',
    '#3E8B5B', '#400E14', '#B54446', '#214BDE', '#1F2C6C', '#235246', '#FE8A42', '#3F8B6B', '#410E14',
    '#B64456', '#224BEE', '#1A2C7C', '#245256', '#FF8A52', '#3A8B7B', '#420E24',
    '#B61156', '#2211EE', '#1A117C', '#241156', '#FF6652', '#3A667B', '#421124',
    '#B64456', '#2244EE', '#1A447C', '#244456', '#FF4452', '#3A447B', '#424424'];
  const graphTitle = id.charAt(0).toUpperCase() + id.slice(1);

  useLayoutEffect(() => {
    if (!data.length || loading) {
      return;
    }
    const root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
        innerRadius: 80,
      }),
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'percent',
        categoryField: 'name',
        radius: 40,
      }),
    );

    series.set('colors', am5.ColorSet.new(root, {
      colors: [
        am5.color(0xB44436),
        am5.color(0x204BCE),
        am5.color(0x1E2C5C),
        am5.color(0x225236),
        am5.color(0xFD8A32),
        am5.color(0x3E8B5B),
        am5.color(0x400E14),
        am5.color(0xB54446),
        am5.color(0x214BDE),
        am5.color(0x1F2C6C),
        am5.color(0x235246),
        am5.color(0xFE8A42),
        am5.color(0x3F8B6B),
        am5.color(0x410E14),
        am5.color(0xB64456),
        am5.color(0x224BEE),
        am5.color(0x1A2C7C),
        am5.color(0x245256),
        am5.color(0xFF8A52),
        am5.color(0x3A8B7B),
        am5.color(0x420E24),
        am5.color(0xB61156),
        am5.color(0x2211EE),
        am5.color(0x1A117C),
        am5.color(0x241156),
        am5.color(0xFF6652),
        am5.color(0x3A667B),
        am5.color(0x421124),
        am5.color(0xB64456),
        am5.color(0x2244EE),
        am5.color(0x1A447C),
        am5.color(0x244456),
        am5.color(0xFF4452),
        am5.color(0x3A447B),
        am5.color(0x424424),
      ],
    }));

    series.slices.template.setAll({
      strokeWidth: 2,
      stroke: am5.color(0xffffff),
      cornerRadius: 4,
    });

    series.slices.template.states.create('hover', {
      shadowOpacity: 1,
      shadowBlur: 10,
    });

    series.ticks.template.setAll({
      strokeOpacity: 0.4,
      strokeDasharray: [2, 2],
    });

    series.states.create('hidden', {
      endAngle: -90,
    });

    series.data.setAll(data);
    series.labels.template.set('visible', false);
    series.ticks.template.set('visible', false);
    series.slices.template.setAll({
      tooltipText: '[bold]Name:[/] {name}\n[bold]Qty:[/] {qty}\n[bold]Percent:[/] {percent}%\n[bold]Total Cost:[/] {totalCost}',
    });

    // center text
    chart.seriesContainer.children.push(am5.Label.new(root, {
      textAlign: 'center',
      centerY: am5.p50,
      centerX: am5.p50,
      text: data && data.length > 0 ? `[bold fontSize:22px]${graphTitle}[/]` : undefined,
    }));

    // show/dispose
    series.appear(1000, 100);

    return () => void root.dispose();
  }, [id, data, loading]);

  return (
    <InitialCard left={title} extraPadding wrapperClass={cls.wrap}>
      {loading ? <Loader className={cls.loader} center /> : !data.length ? <Empty /> : <Row>
        <Col sm={24} md={9} xl={15} xxl={18}>
          <div className={clsx(
            cls.labels,
            data.length <= 7 && cls.oneRow,
            data.length > 7 && data.length <= 14 && cls.twoRow,
            data.length > 14 && data.length <= 21 && cls.threeRow,
            data.length > 21 && cls.multiRows,
          )}>
            {data.map((row, idx) => (
              <div key={idx} className={cls.label}>
                <div
                  className={cls.labelColor}
                  style={{ backgroundColor: colors[idx] || colors[0] }}
                />
                <div className={cls.labelText}>{row.name}</div>
                <div className={cls.labelValue}>{row.percent}%</div>
              </div>
            ))}
          </div>
        </Col>
        <Col sm={24} md={15} xl={9} xxl={6}>
          <div className={cls.chart}>
            <div id={id} style={{ height: 300 }} />
          </div>
        </Col>
      </Row>}
    </InitialCard>
  );
};
