import { useEffect, useState } from 'react';
import { required } from 'react-admin';
import { makeStyles } from '@mui/styles';

import { CreditRisk, DealerType, fixedRiskText } from '@types';
import {
  IGrid,
  InitialCard,
  InitialLine,
  InitialRow,
  ManyTagsInput,
  MoneyInput,
  TextInput,
} from '@components';
import { dateFormatter, moneyFormatter } from '@utils';
import { useController } from 'react-hook-form';

const MAX_REQUEST_AMOUNT_LENGTH = 10;

interface Props {
  risk: CreditRisk;
  isFixed: boolean;
  labelGrid?: IGrid;
  valueGrid?: IGrid;
}

export const RiskGeneral = ({ risk, isFixed, labelGrid, valueGrid }: Props) => {
  const { credit } = risk;
  const { user } = credit;
  const {
    field: { value: requestAmount },
  } = useController({ name: 'requestAmount' });
  const [hasRequestAmountError, setHasRequestAmountError] = useState(false);

  useEffect(() => {
    setHasRequestAmountError(`${requestAmount}`.length > MAX_REQUEST_AMOUNT_LENGTH);
  }, [requestAmount]);

  const crops = credit.creditCrops.map(crop => crop.financedCrop.nameMx).join(', ');
  const cls = useStyles();

  const validateRequestAmountLength = (maxLength: number) => (value: string) => {
    if (!value) return;

    const stringValue = value.toString();
    if (stringValue.length > maxLength) {
      return `Number must be no more than ${maxLength} digits`;
    }

    return undefined;
  };

  return (
    <InitialCard left="General information" autoHeight mediumPadding
      lockText={risk.isFixed ? fixedRiskText : null}>
      <div className={cls.box}>
        <InitialRow
          title="Verqor Code"
          labelGrid={labelGrid}
          valueGrid={valueGrid}
        >{credit.code}</InitialRow>
        <InitialRow
          title="Accredited"
          labelGrid={labelGrid}
          valueGrid={valueGrid}
        >{credit.accreditedName}</InitialRow>

        <InitialRow title="Buyers:" labelGrid={labelGrid} valueGrid={valueGrid}>
          <ManyTagsInput source="buyerIds"
            reference={`dealer/by-type/${DealerType.BUYER}`} />
        </InitialRow>
        <InitialRow title="Retailers:" labelGrid={labelGrid} valueGrid={valueGrid}>
          <ManyTagsInput source="retailerIds"
            reference={`dealer/by-type/${DealerType.RETAILER}`} />
        </InitialRow>
        <InitialRow title="Associates:" labelGrid={labelGrid} valueGrid={valueGrid}>
          <ManyTagsInput source="associateIds"
            reference={`dealer/by-type/${DealerType.ASSOCIATE}`} />
        </InitialRow>

        <InitialRow
          title="State"
          labelGrid={labelGrid}
          valueGrid={valueGrid}
        >{user.state?.name}</InitialRow>
        <InitialRow
          title="Crop"
          labelGrid={labelGrid}
          valueGrid={valueGrid}
        >{crops}</InitialRow>
        <InitialRow
          title="Request amount"
          labelGrid={labelGrid}
          valueGrid={valueGrid}
        >
          <div className={cls.amount}>
            <div>
              <MoneyInput
                name="requestAmount"
                source="requestAmount"
                label={false}
                prefix="$"
                validate={[required(), validateRequestAmountLength(MAX_REQUEST_AMOUNT_LENGTH)]}
              />
            </div>
            <div className={cls.amountValue}>
              {hasRequestAmountError ? (
                <span className={cls.error}>
                  {`Request amount must be no more than ${MAX_REQUEST_AMOUNT_LENGTH} digits`}
                </span>
              ) : (
                <>
                  {moneyFormatter.format(requestAmount === null || requestAmount > 0
                    ? requestAmount
                    : risk?.credit?.requestAmount,
                  )}
                </>
              )}
            </div>
          </div>
        </InitialRow>
        <InitialRow
          title="Date"
          labelGrid={labelGrid}
          valueGrid={valueGrid}
        >{dateFormatter.toDateSlash(credit.createdAt)}</InitialRow>
        <InitialRow
          title="Channel"
          labelGrid={labelGrid}
          valueGrid={valueGrid}
        >{user.sourceEn}</InitialRow>
        <div className={cls.personas}>
          <PersonaWidget text="PF - Persona Fisica" isActive={!user.isCompany} />
          <PersonaWidget text="PM - Persona Moral" isActive={user.isCompany} />
        </div>
      </div>
    </InitialCard>
  );
};

const PersonaWidget = ({ text, isActive }: {text: string; isActive: boolean}) => {
  const cls = useStyles();

  return (
    <div className={cls.persona}>
      <div className={isActive ? cls.personaChecked : cls.personaUnchecked} />
      <div className={cls.personaText}>{text}</div>
    </div>
  );
};

const useStyles = makeStyles({
  personas: {
    display: 'flex',
    marginTop: 0,
  },
  persona: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 2,
    border: '1px solid #042E6B',
    background: '#E1E5ED',
    marginRight: 10,
    padding: '7px 10px',
  },
  personaUnchecked: {
    width: 20,
    height: 20,
    borderRadius: 2,
    border: '1px solid #042E6B',
  },
  personaChecked: {
    width: 20,
    height: 20,
    borderRadius: 2,
    border: '1px solid #042E6B',
    backgroundColor: '#042E6B',
  },
  personaText: {
    marginLeft: 8,
    color: '#000',
    fontSize: 13,
    fontWeight: 400,
  },
  box: {
    marginTop: 15,
    fontFamily: 'Outfit, sans-serif',
    color: '#272727',

    '& > .ant-row': {
      marginTop: 0,
    },
  },
  amount: {
    marginBottom: 20,
  },
  amountValue: {
    fontSize: 12,
    color: '#5b5b5b',
  },
  error: {
    color: 'red',
  },
});
