import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SaveFilled } from '@ant-design/icons';
import { EditProps, Edit, SimpleForm, TopToolbar, SaveButton, DeleteButton, useGetOne } from 'react-admin';

import { dateFormatter } from '@utils';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';
import { Container, Forbidden, TextInput, EditorInput, PageHeader, FormRow } from '@components';
import cls from './FaqEdit.module.css';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Faq: {record.id}</span>) : null;

export const FaqEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const [empty, setEmpty] = useState(false);
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();
  const { id } = useParams();

  const { data: record } = useGetOne('faq', { id });
  const editorToolbar = 'undo redo formatselect '
    + 'bold italic backcolor bullist numlist outdent indent table '
    + 'removeformat code help '
    + 'alignleft aligncenter alignright alignjustify ';

  const validate = (values: any) => {
    const errors: any = {};
    setEmpty(false);

    ['question', 'answer'].forEach((key: string) => {
      if (!values[key]) {
        errors[key] = 'This field is required';
      }
      if (key === 'answer' && !values[key]) {
        setEmpty(true);
      }
    });

    return errors;
  };

  const Actions = () => {
    return (
      <TopToolbar className={cls.actions}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon}/>}
          label="Save"
          type="button"
          className={clsx(cls.action, cls.actionSave)}
          variant="outlined"
        />
        <DeleteButton
          className={clsx(cls.action, cls.actionDelete)}
        />
      </TopToolbar>
    );
  };

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps} className={clsx(cls.page, 'faq-page-wrapper')}>
      <>
        <PageHeader
          title="Individual FAQ"
          linkText="Back to all FAQ"
          linkUrl="/faq"
        />

        <SimpleForm
          toolbar={rbacEdit ? <Actions/> : false}
          className={cls.form}
          validate={validate}
        >
          <Container max>
            <Row gutter={24} className={cls.wrapper}>
              <Col xs={24} className={cls.wrapperCol}>
                <div className={clsx(cls.block, cls.blockContent)}>
                  <Row gutter={24} className={cls.inner}>
                    <Col xs={24} xl={12} className={clsx(cls.innerCol, cls.innerColFirst)}>
                      <div className={cls.blockHeader}>Frequently Asked Questions</div>
                      <div className={cls.blockTop}>
                        <div className={cls.readOnlyRow}>
                          <span className={cls.readOnlyLabel}>ID:</span>
                          <span className={cls.readOnlyValue}>{id}</span>
                        </div>

                        <div className={clsx(cls.blockSubHeader, cls.paddingTop)}>Question*</div>

                        <FormRow
                          fullWidth
                          rowClassName={cls.formRow}
                          colTitleClassName={clsx(cls.formLabelCol, cls.formTitleCol)}
                          colChildrenClassName={clsx(cls.formValueCol, cls.formValueColTextarea)}
                        >
                          <TextInput name="question" source="question" required autoFocus multiline label={false}/>
                        </FormRow>
                      </div>
                      <div className={cls.blockBottom}>
                        <div className={clsx(cls.readOnlyRow, cls.paddingTop)}>
                          <span className={cls.readOnlyLabel}>Created at:</span>
                          <span className={cls.readOnlyValue}>{record?.createdAt
                            ? `${dateFormatter.toDateSlash(record.createdAt)} ${dateFormatter.toTime(record.createdAt)}`
                            : ''}
                          </span>
                        </div>
                        <div className={clsx(cls.readOnlyRow, cls.paddingTop)}>
                          <span className={cls.readOnlyLabel}>Updated at:</span>
                          <span className={cls.readOnlyValue}>{record?.updatedAt
                            ? `${dateFormatter.toDateSlash(record.updatedAt)} ${dateFormatter.toTime(record.updatedAt)}`
                            : ''}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} xl={12} className={clsx(cls.innerCol, cls.innerColLast)}>
                      <div className={clsx(cls.blockSubHeader, cls.paddingTop)}>Answer*</div>
                      <FormRow
                        fullWidth
                        rowClassName={clsx(cls.formRow, cls.messageRow)}
                        colTitleClassName={clsx(cls.formLabelCol, cls.formTitleCol)}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formValueColTextarea)}
                      >
                        <EditorInput
                          source="answer"
                          label={false}
                          toolbar={editorToolbar}
                          height={250}
                          className={clsx(cls.editor, empty ? cls.empty : '')}
                          required
                        />
                        {empty
                          ? <span className={cls.errorEditor}>This field is required</span>
                          : null}
                      </FormRow>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>


          {/*<Container max>*/}
          {/*  <Row title="Title">*/}
          {/*    <Col><TextInput source="id" disabled /></Col>*/}
          {/*    <Col> <DateTimeInput source="createdAt" disabled /></Col>*/}
          {/*    <Col><DateTimeInput source="updatedAt" disabled /></Col>*/}
          {/*  </Row>*/}
          {/*  <Row>*/}
          {/*    <Col title="Question">*/}
          {/*      <TextInput source="question" label={false} />*/}
          {/*    </Col>*/}
          {/*    <Col title="Answer">*/}
          {/*      <EditorInput source="answer" label={false} />*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</Container>*/}
        </SimpleForm>
      </>
    </Edit>
  );
};
