import React from 'react';
import { ListProps, TextField } from 'react-admin';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  MoneyField,
  NumberField, SelectArrayInput,
} from '@components';
import { useExporter, useRbacList } from '@hooks';
import { useLoadActivationOptions } from '@pages/finance-campaign/use-load-activation-options';

export const FinanceCampaignList = (props: ListProps) => {
  const rbacList = useRbacList();
  const exporter = useExporter({
    filename: 'Campaigns',
    props: {
      'id': {
        label: 'ID',
      },
      'name': {
        label: 'Campaign',
      },
      'description': {
        label: 'Objective',
      },
      'activationsTotal': {
        label: 'Activations',
      },
      'budget': {
        label: 'Budget',
        transform: 'money',
      },
      'mql': {
        label: 'MQL\'s',
      },
      'cpl': {
        label: 'Average CPL',
        transform: 'money',
      },
    },
  });
  const activationOptions = useLoadActivationOptions();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props} exporter={exporter} filters={[
      <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
      <SelectArrayInput
        key="activationIds"
        source="activationIds"
        choices={activationOptions}
        alwaysOn
        className="MS-field"
        label="Activation"
        style={{ width: 640 }}
      />,
    ]}>
      <Grid
        rowClick="edit"
        defaultColumns={'name description activationsTotal budget mql cpl'.split(' ')}
      >
        <TextField source="name" label="Campaign" autoWidth />
        <TextField source="description" label="Objective" autoWidth />
        <NumberField source="activationsTotal" label="Activations" />
        <MoneyField source="budget" label="Budget" />
        <NumberField source="mql" label="MQL's" />
        <MoneyField source="cpl" label="Average CPL" />
      </Grid>
    </GridList>
  );
};
