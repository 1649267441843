import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Input, Select } from 'antd';
import { useRequest } from '@hooks';
import { useNotify } from 'react-admin';
import { useListContext } from 'ra-core';
import { DollarOutlined, PercentageOutlined } from '@ant-design/icons';

import { loanContractHttp } from '@network';
import { BlueButton, BlueOutlineButton, AntdDateControlledInput } from '@components';
import { useModalContext } from '@providers';
import { useLoanContractOptions } from './use-loan-contract-options';

enum BulkProps {
  'Type of Loan', // text
  'Type of Payments', // text
  'Product', // text
  'Expected Payments', // integer
  'Periodicity', // integer
  'Interest Rate', // percent
  'Interest Tax', // percent
  'Moratory Interest Rate', // percent
  'Moratory Tax', // percent
  'Disposal Fee', // percent
  'Disposal Fee Tax', // percent
  'DPD', // parDelay options
  'Days on alert', // integer
  'Legal Representative', // text
  'Date of birth', // date
  'RFC', // text
  'Colony', // text
  'State', // states options
  'ZIP', // text
  'Crop State', // states options
  'Crop City', // cities options
  'Crop Zone', // cropZones options
  'Current Number of harvests', // integer
  'Insurance Company', // text
  'Insurance Policy Amount', // money
}

type BulkProp = keyof typeof BulkProps;

const bulkPropsList = Object.keys(BulkProps).filter(k => isNaN(+k) || isNaN(parseFloat(k)));
const MAX_MONEY = 1000000000;

export const LoanContractListActions = () => {
  const notify = useNotify();
  const cls = useStyles();
  const { hideModal } = useModalContext();
  const { selectedIds, refetch } = useListContext();
  const textRef = useRef<any>();
  const intRef = useRef<any>();
  const percentRef = useRef<any>();
  const amountRef = useRef<any>();

  const options = useLoanContractOptions();
  const [prop, setProp] = useState<BulkProp>();
  const [value, setValue] = useState<string | number | null>(null);

  useEffect(() => {
    resetValues();
    prop && setTimeout(() => {
      textRef.current?.focus();
      intRef.current?.focus();
      percentRef.current?.focus();
      amountRef.current?.focus();
    }, 300);
  }, [prop]);

  const resetValues = () => {
    setValue(null);
  };

  const onCancel = () => {
    setProp(undefined);
    resetValues();
    hideModal();
  };

  const { loading, submit } = useRequest(async () => {
    if (!Array.isArray(selectedIds) || selectedIds.length === 0) {
      return notify('No records are selected');
    }
    if (!prop) {
      return notify('No property is selected');
    }

    const updatedTotal = await loanContractHttp.updateBulk({ selectedIds, prop, value });

    notify(`Updated records: ${updatedTotal}`);
    onCancel();
    refetch();
  });

  const isText = prop === 'Type of Loan' || prop === 'Type of Payments'
    || prop === 'Product' || prop === 'Legal Representative' || prop === 'RFC'
    || prop === 'Colony' || prop === 'ZIP' || prop === 'Insurance Company';

  const isInt = prop === 'Expected Payments' || prop === 'Periodicity'
    || prop === 'Days on alert' || prop === 'Current Number of harvests';

  const isPercent = prop === 'Interest Rate' || prop === 'Interest Tax'
    || prop === 'Moratory Interest Rate' || prop === 'Moratory Tax'
    || prop === 'Disposal Fee' || prop === 'Disposal Fee Tax';

  const isDate = prop === 'Date of birth';
  const isState = prop === 'State' || prop === 'Crop State';
  const isMoney = prop === 'Insurance Policy Amount';

  const bulkPropsSorted = bulkPropsList
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  // console.log({ prop, isInt, isPercent, isText, isDate, isState, isMoney });

  return (
    <div className={cls.box}>
      <div className={cls.title}>Properties to update</div>
      <Select value={prop} onChange={v => setProp(v)} className={cls.select}
        placeholder="Select a property to edit">
        {bulkPropsSorted.map(propName => (
          <Select.Option key={propName} value={propName}>
            {propName}
          </Select.Option>
        ))}
      </Select>

      <div className={cls.content}>
        <div className={cls.title}>{prop}</div>
        {isText ? (
          <Input.TextArea
            className={cls.textarea}
            value={value || ''}
            onChange={e => setValue(e.target.value)}
            ref={textRef}
            autoFocus
          />
        ) : null}

        {isInt ? (
          <Input
            type="number"
            className={cls.textarea}
            value={typeof value === 'number' ? value : undefined}
            onChange={e => setValue(+e.target.value)}
            ref={intRef}
            autoFocus
          />
        ) : null}

        {isPercent ? (
          <Input
            type="number"
            className={cls.textarea}
            prefix={<PercentageOutlined className={cls.inputIcon} />}
            value={typeof value === 'number' ? value : undefined}
            onChange={e => e.target.value && +e.target.value > 100
              ? setValue(100)
              : setValue(+e.target.value)}
            ref={percentRef}
            min={0} max={100}
          />
        ) : null}

        {isDate ? (
          <AntdDateControlledInput
            valueStr={typeof value === 'string' ? value : null}
            onUpdate={date => setValue(date)}
            className={cls.textarea}
          />
        ) : null}

        {isMoney ? (
          <Input
            type="number"
            className={cls.textarea}
            prefix={<DollarOutlined className={cls.inputIcon} />}
            value={typeof value === 'number' ? value : undefined}
            onChange={e => e.target.value && +e.target.value > MAX_MONEY
              ? setValue(MAX_MONEY)
              : setValue(+e.target.value)}
            ref={amountRef}
            min={0} max={MAX_MONEY}
          />
        ) : null}

        {prop === 'DPD' ? (
          <Select value={value}
            onChange={v => setValue(v)}
            className={cls.select}
            placeholder="Select DPD (Par Delay)"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) => {
              return option.props?.children?.toString()
                .toLowerCase().includes(input.toLowerCase());
            }}
          >
            {(options?.parDelay || []).map(opt => (
              <Select.Option key={opt} value={opt}>{opt}</Select.Option>
            ))}
          </Select>
        ) : null}

        {isState ? (
          <Select value={value}
            onChange={v => setValue(v)}
            className={cls.select}
            placeholder="Select State"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) => {
              return option.props?.children?.toString()
                .toLowerCase().includes(input.toLowerCase());
            }}
          >
            {(options?.states || []).map(opt => (
              <Select.Option key={opt} value={opt}>{opt}</Select.Option>
            ))}
          </Select>
        ) : null}

        {prop === 'Crop City' ? (
          <Select value={value}
            onChange={v => setValue(v)}
            className={cls.select}
            placeholder="Select City"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) => {
              const search = input.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
              const optionText = option.props?.children?.toString()
                .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
              return optionText.includes(search);
            }}
          >
            {(options?.cities || []).map(opt => (
              <Select.Option key={opt} value={opt}>{opt}</Select.Option>
            ))}
          </Select>
        ) : null}

        {prop === 'Crop Zone' ? (
          <Select value={value}
            onChange={v => setValue(v)}
            className={cls.select}
            placeholder="Select Crop Zone"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) => {
              return option.props?.children?.toString()
                .toLowerCase().includes(input.toLowerCase());
            }}
          >
            {(options?.cropZones || []).map(opt => (
              <Select.Option key={opt} value={opt}>{opt}</Select.Option>
            ))}
          </Select>
        ) : null}
      </div>

      <div className={cls.controls}>
        <BlueButton className={cls.btn} onClick={submit} disabled={loading}>
          Update
        </BlueButton>
        <BlueOutlineButton className={cls.btn} onClick={onCancel}>
          Cancel
        </BlueOutlineButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  box: {},
  group: {
    marginTop: 15,
  },
  title: {
    fontSize: 20,
    lineHeight: 1,
    fontWeight: 500,
  },
  select: {
    marginTop: 10,
    width: '100%',
    borderRadius: 8,
    border: '1px solid #E0E7EE',
    backgroundColor: '#F3F7F9',
    '& .ant-select-selector': {
      backgroundColor: 'transparent !important',
    },
  },
  content: {
    marginTop: 15,
  },
  controls: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    margin: '0 7px',
    padding: '5px 29px !important',
    height: 34,
    borderRadius: 8,
    textTransform: 'none',
  },
  textarea: {
    marginTop: 10,
    padding: '10px 19px 12px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#E1E5ED',
    '& input': {
      background: '#E1E5ED',
    },
    '& .ant-picker-input': {
      paddingLeft: 10,
      paddingRight: 10,
      background: '#E1E5ED',
      borderRadius: 8,
      border: '1px solid #042E6B',
    },
  },
  inputIcon: {
    color: '#333',
    marginRight: 10,
  },
});
