import { Http } from './http';

class LoanContractHttp extends Http {
  prefix = 'loan-contract';

  async updateBulk(params: UpdateBulkParams): Promise<number> {
    const { data } = await this.put<number>('/update-bulk', params);

    return data;
  }

  async getOptions(): Promise<LoanContractOptions> {
    const { data } = await this.get<LoanContractOptions>('/options');

    return data;
  }
}

export const loanContractHttp = new LoanContractHttp();

interface UpdateBulkParams {
  selectedIds: number[];
  prop: string;
  value: string | number | null;
}

export interface LoanContractOptions {
  parDelay: string[];
  cropZones: string[];
  states: string[];
  cities: string[];
}

