import { clsx } from 'clsx';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { Select, Table as AntTable } from 'antd';

import { InitialCard } from '@components';
import { PaidReportsProduct } from '@network';
import cls from './Table.module.css';

interface Props {
  list: PaidReportsProduct[];
  loading: boolean;
}

const columns: ColumnsType<PaidReportsProduct> = [
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    align: 'center',
    className: clsx(cls.col, cls.productCol),
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    key: 'brand',
    align: 'center',
    className: clsx(cls.col, cls.brandCol),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    align: 'center',
    className: clsx(cls.col, cls.categoryCol),
  },
  {
    title: 'Subcategory',
    dataIndex: 'subcategory',
    key: 'subcategory',
    align: 'center',
    className: clsx(cls.col, cls.subcatCol),
  },
  {
    title: 'Picture',
    dataIndex: 'image',
    key: 'image',
    align: 'center',
    className: clsx(cls.col, cls.pictureCol, 'product-img'),
    render: text => text ? <img alt={'Product'} src={text} className={cls.img} /> : <div className={cls.noImg}/>,
  },
  {
    title: 'Purchased Quantity',
    dataIndex: 'qty',
    key: 'qty',
    align: 'center',
    className: clsx(cls.col, cls.qtyCol),
  },
];

export const Table = ({ list, loading }: Props) => {
  const [loadingIn, setLoadingIn] = useState(true);
  const [qty, setQty] = useState('5');
  const [data, setData] = useState<PaidReportsProduct[]>([]);

  async function imageToBase64(url: string): Promise<string | ArrayBuffer | null > {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob); // Конвертация в Base64
      });
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  const transformList = async (lst: PaidReportsProduct[]): Promise<PaidReportsProduct[]> => {
    const res: PaidReportsProduct[] = [];
    setLoadingIn(true);
    for (let i = 0; i < lst.length; i++) {
      let image = '';
      if (lst[i].image) {
        if ((qty && +qty - 1 >= i) || (qty === '' && i < 19)) {
          // @ts-ignore
          image = await imageToBase64(lst[i].image) || lst[i].image;
        }
        else {
          image = lst[i].image;
        }
      }
      res.push({ ...lst[i], image });
    }
    setLoadingIn(false);
    return res;
  };

  useEffect(() => {
    (async () => {
      const data = qty === '' ? list : list.slice(0, +qty);
      setData(await transformList(data));
    })();
  }, [qty]);

  useEffect(() => {
    (async () => {
      const data = list.slice(0, +qty);
      setData(await transformList(data));
    })();
  }, [list]);

  return (
    <InitialCard wrapperClass={cls.card} right={<div className={cls.filter}>
      <Select
        className={cls.select}
        popupClassName={cls.popup}
        defaultValue={qty}
        onSelect={setQty}
        onClear={() => setQty('')}
        placeholder="Show all"
        allowClear
        options={[
          { label: 'Top 5', value: '5' },
          { label: 'Top 10', value: '10' },
          { label: 'Top 20', value: '20' },
          { label: 'Show all', value: '' },
        ]}
      />
    </div>} extraPadding>
      <AntTable
        columns={columns}
        dataSource={data.length ? data : []}
        bordered
        loading={loading || loadingIn}
        size={'middle'}
        className={cls.table}
        rowClassName={cls.tableRow}
        pagination={qty ? false : { position: ['bottomRight'], pageSizeOptions: [5, 10, 15, 20] }}
      />
    </InitialCard>
  );
};
