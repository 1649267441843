import { ListProps, TextField, SelectField } from 'react-admin';

import {
  BooleanField,
  ManyField,
  TextInput,
  Grid,
  GridList,
  Forbidden,
  MovePositionField,
  NullableBooleanInput, SelectArrayInput,
} from '@components';
import { useRbacList } from '@hooks';
import { requirementsOptions } from '../requirements-options';
import cls from './QuestionList.module.css';
import { dealerTiersChoices } from '@types';

export const QuestionList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      className={cls.list}
    >
      <Grid
        rowClick="edit"
        className={cls.grid}
        defaultColumns={'position requirements nameMx answers withCustomAnswer excludedTiers isVisible isMultiple isTable isEnabled isOptional'.split(' ')}
      >
        <MovePositionField source="position" label="Position" />
        <SelectField
          source="requirements"
          label="Applies to type of User"
          choices={requirementsOptions}
          sortable={false}
        />
        <TextField source="nameMx" />
        <TextField source="nameEn" />
        <ManyField source="answers" noload />
        <BooleanField source="withCustomAnswer" label="Custom" />
        <TextField source="excludedTiers" label="Excluded for Tier" sortable={false} />
        <BooleanField source="isVisible" label="Is visible" />
        <BooleanField source="isMultiple" label="Multiple" />
        <BooleanField source="isTable" label="Table" />
        <BooleanField source="isEnabled" label="Enabled" />
        <BooleanField source="isOptional" label="Optional" />
        <BooleanField source="isSendToCredit" label="Send to Analysis" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" name="q" key="q" variant="outlined" label="Search" alwaysOn />,
  <NullableBooleanInput
    style={{ width: 100 }}
    source="isEnabled"
    key="isEnabled"
    label="Is enabled"
    trueLabel="Yes"
    falseLabel="No"
  />,
  <NullableBooleanInput
    style={{ width: 100 }}
    source="isVisible"
    key="isVisible"
    label="Is visible"
    trueLabel="Yes"
    falseLabel="No"
  />,
  <SelectArrayInput
    style={{ width: 100 }}
    key="excludedTiers"
    source="excludedTiers"
    label="Excluded for Tier"
    choices={dealerTiersChoices}
    className={'MS-field'}
  />,
];
