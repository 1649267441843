import { Http } from './http';
import { SurveyQuestion } from '@types';

class SurveyHttp extends Http {

  async getCreditSurvey(creditId: number): Promise<GetCreditSurveyResponse> {
    const res = await this.get<GetCreditSurveyResponse>(`/survey/${creditId}`);

    return res.data;
  }

  async updateCreditSurvey({
    creditId,
    questionId,
    params,
    type = 'answer',
  }: UpdateCreditSurveyParams): Promise<GetCreditAnswerResponse> {
    const res = await this.put<GetCreditAnswerResponse>(`/survey/${creditId}/${type}/${questionId}`, params);

    return res.data;
  }
}

export const surveyHttp = new SurveyHttp();

interface GetCreditSurveyResponse {
  surveyQuestions: SurveyQuestion[];
  answeredDate: Date;
  isFixed: boolean;
}

interface GetCreditAnswerResponse {
  surveyQuestions: SurveyQuestion;
  answeredDate: Date;
}

export interface CreditSurveyTableColProps {
  answerId?: number;
  customAnswer?: string;
}

export interface CreditSurveyTableRowProps {
  cols: CreditSurveyTableColProps[];
}

interface UpdateCreditSurveyParams {
  creditId: number;
  questionId: number;
  params: UpdateSurveyDTO;
  type?: UpdateCreditSurveyType;
}

interface UpdateSurveyDTO {
  customAnswer?: string;
  answerIds?: number[];
  rows?: CreditSurveyTableRowProps[]
}

export type UpdateCreditSurveyType = 'answer-table' | 'answer' | 'answer-percent';
