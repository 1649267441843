import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

import { InfoTooltip } from '@components/InfoTooltip';

interface Props {
  title: string;
  tooltip?: NonNullable<React.ReactNode>;
  className?: string;
}

export const InitialTitle = ({
  children, title, tooltip, className,
}: PropsWithChildren<Props>) => {
  const cls = useStyles();

  return (
    <div className={clsx(cls.box, className)}>
      {title && <div className={cls.title}>{title}</div>}
      {tooltip && <InfoTooltip title={tooltip} inline />}
      {children}
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '5px solid #042E6B',
    borderRadius: 5,
    maxWidth: 300,
    fontSize: 18,
    fontWeight: 400,
    color: '#000',
    padding: '25px 30px 15px',

    '& .MuiInputLabel-standard': {
      display: 'none !important',
    },
  },
  title: {
    position: 'absolute',
    backgroundColor: 'white',
    top: -11,
    left: -5,
    paddingRight: 6,
  },
  value: {
    fontSize: 22,
  },
});
