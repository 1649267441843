import { clsx } from 'clsx';
import { InputNumber } from 'antd';

import { moneyFormatter } from '@utils';
import cls from './RevenueTable.module.css';

export type RTRowKeys = 'year' | 'iEmitted' | 'iCancelled' | 'iCurrent' | 'eReceived' | 'eCancelled' | 'eCurrent' | 'margin';

export interface RTPHeaderRow {
  key: RTRowKeys;
  label: string;
}

export interface RTRow {
  year: number;
  iEmitted: number;
  iCancelled: number;
  iCurrent: number;
  eReceived: number;
  eCancelled: number;
  eCurrent: number;
  margin: number;
}

export interface RevenueDataProps {
  header: RTPHeaderRow[];
  values: RTRow[];
  total: RTRow;
}

interface Props {
  data: RevenueDataProps;
  calcData: RevenueDataProps;
  onChange: any;
}

interface InputProps {
  row: RTRow;
  field: RTRowKeys;
  onChange: any
}

const Input = ({ row, field, onChange }: InputProps) => {
  return (
    field === 'year'
      ? <span className={cls.text}>{row[field]}</span>
      : <span className={cls.inputWrap}>
        <InputNumber
          min={field !== 'margin' ? 0 : -1000000000}
          max={1000000000}
          formatter={(value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value ? +value.replace(/\$\s?|(,*)/g, '') : 0}
          defaultValue={row[field]}
          bordered={false}
          onChange={e => onChange(e, field, row.year)}
          className={cls.input}
        />
      </span>
  );
};

export const RevenueTable = ({ data, calcData, onChange }: Props) => {
  const fields: RTRowKeys[] = data.header.map(h => h.key);

  const onFieldChange = (val: number | null, key: RTRowKeys, year: number) => {
    if (val === null) return;
    const newValues = data.values.map(y => y.year === year ? { ...y, [key]: val } : y);
    const newValue = { ...data, values: newValues };
    onChange(newValue);
  };

  if (!data) {
    return null;
  }

  return (
    <div className={cls.block}>
      <div className={cls.table}>
        <div className={cls.header}>
          <div className={cls.headerRow}>
            <div className={cls.headerCol}/>
            <div className={cls.headerCol}>Income</div>
            <div className={cls.headerCol}>Expenses</div>
          </div>
        </div>
        <div className={cls.titles}>
          <div className={cls.row}>
            {data.header.map(h => (
              <div className={cls.col} key={h.key}>{h.label}</div>
            ))}
          </div>
        </div>
        <div className={cls.body}>
          {data.values.map(r => (
            <div className={clsx(cls.row, cls.rowColored)} key={r.year}>
              {fields.map(f => (
                <div className={cls.col} key={f}>
                  <Input row={r} field={f} onChange={onFieldChange}/>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className={cls.footer}>
          <div className={cls.footerRow}>
            <div className={cls.col}>Total</div>
            {fields.map(f => f === 'year' ? null : (
              <div className={cls.col} key={f}>
                {moneyFormatter.format(calcData.total[f])}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
