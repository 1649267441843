import React from 'react';
import { Create, CreateProps, SimpleForm, number, minValue, maxValue } from 'react-admin';
import { makeStyles } from '@mui/styles';

import { creditStatusOptions } from './CreditStatusField';
import { useCreateProps, useRbacCreate } from '@hooks';
import {
  Col,
  Container,
  ReferenceInput,
  Row,
  SelectInput,
  NumberInput,
  Forbidden,
} from '@components';

export const CreditCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const cls = useStyles();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  const validateRequestAmount = [number(), minValue(0), maxValue(1000000000)];

  return (
    <Create {...props} {...createProps} title="Create credit">
      <SimpleForm className={cls.form}>
        <Container max>
          <Row>
            <Col title="New credit">
              <ReferenceInput
                source="userId"
                filter={{ forFilter: 'true' }}
                reference="user"
                label="User"
                allowEmpty
                alwaysOn
                perPage={1000}
                sort={{ field: 'fullName', order: 'ASC' }}
              >
                <SelectInput optionText="fullName" required />
              </ReferenceInput>
              <NumberInput source="requestAmount" validate={validateRequestAmount} />
            </Col>
            <Col title="Info">
              <SelectInput source="status" choices={creditStatusOptions} required />
              <ReferenceInput reference="bank" source="bankId" label="Bank" allowEmpty>
                <SelectInput optionText="nameMx" />
              </ReferenceInput>
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  form: {
    padding: 24,
    maxWidth: 800,
  },
});
