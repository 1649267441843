import { Http } from './http';
import { MoffinProfile } from '@types';

class MoffinHttp extends Http {

  prefix = 'moffin';

  async getMoffinProfiles(userId: number): Promise<MoffinProfile[]> {
    const res = await this.get<MoffinProfile[]>(`/by-user/${userId}`);

    return res.data;
  }

  async requestScore(userId: number, accountType: 'PF' | 'PM'): Promise<MoffinProfile> {
    const res = await this.put<MoffinProfile>(`/by-user/${userId}/${accountType}`);

    return res.data;
  }

  async resetProfile(profileId: number): Promise<MoffinProfile> {
    const res = await this.delete<MoffinProfile>(`/${profileId}`);

    return res.data;
  }
}

export const moffinHttp = new MoffinHttp();
