import { ListProps, TextField } from 'react-admin';

import { Grid, GridList, Forbidden } from '@components';
import { useRbacList } from '@hooks';

export const SeoList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList
      {...props}
      filters={[]}
      sort={{ field: 'id', order: 'DESC' }}
      exporter={false}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'id page titleEn titleEs'.split(' ')}
      >
        <TextField source="id" label="ID" />
        <TextField source="page" label="Page" />
        <TextField source="titleEn" label="Title EN" />
        <TextField source="titleEs" label="Title ES" />
        <TextField source="descriptionEn" label="Description EN" />
        <TextField source="descriptionEs" label="Description ES" />
        <TextField source="keywordsEn" label="Keywords EN" />
        <TextField source="keywordsEs" label="Keywords ES" />
        <TextField source="createdAt" label="Created at" />
      </Grid>
    </GridList>
  );
};
