import { Http } from './http';
import {
  Doctype,
  Guarantor,
  Document,
  DocumentFile,
  FileStatus,
  DocumentStatus,
  CreditTransaction,
  Credit,
  CreditInsurance,
  CreditInvoice,
  CreditInvoiceStatusType,
  OrderCompositionProduct,
  CreditMoney,
  CreditRisk,
  CreditReportFile,
  CreditReport, FinanceTransaction,
} from '@types';
import { dateFormatter, isProdServer } from '@utils';
import { CreditReportsType } from '@pages/credit/CreditReport';

class CreditHttp extends Http {
  prefix = 'credit';

  async createCredit({ userId, isAdminDraft }: CreateCreditParams): Promise<Credit> {
    const res = await this.post<Credit>('', { userId, isAdminDraft });

    return res.data;
  }

  async getCreditsWithHistory(userId: number): Promise<Credit[]> {
    const res = await this.get<Credit[]>(`/credits-with-history/${userId}`);

    return res.data;
  }

  async getCreditsByUser(userId: number, bank = false): Promise<Credit[]> {
    const res = await this.get<Credit[]>(`?userId=${userId}&getBankInfo=${bank}&isAdminDraft=true`);

    return res.data;
  }

  async changeFileStatus(params: FileStatusParams) {
    const { creditId, file, status } = params;
    const url = `/${creditId}/file/${file.id}`;
    const res = await this.put<Document>(url, { status });

    return res.data;
  }

  async changeDocumentStatus(params: DocumentStatusParams) {
    const { creditId, document, status } = params;
    const url = `/${creditId}/document/${document.id}`;
    const res = await this.put<Document>(url, { status });

    return res.data;
  }

  async addCommentToDocument(params: DocumentCommentParams) {
    const { creditId, document, comment } = params;
    const url = `/${creditId}/document/${document.id}`;
    const res = await this.put<Document>(url, { comment });

    return res.data;
  }

  async saveMoney(creditId: number, params: SaveMoneyParams): Promise<Credit> {
    const res = await this.post<Credit>(`/${creditId}/money`, params);

    return res.data;
  }

  async saveInsurances(creditId: number, fileIds: number[]): Promise<CreditInsurance[]> {
    const res = await this.post<CreditInsurance[]>(`/${creditId}/insurances`, { fileIds });

    return res.data;
  }

  async saveDocuments(params: DocumentsParams) {
    const { creditId, guarantorId, doctypeId, fileIds, number, status } = params;
    const url = guarantorId
      ? `/${creditId}/guarantor/${guarantorId}/doctype/${doctypeId}`
      : `/${creditId}/doctype/${doctypeId}`;
    const res = await this.post<Document>(url, { fileIds, number, status });

    return res.data;
  }

  async saveTransactionFile(params: TransactionFileParams) {
    const { creditId, transactionId, fileId } = params;
    const url = `/${creditId}/transaction/${transactionId}`;
    const res = await this.post<CreditTransaction>(url, { fileId });

    return res.data;
  }

  async deleteTransactionFile(params: DeleteTransactionFileParams) {
    const { creditId, transactionId } = params;
    const url = `/${creditId}/transaction/${transactionId}`;
    const res = await this.delete<CreditTransaction>(url);

    return res.data;
  }

  async downloadCreditDocuments(creditId: number) {
    await this.downloadZip(`/${creditId}/download-documents`);
  }

  async downloadGuarantorDocuments(creditId: number, guarantorId: number) {
    await this.downloadZip(`/${creditId}/guarantors/${guarantorId}/download-documents`);
  }

  async downloadAllGuarantorDocuments(creditId: number, creditCode: string) {
    await this.downloadZip(`/${creditId}/guarantors/download-all-documents`, `Docs - Guarantors (${creditCode}).zip`);
  }

  async deleteGuarantor(creditId: number, guarantorId: number): Promise<Guarantor> {
    const res = await this.delete<Guarantor>(`/${creditId}/guarantors/${guarantorId}`);

    return res.data;
  }

  async getCredit(creditId: number): Promise<Credit> {
    const res = await this.get<Credit>(`/${creditId}`);

    return res.data;
  }

  async getCreditDoctypes(creditId: number): Promise<DoctypesResponse> {
    const res = await this.get<DoctypesResponse>(`/${creditId}/doctypes`);

    return res.data;
  }

  async createGuarantor(
    creditId: number,
    params: GuarantorParams,
  ): Promise<Guarantor> {
    params.workSince = dateFormatter.dateToDbFormat(params.workSince);
    const url = `/${creditId}/guarantors/`;
    const res = await this.post<Guarantor>(url, params);

    return res.data;
  }

  async updateGuardian(
    creditId: number,
    guarantorId: number,
    params: GuarantorParams,
  ): Promise<Guarantor> {
    params.workSince = dateFormatter.dateToDbFormat(params.workSince);
    const url = `/${creditId}/guarantors/${guarantorId}`;
    const res = await this.put<Guarantor>(url, params);

    return res.data;
  }

  async deleteGuardian(
    creditId: number,
    guarantorId: number,
  ): Promise<Guarantor> {
    const url = `/${creditId}/guarantors/${guarantorId}`;
    const res = await this.delete<Guarantor>(url);

    return res.data;
  }

  async payOrder(params: PayOrderParams): Promise<CreditTransaction> {
    const { creditId, orderId } = params;
    const url = `/${creditId}/pay-order/${orderId}`;
    const res = await this.post<CreditTransaction>(url);

    return res.data;
  }

  async deleteComment(params: DeleteCommentParams): Promise<Document> {
    const { creditId, commentId } = params;
    const url = `/${creditId}/comments/${commentId}`;
    const res = await this.delete<Document>(url);

    return res.data;
  }

  async getInvoiceById(invoiceId: number): Promise<CreditInvoice> {
    const url = `/invoice/${invoiceId}`;
    const res = await this.get<CreditInvoice>(url);

    return res.data;
  }

  async updateInvoice(invoiceId: number, params: UpdateInvoiceParams): Promise<CreditInvoice> {
    const url = `/invoice/${invoiceId}`;
    const res = await this.put<CreditInvoice>(url, params);

    return res.data;
  }

  async removeInvoice(invoiceId: number): Promise<CreditInvoice> {
    const url = `/invoice/${invoiceId}`;
    const res = await this.delete<CreditInvoice>(url);

    return res.data;
  }

  async uploadInvoice({ creditId, userId, fileIds }: UploadInvoiceParams): Promise<CreditInvoice> {
    const url = `/invoice/upload/${creditId}`;
    const res = await this.post<CreditInvoice>(url, { fileIds, userId });

    return res.data;
  }

  async getAllUsers() {
    const res = await this.get<any[]>('/users');

    return res.data;
  }

  async confirmOrderQuotation({ creditId, orderId }: ConfirmOrderParams): Promise<void> {
    const url = `/${creditId}/pay-order/${orderId}`;
    await this.post(url);

    return;
  }

  async removeCreditCrop(creditId: number, cropId: number): Promise<boolean> {
    const url = `/${creditId}/credit-crops/${cropId}`;
    const res = await this.delete<boolean>(url);

    return res.data;
  }

  async getReportFiles(creditId: number) {
    const url = `/${creditId}/get-report-files`;
    const res = await this.get<CreditReportsType>(url);

    return res.data;
  }

  async saveReportFile(params: SaveReportFile) {
    const { creditId, fileId, type } = params;
    const url = `/${creditId}/upload-report-file`;
    const res = await this.post<CreditReportFile>(url, { fileId, type });

    return res.data;
  }

  async removeReportFile(params: RemoveReportFile) {
    const { creditId, fileId } = params;
    const url = `/${creditId}/remove-report-file/${fileId}`;
    const res = await this.post<boolean>(url);

    return res.data;
  }

  async downloadReportFile(params: DownloadReportFile) {
    const { creditId, type, filename } = params;
    await this.downloadZip(
      `/${creditId}/download-report-file/${type}`,
      `${filename || type}.zip`,
    );
  }

  async reportFiles(creditId: number): Promise<ReportDocumentsProps> {
    const res = await this.get<ReportDocumentsProps>(`/${creditId}/get-report-files`);

    return res.data;
  }

  async creditDocuments(creditId: number): Promise<number> {
    const res = await this.get<number>(`/${creditId}/count-credit-documents`);

    return res.data;
  }

  async guarantorsDocuments(creditId: number): Promise<number> {
    const res = await this.get<number>(`/${creditId}/count-guarantors-documents`);

    return res.data;
  }

  async getRisk(creditId: number): Promise<CreditRisk | null> {
    const res = await this.get<CreditRisk | null>(`/${creditId}/risk`);

    return res.data;
  }

  async createCreditRisk(creditId: number): Promise<CreditRisk> {
    const res = await this.post<CreditRisk>(`/${creditId}/risk`);

    return res.data;
  }

  async saveRequestAmount(creditId: number, requestAmount: number | string) {
    const res = await this.post<number>(`/${creditId}/request-amount`, { requestAmount });

    return res.data;
  }

  async updateBulk(params: UpdateBulkCreditsParams): Promise<number> {
    const { data } = await this.put<number>('/update-bulk', params);

    return data;
  }

  async setOptionalDocument(params: OptionalDocumentParams) {
    const { creditId, doctypeId, isOptionalChecked, guarantorId } = params;
    const url = `/${creditId}/doctype/${doctypeId}/setOptional`;
    const res = await this.post<Document>(url, { isOptionalChecked, guarantorId });

    return res.data;
  }

  async setGuarantorRequired(params: setGuarantorRequiredParams) {
    const { creditId, isRequired } = params;
    const url = `/${creditId}/set-guarantor-required`;
    const res = await this.put<Credit>(url, { isRequired });

    return res.data;
  }

  async remove(creditId: number) {
    const url = `/${creditId}`;
    const res = await this.delete<Credit>(url);

    return res.data;
  }

  async updatePartially(creditId: number, payload: Record<string, any>): Promise<Credit> {
    const { data: credit } = await this.put<Credit>(`/${creditId}/partial`, payload);

    return credit;
  }

  async updateAccredited(creditId: number, accredited: string): Promise<Credit> {
    return await this.updatePartially(creditId, { accredited });
  }

  async interestCalculation(
    creditId: number, payload: CreditInterestDto,
  ): Promise<InterestCalculateResponse> {
    const { data: calculation } = await this.post<InterestCalculateResponse>(
      `/${creditId}/money-interest`, payload,
    );

    return calculation;
  }
}

export const creditHttp = new CreditHttp();

export interface InterestRow {
  paymentTitle: string;
  paymentAmount: number | null;
  paymentDate: Date | string | null;
  priorCapitalBalance: number;
  priorInterests: number | null;
  priorTotalBalance: number;
  periodInterests: number | null;
  prepeymentInterestsBalance: number | null;
  prepeymentBalanceInquiry: number | null;
  postpaymentInterestsBalance: number | null;
  postpaymentCapitalBalance: number | null;
  postpaymentFinalBalance: number | null;
  daysDiff: number | null;
  interestRate: number;
  signatureYearDays: number;
  isDelay: boolean;
  isPaid: boolean;
  paymentPaidTitle: string | null;
  withdrawalId?: number;
}

export interface InterestCalculateResponse {
  creditId: number;
  credit: number; // Bank approved amount
  interestsAmount: number;
  paymentAmount: number;
  riskExists: boolean;
  interestRate: number;
  moratoryRate: number;
  signatureDate: string | null;
  paymentDate: string | null;
  finalPayment: number;
  isDelay: boolean;
  nextDate: string;
  rows: InterestRow[];
  payments: CreditInterestPayment[];
  isPaid: boolean;
  savingsAmount: number;
}

interface CreditInterestPayment {
  paymentTitle?: string;
  paymentAmount: number;
  paymentDate: string;
}

interface CreditInterestDto {
  payments?: CreditInterestPayment[];
  isSave?: boolean;
  todayDate?: string;
  creditAmount?: number;
  interestRate?: number;
  moratoryRate?: number;
  signatureDate?: string;
  paymentDate?: string;
}

interface CreateCreditParams {
  userId: number;
  isAdminDraft?: boolean;
}

interface UpdateBulkCreditsParams {
  creditIds: number[];
  status?: string;
  bankId?: number;
  responsibleAdminId?: number;
  newResponsibleAdminId?: number;
  contactBy?: string;
  observation?: string;
  visitAction?: string;
  rejectReason?: string;
  channelAction?: string;
}

interface PayOrderParams {
  creditId: number;
  orderId: number;
}

interface DoctypesResponse {
  doctypes: Doctype[];
  guarantorDoctypes: Doctype[];
}

interface DocumentsParams {
  creditId: number;
  doctypeId: number;
  fileIds: number[];
  guarantorId?: number;
  number?: string | null;
  status?: DocumentStatus;
}

interface TransactionFileParams {
  creditId: number;
  transactionId: number,
  fileId: number,
}

interface DeleteTransactionFileParams {
  creditId: number;
  transactionId: number,
}

interface DocumentCommentParams {
  creditId: number;
  document: Document;
  comment: string;
}

interface DocumentStatusParams {
  creditId: number;
  document: Document;
  status: DocumentStatus;
}

interface DeleteCommentParams {
  creditId: number;
  commentId: number;
}

interface FileStatusParams {
  creditId: number;
  file: DocumentFile;
  status: FileStatus;
}

interface SaveMoneyParams {
  money: CreditMoney[];
  transactions: FinanceTransaction[];
  membershipPercent: number;
  membershipCompanyPercent: number;
  paymentAmount: number;
  bankAmount?: number;
}

export interface GuarantorParams {
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string | null;
  marriage: string;
  phone: string;
  profession: string;
  relation: string;
  workSince: string;
  companyShare: number | null;
}

export interface UpdateInvoiceParams {
  isIva: boolean;
  status: CreditInvoiceStatusType;
  state: string;
  retailerName: string;
  comment: string;
  composition: OrderCompositionProduct[];
  creditId?: number;
}

export interface ConfirmOrderParams {
  orderId: number;
  creditId: number;
}

export interface SaveReportFile {
  creditId: number;
  fileId: number;
  type: CreditReportTypes;
}

export interface RemoveReportFile {
  creditId: number;
  fileId: number;
}

export interface DownloadReportFile {
  creditId: number;
  type: CreditReportTypes;
  filename?: string;
}

export interface UploadInvoiceParams {
  creditId: number;
  userId: number;
  fileIds: number[];
}

export enum CreditReportTypes {
  EKATENA_REPORT = 'EKATENA_REPORT',
  BUREAU_REPORT = 'BUREAU_REPORT',
  SINECTA_REPORT = 'SINECTA_REPORT',
}

export interface OptionalDocumentParams {
  creditId: number;
  doctypeId: number;
  isOptionalChecked: boolean;
  guarantorId?: number;
}

export interface setGuarantorRequiredParams {
  creditId: number;
  isRequired: boolean;
}

export type ReportDocumentsProps = {
  [key in CreditReportTypes]: CreditReport[];
};
