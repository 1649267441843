import { clsx } from 'clsx';
import { RcFile } from 'antd/lib/upload';

import { showAxiosError } from '@utils';
import { InputText } from '@components/inputs/InputText';
import { convertFileToBase64, FileUpload } from '@components';
import cls from './crop-category-form.module.css';

const MAX_IMAGE_SIZE = 5;

interface CropCreatorFormProps {
  formValues: any;
  setFormValues: any;
  showErrors: boolean;
}

export const UploadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="32" viewBox="0 0 26 32" fill="none">
    <path d="M21.5 15.27C21.5 13.34 19.93 11.77 18 11.77C16.07 11.77 14.5 13.34 14.5 15.27C14.5 17.2 16.07 18.77 18 18.77C19.93 18.77 21.5 17.2 21.5 15.27ZM18 13.77C18.83 13.77 19.5 14.44 19.5 15.27C19.5 16.1 18.83 16.77 18 16.77C17.17 16.77 16.5 16.1 16.5 15.27C16.5 14.44 17.17 13.77 18 13.77Z" fill="#042E6B"/>
    <path d="M23 8.27002H17C16.45 8.27002 16 8.72002 16 9.27002C16 9.82002 16.45 10.27 17 10.27H23C23.55 10.27 24 10.72 24 11.27V25.48L20.08 21.56C18.32 19.89 15.54 19.93 13.83 21.65L12.73 22.75L11.82 21.83L8.1 18.14C6.5 16.56 4.19 15.91 2 16.43V11.27C2 10.72 2.45 10.27 3 10.27H9C9.55 10.27 10 9.82002 10 9.27002C10 8.72002 9.55 8.27002 9 8.27002H3C1.34 8.27002 0 9.61002 0 11.27V28.27C0 29.93 1.34 31.27 3 31.27H22C24.21 31.27 26 29.48 26 27.27V11.27C26 9.61002 24.66 8.27002 23 8.27002ZM22 29.27H3C2.45 29.27 2 28.82 2 28.27V18.53L2.27 18.41C3.84 17.97 5.53 18.41 6.68 19.56L10.39 23.27L11.39 24.27H11.44L11.59 24.42C11.9 24.69 12.31 24.83 12.72 24.83C13.12 24.83 13.51 24.69 13.82 24.45L14 24.27L15.22 23.05C16.21 22.11 17.77 22.11 18.76 23.05L23.76 28.05C23.46 28.76 22.77 29.24 22 29.27Z" fill="#042E6B"/>
    <path d="M10.5209 4.69986L12.0009 3.26986V11.2699C12.0009 11.8199 12.4509 12.2699 13.0009 12.2699C13.5509 12.2699 14.0009 11.8199 14.0009 11.2699V3.19986L15.4809 4.67986C15.8709 5.06986 16.5109 5.06986 16.9009 4.67986C17.2909 4.28986 17.2909 3.65986 16.9009 3.26986L14.0709 0.449864C13.5109 -0.120136 12.5909 -0.150136 12.0009 0.389864L11.9309 0.449864L9.11086 3.26986C8.72086 3.65986 8.72086 4.29986 9.11086 4.68986C9.50086 5.07986 10.1309 5.08986 10.5309 4.69986H10.5209Z" fill="#042E6B"/>
  </svg>
);

const UploadContent = () => {
  return (
    <div className={cls.uploadContent}>
      <div className={cls.uploadContentBlock}>
        <div className={cls.uploadContentTitle}>Upload Image</div>
        <div className={cls.uploadContentIcon}>
          <UploadIcon />
        </div>
      </div>
      <div className={cls.uploadContentComment}>
        *File size should not exceed {MAX_IMAGE_SIZE}MB
      </div>
    </div>
  );
};

export const CropCategoryForm = ({
  formValues, setFormValues, showErrors,
}: CropCreatorFormProps) => {


  const onChangeHandler = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const uploadPicture = async(rcFile: RcFile): Promise<any> => {
    try {
      const base64Img = await convertFileToBase64(rcFile) as string;

      if (base64Img) {
        setFormValues({
          ...formValues, image: base64Img as string,
        });
      }
    } catch (err: any) {
      showAxiosError(err);
    }
  };

  const onImageClear = () => {
    setFormValues({
      ...formValues,
      image: '',
    });
  };

  const showNameMxError = formValues.nameMx?.length === 0 && showErrors;
  const showImageError = formValues.image?.length === 0 && showErrors;

  return (
    <div className={cls.form}>
      <div className={cls.row}>
        <InputText
          name="id"
          label="Id"
          labelWidth={135}
          centered
          value={formValues.id || ''}
          onChange={() => {}}
        />
      </div>
      <div className={cls.row}>
        <InputText
          name="nameMx"
          label="Name  mx*"
          labelWidth={135}
          centered
          onChange={onChangeHandler}
          className={clsx(showNameMxError && cls.error)}
          value={formValues.nameMx || ''}
        />
      </div>
      <div className={cls.row}>
        <InputText
          name="nameEn"
          label="Name en"
          labelWidth={135}
          centered
          onChange={onChangeHandler}
          value={formValues.nameEn || ''}
        />
      </div>

      {formValues.image?.length ? (
        <div className={cls.uploadedBox} onClick={onImageClear}>
          <div className={cls.uploadedIcon}>
            <UploadIcon />
          </div>
          <img src={formValues.image} alt="" className={cls.image} />
        </div>
      ) : (
        <FileUpload
          uploadAction={uploadPicture}
          className={clsx(cls.uploadBox, showImageError && cls.imageError)}
          type="image"
          content={<UploadContent />}
          size={MAX_IMAGE_SIZE}
        />
      )}
    </div>
  );
};
