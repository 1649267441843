import { Col, Row } from 'antd';

import { querySerialize } from '@utils';
import { FinanceDashboardActivationsTableProps } from '@types';
import { FinanceDashboardTableItemProps, Table } from './Table';
import cls from './Table.module.css';

interface FinanceDashboardTablesProps {
  cplTop: FinanceDashboardActivationsTableProps[];
  cplBottom: FinanceDashboardActivationsTableProps[];
  mqlTop: FinanceDashboardActivationsTableProps[];
  mqlBottom: FinanceDashboardActivationsTableProps[];
  budgetTop: FinanceDashboardActivationsTableProps[];
  budgetBottom: FinanceDashboardActivationsTableProps[];
}

export const Tables = (
  { cplTop, cplBottom, mqlTop, mqlBottom, budgetTop, budgetBottom }: FinanceDashboardTablesProps,
) => {
  const list: FinanceDashboardTableItemProps[] = [
    {
      title: 'Activations with the most MQL’s',
      link: '/finance-activation?' + querySerialize({ order: 'DESC', sort: 'mql' }),
      list: mqlTop,
    },
    {
      title: 'Activations with the least MQL’s',
      link: '/finance-activation?' + querySerialize({ order: 'ASC', sort: 'mql' }),
      list: mqlBottom,
    },
    {
      title: 'Activations with the highest Budget',
      link: '/finance-activation?' + querySerialize({ order: 'DESC', sort: 'budget' }),
      list: budgetTop,
    },
    {
      title: 'Activations with the lowest Budget',
      link: '/finance-activation?' + querySerialize({ order: 'ASC', sort: 'budget' }),
      list: budgetBottom,
    },
    {
      title: 'Activations with the highest CPL',
      link: '/finance-activation?' + querySerialize({ order: 'DESC', sort: 'cpl' }),
      list: cplTop,
    },
    {
      title: 'Activations with the lowest CPL',
      link: '/finance-activation?' + querySerialize({ order: 'ASC', sort: 'cpl' }),
      list: cplBottom,
    },
  ];

  return (
    <Row className={cls.row} gutter={[20, 20]}>
      {list.map((item, index) => (
        <Col xs={24} xl={12} className={cls.col} key={index}>
          <Table {...item} />
        </Col>
      ))}
    </Row>
  );
};
