import React, { useRef } from 'react';
import IconButton from '@mui/material/IconButton';

import { http } from '@network';
import { DownloadIcon } from '@components/FileImage';
import cls from './file-without-preview.module.css';

type Props = {
  url: string;
  downloadFilename?: string
}

const downloadPdf = async (url: string) => {
  const res = await http.get(url, { responseType: 'blob' });
  const blobResponse = new Blob([res.data as any], { type: 'application/pdf' });
  const blobUrl = URL.createObjectURL(blobResponse);

  return blobUrl;
};

export const FileWithoutPreview = ({ url, downloadFilename }: Props) => {
  const blob = useRef<string>();

  const onDownload = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!blob.current) {
      blob.current = await downloadPdf(url);
    }

    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = blob.current;
      link.download = downloadFilename || 'Document.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(blob.current);
    }
  };

  return (
    <div className={cls.box}>
      <div className={cls.pdfIcon}/>

      {downloadFilename && (
        <div className={cls.download} title="download">
          <IconButton onClick={onDownload}>
            <DownloadIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};
