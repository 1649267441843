import { clsx } from 'clsx';

import cls from './ScoreTable.module.css';

export type ScoreTableKeys = 'name' | 'score' | 'withCredit' | 'withoutCredit' | 'dscr' | 'cashRatio';

interface HeaderProps {
  key: ScoreTableKeys;
  label: string;
}

interface ValueProps {
  key: ScoreTableKeys;
  label: string;
  score: {percent: number; isPositive: boolean};
  withCredit: number | null;
  withoutCredit: number | null;
}

export interface ScoreTableData {
  header: HeaderProps[];
  values: ValueProps[];
}

export const ScoreTable = ({ data }: { data: ScoreTableData }) => (!data.values.length) ? null : (
  <div className={cls.block}>
    <div className={cls.table}>
      <div className={cls.header}>
        <div className={cls.row}>
          {data.header.map((r, idx) => (
            <div className={cls.col} key={idx}>
              <span className={cls.headerTitle}>{r.label}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={cls.body}>
        {data.values.map((r, idx) => (
          <div className={cls.row} key={idx}>
            <div className={cls.col}>{r.label}</div>
            <div className={clsx(cls.col, cls.colored)}>{r.withCredit || 0}</div>
            <div className={clsx(cls.col, cls.colored)}>{r.withoutCredit || 0}</div>
            <div className={cls.col}>
              <div className={cls.innerRow}>
                <div className={cls.innerCol}>
                  <span className={clsx(cls.badge, r.score.isPositive ? cls.access : cls.error)}>
                    {r.score.isPositive ? 'Positive' : 'Negative'}
                  </span>
                </div>
                <div className={cls.innerCol}>{r.score.percent || 0}%</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
