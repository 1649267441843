import { clsx } from 'clsx';
import { message } from 'antd';
import { TextInputProps, useRecordContext } from 'react-admin';

import { TextInput } from '../RA';
import cls from './LinkInput.module.css';

interface Props {
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  rowClass?: string;
}

export const LinkInput = (props: TextInputProps & Props) => {
  const { leftIcon, rightIcon, rowClass, className, ...rest } = props;
  const record = useRecordContext();
  const value = record[rest.source];

  const copy = async () => {
    await navigator.clipboard.writeText(value);
    message.info('The link has been copied');
  };

  return (
    <div className={clsx(cls.row, rowClass)}>
      <div className={clsx(cls.col, cls.colFirst)}>
        {leftIcon ? leftIcon : (
          <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none" className={clsx(cls.icon, cls.leftIcon)}>
            <circle cx="19" cy="19" r="19" fill="#042E6B"/>
            <g clipPath="url(#clip0_286_20)">
              <path d="M19 33C11.272 33 5 26.728 5 19C5 11.272 11.272 5 19 5C26.728 5 33 11.272 33 19C33 26.728 26.728 33 19 33ZM19 7.8C12.812 7.8 7.8 12.812 7.8 19C7.8 25.188 12.812 30.2 19 30.2C25.188 30.2 30.2 25.188 30.2 19C30.2 12.812 25.188 7.8 19 7.8Z" fill="white"/>
              <path d="M20.5678 14.8H17.4318C15.9782 14.8 14.7998 15.9784 14.7998 17.432V20.568C14.7998 22.0217 15.9782 23.2 17.4318 23.2H20.5678C22.0214 23.2 23.1998 22.0217 23.1998 20.568V17.432C23.1998 15.9784 22.0214 14.8 20.5678 14.8Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_286_20">
                <rect width="28" height="28" fill="white" transform="translate(5 5)"/>
              </clipPath>
            </defs>
          </svg>
        )}
      </div>
      <div className={clsx(cls.col, cls.colMiddle)}>
        <TextInput
          InputLabelProps={{ shrink: true }}
          className={clsx(cls.input, className)}
          {...rest}/>
      </div>
      <div className={clsx(cls.col, cls.colLast, cls.clickable)} onClick={copy}>
        {rightIcon ? rightIcon : (
          <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none" className={clsx(cls.icon, cls.rightIcon)}>
            <circle cx="19" cy="19" r="19" fill="#002A77"/>
            <path d="M21.987 20.8821C22.3035 21.1986 22.8148 21.1986 23.1313 20.8821L24.7546 19.2589C26.4184 17.5951 26.4184 14.9005 24.7546 13.2448C23.0908 11.5891 20.3962 11.581 18.7405 13.2448L17.1173 14.868C16.8008 15.1846 16.8008 15.6959 17.1173 16.0124C17.4338 16.329 17.9451 16.329 18.2617 16.0124L19.8849 14.3892C20.9156 13.3584 22.5794 13.3584 23.6102 14.3892C24.6409 15.4199 24.6409 17.0838 23.6102 18.1145L21.987 19.7377C21.6704 20.0543 21.6704 20.5656 21.987 20.8821Z" fill="white"/>
            <path d="M12.2479 25.7516C13.9117 27.4155 16.6062 27.4155 18.2619 25.7516L19.8852 24.1284C20.2017 23.8119 20.2017 23.3006 19.8852 22.984C19.5686 22.6675 19.0573 22.6675 18.7408 22.984L17.1175 24.6073C16.0868 25.638 14.423 25.638 13.3922 24.6073C12.3615 23.5765 12.3615 21.9127 13.3922 20.882L15.0155 19.2587C15.332 18.9422 15.332 18.4309 15.0155 18.1144C14.6989 17.7978 14.1876 17.7978 13.8711 18.1144L12.2479 19.7376C10.584 21.4014 10.584 24.096 12.2479 25.7516Z" fill="white"/>
            <path d="M16.6386 22.5056C16.3221 22.8221 15.8108 22.8221 15.4942 22.5056C15.1777 22.1891 15.1777 21.6778 15.4942 21.3612L20.3639 16.4915C20.6805 16.175 21.1918 16.175 21.5083 16.4915C21.8248 16.8081 21.8248 17.3194 21.5083 17.6359L16.6386 22.5056Z" fill="white"/>
          </svg>
        )}
      </div>
    </div>
  );
};
