export const UserIcon = () => (
  <svg width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M6.78906 0C3.47734 0 0.789062 2.68828 0.789062 6C0.789062 9.31172 3.47734 12 6.78906 12C10.1008 12 12.7891 9.31172 12.7891 6C12.7891 2.68828 10.1008 0 6.78906 0ZM6.78906 2.19349C7.89036 2.19349 8.78307 3.0862 8.78307 4.1875C8.78307 5.2888 7.89036 6.18151 6.78906 6.18151C5.68776 6.18151 4.79505 5.2888 4.79505 4.1875C4.79505 3.0862 5.68776 2.19349 6.78906 2.19349ZM11.0255 9.74349H11.026C9.99063 10.9148 8.47682 11.6531 6.78906 11.6531C5.1013 11.6531 3.5875 10.9148 2.55208 9.74349C2.59583 9.4651 2.72656 9.18854 2.80781 8.91771C2.94271 8.525 3.10599 8.07734 3.33958 7.72344C3.57214 7.37083 3.85911 7.11979 4.26667 7.05937C4.30964 7.05677 4.35208 7.05573 4.39427 7.05625C5.02813 7.06354 5.60234 7.40339 6.30391 7.56771C6.46927 7.60912 6.63099 7.625 6.78906 7.62266C6.94714 7.62526 7.10859 7.60938 7.27422 7.56771C8.0224 7.39245 8.62578 7.01745 9.31146 7.05937C9.71901 7.11953 10.006 7.37083 10.2385 7.72344C10.4719 8.07734 10.6354 8.525 10.7703 8.91771C10.8516 9.18854 10.9826 9.4651 11.0263 9.74349H11.0255Z"
      fill="#042E6B" />
  </svg>
);
