import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { AutoComplete } from 'antd';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';

import { http } from '@network';
import { InitialRow, InitialRowProps } from '@components/InitialCard';
import { ErrorMessage } from '@components/ErrorMessage';

interface ListOption {
  value: number;
  label: string;
  disabled?: boolean;
}

interface Props {
  source: string;
  reference: string;
  optionKey?: string;
  optionLabel?: string;
  placeholder?: string;
  disabledProp?: string;
}

export const AutoCompleteListInput = ({
  source, reference, optionKey, optionLabel, placeholder, disabledProp, ...initialRowProps
}: Props & InitialRowProps) => {
  const cls = useStyles();
  const {
    fieldState: { error },
    field: { value: ids, onChange: setIds },
  } = useController({ name: source });
  const [text, setText] = useState('');
  const [allItems, setAllItems] = useState<ListOption[]>([]);
  const [filteredItems, setFilteredItems] = useState<ListOption[]>([]);

  useEffect(() => {
    if (!text) {
      setFilteredItems(allItems);
    }
  }, [text]);

  useEffect(() => {
    (async () => {
      const response = await http.get<any>(reference || source, {
        params: { _start: 0, _end: 9999999 },
      });
      const nextOptions: ListOption[] = [];
      if (response.data && Array.isArray(response.data)) {
        (response.data ?? []).forEach(model => nextOptions.push({
          value: +`${model[optionKey ?? 'id']}`,
          label: model[optionLabel ?? 'nameMx'] || model['name']
            || model['title'] || model['activation'],
          disabled: disabledProp ? !!model[disabledProp] : undefined,
        }));
      }
      nextOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      setAllItems(nextOptions);
      setFilteredItems(nextOptions);
    })();
  }, []);

  const onSearch = (searchText: string) => setFilteredItems(searchText
    ? allItems.filter(o => o.label.toLowerCase().includes(searchText.toLowerCase()))
    : allItems,
  );
  const onSelect = (id: string) => {
    const nextIds = Array.isArray(ids) ? [+id, ...ids] : [+id];
    setIds(nextIds);
    setText('');
  };
  const onDelete = (deleteId: number) => {
    setIds(ids.filter((id: number) => id !== deleteId));
  };

  const selectedOptions = allItems.filter(item => (ids || []).includes(+item.value));
  const filteredItemsDisabled = filteredItems.map(item => {
    return {
      ...item,
      disabled: item.disabled || (ids || []).includes(+item.value),
    };
  });

  const errorMessage = (error as any)?.message?.message;

  return (
    <>
      <InitialRow {...initialRowProps}>
        <AutoComplete
          value={text}
          onChange={text => setText(text)}
          onClear={() => setText('')}
          allowClear
          options={filteredItemsDisabled}
          className={cls.autocompleteField}
          notFoundContent="Not found"
          onSelect={onSelect}
          onSearch={onSearch}
          placeholder={placeholder}
          status={errorMessage ? 'error' : undefined}
        />
        {errorMessage && <ErrorMessage text={errorMessage} />}
      </InitialRow>
      {selectedOptions.length > 0 && (
        <div className={cls.list}>
          {selectedOptions.map(opt => (
            <div key={opt.value} className={cls.listRow}>
              <div>{opt.label}</div>
              <IconButton className={cls.removeIcon} onClick={() => onDelete(opt.value)}>
                <RemoveIcon />
              </IconButton>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles({
  autocompleteField: {
    width: '100%',
    '& .ant-select-selector': {
      borderColor: 'transparent',
      boxShadow: 'none !important',
      padding: 0,
      border: 'none !important',
      borderBottom: '1px solid #d9d9d9 !important',
      outline: 'none',
    },
  },
  list: {
    marginTop: 16,
    borderRadius: 8,
    border: '1px solid #042E6B',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    paddingBottom: 10,
  },
  listRow: {
    color: '#000',
    fontFamily: 'Outfit, sans-serif',
    fontSize: 13,
    lineHeight: 1,
    padding: '10px 20px 2px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  removeIcon: {
    padding: 4,
  },
});

const RemoveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
    <circle cx="7.5" cy="7.5" r="7.5" fill="#D32F2F" />
    <path d="M9.38832 8.04606L10.7027 9.36236C11.0734 9.73362 11.1071 10.3749 10.7196 10.7462C10.5342 10.9149 10.2983 10.9993 10.0624 10.9993C9.82645 10.9993 9.57368 10.9149 9.38832 10.7293L8.04022 9.37924C7.7369 9.07547 7.26506 9.07547 6.96174 9.37924L5.61364 10.7293C5.42828 10.9149 5.19236 10.9993 4.93959 10.9993C4.70368 10.9993 4.46776 10.9149 4.2824 10.7462C3.89482 10.3749 3.91167 9.73362 4.29925 9.36236L5.61364 8.04606C5.91696 7.7423 5.91696 7.26979 5.61364 6.96602L4.33295 5.64973C3.96222 5.27846 3.92852 4.68782 4.2824 4.29968C4.65312 3.91154 5.25977 3.89467 5.63049 4.2828L6.97859 5.63285C7.28191 5.93661 7.75375 5.93661 8.05707 5.63285L9.40517 4.2828C9.7759 3.91154 10.3994 3.91154 10.7533 4.29968C11.1071 4.67094 11.0734 5.27846 10.7027 5.64973L9.38832 6.96602C9.10185 7.26979 9.10185 7.7423 9.38832 8.04606Z"
      fill="white" />
  </svg>
);

