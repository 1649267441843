export const getQuarter = (dateString: string) => {
  if (!dateString) {
    return '';
  }

  if (dateString.length === 0) {
    return '';
  }
    
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  let quarter;
  if (month >= 1 && month <= 3) {
    quarter = 1;
  } else if (month >= 4 && month <= 6) {
    quarter = 2;
  } else if (month >= 7 && month <= 9) {
    quarter = 3;
  } else {
    quarter = 4;
  }

  return `Q${quarter}${year}`;
};
