import { memo, useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import { Button } from 'antd';

import { Credit } from '@types';
import { creditHttp } from '@network';
import { CreditWidget } from '../CreditsWidget';
import cls from './credits.module.css';

interface CreditsProps {
  userId: number;
  editable: boolean;
  getBankInfo?: boolean;
}

const CreditsInner = ({ userId, editable, getBankInfo = true }: CreditsProps) => {
  const [showAddButton] = useState<boolean>(true);
  const [newCredit, setNewCredit] = useState<Credit>();
  const [credits, setCredits] = useState<Credit[]>([]);

  const redirect = useRedirect();

  useEffect(() => {
    (async () => {
      const nextCredits = await creditHttp.getCreditsByUser(userId, getBankInfo);
      setCredits(nextCredits);
    })();
  }, [userId, getBankInfo]);

  const gotoCreditCreate = async () => {
    await creditHttp.createCredit({ userId }).then((result: any) => {
      setNewCredit(result);
    });
  };

  useEffect(() => {
    if (newCredit) {
      redirect(`/credit/${newCredit.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCredit]);


  return (
    <div className={cls.wrap}>
      {showAddButton && editable ? (
        <div className={cls.actions}>
          <Button size="large" onClick={gotoCreditCreate}>Create new credit</Button>
        </div>
      ) : null}
      <div className={cls.list}>
        {credits.length === 0
          ? <div className={cls.empty}>No credits</div>
          : credits.map(credit => <CreditWidget credit={credit} key={credit.id} />)
        }
      </div>
    </div>
  );
};

export const Credits = memo(CreditsInner);
