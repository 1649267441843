import { FC } from 'react';
import * as AntdIcons from '@ant-design/icons';

interface Props {
  type: string;
  className?: any;
}

export const AntdDynamicIcon: FC<Props> = ({ type, className }) => {
  const AntdIcon = (AntdIcons as any)[type];

  if (!AntdIcon) {
    return null;
  }

  return <AntdIcon className={className} />;
};
