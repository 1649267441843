import { useEffect, useRef, useState } from 'react';
import { Form, Input, InputProps } from 'antd';
import { clsx } from 'clsx';

import cls from './month-year-input.module.css';

interface Props extends InputProps {
  name: string;
  label?: string;
  error?: string;
  minYear?: number;
  maxYear?: number;
  fieldsetClass?: any;
  lockFutureDate?: boolean;
}

const monthsMap = {
  Enero: 1,
  Febrero: 2,
  Marzo: 3,
  Abril: 4,
  Mayo: 5,
  Junio: 6,
  Julio: 7,
  Agosto: 8,
  Septiembre: 9,
  Octubre: 10,
  Noviembre: 11,
  Diciembre: 12,
};
const months = Object.keys(monthsMap);
type MonthType = keyof typeof monthsMap;

const range = (start: number, stop: number) =>
  Array.from({ length: stop - start + 1 }, (_, i) => start + i);
const splitter = ' / ';
const blankMonth = 'Mes';
const blankYear = 'Año';

export const MonthYearInput = ({
  name,
  label,
  fieldsetClass,
  style,
  className,
  required,
  minYear = 1980,
  maxYear = new Date().getFullYear(),
  lockFutureDate,
  ...rest
}: Props) => {
  const [opened, setOpened] = useState(false);
  const form = Form.useFormInstance<any>();
  const value = form.getFieldValue(name) || '';
  const fieldsetRef = useRef<any>();
  const monthsRef = useRef<any>();
  const yearsRef = useRef<any>();
  const years = range(minYear, maxYear);

  useEffect(() => {
    document.addEventListener('click', function (event) {
      const container = fieldsetRef.current;
      if (container && container !== event.target && !container.contains(event.target)) {
        setOpened(false);
      }
    });
  }, []);

  useEffect(() => {
    const digitsCount = ((value || '').match(/[0-9]/g) || []).length;
    if (digitsCount === 8) {
      const [, month, year] = value.split('/');
      const monthIndex = parseInt(month) - 1;
      const nextDate = `${months[monthIndex]}${splitter}${year}`;
      form.setFieldsValue({ [name]: nextDate });
      return;
    }
    const [month, year] = (value || '').trim()
      .replace(blankMonth, '')
      .replace(blankYear, '')
      .split(splitter);
    if (month && year) {
      const nextDate = `${month}${splitter}${year}`;
      form.setFieldsValue({ [name]: nextDate });
    }
  }, [value]);

  const openPicker = () => {
    setOpened(true);
    const [month, year] = (form.getFieldValue(name) || '').split(splitter);
    monthsRef.current.scrollTo({
      top: monthsRef.current.querySelector(`[data-key="${month}"]`)?.offsetTop - 10,
    });
    yearsRef.current.scrollTo({
      top: yearsRef.current.querySelector(`[data-key="${year}"]`)?.offsetTop - 10,
    });
  };

  const onMonthPick = (event: any, nextMonth: MonthType) => {
    event.stopPropagation();
    const [, year] = (form.getFieldValue(name) || '').split(splitter);
    let nextDate = `${nextMonth} / ${year || blankYear}`;
    if (lockFutureDate) {
      const valueMonth = monthsMap[nextMonth] - 1;
      const valueYear = +(value || '').split(splitter)[1];
      const currMonth = new Date().getMonth();
      const currYear = new Date().getFullYear();
      if (valueYear > currYear || (valueYear === currYear && valueMonth > currMonth)) {
        nextDate = `${months[currMonth]}${splitter}${currYear}`;
      }
    }
    form.setFieldsValue({ [name]: nextDate });
    nextMonth && year && year !== blankYear && setOpened(false);
  };

  const onYearPick = (event: any, nextYear: string | number) => {
    event.stopPropagation();
    const [month] = (form.getFieldValue(name) || '').split(splitter);
    let nextDate = `${month || blankMonth} / ${nextYear}`;
    if (lockFutureDate) {
      const valueYear = +(value || '').split(splitter)[1];
      const currYear = new Date().getFullYear();
      if (valueYear > currYear) {
        nextDate = `${month || blankMonth} / ${currYear}`;
      }
    }
    form.setFieldsValue({ [name]: nextDate });
    month && nextYear && month !== blankMonth && setOpened(false);
  };

  return (
    <div className={cls._} style={style} ref={fieldsetRef}>
      <Input
        name={name}
        placeholder={`${blankMonth}${splitter}${blankYear}`}
        onKeyPress={(e) => void e.preventDefault()}
        onFocus={openPicker}
        value={value}
        {...rest}
        className={clsx(cls.input, className)}
      />

      <div className={cls.popup} style={{ visibility: opened ? 'visible' : 'hidden' }}>
        <div className={cls.select} ref={monthsRef}>
          {months.map(month => (
            <div
              key={month}
              data-key={month}
              className={value.includes(month) ? cls.selectItemPicked : cls.selectItem}
              onClick={evt => onMonthPick(evt, month as MonthType)}
            >
              {month}
            </div>
          ))}
        </div>

        <div className={cls.select} ref={yearsRef}>
          {years.map(year => (
            <div
              key={year}
              data-key={year}
              className={value.includes(year) ? cls.selectItemPicked : cls.selectItem}
              onClick={evt => onYearPick(evt, year)}
            >
              {year}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
