import { Link } from 'react-admin';
import PlusIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';

import { BlueOutlineButton, InitialCard } from '@components';
import { ActivationSettingsResponse } from '@network/finance-settings-http';

interface Props {
  settings?: ActivationSettingsResponse;
}

const EditBtn = () => (
  <Link to={'/finance-activation-settings/1'}>
    <BlueOutlineButton size="small">
      <PlusIcon /> Edit elements
    </BlueOutlineButton>
  </Link>
);

export const FinanceActivationSettings = ({ settings }: Props) => {
  const cls = useStyles();
  const statuses = settings?.statuses ?? [];
  const options = settings?.options ?? [];

  return (
    <InitialCard left="Activation settings" extraPadding mt={20}>
      <div className={cls.line}>
        <div>
          <span className={cls.label}>Type options:</span>
          <span>{statuses.length} Elements</span>
        </div>
        <div>
          <span className={cls.label}>Origin options:</span>
          <span>{options?.length} Elements</span>
        </div>
        <div>
          <EditBtn />
        </div>
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    fontSize: 18,
    fontWeight: 400,
  },
  label: {
    color: '#042E6B',
    marginRight: 18,
  },
});
