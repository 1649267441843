import { FunctionFieldProps, FunctionField } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { Button } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import { useRequest } from '@hooks';
import { userHttp } from '@network';
import { User } from '@types';

export const UserLoginBtn = (props: Partial<FunctionFieldProps>) => {
  return (
    <FunctionField
      {...props}
      sortable={false}
      render={(record: any) => (
        <UserLoginWidget user={record} />
      )}
    />
  );
};

const UserLoginWidget = ({ user }: {user: User}) => {
  const cls = useStyles();

  const { loading, submit } = useRequest(async () => {
    const loginUrl = await userHttp.loginAdmin(user.id);
    window.open(loginUrl, '_blank')?.focus();
  });

  return (
    <div onClick={e => e.stopPropagation()} className={cls.box}>
      <Button
        onClick={submit}
        icon={<LoginOutlined />}
        type="ghost"
        loading={loading}
        className={cls.btn}
      >Login</Button>
    </div>
  );
};

const useStyles = makeStyles({
  box: {},
  btn: {
    marginBottom: 15,
  },
});
