import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import cls from './InputSearch.module.css';

interface Props {
  value: string;
  setValue: Dispatch<SetStateAction<string>>
}

export const InputSearch = ({ value, setValue }: Props) => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  return (
    <Input
      className={cls.input}
      suffix={<SearchOutlined style={{ fontSize: 16 }} />}
      placeholder="Search"
      value={value}
      onChange={onChangeHandler}
    />
  );
};
