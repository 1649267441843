import { FunctionFieldProps, FunctionField } from 'react-admin';
import { makeStyles } from '@mui/styles';

import { User } from '@types';

export const UserCity = (props: Partial<FunctionFieldProps>) => {
  return (
    <FunctionField
      {...props}
      sortable={false}
      render={(record: any) => (
        <UserCityWidget user={record} />
      )}
    />
  );
};

const UserCityWidget = ({ user }: {user: User}) => {
  const cls = useStyles();
  const cities = (user.location || []).map(loc => loc.city).filter(Boolean);

  return (
    <div className={cls.box}>
      {cities.join(', ')}
    </div>
  );
};

const useStyles = makeStyles({
  box: {},
});
