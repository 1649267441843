import {
  ListProps,
  TextField,
  RichTextField,
} from 'react-admin';

import {
  BooleanField,
  ImageField,
  TextInput,
  Grid,
  GridList,
  ExternalUrlField,
  Forbidden,
  DateField,
} from '@components';
import { useRbacList } from '@hooks';

export const BlogList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'title link publishDateEn image isEnabled isMain isLastNews isRecommendation'.split(' ')}
      >
        <TextField source="id" />
        <TextField source="title" />
        <ExternalUrlField source="link" label="Website link" />
        <RichTextField source="announce" />
        <RichTextField source="description" />
        <TextField source="author" />
        <DateField source="publishAt" label="Publish at" showTime />
        <DateField source="publishDateEn" label="Publish date" />
        <ImageField source="image" maxHeight={40} width={120} />
        <DateField source="createdAt" label="Created" />
        <BooleanField source="isEnabled" label="Enabled" />
        <BooleanField source="isMain" label="Main" />
        <BooleanField source="isLastNews" label="Last News" />
        <BooleanField source="isRecommendation" label="Recommendation" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 220 }} />,
];
