import { useState } from 'react';
import { notification } from 'antd';

import { axiosErrorToString } from '@utils';

export function useRequest<T = undefined>(runRequest: (params?: any) => Promise<T>) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const submit = async (params?: any) => {
    setLoading(true);

    try {
      const result = await runRequest(params);
      setError(null);

      return result;
    } catch (err: any) {
      const errorMessage = axiosErrorToString(err);
      setError(errorMessage);
      notification.error({ message: errorMessage, duration: 8 });
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, submit };
}
