import { Create, CreateProps, SimpleForm } from 'react-admin';

import {
  Row,
  Col,
  Container,
  ImageInput,
  BooleanInput,
  TextInput,
  NumberInput,
  EditorInput,
  Forbidden,
} from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';

export const JobCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps}>
      <SimpleForm>
        <Container max>
          <Row>
            <Col><TextInput source="title" required autoFocus /></Col>
            <Col><TextInput source="location" /></Col>
            <Col><TextInput source="employment" /></Col>
            <Col><TextInput source="department" /></Col>
          </Row>
          <Row>
            <Col flex={1}>
              <EditorInput source="whatWeOffer" label="What we offer" />
            </Col>
            <Col flex={1}>
              <EditorInput source="aboutUs" label="About us" />
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <EditorInput source="lookingFor" />
            </Col>
            <Col flex={1}>
              <EditorInput source="responsibleFor" />
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <EditorInput source="requiredSkills" />
            </Col>
            <Col flex={1}>
              <EditorInput source="desiredSkills" />
            </Col>
          </Row>
          <Row>
            <Col><BooleanInput source="isEnabled" defaultValue={true} /></Col>
            <Col><BooleanInput source="isRemote" defaultValue={false} /></Col>
            <Col flex={2}><ImageInput source="image" /></Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};
