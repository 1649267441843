import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { useModalState } from '@hooks';

interface Props {
  isOpenedModal: boolean;
  showModal: () => void;
  hideModal: () => void;
  title?: string;
  setTitle: (title?: string) => void;
}

const ModalContext = createContext<Props>({
  isOpenedModal: false,
  showModal: () => {},
  hideModal: () => {},
  title: undefined,
  setTitle: () => {},
});

export const ModalProvider = ({ children }: PropsWithChildren<any>) => {
  const [title, setTitle] = useState<string>();
  const { is: isOpenedModal, show: showModal, hide: hideModal } = useModalState();

  return (
    <ModalContext.Provider value={{ isOpenedModal, showModal, hideModal, title, setTitle }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);
