import { Edit, EditProps, maxLength, required, SimpleForm } from 'react-admin';
import { Typography } from '@mui/material';

import { Col, Container, Forbidden, Row, TextInput } from '@components';

import cls from './seo.module.css';
import { useEditProps, useRbacEdit, useRbacView, useRecord } from '@hooks';

export const SeoEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const record = useRecord(props);
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  if (!record) return null;

  return (
    <div style={{ marginBottom: 40 }}>
      <Edit title="SEO Edit" {...props} {...editProps}>
        <SimpleForm className={cls.form} toolbar={rbacEdit ? undefined : false}>
          <Container max>
            <Typography variant="h5" component="h2" style={{ marginBottom: 20 }}>New page SEO
              data</Typography>
            <Row>
              <Col>
                <TextInput
                  name="page"
                  source="page"
                  label="Page identifier"
                  validate={[required(), maxLength(50, 'Maximum length is 50 symbols.')]}
                />
              </Col>
              <Col />
            </Row>
            <Row marginTop={20}>
              <Col>
                <TextInput
                  name="titleEn"
                  source="titleEn"
                  validate={[required(), maxLength(100, 'Maximum length of the title is 100 symbols.')]}
                  label="Page title EN"
                  helperText={`Page title template in English / ${record.titleEn.length}`}
                />
              </Col>
              <Col>
                <TextInput
                  name="titleEs"
                  source="titleEs"
                  validate={[required(), maxLength(100, 'Maximum length of the title is 100 symbols.')]}
                  label="Page title ES"
                  helperText={`Page title template in Spanish / ${record.titleEs.length}`}
                />
              </Col>
            </Row>
            <Row marginTop={20}>
              <Col>
                <TextInput
                  name="descriptionEn"
                  source="descriptionEn"
                  multiline
                  label="Description EN"
                  validate={[required(), maxLength(200, 'Maximum length of the description is 200 symbols.')]}
                  maxRows={3}
                  rows={3}
                  helperText={`Page description template in English / ${record.descriptionEn.length}`}
                />
              </Col>
              <Col>
                <TextInput
                  name="descriptionEs"
                  source="descriptionEs"
                  multiline
                  label="Description ES"
                  validate={[required(), maxLength(200, 'Maximum length of the description is 200 symbols.')]}
                  maxRows={3}
                  rows={3}
                  helperText={`Page description template in Spanish / ${record.descriptionEs.length}`}
                />
              </Col>
            </Row>
            <Row marginTop={20}>
              <Col>
                <TextInput
                  name="keywordsEn"
                  source="keywordsEn"
                  label="Keywords EN"
                  validate={[maxLength(250, 'Maximum length of the keywords phrases list is 250 symbols.')]}
                  helperText={`Keywords list divided by the comma in English / ${record.keywordsEn.length}`}
                />
              </Col>
              <Col>
                <TextInput
                  name="keywordsEs"
                  source="keywordsEs"
                  label="Keywords ES"
                  validate={[maxLength(250, 'Maximum length of the keywords phrases list is 250 symbols.')]}
                  helperText={`Keywords list divided by the comma in Spanish / ${record.keywordsEs.length}`}
                />
              </Col>
            </Row>
            <Row marginTop={20}>
              <Col>
                <div className={cls.result}>
                  <h3>Google search preview in English</h3>
                  <div className={cls.title}>{record.titleEn || 'Page title'}</div>
                  <div className={cls.description}>{record.descriptionEn || 'Page description'}</div>
                </div>
              </Col>
              <Col>
                <div className={cls.result}>
                  <h3>Google search preview in Spanish</h3>
                  <div className={cls.title}>{record.titleEs || 'Page title'}</div>
                  <div className={cls.description}>{record.descriptionEs || 'Page description'}</div>
                </div>
              </Col>
            </Row>
          </Container>
        </SimpleForm>
      </Edit>
    </div>
  );
};
