import {
  ListProps,
  TextField,
  SimpleList,
  RichTextField,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import { TextInput, Grid, GridList, DateField, BooleanField } from '@components';
import { dateFormatter } from '@utils';

export const FaqList = (props: ListProps) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (isSmall) {
    return (
      <GridList {...props}
        filters={Filters}
        sort={{ field: 'id', order: 'DESC' }}
      >
        <SimpleList
          primaryText={record => `Faq #${record.id}`}
          secondaryText={record => null}
          tertiaryText={record => dateFormatter.toDate(record.createdAt)}
        />
      </GridList>
    );
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick="edit"
        defaultColumns={['id', 'question', 'answer', 'isEnabled', 'createdAt']}
      >
        <TextField source="id" label="ID" />
        <TextField source="question" label="Question" />
        <RichTextField source="answer" label="Answer" />
        <BooleanField source="isEnabled" label="Enabled" />
        <DateField source="createdAt" label="Created" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
];
