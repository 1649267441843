import {
  ListProps,
  TextField,
  ReferenceField,
  ReferenceInput,
} from 'react-admin';

import { TextInput, SelectInput, Grid, GridList, Forbidden } from '@components';
import { checkSuperadmin } from '@providers';
import { useRbacList } from '@hooks';

export const CityList = (props: ListProps) => {
  const isSuperadmin = checkSuperadmin();
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        {isSuperadmin ? (
          <ReferenceField source="stateId" reference="state" label="State">
            <TextField source="name" />
          </ReferenceField>
        ) : (
          <TextField source="state.name" label="State" />
        )}
        <TextField source="latitude" />
        <TextField source="longitude" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  <ReferenceInput
    source="stateId"
    key="stateId"
    reference="state"
    label="State"
    allowEmpty
    alwaysOn
    perPage={1000}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];
