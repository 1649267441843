import { clsx } from 'clsx';
import { useEffect, useState } from 'react';

import { moneyFormatter } from '@utils';
import { financeDashboardHttp } from '@network';
import { FinanceDashboardBalance } from '@types';
import cls from './balance.module.css';

interface TableProps {
  className: string;
  title: string;
  topValue: number | string;
  bottomValue: number | string;
  totalValue?: number | string;
}

const Table = ({ className, title, topValue, bottomValue, totalValue }: TableProps) => {
  const getValue = (value: number | string) => (typeof value === 'string') ? value : moneyFormatter.format(value);

  return (
    <div className={clsx(cls.table, className)}>
      <div className={clsx(cls.td, cls.tableHeader)}>{title}</div>
      <div className={clsx(cls.td, cls.topValue)}>{getValue(topValue)}</div>
      <div className={clsx(cls.td, cls.bottomValue)}>{getValue(bottomValue)}</div>
      {totalValue && (
        <div className={clsx(cls.td, cls.totalValue)}>
          <div className={cls.label}>Total</div>
          <div className={cls.value}>{getValue(totalValue)}</div>
        </div>
      )}
    </div>
  );
};

export const Balance = () => {
  const [balance, setBalance] = useState<FinanceDashboardBalance | null>(null);

  const getOptions = async () => {
    try {
      const balanceData: FinanceDashboardBalance = await financeDashboardHttp.getBalance();

      if (!balanceData) {
        throw new Error('The balance has not been received');
      }

      setBalance(balanceData);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getOptions();
    })();
  }, []);

  return !balance ? null : (
    <div className={cls.wrap}>
      <div className={cls.row}>
        <Table className={cls.col} title="Type" topValue="Operations" bottomValue="Credits"/>
        <Table
          className={cls.col}
          title="Inflows"
          topValue={balance.operationsIncomeSum}
          bottomValue={balance.creditIncomeSum}
          totalValue={balance.operationsIncomeSum + balance.creditIncomeSum}
        />
        <Table
          className={cls.col}
          title="Outflows"
          topValue={balance.operationsOutcomeSum}
          bottomValue={balance.creditOutcomeSum}
          totalValue={balance.operationsOutcomeSum + balance.creditOutcomeSum}
        />
        <Table
          className={cls.col}
          title="Balance"
          topValue={balance.operationsBalance}
          bottomValue={balance.creditsBalance}
          totalValue={balance.operationsBalance + balance.creditsBalance}
        />
      </div>
    </div>
  );
};
