import cls from './SurveyMultipleAnswer.module.css';
import { SurveyQuestion } from '@types';

export const SurveyMultipleAnswer = ({ value }: { value: SurveyQuestion }) => {
  return (
    <div className={cls.wrap}>
      {value.answerIds.length ? (
        <div className={cls.list}>
          {value.answerIds.map(id => {
            const answer = value.answers.find(a => a.id === id);
            return answer ? (
              <div className={cls.answer} key={id}>{answer.nameMx}</div>
            ) : null;
          })}
        </div>
      ) : (
        <div className={cls.empty}>No answers</div>
      )}
    </div>
  );
};
