import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { useRedirect } from 'react-admin';

import { RiskAdminQuestion } from '@types';
import { BlueButton, InitialCard } from '@components';
import cls from './Terms.module.css';

interface TableItem {
  title: string;
  isFixed: boolean;
}

const Table = ({ items }: {items: RiskAdminQuestion[]}) => {
  const list: TableItem[] = [];
  for (let i = 0; i < 10; i++) {
    list[i] = items[i] ? { title: items[i].title, isFixed: items[i].isFixed } : { title: '', isFixed: false };
  }

  return (
    <div className={cls.table}>
      {list.map((item: TableItem, idx: number) => (
        <div key={idx} className={clsx(cls.tableRow, item.isFixed && cls.fixed)}>{item.title}</div>
      ))}
    </div>
  );
};

export const Terms = ({ items, readonly }: {items: RiskAdminQuestion[], readonly?: boolean}) => {
  const redirect = useRedirect();

  const getList = (tableFirst?: boolean) => {
    if (tableFirst) {
      return items.slice(0, 11);
    }
    return items.length > 10 ? items.slice(10) : [];
  };

  return (
    <InitialCard
      left="Credit terms"
      mediumPadding
    >
      <div className={clsx(cls.wrap, cls.viewPage)}>
        <Row gutter={20} className={cls.row}>
          <Col xs={24} md={12} lg={8}>
            <Table items={getList(true)} />
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Table items={getList()} />
          </Col>
          <Col xs={24} lg={8}>
            <div className={cls.totalWrap}>
              <div className={cls.total}>
                <div className={cls.title}>Total Variables</div>
                <div className={cls.counter}>{items.length}</div>
                <div className={cls.actions}>
                  <BlueButton
                    onClick={() => redirect('/risk/1?terms')}
                    extraPadding
                    className={cls.action}
                  >Edit</BlueButton>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </InitialCard>
  );
};
