import { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { Button } from '@mui/material';
import { Modal, Select, SelectProps } from 'antd';
import { clsx } from 'clsx';

import { axiosErrorToString, moneyFormatter } from '@utils';
import { Credit, CreditInvoiceStatusType } from '@types';
import { creditHttp } from '@network';
import cls from './credit-invoice-status.module.css';

const statuses = Object.values(CreditInvoiceStatusType);

interface Props {
  currentStatus: CreditInvoiceStatusType;
  updateStatus: (status: CreditInvoiceStatusType) => void;
  submit: (creditId?: number) => void;
  credit: Credit;
  invoiceTotal: number;
}

export const CreditInvoiceStatus = ({
  currentStatus, updateStatus, submit, credit, invoiceTotal,
}: Props) => {
  const notify = useNotify();

  const [isStatusListActive, setIsStatusListActive] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [creditsList, setCreditsList] = useState<Credit[]>([]);
  const [selectedCreditId, setSelectedCreditId] = useState<number | null>(null);

  const selectStatus = (newStatus: CreditInvoiceStatusType) => {
    setIsStatusListActive(false);
    updateStatus(newStatus);
  };

  const selectCredit = (creditId: number) => {
    setSelectedCreditId(creditId);
  };

  const handleOk = () => {
    setIsModalOpen(false);

    if (selectedCreditId) {
      submit(selectedCreditId);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const loadedCreditsList = await creditHttp.getCreditsByUser(credit.userId);
        setCreditsList(loadedCreditsList);

        const [firstCredit] = loadedCreditsList;
        setSelectedCreditId(firstCredit?.id || null);
      } catch (e) {
        notify(axiosErrorToString(e));
      }
    })();
  }, []);

  const creditOptions: SelectProps['options'] = creditsList.map(creditOption => ({
    value: creditOption.id,
    label: `Credit #${creditOption.code} (${creditOption.statusEn}) Balance: ${moneyFormatter.format(creditOption.balance)}`,
  }));

  return (
    <div className={cls._}>
      <div className={cls.wrap}>
        <div className={cls.action} onClick={() => setIsStatusListActive(prev => !prev)}>
          <span>Status</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
            <path d="M4.08793 5.42919C4.22701 5.42557 4.35983 5.36472 4.46082 5.25838L7.96917 1.50706C8.11326 1.35159 8.1724 1.12244 8.12404 0.905564C8.07567 0.688691 7.92707 0.516755 7.73436 0.454513C7.54165 0.39227 7.33366 0.44893 7.18856 0.603561C5.50154 2.40843 2.63923 2.40843 0.952213 0.603561C0.807117 0.448931 0.599122 0.39227 0.406413 0.454513C0.213454 0.516756 0.0651012 0.688691 0.0167356 0.905565C-0.0316299 1.12244 0.0275112 1.35159 0.171604 1.50706L3.67996 5.25838C3.78972 5.37421 3.93707 5.43589 4.08793 5.42919Z" fill="#D9D9D9"/>
          </svg>
        </div>

        <div
          className={clsx(cls.label, cls[currentStatus])}
          onClick={() => setIsStatusListActive(prev => !prev)}
        >
          {currentStatus}
        </div>

        {isStatusListActive && (
          <div className={cls.list}>
            {statuses.filter(status => status !== currentStatus).map(status => (
              <div
                key={status}
                className={clsx(cls.item, cls[status])}
                onClick={() => selectStatus(status)}
              >
                {status}
              </div>
            ))}
          </div>
        )}
      </div>
      <Button
        className={cls.submit}
        variant="contained"
        onClick={() =>
          currentStatus === CreditInvoiceStatusType.APPROVED ? setIsModalOpen(true) : submit()
        }
      >
        Confirm
      </Button>

      <Modal
        title="Confirm order"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => setIsModalOpen(false)}
        className={cls.modal}
        centered
      >
        <div className={cls.modalTitle}>Order total: {moneyFormatter.format(invoiceTotal)}</div>
        <Select
          value={selectedCreditId}
          onChange={(e) => selectCredit(e)}
          className={cls.modalSelect}
          showSearch
          placeholder="Add product"
          options={creditOptions}
        />
      </Modal>
    </div>
  );
};
