import { makeStyles } from '@mui/styles';

import { CreditRisk } from '@types';
import { moneyFormatter } from '@utils';
import { TableCropRow } from '@network';
import { clsx } from 'clsx';
import React from 'react';

export const RiskCrops = ({ risk }: {risk: CreditRisk}) => {
  const cls = useStyles();
  const { tableCrop } = risk;

  if (!tableCrop || !tableCrop.headers || !tableCrop.headers.length) {
    return null;
  }

  const rows = tableCrop.rows;
  let incomeTotal = 0;
  let profit = 0;

  for (const row of rows) {
    incomeTotal += +(row.incomeTotal || 0);
    profit += +(row.profit || 0);
  }

  const accumulatedRow: TableCropRow = {
    year: 'Accumulated',
    incomeTotal,
    profit,
  };

  const getValue = (value: number | string | null): string => {
    if (!value) {
      return '';
    } else {
      return typeof value === 'number' ? moneyFormatter.format(value || 0).toString() : value;
    }
  };

  return (
    <>
      <div className={cls.title}>Fiscal Income of Crop to Finance</div>
      <div className={cls.wrap}>
        <div className={clsx(cls.row, cls.categories)}>
          <div className={clsx(cls.category, cls.col, cls.col_full)}>Income</div>
        </div>
        <div className={cls.row}>
          {tableCrop.headers.map(header => (
            <div key={header.key} className={clsx(cls.header, cls.col, cls.col_1)}>
              {header.label}
            </div>
          ))}
        </div>
        <div>
          {tableCrop.rows.map((row, idx) => (
            <div key={idx} className={cls.row}>
              {tableCrop.headers.map((header) => (
                <div key={header.key} className={clsx(cls.content, cls.bg, cls.col, cls.col_1)}>
                  {getValue(row[header.key])}
                </div>
              ))}
            </div>
          ))}
          <div className={cls.row}>
            {tableCrop.headers.map((header) => (
              <div key={header.key} className={clsx(cls.content, cls.col, cls.col_1)}>
                {getValue(accumulatedRow[header.key])}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles({
  title: {
    margin: '25px 0 5px',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: '#000',
  },
  wrap: {
    border: '1px solid #042E6B',
    borderRadius: 5,
    overflow: 'hidden',
  },
  row: {
    display: 'flex',
  },
  col: {
    backgroundColor: '#FFF',
  },
  col_1: {
    flex: '0 0 33.33%',
    maxWidth: '33.33%',
  },
  col_full: {
    flex: '0 0 100%',
    maxWidth: '100%',
  },
  categories: {
    backgroundColor: '#042E6B',
  },
  category: {
    minHeight: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: '#042E6B',
    borderLeft: '1px solid #FFF',
    '&:first-child': {
      borderLeft: 'none',
    },
  },
  header: {
    minHeight: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 500,
    color: '#042E6B',
    borderLeft: '1px solid #042E6B',
    '&:first-child': {
      borderLeft: 'none',
    },
  },
  bg: {
    backgroundColor: '#F0F3FB',
  },
  content: {
    borderTop: '1px solid #042E6B',
    borderLeft: '1px solid #042E6B',
    '&:first-child': {
      borderLeft: 'none',
    },
    minHeight: 34,
    fontSize: 12,
    fontWeight: 400,
    color: '#042E6B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
