import { Link } from 'react-admin';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';

import { Order } from '@types';
import { dateFormatter, moneyFormatter } from '@utils';
import cls from './user-order-detail.module.css';

interface UserOrderDetailProps {
  order: Order;
  selectOrder: (arg: null) => void;
}

export const UserOrderDetail = ({ order, selectOrder }: UserOrderDetailProps) => {
  return (
    <div className={cls._}>
      <div className={cls.wrap}>
        <div className={cls.close} onClick={() => selectOrder(null)} />
        <div className={cls.head}>
          <div className={cls.title}>Orders #{order.id}</div>
          <div className={cls.date}>
            {`${dateFormatter.toDateSlash(order.createdAt)} ${dateFormatter.toTime(order.createdAt)}`}
          </div>
          <div className={cls.status}>
            {order.statusEn}
            {order.status === 'paid' && <div className={cls.paid} />}
            {order.status === 'cancelled' && <div className={cls.canceled} />}
          </div>
        </div>
        <div className={cls.content}>
          <div className={cls.list}>
            {order.positions?.map(position => (
              <div key={position.id} className={cls.item}>
                <figure className={cls.itemImage}>
                  <picture>
                    {position.product?.image ? <img src={position.product.image} alt="" /> : <NoPhotographyIcon />}
                  </picture>
                </figure>
                <div className={cls.itemInfo}>
                  <div className={cls.itemTitle}>{position.product?.name || ''}</div>
                  <div className={cls.itemBrand}>{position.product?.distributor || ''}</div>
                  <div className={cls.itemDetails}>
                    <span>{moneyFormatter.format(position.productPrice)}</span>
                    <span>X{position.qty}</span>
                    <span>{moneyFormatter.format(position.totalCost)}</span>
                  </div>
                </div>
              </div>
            ))}

            {order.positions.length === 0 && (
              <div>
              No positions
              </div>
            )}
          </div>
          <div className={cls.total}>
            <span>Total</span>
            <span>{moneyFormatter.format(order.totalCost)}</span>
          </div>
        </div>

        <div className={cls.actions}>
          <Link to={`/order/${order.id}`}>
            <button className={cls.action}>Open in orders</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
