import { Link } from 'react-admin';

interface Props {
  id: number;
  name: string;
  isManager?: boolean;
  isSuperadmin?: boolean;
}

export const HistoryAdminName = ({ id, name, isManager, isSuperadmin = false }: Props) => {
  const linkTo = isManager ? `/manager/${id}` : `/admin/${id}`;
  const by = isManager ? 'Manager B2B' : 'Admin';

  return (
    <span>by {isSuperadmin
      ? <Link to={linkTo}>{name}</Link>
      : <span>{name}</span>
    } ({by})
    </span>
  );
};
