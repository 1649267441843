import {
  DateInput,
  DateTimeInputProps,
} from 'react-admin';
import { makeStyles } from '@mui/styles';

export const DateRangeFilterRA = (props: DateTimeInputProps) => {
  const { source } = props;
  const useStyles = makeStyles({
    dateStart: {
      '& .MuiInputBase-root': {
        borderTopRightRadius: 0,
      },
    },
    dateEnd: {
      '& .MuiInputBase-root': {
        borderTopLeftRadius: 0,
      },
    },
  });
  const stylesCls = useStyles();

  return (
    <>
      <DateInput
        { ...props }
        source={`${source}Start`}
        className={stylesCls.dateStart}
      />
      <DateInput
        { ...props }
        source={`${source}End`}
        className={stylesCls.dateEnd}
      />
    </>
  );
};
