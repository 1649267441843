import { Button, Toolbar } from '@mui/material';
import { DeleteButton, Edit, EditProps, Link, SaveButton, SimpleForm } from 'react-admin';

import { rbac } from '@utils';
import { SupportManual } from '@types';
import { InitialPage } from '@components';
import { ManualsView } from './ManualsView';
import { ManualsForm, validate } from './ManualsForm';
import { useEditProps, useQuery, useRbacEdit, useRbacReadonly, useRecord } from '@hooks';
import cls from './Manuals.module.css';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Manual: {record.name}</span>) : null;

export const ManualsEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const record = useRecord<SupportManual>(props);
  const rbacEdit = useRbacEdit();
  const query = useQuery();
  const showView = query.get('view') === '';
  const rbacReadonly = useRbacReadonly();
  const isViewPage = rbacReadonly || showView;

  const noEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const EditActions = () => {
    return rbacEdit ? (
      <Toolbar className={cls.toolbar}>
        <Link to={`/manuals/${record.id}?view`}>
          <Button variant="outlined" color="secondary" className={cls.viewBtn}>View</Button>
        </Link>
        <DeleteButton className={cls.removeBtn} />
        <SaveButton label="Save" type="button" className={cls.saveBtn} />
      </Toolbar>
    ) : null;
  };

  const ViewActions = () => {
    return rbacEdit ? (
      <Toolbar className={cls.toolbar}>
        <Link to={`/manuals/${rbacEdit && rbac.isSuperadmin() ? record.id : ''}`}>
          <Button variant="outlined" color="secondary" className={cls.viewBtn}>Return</Button>
        </Link>
      </Toolbar>
    ) : null;
  };

  return (
    <Edit {...props} title={<Title />} {...editProps} className={cls.page}>
      <InitialPage
        title="Manual"
        backTo="/manuals"
        backText="Back to other manuals"
      >
        {isViewPage ? (
          <SimpleForm toolbar={<ViewActions />} onKeyPress={noEnter}>
            <ManualsView record={record} />
          </SimpleForm>
        ) : (
          <SimpleForm validate={validate} toolbar={<EditActions />}>
            <ManualsForm />
          </SimpleForm>
        )}
      </InitialPage>
    </Edit>
  );
};
