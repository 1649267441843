import { makeStyles } from '@mui/styles';
import { Button, Chip } from '@mui/material';
import { Link } from 'react-admin';

import { Chat } from '@types';
import { LightTooltip } from '@components';
import { useSettingsContext } from '@providers';

export const ChatTeams = ({ chat, isPopup }: {chat: Chat, isPopup?: boolean}) => {
  const { admin } = useSettingsContext();
  const linkTo = admin?.isRisk && chat.riskId
    ? `/credit-risk/${chat.riskId}`
    : `/credit/${chat.creditId}`;
  const creditCode = chat.title.split(' ').pop();
  const cls = useStyles();
  const invitedTeam = chat.managers?.invited || [];

  return (
    <div className={cls.box}>
      {isPopup && (
        <Link to={linkTo}>
          <Button variant="text" className={cls.link}>
            Credit {creditCode}
          </Button>
        </Link>
      )}

      {invitedTeam.length > 0 && (
        <LightTooltip title={(
          <>
            {invitedTeam.map(name => (
              <div key={name}>{name}</div>
            ))}
          </>
        )}>
          <Chip className={cls.chip} label="INVITED" variant="outlined" />
        </LightTooltip>
      )}

      <LightTooltip title={(
        <>
          {(chat.managers?.sales || []).map(name => (
            <div key={name}>{name}</div>
          ))}
        </>
      )}>
        <Chip className={cls.chip} label="SALES TEAM" variant="outlined" />
      </LightTooltip>

      <LightTooltip title={(
        <>
          {(chat.managers?.risk || []).map(name => (
            <div key={name}>{name}</div>
          ))}
        </>
      )}>
        <Chip className={cls.chip} label="RISK TEAM" variant="outlined" />
      </LightTooltip>
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    display: 'flex',
    position: 'absolute',
    right: 0,
    top: -36,
  },
  chip: {
    marginLeft: 10,
  },
  link: {
    marginTop: -5,
    marginRight: 10,
    textTransform: 'none',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});
