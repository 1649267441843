import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Progress } from 'antd';
import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';

import { AdminLoginCounter, reportsHttp } from '@network';
import { BlueOutlineButton, InitialCard } from '@components';

interface Props {
  type: string;
  range: string;
  setLoader: (num: number, value: boolean) => void;
}

export const AdminLoginCounterReport = ({ type, range, setLoader }: Props) => {
  const [numbers, setNumbers] = useState<AdminLoginCounter>();
  const cls = useStyles();

  const loadNumbers = async () => {
    setLoader(10, true);
    try {
      const nextNumbers = await reportsHttp.adminLoginCounter({ type, range });
      setNumbers(nextNumbers);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(10, false);
    }
  };

  useEffect(() => void loadNumbers(), [type]);

  if (!numbers) {
    return null;
  }

  const counter = numbers?.loginsByPeriod || 0;
  let strokeColor = '#8FD14F';
  if (type === 'week') {
    strokeColor = counter >= 64 ? '#8FD14F' : (counter >= 32 ? 'yellow' : 'red');
  }
  if (type === 'month') {
    strokeColor = counter >= 248 ? '#8FD14F' : (counter >= 125 ? 'yellow' : 'red');
  }

  const downloadFile = () => {
    const data = numbers.logins.map(login => {
      const periodName = type === 'week' ? 'Week' : 'Month';
      return { [periodName]: login.period, 'Successful logins': login.loginTries };
    });
    jsonExport(data, {}, (err, csv) => {
      downloadCSV(csv, `Admin Login Counter by ${type}`);
    });
  };

  return (
    <InitialCard center="Admin Login Counter" extraPadding fullHeight
      boxClass={cls.box} contentClass={cls.content}
      right={
        <BlueOutlineButton onClick={downloadFile} className={cls.downloadBtn}>
          <svg xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="20"
            viewBox="0 0 16 20"
            fill="none">
            <g clipPath="url(#clip0_351_4700)">
              <path d="M12.1043 3.25799H15.1268C15.0889 3.18466 15.0384 3.11841 14.9784 3.06216L11.8814 0.191325C11.7976 0.112992 11.6964 0.0559082 11.5859 0.0246582L11.6393 2.79799C11.6439 3.05216 11.8501 3.25633 12.1043 3.25799Z"
                fill="#042E6B" />
              <path d="M12.1042 3.9475C11.4758 3.94375 10.9646 3.44083 10.95 2.81292L10.8958 0H1.55125C0.693334 0.0025 -0.000832584 0.699167 7.49515e-07 1.55708V18.4429C-0.000832584 19.3008 0.693334 19.9975 1.55125 20H13.6571C14.515 19.9975 15.2092 19.3008 15.2083 18.4429V3.9475H12.1042Z"
                fill="#042E6B" />
              <path d="M10.3852 12.1962L8.55982 14.0162C8.35399 14.2212 8.08107 14.3441 7.79107 14.3624C7.76565 14.3658 7.74024 14.3674 7.71482 14.3679C7.69274 14.3691 7.67065 14.3691 7.64857 14.3679C7.35815 14.3549 7.08274 14.2366 6.87315 14.0354L5.01732 12.3762C4.7569 12.1445 4.73357 11.7458 4.96524 11.4854C5.1969 11.2249 5.59565 11.2016 5.85607 11.4333L7.09065 12.5312V7.29368C7.07857 6.94535 7.35107 6.65285 7.69982 6.64077C8.04815 6.62868 8.34065 6.90118 8.35274 7.24993C8.35315 7.26452 8.35315 7.2791 8.35274 7.29368V12.4441L9.49649 11.3004C9.74399 11.0549 10.1436 11.0562 10.389 11.3037C10.6344 11.5512 10.6327 11.9508 10.3857 12.1962H10.3852Z"
                fill="#E1E5ED" />
            </g>
            <defs>
              <clipPath id="clip0_351_4700">
                <rect width="15.2083" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </BlueOutlineButton>
      }>
      <Progress
        type="circle"
        width={190}
        percent={100}
        style={{ marginTop: 45 }}
        strokeColor={strokeColor}
        trailColor={'black'}
        format={() => <span className={cls.counter}>{counter}</span>}
      />
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {},
  btn: {
    padding: 0,
    textTransform: 'none',
    display: 'block',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  counter: {
    marginTop: 5,
    fontSize: 40,
    fontWeight: 600,
    color: '#000',
  },
  downloadBtn: {
    display: 'block',
    position: 'absolute',
    top: -10,
    right: -20,
    padding: 5,
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#FFF',
    width: 37,
    height: 37,
    minWidth: 'unset',
  },
});
