import { clsx } from 'clsx';
import { Credit, CreditStatus } from '@types';
import { Link, useRedirect } from 'react-admin';
import IconButton from '@mui/material/IconButton';

import { centsFormatter } from '@utils';
import { InitialCard } from '@components';
import { CreditStatusField } from '@pages/credit/CreditStatusField';
import { CheckCircleFilled, RightCircleFilled } from '@ant-design/icons';
import cls from './credit-card.module.css';

export const CreditCard = ({ credit }: { credit: Credit }) => {
  const redirect = useRedirect();
  // !showCreditAmount = Draft, Applying, Completed File, Analysis, Denied, Approved
  // showCreditAmount = Signed, Delay, Restructure, Repaid
  const showCreditAmount = [CreditStatus.SIGNED, CreditStatus.DELAY,
    CreditStatus.RESTRUCTURE, CreditStatus.REPAID].includes(credit.status);
  const isSendToAnalysisEnabled = credit.status === CreditStatus.COMPLETED_FILE
    || credit.status === CreditStatus.BANK_REVISION;
  const isSendToAnalysisColored = credit.status === CreditStatus.COMPLETED_FILE;

  const user = credit.user;

  const sendToAnalysis = (e: any) => {
    e.stopPropagation();
    redirect(`/credit/${credit.id}/?report`);
  };

  const onAvatarClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    redirect(`/user/${user.id}`);
  };

  const getCrops = (): string => {
    if (credit.creditCrops.length) {
      const cropsArray = credit.creditCrops.map(c => c.financedCrop.nameMx);
      return cropsArray.join(', ');
    }

    return '-';
  };


  return (
    <InitialCard
      left="Credit information"
      right={<RightCircleFilled className={cls.headerIcon} />}
      link={`/credit/${credit.id}`}
      wrapperClass={cls.cardWrapper}
    >
      <div className={cls.box}>
        <div className={cls.info}>
          <div className={cls.mainInfo}>
            <div className={cls.icon}>
              <IconButton className={cls.contact} onClick={onAvatarClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path d="M16 0C7.16571 0 0 7.16571 0 16C0 24.8343 7.16571 32 16 32C24.8343 32 32 24.8343 32 16C32 7.16571 24.8343 0 16 0ZM16 8C19.6 8 22.5257 10.9257 22.5257 14.5257C22.5257 18.1257 19.6 21.0514 16 21.0514C12.4 21.0514 9.47429 18.1257 9.47429 14.5257C9.47429 10.9257 12.4 8 16 8ZM16 29.9429C12.7543 29.9429 9.76 28.8229 7.38286 26.9486C8.22857 25.7371 9.62286 25.0057 11.1086 25.0057H20.8914C22.3771 25.0057 23.7714 25.7371 24.6171 26.9486C22.24 28.8229 19.2457 29.9429 16 29.9429Z"
                    fill="#042E6B" />
                </svg>
              </IconButton>
            </div>
            <div className={cls.content}>
              <div className={cls.creditCode}>{credit.code}</div>
              <Link to={`/user/${user.id}?initial`}
                className={cls.userLink}
                onClick={e => e.stopPropagation()}>
                <div className={cls.userName}>
                  {credit.accreditedName || '(No company)'}
                </div>
              </Link>
              <div className={cls.userState}>
                State: {user.state?.name || '-'}
              </div>
            </div>
          </div>

          <div className={cls.balanceText}>{showCreditAmount ? 'Credit' : 'Requested'} Amount</div>
          <div className={cls.balanceValue}>
            <span className={cls.balanceValueText}>
              {centsFormatter.format(showCreditAmount
                ? credit.bankApproval || 0
                : credit.requestAmount)}
            </span>
          </div>

          <div className={cls.financedCrop}>
            <CheckCircleFilled className={cls.cropsIcon}/>
            Financed crops: {getCrops()}
          </div>

          <div className={cls.status}>
            <CreditStatusField
              source="status"
              creditStatus={credit.status}
              className={cls.statusChip}
            />
            <button
              className={clsx(
                cls.sendToCredit,
                isSendToAnalysisColored && cls.sendToCreditColored,
              )}
              onClick={sendToAnalysis}
              disabled={!isSendToAnalysisEnabled}
            >
              <span>Send to Analysis</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <g clipPath="url(#clip0_964_4967)">
                  <path fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.399395 4.0515C0.14826 4.0515 -0.0287449 3.8505 0.0121029 3.606C0.0121029 3.606 -0.0166418 3.75 0.0121029 3.603C0.432677 1.548 2.26475 -2.94407e-07 4.46142 -1.98388e-07C6.96823 -8.88115e-08 9 2.0145 9 4.5C9 6.9855 6.96823 9 4.46142 9C2.26778 9 0.437216 7.4565 0.0151289 5.4045C-0.0151284 5.256 0.0136154 5.394 0.0136154 5.394C-0.0242064 5.148 0.149772 4.95 0.400907 4.95L4.69138 4.95C4.8593 4.95 4.89713 5.0445 4.77912 5.163L3.9531 5.9775C3.52496 6.4005 4.16793 7.035 4.59455 6.612L6.44629 4.8075C6.62481 4.6335 6.62481 4.353 6.44478 4.179L4.59607 2.391C4.16793 1.968 3.52648 2.6025 3.95461 3.0255L4.78064 3.84C4.90015 3.957 4.8593 4.053 4.69289 4.053L0.40242 4.053L0.399395 4.0515Z"
                    fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_964_4967">
                    <rect width="9" height="9" fill="white" transform="translate(9) rotate(90)" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </InitialCard>
  );
};
