import { Http } from './http';
import { Blog } from '@types';

class BlogHttp extends Http {
  async create(params: CreateBlogParams) {
    const res = await this.post<Blog>('/blog', params);

    return res.data;
  }

  async update(params: UpdateBlogParams) {
    const res = await this.put<Blog>(`/blog/${params.id}`, params);

    return res.data;
  }

  async remove(id: number) {
    const res = await this.delete<Blog>(`/blog/${id}`);

    return res.data;
  }
}

export const blogHttp = new BlogHttp();

interface CreateBlogParams {
  title: string;
  announce: string;
  description: string;
  keywords: string;
  isEnabled: boolean;
  isLastNews: boolean;
  isMain: boolean;
  isRecommendation: boolean;
  imageAlt: string;
}

interface UpdateBlogParams extends CreateBlogParams{
  id: number;
}
