import { Http } from './http';
import { MonthData } from '@pages/dashboard/AllocationRevenueGoals/AllocationRevenueGoals';

class CreditGoalHttp extends Http {
  prefix = 'credit-goal';

  async findAll() {
    const res = await this.get<Record<string, MonthData[]>>('/credit-goals-list');

    return res.data;
  }

  async findOne(year: string) {
    const res = await this.get<MonthData[]>(`/${year}`);

    return res.data;
  }

  async create(dto: CreateGoalParams) {
    const res = await this.post<MonthData[]>('', dto);

    return res.data;
  }
}

export const creditGoalHttp = new CreditGoalHttp();

interface CreateGoalParams {
  year: string;
  data: MonthData[];
}
