import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { Edit, EditProps, SimpleForm } from 'react-admin';
import { useEditProps, useQuery, useRbacView, useRecord } from '@hooks';

import { rbac } from '@utils';
import { Score } from '@pages/risk/score';
import { Forbidden, InitialPage } from '@components';
import { Terms, TermsEdit } from '@pages/risk/terms';
import { riskAdminHttp } from '@network/risk-admin-http';
import { Variables, VariablesEdit } from '@pages/risk/variables';
import { RiskAdminDashboard, RiskAdminQuestion, RiskProps } from '@types';
import cls from './RiskEdit.module.css';

export const RiskEdit = (props: EditProps) => {
  const risk: RiskProps = useRecord<RiskProps>(props);
  const query = useQuery();
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const [terms, setTerms] = useState<RiskAdminQuestion[]>([]);
  const [score, setScore] = useState<RiskAdminQuestion[]>([]);
  const [other, setOther] = useState<RiskAdminQuestion[]>([]);
  const [dashboard, setDashboard] = useState<RiskAdminDashboard | null>(null);

  const isSuperAdmin = rbac.isSuperadmin();
  const riskAdminReadonly = rbac.hasRiskAdminReadonlyPerm();
  const readonly = riskAdminReadonly && !isSuperAdmin;

  const showTerms = query.get('terms') === '';
  const showVariables = query.get('variables') === '';

  const getTerms = async () => {
    const termsRes: RiskAdminQuestion[] = await riskAdminHttp.getRiskTerms();
    setTerms(termsRes);
  };

  const getDashboard = async () => {
    const dashboardRes: RiskAdminDashboard = await riskAdminHttp.getRiskDashboard();
    setDashboard(dashboardRes);
  };

  const getOtherList = async () => {
    const otherRes: RiskAdminQuestion[] = await riskAdminHttp.getRiskOther();
    await getDashboard();
    setOther(otherRes);
  };

  const getScore = async () => {
    await getTerms();
    await getOtherList();
    await getDashboard();
    const scoreRes: RiskAdminQuestion[] = await riskAdminHttp.getRiskScore();
    setScore(scoreRes);
  };

  useEffect(() => {
    void getScore();
  }, []);

  if (!rbacView && !riskAdminReadonly) {
    return <Forbidden />;
  }

  if (showTerms) {
    return (
      <InitialPage
        title="Credit Terms"
        className={cls.wrap}
        backText="Back to all risk admin"
        backTo="/risk/1"
      >
        <Edit {...props} title={<span>Credit Terms Edit</span>} {...editProps}>
          <SimpleForm toolbar={false}>
            {risk && (
              <Row gutter={20} className={cls.row}>
                <Col sm={24}>
                  <TermsEdit items={terms} onChange={getTerms} readonly={readonly} />
                </Col>
              </Row>
            )}
          </SimpleForm>
        </Edit>
      </InitialPage>
    );
  }

  if (showVariables) {
    return (
      <InitialPage
        title="Score Variables"
        className={cls.wrap}
        backText="Back to all risk admin"
        backTo="/risk/1"
      >
        <Edit {...props} title={<span>Score Variables Edit</span>} {...editProps}>
          <SimpleForm toolbar={false}>
            {risk && (
              <Row gutter={20} className={cls.row}>
                <Col sm={24}>
                  <VariablesEdit
                    items={score}
                    other={other}
                    onOtherChange={getOtherList}
                    getScore={getScore}
                    readonly={readonly}
                  />
                </Col>
              </Row>
            )}
          </SimpleForm>
        </Edit>
      </InitialPage>
    );
  }

  return (
    <InitialPage title="Risk Admin" className={cls.wrap}>
      <Edit {...props} title="Risk Admin" {...editProps} redirect="/risk/1">
        <SimpleForm toolbar={false}>
          {risk && (
            <Row gutter={[20, 20]} className={cls.row}>
              <Col xs={24} xl={16} className={cls.col}>
                <Terms items={terms} readonly={readonly} />
              </Col>
              <Col xs={24} xl={8} className={cls.col}>
                <Score value={risk.successPercent} readonly={readonly} />
              </Col>
              <Col xs={24}>
                {dashboard ? <Variables data={dashboard} readonly={readonly} /> : null}
              </Col>
            </Row>
          )}
        </SimpleForm>
      </Edit>
    </InitialPage>
  );
};
