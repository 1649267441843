import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { http } from '@network';

interface Props {
  source: string;
  label?: string;
  hideLabel?: boolean;
  reference?: string;
  optionKey?: string;
  optionLabel?: string;
  className?: string;
}

export const ManyInput = ({
  source, label, hideLabel, reference, optionKey, optionLabel, className,
}: Props) => {
  const {
    fieldState: { isTouched, error },
    field: { value, onChange },
  } = useController({ name: source });
  const ids = value ? (value[0]?.id ? value.map((v: any) => v.id) : value) : [];
  const [options, setOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    (async () => {
      const response = await http.get<any>(reference || source, {
        params: { _start: 0, _end: 9999999 },
      });
      const nextOptions: SelectOption[] = [];
      if (response.data && Array.isArray(response.data)) {
        (response.data ?? []).forEach((model: any) => {
          nextOptions.push({
            key: model[optionKey ?? 'id'],
            label: model[optionLabel ?? 'nameMx'] || model['name'] || model['title'],
          });
        });
      }
      setOptions(nextOptions);
    })();
  }, []);

  if (label === '') {
    hideLabel = true;
  }
  const inputLabel = label || source.slice(0, 1).toUpperCase().concat(source.slice(1));

  return (
    <FormControl fullWidth>
      <TextField
        label={hideLabel ? undefined : inputLabel}
        value={ids}
        onChange={onChange}
        error={!!(isTouched && error)}
        select
        className={className}
        SelectProps={{
          multiple: true,
          value: ids,
          onChange,
        }}
        fullWidth
        variant="standard"
        size="small"
      >
        {options && options.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

interface SelectOption {
  key: string,
  label: string,
}
