import { Create, CreateProps, SimpleForm } from 'react-admin';

import { FaqForm, validate } from './FaqForm';
import { Forbidden, InitialPage } from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';
import cls from './Faq.module.css';

export const SupportFaqCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps} title="Creation of FAQ" className={cls.page}>
      <InitialPage
        title="Creation of FAQ"
        backTo="/support-faq"
        backText="Back to all FAQ´s"
      >
        <SimpleForm validate={validate}>
          <FaqForm />
        </SimpleForm>
      </InitialPage>
    </Create>
  );
};
