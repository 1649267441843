import { Http } from './http';
import { Lead, LeadFormProps } from '@types';

class LeadHttp extends Http {
  async create(params: LeadFormProps) {
    const res = await this.post<Lead>('/lead', params);

    return res.data;
  }

  async update(params: LeadFormProps, id: number) {
    const res = await this.put<Lead>(`/lead/${id}`, params);

    return res.data;
  }

  async remove(id: number) {
    const res = await this.delete<Lead>(`/lead/${id}`);

    return res.data;
  }

  async options(): Promise<LeadOptionsProps> {
    const res = await this.get<LeadOptionsProps>('/lead/options');

    return res.data;
  }

  async addBulkActivation(ids: number[], activationId: number) {
    const res = await this.post<void>('/lead/add-bulk-activation', { ids, activationId });

    return res.data;
  }

  async removeActivation(id: number) {
    const res = await this.patch<string>(`/lead/${id}/remove-activation`);

    return res.data;
  }

  async getAll(query: string, count = 0) {
    const res = await this.get<Lead[]>(`/lead/?_start=0&_end=${count}&q=${query}&_sort=name`);

    return res.data;
  }
}

export interface LeadOptionsProps {
  responsibleAdmins: { id: number; name: string }[];
  stages: string[]
}

export const leadHttp = new LeadHttp();
