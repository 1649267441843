import React, { useEffect } from 'react';
import { Col, Form, InputNumber, message, Row } from 'antd';
import { makeStyles } from '@mui/styles';

import { Credit } from '@types';
import { moneyFormatter } from '@utils';
import { useRequest } from '@hooks/use-request';
import { BlueOutlineButton } from '@components';
import { creditHttp } from '@network';

interface Props {
  credit: Credit;
  disabled?: boolean;
}

export enum CreditConstants {
  MIN_REQUEST_AMOUNT = 300000,
  MAX_REQUEST_AMOUNT = 1000000000000,
}

export const RequestedAmount = ({ credit, disabled }: Props) => {
  const [form] = Form.useForm<{amount: string}>();
  const amount = Form.useWatch('amount', form);
  const cls = useStyles();

  useEffect(() => {
    if (amount && +amount > CreditConstants.MAX_REQUEST_AMOUNT) {
      form.setFieldsValue({ amount: `${CreditConstants.MAX_REQUEST_AMOUNT}` });
    }
  }, [amount]);

  useEffect(() => {
    form.setFieldsValue({ amount: `${credit?.requestAmount || 0}` });
  }, [credit]);

  const { submit, loading } = useRequest(async () => {
    const fields = await form.validateFields();
    const newAmount = +(fields.amount || 0);

    if (newAmount < CreditConstants.MIN_REQUEST_AMOUNT) {
      message.error(`Ingrese un número no menor a ${moneyFormatter.format(CreditConstants.MIN_REQUEST_AMOUNT)}`);
      return;
    }
    if (newAmount > CreditConstants.MAX_REQUEST_AMOUNT) {
      message.error(`Ingrese un número no mas a ${moneyFormatter.format(CreditConstants.MAX_REQUEST_AMOUNT)}`);
    }

    await creditHttp.saveRequestAmount(credit.id, newAmount);
    message.success('Saved Amount requested');
  });

  const checkValue = (): boolean => {
    return +credit.requestAmount === +form.getFieldValue('amount');
  };

  if (!credit) {
    return null;
  }

  return (
    <div className={cls.box}>
      <Row gutter={20} className={cls.row}>
        <Col xs={24} md={11} className={cls.label}>
          Amount requested
        </Col>
        <Col xs={24} md={8}>
          <Form form={form}>
            <Form.Item
              name="amount"
              initialValue={`${credit.requestAmount || 0}`}
              label={false}
            >
              <InputNumber
                controls={false}
                formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value: any) => +value.replace(/\$\s?|(,*)/g, '')}
                onFocus={evt => {
                  setTimeout(() => {
                    if (evt.target.value?.length <= 3) {
                      evt.target.selectionStart = evt.target.selectionEnd = 10000;
                    }
                  }, 0);
                }}
                className={cls.input}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} md={5} className={cls.controls}>
          <BlueOutlineButton
            disabled={loading || checkValue()}
            className={cls.btn}
            onClick={submit}
            style={{ width: '100%' }}
          >Save</BlueOutlineButton>
        </Col>
      </Row>
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    width: 450,
    height: 60,
    marginBottom: -15,
    padding: '12px 23px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#FFF',
    fontFamily: 'Outfit, sans-serif',
    color: '#000',
  },
  row: {
    height: 40,
  },
  label: {
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    width: '100%',
    borderRadius: 0,
    border: 'none',
    borderBottom: '1px solid rgba(4, 46, 107, 0.50)',
  },
  controls: {},
  btn: {
    textTransform: 'none',
  },
});
