import { useEffect, useState } from 'react';

import { Order, Credit } from '@types';
import { creditHttp, ordersHttp } from '@network';
import { OrderWidget } from '../OrderWidget';
import cls from './orders.module.css';

interface Props {
  userId: number;
  editable: boolean;
}

export const Orders = ({ userId, editable }: Props) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [credits, setCredits] = useState<Credit[]>([]);

  useEffect(() => {
    (async () => {
      const nextOrders = await ordersHttp.getOrdersByUser(userId);
      setOrders(nextOrders);
    })();
    (async () => {
      const nextCredits = await creditHttp.getCreditsByUser(userId);
      setCredits(nextCredits);
    })();
  }, [userId]);

  return (
    <div>
      {orders.length === 0 ? (
        <div className={cls.empty}>No orders</div>
      ) : null}

      {orders.map(order => (
        <OrderWidget order={order} credits={credits} key={order.id} editable={editable} />
      ))}
    </div>
  );
};
