import { EditProps, Edit, SimpleForm } from 'react-admin';

import { useEditProps, useRbacEdit, useRecord } from '@hooks';
import { Forbidden, InitialPage } from '@components';
import { AdminForm, validate } from '@pages/admin/AdminForm';
import React from 'react';
import { Admin } from '@types';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Admin: {record.name}</span>) : null;

export const AdminEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacEdit = useRbacEdit();
  const record = useRecord<Admin>(props);

  if (!rbacEdit) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <InitialPage
        title="Admin"
        backTo="/admin"
        backText="Back to other admins"
      >
        <SimpleForm validate={validate}>
          <AdminForm record={record} />
        </SimpleForm>
      </InitialPage>
    </Edit>
  );
};
