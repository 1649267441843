import { useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import { Loader } from '@components';
import { CreditFarm } from '@types';

interface Props {
  farm: CreditFarm;
  onMapClick: (lat: number, lng: number) => void;
}

const defaultPosition = { lat: 19.3315987, lng: -99.2069962 };
let marker: google.maps.Marker;

export const FarmMap = ({ farm, onMapClick }: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'credit-farm-script',
    googleMapsApiKey: 'AIzaSyCFZ9FdcDkurEUjnzZtsaHk9CXDgaaNVAo',
  });
  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    if (map) {
      if (farm.id > 0) {
        setMapMarker(map, +farm.latitude, +farm.longitude);
      } else {
        marker && marker.setMap(null);
      }
    }
  }, [farm.id, map]);

  const position = farm.id > 0
    ? { lat: +farm.latitude, lng: +farm.longitude }
    : defaultPosition;

  const setMapMarker = (nextMap: google.maps.Map, lat: number, lng: number) => {
    if (marker) {
      marker.setMap(null);
    }
    setTimeout(() => {
      marker = new google.maps.Marker({
        position: { lat, lng },
        map: nextMap,
        icon: {
          path: 'M17.3025 37.5628C14.7117 32.2252 11.9217 28.883 9.58007 26.1393C6.39146 22.3481 4 19.5546 4 14.0175C4 10.1265 5.59431 6.6346 8.08541 4.09051C10.6263 1.54641 14.1139 0 18 0C21.8861 0 25.3737 1.54641 27.9146 4.09051C30.4555 6.6346 32 10.1265 32 14.0175C32 19.5047 29.6085 22.3481 26.4199 26.1393C24.0783 28.883 21.2883 32.2252 18.6975 37.5628C18.4982 37.9619 18 38.1115 17.6014 37.912C17.452 37.8122 17.3025 37.7125 17.2527 37.5628H17.3025ZM20.8897 11.1741C20.1423 10.4258 19.1459 9.97684 18.0498 9.97684C16.9537 9.97684 15.9075 10.4258 15.21 11.1741C14.5125 11.9223 14.0142 12.92 14.0142 14.0175C14.0142 15.1149 14.4626 16.1625 15.21 16.8609C15.9573 17.5592 16.9537 18.0581 18.0498 18.0581C19.1459 18.0581 20.1922 17.6091 20.8897 16.8609C21.5872 16.1126 22.0854 15.1149 22.0854 14.0175C22.0854 12.92 21.637 11.8724 20.8897 11.1741Z',
          fillOpacity: 1,
          strokeWeight: 1,
          scale: 1,
          fillColor: '#FD8A32',
          strokeColor: 'white',
          anchor: new google.maps.Point(8, 30),
        },
      });
      const markerInfoWindow = new google.maps.InfoWindow({
        content: farm.address
          ? `<h3>${lat}, ${lng}</h3><hr/><br/><p>${farm.address}</p>`
          : `<h3>${lat}, ${lng}</h3>`,
      });
      marker.addListener('click', () => {
        markerInfoWindow.open({
          anchor: marker,
          map,
          shouldFocus: false,
        });
      });
    }, 100);
  };

  const onLoad = (nextMap: google.maps.Map) => {
    setMap(nextMap);
    nextMap.setCenter(new google.maps.LatLng(position.lat, position.lng));
    nextMap.setOptions({ draggableCursor: 'se-resize' });
    nextMap.addListener('click', (e: any) => {
      if (farm.id > 0) {
        return setMapMarker(nextMap, +farm.latitude, +farm.longitude);
      }
      const lat = +(+e.latLng.lat()).toFixed(5);
      const lng = +(+e.latLng.lng()).toFixed(5);
      setMapMarker(nextMap, lat, lng);
      onMapClick(lat, lng);
    });
  };

  const onUnmount = () => setMap(null);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <GoogleMap
      key={farm.id}
      mapContainerStyle={{ width: '100%', height: '220px', userSelect: 'none', border: '1px solid #AAA', borderRadius: '0 8px 8px 8px' }}
      zoom={8}
      center={position}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        minZoom: 6,
        controlSize: 20,
      }}
    ></GoogleMap>
  );
};
