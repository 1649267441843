import React, { ChangeEvent, useState } from 'react';
import { Button, Form, FormListOperation, Input } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';

import { RemoveIcon } from '@pages/risk/variables';
import cls from './VariablesAnswer.module.css';

interface VariablesAnswerProps {
  fields: FormListFieldData[];
  operation: FormListOperation;
  onChange: (answerIndex: number, value: string) => void;
  readonly?: boolean;
}

export const VariablesAnswer = ({
  fields, operation, onChange, readonly,
}: VariablesAnswerProps) => {
  const [percentValue, setPercentValue] = useState('0');

  const onAnswerPercentChange = (
    e: ChangeEvent<HTMLInputElement>, answerIndex: number,
  ) => {
    const value = e.target.value.replace(/\D/g, '');
    setPercentValue((+value > 100) ? '100' : value);
    onChange(answerIndex, value);
  };

  const handleKeyPress = (e: any) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className={cls._}>
      {fields.map((field) => {
        return (
          <div key={field.key} className={cls.option}>
            <Form.Item noStyle name={[field.name, 'title']} className={cls.title}>
              <Input
                placeholder="Variable title"
                maxLength={100}
                onBeforeInputCapture={readonly ? e => e.preventDefault() : undefined}
                disabled={readonly}
              />
            </Form.Item>
            <Form.Item name={[field.name, 'progressPercent']} className={cls.percent}>
              <Input
                placeholder="0%"
                required
                onChange={(e) => onAnswerPercentChange(e, field.name + 1)}
                value={+percentValue > 100 ? '100' : percentValue}
                maxLength={3}
                onKeyPress={handleKeyPress}
                onBeforeInputCapture={readonly ? e => e.preventDefault() : undefined}
                disabled={readonly}
              />
            </Form.Item>

            {!readonly ? (
              <div className={cls.remove} onClick={() => void operation.remove(field.name)}>
                <RemoveIcon />
              </div>
            ) : null}
          </div>
        );
      })}

      <div className={cls.action}>
        <Button type="default" disabled={readonly} onClick={() => operation.add()}
          block className={cls.add}>
          Option {fields.length + 1}
          <RemoveIcon />
        </Button>
      </div>
    </div>
  );
};
