import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { SaveFilled } from '@ant-design/icons';
import { Create, CreateProps, SaveButton, SimpleForm, TopToolbar } from 'react-admin';

import {
  Container,
  ImageInput,
  TextInput,
  EditorInput,
  DateTimeInput,
  Forbidden,
  LinkInput,
  SwitchInput,
  TimerInput,
  PageHeader,
  FormRow,
} from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';
import cls from './WebinarCreate.module.css';

export const WebinarCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();
  const editorToolbar = 'undo redo formatselect '
    + 'bold italic backcolor bullist numlist outdent indent table '
    + 'removeformat code help '
    + 'alignleft aligncenter alignright alignjustify ';
  const mainColGrid = {
    sm: { title: 5, children: 19 },
    lg: { title: 6, children: 18 },
    xl: { title: 7, children: 17 },
    xxl: { title: 5, children: 19 },
  };

  if (!rbacCreate) {
    return <Forbidden />;
  }

  const Actions = () => {
    return (
      <TopToolbar className={cls.actions}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon} />}
          label="Save"
          type="button"
          className={clsx(cls.action, cls.actionSave)}
          variant="outlined"
        />
      </TopToolbar>
    );
  };

  return (
    <Create {...props} {...createProps} className={clsx(cls.page, 'details-page-wrapper')}>
      <PageHeader
        title="Webinar create"
        linkText="Back to all webinars"
        linkUrl="/webinar-list"
      />

      <SimpleForm toolbar={<Actions />} className={cls.form}>
        <Container max>
          <Row gutter={24} className={cls.wrapper}>
            <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColFirst)}>
              <div className={clsx(cls.block, cls.blockMain)}>
                <div className={cls.blockHeader}>User</div>
                <div className={cls.blockMiddle}>
                  <div className={cls.blockMiddleIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                      width="55"
                      height="55"
                      viewBox="0 0 55 55"
                      fill="none">
                      <path d="M27.5 0C12.3161 0 0 12.3161 0 27.5C0 42.6839 12.3161 55 27.5 55C42.6839 55 55 42.6839 55 27.5C55 12.3161 42.6839 0 27.5 0ZM27.5 13.75C33.6875 13.75 38.7161 18.7786 38.7161 24.9661C38.7161 31.1536 33.6875 36.1821 27.5 36.1821C21.3125 36.1821 16.2839 31.1536 16.2839 24.9661C16.2839 18.7786 21.3125 13.75 27.5 13.75ZM27.5 51.4643C21.9214 51.4643 16.775 49.5393 12.6893 46.3179C14.1429 44.2357 16.5393 42.9786 19.0929 42.9786H35.9071C38.4607 42.9786 40.8571 44.2357 42.3107 46.3179C38.225 49.5393 33.0786 51.4643 27.5 51.4643Z"
                        fill="#042E6B" />
                    </svg>
                  </div>
                  <div className={cls.blockMiddleInfo}>
                    <FormRow
                      title="Title:"
                      width={mainColGrid}
                      rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                      colTitleClassName={cls.formLabelCol}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                    >
                      <TextInput name="titleMx" source="titleMx" required autoFocus label={false} />
                    </FormRow>
                    <FormRow
                      title="Speaker:"
                      width={mainColGrid}
                      rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                      colTitleClassName={cls.formLabelCol}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                    >
                      <TextInput name="speaker" source="speaker" label={false} />
                    </FormRow>
                    <FormRow
                      title="Starts at:"
                      width={mainColGrid}
                      rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                      colTitleClassName={cls.formLabelCol}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                    >
                      <DateTimeInput name="startsAt" source="startsAt" label={false} />
                    </FormRow>
                    <FormRow
                      title="Duration:"
                      width={mainColGrid}
                      rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                      colTitleClassName={cls.formLabelCol}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                    >
                      <TimerInput source="durationMinutes" label={false} />
                    </FormRow>
                    <FormRow
                      title="Image:"
                      width={mainColGrid}
                      rowClassName={clsx(cls.formRow, cls.mainFormRow, cls.imgRow)}
                      colTitleClassName={cls.formLabelCol}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue, cls.imgCol)}
                    >
                      <ImageInput name="image" source="image" label={false} />
                    </FormRow>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColLast)}>
              <div className={clsx(cls.block, cls.blockLinks)}>
                <div className={cls.blockHeader}>Links</div>
                <FormRow
                  title="Zoom link:"
                  fullWidth
                  rowClassName={clsx(cls.formRow, cls.linkFormRow)}
                  colTitleClassName={clsx(cls.formLabelCol, cls.linkLabel)}
                  colChildrenClassName={clsx(cls.formValueCol, cls.formLinkValue)}
                >
                  <LinkInput name="url" source="url" label={false} placeholder="https://any.link" />
                </FormRow>
                <div className={cls.switcher}>
                  <SwitchInput
                    name="isEnabled"
                    source="isEnabled"
                    label="is enabled"
                    defaultValue={true}
                    className={cls.switcherField} />
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={24} className={cls.wrapper}>
            <Col xs={24} className={clsx(cls.wrapperCol, cls.wrapperColBottom)}>
              <div className={clsx(cls.block, cls.blockContent)}>
                <Row gutter={24} className={cls.inner}>
                  <Col xs={24} xl={12} className={clsx(cls.innerCol, cls.innerColFirst)}>
                    <FormRow
                      title="Announce:"
                      fullWidth
                      rowClassName={clsx(cls.formRow, cls.messageRow)}
                      colTitleClassName={clsx(cls.formLabelCol, cls.formTitleCol)}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formValueColTextarea)}
                    >
                      <EditorInput
                        source="announceMx"
                        label={false}
                        toolbar={editorToolbar}
                        height={250}
                        className={cls.editor}
                      />
                    </FormRow>
                  </Col>
                  <Col xs={24} xl={12} className={clsx(cls.innerCol, cls.innerColLast)}>
                    <FormRow
                      title="Description:"
                      fullWidth
                      rowClassName={clsx(cls.formRow, cls.messageRow)}
                      colTitleClassName={clsx(cls.formLabelCol, cls.formTitleCol)}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formValueColTextarea)}
                    >
                      <EditorInput
                        source="descriptionMx"
                        label={false}
                        toolbar={editorToolbar}
                        height={250}
                        className={cls.editor}
                      />
                    </FormRow>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </SimpleForm>
    </Create>
  );
};
