import React from 'react';
import { Box, Chip, Divider, Stack, Typography } from '@mui/material';

import cls from './sms-create-instructions.module.css';

export const SmsCreateInstructions = () => {
  return (
    <Box className={cls.box}>
      <Stack spacing={0.5}>
        <Typography variant="h5" component="h2" style={{ marginBottom: 20 }}>Instructions</Typography>
        <Typography component="div">
          1. Add new message into a&nbsp;&ldquo;Message text&rdquo; field.
        </Typography>
        <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        <Typography component="div">
          2. Type <Chip label="#name#" /> or <Chip label="{name}" /> to add a placeholder to the message. <br />
          Example message: <i>Hello {'{name}'}!</i> <br />
          User will receive: <i>Hello Anabella!</i>
        </Typography>
        <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        <Typography component="p">
          3. Select recipients from the users list. You can choose one or many using filters.
        </Typography>
        <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        <Typography component="p">
          4. (Optional) Fill the&nbsp;&ldquo;Phones list&rdquo; with the comma divider.
          This option is for test purposes. <br />
          For example: +79991234567, +529612321232, +79299004040,
        </Typography>
      </Stack>
    </Box>
  );
};
