import { DateTimeInputProps, DateTimeInput, useRecordContext } from 'react-admin';

export const DateTimeField = (props: DateTimeInputProps) => {
  const { source } = props;
  const record = useRecordContext();
  const data: any = record[source];

  return (
    <DateTimeInput
      type={data ? 'datetime-local' : 'text'}
      placeholder="dd.mm.yyyy --:--"
      className="date-time-input"
      {...props}
    />
  );
};
