import React, { useEffect, useLayoutEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5percent from '@amcharts/amcharts5/percent';
import * as am5 from '@amcharts/amcharts5';

import { FinancialRankingReportWithColors, reportsHttp } from '@network';
import { InitialCard } from '@components';
import { Col, Row } from 'antd';
import { moneyFormatter } from '@utils';

interface Props {
  range: string;
  setLoader: (num: number, value: boolean) => void;
}

const colorsPalette = [
  '#B44436',
  '#204BCE',
  '#1E2C5C',
  '#225236',
  '#FD8A32',
  '#3E8B5B',
  '#400E04',
];

const getRandomColor = () => '#' + Math.random().toString(16).slice(-6);

export const FinancialRankingReport = ({ range, setLoader }: Props) => {
  const [numbers, setNumbers] = useState<FinancialRankingReportWithColors[]>();
  const cls = useStyles();
  const chartId = 'financial-ranking-report';

  const loadNumbers = async () => {
    setLoader(21, true);
    try {
      const nextNumbers = await reportsHttp.financialRankingReport({ range });

      setNumbers(nextNumbers.map((item, index) => ({
        ...item,
        color: colorsPalette[index] ? colorsPalette[index] : getRandomColor(),
      })));
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(21, false);
    }
  };

  useEffect(() => void loadNumbers(), [range]);

  useLayoutEffect(() => {
    if (!numbers) {
      return;
    }
    const hasRecord = numbers.find(data => data.value > 0);

    const root = am5.Root.new(chartId);
    root.setThemes([am5themes_Animated.new(root)]);
    const chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      innerRadius: 120,
    }));

    const amColors = colorsPalette.map(color => am5.color(color));
    const series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: 'value',
      legendValueText: '',
      categoryField: 'label',
      radius: 80,
      colors: am5.ColorSet.new(root, {
        colors: amColors,
      }),
      alignLabels: false,
    }));
    series.ticks.template.set('forceHidden', true);
    series.labels.template.set('forceHidden', true);

    hasRecord && series.data.setAll(numbers);

    series.get('colors')?.set('colors', amColors);

    const total = numbers?.reduce((sum, item) => sum + item.value, 0);
    chart.seriesContainer.children.push(am5.Label.new(root, {
      textAlign: 'center',
      centerY: 28,
      centerX: am5.p50,
      text: `[fontSize:15px]Total financed[/]:\n[bold fontSize:22px]${moneyFormatter.format(total)}[/]`,
    }));

    series.slices.template.setAll({
      cornerRadius: 10,
    });

    series.appear(1000, 100);

    return () => void root.dispose();
  }, [chartId, numbers]);

  if (!numbers) {
    return null;
  }

  return (
    <InitialCard left="Financial Institution Ranking"
      leftTooltip="Banks with Credits in statuses: Signed, Delay, Restructure, Repaid"
      extraPadding boxClass={cls.box}>
      <Row>
        <Col sm={24} md={9}>
          <div className={cls.labels}>
            {numbers.map((row, index) => (
              <div key={row.label} className={cls.row}>
                <div className={cls.label}>
                  <div
                    className={cls.labelColor}
                    style={{ backgroundColor: row.color }}
                  />
                  <div className={cls.labelText}>{`${index + 1}.- ${row.label}`}</div>
                </div>
                <div className={cls.amount}>{moneyFormatter.format(row.value)}</div>
                <div className={cls.count}>{row.count}</div>
              </div>
            ))}
          </div>
        </Col>
        <Col sm={24} md={15}>
          <div className={cls.chart}>
            <div id={chartId} style={{ minHeight: 516 }} />
          </div>
        </Col>
      </Row>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    paddingBottom: '0 !important',
    height: '520px !important',
  },
  labels: {
    minWidth: 420,
    marginTop: 30,
    padding: 0,
    border: '1px solid #042E6B',
    borderRadius: 8,

    '@media (min-width: 1440px)': {
      minWidth: 520,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    height: 48,
    alignItems: 'center',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRight: '1px solid #042E6B',
    paddingLeft: 28,
    paddingRight: 28,
    minWidth: 180,
  },
  labelColor: {
    minWidth: 20,
    width: 20,
    height: 20,
    borderRadius: 5,
  },
  labelText: {
    marginLeft: 20,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 15,
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 160,
    width: 180,
    height: '100%',
    borderRight: '1px solid #042E6B',
    paddingLeft: 19,
    paddingRight: 19,
    fontSize: 15,

    '@media (min-width: 1440px)': {
      minWidth: 180,
    },
  },
  count: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minWidth: 60,
    width: 60,
    height: '100%',
    fontSize: 15,
  },
  chart: {
    marginTop: -40,
    marginRight: -30,
  },
});
