import { makeStyles } from '@mui/styles';

import { CreditRisk } from '@types';
import { moneyFormatter } from '@utils';

export const RiskBuro = ({ risk }: {risk: CreditRisk}) => {
  const cls = useStyles();
  const { creditBureau } = risk;
  const items = creditBureau.values || [];

  return (
    <table className={cls.table}>
      <thead>
        <tr>
          <th>Individual</th>
          <th>Bc Score</th>
          <th>Current debt</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {items.map((item, idx) => (
          <tr key={item.name}>
            <td>{titles[item.name]
              || item.name.replace('_', ' ').replace('guarantor', 'Garante')}
            </td>
            <td>{item.bsScore || 0}</td>
            <td className={idx > 0 ? cls.noborder : undefined}>
              {moneyFormatter.format(item.currentDebt || 0)}
            </td>
            {idx === 0 && (
              <td rowSpan={items.length}>{creditBureau.score}%</td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const titles: Record<string, string> = {
  'accredited': 'Autorizado',
  'legalAdvisor': 'Asesora legal',
};

const useStyles = makeStyles({
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& th': {
      backgroundColor: '#042E6B',
      color: '#FFF',
      fontSize: 12,
      fontWeight: 500,
      borderLeft: '1px solid white',
      '&:first-child': {
        borderTopLeftRadius: 5,
        borderLeft: '1px solid #042E6B',
      },
      '&:last-child': {
        borderTopRightRadius: 5,
      },
    },
    '& td': {
      borderTop: '1px solid #042E6B',
      borderLeft: '1px solid #042E6B',
      fontSize: 12,
      fontWeight: 400,
      color: '#042E6B',
      width: '25%',
      padding: '3px 8px',
      backgroundColor: '#F0F3FB',
      '&:first-child': {
        backgroundColor: 'transparent',
      },
      '&:last-child': {
        borderRight: '1px solid #042E6B',
      },
      '&[rowspan]': {
        textAlign: 'center',
        backgroundColor: '#F0F3FB',
      },
    },
    '& tr:last-child td, & td[rowspan]': {
      borderBottom: '1px solid #042E6B',
      '&:first-child': {
        borderBottomLeftRadius: 5,
      },
      '&:last-child': {
        borderBottomRightRadius: 5,
      },
    },
  },
  noborder: {
    borderRight: 'none !important',
    borderRadius: '0px !important',
  },
});
