import React from 'react';
import { Button } from '@mui/material';
import { DoctypeDocument } from '@pages/credit/DoctypeDocument';
import { Credit, Doctype, Guarantor } from '@types';
import { useRequest } from '@hooks';
import { creditHttp } from '@network';
import cls from './credit-documents.module.css';

interface CreditDocumentsProps {
  credit: Credit;
  doctypes: Doctype[];
  isSuperadmin: boolean;
  editable: boolean;
  guarantor?: Guarantor;
}

export const CreditDocuments = ({
  credit, doctypes, isSuperadmin, editable, guarantor,
}: CreditDocumentsProps) => {
  const { submit: downloadDocuments, loading: downloadLoading } = useRequest(async () => {
    if (guarantor) {
      await creditHttp.downloadGuarantorDocuments(credit.id, guarantor.id);
    }
  });

  return (
    <div className={cls._}>
      <div className={cls.actions}>
        <Button
          variant="contained"
          color="secondary"
          onClick={downloadDocuments}
          disabled={downloadLoading}
          className={cls.downloadBtn}
        >
          Download guarantor documents
        </Button>
      </div>

      <div className={cls.list}>
        {doctypes.map(doctype => (
          <div className={cls.document} key={doctype.id}>
            <DoctypeDocument
              doctype={doctype}
              credit={credit}
              isSuperadmin={isSuperadmin}
              editable={editable}
              guarantor={guarantor}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
