export const formatHoursToPhrase = (hours: number | null): string => {
  if (!hours) {
    return 'No data';
  }

  if (hours === 0) {
    return 'Less then one hour';
  }

  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;

  const months = Math.floor(days / 30);
  const remainingDays = days % 30;

  let result = '';
  if (months > 0) {
    result += `${months}m `;
  }
  if (remainingDays > 0) {
    result += `${remainingDays}d `;
  }
  if (remainingHours > 0 || result === '') {
    result += `${remainingHours}h`;
  }

  return result.trim();
};
