import { CreateProps, Create, SimpleForm } from 'react-admin';

import { AdminForm, validate } from './AdminForm';
import { Forbidden, InitialPage } from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';

export const AdminCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps} title="Create Admin">
      <InitialPage
        title="Admin"
        backTo="/admin"
        backText="Back to other admins"
      >
        <SimpleForm validate={validate}>
          <AdminForm />
        </SimpleForm>
      </InitialPage>
    </Create>
  );
};
