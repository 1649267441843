import { clsx } from 'clsx';
import { Button } from '@mui/material';
import { Radio, Space, Row, Col } from 'antd';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import IconClose from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useController, useWatch } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

import { Container } from '@components';

import cls from './Question.module.css';

interface Props {
  source: string;
}

type AnswerType = 'text' | 'number' | 'money' | 'option';

interface AnswerOption {
  nameMx: string;
  nameEn?: string;
}

interface Answer {
  id?: number | string;
  nameMx: string;
  nameEn?: string;
  position: number;
  isEnabled?: boolean;
  isRequired?: boolean;
  type?: AnswerType;
  options?: AnswerOption[];
}

export const AnswersInput = ({ source }: Props) => {
  const {
    field: { value, onChange },
  } = useController({ name: source });
  const isTable = useWatch({ name: 'isTable' });
  const [answers, setAnswers] = useState<Answer[]>(value || []);

  useEffect(() => {
    onChange(answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  useEffect(() => {
    if (isTable && value?.length > 5) {
      const updatedAnswers: Answer[] = answers.splice(0, 5)
        .map(item => !item.type ? ({ ...item, type: 'text' }) : item);
      setAnswers(updatedAnswers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTable]);

  const updateAnswer = (editAnswer: Answer, field: keyof Answer, value: any) => {
    const nextAnswers: Answer[] = answers.map((answer => {
      if (answer.position === editAnswer.position) {
        (editAnswer as any)[field] = value;

        return editAnswer;
      }

      return answer;
    }));
    setAnswers(nextAnswers);
  };

  const addAnswer = () => {
    const maxPos = Math.max.apply(this, answers.map(a => a.position));
    const answer: Answer = {
      nameMx: '',
      nameEn: '',
      position: answers?.length > 0 ? maxPos + 1 : 1,
      isEnabled: true,
      isRequired: false,
    };
    if (isTable) {
      answer.type = 'text';
    }
    setAnswers([...answers, answer]);
  };

  const removeAnswer = (answer: Answer) => {
    const nextAnswers = answers.filter(a => a.position !== answer.position);
    setAnswers(nextAnswers);
  };

  const addOption = (currentAnswer: Answer) => {
    const answerIndex = answers.findIndex(a => a.position === currentAnswer.position);
    if (answerIndex !== -1) {
      const newAnswer = { ...answers[answerIndex] };

      if (!('options' in newAnswer)) {
        newAnswer.options = [];
      }

      const optionsLength = newAnswer.options?.length || 0;
      const newAnswerOptions = newAnswer.options ? newAnswer.options[optionsLength - 1] : null;

      if (optionsLength !== 0 && newAnswerOptions?.nameMx === '') {
        return;
      }

      if (newAnswer.options) {
        newAnswer.options.push({ nameMx: '', nameEn: '' });

        setAnswers([
          ...answers.slice(0, answerIndex),
          newAnswer,
          ...answers.slice(answerIndex + 1),
        ]);
      }
    }
  };

  const updateOption = (editAnswer: AnswerOption, field: any, value: any) => {
    const [answerPosition, optionIndex] = field;
    const answerIndex = answers.findIndex(a => a.position === answerPosition);

    if (answerIndex !== -1) {
      const newAnswer = {
        ...answers[answerIndex],
        options: [
          ...(answers[answerIndex]?.options || []).slice(0, optionIndex),
          { nameMx: value },
          ...(answers[answerIndex]?.options || []).slice(optionIndex + 1)],
      };

      setAnswers([
        ...answers.slice(0, answerIndex),
        newAnswer,
        ...answers.slice(answerIndex + 1),
      ]);
    }
  };

  const removeOption = (answer: Answer, optionIndex: number) => {
    const answerIndex = answers.findIndex(a => a.id === answer.id);
    if (answerIndex !== -1) {
      const newAnswer = {
        ...answers[answerIndex],
        options: (answers[answerIndex]?.options || []).filter((_, index) => index !== optionIndex),
      };

      setAnswers([...answers.slice(0, answerIndex), newAnswer, ...answers.slice(answerIndex + 1)]);
    }
  };

  return (
    <Container max>
      {!isTable ? (
        <>
          {answers.map(answer => (
            <Row key={answer.position} gutter={24}>
              <Col xs={24} md={18} lg={20} xl={14} xxl={16} className={cls.colAnswer}>
                <TextField
                  label="Name Mx"
                  placeholder="Write your question"
                  value={answer.nameMx || ''}
                  onChange={e => updateAnswer(answer, 'nameMx', e.target.value)}
                  fullWidth
                  size="small"
                  variant="outlined"
                  autoFocus
                  className={clsx(cls.name, cls.textField)}
                />
              </Col>
              <Col xs={24} md={6} lg={4} xl={3} xxl={3} className={cls.colAnswer}>
                <TextField
                  label="Position"
                  value={answer.position || ''}
                  onChange={e => updateAnswer(answer, 'position', +e.target.value)}
                  fullWidth
                  size="small"
                  variant="outlined"
                  className={clsx(cls.position, cls.textField)}
                />
              </Col>
              <Col xs={24} xl={7} xxl={5} className={cls.colAnswer}>
                <div className={cls.row}>
                  <div className={cls.col}>
                    <FormControlLabel className={cls.enabled} label="Is enabled" control={
                      <Checkbox
                        checked={answer.isEnabled}
                        onChange={() => updateAnswer(answer, 'isEnabled', !answer.isEnabled)}
                        name="checkedB"
                        color="primary"
                      />
                    }/>
                  </div>
                  <div className={cls.col}>
                    <IconButton className={cls.remove} onClick={() => removeAnswer(answer)}>
                      <DeleteIcon className={cls.removeIcon}/>
                    </IconButton>
                    <span className={cls.removeText}>Remove</span>
                  </div>
                </div>
              </Col>
            </Row>
          ))}
          <Row>
            <Col className={cls.actionCol}>
              <Button
                onClick={addAnswer}
                variant="outlined"
                startIcon={<AddIcon/>}
                className={cls.btn}
              >Add answer</Button>
            </Col>
          </Row>
        </>
      ) : (
        <Row gutter={12}>
          {answers
            .sort((a, b) => a.position > b.position ? 1 : -1)
            .map(answer => (
              <Col key={answer.position}>
                <TextField
                  label="Column name"
                  value={answer.nameMx || ''}
                  onChange={e => updateAnswer(answer, 'nameMx', e.target.value)}
                  fullWidth
                  size="small"
                  variant="outlined"
                  autoFocus
                />
                <div>
                  <FormControlLabel label="Is enabled" control={
                    <Checkbox
                      checked={answer.isEnabled}
                      onChange={() => updateAnswer(answer, 'isEnabled', !answer.isEnabled)}
                      name="isEnabled"
                      color="primary"
                    />
                  }/>
                </div>
                <div>
                  <FormControlLabel label="Is required" control={
                    <Checkbox
                      checked={answer.isRequired}
                      onChange={() => updateAnswer(answer, 'isRequired', !answer.isRequired)}
                      name="isRequired"
                      color="primary"
                    />
                  }/>
                </div>
                <div>
                  <Radio.Group
                    value={answer.type}
                    onChange={(e) => updateAnswer(answer, 'type', e.target.value)}
                  >
                    <Space direction="vertical">
                      <Radio value="text">Text</Radio>
                      <Radio value="number">Number</Radio>
                      <Radio value="money">Money</Radio>
                      <Radio value="option">Option</Radio>
                    </Space>
                  </Radio.Group>

                  {answer.type === 'option' && (
                    <div className={cls.options}>
                      {answer.options?.map((option, index) => (
                        <div key={`option-${index}`} className={cls.option}>
                          <TextField
                            label={`Option ${index + 1}`}
                            value={option.nameMx || ''}
                            onChange={
                              e => updateOption(option, [answer.position, index], e.target.value)
                            }
                            fullWidth
                            size="small"
                            variant="outlined"
                          />
                          <Button
                            variant="text"
                            onClick={() => removeOption(answer, index)}
                          >
                            <RemoveIcon />
                          </Button>
                        </div>
                      ))}

                      <Button
                        variant="outlined"
                        startIcon={<AddIcon/>}
                        onClick={() => addOption(answer)}
                      >Add option</Button>
                    </div>
                  )}
                </div>
                <div className={cls.removeBox}>
                  <Button
                    color="error"
                    variant="outlined"
                    size="small"
                    className={cls.removeBtn}
                    onClick={() => removeAnswer(answer)}
                  >
                    <IconClose className={cls.removeBtnIcon}/> Remove column
                  </Button>
                </div>
              </Col>
            ))}
          <Col className={cls.actionCol}>
            <Button
              onClick={addAnswer}
              variant="outlined"
              startIcon={<AddIcon/>}
              disabled={answers?.length === 5}
            >Add column</Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

