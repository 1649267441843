import { useRef, useState } from 'react';
import { FunctionFieldProps, FunctionField } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { Button, Input, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { CancelOutlined } from '@mui/icons-material';

import { AdminComment } from '@types';
import { dateFormatter } from '@utils';
import { resolvePath } from './resolve-path';
import { useRequest, useUpdated } from '@hooks';
import { adminHttp } from '@network';

interface ExtraFields {
  partnerClass: string;
  editable?: boolean;
}

export const CommentsField = (props: Partial<FunctionFieldProps> & ExtraFields) => {
  const { partnerClass, editable, ...restProps } = props;

  return (
    <FunctionField
      sortable={false}
      {...restProps}
      render={(record: any) => (
        <Comments
          partnerClass={partnerClass}
          partnerId={record?.id || 0}
          comments={resolvePath(record, props.source || '')}
          editable={editable}
        />
      )}
    />
  );
};

interface CommentsProps {
  comments: AdminComment[];
  partnerClass: string;
  partnerId: number;
  editable?: boolean;
}

const Comments = ({ comments, partnerClass, partnerId, editable }: CommentsProps) => {
  const cls = useStyles();
  const textRef = useRef<any>();
  const [text, setText] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [currentComment, setCurrentComment] = useState<number | null>(null);
  const updated = useUpdated('Comment added');
  const deleted = useUpdated('Comment deleted');

  const showModal = () => {
    setIsModalVisible(true);
    setTimeout(() => void textRef.current?.focus(), 500);
  };
  const hideModal = () => setIsModalVisible(false);

  const showCommentModal = (id: number): void => {
    setCurrentComment(id);
    setIsCommentModalVisible(true);
  };
  const hideCommentModal = (): void => {
    setIsCommentModalVisible(false);
    setCurrentComment(null);
  };

  const { loading, submit } = useRequest(async () => {
    if (!text) {
      textRef.current?.focus();

      return;
    }
    await adminHttp.addComment({ comment: text, partnerClass, partnerId });
    setText('');
    hideModal();
    setImmediate(updated);
  });

  const { loading: removeLoading, submit: remove } = useRequest(async (id: number) => {
    if (!id) {
      hideCommentModal();

      return;
    }
    await adminHttp.removeComment(id);
    hideCommentModal();
    setImmediate(deleted);
  });

  return (
    <div className={cls.wrap} onClick={e => e.stopPropagation()}>
      {Array.isArray(comments) && comments.map(comment => (
        <div className={cls.item} key={comment.id}>
          <div className={cls.comment}>{comment.comment}</div>
          <div className={cls.row}>
            <b>{comment.adminName}</b>
            <span>{dateFormatter.toDateTime(comment.createdAt)}</span>
          </div>
          {editable ? (
            <div className={cls.closeBtn} onClick={() => showCommentModal(+comment.id)}>
              <CancelOutlined className={cls.closeBtnIcon} />
            </div>
          ) : null}
        </div>
      ))}
      {editable ? (
        <Button
          className={cls.btn}
          onClick={showModal}
          icon={<PlusCircleOutlined />}
          type="primary"
        />
      ) : null}
      <Modal
        open={isModalVisible}
        title={'Add note'}
        okText={'Save'}
        onOk={submit}
        confirmLoading={loading}
        onCancel={hideModal}
      >
        <Input.TextArea
          ref={textRef}
          value={text}
          onChange={e => setText(e.target.value)}
          autoFocus
        />
      </Modal>

      <Modal
        title="Deleting a comment"
        open={isCommentModalVisible}
        onOk={() => remove(currentComment)}
        onCancel={hideCommentModal}
        okText="Yes"
        cancelText="No"
        confirmLoading={removeLoading}
      >
        <p>Do you really want to delete the comment?</p>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -15,
  },
  item: {
    position: 'relative',
    width: 170,
    marginLeft: 15,
    marginBottom: 10,
    fontSize: 13,
    lineHeight: '1.1em',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  comment: {
    fontSize: 13,
    backgroundColor: 'beige',
    padding: '3px 5px',
    borderRadius: 3,
    boxShadow: '0 4px 8px 0 rgba(48, 55, 61, 0.2)',
    wordWrap: 'break-word',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 5px 0',
    fontSize: 12,
  },
  btn: {
    marginLeft: 15,
  },
  closeBtn: {
    position: 'absolute',
    top: -8,
    right: -8,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  closeBtnIcon: {
    fontSize: 18,
    color: '#f5222d',
  },
});
