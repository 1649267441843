import {
  Document,
  HeadingLevel,
  Paragraph,
  TextRun,
  Table,
  TableCell,
  TableRow,
} from 'docx';
import { Credit, SurveyQuestion } from '@types';
import { formatMoney } from '@utils';

export class DocumentCreator {
  // tslint:disable-next-line: typedef
  public createFromQeustions(questions: SurveyQuestion[], credit: Credit): Document {
    const document = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: `${credit.user.fullName}. Questionario of Credit ${credit.code}.docx`,
              heading: HeadingLevel.HEADING_1,
              thematicBreak: true,
            }),
            new Paragraph(' '),
            ...questions.map((question, i) => {
              const arr: (Paragraph | Table)[] = [];
              arr.push(new Paragraph({
                text: `${i + 1}. ${question.nameMx}`,
                heading: HeadingLevel.HEADING_2,
                thematicBreak: true,
              }));
              if (question.customAnswer) {
                arr.push(new Paragraph({
                  children: [new TextRun(question.customAnswer)],
                }));
              }
              if (question.isTable) {
                question.rows = (question.rows || []).filter(row => row.cols.length > 0);
                arr.push(new Table({
                  columnWidths: [2000, 2000, 2000, 2000, 2000],
                  rows: [
                    new TableRow({
                      children: question.answers.map(answer => new TableCell({
                        children: [new Paragraph(answer.nameMx)],
                      })),
                    }),
                    ...question.rows.map(row => new TableRow({
                      children: row.cols.map(col => new TableCell({
                        children: [new Paragraph(col.answerType === 'money'
                          ? `${formatMoney(col.customAnswer)}`
                          : `${col.customAnswer || ''}`)],
                      })),
                    })),
                  ],
                }));
              } else if (question.isPlanner) {
                question.rows = (question.rows || []).filter(row => row.cols.length > 0);
                arr.push(new Table({
                  columnWidths: [2000, 2000],
                  rows: [
                    new TableRow({
                      children: question.answers.map(answer => new TableCell({
                        children: [new Paragraph(answer.nameMx)],
                      })),
                    }),
                    ...question.rows.map(row => new TableRow({
                      children: row.cols.map(col => new TableCell({
                        children: [new Paragraph(`${col.customAnswer}%` || '')],
                      })),
                    })),
                  ],
                }));
              }
              else if (question.answers.length > 0 && question.answerIds.length > 0) {
                question.answers.forEach(answer => {
                  if (question.answerIds.includes(+answer.id)) {
                    arr.push(new Paragraph({
                      children: [new TextRun(answer.nameMx)],
                    }));
                  }
                });
              }
              arr.push(new Paragraph({ text: ' ' }));
              return arr;
            }).reduce((prev: any, curr: any) => prev.concat(curr), []),
          ],
        },
      ],
    });

    return document;
  }
}
