import { clsx } from 'clsx';
import { Link } from 'react-admin';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useController } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { InputNumber, Select, Checkbox } from 'antd';

import { deliveryHttp } from '@network';
import { DeliveryPosition, OrderPosition } from '@types';
import cls from './DeliveryForm.module.css';

interface Option {
  key: string;
  position: OrderPosition;
  search: string;
}

interface DeliveryFormProps {
  editable: boolean;
}

export const DeliveryForm = ({ editable }: DeliveryFormProps) => {
  const {
    field: { value: deliveryId },
  } = useController({ name: 'id' });
  const {
    field: { value: del, onChange: changeDeliveryPositions },
  } = useController({ name: 'deliveryPositions' });

  const [deliveryPositions, setDeliveryPositions] = useState<DeliveryPosition[]>(del || []);
  const [positions, setPositions] = useState<OrderPosition[]>([]);

  const loadPositions = async (deliveryId?: number) => {
    const nextPositions = await deliveryHttp.getPositions(deliveryId);
    if (Array.isArray(nextPositions)) {
      setPositions(nextPositions);
    }
  };

  useEffect(() => {
    loadPositions(deliveryId);
    if ((deliveryPositions || []).length === 0) {
      addPosition();
    }
  }, []);

  useEffect(() => {
    changeDeliveryPositions(deliveryPositions);
  }, [deliveryPositions]);

  const options: Option[] = positions.map(position => ({
    key: `${position.id}`,
    position,
    search: `${position.distributor} ${position.product.name} ${position.userCode}`,
  }));

  const addPosition = () => {
    const addedPosition: DeliveryPosition = {
      orderId: 0,
      positionId: null,
      qty: 0,
      isPesoCost: false,
      usdPositionCost: 0,
      farmerPositionCost: 0,
    };
    setDeliveryPositions([...deliveryPositions, addedPosition]);
  };
  const removePosition = (index: number) => {
    const nextDeliveryPositions = deliveryPositions.filter((p, i) => i !== index);
    setDeliveryPositions(nextDeliveryPositions);
  };
  const onPositionSelect = (index: number, positionId: number) => {
    if (deliveryPositions.find(pos => pos.positionId === positionId)) {
      return removePosition(index);
    }
    const nextDeliveryPositions = deliveryPositions.slice();
    const position = positions.find(pos => pos.id === positionId);
    deliveryPositions[index].orderId = position?.orderId;
    deliveryPositions[index].positionId = positionId;
    deliveryPositions[index].qty = position?.qty || 0;
    deliveryPositions[index].isPesoCost = position?.isPesoCost || false;
    deliveryPositions[index].usdPositionCost = position?.usdPositionCost || 0;
    deliveryPositions[index].farmerPositionCost = position?.farmerPositionCost || 0;
    setDeliveryPositions(nextDeliveryPositions);
    loadPositions(deliveryId);
  };
  const onQtyChange = (index: number, value: number) => {
    const nextDeliveryPositions = deliveryPositions.slice();
    nextDeliveryPositions[index].qty = value;
    setDeliveryPositions(nextDeliveryPositions);
  };
  const onCost = (index: number, value: number) => {
    const nextDeliveryPositions = deliveryPositions.slice();
    nextDeliveryPositions[index].usdPositionCost = value;
    setDeliveryPositions(nextDeliveryPositions);
  };
  const onIsPeso = (index: number, value: boolean) => {
    const nextDeliveryPositions = deliveryPositions.slice();
    nextDeliveryPositions[index].isPesoCost = value;
    setDeliveryPositions(nextDeliveryPositions);
  };
  const onFarmer = (index: number, value: number) => {
    const nextDeliveryPositions = deliveryPositions.slice();
    nextDeliveryPositions[index].farmerPositionCost = value;
    setDeliveryPositions(nextDeliveryPositions);
  };

  const orderedPositions = (deliveryPositions || [])
    .sort((a, b) => (a.positionId || 0) - (b.positionId || 0));

  return (
    <>
      {orderedPositions.map((position, index) => {
        return (
          <div className={cls.box} key={index}>
            <div className={clsx(cls.col, cls.colProduct)}>
              <InputLabel shrink>Product</InputLabel>
              <Select
                className={cls.selectProduct}
                value={position?.positionId?.toString()}
                showSearch
                allowClear={false}
                placeholder="Select a product"
                optionFilterProp="children"
                onChange={positionId => onPositionSelect(index, +positionId)}
                filterOption={(input, option: any) => {
                  return option.key?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {options.map(({ key, position, search }) => {
                  const product = position.product;
                  const isPicked = deliveryPositions.find(p => `${p.positionId}` === key);

                  return (
                    <Select.Option key={search + key} value={key} disabled={!!isPicked}>
                      <div className={cls.position}>
                        <div className={cls.positionCol}><b>{product.distributor}</b></div>
                        <div className={cls.positionCol}>{product.name}</div>
                        {product.pack && (
                          <div className={cls.positionCol}>pack: {product.pack}</div>
                        )}
                        <div className={cls.positionCol}>units: {position.qty}</div>
                        <div className={cls.positionCol}>farmer: {position.userCode}</div>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div className={clsx(cls.col, cls.colCost)}>
              <InputLabel shrink>{position.isPesoCost ? 'Peso' : 'USD'} cost</InputLabel>
              <InputNumber
                value={position.usdPositionCost}
                onChange={cost => onCost(index, +`${cost}`)}
                min={0} max={999999999}
              />
            </div>
            <div className={clsx(cls.col, cls.colCheck)}>
              <InputLabel shrink>isPeso</InputLabel>
              <Checkbox
                checked={position.isPesoCost || false}
                onChange={e => onIsPeso(index, e.target.checked || false)}
              />
            </div>
            <div className={clsx(cls.col, cls.colPubPrice)}>
              <InputLabel shrink>Public price pesos</InputLabel>
              <InputNumber
                value={position.farmerPositionCost}
                onChange={cost => onFarmer(index, +`${cost}`)}
                min={0} max={999999999}
              />
            </div>
            <div className={clsx(cls.col, cls.colUnits)}>
              <InputLabel shrink>Units</InputLabel>
              <InputNumber
                value={position.qty}
                onChange={qty => onQtyChange(index, +`${qty}`)}
                min={0.001} max={999999999}
              />
            </div>
            {position?.orderId ? (
              <div className={clsx(cls.col, cls.colOrder)}>
                <InputLabel shrink>Order</InputLabel>
                <Link
                  to={{ pathname: `/order/${position.orderId}` }}
                  target={deliveryId ? undefined : '_blank'}
                  className={cls.orderLink}
                >
                  Order #{position.orderId}
                </Link>
              </div>
            ) : null}
            {editable ? (
              <div className={clsx(cls.col, cls.colRemove)}>
                <InputLabel className={cls.removeLabel} shrink>Remove</InputLabel>
                <Button className={cls.removeBtn} onClick={() => removePosition(index)}>
                  <DeleteIcon className={cls.removeIcon} />
                </Button>
              </div>
            ) : null}
          </div>
        );
      })}

      {editable ? (
        <div>
          <Button
            onClick={addPosition}
            variant="contained"
            color="secondary"
            startIcon={<AddIcon className={cls.btnIcon} />}
            className={cls.btn}
            disabled={!!deliveryPositions.find(p => !p.positionId)}
          >Add product</Button>
        </div>
      ) : null}
    </>
  );
};
