import { useRef, useState } from 'react';
import { useNotify, useRedirect, useRefresh } from 'react-admin';
import { clsx } from 'clsx';
import { Upload, UploadFile } from 'antd';
import moment from 'moment-timezone';

import { Credit, CreditInvoice, CreditInvoiceStatusType } from '@types';
import { BlueButton, FileImage, FilePdf, InitialPage } from '@components';
import { ConfirmDelete } from '@components/ConfirmDelete';
import { creditHttp, s3http } from '@network';
import { axiosErrorToString, beforeUploadFlex, FileTypesOptions, showAxiosError } from '@utils';
import { SentBy } from '@pages/credit/SentBy';
import { CreditAmounts } from '@pages/credit/CreditEdit/CreditAmounts';
import { FinancialTransactions } from '@pages/credit/CreditEdit/FinancialTransactions';
import cls from './credit-invoices.module.css';
import { FileXml } from '@components/FileXml';

const UploadInvoiceFile = ({ onUpload, isLoading }: any) => {
  const options: FileTypesOptions = {
    types: [
      'application/pdf',
      'image/jpeg',
      'image/jpg',
      'image/png',
      'application/vnd.google-earth.kml+xml',
      'text/xml',
      '',
    ],
    typeError: 'You can only upload JPG/PNG/PDF/KML/XML file!',
  };

  return (
    <Upload
      maxCount={10}
      multiple
      listType="picture-card"
      showUploadList={false}
      beforeUpload={(file) => beforeUploadFlex(file, options)}
      customRequest={async ({ onSuccess }) => setTimeout(() => {
        onSuccess && onSuccess('ok');
      }, 0)}
      onChange={({ fileList }) => onUpload(fileList)}
      onPreview={() => {}}
      className={cls.addInvoiceUpload}
      disabled={isLoading}
      accept={(options.types || []).join(', ')}
    >
      <BlueButton disabled={isLoading}>Add invoice</BlueButton>
    </Upload>
  );
};

export const CreditInvoicesPage = ({ credit }: {credit: Credit}) => {
  moment.locale('en');
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();

  const [isLoading, setIsLoading] = useState(false);
  const usedFiles = useRef<Record<string, boolean>>({});

  const invoices = credit.invoices;
  const groups: Record<string, CreditInvoice[]> = {};

  invoices?.forEach(invoice => {
    const date = moment(invoice.createdAt).format('LL');
    if (groups[date]) {
      groups[date].push(invoice);
    } else {
      groups[date] = [invoice];
    }
  });

  const selectInvoice = (e: any, id: number) => {
    e.stopPropagation();
    redirect(`/credit/${credit.id}?invoices=${id}`);
  };

  const remove = async (invoiceId: number) => {
    try {
      await creditHttp.removeInvoice(invoiceId);
      refresh();
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  const onUpload = async (uploadFiles: UploadFile[]) => {
    const files: UploadFile[] = [];
    uploadFiles.forEach(file => {
      const uid = file.uid;
      if (!usedFiles.current[uid]) {
        usedFiles.current[uid] = true;
        files.push(file);
      }
    });

    if (uploadFiles.length === 0) {
      return;
    }

    setIsLoading(true);
    try {
      const fileIds: number[] = [];

      const s3files = await Promise.all(
        files.map(file => s3http.uploadFile(file.originFileObj as File)),
      );

      for (let i = 0; i < s3files.length; i++) {
        const fileId = s3files[i].fileId;
        fileIds.push(fileId);
      }

      await creditHttp.uploadInvoice({ creditId: credit.id, userId: credit.userId, fileIds });
    } catch (err: any) {
      console.error(err);
      showAxiosError(err);
    } finally {
      refresh();
      setIsLoading(false);
    }
  };

  const groupsList = Object.keys(groups);
  const backTo = `/credit/${credit.id}/?initial`;

  return (
    <InitialPage
      title="Invoice and Credit Manager"
      backTo={backTo}
      backText="Back to individual credit"
      headerRight={(
        <div className={cls.info}>
          <div className={cls.infoCode}>{credit.code}</div>
          <div className={cls.infoName}>{credit.accreditedName}</div>
        </div>
      )}
    >
      <div className={cls.amounts}>
        <CreditAmounts credit={credit} />
      </div>

      <div className={cls.list}>
        <div className={cls.listHead}>
          <h2 className={cls.listTitle}>Invoices</h2>
          <UploadInvoiceFile onUpload={onUpload} isLoading={isLoading} />
        </div>
        {groupsList.length === 0 && <div className={cls.text}>No items</div>}
        {groupsList.map((groupDate) => {
          return (
            <div className={cls.group} key={groupDate}>
              <div className={cls.groupTitle}>Update {groupDate}</div>
              <div className={cls.groupList}>
                {groups[groupDate].map((invoice, invoiceIdx) => (
                  <div
                    className={cls.groupItem}
                    key={`${groupDate}-${invoice.id}`}
                    onClick={(e) => selectInvoice(e, invoice.id)}
                  >
                    <div className={cls.sendBy}>
                      <SentBy type={invoice.managerId ? 'manager'
                        : invoice.adminId ? 'admin' : 'user'} />
                    </div>
                    <div>
                      <div className={cls.groupItemContent}>
                        <div className={cls.groupItemFile}>
                          <div className={cls.preview}>
                            {invoice.file.isPdf && (
                              <FilePdf url={invoice.file.adminUrl} downloadFilename={
                                `Credit ${credit.code} Invoice #${invoiceIdx + 1}`} />
                            )}
                            {invoice.file.isXml && (
                              <FileXml url={invoice.file.adminUrl} downloadFilename={
                                `Credit ${credit.code} Invoice #${invoiceIdx + 1}`} />
                            )}
                            {invoice.file.isImage && (
                              <FileImage url={invoice.file.adminUrl} downloadFilename={
                                `Credit ${credit.code} Invoice #${invoiceIdx + 1}`} />
                            )}
                          </div>
                          <div className={cls.groupItemRemove} onClick={e => e.stopPropagation()}>
                            <ConfirmDelete title="Are you sure?" cb={() => remove(invoice.id)}>
                              <svg style={{ display: 'block' }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none">
                                <path
                                  d="M7 0C3.13461 0 0 3.13461 0 7C0 10.8654 3.13461 14 7 14C10.8654 14 14 10.8654 14 7C13.9938 3.13461 10.8623 0.00310973 7 0Z"
                                  fill="#002A77" />
                                <path
                                  d="M9.63374 4.36293C9.51246 4.24165 9.31655 4.24165 9.19527 4.36293L6.9998 6.5584L4.80433 4.36293C4.68305 4.24165 4.48714 4.24165 4.36586 4.36293C4.24458 4.48421 4.24458 4.68012 4.36586 4.8014L6.56133 6.99687L4.36586 9.19234C4.24458 9.31362 4.24458 9.50953 4.36586 9.63081C4.48714 9.75209 4.68305 9.75209 4.80433 9.63081L6.9998 7.43534L9.19527 9.63081C9.31655 9.75209 9.51246 9.75209 9.63374 9.63081C9.75502 9.50953 9.75502 9.31362 9.63374 9.19234L7.43827 6.99687L9.63374 4.8014C9.75502 4.68012 9.75502 4.48421 9.63374 4.36293Z"
                                  fill="white" />
                              </svg>
                            </ConfirmDelete>
                          </div>
                        </div>
                      </div>
                      <div className={cls.groupItemInfo}>{invoice.file?.originalName || ''}</div>
                    </div>
                    <div className={cls.groupItemStatus}>
                      <div className={clsx(
                        cls.groupItemLabel,
                        invoice.status && cls[invoice.status],
                      )}>
                        {invoice.status === CreditInvoiceStatusType.APPROVED && <ApprovedIcon />}
                        {invoice.status === CreditInvoiceStatusType.DENIED && <DeniedIcon />}
                        {invoice.status === CreditInvoiceStatusType.PENDING && <PendingIcon />}
                        <span>{invoice.status}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>

      <div className={cls.transactions}>
        <FinancialTransactions credit={credit} />
      </div>
    </InitialPage>
  );
};

const ApprovedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clipPath="url(#clip0_3856_5275)">
      <path d="M6 12C2.692 12 0 9.308 0 6C0 2.692 2.692 0 6 0C9.308 0 12 2.692 12 6C12 9.308 9.308 12 6 12ZM6 0.8C3.132 0.8 0.8 3.132 0.8 6C0.8 8.868 3.132 11.2 6 11.2C8.868 11.2 11.2 8.868 11.2 6C11.2 3.132 8.868 0.8 6 0.8ZM5.484 7.884L9.084 4.284C9.24 4.128 9.24 3.876 9.084 3.72C8.928 3.564 8.676 3.564 8.52 3.72L5.204 7.036L3.888 5.72C3.732 5.564 3.48 5.564 3.324 5.72C3.168 5.876 3.168 6.128 3.324 6.284L4.924 7.884C5 7.96 5.104 8 5.208 8C5.312 8 5.412 7.96 5.492 7.884H5.484Z"
        fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_3856_5275">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const DeniedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clipPath="url(#clip0_3856_5345)">
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M3.88628 4.16811L4.16811 3.88628C4.30161 3.76761 4.49444 3.76761 4.6131 3.88628L5.99258 5.28059L7.37206 3.88628C7.50556 3.76761 7.69839 3.76761 7.81706 3.88628L8.09889 4.16811C8.21755 4.30161 8.21755 4.49444 8.09889 4.6131L6.71941 5.99258L8.09889 7.37206C8.21755 7.50556 8.21755 7.69839 8.09889 7.81706L7.81706 8.09889C7.69839 8.21755 7.50556 8.21755 7.37206 8.09889L5.99258 6.71941L4.6131 8.09889C4.49444 8.21755 4.30161 8.21755 4.16811 8.09889L3.88628 7.81706C3.76761 7.69839 3.76761 7.50556 3.88628 7.37206L5.28059 5.99258L3.88628 4.6131C3.76761 4.49444 3.76761 4.30161 3.88628 4.16811ZM5.99258 0C9.3152 0 12 2.6848 12 5.99258C12 9.30037 9.3152 12 5.99258 12C2.66996 12 0 9.3152 0 5.99258C0 2.66996 2.6848 0 5.99258 0ZM5.99258 1.02349C8.75154 1.02349 10.9765 3.24845 10.9765 5.99258C10.9765 8.73671 8.75154 10.9765 5.99258 10.9765C3.23362 10.9765 1.02349 8.75154 1.02349 5.99258C1.02349 3.23362 3.24845 1.02349 5.99258 1.02349Z"
        fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_3856_5345">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const PendingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clipPath="url(#clip0_3856_5261)">
      <path d="M6 0C9.31333 0 12 2.68667 12 6C12 9.31333 9.31333 12 6 12C2.68667 12 -9.53674e-07 9.31333 -9.53674e-07 6C0.00399971 2.688 2.688 0.004 6 0ZM6 11.2C8.872 11.2 11.2 8.872 11.2 6C11.2 3.128 8.872 0.8 6 0.8C3.128 0.8 0.799999 3.128 0.799999 6C0.802666 8.87067 3.12933 11.1973 6 11.2Z"
        fill="black" />
      <path d="M5.99999 4.8418C6.29466 4.8418 6.53333 5.08046 6.53333 5.37513V8.72313C6.53333 9.0178 6.29466 9.25646 5.99999 9.25646C5.70533 9.25646 5.46666 9.0178 5.46666 8.72313V5.37513C5.46666 5.08046 5.70533 4.8418 5.99999 4.8418Z"
        fill="black" />
      <path d="M5.99999 3.80885C5.70544 3.80885 5.46666 3.57007 5.46666 3.27552C5.46666 2.98097 5.70544 2.74219 5.99999 2.74219C6.29454 2.74219 6.53333 2.98097 6.53333 3.27552C6.53333 3.57007 6.29454 3.80885 5.99999 3.80885Z"
        fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_3856_5261">
        <rect width="12" height="12" fill="white" transform="matrix(-1 0 0 1 12 0)" />
      </clipPath>
    </defs>
  </svg>
);
