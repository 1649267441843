import { FunctionField, FunctionFieldProps } from 'react-admin';
import Chip from '@mui/material/Chip';
import { Color } from '@mui/material';
import { rbac } from '@utils';
import { CreditStatus } from '@types';

interface ColoredOption {
  id: CreditStatus;
  name: string;
  color: Color | string;
  disabled?: boolean;
}

export const creditStatusOptions: ColoredOption[] = [
  { id: CreditStatus.DRAFT, name: 'Draft', color: '#74706B' },
  { id: CreditStatus.MODERATION, name: 'Applying', color: '#2196F3' },
  { id: CreditStatus.COMPLETED_FILE, name: 'Completed File', color: '#213df3' },
  { id: CreditStatus.BANK_REVISION, name: 'Analysis', color: '#009688' },
  { id: CreditStatus.APPROVED, name: 'Approved', color: '#4CAF50' },
  { id: CreditStatus.DENIED, name: 'Denied', color: '#FF5722' },
  { id: CreditStatus.SIGNED, name: 'Signed', color: '#4CAF50' },
  { id: CreditStatus.REPAID, name: 'Repaid', color: '#3F51B5' },
  { id: CreditStatus.RESTRUCTURE, name: 'Restructure', color: '#673AB7' },
  { id: CreditStatus.DELAY, name: 'Delay', color: '#CDDC39' },
];

export const getCreditStatusOptions = () => {
  const disabledStatuses: CreditStatus[] = [];

  if (rbac.isSuperadmin()) {
    // no extra restrictions for Superadmin
  } else if (rbac.hasCreditStatusControl()) {
    // no extra restrictions for Credit Status Control
  } else {
    disabledStatuses.push(CreditStatus.DRAFT);
    disabledStatuses.push(CreditStatus.BANK_REVISION);
    disabledStatuses.push(CreditStatus.APPROVED);
    disabledStatuses.push(CreditStatus.SIGNED);
    disabledStatuses.push(CreditStatus.REPAID);
    disabledStatuses.push(CreditStatus.RESTRUCTURE);
    disabledStatuses.push(CreditStatus.DELAY);
  }

  return creditStatusOptions.map(o => ({
    ...o,
    disabled: disabledStatuses.includes(o.id),
  }));
};

interface Props {
  source: string;
  creditStatus?: string;
  className?: string;
}

export const CreditStatusField = (props: Props & Partial<FunctionFieldProps>) => {
  const { creditStatus, className, ...restProps } = props;

  return (
    <FunctionField {...restProps} render={(record: any) => {
      const status = creditStatus || record.credit?.status || record[props.source];
      const statusName = creditStatusOptions.find(o => o.id === status)?.name;
      const color: any = creditStatusOptions.find(o => o.id === status)?.color;

      if (!status || !color) {
        return null;
      }

      return (
        <Chip
          label={statusName || status}
          size="small"
          style={{
            backgroundColor: typeof color === 'string'
              ? color
              : (color && color[500] ? color[500] : '#74706B'),
            color: 'white',
            height: 20,
            fontSize: 11,
            width: '100%',
          }}
          className={className}
        />
      );
    }} />
  );
};
