import { Credit } from '@types';
import { CreditFarms } from '@pages/credit';
import { InitialPage, InitialCard } from '@components';

interface Props {
  setCredit?: (newCredit: Credit) => void;
  credit: Credit;
}

export const CreditFarmsPage = ({ credit, setCredit }: Props) => {
  const backTo = `/credit/${credit.id}?initial`;

  return (
    <InitialPage title="Farms of credit" backTo={backTo} backText="Back to credit">
      <InitialCard key={credit.id} mt={24}>
        <CreditFarms credit={credit} editable={true} setCredit={setCredit} />
      </InitialCard>
    </InitialPage>
  );
};
