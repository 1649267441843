import Chip from '@mui/material/Chip';
import { ColoredOption } from '@pages/credit-call/CallStatusField';

export const creditStatusOptions: ColoredOption[] = [
  { id: 'pending', name: 'Pending', color: '#FFC82D' },
  { id: 'denied', name: 'Denied', color: '#FD492D' },
  { id: 'approved', name: 'Approved', color: '#88D256' },
];

interface Props {
  invoiceStatus?: string;
  className?: string;
  width?: number;
}

export const CreditInvoicesStatusField = ({ invoiceStatus, className, width }: Props) => {
  const statusName = creditStatusOptions.find(o => o.id === invoiceStatus)?.name;
  const color: any = creditStatusOptions.find(o => o.id === invoiceStatus)?.color;

  return (
    <Chip
      label={statusName || invoiceStatus}
      size="small"
      style={{
        backgroundColor: typeof color === 'string'
          ? color
          : (color && color[500] ? color[500] : '#74706B'),
        color: 'white',
        height: 20,
        fontSize: 11,
        width: width || '100%',
      }}
      className={className}
    />
  );
};
