import { Button, Chip } from '@mui/material';
import { useController } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

export const UserTags = () => {
  const {
    field: { value: isRenewal, onChange: setRenewal },
  } = useController({ name: 'isRenewal' });
  const {
    field: { value: isUnqualified, onChange: setUnqualified },
  } = useController({ name: 'isUnqualified' });
  const cls = useStyles();

  const onRenewalClick = () => setRenewal(isRenewal ? false : true);
  const onUnqualifiedClick = () => setUnqualified(isUnqualified ? false : true);

  return (
    <div className={cls.box}>
      <Button className={cls.btn} onClick={onRenewalClick}>
        <Chip
          className={clsx(cls.chip, isRenewal && cls.renewal)}
          label="Eligible for Renewal"
        />
      </Button>
      <Button className={cls.btn} onClick={onUnqualifiedClick}>
        <Chip
          className={clsx(cls.chip, isUnqualified && cls.isUnqualified)}
          label="Unqualified"
        />
      </Button>
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -8,
  },
  btn: {
    padding: '3px 8px',
    textTransform: 'none',
    minWidth: 'unset',
    borderRadius: 15,
  },
  chip: {
    color: 'white',
    backgroundColor: '#AAA',
    fontSize: '14px !important',
    cursor: 'pointer',
  },
  renewal: {
    backgroundColor: '#002A77',
  },
  isUnqualified: {
    backgroundColor: '#DD482E',
  },
});
