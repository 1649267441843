import { ImageInputProps, ImageInput as RaImageInput } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { useCallback, useState, memo } from 'react';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useController } from 'react-hook-form';
import { clsx } from 'clsx';

type InputWithExtraProps = ImageInputProps & {
  width?: number;
  required?: boolean;
  value?: string;
};

const FILE_MAX_BYTE_SIZE = 5 * 1024 * 1024;

export const ImageInput = (props: InputWithExtraProps) => {
  const { required, ...restProps } = props;
  const {
    field: { value, onChange },
  } = useController({ name: props.source });
  const cls = useStyles();

  const changeImage = useCallback(async (file: File | null) => {
    if (file) {
      const base64img = await convertFileToBase64(file);
      onChange(base64img);
    }
  }, [onChange]);
  const onRemove = useCallback(() => void onChange(null), [onChange]);

  return (
    <RaImageInput
      label="Image"
      accept="image/png, image/jpg, image/jpeg"
      maxSize={FILE_MAX_BYTE_SIZE}
      placeholder={<p>Upload Image <span>*File size should not exceed 5MB</span></p>}
      {...restProps}
      format={format}
      className={clsx(cls.field, value ? 'hasPreview' : 'empty')}
      isRequired={required}
      onChange={changeImage}
      onRemove={onRemove}
    >
      <MemoPreviewImage {...props} value={value} />
    </RaImageInput>
  );
};

const PreviewImage = (props: InputWithExtraProps) => {
  const { value, source, width } = props;
  const cls = useStyles();
  const [isZoomed, setIsZoomed] = useState(false);
  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom);
  }, []);

  if (!value) {
    return null;
  }

  return (
    <div onClick={() => setIsZoomed(!isZoomed)} className={clsx(cls.preview, value ? 'hasPreview' : 'empty')}>
      <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
        <img src={value} className={cls.img} style={{ maxWidth: width }} alt={source} />
      </ControlledZoom>
    </div>
  );
};

const MemoPreviewImage = memo(PreviewImage, (prev, next) => prev.value === next.value);

const useStyles = makeStyles({
  field: {
    '& button': {
      top: '-20px !important',
      zIndex: 1000,
    },
  },
  preview: {
    '& [data-rmiz-wrap="visible"]': {
      zIndex: 1,
    },
  },
  img: {
    maxWidth: 300,
    maxHeight: 300,
  },
});

function format(value: any) {
  if (!value || typeof value === 'string') {
    return { url: value };
  } else {
    return value;
  }
}

export const convertFileToBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
  reader.readAsDataURL(file);
});
