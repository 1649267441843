import { Upload, UploadFile } from 'antd';
import { useController } from 'react-hook-form';

import { beforeUpload } from '@utils';
import { convertFileToBase64 } from '@components';
import cls from './manager-avatar.module.css';

const { Dragger } = Upload;

export const ManagerAvatar = () => {
  const {
    field: { value: avatar, onChange: setAvatar },
  } = useController({ name: 'image' });

  const uploadAvatar = async (uploadFile: UploadFile): Promise<any> => {
    if (uploadFile.originFileObj) {
      const base64Img = await convertFileToBase64(uploadFile.originFileObj) as string;
      base64Img && setAvatar(base64Img);
    } else {
      setAvatar(null);
    }
  };

  const onDelete = (e: any) => {
    e.stopPropagation();
    setAvatar(null);
  };

  return (
    <div className={cls.box}>
      <Dragger
        beforeUpload={beforeUpload}
        customRequest={
          async ({ onSuccess }) => setTimeout(() => onSuccess && onSuccess(1), 0)
        }
        maxCount={1}
        onChange={({ file }) => uploadAvatar(file)}
        showUploadList={false}
        className={cls.dragger}
      >
        {avatar ? (
          <>
            <div className={cls.close} onClick={onDelete}>+</div>
            <img src={avatar} alt="avatar" className={cls.img}/>
          </>
        ) : (
          <svg
            width="55"
            height="55"
            viewBox="0 0 55 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M27.5 0C12.3161 0 0 12.3161 0 27.5C0 42.6839 12.3161 55 27.5 55C42.6839 55 55 42.6839 55 27.5C55 12.3161 42.6839 0 27.5 0ZM27.5 13.75C33.6875 13.75 38.7161 18.7786 38.7161 24.9661C38.7161 31.1536 33.6875 36.1821 27.5 36.1821C21.3125 36.1821 16.2839 31.1536 16.2839 24.9661C16.2839 18.7786 21.3125 13.75 27.5 13.75ZM27.5 51.4643C21.9214 51.4643 16.775 49.5393 12.6893 46.3179C14.1429 44.2357 16.5393 42.9786 19.0929 42.9786H35.9071C38.4607 42.9786 40.8571 44.2357 42.3107 46.3179C38.225 49.5393 33.0786 51.4643 27.5 51.4643Z"
              fill="#042E6B"/>
          </svg>
        )}
        <div className={cls.text}>foto de perfil</div>
      </Dragger>
    </div>
  );
};
