import { clsx } from 'clsx';
import { InputNumber } from 'antd';

import cls from './TaxCropTable.module.css';

export type TaxCropRowKeys = 'year' | 'current' | 'emitted' | 'cancelled';

export interface TaxCropHeaderRow {
  key: TaxCropRowKeys;
  label: string;
}

export interface TaxCropRow {
  year: number;
  current: number;
  emitted: number;
  cancelled: number;
}

export interface TaxCropDataProps {
  header: TaxCropHeaderRow[];
  values: TaxCropRow[];
}

interface Props {
  data: TaxCropDataProps;
  onChange: any;
  title: string;
}

interface InputProps {
  row: TaxCropRow;
  field: TaxCropRowKeys;
  onChange: any
}

const Input = ({ row, field, onChange }: InputProps) => {
  return (
    field === 'year'
      ? <span className={cls.text}>{row[field]}</span>
      : <InputNumber
        min={0}
        max={1000000000}
        formatter={(value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value ? +value.replace(/\$\s?|(,*)/g, '') : 0}
        value={row[field]}
        bordered={false}
        onChange={e => onChange(e, field, row.year)}
        className={cls.input}
      />
  );
};

export const TaxCropTable = ({ data, onChange, title }: Props) => {
  const fields: TaxCropRowKeys[] = data.header.map(h => h.key);

  const onFieldChange = (val: number | null, key: TaxCropRowKeys, year: number) => {
    if (val === null) return;
    const newValues = data.values.map(y => y.year === year ? { ...y, [key]: val } : y);
    const newValue = { ...data, values: newValues };
    onChange(newValue);
  };

  if (!data) {
    return null;
  }

  return (
    <div className={cls.block}>
      <div className={cls.table}>
        <div className={cls.header}>
          <div className={cls.headerRow}>
            <div className={cls.headerCol}>{title}</div>
          </div>
        </div>
        <div className={cls.titles}>
          <div className={cls.row}>
            {data.header.map(h => (
              <div className={cls.col} key={h.key}>{h.label}</div>
            ))}
          </div>
        </div>
        <div className={cls.body}>
          {data.values.map(r => (
            <div className={clsx(cls.row, cls.rowColored)} key={r.year}>
              {fields.map(f => (
                <div className={cls.col} key={f}>
                  <Input row={r} field={f} onChange={onFieldChange}/>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
