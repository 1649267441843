import { adminHttp } from '@network';
import { axiosErrorToString } from '@utils';
import { Admin } from '@types';

export const checkSuperadmin = () => {
  const admin = localStorage.getItem('admin');
  if (admin) {
    const { isSuperadmin } = JSON.parse(admin);

    return isSuperadmin;
  }

  return false;
};

export const authProvider = {
  // called when the user attempts to log in
  login: async ({ username, password }: {username: string, password: string}) => {
    try {
      const admin = await adminHttp.login({ username, password });
      localStorage.setItem('admin', JSON.stringify(admin));

      return Promise.resolve();
    } catch (err: any) {
      const errMessage = axiosErrorToString(err);

      return Promise.reject(errMessage);
    }
  },
  // called when the user clicks on the logout button
  logout: async () => {
    localStorage.removeItem('admin');
    adminHttp.logout().catch(() => null);

    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }: {status: number}) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('admin');
      (async () => {
        await adminHttp.logout();
      })();

      return Promise.reject();
    }

    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    if (window.location.hash === '#/login') {
      return Promise.reject();
    }

    let nextAdmin: Admin;
    try {
      nextAdmin = await adminHttp.me();
    } catch {
      return Promise.reject();
    }
    const admin = localStorage.getItem('admin');

    if (admin) {
      const { isSuperadmin, permissionKeys } = JSON.parse(admin);
      localStorage.setItem('admin', JSON.stringify(nextAdmin));
      // reload window if Admin permission is updated
      const samePerm = JSON.stringify(permissionKeys) === JSON.stringify(nextAdmin.permissionKeys);
      if (isSuperadmin !== nextAdmin.isSuperadmin || !samePerm) {
        window.location.reload();
      }
    } else {
      localStorage.setItem('admin', JSON.stringify(nextAdmin));
    }

    return Promise.resolve();
  },
  getIdentity: () => {
    const admin = localStorage.getItem('admin');
    if (admin) {
      const { id, name: fullName, avatar } = JSON.parse(admin);

      return Promise.resolve({ id, fullName, avatar });
    }

    return Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const admin = localStorage.getItem('admin');
    if (admin) {
      const { isSuperadmin } = JSON.parse(admin);
      if (isSuperadmin) {
        return Promise.resolve('isSuperadmin');
      }
    }

    return Promise.resolve();
  },
};
