import { DatePicker } from 'antd';
import moment from 'moment-timezone';

import cls from './input-date.module.css';

interface InputDateProps {
  name: string;
  label: string;
  onChange: any;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  labelWidth?: number;
  withoutLabel?: boolean;
  error?: string;
}

export const InputDate = ({
  name, label, value, labelWidth, withoutLabel, onChange, placeholder, error, disabled,
}: InputDateProps) => {
  moment.locale('en');

  return (
    <div className={cls._}>
      {!withoutLabel && (
        <label
          className={cls.label}
          htmlFor={name}
          style={{ minWidth: labelWidth ? labelWidth : 100 }}
        >
          {label}
        </label>
      )}
      <div className={cls.content}>
        <DatePicker
          allowClear={false}
          className={cls.date}
          onChange={onChange}
          defaultValue={moment(value)}
          placeholder={placeholder}
          disabled={disabled}
          value={moment(value)}
        />
        {error && (
          <span className={cls.error}>{error}</span>
        )}
      </div>
    </div>
  );
};
