import { ListProps, TextField } from 'react-admin';
import {
  BooleanField,
  ImageField,
  ManyField,
  TextInput,
  Grid,
  GridList,
  Forbidden,
} from '@components';
import { useRbacList } from '@hooks';

export const BankList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'id nameMx doctypes image isEnabled'.split(' ')}
      >
        <TextField source="id" />
        <TextField source="nameMx" />
        <TextField source="nameEn" />
        <ManyField source="doctypes" reference="doctype" />
        <ImageField source="image" />
        <BooleanField source="isEnabled" label="Enabled" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
];
