import { useController } from 'react-hook-form';
import { Input } from 'antd';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

interface Props {
  source: string;
  label?: string | false;
  placeholder?: string;
  className?: string;
  styled?: boolean;
  autoFocus?: boolean;
  itemRef?: any;
}

export const TextAreaInput = ({
  source, label, placeholder, className, styled, autoFocus, itemRef,
}: Props) => {
  const {
    field: { value, onChange },
  } = useController({ name: source });
  const cls = useStyles();

  const handleChange = (e: any) => {
    onChange(e.target.value);
  };

  return (
    <>
      {label && <InputLabel className={clsx(styled && cls.styledLabel)}>{label}</InputLabel>}
      <Input.TextArea
        ref={itemRef}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={clsx(className, styled && cls.styledTextArea)}
        autoFocus={autoFocus}
      />
    </>
  );
};

const useStyles = makeStyles({
  styledLabel: {
    color: '#042E6B',
    fontFamily: 'Outfit, sans-serif',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 1,
    paddingBottom: 12,
  },
  styledTextArea: {
    borderRadius: 8,
    border: '1px solid #002A77',

    '> div::before': {
      display: 'none',
    },
    '& input': {
      borderRadius: 8,
      border: '1px solid #002A77',
      height: 90,
      padding: '6px 12px',
    },
  },
});
