export interface IFile {
  adminUrl: string;
  id: number;
  url: string;
}

export interface CreditTransaction {
  id: number;
  title: string;
  type: 'income' | 'outcome';
  createdAt: string;
  amount: number;
  partnerClass: CreditTransactionPartner;
  partnerId?: number;
  balanceBefore: number;
  balanceAfter: number;
  adminName?: string;
  adminId?: number;
  file: IFile | null;
  creditId: number;
  isRollback: boolean;
  rollbackTransactionId: number | null;
}

export enum CreditTransactionPartner {
  BANK_APPROVAL = 'bank_approval', // +
  FARMER_DEPOSIT = 'farmer_deposit', // +
  MEMBERSHIP = 'membership', // -
  CREDIT_FEE = 'credit_fee', // -
  INTEREST = 'interest', // -+
  INSURANCE = 'insurance', // -
  REFUNDS = 'refunds', // -
  MANUAL = 'manual', // -
  RETENTION = 'retention', // +
  REPAYMENT = 'repayment', // -
  ORDER = 'Order', // -
  GUARANTEE = 'guarantee', // @deprecated
  SEEDS = 'seeds', // @deprecated
}

export const creditPartnerMap = {
  [CreditTransactionPartner.BANK_APPROVAL]: 'Credit',
  [CreditTransactionPartner.FARMER_DEPOSIT]: 'Farmer deposit',
  [CreditTransactionPartner.MEMBERSHIP]: 'Membership',
  [CreditTransactionPartner.CREDIT_FEE]: 'Withdrawal fee',
  [CreditTransactionPartner.INTEREST]: 'Interest',
  [CreditTransactionPartner.INSURANCE]: 'Insurance',
  [CreditTransactionPartner.REFUNDS]: 'Refunds',
  [CreditTransactionPartner.MANUAL]: 'External',
  [CreditTransactionPartner.REPAYMENT]: 'Repayment',
  [CreditTransactionPartner.RETENTION]: 'Retention',
  [CreditTransactionPartner.ORDER]: 'Order',
  [CreditTransactionPartner.GUARANTEE]: 'Guarantee', // @deprecated
  [CreditTransactionPartner.SEEDS]: 'Seeds', // @deprecated
};
