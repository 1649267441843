import { AnswerCol, SurveyQuestion } from '@types';
import { moneyFormatter, numberFormatter } from '@utils';
import { InitialCard } from '@components';
import { NoIcon, YesIcon } from './credit-report';
import cls from './credit-report.module.css';

interface CreditReportSurveyQuestionProps {
  question: SurveyQuestion;
}
interface CreditReportSurveyProps {
  questions: SurveyQuestion[];
  checkSurveyAnswers: (value: boolean) => void;
}

const CreditReportSurveyQuestion = ({ question }: CreditReportSurveyQuestionProps) => {
  if (!question) {
    return null;
  }

  const [answerRows] = question.rows || [];
  const { cols: answers } = answerRows || {};
  const filledAnswers = answers?.filter((i: AnswerCol) => !!i.customAnswer);
  const isValid = filledAnswers?.length === answers?.length && filledAnswers?.length > 0;

  const renderAnswer = (answer: AnswerCol) => {
    if (answer.answerType === 'number') {
      return numberFormatter.format(+(answer?.customAnswer || 0));
    } else if (answer.answerType === 'money') {
      return moneyFormatter.format(+(answer?.customAnswer || 0));
    } else {
      return answer.customAnswer;
    }
  };

  return (
    <div className={cls.question}>
      <div className={cls.questionHead}>
        <div className={cls.questionNum}>{question.position}</div>
        <div className={cls.questionName}>{question.nameMx}</div>
        <div className={cls.questionIcon}>
          {isValid ? <YesIcon /> : <NoIcon />}
        </div>
      </div>
      <div className={cls.answers}>
        {answers?.map((answer: any) => (
          <div className={cls.answer} key={answer.answerId}>
            <div className={cls.answerLabel}>{answer.nameMx}</div>
            <div className={cls.answerValue}>
              {renderAnswer(answer)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const CreditReportSurvey = ({ questions }: CreditReportSurveyProps) => {
  return (
    <InitialCard left="History" extraPadding>
      <div className={cls.description}>
        Check the performance and projection questions.
      </div>
      <div className={cls.questions}>
        {questions.map(question => {
          return (
            <CreditReportSurveyQuestion key={question.nameMx} question={question} />
          );
        })}
      </div>
    </InitialCard>
  );
};
