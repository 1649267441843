export interface RiskProps {
  history: any[];
  id: number;
  successPercent: number;
}

export type RiskAdminAnswer = {
  id?: number;
  title: string;
  progressPercent?: number;
}
export interface RiskAdminAnswerWithPosition extends RiskAdminAnswer {
  position: number;
}

export type RiskAdminCategory = {
  id: number;
  title: string;
}

export enum RiskQuestionType {
  SHORT_ANSWER = 'short_answer',
  LONG_ANSWER = 'long_answer',
  MONEY = 'money',
  PERCENTAGE = 'percentage',
  SINGLE_CHOICE = 'single_choice',
}

export enum RiskQuestionTypeTitles {
  SHORT_ANSWER = 'Short Answer',
  LONG_ANSWER = 'Long Answer',
  MONEY = 'Money',
  PERCENTAGE = 'Percentage',
  SINGLE_CHOICE = 'Single Choice',
}

interface QuestionItem extends RiskAdminQuestion {
  idx: number;
}

export interface QuestionItemShort {
  idx: number;
  type: RiskQuestionType;
  title: string;
  titleMx: string;
  position: number;
  isFixed: boolean;
  answers: RiskAdminAnswer[] | undefined;
}

export type QuestionItemType = QuestionItem | QuestionItemShort;

export interface RiskAdminQuestion {
  answers: RiskAdminAnswerWithPosition[] | undefined;
  category: RiskAdminCategory | null;
  categoryId: number | null;
  division: string;
  id: number;
  isEnabled: boolean;
  isFixed: boolean;
  position: number;
  progressPercent: number | null;
  tag: string;
  title: string;
  titleMx: string;
  type: RiskQuestionType;
}

export interface RiskAdminDashboardScore {
  categoryId: number | null;
  categoryTitle: string;
  totalPercent: number;
  totalRecords: number;
}

export interface RiskAdminDashboard {
  successPercent: number;
  termTitles: string[];
  score: RiskAdminDashboardScore[];
  totalPercent: number;
  otherTitles: RiskAdminDashboardScore[];
}
