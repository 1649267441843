import { FunctionField, FunctionFieldProps } from 'react-admin';
import { makeStyles } from '@mui/styles';
import LaunchIcon from '@mui/icons-material/Launch';

import { resolvePath } from './resolve-path';
import { FormLabel } from '@mui/material';

export const ExternalUrlField = (props: Partial<FunctionFieldProps>) => (
  <FunctionField
    {...props}
    render={(record: any) => (
      <ExternalUrl url={resolvePath(record, props.source || '')} label={props.label} />
    )}
  />
);

interface Props {
  url: string;
  label?: string | any;
}

const ExternalUrl = ({ url, label }: Props) => {
  const classes = useStyles();

  if (!url) {
    return null;
  }

  const href = url.includes('http://') || url.includes('https://') ? url : `https://${url}`;

  return (
    <>
      {label && <div><FormLabel>{label}</FormLabel></div>}
      <a
        href={href}
        className={classes.link}
        target="_blank"
        rel="noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {url}
        <LaunchIcon className={classes.icon} />
      </a>
    </>
  );
};

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
  },
  icon: {
    width: '0.5em',
    height: '0.5em',
    paddingLeft: 2,
  },
});
