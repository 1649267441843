import { clsx } from 'clsx';
import { phoneFormatter } from '@utils';
import { makeStyles } from '@mui/styles';
import MaskedInput from 'antd-mask-input';
import { TextInputProps } from 'react-admin';
import { useController } from 'react-hook-form';
import React, { SyntheticEvent } from 'react';

interface Props {
  valueFormat?: 'masked' | 'unmasked';
}

export const PhoneMaskedInput = (props: TextInputProps & Props) => {
  const cls = useStyles();
  const [error, setError] = React.useState(false);
  const {
    field: { value, onChange },
  } = useController({ name: props.source });

  const handleChange = (
    e: SyntheticEvent & { maskedValue: string; unmaskedValue: string },
  ): any => {
    if (e.unmaskedValue.length === 10) {
      const result = props.valueFormat === 'masked' || !props.valueFormat
        ? e.maskedValue
        : `+52${e.unmaskedValue}`;
      onChange(result);
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <label className={cls.label}>
        <span>{props.label}</span>
      </label>
      <div className={clsx('MuiInputBase-root', cls.box)}>
        <MaskedInput
          mask={'+52 (000) 000-0000'}
          className={clsx(cls.inputField, error && cls.error)}
          value={phoneFormatter.format(value)}
          onChange={handleChange}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  label: {
    display: 'block',
    color: '#042E6B',
    fontSize: 12,
    lineHeight: 1,
    marginTop: 4,
    marginLeft: 14,
    marginBottom: 3,
  },
  box: {
    overflow: 'hidden',
  },
  inputField: {
    fontSize: 16,
    padding: 0,
    border: 0,
    borderBottom: '1px solid rgba(0, 42, 119, 0.10) !important',
    boxShadow: 'none',
    background: 'none',
    '&[disabled]': {
      background: 'none',
      color: '#000',
    },
  },
  error: {
    borderBottom: '1px solid red !important',
  },
});
