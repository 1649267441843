export const VerqorLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'inline-block' }}
    width="113" height="39" viewBox="0 0 113 39" fill="none">
    <path d="M14.5129 35.1243L12.9451 32.1483C12.4847 31.6234 12.0062 31.1141 11.5071 30.6239C11.2359 30.3569 10.9556 30.0989 10.673 29.8432C10.3756 29.5729 10.0703 29.3104 9.76148 29.0524C8.21185 27.7579 6.52321 26.6194 4.72062 25.6625C3.91846 25.2374 3.09351 24.847 2.24805 24.4971C4.31156 29.0075 7.52591 32.8943 11.5219 35.7995C12.838 36.7126 14.2406 37.5124 15.7127 38.191C15.7219 38.1955 15.731 38.2 15.7401 38.2034C15.7412 38.2034 15.7424 38.2034 15.7435 38.2045C15.9121 38.2819 16.0831 38.3571 16.254 38.4311L14.5129 35.1254V35.1243Z"
      fill="#A5C4E0" />
    <path d="M16.7386 38.6992H16.897C16.8298 38.6723 16.7625 38.6443 16.6953 38.6162L16.7386 38.6992Z"
      fill="white" />
    <path d="M26.8839 28.3269C26.5762 28.5636 26.2731 28.8036 25.9757 29.0515C25.6669 29.3107 25.3627 29.572 25.0653 29.8424C24.7827 30.0981 24.5024 30.3572 24.2313 30.6231C23.7322 31.1133 23.2548 31.6225 22.7933 32.1475L21.2254 35.1234L19.4844 38.4291C19.6553 38.3551 19.8251 38.2799 19.9948 38.2026C19.996 38.2026 19.9971 38.2025 19.9983 38.2014C20.0074 38.1969 20.0165 38.1925 20.0256 38.1891C21.4978 37.5116 22.9004 36.7107 24.2164 35.7976C28.2136 32.8923 31.4279 29.0056 33.4903 24.4951C32.6448 24.8462 31.8199 25.2355 31.0177 25.6606C29.9524 26.2259 28.928 26.8575 27.947 27.5451C27.6222 27.7728 27.302 28.0072 26.9876 28.2473C26.9534 28.2731 26.9203 28.3 26.8861 28.3258L26.8839 28.3269Z"
      fill="#A5C4E0" />
    <path d="M18.8398 38.6992H18.9982L19.0415 38.6162C18.9743 38.6443 18.9071 38.6712 18.8398 38.6992Z"
      fill="white" />
    <path d="M16.8963 38.7H16.7379L16.6946 38.617L15.2053 35.7903L13.8756 33.2664L2.96782 12.5583L2.96554 12.5549C2.8892 12.5179 2.81286 12.482 2.73651 12.4461C2.66131 12.4102 2.58497 12.3754 2.50977 12.3418L2.51546 12.353L12.9436 32.1492L14.5114 35.1251L16.2525 38.4308L16.51 38.9187L16.6456 38.9995H17.8545V38.7H16.8951H16.8963Z"
      fill="#014874" />
    <path d="M2.51652 12.3523C1.70068 11.9855 1.17198 11.7757 1.0683 11.7006C0.227394 11.1004 0.332222 10.7011 0.122566 11.6007C0.089522 11.7421 0.0667333 11.8924 0.0507812 12.0438C0.312851 18.0989 3.22183 23.556 7.79096 27.546C8.46892 28.0216 9.12752 28.523 9.76104 29.0525C9.87499 29.1478 9.98665 29.2443 10.0995 29.3396C10.2932 29.5056 10.4846 29.6716 10.6726 29.8433C10.9552 30.099 11.2355 30.3581 11.5067 30.624C12.0057 31.1142 12.4832 31.6234 12.9446 32.1484L12.1345 30.6105L2.51652 12.3523Z"
      fill="#014874" />
    <path d="M26.3607 16.2757C25.3523 17.0048 24.3257 17.8315 23.3276 18.7615C22.7692 19.2819 22.22 19.8338 21.6879 20.4205C20.4163 22.0492 19.2074 23.9427 18.1374 26.1558C18.0508 26.3353 17.9642 26.5159 17.8799 26.6999L17.8583 26.5933C17.8583 26.5933 17.856 26.5978 17.8549 26.5989V26.6988C17.7705 26.5148 17.6839 26.3342 17.5973 26.1547C16.5286 23.9416 15.3185 22.0481 14.0469 20.4194C13.5148 19.8327 12.9655 19.2797 12.4072 18.7603C11.4079 17.8293 10.3824 17.0037 9.37405 16.2746C9.29884 16.2163 9.22364 16.1579 9.14844 16.0996L9.16211 16.1232L13.4977 23.8227L17.8332 31.5222H17.856V31.4414L17.8811 31.5222H17.9039L22.2394 23.8227L26.5749 16.1232L26.5818 16.1119L26.5886 16.1007C26.5123 16.1591 26.4371 16.2174 26.363 16.2757H26.3607Z"
      fill="#014874" />
    <path d="M13.498 23.8232L17.8336 31.5216H17.8564H17.8336L13.498 23.8232Z" fill="#014874" />
    <path d="M26.5875 16.1006L26.5801 16.1126L26.5875 16.1006Z" fill="#014874" />
    <path d="M22.2392 23.8232L17.9036 31.5216H17.8809H17.9036L22.2392 23.8232Z" fill="#014874" />
    <path d="M35.6865 12.0438C35.6705 11.8935 35.6477 11.7421 35.6147 11.6007C35.405 10.7011 35.5099 11.1004 34.669 11.7006C34.5653 11.7746 34.0377 11.9855 33.2207 12.3523L33.2264 12.3411C33.1512 12.3758 33.0749 12.4106 32.9997 12.4454C32.9233 12.4813 32.847 12.5172 32.7707 12.5542V12.5576L27.3162 22.9122L21.8617 33.2668L20.532 35.7906L19.0428 38.6174L18.9995 38.7004H17.8828V38.9999H19.0918L19.2273 38.9191L19.4849 38.4312L21.2259 35.1255L22.7938 32.1495C23.2541 31.6246 23.7327 31.1153 24.2317 30.6251C24.5029 30.3581 24.7832 30.1001 25.0658 29.8444C25.2538 29.6739 25.4452 29.5068 25.6389 29.3407C25.7506 29.2443 25.8634 29.1489 25.9762 29.0536C26.2736 28.8046 26.5767 28.5645 26.8843 28.3278C26.9185 28.302 26.9516 28.2751 26.9858 28.2493C27.3002 28.0093 27.6204 27.7748 27.9452 27.5471C32.5143 23.5571 35.4233 18.1 35.6853 12.0449L35.6865 12.0438Z"
      fill="#014874" />
    <path d="M27.9438 27.5459C27.619 27.7736 27.2989 28.008 26.9844 28.2481C27.3 28.008 27.619 27.7736 27.9438 27.5459Z"
      fill="#014874" />
    <path d="M26.8827 28.3271C26.5751 28.5638 26.272 28.8039 25.9746 29.0518C26.272 28.8039 26.5751 28.5627 26.8827 28.3271Z"
      fill="#014874" />
    <path d="M21.8594 33.2667L27.3138 22.9121L21.8594 33.2667Z" fill="#014874" />
    <path d="M20.5293 35.7915L21.859 33.2676L20.5293 35.7915Z" fill="#014874" />
    <path d="M17.8286 26.6422L17.8582 26.5895C17.8582 26.5895 17.857 26.5873 17.8559 26.5862V26.5794L17.8582 26.5895L17.9015 26.6557C17.9801 26.4874 18.0587 26.3203 18.1385 26.1554C19.2073 23.9423 20.4173 22.0488 21.689 20.4201C23.3628 18.2764 25.1437 16.5927 26.8552 15.2422C31.3149 11.7245 35.2972 10.4771 35.6561 9.30044C36.0025 8.16525 35.1696 5.51575 34.3857 3.4372C34.192 2.8124 33.571 0.739462 33.4217 0.201035C33.4001 0.122515 33.3203 0.00136906 33.2189 0.101202C33.1277 0.19094 32.7665 0.498291 32.6093 0.60149C32.3985 0.740583 31.9689 0.951467 31.6066 1.11748C30.5367 1.53701 28.1564 2.64414 25.0936 5.30038C22.8774 7.22301 21.0167 10.0621 19.7417 12.3482C19.0375 13.5181 18.4142 14.7419 17.8821 16.0117L17.857 16.0913V16.0117C17.3249 14.7419 16.7017 13.5181 15.9975 12.3482C14.7213 10.0632 12.8618 7.22301 10.6456 5.30038C7.58162 2.64527 5.2002 1.53701 4.13141 1.1186C3.76907 0.952588 3.3395 0.740583 3.12871 0.602611C2.97147 0.499413 2.61141 0.193183 2.51911 0.102324C2.4177 0.00249078 2.33794 0.123637 2.31629 0.202157C2.16703 0.740583 1.54603 2.8124 1.35233 3.43832C0.56726 5.51687 -0.265667 8.16638 0.0807215 9.30156C0.439643 10.4782 4.42311 11.7256 8.88171 15.2433C10.5931 16.5927 12.3741 18.2776 14.0479 20.4212C15.3195 22.0499 16.5285 23.9434 17.5984 26.1565C17.6759 26.3169 17.7522 26.4785 17.8286 26.6422Z"
      fill="#4D748E" />
    <path d="M17.8574 26.5902L17.8278 26.6429C17.7515 26.4792 17.6751 26.3176 17.5977 26.1572C17.6843 26.3367 17.7709 26.5173 17.8552 26.7013V26.6014C17.8552 26.6014 17.8574 26.5969 17.8586 26.5958L17.8802 26.7024C17.9646 26.5184 18.0512 26.3378 18.1377 26.1583C18.058 26.3232 17.9794 26.4904 17.9007 26.6586L17.8574 26.5925V26.5902Z"
      fill="#4D748E" />
    <path d="M32.6282 12.6238C32.1816 12.8392 31.7452 13.0512 31.319 13.2621C29.9551 13.9564 28.2961 14.8986 26.5744 16.1236L22.2389 23.8231L17.9033 31.5226H17.8805V31.6224L17.8555 31.5708V31.5226H17.8327L13.4971 23.8231L9.16158 16.1236C7.43989 14.8986 5.78087 13.9564 4.41697 13.2621C3.99082 13.0512 3.98057 13.0175 3.53391 12.8022C3.48719 12.7797 3.01433 12.5801 2.96875 12.5587L13.8777 33.2668L15.2074 35.7907L16.6967 38.6174C16.7639 38.6455 16.8311 38.6724 16.8983 38.7004H17.8577L17.8828 38.604V38.7004H18.8422C18.9094 38.6735 18.9767 38.6455 19.0439 38.6174L20.5331 35.7907L21.8629 33.2668L27.3173 22.9122L32.7718 12.5576C32.7262 12.5789 32.6784 12.6014 32.6317 12.6227L32.6282 12.6238Z"
      fill="white" />
    <path d="M14.5129 35.0618L12.9451 32.0858C12.4847 31.5609 12.0062 31.0516 11.5071 30.5614C11.2359 30.2944 10.9556 30.0364 10.673 29.7807C10.3756 29.5104 10.0703 29.2479 9.76148 28.9899C8.21185 27.6954 6.52321 26.5569 4.72062 25.6C3.91846 25.1749 3.09351 24.7845 2.24805 24.4346C4.31156 28.945 7.52591 32.8318 11.5219 35.737C12.838 36.6501 14.2406 37.4499 15.7127 38.1285C15.7219 38.133 15.731 38.1375 15.7401 38.1409C15.7412 38.1409 15.7424 38.1409 15.7435 38.142C15.9121 38.2194 16.0831 38.2946 16.254 38.3686L14.5129 35.0629V35.0618Z"
      fill="#EBAD78" />
    <path d="M16.7386 38.6367H16.897C16.8298 38.6098 16.7625 38.5818 16.6953 38.5537L16.7386 38.6367Z"
      fill="white" />
    <path d="M26.8839 28.2634C26.5762 28.5001 26.2731 28.7402 25.9757 28.9881C25.6669 29.2472 25.3627 29.5086 25.0653 29.7789C24.7827 30.0346 24.5024 30.2938 24.2313 30.5596C23.7322 31.0498 23.2548 31.5591 22.7933 32.084L21.2254 35.0599L19.4844 38.3657C19.6553 38.2916 19.8251 38.2165 19.9948 38.1391C19.996 38.1391 19.9971 38.1391 19.9983 38.1379C20.0074 38.1335 20.0165 38.129 20.0256 38.1256C21.4978 37.4481 22.9004 36.6472 24.2164 35.7341C28.2136 32.8288 31.4279 28.9421 33.4903 24.4316C32.6448 24.7827 31.8199 25.172 31.0177 25.5971C29.9524 26.1625 28.928 26.794 27.947 27.4816C27.6222 27.7093 27.302 27.9437 26.9876 28.1838C26.9534 28.2096 26.9203 28.2365 26.8861 28.2623L26.8839 28.2634Z"
      fill="#EBAD78" />
    <path d="M18.8398 38.6367H18.9982L19.0415 38.5537C18.9743 38.5818 18.9071 38.6087 18.8398 38.6367Z"
      fill="white" />
    <path d="M16.8963 38.6365H16.7379L16.6946 38.5535L15.2053 35.7268L13.8756 33.2029L2.96782 12.4948L2.96554 12.4914C2.8892 12.4544 2.81286 12.4185 2.73651 12.3826C2.66131 12.3467 2.58497 12.312 2.50977 12.2783L2.51546 12.2895L12.9436 32.0857L14.5114 35.0616L16.2525 38.3673L16.51 38.8553L16.6456 38.936H17.8545V38.6365H16.8951H16.8963Z"
      fill="#CC5E16" />
    <path d="M2.51652 12.2888C1.70068 11.922 1.17198 11.7122 1.0683 11.6371C0.227394 11.037 0.332222 10.6376 0.122566 11.5372C0.089522 11.6786 0.0667333 11.8289 0.0507812 11.9803C0.312851 18.0354 3.22183 23.4926 7.79096 27.4825C8.46892 27.9581 9.12752 28.4595 9.76104 28.989C9.87499 29.0843 9.98665 29.1808 10.0995 29.2761C10.2932 29.4422 10.4846 29.6082 10.6726 29.7798C10.9552 30.0356 11.2355 30.2947 11.5067 30.5605C12.0057 31.0507 12.4832 31.56 12.9446 32.0849L12.1345 30.5471L2.51652 12.2888Z"
      fill="#CC5E16" />
    <path d="M26.3607 16.2113C25.3523 16.9404 24.3257 17.7671 23.3276 18.697C22.7692 19.2175 22.22 19.7694 21.6879 20.356C20.4163 21.9848 19.2074 23.8782 18.1374 26.0914C18.0508 26.2709 17.9642 26.4515 17.8799 26.6354L17.8583 26.5289C17.8583 26.5289 17.856 26.5333 17.8549 26.5345V26.6343C17.7705 26.4503 17.6839 26.2697 17.5973 26.0903C16.5286 23.8771 15.3185 21.9836 14.0469 20.3549C13.5148 19.7682 12.9655 19.2152 12.4072 18.6959C11.4079 17.7649 10.3824 16.9393 9.37405 16.2101C9.29884 16.1518 9.22364 16.0935 9.14844 16.0352L9.16211 16.0587L13.4977 23.7582L17.8332 31.4577H17.856V31.3769L17.8811 31.4577H17.9039L22.2394 23.7582L26.5749 16.0587L26.5818 16.0475L26.5886 16.0363C26.5123 16.0946 26.4371 16.1529 26.363 16.2113H26.3607Z"
      fill="#CC5E16" />
    <path d="M13.498 23.7598L17.8336 31.4593H17.8564H17.8336L13.498 23.7598Z" fill="#A5C4E0" />
    <path d="M26.5875 16.0361L26.5801 16.0494L26.5875 16.0361Z" fill="#A5C4E0" />
    <path d="M22.2392 23.7598L17.9036 31.4593H17.8809H17.9036L22.2392 23.7598Z" fill="#A5C4E0" />
    <path d="M35.6865 11.9803C35.6705 11.83 35.6477 11.6786 35.6147 11.5372C35.405 10.6376 35.5099 11.037 34.669 11.6371C34.5653 11.7111 34.0377 11.922 33.2207 12.2888L33.2264 12.2776C33.1512 12.3124 33.0749 12.3471 32.9997 12.3819C32.9233 12.4178 32.847 12.4537 32.7707 12.4907V12.4941L27.3162 22.8487L21.8617 33.2033L20.532 35.7272L19.0428 38.5539L18.9995 38.6369H17.8828V38.9364H19.0918L19.2273 38.8556L19.4849 38.3677L21.2259 35.062L22.7938 32.0861C23.2541 31.5611 23.7327 31.0518 24.2317 30.5616C24.5029 30.2947 24.7832 30.0367 25.0658 29.7809C25.2538 29.6104 25.4452 29.4433 25.6389 29.2773C25.7506 29.1808 25.8634 29.0855 25.9762 28.9901C26.2736 28.7411 26.5767 28.501 26.8843 28.2644C26.9185 28.2386 26.9516 28.2116 26.9858 28.1858C27.3002 27.9458 27.6204 27.7113 27.9452 27.4836C32.5143 23.4937 35.4233 18.0365 35.6853 11.9814L35.6865 11.9803Z"
      fill="#CC5E16" />
    <path d="M27.9438 27.4824C27.619 27.7101 27.2989 27.9446 26.9844 28.1846C27.3 27.9446 27.619 27.7101 27.9438 27.4824Z"
      fill="#A5C4E0" />
    <path d="M26.8827 28.2627C26.5751 28.4994 26.272 28.7394 25.9746 28.9873C26.272 28.7394 26.5751 28.4983 26.8827 28.2627Z"
      fill="#A5C4E0" />
    <path d="M21.8594 33.2032L27.3138 22.8486L21.8594 33.2032Z" fill="#A5C4E0" />
    <path d="M20.5293 35.727L21.859 33.2031L20.5293 35.727Z" fill="#A5C4E0" />
    <path d="M17.8574 26.5267L17.8278 26.5795C17.7515 26.4157 17.6751 26.2542 17.5977 26.0938C17.6843 26.2732 17.7709 26.4538 17.8552 26.6378V26.538C17.8552 26.538 17.8574 26.5335 17.8586 26.5323L17.8802 26.6389C17.9646 26.4549 18.0512 26.2743 18.1377 26.0949C18.058 26.2598 17.9794 26.4269 17.9007 26.5952L17.8574 26.529V26.5267Z"
      fill="#4D748E" />
    <path d="M17.8286 26.5797L17.8582 26.527C17.8582 26.527 17.857 26.5248 17.8559 26.5237V26.5169L17.8582 26.527L17.9015 26.5932C17.9801 26.4249 18.0587 26.2578 18.1385 26.0929C19.2073 23.8798 20.4173 21.9863 21.689 20.3576C23.3628 18.2139 25.1437 16.5302 26.8552 15.1797C31.3149 11.662 35.2972 10.4146 35.6561 9.23794C36.0025 8.10275 35.1696 5.45325 34.3857 3.3747C34.192 2.7499 33.571 0.676962 33.4217 0.138535C33.4001 0.0600149 33.3203 -0.0611309 33.2189 0.0387022C33.1277 0.12844 32.7665 0.435791 32.6093 0.53899C32.3985 0.678083 31.9689 0.888967 31.6066 1.05498C30.5367 1.47451 28.1564 2.58164 25.0936 5.23788C22.8774 7.16051 21.0167 9.99959 19.7417 12.2857C19.0375 13.4556 18.4142 14.6794 17.8821 15.9492L17.857 16.0288V15.9492C17.3249 14.6794 16.7017 13.4556 15.9975 12.2857C14.7213 10.0007 12.8618 7.16051 10.6456 5.23788C7.58162 2.58164 5.2002 1.47338 4.13141 1.05498C3.76907 0.888967 3.3395 0.676961 3.12871 0.53899C2.97147 0.435791 2.61141 0.129562 2.51911 0.0387022C2.4177 -0.0611309 2.33794 0.0600149 2.31629 0.138535C2.16703 0.676962 1.54603 2.74878 1.35233 3.3747C0.56726 5.45325 -0.265667 8.10275 0.0807215 9.23794C0.439643 10.4146 4.42311 11.662 8.88171 15.1797C10.5931 16.5291 12.3741 18.2139 14.0479 20.3576C15.3195 21.9863 16.5285 23.8798 17.5984 26.0929C17.6759 26.2533 17.7522 26.4149 17.8286 26.5786V26.5797Z"
      fill="#F07F11" />
    <path d="M32.6282 12.5603C32.1816 12.7757 31.7452 12.9877 31.319 13.1986C29.9551 13.8929 28.2961 14.8352 26.5744 16.0601L22.2389 23.7596L17.9033 31.4591H17.8805V31.5589L17.8555 31.5073V31.4591H17.8327L13.4971 23.7596L9.16158 16.0601C7.43989 14.8352 5.78087 13.8929 4.41697 13.1986C3.99082 12.9877 3.98057 12.954 3.53391 12.7387C3.48719 12.7162 3.01433 12.5166 2.96875 12.4953L13.8777 33.2034L15.2074 35.7272L16.6967 38.554C16.7639 38.582 16.8311 38.6089 16.8983 38.637H17.8577L17.8828 38.5405V38.637H18.8422C18.9094 38.6101 18.9767 38.582 19.0439 38.554L20.5331 35.7272L21.8629 33.2034L27.3173 22.8487L32.7718 12.4941C32.7262 12.5155 32.6784 12.5379 32.6317 12.5592L32.6282 12.5603Z"
      fill="white" />
    <path d="M63.1204 23.4004V20.623H62.4846C60.4883 20.623 58.876 21.2546 57.7241 22.4683V20.8451H54.8379V37.9996V38.0636H57.7241V37.9996V28.5951C57.7241 23.8345 61.368 23.4644 62.4846 23.4644H63.1204V23.4004Z"
      fill="#233567" />
    <path d="M43.7148 20.4951C38.4973 20.4951 34.7109 24.2495 34.7109 29.4229C34.7109 29.433 34.7109 29.4442 34.7109 29.4543C34.7109 29.4655 34.7109 29.4756 34.7109 29.4868C34.7109 34.6591 38.552 38.4135 43.8435 38.4135C47.149 38.4135 49.9144 36.9934 51.431 34.5166L51.7626 33.976L51.7341 33.9591L51.7626 33.912L49.2239 32.4358L48.9083 33.0034C48.0138 34.612 46.1326 35.5722 43.8754 35.5722C40.775 35.5722 38.4152 33.7393 37.7851 30.9383H52.2981V30.8744V30.3124C52.2981 30.1991 52.3061 30.0847 52.3129 29.9703C52.322 29.8301 52.3312 29.6899 52.3312 29.5496C52.3312 29.5384 52.3312 29.5272 52.3312 29.5149C52.3312 29.5048 52.3312 29.4958 52.3312 29.4857C52.3312 25.1278 49.3117 20.4951 43.7148 20.4951ZM37.7692 28.0656C38.3457 25.2075 40.6508 23.3364 43.7148 23.3364C46.1098 23.3364 48.6588 24.807 49.2763 28.0656H37.7703H37.7692Z"
      fill="#233567" />
    <path d="M93.0154 20.4951C87.8936 20.4951 83.8828 24.4167 83.8828 29.4229C83.8828 29.433 83.8828 29.4431 83.8828 29.4543C83.8828 29.4655 83.8828 29.4756 83.8828 29.4868C83.8828 34.4931 87.8948 38.4135 93.0154 38.4135C98.136 38.4135 102.116 34.492 102.116 29.4868C102.116 29.4767 102.116 29.4666 102.116 29.4565C102.116 29.4453 102.116 29.4341 102.116 29.4229C102.116 24.4167 98.1189 20.4951 93.0154 20.4951ZM93.0154 35.5722C89.5412 35.5722 86.818 32.8868 86.8021 29.4532C86.8191 26.0207 89.5424 23.3364 93.0154 23.3364C96.4884 23.3364 99.1809 26.0218 99.1968 29.4554C99.1797 32.8879 96.4713 35.5722 93.0154 35.5722Z"
      fill="#233567" />
    <path d="M72.1931 20.4953C67.0714 20.4953 63.0605 24.4168 63.0605 29.423C63.0605 29.4331 63.0605 29.4432 63.0605 29.4533C63.0605 29.4645 63.0605 29.4746 63.0605 29.4859C63.0605 34.4921 67.0725 38.4125 72.1931 38.4125C74.1039 38.4125 75.8598 37.8629 77.3092 36.9117L78.3552 38.4125H81.2938L81.2493 38.3486H81.2938L79.1596 35.2852C80.495 33.7349 81.2938 31.7192 81.2938 29.4859C81.2938 29.4758 81.2938 29.4645 81.2938 29.4544C81.2938 29.4432 81.2938 29.4331 81.2938 29.4219C81.2938 24.4157 77.2966 20.4941 72.1931 20.4941V20.4953ZM78.3711 29.6205C78.3711 29.6743 78.3677 29.727 78.3654 29.7797C78.305 30.8858 77.9621 31.9043 77.4072 32.7691L75.4097 29.9009H72.4711L72.5156 29.9648H72.4711L75.6604 34.5437C74.6782 35.1954 73.4887 35.5723 72.1943 35.5723C68.7201 35.5723 65.9969 32.8869 65.9809 29.4533C65.998 26.0209 68.7213 23.3366 72.1943 23.3366C75.6673 23.3366 78.3597 26.022 78.3757 29.4556C78.3757 29.5105 78.3734 29.5655 78.3711 29.6205Z"
      fill="#233567" />
    <path d="M112.365 20.623C110.369 20.623 108.757 21.2546 107.605 22.4683V20.8451H104.719V20.9091V38.0636H107.605V37.9996V28.5929C107.606 23.8345 111.249 23.4644 112.365 23.4644H113.001V20.623H112.365Z"
      fill="#233567" />
  </svg>
);
