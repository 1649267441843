import { TextInputProps } from 'react-admin';
import { makeStyles } from '@mui/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import { useWatch } from 'react-hook-form';

import { TextInput } from './RA';

export const UrlInput = (props: TextInputProps) => {
  const value = useWatch({ name: props.source });
  const cls = useStyles();

  return (
    <>
      <div className={cls.box}>
        <TextInput
          InputLabelProps={{ shrink: true }}
          placeholder="https://any.url"
          {...props}
        />

        {value && (
          <a className={cls.hint}
            href={value}
            target="_blank"
            rel="noreferrer"
          >
            <LaunchIcon className={cls.icon} />
          </a>
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles({
  box: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    '&:hover': {
      color: '#1397C7',
    },
    fontSize: 24,
    color: '#003FB4',
  },
  hint: {
    display: 'block',
    zIndex: 10,
    marginTop: -10,
  },
});
