import { Create, CreateProps, SimpleForm } from 'react-admin';

import { RoleForm, validate } from './RoleForm';
import { Forbidden, InitialPage } from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';

export const RoleCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps} title="Create Role">
      <InitialPage
        title="Role"
        backTo="/role"
        backText="Back to all data structured"
      >
        <SimpleForm validate={validate}>
          <RoleForm />
        </SimpleForm>
      </InitialPage>
    </Create>
  );
};
