import React from 'react';
import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { SaveFilled } from '@ant-design/icons';
import { Create, CreateProps, SaveButton, SimpleForm, TopToolbar } from 'react-admin';

import {
  Container,
  TextInput,
  Forbidden, ManyCheckboxInput, PageHeader, FormRow, SwitchInput, ImageStyledInput,
} from '@components';
import { useCreateProps, useRbacCreate } from '@hooks';
import cls from './BankCreate.module.css';

export const BankCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();
  const mainColGrid = {
    xs: { title: 24, children: 24 },
    lg: { title: 6, children: 18 },
    xl: { title: 8, children: 16 },
    xxl: { title: 7, children: 17 },
  };

  const validate = (values: any) => {
    const errors: any = {};

    ['nameMx', 'managerPhone'].forEach((key: string) => {
      const regexp = /^\+?[78][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/;
      if (!values[key] && key === 'nameMx') {
        errors[key] = 'This field is required';
      }
      if (key === 'managerPhone' && values[key] && !regexp.test(values[key])) {
        errors[key] = 'Invalid phone number';
      }
    });

    return errors;
  };

  const Actions = () => {
    return (
      <TopToolbar className={cls.actions}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon}/>}
          label="Save"
          type="button"
          className={clsx(cls.action, cls.actionSave)}
          variant="outlined"
          alwaysEnable
        />
      </TopToolbar>
    );
  };

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps} className={clsx(cls.page, 'bank-page-wrapper')}>
      <>
        <PageHeader
          title="Create Bank"
          linkText="Back to all banks"
          linkUrl="/bank"
        />

        <SimpleForm
          toolbar={<Actions/>}
          validate={validate}
          className={cls.form}
        >
          <Container max>
            <Row gutter={24} className={clsx(cls.wrapper, cls.paddingBottom)}>
              <Col xs={24} className={cls.wrapperCol}>
                <div className={clsx(cls.block, cls.blockContent)}>
                  <Row gutter={24} className={cls.inner}>
                    <Col xs={24} xl={12} className={clsx(cls.innerCol, cls.innerColFirst)}>
                      <div className={cls.blockHeader}>Bank information</div>
                      <FormRow
                        title="Name mx*:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <TextInput name="nameMx" source="nameMx" required autoFocus label={false}/>
                      </FormRow>
                      <FormRow
                        title="Name en:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <TextInput name="nameEn" source="nameEn" label={false}/>
                      </FormRow>
                      <FormRow
                        title="Manager name:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <TextInput name="managerName" source="managerName" label={false}/>
                      </FormRow>
                      <FormRow
                        title="Manager phone:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <TextInput name="managerPhone" source="managerPhone" label={false} type="tel"/>
                      </FormRow>
                      <div className={cls.switcher}>
                        <SwitchInput
                          name="isEnabled"
                          source="isEnabled"
                          label="is enabled"
                          defaultValue={true}
                          className={cls.switcherField}/>
                      </div>
                    </Col>
                    <Col xs={24} xl={12} className={clsx(cls.innerCol, cls.innerColLast)}>
                      <div className={clsx(cls.blockHeader, cls.blockHeaderThin)}>
                        {'Banking institution\'s mark'}
                      </div>
                      <ImageStyledInput name="image" source="image"/>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row gutter={24} className={clsx(cls.wrapper, cls.paddingBottom)}>
              <Col xs={24} className={cls.wrapperCol}>
                <div className={clsx(cls.block, cls.blockContent)}>
                  <div className={cls.blockHeader}>Document types</div>
                  <ManyCheckboxInput
                    columns={4}
                    source="doctypes"
                    reference="doctype"
                    className={cls.checkboxes}
                    label=" "
                  />
                </div>
              </Col>
            </Row>
          </Container>


          {/*<Container max>*/}
          {/*  <Row>*/}
          {/*    <Col>*/}
          {/*      <ManyCheckboxInput*/}
          {/*        columns={4}*/}
          {/*        source="doctypes"*/}
          {/*        reference="doctype"*/}
          {/*        label="Document types"*/}
          {/*      />*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*  <Row>*/}
          {/*    <Col>*/}
          {/*      <TextInput name="nameMx" source="nameMx" required autoFocus />*/}
          {/*      <TextInput name="managerName" source="managerName" />*/}
          {/*    </Col>*/}
          {/*    <Col>*/}
          {/*      <TextInput name="nameEn" source="nameEn" />*/}
          {/*      <PhoneInput name="managerPhone" source="managerPhone" />*/}
          {/*    </Col>*/}
          {/*    <Col><ImageInput name="image" source="image" /></Col>*/}
          {/*    <Col><BooleanInput name="isEnabled" source="isEnabled" defaultValue={true}/></Col>*/}
          {/*  </Row>*/}
          {/*</Container>*/}
        </SimpleForm>
      </>
    </Create>
  );
};
