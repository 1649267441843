import { makeStyles } from '@mui/styles';
import { InputNumber, Row, Col } from 'antd';
import { PercentageOutlined } from '@ant-design/icons';
import { useEffect, useRef } from 'react';
import { moneyFormatter } from '@utils';

interface Props {
  percent: number;
  setPercent: (value: number) => void;
  percentCompany: number;
  setPercentCompany: (value: number) => void;
  bankAmount: number;
  editable: boolean;
}

export const MembershipPercent = ({
  percent, setPercent, percentCompany, setPercentCompany, bankAmount, editable,
}: Props) => {
  const cls = useStyes();
  const percentRef = useRef<any>();

  useEffect(() => {
    if (editable) {
      setTimeout(() => percentRef.current?.focus(), 100);
      setTimeout(() => percentRef.current?.focus(), 300);
    }
  }, []);

  const taxMultiply = 1.16;
  const membershipVerqorAmount = Math.ceil(bankAmount * percent * taxMultiply) / 100;
  const membershipVerqor = moneyFormatter.format(membershipVerqorAmount);
  const membershipCompanyAmount = Math.ceil(bankAmount * percentCompany * taxMultiply) / 100;
  const membershipCompany = moneyFormatter.format(membershipCompanyAmount);
  const membershipAmount = moneyFormatter.format(membershipVerqorAmount + membershipCompanyAmount);

  return (
    <div className={cls.wrap}>
      <div className={cls.box}>
        <div className={cls.name}>Verqor membership</div>
        <Row gutter={[20, 20]}>
          <Col md={6}>
            <div className={cls.title}>Credit</div>
            <div className={cls.amount}>{moneyFormatter.format(bankAmount)}</div>
          </Col>
          <Col md={6} style={{ paddingLeft: 30 }}>
            <div className={cls.title}>Cost of membership</div>
            <InputNumber
              bordered={false}
              ref={percentRef}
              prefix={<PercentageOutlined className={cls.inputIcon} />}
              value={percent}
              controls={editable ? undefined : false}
              onKeyDown={editable ? undefined : e => e.preventDefault()}
              onChange={value => setPercent(+`${value}`)}
              className={cls.input}
              min={0} max={100}
            />
          </Col>
          <Col md={3}>
            <div className={cls.title}>IVA</div>
            <div className={cls.amount}>16%</div>
          </Col>
          <Col md={5}>
            <div className={cls.title}>Total</div>
            <div className={cls.amount}>{membershipVerqor}</div>
          </Col>
        </Row>
        <div className={cls.name}>For Financial Institution</div>
        <Row gutter={[20, 20]}>
          <Col md={6}>
            <div className={cls.title}>Credit</div>
            <div className={cls.amount}>{moneyFormatter.format(bankAmount)}</div>
          </Col>
          <Col md={6} style={{ paddingLeft: 30 }}>
            <div className={cls.title}>Cost of membership</div>
            <InputNumber
              bordered={false}
              prefix={<PercentageOutlined className={cls.inputIcon} />}
              value={percentCompany}
              controls={editable ? undefined : false}
              onKeyDown={editable ? undefined : e => e.preventDefault()}
              onChange={value => setPercentCompany(+`${value}`)}
              className={cls.input}
              min={0} max={100}
            />
          </Col>
          <Col md={3}>
            <div className={cls.title}>IVA</div>
            <div className={cls.amount}>16%</div>
          </Col>
          <Col md={5}>
            <div className={cls.title}>Total</div>
            <div className={cls.amount}>{membershipCompany}</div>
          </Col>
        </Row>
        <Row gutter={[20, 20]} style={{ marginTop: 25 }}>
          <Col md={6} />
          <Col md={6} style={{ paddingLeft: 30 }} />
          <Col md={3} />
          <Col md={5}>
            <div className={cls.title}>Total</div>
            <div className={cls.amount}>{membershipAmount}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const useStyes = makeStyles({
  wrap: {
    marginLeft: 150,
  },
  box: {
    width: 800,
    margin: '0 auto',
  },
  name: {
    marginTop: 30,
    fontSize: 18,
    fontWeight: 700,
    marginRight: 15,
  },
  title: {
    fontSize: 16,
    marginRight: 15,
  },
  amount: {},
  input: {
    width: 160,
    height: 20,
    lineHeight: '20px',
    overflow: 'hidden',
    borderBottom: '1px solid #CCC !important',
    borderRadius: 0,
    '& input': {
      height: 20,
      lineHeight: '20px',
    },
  },
  inputIcon: {
    color: '#CCC',
    marginRight: 10,
  },
});
