import { Http } from './http';

class LoanPaymentHttp extends Http {
  prefix = 'loan-payment';

  async updateBulk(params: UpdateBulkParams): Promise<number> {
    const { data } = await this.put<number>('/update-bulk', params);

    return data;
  }
}

export const loanPaymentHttp = new LoanPaymentHttp();

interface UpdateBulkParams {
  selectedIds: number[];
  prop: string;
  value: string | number | null;
}
