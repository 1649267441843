import { Col, Row } from 'antd';
import { CreditRisk } from '@types';
import { Pill } from './Pill';

export const RiskOfftaker = ({ risk }: {risk: CreditRisk}) => {
  const {
    offTakerBusinessAge,
    offTakerPaymentsDso,
    offTakerLegalIncidences,
    offTakerLegalTimeWorking,
  } = risk;

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col md={12} sm={24}>
          <Pill title="Business age" info={offTakerBusinessAge} />
        </Col>
        <Col md={12} sm={24}>
          <Pill title="Legal incidences" info={offTakerLegalIncidences} />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col md={12} sm={24}>
          <Pill title="Payments DSO" info={offTakerPaymentsDso} mt={10} />
        </Col>
        <Col md={12} sm={24}>
          <Pill title="Tiempo trabajando con el retenedor"
            info={offTakerLegalTimeWorking} mt={10} />
        </Col>
      </Row>
    </>
  );
};
