import get from 'lodash/get';

const STORAGE_KEY = 'raColumnsConfig';

const getRootValue = () => {
  const json = window.localStorage.getItem(STORAGE_KEY);

  return json ? JSON.parse(json) : undefined;
};

const setRootValue = (value: boolean) => {
  try {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
  } catch (e) {}
};

export const LocalStorage = {
  get(key: string) {
    const value = get(getRootValue(), key);
    if (key.slice(0, 6) === 'dealer' && value && 'addedByUserId' in value) {
      this.remove(key);
      return {};
    }
    return value;
  },
  set(key: string, value: any) {
    const oldValue = getRootValue();
    setRootValue({
      ...oldValue,
      [key]: value,
    });
  },
  remove(removeKey: string) {
    const oldValue = getRootValue();
    if (oldValue) {
      const filtered = Object.keys(oldValue)
        .filter(key => key !== removeKey)
        .reduce((obj: any, key: string) => {
          obj[key] = oldValue[key];

          return obj;
        }, {});
      setRootValue(filtered);
    }
  },
};

