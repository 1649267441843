import { clsx } from 'clsx';
import { Button, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { ClockCircleOutlined } from '@ant-design/icons';

import cls from './TimerInput.module.css';

interface Props {
  source: string;
  label?: string | false;
  className?: string;
}

type CounterType = 'h' | 'm';

interface CounterProps {
  num: number;
  label: string;
  type: CounterType;
}

interface ItemProps {
  item: CounterProps;
  cb: (i: CounterProps) => void;
  active: CounterProps | null;
}

const listTransform = (num: number, type: CounterType): CounterProps =>
  ((num < 10) ? { num, label: `0${num}`, type } : { num, label: num.toString(), type });

const range = (start: number, stop: number, type: CounterType) =>
  Array.from({ length: stop - start + 1 }, (_, i) => listTransform(start + i, type));

const hours = range(0, 24, 'h');
const minutes = range(0, 59, 'm');

const Item = ({ item, cb, active }: ItemProps) => {
  const isActive = (item.type === active?.type && item.num === active.num);

  return (
    <div className={clsx(cls.item, isActive ? cls.active : '')} onClick={() => cb(item)}>
      {item.label}
    </div>
  );
};

export const TimerInput = (props: Props) => {
  const { source, className } = props;
  const [show, setShow] = useState(false);
  const [display, setDisplay] = useState('');
  const [selectedHour, setSelectedHour] = useState<CounterProps | null>(null);
  const [selectedMin, setSelectedMin] = useState<CounterProps | null>(null);

  const {
    field: { value, onChange },
  } = useController({ name: source });

  const handleChange = (val: CounterProps) => {
    val.type === 'h' ? setSelectedHour(val) : setSelectedMin(val);
  };

  const loadValue = (val: number) => {
    const h = ~~(val / 60);
    const m = val % 60;
    const hourValue = hours.find((item) => item.num === h);
    const minValue = minutes.find((item) => item.num === m);
    hourValue && setSelectedHour(hourValue);
    minValue && setSelectedMin(minValue);
    setDisplay(`${hourValue?.label}:${minValue?.label}`);
  };

  const onSubmit = () => {
    setDisplay(`${selectedHour?.label}:${selectedMin?.label}`);
    setShow(false);
    const result = selectedHour && selectedMin ? (selectedHour?.num * 60 + selectedMin?.num) : 0;
    onChange(result);
  };

  useEffect(() => {
    if (value) {
      loadValue(value);
    }
  }, [value]);

  return (
    <div className={clsx(cls.section, show ? cls.active : '', className)}>
      <div className={cls.inputBlock}>
        <Input
          prefix={<ClockCircleOutlined className={cls.inputIcon} />}
          className={cls.input}
          onClick={() => setShow(!show)}
          bordered={false}
          value={display}
          readOnly
        />
      </div>
      <div className={clsx(cls.dropdownBlock, show ? cls.active : '')}>
        <div className={cls.dropdownValues}>
          <div className={clsx(cls.dropdownValuesList, cls.first)}>
            {hours.map((h, key) => (
              <Item item={h} cb={handleChange} key={key} active={selectedHour} />
            ))}
          </div>
          <div className={clsx(cls.dropdownValuesList, cls.last)}>
            {minutes.map((h, key) => (
              <Item item={h} cb={handleChange} key={key} active={selectedMin} />
            ))}
          </div>
        </div>
        <div className={cls.dropdownActions}>
          <Button type="primary" onClick={onSubmit} size="small" className={cls.action}>Ok</Button>
        </div>
      </div>
    </div>
  );
};
