import React, { PropsWithChildren, useCallback, useState } from 'react';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

interface Props {
  className?: string;
}

export const ImageZoom = ({ children, className }: PropsWithChildren<Props>) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <div className={className} onClick={e => setIsZoomed(!isZoomed)}>
      <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
        {children}
      </ControlledZoom>
    </div>
  );
};
