import { Create, CreateProps, SimpleForm } from 'react-admin';

import { useCreateProps, useRbacCreate } from '@hooks';
import { Forbidden, InitialPage } from '@components';
import { FinanceCostCategoryForm, validate } from './FinanceCostCategoryForm';

export const FinanceCostCategoryCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  return (
    <Create {...props} {...createProps} title="Create Individual Category">
      <InitialPage
        title="Individual Category"
        backTo="/finance-settings"
        backText="Back to Finance Settings"
      >
        <SimpleForm validate={validate}>
          <FinanceCostCategoryForm />
        </SimpleForm>
      </InitialPage>
    </Create>
  );
};
