import { makeStyles } from '@mui/styles';
import { useNotify } from 'react-admin';

import { BlueButton } from '@components';
import { useRequest } from '@hooks';
import { userHttp } from '@network';

export const DashboardControls = () => {
  const cls = useStyles();
  const notify = useNotify();

  const { submit: submitActivity, loading: loadingActivity } = useRequest(async () => {
    await userHttp.inactivateByLogins();
    notify('Completed the command to check User activity!');
  });

  return (
    <div className={cls.box}>
      <BlueButton disabled={loadingActivity} onClick={submitActivity} className={cls.btn}>
        Check users Activity
      </BlueButton>
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    padding: '6px 10px',
    marginLeft: 10,
  },
});
