import { DocumentFile } from './DocumentFile';
import { DocumentHistory } from './DocumentHistory';

export interface Document {
  id: number;
  status: DocumentStatus;
  statusEn: string;
  creditId?: number;
  guarantorId?: string;
  doctypeId: number;
  inRevision: boolean;
  isModerated: boolean;
  isIncomplete: boolean;
  isDraft: boolean;
  isSigned: boolean;
  files: DocumentFile[];
  history: DocumentHistory[];
  number: string | null;
  isOptionalChecked: boolean;
  isOptionalCheckedAvailable?: boolean;
  adminId: number | null;
  adminName: string | null;
  managerId: number | null;
  managerName: string | null;
}

export enum DocumentStatus {
  DOCS_SENT = 'docs_sent',
  DOCS_INCOMPLETE = 'docs_incomplete',
  DOCS_READY = 'docs_ready',
}

export const documentStatusMap = {
  [DocumentStatus.DOCS_SENT]: 'Docs sent',
  [DocumentStatus.DOCS_INCOMPLETE]: 'Docs incomplete',
  [DocumentStatus.DOCS_READY]: 'Docs ready',
};
