import React from 'react';
import { Edit, EditProps, SimpleForm, useRefresh } from 'react-admin';

import { useEditProps, useRbacEdit, useRecord } from '@hooks';
import { Forbidden, InitialPage } from '@components';
import { FinanceCampaignForm, validate } from './FinanceCampaignForm';

export const FinanceCampaignEdit = (props: EditProps) => {
  const editProps = useEditProps('The changes have been saved');
  const activation = useRecord(props);
  const rbacEdit = useRbacEdit();
  const refresh = useRefresh();

  if (!rbacEdit) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} {...editProps} title="Update Campaign">
      <InitialPage
        title="Campaign"
        backTo="/finance-campaign"
        backText="Back to all Campaigns"
      >
        <SimpleForm validate={validate}>
          <FinanceCampaignForm />
        </SimpleForm>
      </InitialPage>
    </Edit>
  );
};
