import { makeStyles } from '@mui/styles';
import { CreditRisk, ReplyQuestion } from '@types';
import { moneyFormatter } from '@utils';
import React from 'react';

export const RiskTerms = ({ risk }: { risk: CreditRisk }) => {
  const cls = useStyles();
  const { replyTerms } = risk;

  const getValue = (term: ReplyQuestion): string => {
    if (term?.tag === 'client') return risk.credit?.accreditedName || '';
    if (term?.tag === 'buyer') return risk.buyerNames || '';
    if (term?.tag === 'supplier') return risk.retailerNames || '';
    if (!term) return '';
    if (term.type === 'money') return moneyFormatter.format(term.value ? +term.value : 0);
    if (term.type === 'percentage') return term.value ? `${term.value}%` : '';
    return term.value || '';
  };

  return replyTerms?.length ? (
    <>
      <div className={cls.title}>Credit Terms</div>
      <table className={cls.table}>
        <tbody>
          {replyTerms.slice(0, 10).map((term, key) => (
            <tr key={key}>
              <td>{term.title}</td>
              <td>{getValue(term)}</td>
              <td>{replyTerms[key + 10]?.title || ''}</td>
              <td>{getValue(replyTerms[key + 10])}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  ) : null;
};

const useStyles = makeStyles({
  title: {
    margin: '25px 0 5px',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: '#000',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& td': {
      borderTop: '1px solid #042E6B',
      borderLeft: '1px solid #042E6B',
      fontSize: 12,
      fontWeight: 500,
      color: '#042E6B',
      width: '25%',
      padding: '3px 8px',
      backgroundColor: 'transparent',
      '&:last-child': {
        borderRight: '1px solid #042E6B',
      },
      '&[rowspan]': {
        borderBottom: '1px solid #042E6B',
        '&:last-child': {
          borderBottomRightRadius: 5,
        },
      },
      '&:nth-child(even)': {
        width: '25%',
        backgroundColor: '#F0F3FB',
      },
    },
    '& tr:first-child td': {
      '&:first-child': {
        borderTopLeftRadius: 5,
      },
      '&:last-child': {
        borderTopRightRadius: 5,
      },
    },
    '& tr:last-child td': {
      borderBottom: '1px solid #042E6B',
      '&:first-child': {
        borderBottomLeftRadius: 5,
      },
      '&:last-child': {
        borderBottomRightRadius: 5,
      },
    },
  },
  noborder: {
    borderRadius: '0 !important',
    borderRight: 'none !important',
  },
});
