import { FormControl, MenuItem, Select } from '@mui/material';
import { useController } from 'react-hook-form';
import { clsx } from 'clsx';

import cls from './input-multiple.module.css';

interface Props {
  source: string;
  label?: string;
  name?: string;
  options: string[];
  labelWidth?: number;
  error?: string;
  className?: string;
  centered?: boolean;
  multiple?: boolean;
}

export const InputMultiple = ({
  source,
  label,
  name,
  options,
  labelWidth,
  error,
  className,
  centered,
}: Props) => {
  const {
    field: { value, onChange },
  } = useController({ name: source });
  name = name || source;

  return (
    <div className={cls._}>
      {label && (
        <label
          className={cls.label}
          style={{ minWidth: labelWidth ? labelWidth : 100 }}
        >{label}</label>
      )}
      <FormControl
        variant="standard"
        className={clsx(cls.form, className, centered && cls.centered)}
      >
        <Select
          name={name}
          value={value as any}
          onChange={onChange}
          multiple
        >
          {options.map(opt => <MenuItem key={opt} value={opt}>{opt}</MenuItem>)}
        </Select>

        {error && (
          <span className={cls.error}>{error}</span>
        )}
      </FormControl>
    </div>
  );
};
