import { EditProps, Edit, SimpleForm } from 'react-admin';
import {
  Row,
  Col,
  Container,
  TextInput,
  SelectInput,
  ReferenceInput,
  Forbidden,
} from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>City: {record.name}</span>) : null;

export const CityEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <SimpleForm toolbar={rbacEdit ? undefined : false}>
        <Container>
          <Row>
            <Col><TextInput source="name" required /></Col>
            <Col>
              <ReferenceInput source="stateId" reference="state" req>
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Col>
            <Col><TextInput source="id" disabled /></Col>
          </Row>
          <Row>
            <Col><TextInput source="latitude" /></Col>
            <Col><TextInput source="longitude" /></Col>
            <Col />
          </Row>
        </Container>
      </SimpleForm>
    </Edit>
  );
};
