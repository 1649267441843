import React, { DragEvent, useState } from 'react';
import { Button, FormInstance, FormListOperation } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';

import { VariablesQuestion } from '@pages/risk/variables';
import cls from './VariablesQuestion.module.css';

interface VariablesQuestionsProps {
  form: FormInstance<any>;
  index: number;
  addTotalValue: (questionIndex: number, value: number) => void;
  fields: FormListFieldData[];
  operation: FormListOperation;
  readonly?: boolean;
}

export const VariablesQuestions = ({
  form, index, addTotalValue, fields, operation, readonly,
}: VariablesQuestionsProps) => {
  const [currentDraggableElementIndex, setCurrentDraggableElementIndex] =
    useState<number | null>(null);

  const onDragStart = (e: DragEvent<HTMLDivElement>, index: number) => {
    e.dataTransfer.setData('dragIndex', `${index}`);
    setCurrentDraggableElementIndex(index);
  };

  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onDrop = (e: DragEvent<HTMLDivElement>, dropIndex: number) => {
    const dragIndex: string = e.dataTransfer.getData('dragIndex');

    setCurrentDraggableElementIndex(null);

    const dragFields = form.getFieldValue(['sections', index, 'questions', dragIndex]);
    const dropFields = form.getFieldValue(['sections', index, 'questions', dropIndex]);

    if (dropIndex !== currentDraggableElementIndex) {
      const newList = [...form.getFieldValue(['sections', index, 'questions'])];

      newList[+dragIndex] = dropFields;
      newList[+dropIndex] = dragFields;

      const formValues = form.getFieldValue(['sections', index]);
      form.setFieldValue(['sections', index], { ...formValues, questions: newList });
    }
  };

  const onAdd = () => operation.add({
    title: '',
    position: fields.length + 1,
  });

  return (
    <div className={cls._}>
      {fields.map((field) => {
        const question = form.getFieldValue(['sections', index, 'questions', field.name]);

        return (
          <VariablesQuestion
            key={`question-${index}-${field.name}`}
            form={form}
            sectionIndex={index}
            question={question}
            index={currentDraggableElementIndex}
            addTotalValue={addTotalValue}
            field={field}
            operation={operation}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDrop={onDrop}
            readonly={readonly}
          />
        );
      })}

      <div className={cls.actions}>
        <Button className={cls.add} type="default" disabled={readonly} onClick={onAdd}>
          Add variable
        </Button>
      </div>
    </div>
  );
};
