import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { clsx } from 'clsx';
import { makeStyles } from '@mui/styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { moneyFormatter } from '@utils';
import { creditGoalHttp } from '@network/credit-goal-http';
import {
  GoalsViewType,
  MonthData,
  QuarterlyData,
} from './AllocationRevenueGoals';
import { QuarterGoals } from './QuarterGoals';
import { ConfirmDelete } from '@components';
import { checkSuperadmin } from '@providers';
import { useOutsideClick } from '@hooks';

interface GoalsFormProps {
  goals: MonthData[];
  availableYears: string[];
  currentYear: string;
  type: GoalsViewType;
  hideModal: () => void;
  setCurrentYear?: Dispatch<SetStateAction<string>>;
  hasPreviousYear?: boolean;
  hasNextYear?: boolean;
  addNewGoalModal?: () => void;
  editGoalModal?: () => void;
  setUpdated?: () => void;
}

export const GoalsForm = ({
  goals,
  availableYears,
  currentYear,
  type,
  setCurrentYear,
  hasPreviousYear,
  hasNextYear,
  hideModal,
  addNewGoalModal,
  editGoalModal,
  setUpdated,
}: GoalsFormProps) => {
  const cls = useStyles();

  const [yearData, setYearData] = useState({});
  const [goalData, setGoalData] = useState<MonthData[]>([]);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [showYearValues, setShowYearValues] = useState(false);

  const yearValuesRef = useRef<HTMLDivElement>(null);

  useOutsideClick(yearValuesRef, () => {
    setShowYearValues(false);
  });

  const goToPreviousYear = () => {
    if (!setCurrentYear) {
      return;
    }

    const previousYear = dayjs(`${currentYear}-01-01`).subtract(1, 'year').year();
    setCurrentYear(previousYear.toString());
  };

  const goToNextYear = () => {
    if (!setCurrentYear) {
      return;
    }

    const nextYear = dayjs(`${currentYear}-01-01`).add(1, 'year').year();
    setCurrentYear(nextYear.toString());
  };

  const openYearValues = () => {
    if (type !== 'view') {
      return;
    }

    setShowYearValues(prev => !prev);
  };

  const selectYear = (year: string) => {
    if (!setCurrentYear) {
      return;
    }

    setCurrentYear(year);
    setShowYearValues(false);
  };

  const submit = async () => {
    const params = {
      year: currentYear,
      data: Object.values(yearData) as MonthData[],
    };

    try {
      await creditGoalHttp.create(params);
      hideModal();
      setHasUpdated(false);
      if (setUpdated) setUpdated();
    } catch (e) {
      console.error(e);
    }
  };

  const quartersData = useMemo(() => {
    const result: QuarterlyData = [];

    if (!goalData) {
      return [];
    }

    for (let i = 0; i < goalData.length; i++) {
      const quarterIndex = Math.floor(i / 3);
      const month = { ...goalData[i] };

      if (result[quarterIndex]) {
        result[quarterIndex].push(month);
      } else {
        result.push([month]);
      }
    }

    return result;
  }, [goalData]);

  const total = useMemo(() => {
    const values = Object.values(yearData) as unknown as MonthData[];

    return values.reduce(
      (acc, month) => {
        acc.allocation += +month.allocation;
        acc.revenue += +month.revenue;
        return acc;
      },
      { allocation: 0, revenue: 0 },
    );
  }, [yearData]);

  useEffect(() => {
    setGoalData(goals);
  }, [goals]);

  const isSuperadmin = checkSuperadmin();

  return (
    <div>
      <div className={cls.controls}>
        <div className={cls.actions}>
          {type === 'view' && isSuperadmin && (
            <>
              <button className={cls.editIcon} onClick={editGoalModal}>
                <EditIcon />
              </button>
              <button className={cls.addIcon} onClick={addNewGoalModal} disabled={!setCurrentYear}>
                <AddIcon />
              </button>
            </>
          )}

          {(type === 'edit' || type === 'create') && (
            <>
              {hasUpdated
                ? (
                  <ConfirmDelete
                    title="Are you sure?"
                    cb={() => {
                      hideModal();
                      setHasUpdated(false);
                    }}
                    placement="bottom"
                    className={cls.cancelIcon}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg"
                      width="59"
                      height="41"
                      viewBox="0 0 59 41"
                      fill="none">
                      <rect x="18"
                        y="12.6274"
                        width="5"
                        height="27"
                        rx="2"
                        transform="rotate(-45 18 12.6274)"
                        fill="#FF2222" />
                      <rect x="22"
                        y="31.6274"
                        width="5"
                        height="27"
                        rx="2"
                        transform="rotate(-135 22 31.6274)"
                        fill="#FF2222" />
                    </svg>
                  </ConfirmDelete>
                )
                : (
                  <button className={cls.cancelIcon} onClick={() => {
                    hideModal();
                    setHasUpdated(false);
                  }}>
                    <CancelIcon />
                  </button>
                )
              }

              <button className={cls.saveIcon} onClick={submit} disabled={!hasUpdated}>
                <SaveIcon />
              </button>
            </>
          )}
        </div>
        <div className={cls.year}>
          {type === 'view' && (
            <button
              onClick={goToPreviousYear}
              className={clsx(cls.arrowIcon, !hasPreviousYear && cls.disabledIcon)}
              disabled={!hasPreviousYear}
            >
              <ArrowIcon />
            </button>
          )}
          <div ref={yearValuesRef} className={cls.yearControl}>
            <div className={cls.yearValue} onClick={openYearValues}>
              {currentYear}
              {type === 'view' && (
                <>
                  {showYearValues ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </>
              )}
            </div>
            {showYearValues && (
              <div className={cls.yearValues}>
                {availableYears?.map(year =>
                  <div
                    key={year}
                    className={clsx(cls.yearItem, year === currentYear && cls.yearCurrent)}
                    onClick={() => selectYear(year)}
                  >
                    {year}
                  </div>,
                )}
              </div>
            )}
          </div>
          {type === 'view' && (
            <button
              onClick={goToNextYear}
              className={clsx(cls.arrowIcon, !hasNextYear && cls.disabledIcon, cls.next)}
              disabled={!hasNextYear}
            >
              <ArrowIcon />
            </button>
          )}
        </div>
      </div>
      <div className={cls.table}>
        {quartersData?.map((quarter, index) => {
          return (
            <QuarterGoals
              key={index}
              quarter={quarter}
              index={index}
              isEditable={type === 'edit' || type === 'create'}
              setYearData={setYearData}
              setHasUpdated={setHasUpdated}
            />
          );
        })}
      </div>
      <div className={cls.total}>
        <div className={cls.totalWrap}>
          <div className={cls.totalYear}>{currentYear} Total</div>
          <div className={cls.totalValue}>
            Allocation: {moneyFormatter.format(total.allocation)}
          </div>
          <div className={cls.totalValue}>
            Revenue: {moneyFormatter.format(total.revenue)}
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  table: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(2, 1fr)',
    rowGap: 36,
    columnGap: 87,
    marginTop: 38,
  },
  total: {
    paddingLeft: 120,
    paddingRight: 120,
    marginTop: 40,
  },
  totalWrap: {
    border: '2px solid #B9C4D6',
    borderRadius: 5,
    padding: '18px 40px',
    fontSize: 20,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    gap: 50,
    width: 'fit-content',
    margin: '0 auto 15px',
  },
  totalYear: {},
  totalValue: {},
  editIcon: {
    appearance: 'none',
    border: 'none',
    borderRadius: 5,
    backgroundColor: '#042E6B',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    width: 59,
    height: 41,
    cursor: 'pointer',
    padding: 4,

    '& svg': {
      height: '100%',
    },
  },
  addIcon: {
    appearance: 'none',
    border: 'none',
    borderRadius: 5,
    backgroundColor: '#042E6B',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    width: 59,
    height: 41,
    cursor: 'pointer',
    padding: 4,

    '& svg': {
      height: '100%',
    },
  },
  saveIcon: {
    appearance: 'none',
    border: 'none',
    borderRadius: 5,
    backgroundColor: '#042E6B',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    width: 59,
    height: 41,
    cursor: 'pointer',
    padding: 0,

    '&:disabled': {
      background: 'grey',
      cursor: 'not-allowed',
    },

    '& svg': {
      height: '100%',
      width: 24,
    },
  },
  arrowIcon: {
    appearance: 'none',
    border: 'none',
    borderRadius: 5,
    backgroundColor: '#042E6B',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    width: 59,
    height: 41,
    cursor: 'pointer',
    padding: 0,

    '& svg': {
      height: '100%',
      width: 24,
    },
  },
  cancelIcon: {
    appearance: 'none',
    borderRadius: 5,
    backgroundColor: 'transparent',
    border: '1px solid #FF2222',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    width: 59,
    height: 41,
    cursor: 'pointer',
    padding: 0,

    '& svg': {
      height: '100%',
      width: 40,
    },
  },
  next: {
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
  disabledIcon: {
    backgroundColor: 'grey',
  },
  controls: {
    width: 'calc(50% - 40px)',
    marginLeft: 'auto',
    display: 'flex',
  },
  actions: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    gap: 19,
  },
  year: {
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    gap: 40,
  },
  yearValue: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    fontSize: 25,
    fontWeight: 700,
    cursor: 'pointer',
  },
  yearControl: {
    position: 'relative',
  },
  yearValues: {
    position: 'absolute',
    zIndex: 10,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    width: '100%',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 2,
  },
  yearItem: {
    padding: 4,
    textAlign: 'center',

    '&:hover': {
      background: '#eee',
      color: '#000',
      cursor: 'pointer',
    },
  },
  yearCurrent: {
    backgroundColor: '#042E6B',
    color: '#fff',

    '&:hover': {
      backgroundColor: '#021e45',
      color: '#fff',
    },
  },
});

const SaveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'inline-block' }}
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none">
    <g clipPath="url(#clip0_3843_4772)">
      <path d="M14.8964 1.75077L13.2321 0.10257C13.1643 0.0389059 13.075 0 12.9786 0H2.25C2.21429 0 2.18571 0 2.15 0C2.15 0 2.14643 0 2.14286 0H0.357143C0.160714 0 0 0.159161 0 0.35369V14.6463C0 14.8408 0.160714 15 0.357143 15H14.6429C14.8393 15 15 14.8408 15 14.6463V2.00189C15 1.90639 14.9607 1.81797 14.8964 1.75077ZM7.5 11.8486C6.11786 11.8486 5 10.7416 5 9.37279C5 8.00401 6.11786 6.89696 7.5 6.89696C8.88214 6.89696 10 8.00401 10 9.37279C10 10.7416 8.88214 11.8486 7.5 11.8486ZM11.4286 3.90474C11.4286 4.49187 10.95 4.96581 10.3571 4.96581H4.64286C4.05 4.96581 3.57143 4.49187 3.57143 3.90474V1.40769C3.57143 1.22377 3.72143 1.07522 3.90714 1.07522L7.91071 1.06814C8.07857 1.06814 8.21429 1.20255 8.21429 1.36878V3.47324C8.21429 3.70667 8.40357 3.89413 8.63929 3.89413H9.575C9.81071 3.89413 10 3.70667 10 3.47324V1.36171C10 1.19547 10.1357 1.06107 10.3036 1.06107H11.0929C11.2786 1.06107 11.4286 1.20962 11.4286 1.39354V3.90474Z"
        fill="#E1E5ED" />
    </g>
    <defs>
      <clipPath id="clip0_3843_4772">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const EditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="37" viewBox="0 0 27 37" fill="none">
    <rect x="15.3096"
      y="0.377441"
      width="2.97817"
      height="23.8254"
      transform="rotate(25 15.3096 0.377441)"
      fill="white" />
    <rect x="23.4072"
      y="4.15332"
      width="2.97817"
      height="23.8254"
      transform="rotate(25 23.4072 4.15332)"
      fill="white" />
    <rect x="19.3584"
      y="2.26562"
      width="2.97817"
      height="23.8254"
      transform="rotate(25 19.3584 2.26562)"
      fill="white" />
    <path d="M6.29686 33.8L5.26283 21.9809L16.0154 26.995L6.29686 33.8Z" fill="white" />
    <rect x="15.3096"
      y="0.377441"
      width="11.9127"
      height="3.27599"
      transform="rotate(25 15.3096 0.377441)"
      fill="white" />
  </svg>
);

const AddIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
    <path d="M0.775879 17.1841V12.0001H27.9119V17.1841H0.775879ZM11.7199 0.352051H17.0319V28.8321H11.7199V0.352051Z"
      fill="white" />
  </svg>
);

const ArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="27" viewBox="0 0 19 27" fill="none">
    <rect x="18.041"
      y="22.5"
      width="6.36393"
      height="19.0918"
      rx="2"
      transform="rotate(135 18.041 22.5)"
      fill="white" />
    <rect x="13.5"
      width="6.36393"
      height="19.0918"
      rx="2"
      transform="rotate(45 13.5 0)"
      fill="white" />
  </svg>
);

const CancelIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="59" height="41" viewBox="0 0 59 41" fill="none">
    <rect x="18"
      y="12.6274"
      width="5"
      height="27"
      rx="2"
      transform="rotate(-45 18 12.6274)"
      fill="#FF2222" />
    <rect x="22"
      y="31.6274"
      width="5"
      height="27"
      rx="2"
      transform="rotate(-135 22 31.6274)"
      fill="#FF2222" />
  </svg>
);
