import { FunctionField, FunctionFieldProps } from 'react-admin';

import { resolvePath } from '@components';
import { Crop } from '@types';

const Crops = ({ crops }: { crops: Crop[] }) => crops.length ? (
  <div>{crops.map(c => c.nameMx).filter(Boolean).join(', ')}</div>
) : <></>;

export const CropsField = (props: Partial<FunctionFieldProps>) => (
  <FunctionField
    sortable={false}
    {...props}
    render={(record: any) => <Crops crops={resolvePath(record, props.source || '')} />}
  />
);
