import { ListProps, RichTextField, TextField } from 'react-admin';

import { TextInput, Grid, GridList, DateField, Forbidden } from '@components';
import { useRbacList } from '@hooks';
import cls from './PageList.module.css';

export const PageList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      className={cls.list}
    >
      <Grid
        rowClick="edit"
        defaultColumns={['id', 'titleMx', 'url', 'createdAt', 'updatedAt']}
        className={cls.grid}
      >
        <TextField source="id" />
        <TextField source="titleMx" label="Title" />
        <TextField source="url" />
        <RichTextField source="contentMx" label="Content" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
];
