import { TextInputProps } from 'react-admin';
import { useController } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';

import { InitialLabel, TextInput } from '@components';
import { makeStyles } from '@mui/styles';

interface Props {
  suffix: string;
  maxLength?: number;
  fractional?: boolean;
  upperLabel?: string;
  tooltip?: string;
  fixed?: boolean;
}

export const NumberSuffixInput = (props: TextInputProps & Props) => {
  const { suffix, maxLength, fractional, upperLabel, tooltip, fixed, ...restProps } = props;
  const {
    field: { value, onChange },
  } = useController({ name: props.source || '' });
  const inputRef = useRef<any>();
  const [inputWidth, setInputWidth] = useState<number>(0);
  const cls = useStyles();

  useEffect(() => {
    if (inputRef.current) {
      setInputWidth(getInputWidth(inputRef.current));
    }
  }, [value]);

  const allowedChars = fractional ? '0123456789,.'.split('') : '0123456789,'.split('');

  return (
    <>
      {upperLabel && <InitialLabel tooltip={tooltip}>{upperLabel}</InitialLabel>}
      <div className={cls.box}>
        <div className={cls.suffix} style={{
          fontFamily: inputRef.current?.style.fontFamily,
          fontSize: inputRef.current?.style.fontSize,
          fontWeight: inputRef.current?.style.fontWeight,
          fontStyle: inputRef.current?.style.fontStyle,
          letterSpacing: inputRef.current?.style.letterSpacing,
          color: inputRef.current?.style.color,
          paddingBottom: suffix.includes('%') ? 5 : 7,
          left: inputWidth * 1.3 + 10,
        }}>{suffix}</div>
        <TextInput {...restProps}
          className={cls.input}
          label={restProps.label || ''}
          format={(val) => `${val || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          onBeforeInputCapture={(e: any) => {
            if (fixed || !allowedChars.includes(`${e.data}`)) e.preventDefault();
          }}
          parse={(val) => {
            const str = `${val}`;
            if (str.includes('.') && fractional) {
              const [left, right] = str.split('.');
              const number = [left.replace(/(,*)/g, ''), right.slice(0, 2)].join('.');
              onChange(+number);
              return number;
            }

            return val ? +`${val}`.replace(/(,*)/g, '') : 0.00;
          }}
          inputProps={{
            maxLength: maxLength || 19,
            ref: inputRef,
          }}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles({
  box: {
    position: 'relative',
  },
  input: {
    zIndex: 0,
  },
  suffix: {
    position: 'absolute',
    display: 'inline-block',
    bottom: 0,
    zIndex: 0,
  },
});

function getInputWidth(input: HTMLInputElement): number {
  const span = document.createElement('span');
  span.style.whiteSpace = 'pre';
  span.style.fontFamily = input.style.fontFamily;
  span.style.fontSize = input.style.fontSize;
  span.style.fontWeight = input.style.fontWeight;
  span.style.fontStyle = input.style.fontStyle;
  span.style.letterSpacing = input.style.letterSpacing;
  span.style.textTransform = input.style.textTransform;
  span.style.padding = input.style.padding;
  span.style.margin = input.style.margin;
  span.style.border = input.style.border;
  span.style.boxSizing = input.style.boxSizing;
  span.style.position = 'absolute';
  span.style.left = '-100%';
  span.innerText = input.value;
  document.body.appendChild(span);
  const width = span.getBoundingClientRect().width;
  document.body.removeChild(span);

  return width;
}
