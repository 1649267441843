import jsonExport from 'jsonexport/dist';
import { downloadCSV, Exporter, useResourceContext } from 'react-admin';

import { moneyFormatter, dateFormatter, LocalStorage } from '@utils';

export interface ColumnType {
  props: {source: string; label?: string};
}

const toCamelCase = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const useDefaultExporter = (columns: ColumnType[]): Exporter => {
  const resource = useResourceContext();
  const labels: Record<string, string> = {};

  columns.forEach((column) => {
    if (!column) {
      return;
    }
    const { props } = column;
    labels[props.source] = props.label || props.source.split('.').map(toCamelCase).join(' ');
  });

  const exporter: Exporter = async (records: any[]) => {
    const selection = LocalStorage.get(`${resource}`);
    const selectionLength = Object.keys(selection || {}).length;

    const mappedRecords = records.map(record => {
      const exportObj: Record<string, string | number> = {};

      for (const column of columns) {
        const recordKey = column.props?.source || '';
        let key: string = recordKey;
        const label = labels[recordKey] || '';

        if (key === 'product' && record['product']) {
          key = 'product.name';
        }
        if (key === 'credit' && record['credit']) {
          key = 'credit.code';
        }
        if (key === 'status' && record['statusEn']) {
          key = 'statusEn';
        }
        if (key === 'user.status' && record.user?.statusEn) {
          key = 'user.statusEn';
        }
        if (key === 'user.stateId') {
          key = 'user.state.name';
        }
        if (key === 'userId' || key === 'user.id') {
          key = 'user.fullName';
        }
        if (key === 'credit.userId') {
          key = 'credit.user.fullName';
        }
        if (key === 'credit.id') {
          key = 'credit.code';
        }
        if (key === 'credit.status' && record.credit?.statusEn) {
          key = 'credit.statusEn';
        }

        if (selectionLength === 0 || selection[recordKey]) {
          let value = recompose(record, key);
          const strVal = `${value}`;
          if (key.toLowerCase().includes('phone')) {
            value = phoneTransformer(value);
          }
          if (strVal.match(/\.\d{3}Z$/) || strVal.match(/^\d{4}-\d{2}-\d{2}$/)) {
            value = dateFormatter.toDateSlash(strVal);
          }
          if (key.toLowerCase().includes('amount') || label.toLowerCase().includes('amount')) {
            value = moneyFormatter.format(+value || 0);
          }
          exportObj[label] = value;
        }
      }

      return exportObj;
    });

    const fileName = resource.split(/[ _-]+/)
      .map(w => w.charAt(0).toUpperCase() + w.slice(1))
      .join(' ');

    jsonExport(mappedRecords, {}, (err, csv) => {
      downloadCSV(csv, fileName);
    });
  };

  return exporter;
};

function recompose(obj: any, prop: any): any {
  if (obj === null || obj === undefined || !prop) {
    return null;
  }

  const parts = prop.split('.');
  const newObj = obj[parts[0]];

  if (parts[1]) {
    parts.splice(0, 1);
    const newString = parts.join('.');

    return recompose(newObj, newString);
  }

  return newObj;
}

function phoneTransformer(phone: any): string | null {
  if (!phone) {
    return null;
  }

  const countryCode = phone.slice(0, phone.length - 10);
  let f = phone.replace(/\D/g, '').slice(phone.length - 11);

  if (f[9] !== undefined) {
    f = `${countryCode
    } (${f[0]}${f[1]}${f[2]}) ${f[3]}${f[4]}${f[5]}-${
      f[6]}${f[7]}${f[8]}${f[9]}`;
  }

  return f;
}
