import { InputNumber } from 'antd';

import cls from './SuppliersTable.module.css';

export type SupplierRowKeys = 'period' | 'emitted' | 'avgDpo';

export interface SupplierHeaderRow {
  key: SupplierRowKeys;
  label: string;
}

export interface SupplierRow {
  period: number;
  emitted: number;
}

export interface SupplierDataProps {
  avgDpo: number;
  header: SupplierHeaderRow[];
  values: SupplierRow[];
}

interface Props {
  data: SupplierDataProps;
  onChange: any;
  title: string;
}

export const SuppliersTable = ({ data, onChange, title }: Props) => {
  const onFieldChange = (val: number | null, period: number) => {
    if (val === null) return;
    const newValues = data.values.map(r => r.period === period ? { ...r, emitted: val } : r);
    const newValue = { ...data, values: newValues };
    onChange(newValue);
  };

  if (!data) {
    return null;
  }

  return (
    <div className={cls.block}>
      <div className={cls.table}>
        <div className={cls.header}>
          <div className={cls.headerRow}>
            <div className={cls.headerCol}>{title}</div>
          </div>
        </div>
        <div className={cls.titles}>
          <div className={cls.row}>
            {data.header.map(h => (
              <div className={cls.hCol} key={h.key}>{h.label}</div>
            ))}
          </div>
        </div>
        <div className={cls.body}>
          <div className={cls.bodyRow}>
            <div className={cls.bodyCol}>
              {data.values.map((r, key) => (
                <div className={cls.row} key={key}>
                  <div className={cls.col}>
                    <span className={cls.text}>{r.period}</span>
                  </div>
                  <div className={cls.col}>
                    <InputNumber
                      min={0}
                      max={1000000000}
                      formatter={(value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value ? +value.replace(/\$\s?|(,*)/g, '') : 0}
                      value={r.emitted}
                      bordered={false}
                      onChange={e => onFieldChange(e, r.period)}
                      className={cls.input}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className={cls.bodyCol}>
              <InputNumber
                min={0}
                max={1000000000}
                value={data.avgDpo}
                bordered={false}
                onChange={e => onChange({ ...data, avgDpo: e || 0 })}
                className={cls.input}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
