import { FunctionFieldProps, FunctionField } from 'react-admin';

import { FileWidget } from '@components';
import { File } from '@types';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';

export const FilesField = (props: Partial<FunctionFieldProps>) => {
  const { prefix, ...restProps } = props;
  const sourceField = props.source as string;

  return (
    <FunctionField
      {...restProps}
      render={(record: any) => (
        <FilesWidget
          label={props?.label?.toString()}
          prefix={props?.prefix}
          files={(record[sourceField] || []) as File[]}
        />
      )}
    />
  );
};

interface FilesWidgetProps {
  files: File[];
  prefix?: string;
  label?: string;
}

const FilesWidget = ({ files, label, prefix }: FilesWidgetProps) => {
  const cls = useStyles();

  if (!Array.isArray(files) || files.length === 0) {
    return null;
  }

  return (
    <>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <div className={cls.files}>
        {files.map(file => (
          <div className={cls.file} key={file.id}>
            <FileWidget file={file} prefix={prefix} />
          </div>
        ))}
      </div>
    </>
  );
};

const useStyles = makeStyles({
  files: {
    display: 'flex',
  },
  file: {
    marginRight: 10,
  },
});
