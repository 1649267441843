import { clsx } from 'clsx';
import { useNotify } from 'react-admin';
import { SaveFilled } from '@ant-design/icons';
import { DragEvent, useEffect, useState } from 'react';

import { axiosErrorToString } from '@utils';
import { riskAdminHttp } from '@network/risk-admin-http';
import { RiskQuestion } from '@pages/risk/risk-question';
import {
  BlueButton,
  BlueOutlineButton,
  InitialCard,
  Loader,
  OrangeOutlineButton,
} from '@components';
import {
  RiskAdminAnswer,
  RiskAdminQuestion,
  RiskQuestionType,
  QuestionItemType,
  QuestionItemShort,
} from '@types';
import cls from './Terms.module.css';

interface Props {
  items: RiskAdminQuestion[];
  onChange: () => Promise<void>;
  readonly?: boolean;
}

export const TermsEdit = ({ items, onChange, readonly }: Props) => {
  const notify = useNotify();
  const [defaultList, setDefaultList] = useState<QuestionItemType[]>([]);
  const [list, setList] = useState<QuestionItemType[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState<QuestionItemType | null>(null);
  const [targetItem, setTargetItem] = useState<number | null>(null);

  const onDragStart = (_: DragEvent<HTMLDivElement>, item: QuestionItemType) => {
    if (readonly) return;
    setCurrentItem(item);
  };

  const onDragEnd = (_: DragEvent<HTMLDivElement>) => {
    if (readonly) return;
    setTargetItem(null);
  };

  const onDragOver = (e: DragEvent<HTMLDivElement>, item: QuestionItemType) => {
    e.preventDefault();
    if (readonly) return;
    !item.isFixed && setTargetItem(item.idx);
  };

  const onDrop = (e: DragEvent<HTMLDivElement>, item: QuestionItemType) => {
    e.preventDefault();
    if (readonly) return;
    setTargetItem(null);
    if (item.isFixed) return;

    setList(list.map((i: QuestionItemType) => {
      if (i.idx === item.idx && currentItem) {
        return { ...i, position: currentItem.position };
      }
      if (i.idx === currentItem?.idx) {
        return { ...i, position: item.position };
      }
      return i;
    }));
  };

  const validation = (): boolean => {
    const errors = list.filter(i => (!i.title.trim() || !i.titleMx.trim()
        || (i.type === RiskQuestionType.SINGLE_CHOICE && Array.isArray(i.answers)
          && i.answers.length < 2))
      || (i.type === RiskQuestionType.SINGLE_CHOICE && Array.isArray(i.answers) && !!i.answers
        .filter((a: RiskAdminAnswer) => !a.title.trim()).length),
    );
    return !errors.length;
  };

  const sortItems = (a: QuestionItemType, b: QuestionItemType): number =>
    a.position > b.position ? 1 : -1;

  const onItemChange = (item: QuestionItemType) => {
    if (readonly) return;
    setList(prev => prev.map(i => i.idx === item.idx ? item : i));
  };

  const onItemDelete = (item: QuestionItemType) => {
    if (readonly) return;
    setList(prev => prev.filter(i => i.idx !== item.idx));
  };

  const setItemsList = () => {
    const updatedList: QuestionItemType[] = items
      .map((i: RiskAdminQuestion, idx: number) => ({
        ...i,
        idx,
        answers: i.answers
          ? i.answers.map((a: RiskAdminAnswer, key: number) => ({ ...a, idx: key }))
          : undefined,
      }));
    setList(updatedList);
    setDefaultList(updatedList);
  };

  const onAddItem = () => {
    if (readonly) return;
    if (list.length === 20 || !validation()) return;

    const newItem: QuestionItemShort = {
      idx: Date.now(),
      type: RiskQuestionType.SHORT_ANSWER,
      title: '',
      titleMx: '',
      position: Date.now(),
      isFixed: false,
      answers: undefined,
    };
    setList(list => ([
      ...list,
      newItem,
    ]));
  };

  const onSave = async () => {
    if (readonly) return;
    if (!validation()) {
      throw new Error('Questions are not valid!');
    }

    const questions = list.map(i => ({
      ...i,
      title: i.title.trim(),
      titleMx: i.titleMx.trim(),
      answers: i.type !== RiskQuestionType.SINGLE_CHOICE || !i.answers
        ? undefined
        : i.answers.map(a => ({
          ...a,
          title: a.title.trim(),
        })),
    }));

    setLoading(true);
    try {
      await riskAdminHttp.updateRiskTerms({ questions });
      await onChange();
      notify('Updated');
    } catch (e) {
      notify(axiosErrorToString(e));
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    if (readonly) return;
    setItemsList();
  };

  const hasChanges = (): boolean => {
    return JSON.stringify(defaultList) !== JSON.stringify(list);
  };

  useEffect(() => {
    if (items.length) {
      setItemsList();
    }
  }, [items]);

  return (
    <InitialCard left="Edit Credit Terms" autoHeight mediumPadding>
      <div className={clsx(cls.editPage, cls.wrap)}>
        <div className={cls.body}>
          <div className={cls.header}>
            <div className={cls.row}>
              <div className={cls.col}>Variable name</div>
              <div className={cls.col}>Variable name MX</div>
              <div className={cls.col}>Type</div>
              <div className={cls.col} />
            </div>
          </div>
          {!list.length ? <Loader /> : list.sort(sortItems).map((i: QuestionItemType) => (
            <RiskQuestion
              item={i}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onChange={onItemChange}
              onDelete={onItemDelete}
              key={i.position}
              className={clsx(targetItem === i.idx && cls.target)}
              readonly={readonly}
            />
          ))}
        </div>
        <div className={cls.tableActions}>
          <BlueOutlineButton
            className={clsx(cls.btn, cls.btnOutline, cls.addBtn)}
            onClick={onAddItem}
            disabled={readonly || loading || list.length === 20 || !validation()}
          >Add variable</BlueOutlineButton>
        </div>
        <div className={cls.pageActions}>
          <BlueButton
            className={clsx(cls.btn, cls.saveBtn)}
            onClick={onSave}
            disabled={readonly || loading || !validation() || !hasChanges()}
          >
            <SaveFilled className={cls.btnIcon} />
            Save
          </BlueButton>
          <OrangeOutlineButton
            className={clsx(cls.btn, cls.btnOutline, cls.cancelBtn)}
            onClick={onCancel}
            disabled={readonly || loading}
          >Cancel</OrangeOutlineButton>
        </div>
        <div className={cls.footer}>Max 20 terms</div>
      </div>
    </InitialCard>
  );
};
