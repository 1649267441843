import { ListProps, TextField, SelectField } from 'react-admin';

import {
  TextInput,
  ImageField,
  Grid,
  GridList,
  BooleanField,
  Forbidden,
  NullableBooleanInput,
  SelectInput, SelectArrayInput,
} from '@components';
import { useRbacList } from '@hooks';
import { categoryOptions } from './category-options';
import { DoctypePosition } from './DoctypePosition';
import { requirementsOptions } from './requirements-options';
import { dealerTiersChoices } from '@types';

export const DoctypeList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props} filters={Filters}>
      <Grid
        rowClick="edit"
        defaultColumns={'position nameMx nameEn infoMx infoEn image requirements category excludedTiers isVisible isDefault isGuarantor isOptional'.split(' ')}
      >
        <TextField source="position" name="position" sortable={false} />
        <DoctypePosition label="Move" sortable={false} />
        <TextField source="nameMx" label="Title MX" sortable={false} />
        <TextField source="infoMx" label="Subtitle MX" sortable={false} />
        <TextField source="nameEn" label="Title EN" sortable={false} />
        <TextField source="infoEn" label="Subtitle EN" sortable={false} />
        <ImageField source="image" sortable={false} width={40} maxHeight={40} />
        <SelectField
          source="requirements"
          label="Applies to type of User"
          choices={requirementsOptions}
          sortable={false}
        />
        <TextField source="categoryName" label="Category grouped" sortable={false} />
        <TextField source="excludedTiers" label="Excluded for Tier" sortable={false} />
        <BooleanField source="isVisible" label="Is visible" />
        <BooleanField source="isDefault" label="Default of Credit docs" sortable={false} />
        <BooleanField source="isGuarantor" label="Default of Guarantor docs" sortable={false} />
        <BooleanField source="isOptional" label="Is optional" sortable={false} />
        <BooleanField source="isSendToCredit" label="Send to Analysis" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  <NullableBooleanInput
    style={{ width: 200 }}
    source="isDefault"
    key="isDefault"
    label="Default of Credit docs"
    alwaysOn
    trueLabel="Yes"
    falseLabel="No"
  />,
  <SelectInput
    style={{ width: 250 }}
    source="requirements"
    key="requirements"
    label="Applies to user"
    choices={[
      { id: 'Individual', name: 'Individual' },
      { id: 'Company', name: 'Company' },
    ]}
    alwaysOn
  />,
  <NullableBooleanInput
    style={{ width: 220 }}
    source="isGuarantor"
    key="isGuarantor"
    label="Default of Guarantor docs"
    alwaysOn
    trueLabel="Yes"
    falseLabel="No"
  />,
  <NullableBooleanInput
    style={{ width: 220 }}
    source="isOptional"
    key="isOptional"
    label="Is optional"
    alwaysOn
    trueLabel="Yes"
    falseLabel="No"
  />,
  <SelectInput
    style={{ width: 250 }}
    source="category"
    key="category"
    label="Category grouped"
    choices={categoryOptions}
    alwaysOn
  />,
  <NullableBooleanInput
    style={{ width: 100 }}
    source="isVisible"
    key="isVisible"
    label="Is visible"
    trueLabel="Yes"
    falseLabel="No"
  />,
  <SelectArrayInput
    style={{ width: 100 }}
    key="excludedTiers"
    source="excludedTiers"
    label="Excluded for Tier"
    choices={dealerTiersChoices}
    className={'MS-field'}
  />,
];
