import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';

import { Doctype, Guarantor } from '@types';
import { InitialCard } from '@components';
import { TickWithLabel } from './credit-report';
import cls from './credit-report.module.css';

interface CreditReportGuarantorProps {
  guarantor: Guarantor;
  guarantorDoctypes: Doctype[];
  setGuarantorDocumentsCompleted: Dispatch<SetStateAction<boolean>>;
  isNotRequired?: boolean;
}
export const CreditReportGuarantor = ({
  guarantor, guarantorDoctypes, setGuarantorDocumentsCompleted, isNotRequired,
}: CreditReportGuarantorProps) => {
  moment.locale('en');

  const workSince = useMemo(() => {
    let date = '';
    if (guarantor) {
      const [, month, year] = guarantor.workSince?.split('/') || [];

      date = !year || !month ? '' : moment(`${year}-${month}`).format('MMMM / YYYY');
    }

    return date;
  }, [guarantor?.workSince]);

  useEffect(() => {
    const guarantorDoctypesIds = guarantorDoctypes.map(d => d.id);
    let savedDocumentsCount = 0;
    guarantorDoctypesIds.forEach((doctypeId) => {
      const savedDocument = guarantor?.documents.find(document => document.doctypeId === doctypeId);

      if (savedDocument) {
        savedDocumentsCount++;
      }
    });

    setGuarantorDocumentsCompleted(
      savedDocumentsCount > 0 && savedDocumentsCount === guarantorDoctypesIds.length,
    );
  }, [guarantor, guarantorDoctypes]);

  return (
    <InitialCard
      boxClass={cls.verificationGuarantor}
      contentClass={cls.guarantorContent}
      autoHeight
    >
      <div className={cls.guarantorVerification}>
        {guarantorDoctypes.map(doctype => {
          const isValid = !!guarantor?.documents.find(d => d.doctypeId === doctype.id);
          return (
            <TickWithLabel
              key={doctype.nameMx}
              label={doctype.nameMx}
              isValid={isValid || !!isNotRequired}
            />
          );
        })}
      </div>
      {!guarantor && (
        <div className={cls.guarantor}>
          <div className={cls.guarantorTitle}>Aval</div>
          <div className={cls.guarantorName}>No guarantor</div>
        </div>
      )}
      {guarantor && (
        <div className={cls.guarantor}>
          <div className={cls.guarantorTitle}>Aval</div>
          <div className={cls.guarantorName}>{guarantor.fullName}</div>
          <div className={cls.guarantorLabel}>{guarantor.profession}</div>
          <div className={cls.guarantorDate}>{workSince}</div>
        </div>
      )}
    </InitialCard>
  );
};
