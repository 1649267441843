import { useRecordContext } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { FormLabel } from '@mui/material';

const useStyles = makeStyles({
  json: {
    fontSize: 12,
    maxWidth: 350,
  },
});

interface Props {
  source: string;
  fullWidth?: boolean;
  label?: string;
}

export const JsonField = ({ source, fullWidth, label }: Props) => {
  const classes = useStyles();
  const record = useRecordContext();
  const json = record[source];

  if (!record) {
    return null;
  }

  return (
    <div className={classes.json} style={{ maxWidth: fullWidth ? '100%' : undefined }}>
      {label && <FormLabel>{label}</FormLabel>}
      <pre>
        {JSON.stringify(json || {}, null, 2)}
      </pre>
    </div>
  );
};
