import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

import { ChatManagers, ChatMessage } from '@types';
import { dateFormatter } from '@utils';
import { LightTooltip } from '@components';
import { UserIcon } from './user-icon';

interface ChatMessagesProps {
  messages: ChatMessage[];
  adminId?: number;
  managers?: ChatManagers;
}

const MAX_LENGTH = 520;

export const ChatMessages = ({ messages, adminId, managers }: ChatMessagesProps) => {
  const cls = useStyles();
  const admins = managers?.adminsById || {};

  if (!messages[0]) {
    return null;
  }

  return (
    <div className={cls.messages}>
      {messages.map(message => {
        const text = message.message && message.message.length > MAX_LENGTH
          ? message.message.slice(0, MAX_LENGTH) + '...'
          : message.message;
        const textLexemas = text.split('@@@');

        return (
          <div key={message.id} className={clsx(
            cls.message,
            adminId && adminId === message.adminId && cls.messageMine,
          )}>
            <div className={cls.title}>
              <UserIcon />
              <div className={cls.author}>{message.author}</div>
            </div>
            <div className={cls.text}>
              {textLexemas.map((lexema, i) => {
                if (lexema.charAt(0) === '-' && lexema.charAt(lexema.length - 1) === '-') {
                  const adminId = lexema.replace(/-/g, '');
                  const admin = admins[adminId];
                  if (admin) {
                    return (
                      <LightTooltip extraWidth title={(
                        <div className={cls.admin}>
                          <div className={cls.adminIcon}><UserIcon /></div>
                          <div>
                            <div className={cls.adminName}>{admin.name}</div>
                            {admin.email && <div className={cls.adminEmail}>{admin.email}</div>}
                          </div>
                        </div>
                      )} key={i}>
                        <span className={cls.mention}>@{admin.name}</span>
                      </LightTooltip>
                    );
                  }
                }

                return (<span key={i} className={cls.textBlock}>{lexema}</span>);
              })}
            </div>
            <div className={cls.date}>
              {dateFormatter.toDateTime(message.createdAt)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles({
  messages: {},
  message: {
    marginTop: 15,
    marginRight: 45,
    padding: '14px 16px 8px',
    borderRadius: 8,
    border: '2px solid #042E6B',
    backgroundColor: 'rgba(225, 229, 237, 0.00)',
    color: '#000',
    wordBreak: 'break-all',
    '&:last-child': {
      marginBottom: 15,
    },
  },
  messageMine: {
    marginLeft: 40,
    marginRight: 5,
    backgroundColor: '#E1E5ED',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  author: {
    marginLeft: 6,
    fontSize: 10,
    fontWeight: 300,
  },
  text: {
    marginTop: 7,
    fontSize: 12,
    fontWeight: 300,
    whiteSpace: 'initial',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
  },
  textBlock: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  date: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#042E6B',
    fontSize: 10,
    fontWeight: 600,
  },
  mention: {
    padding: '3px 5px',
    backgroundColor: 'lightblue',
    color: '#042E6B',
    borderRadius: 5,
  },
  admin: {
    display: 'flex',
    alignItems: 'center',
  },
  adminIcon: {
    marginRight: 15,
  },
  adminName: {
    fontSize: 15,
    lineHeight: 1,
    fontWeight: 'bold',
  },
  adminEmail: {
    borderTop: '1px solid #042E6B',
    marginTop: 5,
    paddingTop: 1,
  },
});
