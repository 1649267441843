import { FunctionField, ListProps, TextField } from 'react-admin';
import React from 'react';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
} from '@components';
import { Guarantor } from '@types';
import { useExporter, useRbacList } from '@hooks';
import cls from './Contracts.module.css';

export const ContractsList = (props: ListProps) => {
  const rbacList = useRbacList();

  const exporter = useExporter({
    filename: 'Verqor Farmers',
    props: {
      'id': {
        label: 'ID',
      },
      'creditCode': {
        label: 'Credit Code',
      },
      'accredited': {
        label: 'Accredited',
      },
      'rfc': {
        label: 'RFC',
      },
      'guarantors': {
        label: 'Guarantors',
        transform: (guarantors: Guarantor[]) => {
          return guarantors.map(g => g.fullName).filter(Boolean).join(', ');
        },
      },
    } });

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      hideRemoveButton
      exporter={exporter}
      className={cls.gridList}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'id creditCode accredited rfc guarantors'.split(' ')}
      >
        <TextField source="id" />
        <TextField source="creditCode" />
        <TextField source="accredited" />
        <TextField source="rfc" label="RFC" />
        <FunctionField resource="contract" source="guarantors" label="Guarantors"
          render={(record: any) => record.guarantors.length > 0 ? (
            record.guarantors.map((g: Guarantor) => g.fullName).join(', ')
          ) : ''}
        />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
];
