import { makeStyles } from '@mui/styles';
import { InputNumber } from 'antd';
import { DollarCircleOutlined } from '@ant-design/icons';
import { useEffect, useRef } from 'react';

interface Props {
  titleBold?: string;
  title: string;
  amount: number;
  setAmount?: (value: number) => void;
  editable: boolean;
}

export const BankAmount = ({ titleBold, title, amount, setAmount, editable }: Props) => {
  const cls = useStyes();
  const amountRef = useRef<any>();

  useEffect(() => {
    if (editable) {
      setTimeout(() => amountRef.current?.focus(), 100);
      setTimeout(() => amountRef.current?.focus(), 300);
    }
  }, []);

  return (
    <div className={cls.box}>
      <div className={cls.title}>
        {titleBold && <span className={cls.titleBold}>{titleBold}</span>}
        {title}
      </div>
      <InputNumber
        ref={amountRef}
        prefix={<DollarCircleOutlined className={cls.amountIcon} />}
        value={amount}
        controls={editable ? undefined : false}
        onKeyDown={editable ? undefined : e => e.preventDefault()}
        onChange={cost => {
          if (setAmount) setAmount(+`${cost}`);
        }}
        className={cls.amountInput}
        min={0} max={999999999}
      />
    </div>
  );
};

const useStyes = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: '#042E6B',
    fontSize: 17,
    marginRight: 15,
    fontWeight: 500,
  },
  titleBold: {
    fontSize: 18,
    fontWeight: 700,
    marginRight: 10,
  },
  amountInput: {
    width: 160,
    height: 37,
    lineHeight: '37px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    overflow: 'hidden',
  },
  amountIcon: {
    color: '#CCC',
    marginRight: 10,
  },
});
