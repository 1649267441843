import { Http } from './http';
import { FinanceDashboardActivationsProps, FinanceDashboardBalance } from '@types';

class FinanceDashboardHttp extends Http {
  async getBalance(): Promise<FinanceDashboardBalance> {
    const res = await this.get<FinanceDashboardBalance>('/finance-dashboard/balance');

    return res.data;
  }

  async getBalanceGraph(range: string, type: PeriodType): Promise<BalanceGraphData[]> {
    let url = `/finance-dashboard/balance-graph?type=${type}`;
    if (range) {
      url += `&range=${range}`;
    }
    const res = await this.get<BalanceGraphData[]>(url);

    return res.data;
  }

  async getActivations(): Promise<FinanceDashboardActivationsProps> {
    const res = await this.get<FinanceDashboardActivationsProps>('/finance-dashboard/activations');

    return res.data;
  }
}

export type PeriodType = 'day' | 'week' | 'month' | 'year';

export interface BalanceGraphData {
  period: string;
  values: {
    deposit: { credits: number, operations: number };
    withdrawal: { credits: number, operations: number };
  };
}

export const financeDashboardHttp = new FinanceDashboardHttp();
