import React, { useCallback, useState } from 'react';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import { FunctionField, ImageFieldProps } from 'react-admin';
import 'react-medium-image-zoom/dist/styles.css';

import { resolvePath } from '@components/fields/resolve-path';

interface ImgProps {
  url?: string;
  alt?: string;
  round?: boolean;
  width?: number;
  maxHeight?: number;
}

export const ImageField = (props: ImageFieldProps & ImgProps) => {
  const { width, round, maxHeight, url, alt, ...restProps } = props;

  return (
    <FunctionField
      sortable={false}
      {...restProps}
      render={(record: any) => {
        const href = url || resolvePath(record, props.source || '');
        return href ? (
          <ImageWidget
            url={href}
            alt={alt}
            round={round}
            width={width}
            maxHeight={maxHeight}
          />
        ) : null;
      }}
    />
  );
};

export const ImageWidget = (props: ImgProps) => {
  const { url, alt, round, width, maxHeight } = props;

  const [isZoomed, setIsZoomed] = useState(false);
  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom);
  }, []);
  const onClick = useCallback(e => {
    e.stopPropagation();
    setIsZoomed(!isZoomed);
  }, [isZoomed]);

  return url ? (
    <div onClick={onClick}>
      <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
        <img src={url} alt={alt || 'preview'} style={{
          maxWidth: width || 200,
          maxHeight: maxHeight || 250,
          borderRadius: round ? '50%' : undefined,
          boxShadow: '0 4px 8px 0 rgba(48, 55, 61, 0.2)',
        }} />
      </ControlledZoom>
    </div>
  ) : null;
};
