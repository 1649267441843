class InputParser {
  lettersAndSpace(value: string) {
    return value.replace(/[^a-zA-Zа-яёА-ЯЁ ]/gi, '');
  }
  onlyEngLetters(value: string) {
    return value.replace(/[^a-zA-Z]/gi, '');
  }
  onlyNumbers(value: string) {
    return value.replace(/[^0-9]/gi, '');
  }
}

export const inputParser = new InputParser();
