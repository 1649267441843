import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import InputLabel from '@mui/material/InputLabel';

interface Props {
  source: string;
  label?: string | false;
  height?: number;
  plugins?: string;
  toolbar?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium' | 'large' | undefined;
  required?: boolean;
  maxCount?: number;
  helperText?: string;
  className?: string;
}

export const EditorInput = ({
  label, size, required, maxCount, height, plugins, toolbar, source, helperText, className,
}: Props) => {
  const {
    field: { value, onChange },
  } = useController({ name: source });

  const getLength = (newValue: string): number => {
    const val = `${newValue}`.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '');
    return val.length;
  };

  const [length, setLength] = useState(getLength(value));

  const handleChange = (newValue: string) => {
    const newLength = getLength(newValue);
    if (maxCount && newLength > maxCount) {
      return;
    }
    onChange(newValue);
    setLength(newLength);
  };

  const getLabel = () => {
    if (label === false) {
      return '';
    }
    if (label === undefined) {
      return source.charAt(0).toUpperCase() + source.slice(1);
    }
    return label;
  };

  return (
    <div className={className}>
      <InputLabel>{getLabel()}</InputLabel>
      <Editor
        value={value}
        onEditorChange={handleChange}
        apiKey="oph1re1rbxqlrcim9vh1z8z42fo2mtx7jvvbkuupzh9nn5q6"
        init={{
          height: height || 500,
          menubar: false,
          plugins: plugins || 'advlist autolink lists link image charmap table code help',
          toolbar_mode: 'wrap',
          toolbar: toolbar || 'undo redo formatselect '
            + 'bold italic backcolor | bullist numlist outdent indent table '
            + 'removeformat code help | '
            + 'alignleft aligncenter alignright alignjustify ',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
      {maxCount || helperText ? (
        <div>
          {maxCount ? `${maxCount - length}` : helperText}
        </div>
      ) : null}
    </div>
  );
};
