import React from 'react';
import { ListProps, TextField } from 'react-admin';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  MoneyField,
  SelectArrayInput,
  DateField,
  SelectInput,
  PercentField,
} from '@components';
import { useExporter, useRbacList } from '@hooks';
import { useCreditOptions } from '@pages/finance-credit/use-credit-options';

const opts = (options: any) => (options || []).map((opt: any) => ({
  id: typeof opt === 'string' ? opt : opt.id,
  name: typeof opt === 'string' ? opt : (opt.name || opt.title),
}));

export const FinanceCreditList = (props: ListProps) => {
  const rbacList = useRbacList();
  const exporter = useExporter({
    filename: 'Other Credits list',
    props: {
      'code': {
        label: 'Code',
      },
      'accredited': {
        label: 'Accredited',
      },
      'bankName': {
        label: 'NBFI',
      },
      'status': {
        label: 'Status',
      },
      'signatureDate': {
        label: 'Signature Date',
        transform: 'date',
      },
      'paymentDate': {
        label: 'Payment Date',
        transform: 'date',
      },
      'region': {
        label: 'Region',
      },
      'stateName': {
        label: 'State',
      },
      'financedHaFormatted': {
        label: 'Financed Ha',
      },
      'cropNames': {
        label: 'Crops',
      },
      'insuranceCompany': {
        label: 'Insurance C.',
      },
      'delayDaysFormatted': {
        label: 'Delay days',
      },
      'creditAmount': {
        label: 'Credit Amount',
        transform: 'money',
      },
      'verqorPayAmount': {
        label: 'Verqor Paga',
        transform: 'money',
      },
      'ordersAmount': {
        label: 'Orders',
        transform: 'money',
      },
      'membershipAmount': {
        label: 'Membership',
        transform: 'money',
      },
      'cogsAmount': {
        label: 'COGS',
        transform: 'money',
      },
      'commissionsAmount': {
        label: 'Commissions',
        transform: 'money',
      },
      'initialBalanceAmount': {
        label: 'Initial Balance',
        transform: 'money',
      },
      'salesMarginAmount': {
        label: 'Sales Margin',
        transform: 'money',
      },
      'profitAmount': {
        label: 'Profit',
        transform: 'money',
      },
      'revenueAmount': {
        label: 'Revenue',
        transform: 'money',
      },
      'currentBalanceAmount': {
        label: 'Current Balance',
        transform: 'money',
      },
      'marginPercent': {
        label: 'Margin',
        transform: 'percent',
      },
      'mainDebtAmount': {
        label: 'Main Debt',
        transform: 'money',
      },
      'debtWithFiAmount': {
        label: 'Debt with FI',
        transform: 'money',
      },
    },
  });
  const options = useCreditOptions();


  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props} exporter={exporter} sort={{ field: 'id', order: 'DESC' }} filters={[
      <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
      <SelectInput
        label="Status"
        key="status"
        source="status"
        choices={opts(options?.statuses)}
        alwaysOn
        style={{ width: 230 }}
      />,
      <SelectArrayInput
        label="Bank"
        key="banksIds"
        source="bankIds"
        choices={opts(options?.banks)}
        alwaysOn
        style={{ width: 230 }}
      />,
      <SelectArrayInput
        label="State"
        key="stateIds"
        source="stateIds"
        choices={opts(options?.states)}
        style={{ width: 400 }}
      />,
      <SelectArrayInput
        label="Crops"
        key="cropIds"
        source="cropIds"
        choices={opts(options?.crops)}
        style={{ width: 400 }}
      />,
    ]}>
      <Grid
        rowClick="edit"
        defaultColumns={'code accredited bankName status signatureDate paymentDate creditAmount'.split(' ')}
      >
        <TextField source="code" label="Code" />
        <TextField source="accredited" label="Accredited" />
        <TextField source="bankName" label="NBFI" />
        <TextField source="status" label="Status" />
        <DateField source="signatureDate" label="Signature Date" />
        <DateField source="paymentDate" label="Signature Date" />
        <TextField source="region" label="Region" />
        <TextField source="stateName" label="State" />
        <TextField source="financedHaFormatted" sortBy="financedHa" label="Financed Ha" />
        <TextField source="cropNames" label="Crops" sortable={false} />
        <TextField source="insuranceCompany" label="Insurance C." />
        <TextField source="delayDaysFormatted" sortBy="delayDays" label="Delay days" />
        <MoneyField source="creditAmount" label="Credit" />
        <MoneyField source="verqorPayAmount" label="Verqor Paga" />
        <MoneyField source="ordersAmount" label="Orders" />
        <MoneyField source="membershipAmount" label="Membership" />
        <MoneyField source="cogsAmount" label="COGS" />
        <MoneyField source="commissionsAmount" label="Commissions" />
        <MoneyField source="initialBalanceAmount" label="Initial Balance" />
        <MoneyField source="salesMarginAmount" label="Sales Margin" />
        <MoneyField source="profitAmount" label="Profit" />
        <MoneyField source="revenueAmount" label="Revenue" />
        <MoneyField source="currentBalanceAmount" label="Current Balance" />
        <PercentField source="marginPercent" label="Margin" />
        <MoneyField source="mainDebtAmount" label="Main Debt" />
        <MoneyField source="debtWithFiAmount" label="Debt with FI" />
      </Grid>
    </GridList>
  );
};
