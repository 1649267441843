import { Col, Row } from 'antd';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useRedirect } from 'react-admin';
import { PlusOutlined, RightCircleFilled } from '@ant-design/icons';

import { UserCard } from './user-card';
import { CreditFarm, User } from '@types';
import { Farms } from '@pages/user/Farms';
import { UserHubspot } from './UserHubspot';
import { UserOrders } from './UserOrders';
import { UserCredits } from './user-credits';
import { rbac, showAxiosError } from '@utils';
import { creditFarmHttp, creditHttp } from '@network';
import { InitialHistory, InitialPage } from '@components';
import { UserTimeTracker } from '@pages/user/UserInitial/user-time-tracker';

import cls from './user-initial.module.css';
import { UserMoffin } from '@pages/user/UserInitial/UserMoffin';

export const UserInitial = ({ user }: {user: User}) => {
  const redirect = useRedirect();
  const [farms, setFarms] = useState<CreditFarm[]>([]);

  const createDraftCredit = async () => {
    try {
      const draft = await creditHttp.createCredit({ userId: user.id, isAdminDraft: true });
      if (draft) {
        redirect(`/credit/${draft.id}`);
      }
    } catch (err: any) {
      showAxiosError(err);
    }
  };

  const hasDraftCreditRole = rbac.hasDraftCredit();
  const hasCredits = !!user?.credits?.length;
  const hasDraftCredits = !!user?.credits?.find(c => c.isAdminDraft);

  const showAddDraftButton = !hasDraftCredits && hasDraftCreditRole;

  useEffect(() => {
    (async () => {
      const nextFarms = await creditFarmHttp.getFarmsOfUser(user.id);
      setFarms(nextFarms);
    })();
  }, [user.id]);

  return (
    <InitialPage title="Individual User" backTo="/user" backText="Back to all registered users">
      <Row gutter={[20, 20]} className={cls.infoline}>
        <Col xs={24} lg={12} xl={8} className={cls.infolineCard}>
          <UserCard user={user} />
        </Col>
        <Col xs={24} lg={12} xl={8} className={cls.infolineCard}>
          <UserHubspot user={user} />
        </Col>
        <Col xs={24} lg={12} xl={8} className={cls.infolineCard}>
          <InitialHistory
            title={
              <Link to={`/user/${user.id}?history`} className={cls.historyTitle}>
                <span>History</span>
                <RightCircleFilled />
              </Link>}
            className={cls.history}
            history={user.history || []}
            author={user.fullName}
            autoHeight={false}
            showAll
          />
        </Col>
        <Col xs={24} lg={12} xl={8} className={cls.infolineCard}>
          <Farms farms={farms} uid={user.id} noLink />
        </Col>
        <Col xs={24} lg={12} xl={8} className={cls.infolineCard}>
          <UserMoffin user={user} />
        </Col>
        <Col xs={24} lg={12} xl={8} className={cls.infolineCard}>
          <UserTimeTracker user={user} />
        </Col>
      </Row>
      <div className={cls.creditsWrap}>
        <div className={cls.creditsHead}>
          <div className={cls.title}>Credits</div>

          {showAddDraftButton && (
            <Button variant="contained" className={cls.addDraft}
              onClick={createDraftCredit}>
              <PlusOutlined /> Create Draft
            </Button>
          )}
        </div>

        {hasCredits ? <UserCredits user={user} /> : <div>No credits yet</div>}
        <UserOrders userId={user.id} />
      </div>
    </InitialPage>
  );
};
