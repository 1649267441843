import React from 'react';
import { ListProps, NumberField, TextField } from 'react-admin';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  DateRangeInput,
  DateField,
  MoneyField,
  PercentField,
} from '@components';
import { useExporter, useRbacEdit, useRbacList } from '@hooks';
import { LoanContractListActions } from './LoanContractListActions';

export const LoanContractList = (props: ListProps) => {
  const rbacList = useRbacList();
  const rbacEdit = useRbacEdit();
  const exporter = useExporter({
    filename: 'Loan Contracts list',
    props: {
      'id': {
        label: 'ID',
      },
      'borrower': {
        label: 'Borrower',
      },
      'creditCode': {
        label: 'Credit Code',
      },
      'typeOfLoan': {
        label: 'Type of Loan',
      },
      'typeOfPayments': {
        label: 'Type of Payments',
      },
      'product': {
        label: 'Product',
      },
      'signatureDate': {
        label: 'Signature Date',
        transform: 'date',
      },
      'paymentDate': {
        label: 'Payment Date',
        transform: 'date',
      },
      'expectedPayments': {
        label: 'Expected Payments',
      },
      'periodicity': {
        label: 'Periodicity',
      },
      'paymentPerPeriod': {
        label: 'Payment per Period',
      },
      'maxLoanAmount': {
        label: 'Max Loan Amount',
        transform: 'money',
      },
      'loanAmount': {
        label: 'Loan Amount',
        transform: 'money',
      },
      'membership': {
        label: 'Membership',
        transform: 'money',
      },
      'interestRate': {
        label: 'Interest Rate',
        transform: 'percent',
      },
      'interestTax': {
        label: 'Interest Tax',
        transform: 'percent',
      },
      'moratoryInterestRate': {
        label: 'Moratory Interest Rate',
        transform: 'percent',
      },
      'disposalFee': {
        label: 'Disposal Fee',
        transform: 'percent',
      },
      'disposalFeeTax': {
        label: 'Disposal Fee Tax',
        transform: 'percent',
      },
      'dpd': {
        label: 'DPD',
      },
      'daysOnAlert': {
        label: 'Days on Alert',
      },
      'typeOfClient': {
        label: 'Type of Client',
      },
      'accredited': {
        label: 'Accredited',
      },
      'userFullname': {
        label: 'Legal Representative',
      },
      'userBirthday': {
        label: 'Date of birth',
        transform: 'date',
      },
      'userRfc': {
        label: 'RFC',
      },
      'userAddress': {
        label: 'Colony',
      },
      'userState': {
        label: 'State',
      },
      'userZip': {
        label: 'ZIP',
      },
      'opb': {
        label: 'OPB',
        transform: 'money',
      },
      'cropCategory': {
        label: 'Crop Category',
      },
      'crop': {
        label: 'Crop',
      },
      'cropState': {
        label: 'Crop State',
      },
      'cropCity': {
        label: 'Crop City',
      },
      'cropZone': {
        label: 'Crop Zone',
      },
      'currentNumberOfHarvests': {
        label: 'Current Number of Harvests',
      },
      'verqorScore': {
        label: 'Verqor Score',
      },
      'creditScore': {
        label: 'Credit Score',
      },
      'debtToAssetsRatio': {
        label: 'Debt to assets ratio',
        transform: 'percent',
      },
      'returnOnEquity': {
        label: 'Return on equity',
        transform: 'percent',
      },
      'debtToEquityRatio': {
        label: 'Debt to equity Ratio',
        transform: 'percent',
      },
      'dscr': {
        label: 'DSCR',
        transform: 'percent',
      },
      'cashRatio': {
        label: 'Cash Ratio',
        transform: 'percent',
      },
      'insuranceCompany': {
        label: 'Insurance Company',
      },
      'insurancePolicyAmount': {
        label: 'Insurance Policy Amount',
        transform: 'money',
      },
      'createdAt': {
        label: 'Added at',
        transform: 'date',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      editComponent={rbacEdit ? <LoanContractListActions /> : undefined}
      filters={Filters}
      sort={{ field: 'signatureDate', order: 'DESC' }}
      exporter={exporter}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'borrower creditCode typeOfLoan typeOfPayments product signatureDate paymentDate'.split(' ')}
        centered
      >
        <TextField source="id" />
        <TextField source="borrower" label="Borrower" />
        <TextField source="creditCode" label="Credit Code" />
        <TextField source="typeOfLoan" label="Type of Loan" />
        <TextField source="typeOfPayments" label="Type of Payments" />
        <TextField source="product" label="Product" />
        <DateField source="signatureDate" label="Signature Date" />
        <DateField source="paymentDate" label="Payment Date" />
        <NumberField source="expectedPayments" label="Expected Payments" />
        <NumberField source="periodicity" label="Periodicity" />
        <NumberField source="paymentPerPeriod" label="Payment per Period" />
        <MoneyField source="maxLoanAmount" label="Max loan amount" />
        <MoneyField source="loanAmount" label="Loan Amount" />
        <MoneyField source="membership" label="Membership" />
        <PercentField source="interestRate" label="Interest Rate" />
        <PercentField source="interestTax" label="Interest Tax" />
        <PercentField source="moratoryInterestRate" label="Moratory Interest Rate" />
        <PercentField source="moratoryTax" label="Moratory Tax" />
        <PercentField source="disposalFee" label="Disposal Fee" />
        <PercentField source="disposalFeeTax" label="Disposal Fee Tax" />
        <TextField source="dpd" label="DPD" />
        <NumberField source="daysOnAlert" label="Days on Alert" />
        <TextField source="typeOfClient" label="Type of Client" />
        <TextField source="accredited" label="Accredited" />
        <TextField source="userFullname" label="Legal Representative" />
        <DateField source="userBirthday" label="Date of birth" />
        <TextField source="userRfc" label="RFC" />
        <TextField source="userAddress" label="Colony" />
        <TextField source="userState" label="State" />
        <TextField source="userZip" label="ZIP" />
        <MoneyField source="opb" label="OPB" />
        <TextField source="cropCategory" label="Crop Category" />
        <TextField source="crop" label="Crop" />
        <TextField source="cropState" label="Crop State" />
        <TextField source="cropCity" label="Crop City" />
        <TextField source="cropZone" label="Crop Zone" />
        <NumberField source="currentNumberOfHarvests" label="Current Number of harvests" />
        <NumberField source="verqorScore" label="Verqor Score" />
        <NumberField source="creditScore" label="Credit Score" />
        <PercentField source="debtToAssetsRatio" label="Debt to assets ratio" />
        <PercentField source="returnOnEquity" label="Return on equity" />
        <PercentField source="debtToEquityRatio" label="Debt to equity Ratio" />
        <PercentField source="dscr" label="DSCR" />
        <PercentField source="cashRatio" label="Cash Ratio" />
        <TextField source="insuranceCompany" label="Insurance Company" />
        <MoneyField source="insurancePolicyAmount" label="Insurance Policy Amount" />
        <DateField source="createdAt" label="Added at" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  <DateRangeInput key="signatureDate" source="signatureDate" label="Signature Date"
    defaultValue={false} alwaysOn />,
  <DateRangeInput key="paymentDate" source="paymentDate" label="Payment Date"
    defaultValue={false} alwaysOn />,
];
