import { TextInputProps } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { useWatch } from 'react-hook-form';
import { ExportOutlined } from '@ant-design/icons';

import { TextInput } from './RA';
import { AntdDynamicIcon } from '../AntdDynamicIcon';

interface ExtraProps {
  iconClassName?: any;
}

export const AntdIconInput = (props: TextInputProps & ExtraProps) => {
  const value = useWatch({ name: props.source });
  const cls = useStyles();
  const { iconClassName, ...restProps } = props;

  return (
    <>
      <div className={cls.box}>
        <TextInput
          InputLabelProps={{ shrink: true }}
          placeholder="AlertFilled"
          {...restProps}
          label={props.label || 'Antd icon example: AlertFilled'}
        />
        {value && (
          <AntdDynamicIcon type={value} className={cls.icon} />
        )}
      </div>
      <a className={cls.hint}
        href="https://ant.design/components/icon/"
        target="_blank"
        rel="noreferrer"
      >
        <ExportOutlined /> link of all Antd icons
      </a>
    </>
  );
};

const useStyles = makeStyles({
  box: {
    width: '100%',
    display: 'flex',
  },
  icon: {
    fontSize: 34,
    color: '#333',
  },
  hint: {
    display: 'block',
    zIndex: 10,
    marginTop: -10,
  },
});
