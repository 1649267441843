import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { useController, useWatch } from 'react-hook-form';

import { SupportFiles } from '../../components';
import { Container, InitialCard, InitialRow, SelectInput, TextInput } from '@components';
import cls from './ManualsForm.module.css';
import { useEffect } from 'react';

const platformsOptions: { id: string; name: string }[] = [
  { id: 'Client', name: 'Client' },
  { id: 'Admin', name: 'Admin' },
  { id: 'Partners', name: 'Partners' },
];

export const validate = (values: any) => {
  const reqList = ['name', 'platform', 'team', 'description', 'fileIds'];
  const errors: any = {};

  reqList.forEach((key: string) => {
    if (!values[key]) {
      errors[key] = 'This field is required';
    }
  });
  if (!values['fileIds']?.length) {
    errors['fileIds'] = 'This field is required';
  }

  return errors;
};

export const ManualsForm = () => {
  const labelSize = 7;
  const id = useWatch({ name: 'id' });
  const { field: { onChange: setFileIds }, fieldState: { error } } = useController({ name: 'fileIds' });
  const { field: { value: documents, onChange: setDocuments } } = useController({ name: 'documents' });

  useEffect(() => {
    if (id && documents.length) {
      setFileIds([...documents.map(d => d.file.id)]);
    }
  }, [id, documents]);

  return (
    <Container max>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <InitialCard extraPadding>
            <Row gutter={{ xs: 24, lg: 24, xl: 120 }}>
              <Col xs={24} lg={12}>
                <InitialRow title="Title" fontLg labelSize={labelSize} className={clsx(cls.fieldRow, cls.txtField)}>
                  <TextInput source="name" autoFocus inputProps={{ maxLength: 50 }}/>
                </InitialRow>
                <InitialRow title="Platform" fontLg labelSize={labelSize} className={clsx(cls.fieldRow, cls.txtField)}>
                  <SelectInput source="platform" choices={platformsOptions} required label="" />
                </InitialRow>
                <InitialRow title="Team" fontLg labelSize={labelSize} className={clsx(cls.fieldRow, cls.txtField)}>
                  <TextInput source="team" inputProps={{ maxLength: 50 }}/>
                </InitialRow>
                <InitialRow title="Description" fontLg fullWidth className={clsx(cls.fieldRow, cls.descField)}>
                  <TextInput source="description" multiline inputProps={{ maxLength: 300 }}/>
                </InitialRow>
              </Col>
              <Col xs={24} lg={12}>
                <SupportFiles
                  files={documents}
                  setFiles={setFileIds}
                  setDocuments={setDocuments}
                  error={error}
                  title="Upload Document"
                />
              </Col>
            </Row>
          </InitialCard>
        </Col>
      </Row>
    </Container>
  );
};
