import { useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import moment from 'moment-timezone';

import { axiosErrorToString } from '@utils';
import { blogHttp } from '@network';
import { useRbacCreate } from '@hooks';
import { BlogForm } from '@pages/blog/BlogForm';
import { Forbidden, InitialPage } from '@components';

const initialFormValues = {
  title: '',
  announce: '',
  description: '',
  author: '',
  keywords: '',
  isEnabled: false,
  isLastNews: false,
  isMain: false,
  isRecommendation: false,
  image: '',
  imageAlt: '',
  publishAt: moment(new Date()).toISOString(),
};

export type BlogFormValues = typeof initialFormValues;

export const BlogCreate = () => {
  const rbacCreate = useRbacCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const [formValues, setFormValues] = useState<BlogFormValues | null>(initialFormValues);

  const submit = async () => {
    if (formValues) {
      try {
        const result = await blogHttp.create(formValues);
        if (result) {
          notify('Article has been created');
          redirect(`/blog/${result.id}`);
        }
      } catch (e) {
        notify(axiosErrorToString(e));
      }
    }
  };

  if (!rbacCreate) {
    return <Forbidden />;
  }

  const backTo = '/blog';

  return (
    <InitialPage title="Blog post create" backTo={backTo} backText="Back to all pages">
      <BlogForm type="create" formValues={formValues} setFormValues={setFormValues} submit={submit} image={formValues?.image} />
    </InitialPage>
  );
};
