import { clsx } from 'clsx';
import { InputNumber } from 'antd';

import cls from './AddemVariablesTable.module.css';

interface RowProps {
  key: string;
  title: string;
  value: number;
}

export interface AddemVariablesProps {
  values: RowProps[];
}

interface Props {
  data: AddemVariablesProps;
  onChange: (value: AddemVariablesProps) => void;
  readonly?: boolean;
}

export const AddemVariablesTable = ({ data, onChange, readonly }: Props) => {
  const handleChange = (row: RowProps, val: number | null): void => {
    if (readonly) return;
    const res = val || 0;
    const newValues = data.values
      .map(v => (v.key === row.key) ? { ...row, value: res } : v);
    onChange({ ...data, values: newValues });
  };

  if (!data || !data.values.length) {
    return null;
  }

  return (
    <div className={cls.block}>
      <div className={cls.table}>
        <div className={cls.header}>ADDEM Variables</div>
        <div className={cls.body}>
          {data.values.map(v => (
            <div className={cls.row} key={v.key}>
              <div className={cls.col}>{v.title}</div>
              <div className={clsx(cls.col, cls.colored)}>
                <span className={cls.inputWrap}>
                  <InputNumber
                    min={0}
                    max={1000000000}
                    formatter={(value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value ? +value.replace(/\$\s?|(,*)/g, '') : 0}
                    defaultValue={v.value}
                    bordered={false}
                    onChange={e => handleChange(v, e)}
                    className={cls.input}
                    onBeforeInputCapture={readonly ? e => e.preventDefault() : undefined}
                    disabled={readonly}
                  />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
