import { useState } from 'react';
import { Button, Modal } from 'antd';
import { CommentOutlined } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

import { dateFormatter } from '@utils';
import { useRequest } from '@hooks';
import { creditHttp } from '@network';
import { Document, DocumentHistory } from '@types';
import { ConfirmDelete } from '@components/ConfirmDelete';
import { HistoryAdminName } from '@pages/credit/HistoryAdminName';
import cls from './document-history-widget.module.css';

interface Props {
  creditId: number;
  history: DocumentHistory[];
  title: string;
  setDocument: (document: Document) => void;
  isSuperadmin: boolean;
}

interface HistoryItemProps {
  creditId: number;
  item: DocumentHistory;
  setDocument: (document: Document) => void;
  isSuperadmin: boolean;
}

const HistoryItem = ({ creditId, item, setDocument, isSuperadmin }: HistoryItemProps) => {
  const { submit: deleteComment, loading } = useRequest(async () => {
    if (item.isComment && !loading) {
      const updatedDocument = await creditHttp.deleteComment({ creditId, commentId: item.id });
      setDocument(updatedDocument);
    }
  });

  return (
    <div className={cls.item}>
      {item.isComment && (
        <div className={cls.itemComment}>
          <CommentOutlined className={cls.commentIcon} />
          {item.comment}
          <IconButton className={cls.removeIcon}>
            <ConfirmDelete title="Are you sure?" cb={deleteComment}>
              <svg style={{ display: 'block' }}
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none">
                <path d="M7 0C3.13461 0 0 3.13461 0 7C0 10.8654 3.13461 14 7 14C10.8654 14 14 10.8654 14 7C13.9938 3.13461 10.8623 0.00310973 7 0Z"
                  fill="#002A77" />
                <path d="M9.63374 4.36293C9.51246 4.24165 9.31655 4.24165 9.19527 4.36293L6.9998 6.5584L4.80433 4.36293C4.68305 4.24165 4.48714 4.24165 4.36586 4.36293C4.24458 4.48421 4.24458 4.68012 4.36586 4.8014L6.56133 6.99687L4.36586 9.19234C4.24458 9.31362 4.24458 9.50953 4.36586 9.63081C4.48714 9.75209 4.68305 9.75209 4.80433 9.63081L6.9998 7.43534L9.19527 9.63081C9.31655 9.75209 9.51246 9.75209 9.63374 9.63081C9.75502 9.50953 9.75502 9.31362 9.63374 9.19234L7.43827 6.99687L9.63374 4.8014C9.75502 4.68012 9.75502 4.48421 9.63374 4.36293Z"
                  fill="white" />
              </svg>
            </ConfirmDelete>
          </IconButton>
        </div>
      )}
      {!item.comment && (
        <>
          <div className={cls.itemTitle}>{item.title}</div>
          <div className={cls.itemStatus}>
            <div>{item.statusBefore}</div>
            <svg xmlns="http://www.w3.org/2000/svg"
              style={{ display: 'inline-block', margin: '0 10px' }}
              width="16"
              height="8"
              viewBox="0 0 16 8"
              fill="none">
              <path d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464467C11.9763 0.269205 11.6597 0.269205 11.4645 0.464467C11.2692 0.659729 11.2692 0.976312 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM-4.37114e-08 4.5L15 4.5L15 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                fill="#042E6B" />
            </svg>
            <div>{item.statusAfter}</div>
          </div>
        </>
      )}
      <div className={cls.timestamp}>
        {item.adminName && item.adminId && !item.managerId && (
          <HistoryAdminName
            id={item.adminId}
            name={item.adminName}
            isSuperadmin={isSuperadmin}
          />
        )}
        {item.managerName && item.managerId && (
          <HistoryAdminName
            id={item.managerId}
            name={item.managerName}
            isSuperadmin={isSuperadmin}
            isManager
          />
        )}
        <div>{dateFormatter.toDateTime(item.createdAt)}</div>
      </div>
    </div>
  );
};

export const DocumentHistoryWidget = ({
  creditId, history, title, setDocument, isSuperadmin,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  return (
    <>
      <div className={cls.box}>
        {history[0] && (
          <HistoryItem
            creditId={creditId}
            item={history[0]}
            setDocument={setDocument}
            isSuperadmin={isSuperadmin}
          />
        )}
        <div className={cls.boxFooter}>
          <Button size="small" onClick={showModal} className={cls.btnShowAll}>
            show all ({history.length})
          </Button>
        </div>
      </div>

      <Modal open={isModalVisible} title={title} onCancel={hideModal} footer={null}>
        {history.map(item => (
          <HistoryItem
            key={item.id}
            creditId={creditId}
            item={item}
            setDocument={setDocument}
            isSuperadmin={isSuperadmin}
          />
        ))}
      </Modal>
    </>
  );
};
