import { Button, ButtonProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const OrangeOutlineButton = (props: ButtonProps) => {
  const cls = useStyles();

  return (
    <Button className={cls.btn} variant="outlined" color="warning" {...props}>
      {props.children}
    </Button>
  );
};


const useStyles = makeStyles({
  btn: {
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 8,
    textTransform: 'none',
    color: '#FD8A32',
    fontWeight: 400,
    fontFamily: 'Outline, sans-serif',
  },
});
