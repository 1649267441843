import { Http } from './http';
import { Dealer, DealerContract, DealerPartial } from '@types';

class DealerHttp extends Http {

  async getById(dealerId: number): Promise<Dealer> {
    const res = await this.get<Dealer>(`dealer/${dealerId}`);

    return res.data;
  }

  async getList(): Promise<Dealer[]> {
    const res = await this.get<Dealer[]>('dealer?_order=ASC&_sort=name&_start=0&_end=999999');

    return res.data;
  }

  async getAssociateList(q?: string): Promise<Dealer[]> {
    const res = await this.get<Dealer[]>(`/dealer-associate?_order=ASC&_sort=name&_start=0&_end=999999&q=${q}`);

    return res.data;
  }

  async getRetailerList(q?: string): Promise<Dealer[]> {
    const res = await this.get<Dealer[]>(`/dealer-retailer?_order=ASC&_sort=name&_start=0&_end=999999&q=${q}`);

    return res.data;
  }

  async getBuyerList(q?: string): Promise<Dealer[]> {
    const res = await this.get<Dealer[]>(`/dealer-buyer?_order=ASC&_sort=name&_start=0&_end=999999&q=${q}`);

    return res.data;
  }

  async merge(id: number, mergeToId: number): Promise<Dealer> {
    const res = await this.delete<Dealer>(`dealer/${id}/${mergeToId}`);

    return res.data;
  }

  async saveDealerContracts(params: DealerContractParams): Promise<DealerContract[]> {
    const res = await this.post<DealerContract[]>('dealer/contract', params);

    return res.data;
  }

  async create(params: DealerPartial, resource: string): Promise<Dealer> {
    const res = await this.post<Dealer>(resource, params);

    return res.data;
  }

  async update(params: Dealer, resource: string): Promise<Dealer> {
    const { id } = params;
    const res = await this.put<Dealer>(`${resource}/${id}`, params);

    return res.data;
  }

  async remove(id: number, resource: string): Promise<Dealer> {
    const res = await this.delete<Dealer>(`${resource}/${id}`);

    return res.data;
  }

  async addBulkActivation(ids: number[], activationId: number) {
    const res = await this.post<void>('/dealer/add-bulk-activation', { ids, activationId });

    return res.data;
  }

  async removeActivation(id: number) {
    const res = await this.patch<string>(`/dealer/${id}/remove-activation`);

    return res.data;
  }
}

export const dealerHttp = new DealerHttp();

interface DealerContractParams {
  userId: number;
  dealerId: number;
  fileIds: number[];
}
