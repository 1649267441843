import { Http } from './http';
import { Contract, ContractStatus } from '@types';

class ContractHttp extends Http {
  prefix = '/contract';

  async create(params: { creditId: number }): Promise<Contract> {
    const res = await this.post<Contract>('', params);

    return res.data;
  }

  async update(params: Contract): Promise<Contract> {
    const res = await this.put<Contract>(`/${params.id}`, params);

    return res.data;
  }

  async setStatus(params: { status: ContractStatus, id: number }): Promise<any> {
    const res = await this.patch<any>(`/set-status/${params.id}`, { status: params.status });

    return res.data;
  }

  async sendSign(params: { id: number, fileId: number }): Promise<any> {
    const res = await this.put<any>(`/sign/${params.id}`, { fileId: params.fileId });

    return res.data;
  }
}

export const contractHttp = new ContractHttp();
