import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PropsWithChildren } from 'react';
import { clsx } from 'clsx';

const useStyles = makeStyles({
  wrap: {
    minHeight: '68px',
  },
  row: {
    marginRight: '-1em',
  },
  center: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
});

interface Props {
  title?: string;
  marginTop?: number;
  vertical?: boolean;
  center?: boolean;
  className?: string;
}

export const Row = (
  { children, title, marginTop, vertical, center, className }: PropsWithChildren<Props>,
) => {
  const cls = useStyles();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <div className={clsx(cls.wrap, className)} style={{ marginTop }}>
      {title && <Box mb={1}><Typography variant="h6">{title}</Typography></Box>}
      <Box
        display={isSmall || vertical ? 'block' : 'flex'}
        className={center ? cls.center : cls.row}
      >
        {children}
      </Box>
    </div>
  );
};
