import { useController, useWatch } from 'react-hook-form';
import { useEffect } from 'react';

import { FinanceCreditOptions, financeSettingsHttp } from '@network/finance-settings-http';

export const useCalculation = () => {
  const creditAmount = useWatch({ name: 'creditAmount' });
  const verqorPayAmount = useWatch({ name: 'verqorPayAmount' });
  const ordersAmount = useWatch({ name: 'ordersAmount' });
  const membershipAmount = useWatch({ name: 'membershipAmount' });
  const cogsAmount = useWatch({ name: 'cogsAmount' });
  const commissionsAmount = useWatch({ name: 'commissionsAmount' });

  const {
    field: { onChange: initialBalanceAmountChange },
  } = useController({ name: 'initialBalanceAmount' });
  const {
    field: { onChange: salesMarginAmountChange },
  } = useController({ name: 'salesMarginAmount' });
  const {
    field: { onChange: profitAmountChange },
  } = useController({ name: 'profitAmount' });
  const {
    field: { onChange: revenueAmountChange },
  } = useController({ name: 'revenueAmount' });
  const {
    field: { onChange: currentBalanceAmountChange },
  } = useController({ name: 'currentBalanceAmount' });
  const {
    field: { onChange: marginPercentChange },
  } = useController({ name: 'marginPercent' });

  const calculate = async () => {
    const finCredit = await financeSettingsHttp.calculateCreditAmounts({
      creditAmount,
      verqorPayAmount,
      ordersAmount,
      membershipAmount,
      cogsAmount,
      commissionsAmount,
    });
    initialBalanceAmountChange(finCredit.initialBalanceAmount);
    salesMarginAmountChange(finCredit.salesMarginAmount);
    profitAmountChange(finCredit.profitAmount);
    revenueAmountChange(finCredit.revenueAmount);
    currentBalanceAmountChange(finCredit.currentBalanceAmount);
    marginPercentChange(finCredit.marginPercent);
  };

  useEffect(() => {
    setTimeout(() => void calculate(), 500);
  }, []);

  useEffect(() => {
    calculate();
  }, [
    creditAmount,
    verqorPayAmount,
    ordersAmount,
    membershipAmount,
    cogsAmount,
    commissionsAmount,
  ]);
};
