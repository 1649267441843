import React, { useEffect, useRef, useState } from 'react';
import {
  EditProps, Edit, SaveButton, DeleteButton, SimpleForm, useRefresh, useNotify,
} from 'react-admin';
import { clsx } from 'clsx';
import { Button, Toolbar } from '@mui/material';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment-timezone';

import { userHttp } from '@network';
import { axiosErrorToString } from '@utils';
import { Forbidden, Loader, InitialPage } from '@components';
import { useEditProps, useQuery, useRbacEdit, useRbacView, useRecord } from '@hooks';
import { User } from '@types';
import { UserInitial } from '@pages/user/UserInitial';
import { UserOrdersList } from '@pages/user/UserOrders';
import { UserMoffinPage } from '@pages/user/UserMoffinPage';
import { UserHistoryPage } from '@pages/user/UserHistoryPage';
import { UserHubspotPage } from '@pages/user/UserHubspotPage';
import { UserInfo } from './UserInfo';
import { UserPassword } from '@pages/user/UserEdit/UserPassword';
import { UserComments } from '@pages/user/UserEdit/UserComments';
import { UserAddresses, UserAddressesList } from '@pages/user/UserAddresses';
import { UserFarmsPage } from '@pages/user/UserFarmsPage';

import cls from './UserEdit.module.css';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>User: {record.fullName}</span>) : null;

export const UserEdit = (props: EditProps) => {
  const user = useRecord<User>(props);
  const record = useRecord(props);
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const query = useQuery();
  const editable = useRbacEdit();
  const refresh = useRefresh();
  const notify = useNotify();

  const showInitial = query.get('initial') === '';
  const showFarms = query.get('farms') === '';
  const showOrders = query.get('orders') === '';
  const showMoffin = query.get('moffin') === '';
  const showHistory = query.get('history') === '';
  const showHubspot = query.get('hubspot') === '';
  const showAddresses = query.get('addresses') === '' || query.get('addresses') === 'new';
  const withNewAddress = query.get('addresses') === 'new';

  const [isCompany, setIsCompany] = useState<boolean>(false);
  const [showReminder, setShowReminder] = useState<boolean>(false);
  const reminderDate = useRef<string>('');
  const reminderTime = useRef<string>('');

  useEffect(() => {
    if (record) {
      setIsCompany(!!user?.isCompany);
    }
  }, [record]);

  if (!rbacView) {
    return <Forbidden />;
  }

  const rfcSymbols = 13;
  const rfcCompanySymbols = 12;

  const validateUserCreation = (values: any) => {
    const errors: any = {};
    const rfcLength = (values.rfc || '').length;
    const rfcCompanyLength = (values.rfcCompany || '').length;
    const curpLength = (values.curp || '').length;

    if ((values.password || '').length > 0) {
      // eslint-disable-next-line
      const passwordCheck = /((?=.*[0-9])(?=.*[A-z])(?=.*\d).{8,})/;
      if (!passwordCheck.test(values.password)) {
        errors.password =
          'The password must contain at\u00a0least 1\u00a0letter,' +
          ' 1\u00a0number and length more than 8\u00a0symbols.';
      } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = 'Confirm password should be the same as a password.';
      }
    }

    if (rfcLength > 0 && rfcLength !== rfcSymbols) {
      errors.rfc = `RFC should be ${rfcSymbols} characters`;
    }
    if (rfcCompanyLength > 0 && rfcCompanyLength !== rfcCompanySymbols) {
      errors.rfcCompany = `RFC (Company) should be ${rfcCompanySymbols} characters`;
    }
    if (curpLength > 0 && curpLength < 18) {
      errors.curp = 'CURP should be 18 character';
    }

    return errors;
  };

  const selectReminderDate = (date: any) => {
    reminderDate.current = date.format('YYYY-MM-DD');
  };

  const selectReminderTime = (date: any) => {
    reminderTime.current = date.format('HH:mm');
  };

  const resetReminder = async () => {
    try {
      await userHttp.setContactNextCycle({
        userId: user.id,
        contactNextCycle: null,
      });

      refresh();
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  const saveReminder = async () => {
    try {
      if (!reminderDate.current) {
        notify('Please, choose a reminder date', { type: 'error' });
        return;
      }

      if (!reminderTime.current) {
        notify('Please, choose a reminder time', { type: 'error' });
        return;
      }

      await userHttp.setContactNextCycle({
        userId: user.id,
        contactNextCycle: moment(`${reminderDate.current} ${reminderTime.current}`).format(),
      });

      setShowReminder(false);
      reminderDate.current = '';
      reminderTime.current = '';
      refresh();
    } catch (e) {
      console.error(e);
    }
  };

  const EditActions = () => {
    return editable ? (
      <Toolbar>
        <div className={cls.reminderWrap}>
          {user.contactNextCycle ? (
            <Button
              variant="contained"
              style={{
                backgroundColor: '#0C5541',
                boxShadow: 'none',
                color: '#ffffff',
                textTransform: 'none',
                borderRadius: 8,
                height: 38,
                minWidth: 200,
              }}
              onClick={resetReminder}
            >
              Reactivate User
            </Button>
          ) : (
            <Button
              variant="contained"
              style={{
                backgroundColor: '#fd8a32',
                boxShadow: 'none',
                color: '#ffffff',
                textTransform: 'none',
                borderRadius: 8,
                height: 38,
                minWidth: 200,
              }}
              onClick={() => setShowReminder(true)}
            >
              Set reminder
            </Button>
          )}

          {showReminder && (
            <div className={clsx(cls.reminder)}>
              <div className={cls.reminderTitle}>
                Reminder
              </div>
              <div className={cls.reminderDate}>
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  onChange={selectReminderDate}
                  className={cls.reminderDay}
                  suffixIcon={null}
                  disabledDate={(current) => current.isBefore(moment().subtract(1, 'day'))}
                />
                <TimePicker
                  onChange={selectReminderTime}
                  format="HH:mm"
                  placeholder="00:00"
                  className={cls.reminderTime}
                  suffixIcon={null}
                />
              </div>
              <div className={cls.reminderActions}>
                <Button onClick={() => setShowReminder(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none">
                    <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                      fill="#FF4D4F" />
                  </svg>
                </Button>
                <Button onClick={saveReminder}>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none">
                    <rect width="17" height="17" rx="8.5" fill="#9CCE5C" />
                    <path d="M7.42853 10.5713L11 6.99981"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round" />
                    <path d="M7.42847 10.5713L5.99988 9.1427"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round" />
                  </svg>
                </Button>
              </div>
            </div>
          )}
        </div>

        <SaveButton label="Save" type="button" />
        <DeleteButton />
      </Toolbar>
    ) : null;
  };

  if (showInitial) {
    return user ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <UserInitial user={user} />
      </Edit>
    ) : <Loader />;
  }

  if (showFarms) {
    return user ? (
      <UserFarmsPage user={user} />
    ) : <Loader />;
  }

  if (showOrders) {
    return user ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <UserOrdersList user={user} />
      </Edit>
    ) : <Loader />;
  }

  if (showMoffin) {
    return user ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <UserMoffinPage user={user} />
      </Edit>
    ) : <Loader />;
  }

  if (showHistory) {
    return user ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <UserHistoryPage user={user} />
      </Edit>
    ) : <Loader />;
  }

  if (showHubspot) {
    return user ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <UserHubspotPage user={user} />
      </Edit>
    ) : <Loader />;
  }

  if (showAddresses) {
    return user ? (
      <Edit {...props} title={<Title />} {...editProps}>
        <UserAddressesList user={user} withNewAddress={withNewAddress} />
      </Edit>
    ) : <Loader />;
  }

  const backTo = `/user/${user?.id}?initial`;

  return (
    <InitialPage title="Identity" backTo={backTo} backText="Back to Individual User">
      <Edit {...props} title={<Title />} {...editProps}>
        <SimpleForm toolbar={<EditActions />} validate={validateUserCreation}>
          {user && <UserInfo user={user} />}
          {user && <UserAddresses user={user} />}
          {user && <UserPassword user={user} />}
          {user && <UserComments user={user} editable={editable} />}
        </SimpleForm>
      </Edit>
    </InitialPage>
  );
};
