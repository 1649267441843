import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { http } from '@network';
import cls from './image-with-auth.module.css';

type Props = {
  url: string;
  downloadFilename?: string;
  className?: any;
  downloadIconClassName?: any;
  width?: number;
  height?: number;
}

export const ImageWithAuth = ({
  url, downloadFilename, downloadIconClassName, width, height, className,
}: Props) => {
  const [src, setSrc] = useState<string>();

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const res = await http.get<Buffer>(url, { responseType: 'arraybuffer' });
      isMounted && setSrc(imageBufferToBase64(res.data));
    })();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!src) {
    return (<Skeleton.Image style={{ width, height }} />);
  }

  return (
    <div className={cls.box}>
      <img src={src} alt="..." className={className} />
      {downloadFilename && src && (
        <a
          download={downloadFilename}
          href={src}
          className={cls.download}
          onClick={e => e.stopPropagation()}
          title="download"
        >
          <DownloadOutlined
            className={downloadIconClassName ? downloadIconClassName : cls.downloadIcon}
          />
        </a>
      )}
    </div>
  );
};

function imageBufferToBase64(arrayBuffer: Buffer): string {
  // @ts-ignore
  const b64encoded = btoa([].reduce.call(
    new Uint8Array(arrayBuffer),
    (p: any, c: any) => p + String.fromCharCode(c),
    '',
  ));
  const mimetype = 'image/jpeg';

  return `data:${mimetype};base64,${b64encoded}`;
}
