import { clsx } from 'clsx';
import React, { useEffect, useState } from 'react';
import {
  Edit,
  List,
  EditProps,
  TextField,
  ReferenceField,
  TopToolbar,
  SaveButton,
  SimpleForm,
} from 'react-admin';
import StopIcon from '@mui/icons-material/Stop';
import { SaveFilled } from '@ant-design/icons';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { SmsListStatus } from '@types';
import { Typography } from '@mui/material';
import { FormRow } from '@components/FormRow';
import { PageHeader } from '@components/PageHeader';
import { StatusField } from '@components/StatusField';
import { useEditProps, useRbacEdit, useRbacView, useRecord } from '@hooks';
import { Container, DateInput, Forbidden, Grid, TextInput } from '@components';
import { messageStatusFieldOptions, smsStatusFieldOptions } from '@pages/sms/sms-status-options';
import cls from '@pages/sms/SmsEdit.module.css';

const initialSmsForm = { message: '', phones: '' };
const Title = ({ record }: {record?: any}) =>
  record ? (<span>SMS # {record.id}</span>) : (<span>SMS edit</span>);

export const SmsEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const record = useRecord(props);
  const [smsForm, setSmsForm] = useState(initialSmsForm);

  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  useEffect(() => {
    if (record) {
      setSmsForm({
        message: record.message,
        phones: smsForm.phones,
      });
    }
  }, [record]);

  if (!rbacView) {
    return <Forbidden />;
  }

  const onChange = (e: any) => {
    setSmsForm({
      ...smsForm,
      [e.target.name]: e.target.value,
    });
  };

  const isMessageInvalid = smsForm.message.length === 0;

  const EditActions = () => {
    return (
      <TopToolbar className={cls.actionsToolbar}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon} />}
          label="Save"
          type="submit"
          transform={(data: any) => ({ ...data })}
          className={cls.action}
          variant="outlined"
          alwaysEnable
        />

        {record.status === SmsListStatus.SENDING && (
          <SaveButton
            icon={<StopIcon className={cls.actionIcon} />}
            label="Stop"
            type="button"
            transform={(data: any) => ({ ...data, status: SmsListStatus.WAITING })}
            className={cls.action}
            variant="contained"
            alwaysEnable
          />
        )}

        {record.status === SmsListStatus.WAITING && (
          <SaveButton
            icon={<PlayArrowIcon className={cls.actionIcon} />}
            label="Start"
            type="button"
            transform={(data: any) => ({ ...data, status: SmsListStatus.SENDING })}
            className={cls.action}
            variant="contained"
            alwaysEnable
          />
        )}
      </TopToolbar>
    );
  };

  return (
    <Edit {...props} {...editProps}
      title={<Title />}
      className={clsx(cls.page, 'details-page-wrapper')}>
      <PageHeader
        title="SMS Campaign"
        linkText="Back to all SMS Registered"
        linkUrl="/sms"
      />

      <SimpleForm toolbar={rbacEdit ? <EditActions /> : false} className={cls.form}>
        <Container max className={clsx(cls.block, cls.blockForm)}>
          <Typography variant="h6" component="h2" className={cls.subTitle}>User</Typography>

          <div className={clsx(cls.row, cls.userTopRow)}>
            <div className={clsx(cls.col, cls.colId)}>
              <FormRow
                title="Id: #"
                width={{ sm: { title: 11, children: 13 } }}
                rowClassName={cls.formRow}
                colChildrenClassName={cls.formValueCol}
              >
                <TextInput disabled label={false} source="id" />
              </FormRow>
            </div>
            <div className={clsx(cls.col, cls.colStatus)}>
              <FormRow
                title="List status:"
                width={{ sm: { title: 12, children: 12 } }}
                rowClassName={cls.formRow}
                colChildrenClassName={cls.formValueCol}
              >
                <StatusField source="status" options={smsStatusFieldOptions} />
              </FormRow>
            </div>
            <div className={clsx(cls.col, cls.colTotal)}>
              <FormRow
                title="Total sent status:"
                width={{ sm: { title: 16, children: 8 } }}
                rowClassName={cls.formRow}
                colChildrenClassName={cls.formValueCol}
              >
                <TextInput disabled label={false} source="statusDetail" />
              </FormRow>
            </div>
            <div className={clsx(cls.col, cls.colDate)}>
              <FormRow
                title="Created at:"
                width={{ sm: { title: 11, children: 13 } }}
                rowClassName={cls.formRow}
                colChildrenClassName={cls.formValueCol}
              >
                <DateInput disabled label={false} source="createdAt" />
              </FormRow>
            </div>
          </div>
          <div className={cls.row}>
            <div className={clsx(cls.col, cls.textareaCol)}>
              <FormRow
                title="Message*"
                fullWidth
                rowClassName={cls.formRow}
                colChildrenClassName={cls.message}
                colTitleClassName={cls.textareaLabel}
              >
                <TextInput className={clsx(isMessageInvalid && cls.error)} label={false} source="message" onChange={onChange} rows={3} multiline />
              </FormRow>
            </div>
            <div className={clsx(cls.col, cls.textareaCol)}>
              <FormRow
                title="Comment"
                fullWidth
                rowClassName={cls.formRow}
                colChildrenClassName={cls.message}
                colTitleClassName={cls.textareaLabel}
              >
                <TextInput label={false} source="comment" onChange={onChange} rows={3} multiline />
              </FormRow>
            </div>
          </div>
        </Container>
        {record && (
          <Container max className={clsx(cls.block, cls.blockList)}>
            <Typography variant="h6" component="h2" className={cls.subTitle}>SMS list</Typography>

            {record.smsMessages?.length !== 0 && (
              <List
                resource={`sms/messages/${record.id}`}
                exporter={false}
                actions={false}
                className={cls.list}
                title={' '}
              >
                <Grid
                  defaultColumns={'id userId message phone status'.split(' ')}
                >
                  <ReferenceField reference="user" source="userId">
                    <TextField source="fullName" />
                  </ReferenceField>
                  <TextField source="phone" />
                  <StatusField source="status" options={messageStatusFieldOptions} />
                </Grid>
              </List>
            )}

            {record.smsMessages?.length === 0 && (
              <div>No messages yet</div>
            )}
          </Container>
        )}
      </SimpleForm>
    </Edit>
  );
};
