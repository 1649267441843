import { ListProps, TextField } from 'react-admin';
import { TextInput, Grid, GridList, Forbidden } from '@components';
import { useRbacList } from '@hooks';

export const CountryList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick="edit"
        defaultColumns={['id', 'name', 'capital', 'subregion', 'iso3', 'emoji']}
      >
        <TextField source="id" />
        <TextField source="name" label="Country" />
        <TextField source="phoneCode" />
        <TextField source="capital" />
        <TextField source="native" />
        <TextField source="region" />
        <TextField source="subregion" />
        <TextField source="iso3" label="Code" />
        <TextField source="emoji" />
        <TextField source="currency" />
        <TextField source="currencySymbol" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 220 }} />,
];
