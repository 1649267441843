import React, { useRef, useState } from 'react';
import { Input, Modal, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Button from '@mui/material/Button';

import { creditHttp } from '@network';
import { Document } from '@types';
import { useRequest } from '@hooks';
import cls from './document-comment-form.module.css';

interface Props {
  creditId: number;
  document?: Document;
  setDocument: (document: Document) => void;
  editable: boolean;
}

export const DocumentCommentForm = ({ creditId, document, setDocument, editable }: Props) => {
  const [comment, setComment] = useState('');
  const commentInput = useRef<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
    setTimeout(() => commentInput.current?.focus(), 500);
  };
  const hideModal = () => setIsModalVisible(false);

  const { submit, loading } = useRequest(async () => {
    if (!comment || !document || !editable) {
      return;
    }
    const updatedDocument = await creditHttp.addCommentToDocument({
      creditId,
      document,
      comment,
    });
    setComment('');
    hideModal();
    notification.success({ message: 'Comment saved!' });
    setDocument(updatedDocument);
  });

  if (!document) {
    return null;
  }

  return (
    <div>
      <Button onClick={showModal} variant="contained" color="secondary" className={cls.btnAdd}>
        <PlusOutlined /> <span>Add comment</span>
      </Button>
      <Modal
        open={isModalVisible}
        title="Add comment to document"
        okText="Save comment"
        onOk={submit}
        onCancel={hideModal}
        confirmLoading={loading}
      >
        <Input.TextArea
          ref={commentInput}
          value={comment}
          onChange={e => setComment(e.target.value)}
          autoFocus
        />
      </Modal>
    </div>
  );
};
