import React from 'react';
import { Edit, EditProps, SimpleForm } from 'react-admin';
import { useEditProps, useRbacEdit } from '@hooks';
import { Col, Row } from 'antd';

import { Container, Forbidden, InitialCard, InitialPage } from '@components';
import { FinanceActivationStatus, FinanceOriginOption } from '@types';
import { FinanceOptionsForm } from './components/FinanceOptionsForm';
import { rbac } from '@utils';

const validate = (values: any) => {
  const errors: any = {};
  const statuses = (values.statuses || []) as FinanceActivationStatus[];
  const options = (values.options || []) as FinanceOriginOption[];

  if (Array.isArray(statuses) && statuses.length > 0) {
    const uniqueNames: Record<string, boolean> = {};
    statuses.forEach(status => {
      const name = `${status.name || ''}`.trim();
      if (!name && !errors['statuses']) {
        errors['statuses'] = 'Description is required';
      }
      if (name && uniqueNames[name] && !errors['statuses']) {
        errors['statuses'] = `Description is duplicated: ${name}`;
      }
      uniqueNames[name] = true;
    });
  }

  if (Array.isArray(options) && options.length > 0) {
    const uniqueNames: Record<string, boolean> = {};
    options.forEach(option => {
      const name = `${option.name || ''}`.trim();
      if (!name && !errors['options']) {
        errors['options'] = 'Description is required';
      }
      if (name && uniqueNames[name] && !errors['options']) {
        errors['options'] = `Description is duplicated: ${name}`;
      }
      uniqueNames[name] = true;
    });
  }

  return errors;
};

export const FinanceActivationSettingsEdit = (props: EditProps) => {
  const editProps = useEditProps();

  const accessAll = rbac.hasFinanceSettingsAll();
  const accessReadonly = !accessAll && rbac.hasFinanceSettingsReadonly();
  const hasAccess = accessAll || accessReadonly;

  if (!hasAccess) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} {...editProps} title="Activations Settings">
      <InitialPage
        title="Activations Settings"
        backTo="/finance-settings"
        backText="Back to Finance Settings"
      >
        <SimpleForm validate={validate} toolbar={accessReadonly ? false : undefined}>
          <Container max>
            <InitialCard left="Current elements" extraPadding>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={11}>
                  <FinanceOptionsForm
                    fieldName="statuses"
                    label="Activations status"
                    hidePosition
                    readonly={accessReadonly}
                  />
                </Col>
                <Col xs={0} sm={2} />
                <Col xs={24} sm={11}>
                  <FinanceOptionsForm
                    fieldName="options"
                    label="Origin options"
                    hidePosition
                    readonly={accessReadonly}
                  />
                </Col>
              </Row>
            </InitialCard>
          </Container>
        </SimpleForm>
      </InitialPage>
    </Edit>
  );
};
