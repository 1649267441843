import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { SaveFilled } from '@ant-design/icons';
import { EditProps, Edit, SimpleForm, TopToolbar, SaveButton, DeleteButton } from 'react-admin';

import {
  Container,
  ImageInput,
  TextInput,
  EditorInput,
  DateTimeInput,
  Forbidden,
  TimerInput,
  LinkInput,
  SwitchInput,
  PageHeader,
  FormRow,
} from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';
import cls from './WebinarEdit.module.css';

const Title = ({ record }: { record?: any }) =>
  record ? (<span>Webinar: {record.titleMx}</span>) : null;

export const WebinarEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();
  const editorToolbar = 'undo redo formatselect '
    + 'bold italic backcolor bullist numlist outdent indent table '
    + 'removeformat code help '
    + 'alignleft aligncenter alignright alignjustify ';
  const mainColGrid = {
    sm: { title: 5, children: 19 },
    lg: { title: 6, children: 18 },
    xl: { title: 7, children: 17 },
    xxl: { title: 5, children: 19 },
  };
  const linksColGrid = {
    xs: { title: 7, children: 17 },
    sm: { title: 7, children: 17 },
    md: { title: 6, children: 18 },
    lg: { title: 5, children: 19 },
    xl: { title: 5, children: 19 },
    xxl: { title: 4, children: 20 },
  };

  if (!rbacView) {
    return <Forbidden/>;
  }

  const LinkIcon = ({ className }: { className?: string; }) => {
    return (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <circle cx="19" cy="19" r="19" fill="#042E6B"/>
        <g clipPath="url(#clip0_320_7)">
          <path
            d="M19 5C11.3 5 5 11.3 5 19C5 26.7 11.3 33 19 33C26.7 33 33 26.7 33 19C33 11.3 26.7 5 19 5ZM31.0867 18.0667H25.3C25.2533 16.2933 25.0667 14.6133 24.7867 13.12C26.3267 12.84 27.68 12.42 28.8467 11.9067C30.1067 13.68 30.9 15.78 31.0867 18.0667ZM27.54 10.4133C26.6067 10.7867 25.5333 11.0667 24.32 11.3C23.9 9.76 23.2933 8.45333 22.64 7.42667C24.5067 8.03333 26.1867 9.06 27.54 10.4133ZM14.5667 18.0667C14.6133 16.34 14.8 14.8 15.08 13.4C16.2933 13.54 17.6 13.6333 19 13.6333C20.4 13.6333 21.66 13.54 22.92 13.4C23.2 14.8 23.3867 16.34 23.4333 18.0667H14.5667ZM23.4333 19.9333C23.3867 21.66 23.2 23.2 22.92 24.6C21.7067 24.46 20.4 24.3667 19 24.3667C17.6 24.3667 16.34 24.46 15.08 24.6C14.8 23.2 14.6133 21.66 14.5667 19.9333H23.4333ZM19 6.86667C20.26 6.86667 21.5667 8.64 22.4533 11.58C21.38 11.72 20.2133 11.7667 19 11.7667C17.7867 11.7667 16.62 11.6733 15.5467 11.58C16.4333 8.64 17.74 6.86667 19 6.86667ZM15.36 7.42667C14.7067 8.45333 14.1 9.76 13.68 11.3C12.4667 11.0667 11.3933 10.7867 10.46 10.4133C11.8133 9.06 13.4933 8.03333 15.36 7.42667ZM9.15333 11.9067C10.32 12.42 11.6733 12.84 13.2133 13.12C12.9333 14.6133 12.7467 16.2933 12.7 18.0667H6.91333C7.1 15.78 7.89333 13.68 9.15333 11.9067ZM6.91333 19.9333H12.7C12.7467 21.7067 12.9333 23.3867 13.2133 24.88C11.6733 25.16 10.32 25.58 9.15333 26.0933C7.89333 24.3667 7.1 22.22 6.91333 19.9333ZM10.46 27.5867C11.3933 27.2133 12.4667 26.9333 13.68 26.7C14.1 28.24 14.7067 29.5467 15.36 30.5733C13.4933 29.9667 11.8133 28.94 10.46 27.5867ZM19 31.1333C17.74 31.1333 16.4333 29.36 15.5467 26.42C16.62 26.28 17.7867 26.2333 19 26.2333C20.2133 26.2333 21.38 26.3267 22.4533 26.42C21.5667 29.36 20.26 31.1333 19 31.1333ZM22.64 30.5733C23.2933 29.5467 23.9 28.24 24.32 26.7C25.5333 26.9333 26.6067 27.2133 27.54 27.5867C26.1867 28.94 24.5067 29.9667 22.64 30.5733ZM28.8467 26.0933C27.68 25.58 26.3267 25.16 24.7867 24.88C25.0667 23.3867 25.2533 21.7067 25.3 19.9333H31.0867C30.9 22.22 30.1067 24.32 28.8467 26.0933Z"
            fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_320_7">
            <rect width="28" height="28" fill="white" transform="translate(5 5)"/>
          </clipPath>
        </defs>
      </svg>
    );
  };

  const Actions = () => {
    return (
      <TopToolbar className={cls.actions}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon}/>}
          label="Save"
          type="button"
          className={clsx(cls.action, cls.actionSave)}
          variant="outlined"
        />
        <DeleteButton
          className={clsx(cls.action, cls.actionDelete)}
        />
      </TopToolbar>
    );
  };

  return (
    <Edit {...props} title={<Title/>} {...editProps} className={clsx(cls.page, 'details-page-wrapper')}>
      <>
        <PageHeader
          title="Webinar edit"
          linkText="Back to all webinars"
          linkUrl="/webinar-list"
        />

        <SimpleForm toolbar={rbacEdit ? <Actions/> : false} className={cls.form}>
          <Container max>
            <Row gutter={24} className={cls.wrapper}>
              <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColFirst)}>
                <div className={clsx(cls.block, cls.blockMain)}>
                  <div className={cls.blockHeader}>User</div>
                  <div className={cls.blockMiddle}>
                    <div className={cls.blockMiddleIcon}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
                        <path
                          d="M27.5 0C12.3161 0 0 12.3161 0 27.5C0 42.6839 12.3161 55 27.5 55C42.6839 55 55 42.6839 55 27.5C55 12.3161 42.6839 0 27.5 0ZM27.5 13.75C33.6875 13.75 38.7161 18.7786 38.7161 24.9661C38.7161 31.1536 33.6875 36.1821 27.5 36.1821C21.3125 36.1821 16.2839 31.1536 16.2839 24.9661C16.2839 18.7786 21.3125 13.75 27.5 13.75ZM27.5 51.4643C21.9214 51.4643 16.775 49.5393 12.6893 46.3179C14.1429 44.2357 16.5393 42.9786 19.0929 42.9786H35.9071C38.4607 42.9786 40.8571 44.2357 42.3107 46.3179C38.225 49.5393 33.0786 51.4643 27.5 51.4643Z"
                          fill="#042E6B"/>
                      </svg>
                    </div>
                    <div className={cls.blockMiddleInfo}>
                      <FormRow
                        title="Title:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <TextInput name="titleMx" source="titleMx" required autoFocus label={false}/>
                      </FormRow>
                      <FormRow
                        title="Speaker:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <TextInput name="speaker" source="speaker" label={false}/>
                      </FormRow>
                      <FormRow
                        title="Starts at:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <DateTimeInput name="startsAt" source="startsAt" label={false}/>
                      </FormRow>
                      <FormRow
                        title="Duration:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <TimerInput source="durationMinutes" label={false}/>
                      </FormRow>
                      <FormRow
                        title="Image:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow, cls.imgRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue, cls.imgCol)}
                      >
                        <ImageInput name="image" source="image" label={false}/>
                      </FormRow>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} xl={12} className={clsx(cls.wrapperCol, cls.wrapperColLast)}>
                <div className={clsx(cls.block, cls.blockLinks)}>
                  <div className={cls.blockHeader}>Links</div>
                  <div className={cls.blockMiddleLinks}>
                    <FormRow
                      title="Zoom link:"
                      fullWidth
                      rowClassName={clsx(cls.formRow, cls.linkFormRow)}
                      colTitleClassName={clsx(cls.formLabelCol, cls.linkLabel)}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formLinkValue)}
                    >
                      <LinkInput name="url" source="url" label={false} placeholder="http://any.link"/>
                    </FormRow>
                    <FormRow
                      title="Website link:"
                      fullWidth
                      rowClassName={clsx(cls.formRow, cls.linkFormRow)}
                      colTitleClassName={clsx(cls.formLabelCol, cls.linkLabel)}
                      colChildrenClassName={clsx(cls.formValueCol, cls.formLinkValue)}
                    >
                      <LinkInput
                        source="link"
                        name="link"
                        label={false}
                        placeholder="http://any.link"
                        leftIcon={<LinkIcon className={cls.linkIcon}/>}
                      />
                    </FormRow>
                    <div className={cls.switcher}>
                      <SwitchInput
                        source="isEnabled"
                        name="isEnabled"
                        label="is enabled"
                        defaultValue={true}
                        className={cls.switcherField}
                      />
                    </div>
                  </div>
                  <div className={cls.blockFooterleLinks}>
                    <FormRow
                      title="Created at:"
                      width={linksColGrid}
                      rowClassName={clsx(cls.formRow, cls.linkDateRow)}
                      colTitleClassName={clsx(cls.formLabelCol, cls.linkDateLabel)}
                      colChildrenClassName={clsx(cls.formValueCol, cls.linkDateValue)}
                    >
                      <DateTimeInput name="createdAt" source="createdAt" disabled label={false}/>
                    </FormRow>
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={24} className={cls.wrapper}>
              <Col xs={24} className={clsx(cls.wrapperCol, cls.wrapperColBottom)}>
                <div className={clsx(cls.block, cls.blockContent)}>
                  <Row gutter={24} className={cls.inner}>
                    <Col xs={24} xl={12} className={clsx(cls.innerCol, cls.innerColFirst)}>
                      <FormRow
                        title="Announce:"
                        fullWidth
                        rowClassName={clsx(cls.formRow, cls.messageRow)}
                        colTitleClassName={clsx(cls.formLabelCol, cls.formTitleCol)}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formValueColTextarea)}
                      >
                        <EditorInput
                          source="announceMx"
                          label={false}
                          toolbar={editorToolbar}
                          height={250}
                          className={cls.editor}
                        />
                      </FormRow>
                    </Col>
                    <Col xs={24} xl={12} className={clsx(cls.innerCol, cls.innerColLast)}>
                      <FormRow
                        title="Description:"
                        fullWidth
                        rowClassName={clsx(cls.formRow, cls.messageRow)}
                        colTitleClassName={clsx(cls.formLabelCol, cls.formTitleCol)}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formValueColTextarea)}
                      >
                        <EditorInput
                          source="descriptionMx"
                          label={false}
                          toolbar={editorToolbar}
                          height={250}
                          className={cls.editor}
                        />
                      </FormRow>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </SimpleForm>
      </>
    </Edit>
  );
};
