import { clsx } from 'clsx';
import { InputNumber } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import { TableProjectedCycleColumn, TableProjectedCycleRow } from '@network';
import { LightTooltip } from '@components';
import cls from './table-projected-cycle.module.css';

type Column = 'title' | 'value';
type Row = TableProjectedCycleRow;

const defaultData: {rows: Row[]} = {
  rows: [
    { title: 'Hectares', value: 0, format: 'number' },
    { title: 'Yield per Ton', value: 0, format: 'number' },
    {
      title: 'Total Tons', value: 0, format: 'number', disabled: true,
      tooltip: 'Hectares * Yield per Ton',
    },
    { title: 'Price per Ton', value: 0, format: 'money' },
    {
      title: 'Total Income', value: 0, format: 'money', disabled: true,
      tooltip: 'Price per Ton * Total Tons',
    },
    { title: 'Operative Costs per Hectare', value: 0, format: 'money' },
    {
      title: 'Total Costs', value: 0, format: 'money', disabled: true,
      tooltip: 'Operative Costs per Hectare * Hectares',
    },
    {
      title: 'Net Profit', value: 0, format: 'money', disabled: true,
      tooltip: 'Total Income - Total Costs',
    },
    { title: 'Adjusted Price per Ton', value: 0, separator: '', format: 'money' },
    {
      title: 'Adjusted Net Sales', value: 0, format: 'money', disabled: true,
      tooltip: 'Total Tons * Adjusted Price per Ton',
    },
    { title: 'Adjusting Variable', value: 0, for: 'Adjusted Net Income', format: 'percent' },
    {
      title: 'Adjusted Net Income', value: 0, format: 'money', disabled: true,
      tooltip: 'Adjusted Net Sales * Adjusting Variable (1) / 100',
    },
    { title: 'Global Sales', value: 0, format: 'money' },
    { title: 'Adjusting Variable', value: 0, for: 'Adjusted Global Sales', format: 'percent' },
    {
      title: 'Adjusted Global Sales', value: 0, format: 'money',
      tooltip: 'Global Sales * Adjusting Variable (2) / 100',
    },
    { title: 'Adjusting Variable', value: 0, for: 'Adjusted Global Net Income', format: 'percent' },
    {
      title: 'Adjusted Global Net Income', value: 0, format: 'money',
      tooltip: 'Adjusted Global Sales * Adjusting Variable (3) / 100',
    },
  ],
};

interface Props {
  data: {rows: Row[]} | null,
  onChange: any;
  title: string;
  isFixed?: boolean;
}

export const TableProjectedCycle = ({ data, onChange, title, isFixed }: Props) => {
  const onFieldChange = (val: number | null | string, key: Column, index: number) => {
    if (val === null) return;
    const nextRows = (data || defaultData)
      .rows.map((row, i) => i === index ? { ...row, [key]: val } : row);
    const nextData = { ...data, rows: nextRows };
    onChange(nextData);
  };

  if (!Array.isArray(data?.rows)) {
    setTimeout(() => void onChange(defaultData), 500);
  }

  const rows = data?.rows || [] as Row[];

  return (
    <div className={cls.block}>
      <div className={cls.table}>
        <div className={cls.header}>
          <div className={cls.headerRow}>
            <div className={cls.headerCol}>{title}</div>
          </div>
        </div>
        <div className={cls.body}>
          {rows.map((row, index) => (
            <div key={index}>
              {typeof row.separator === 'string' && (
                <div className={cls.separator}>{row.separator}</div>
              )}
              <div className={clsx(cls.row, cls.rowColored)}>
                <div className={clsx(cls.col, cls.colTitle)}>
                  {row.title}
                </div>
                <div className={cls.col}>
                  <Input row={row}
                    field={'value'}
                    onChange={onFieldChange}
                    index={index}
                    isFixed={isFixed || row.disabled}
                  />
                </div>
                {row.tooltip && (
                  <LightTooltip title={row.tooltip} extraWidth>
                    <div className={cls.tooltip}><InfoCircleFilled /></div>
                  </LightTooltip>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface InputProps {
  row: Row;
  field: Column;
  onChange?: any;
  index?: number;
  isFixed?: boolean;
}

const Input = ({ row, field, onChange, index, isFixed }: InputProps) => {
  const value = row[field];
  const prefix = row.format === 'money' ? '$ ' : '';
  const postfix = row.format === 'percent' ? ' %' : '';

  return (
    <InputNumber
      min={0}
      max={1000000000}
      onBeforeInputCapture={e => {
        const value = e.currentTarget.value;
        if (value && value.includes('.')) {
          const [, second] = value.split('.');
          if (second.length >= 2) {
            e.stopPropagation();
            e.preventDefault();
          }
        }
      }}
      formatter={(val) => `${prefix}${val}${postfix}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(val) => {
        let nextVal: number = val ? +val.replace(/\$\s?|\s?\%|(,*)/g, '') : 0;
        if (row.title === 'Adjusting Variable') {
          if (nextVal < 0) nextVal = 0;
          if (nextVal > 100) nextVal = 100;
        }

        return nextVal;
      }}
      value={value}
      bordered={false}
      onChange={val => onChange(val, field, index)}
      className={cls.input}
      disabled={isFixed}
      controls={false}
    />
  );
};
