import { FunctionField, FunctionFieldProps } from 'react-admin';
import Chip from '@mui/material/Chip';
import { Color } from '@mui/material';

export interface ColoredOption {
  id: string;
  name: string;
  color: Color | string;
}

export const callStatusOptions: ColoredOption[] = [
  { id: 'pending', name: 'Pending', color: '#FD492D' },
  { id: 'complete', name: 'Complete', color: '#88D256' },
];

interface Props {
  source: string;
  callStatus?: string;
  className?: string;
  width?: number;
  badgeMode?: boolean;
}

export const CallStatusField = (props: Props & Partial<FunctionFieldProps>) => {
  const { className, width, callStatus, badgeMode, ...restProps } = props;

  if (badgeMode) {
    const status = callStatus;
    const statusName = callStatusOptions.find(o => o.id === status)?.name;
    const color: any = callStatusOptions.find(o => o.id === status)?.color;
    if (!status || !color) {
      return null;
    }

    return (
      <Chip
        label={statusName || status}
        size="small"
        style={{
          backgroundColor: typeof color === 'string'
            ? color
            : (color && color[500] ? color[500] : '#74706B'),
          color: 'white',
          height: 20,
          fontSize: 11,
          width: width || '100%',
        }}
        className={className}
      />
    );
  }

  return (
    <FunctionField {...restProps} render={(record: any) => {
      const status = callStatus || record[props.source];
      const statusName = callStatusOptions.find(o => o.id === status)?.name;
      const color: any = callStatusOptions.find(o => o.id === status)?.color;
      if (!status || !color) {
        return null;
      }

      return (
        <Chip
          label={statusName || status}
          size="small"
          style={{
            backgroundColor: typeof color === 'string'
              ? color
              : (color && color[500] ? color[500] : '#74706B'),
            color: 'white',
            height: 20,
            fontSize: 11,
            width: width || '100%',
          }}
          className={className}
        />
      );
    }} />
  );
};
