export interface DocumentFile {
  id: number;
  fileId: number;
  status: FileStatus;
  statusEn: string;
  privateUrl: string;
  adminUrl: string;
  isPdf: boolean;
  isImage: boolean;
  isXml: boolean;
  inRevision: boolean;
  isApproved: boolean;
  isDenied: boolean;
  isDraft: boolean;
  isSigned: boolean;
  originalName: string;
  checkedAt: string;
  createdAt: string;
  adminId: number | null;
  adminName: string | null;
  managerId: number | null;
  managerName: string | null;
  extension: string;
  url: string;
}

export enum FileStatus {
  REVISION = 'revision',
  APPROVED = 'approved',
  DENIED = 'denied',
  DRAFT = 'draft',
  SIGNED = 'signed',
}

export const fileStatusMap = {
  [FileStatus.REVISION]: 'Pending',
  [FileStatus.APPROVED]: 'Approved',
  [FileStatus.DENIED]: 'Denied',
  [FileStatus.DRAFT]: 'Draft',
  [FileStatus.SIGNED]: 'Signed',
};
