import { useRecordContext } from 'react-admin';
import Chip from '@mui/material/Chip';
import grey from '@mui/material/colors/grey';
import blue from '@mui/material/colors/blue';
import blueGrey from '@mui/material/colors/blueGrey';
import green from '@mui/material/colors/green';

import { DeliveryStatus } from '@types';

interface DeliveryStatusOption {
  id: DeliveryStatus,
  name: string,
  color: any,
}

export const deliveryStatusOptions: DeliveryStatusOption[] = [
  { id: 'waiting', name: 'To pay', color: grey },
  { id: 'paid', name: 'Paid', color: blueGrey },
  { id: 'delivery', name: 'In the way', color: blue },
  { id: 'delivered', name: 'Delivered', color: green },
];

export const DeliveryStatusField = ({ source }: {source: string}) => {
  const record = useRecordContext();
  const status = record[source];
  const statusName = deliveryStatusOptions.find(o => o.id === status)?.name;
  const color: any = deliveryStatusOptions.find(o => o.id === status)?.color;

  if (!status || !color) {
    return null;
  }

  return (
    <Chip
      label={statusName || status}
      size="small"
      style={{ backgroundColor: color && color[500] ? color[500] : undefined, color: 'white' }}
    />
  );
};
