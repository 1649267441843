import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { makeStyles } from '@mui/styles';
import { CreditRisk, ReplyQuestion } from '@types';

interface CategoryProps {
  list: ReplyQuestion[];
  title: string;
}

const Category = ({ list, title }: CategoryProps) => {
  const cls = useStyles();

  return (
    <>
      <div className={clsx(cls.title, cls.titleLeft)}>{title}</div>
      <div className={cls.row}>
        {list.filter(i => i.category?.title === title).map((i) => (
          <div key={i.position} className={clsx(cls.col)}>
            <div className={cls.name}>{i.title}</div>
            <div className={cls.value}>{i.value || '---'}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export const RiskScoreVariables = ({ risk }: { risk: CreditRisk }) => {
  const [catNames, setCatNames] = useState<string[]>([]);

  const loadCategories = (): string[] => {
    const catTitles: string[] = [];
    risk.replyScore.forEach((i: ReplyQuestion) => {
      if (i.category?.title) {
        catTitles[i.category.position] = i.category.title;
      }
    });

    return catTitles.filter(t => t);
  };

  useEffect(() => {
    if (risk.replyScore && risk.replyScore.length) {
      setCatNames(loadCategories());
    }
  }, []);

  return !risk.replyScore || !risk.replyScore.length ? null : (
    <>
      {catNames.map((c: string, idx: number) => (
        <Category list={risk.replyScore} title={c} key={idx}/>
      ))}
    </>
  );
};

const useStyles = makeStyles({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
  },
  col: {
    flex: '0 0 calc(50% - 10px)',
    maxWidth: 'calc(50% - 10px)',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #042E6B',
    borderRadius: '5px',
    height: 35,
    fontSize: 10,
    marginTop: 20,
    backgroundColor: '#F0F3FB',
  },
  name: {
    padding: '2px 10px',
    flex: '0 0 50%',
    maxWidth: '50%',
    backgroundColor: '#042E6B',
    color: 'white',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  value: {
    flex: '0 0 50%',
    maxWidth: '50%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#042E6B',
  },
  title: {
    margin: '25px 0 -15px',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: '#000',
  },
  titleLeft: {
    textAlign: 'left',
  },
});
