import { Link } from 'react-admin';

import cls from './FinancialGoTo.module.css';

interface Props {
    url: string;
}

export const FinancialGoTo = ({ url }: Props) => {
  return (
    <Link to={url} target="_blank" className={cls._}>
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
        <circle cx="9.5" cy="9.5" r="9.5" fill="#002A77"/>
        <path
          d="M12.9993 9.47414C12.9942 9.26912 12.909 9.07333 12.7602 8.92446L7.50832 3.75274C7.29067 3.54033 6.96985 3.45315 6.66623 3.52445C6.36261 3.59574 6.1219 3.8148 6.03476 4.09888C5.94762 4.38295 6.02694 4.68956 6.24342 4.90345L10.9138 9.5L6.24342 14.0966C6.02694 14.3104 5.94762 14.617 6.03476 14.9011C6.1219 15.1856 6.36261 15.4043 6.66623 15.4756C6.96985 15.5468 7.29067 15.4597 7.50832 15.2473L12.7602 10.0755C12.9223 9.91374 13.0087 9.69689 12.9993 9.47451L12.9993 9.47414Z"
          fill="white"/>
      </svg>
    </Link>
  );
};
