import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNotify, useRefresh } from 'react-admin';
import { Col, Row } from 'antd';

import { axiosErrorToString, centsFormatter, dateFormatter } from '@utils';
import { Credit, CreditStatus, User } from '@types';
import { creditHttp } from '@network';
import { ConfirmDelete } from '@components';
import { useIsMounted } from '@hooks';
import { CreditStatusField } from '@pages/credit/CreditStatusField';
import cls from './user-initial.module.css';

interface UserCreditProps {
  credit: Credit;
  remove: (id: number) => void;
}

interface UserCreditsProps {
  user: User;
}

const UserCredit = ({ credit, remove }: UserCreditProps) => {
  return (
    <>
      <Col xs={12} sm={8} md={6} className={cls.infolineCard}>
        <Link to={`/credit/${credit.id}?initial`}>
          <div className={cls.credit}>
            <div className={cls.creditCode}>{credit.code}</div>
            <div className={cls.creditDate}>
              <div>Signature at: {credit.signatureAt ? dateFormatter.toDate(credit.signatureAt) : '-'}</div>
            </div>
            <div className={cls.creditBalance}>Balance</div>
            <div className={cls.creditAmount}>
              {centsFormatter.format(credit.balance)}
            </div>
            <div className={cls.creditBalance}>Requested amount</div>
            <div className={cls.creditAmount}>
              {centsFormatter.format(credit.requestAmount || 0)}
            </div>
            <div className={cls.creditCrop}>Financed crop: {credit.financedCrop?.nameMx || '-'}</div>
            <CreditStatusField
              source="status"
              creditStatus={credit.status}
              className={cls.creditStatus}
            />
          </div>
        </Link>
        {credit.status === CreditStatus.DRAFT && (
          <ConfirmDelete
            title="Are you sure?"
            cb={() => remove(credit.id)}
            overlayClassName={cls.overlayClassName}
            placement="top"
          >
            <div className={cls.close}>
              <div className={cls.closeIcon}>
                <svg xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 35 35"
                  fill="none">
                  <path
                    d="M17.5039 0C7.83826 0 0 7.83826 0 17.5039C0 27.1695 7.83826 35.0078 17.5039 35.0078C27.1695 35.0078 35.0078 27.1695 35.0078 17.5039C34.9922 7.83826 27.1617 0.00777605 17.5039 0Z"
                    fill="#002A77" />
                  <path
                    d="M24.0903 10.9101C23.787 10.6068 23.2971 10.6068 22.9938 10.9101L17.5039 16.4L12.0141 10.9101C11.7108 10.6068 11.2209 10.6068 10.9176 10.9101C10.6144 11.2133 10.6144 11.7032 10.9176 12.0065L16.4075 17.4964L10.9176 22.9863C10.6144 23.2895 10.6144 23.7794 10.9176 24.0827C11.2209 24.386 11.7108 24.386 12.0141 24.0827L17.5039 18.5928L22.9938 24.0827C23.2971 24.386 23.787 24.386 24.0903 24.0827C24.3935 23.7794 24.3935 23.2895 24.0903 22.9863L18.6004 17.4964L24.0903 12.0065C24.3935 11.7032 24.3935 11.2133 24.0903 10.9101Z"
                    fill="#FFFFFF" />
                </svg>
              </div>
            </div>
          </ConfirmDelete>
        )}
      </Col>
    </>);
};

export const UserCredits = ({ user }: UserCreditsProps) => {
  const [credits, setCredits] = useState<Credit[]>([]);
  const isMounted = useIsMounted();

  const notify = useNotify();
  const refresh = useRefresh();

  const loadCredits = useCallback(async () => {
    if (!isMounted.current) return;
    const nextCredits = await creditHttp.getCreditsByUser(user.id);
    isMounted.current && setCredits(nextCredits);
  }, []);

  const remove = async (creditId: number) => {
    try {
      await creditHttp.remove(creditId);
      refresh();
      void loadCredits();
    } catch (err) {
      notify(axiosErrorToString(err));
    }
  };

  useEffect(() => {
    void loadCredits();
  }, [loadCredits, user.id]);

  return (<Row gutter={[20, 20]} className={cls.infoline}>
    {credits.map(credit => (<UserCredit key={credit.id} credit={credit} remove={remove} />))}
  </Row>);
};
