import { FC, useRef, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { EditOutlined, DollarOutlined } from '@ant-design/icons';

import { productHttp } from '@network';
import { useRequest, useUpdated } from '@hooks';
import cls from './prices-update-modal.module.css';

interface Props {
  productId: number;
  price: string;
}

export const PricesUpdateModal: FC<Props> = ({ productId, price }) => {
  const priceRef = useRef<any>();
  const [priceModal, setPriceModal] = useState(price);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const updated = useUpdated();

  const showModal = () => {
    setIsModalVisible(true);
    setTimeout(() => void priceRef.current?.focus(), 500);
  };
  const hideModal = () => setIsModalVisible(false);

  const { loading, submit } = useRequest(async () => {
    await productHttp.update(productId, {
      price: priceModal,
    });
    hideModal();
    updated();
  });

  return (
    <>
      <div className={cls.box}>
        <Button
          onClick={showModal}
          icon={<EditOutlined className={cls.btnEdit} />}
          type="text"
          size="small"
        />
      </div>
      <Modal
        open={isModalVisible}
        title="Update prices"
        okText="Save"
        onOk={submit}
        confirmLoading={loading}
        onCancel={hideModal}
      >
        <Input
          ref={priceRef}
          addonBefore={<DollarOutlined />}
          type="number"
          value={priceModal}
          onChange={e => setPriceModal(e.target.value)}
          placeholder="Amount ex: 10400.50"
          autoFocus
        />
      </Modal>
    </>
  );
};
