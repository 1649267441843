import { clsx } from 'clsx';
import { Link, useNotify } from 'react-admin';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  RightCircleFilled,
} from '@ant-design/icons';

import { MoffinProfile, User } from '@types';
import { InitialCard, InitialLine, Loader } from '@components';
import { moffinHttp } from '@network';
import { dateFormatter, moneyFormatter } from '@utils';
import cls from './UserMoffin.module.css';

export const UserMoffin = ({ user }: {user: User}) => {
  const [profile, setProfile] = useState<MoffinProfile>();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const loadProfile = async () => {
    if (!user) return;
    const profiles = await moffinHttp.getMoffinProfiles(user.id);
    const profileCompany = profiles.find(p => p.accountType === 'PM');
    const profileUser = profiles.find(p => p.accountType === 'PF');

    if (user.isCompany && profileCompany) {
      setProfile(profileCompany);
    }
    if (!user.isCompany && profileUser) {
      setProfile(profileUser);
    }
  };

  useEffect(() => void loadProfile(), [user]);

  const requestScore = async () => {
    setLoading(true);
    try {
      const updatedProfile = await moffinHttp.requestScore(user.id, user.isCompany ? 'PM' : 'PF');
      setProfile(updatedProfile);
      notify('Moffin Score is requested');
    } catch (err: any) {

    } finally {
      setLoading(false);
    }
  };

  const { BureauScore, NumeroMop, CurrentDebt } = profile?.reportExtractions || {};

  return (
    <InitialCard
      left={
        <Link to={`/user/${user.id}?moffin`} className={cls.cardTitle}>
          <span>Moffin Score</span>
          <RightCircleFilled />
        </Link>
      }
      contentClass={clsx(cls.content)}
      wrapperClass={clsx(cls.wrap)}
      extraPadding
    >
      <div className={cls.top}>
        <div className={cls.controls}>
          <div className={cls.extractionTitle}>Extractions</div>
          {loading && <Loader />}
          <Button disabled={loading} onClick={requestScore} className={cls.controlsBtn}
            variant="contained" color="primary">
            Update profile score
          </Button>
        </div>

        <div className={cls.extraction}>
          <InitialLine title="Bureau Score" widthTitle={16} compact>
            {BureauScore || '–'}
          </InitialLine>
          <InitialLine title="Highest Debt Indicator (MOP)" widthTitle={16} compact>
            {NumeroMop || '–'}
          </InitialLine>
          <InitialLine title="Current Debt" widthTitle={16} compact>
            {moneyFormatter.format(CurrentDebt || 0)}
          </InitialLine>
        </div>

        <div className={cls.info}>
          <div className={cls.infoRow}>
            <div className={cls.infoTitle}>
              Bureau full report (bureau{profile?.accountType})
            </div>
            <CheckIcon status={(!profile || profile.createdError) ? 'pending'
              : profile.reportError ? 'error' : 'ok'} />
          </div>

          {!user.isCompany && (
            <div className={cls.infoRow}>
              <div className={cls.infoTitle}>Score full report (prospectorPF)</div>
              <CheckIcon status={(!profile || profile.createdError) ? 'pending'
                : profile.scoreError ? 'error' : 'ok'} />
            </div>
          )}

          <div className={cls.infoRow}>
            <div className={cls.infoTitle}>Create Profile request</div>
            <CheckIcon status={!profile ? 'pending' : profile.createdRequest ? 'ok' : 'error'} />
          </div>

          <div className={cls.infoRow}>
            <div className={cls.infoTitle}>Create Profile response</div>
            <CheckIcon status={!profile ? 'pending' : profile.createdError ? 'error' : 'ok'} />
          </div>
        </div>
      </div>

      <div className={cls.footer}>
        <div>Moffin Score imported at:</div>
        <div>
          {profile?.updatedAt ? dateFormatter.toDateTime(profile.updatedAt, true) : '–'}
        </div>
      </div>
    </InitialCard>
  );
};

const CheckIcon = ({ status }: {status: 'pending' | 'error' | 'ok'}) => (
  <>
    {status === 'pending' && (
      <ClockCircleFilled className={clsx(cls.infoIcon, cls.pending)} />
    )}
    {status === 'error' && (
      <CloseCircleFilled className={clsx(cls.infoIcon, cls.error)} />
    )}
    {status === 'ok' && (
      <CheckCircleFilled className={cls.infoIcon} />
    )}
  </>
);
