import { EditProps, useResourceContext } from 'react-admin';
import { useUpdated } from './use-updated';
import { rbac } from '@utils';

export const useEditProps = (message = 'The changes have been saved') => {
  const updated = useUpdated(message);
  const resource = useResourceContext();
  const readonlyAccess = rbac.readonlyAccess(resource);

  const editProps: Partial<EditProps> = {
    mutationMode: 'pessimistic',
    mutationOptions: { onSuccess: updated },
    transform: transform,
    sx: readonlyAccess ? {
      'button, .ant-upload': {
        visibility: 'hidden !important',
      },
    } : undefined,
  };

  return editProps;
};

const transform = (obj: any) => Object.keys(obj).reduce((acc, key) => {
  acc[key] = obj[key] === '' ? null : obj[key];

  return acc;
}, {} as any);
