import { makeStyles } from '@mui/styles';
import { Col, Row } from 'antd';
import { useWatch } from 'react-hook-form';

import { User } from '@types';
import { InitialCard } from '@components';
import { useEffect, useState } from 'react';
import { userHttp } from '@network';
import { UserLoginBtn } from '@pages/user/UserLoginBtn';
import { checkSuperadmin } from '@providers';
import { checkRbacPermission } from '@utils';
import { CommentsWidget } from './UserCommentField';

export const UserComments = ({ user, editable }: {user: User; editable?: boolean}) => {
  const cls = useStyles();
  const [smsCode, setSmsCode] = useState<string>('None');
  const comments = useWatch({ name: 'comments' });

  const isSuperadmin = checkSuperadmin();
  const permissionUserLogin = checkRbacPermission('user/{id}/login-admin|ALL');
  const showLoginButton = isSuperadmin || permissionUserLogin;

  useEffect(() => {
    (async () => {
      const result = await userHttp.getUserSmsCode(user.id);
      setSmsCode(result);
    })();
  }, [user]);

  return (
    <InitialCard autoHeight extraPadding>
      <Row gutter={[24, 24]} className={cls.box}>
        <Col sm={24} md={12}>
          <div className={cls.leftBox}>
            <div className={cls.title}>Comments</div>
            <CommentsWidget
              comments={comments}
              partnerClass="User"
              partnerId={user.id}
              editable={editable}
            />
          </div>
        </Col>
        <Col sm={24} md={12}>
          <div className={cls.rightBox}>
            <div className={cls.title}>The last SMS or mail code for logon</div>
            <div className={cls.smsCode}>{smsCode}</div>
            {showLoginButton && (
              <div className={cls.login}><UserLoginBtn /></div>
            )}
          </div>
        </Col>
      </Row>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    fontFamily: 'Outfit, sans-serif',
    color: '#272727',
  },
  title: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: 15,
  },
  smsCode: {
    borderRadius: 8,
    border: '1px solid #042E6B',
    padding: '13px 30px',
    fontSize: 15,
    fontWeight: 500,
  },
  login: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  leftBox: {},
  rightBox: {},
});
