import { FunctionFieldProps, FunctionField, useRefresh, useResourceContext } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { Button } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import { useRequest } from '@hooks';
import { http } from '@network';

export const MovePositionField = (props: Partial<FunctionFieldProps>) => {
  return (
    <FunctionField
      {...props}
      sortable={false}
      render={(record: any) => (
        <PositionWidget model={record} />
      )}
    />
  );
};

const PositionWidget = ({ model }: { model: { id: number } }) => {
  const cls = useStyles();
  const refresh = useRefresh();
  const resource = useResourceContext();

  const { loading: loadingUp, submit: submitUp } = useRequest(async () => {
    await http.get(`${resource}/${model.id}/move`, { params: { direction: 'up' } });
    refresh();
  });

  const { loading: loadingDown, submit: submitDown } = useRequest(async () => {
    await http.get(`${resource}/${model.id}/move`, { params: { direction: 'down' } });
    refresh();
  });

  return (
    <div onClick={e => e.stopPropagation()} className={cls.box}>
      <Button
        loading={loadingUp}
        onClick={submitUp}
        icon={<ArrowUpOutlined />}
        type="ghost"
        className={cls.btn}
      ></Button>

      <Button
        loading={loadingDown}
        onClick={submitDown}
        icon={<ArrowDownOutlined />}
        type="ghost"
        className={cls.btn}
      ></Button>
    </div>
  );
};

const useStyles = makeStyles({
  box: {},
  btn: {
    marginBottom: 15,
  },
});
