import React, { useEffect, useLayoutEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';

import { reportsHttp, VisitorReport } from '@network';
import { InitialCard } from '@components';

interface Props {
  range: string;
  type: string;
  setLoader: (num: number, value: boolean) => void;
}

const colors = {
  visited: '#FD8A32',
  rate: '#002A77',
};
type Field = keyof typeof colors;

export const VisitorsReport = ({ range, type, setLoader }: Props) => {
  const [numbers, setNumbers] = useState<VisitorReport[]>([]);
  const cls = useStyles();
  const id = 'visitors-report';

  const loadNumbers = async () => {
    setLoader(4, true);
    try {
      const nextNumbers = await reportsHttp.visitorsReport({ range, type });
      setNumbers(nextNumbers);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(4, false);
    }
  };

  useEffect(() => void loadNumbers(), [range, type]);

  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        paddingLeft: 0,
        layout: root.verticalLayout,
      }),
    );

    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal',
      }),
    );

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minorGridEnabled: true,
      minGridDistance: 60,
    });
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'period',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );
    xRenderer.grid.template.setAll({
      location: 1,
    });

    xAxis.data.setAll(numbers);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.1,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      }),
    );
    const yAxisRight = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.1,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
          opposite: true,
        }),
      }),
    );

    function setColor(fieldName: Field) {
      const color = colors[fieldName];
      return am5.color(color);
    }

    const series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Website Viewers',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'visited',
        categoryXField: 'period',
        fill: setColor('visited'),
        stroke: setColor('visited'),
      }),
    );

    series1.columns.template.setAll({
      tooltipText: '{name}: {valueY}',
      width: am5.percent(90),
      tooltipY: 0,
      strokeOpacity: 0,
    });
    series1.data.setAll(numbers);

    const series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Registration rate',
        xAxis: xAxis,
        yAxis: yAxisRight,
        valueYField: 'rate',
        categoryXField: 'period',
        fill: setColor('rate'),
        stroke: setColor('rate'),
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '{name}: {valueY}',
        }),
      }),
    );

    series2.strokes.template.setAll({
      strokeWidth: 3,
      templateField: 'strokeSettings',
    });

    series2.data.setAll(numbers);

    series2.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          strokeWidth: 3,
          stroke: series2.get('stroke'),
          radius: 5,
          fill: root.interfaceColors.get('background'),
        }),
      });
    });

    chart.set('cursor', am5xy.XYCursor.new(root, {}));

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      }),
    );
    legend.data.setAll(chart.series.values);

    chart.appear(1000, 100);
    series1.appear();

    return () => {
      root.dispose();
    };
  }, [id, numbers]);
  const height = numbers && numbers.length > 0 ? 350 : 50;

  return (
    <InitialCard left="Visitors / Effective registrations rate Graph"
      extraPadding autoHeight contentClass={cls.content} mt={22}>
      <div id={id} className={cls.chart} style={{ height, marginBottom: -45 }} />
    </InitialCard>
  );
};

const useStyles = makeStyles({
  content: {
    paddingTop: 30,
    paddingBottom: 17,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chart: {
    width: '100%',
    height: 350,
  },
});
