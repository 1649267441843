import { moneyFormatter } from '@utils';
import { InitialCard } from '@components';
import cls from './Table.module.css';
import { Link } from 'react-admin';

export interface FinanceDashboardTableProps {
  activation: string;
  mql: number;
  budget: number;
  cpl: number;
}

export interface FinanceDashboardTableItemProps {
  title: string;
  link?: string;
  list: FinanceDashboardTableProps[];
}

const LinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className={cls.linkIcon} viewBox="0 0 24 24">
    <path d="M23.717,20.185l-3.063,3.063c-.61,.61-1.653,.178-1.653-.685v-1.563H1.5c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5H19.001v-1.563c0-.863,1.043-1.295,1.653-.685l3.063,3.063c.378,.378,.378,.991,0,1.369ZM18.201,3.07c1.622,.329,2.799,1.771,2.799,3.43,0,1.93-1.57,3.5-3.5,3.5h-4c-1.93,0-3.5-1.57-3.5-3.5,0-.829-.672-1.5-1.5-1.5s-1.5,.671-1.5,1.5c0,3.584,2.916,6.5,6.5,6.5h4c3.584,0,6.5-2.916,6.5-6.5,0-3.079-2.188-5.758-5.201-6.37-.816-.163-1.604,.359-1.769,1.172-.165,.812,.359,1.604,1.171,1.769ZM5.201,12.87c.792,.167,1.607-.354,1.769-1.172,.165-.812-.359-1.604-1.171-1.769-1.622-.329-2.799-1.771-2.799-3.43,0-1.93,1.57-3.5,3.5-3.5h4c1.93,0,3.5,1.57,3.5,3.5,0,.829,.672,1.5,1.5,1.5s1.5-.671,1.5-1.5c0-3.584-2.916-6.5-6.5-6.5H6.5C2.916,0,0,2.916,0,6.5c0,3.079,2.188,5.758,5.201,6.37Z"/>
  </svg>
);

export const Table = ({ title, link, list }: FinanceDashboardTableItemProps) => {
  return (
    <InitialCard
      left={title}
      right={link
        ? <Link className={cls.link} to={link} target="_blank"><LinkIcon/></Link>
        : undefined}
      mediumPadding
      contentClass={cls.card}
      wrapperClass={cls.cardWrap}
      minHeight={300}
    >
      <table className={cls.table}>
        <thead className={cls.thead}>
          <tr className={cls.tr}>
            <th className={cls.th}>Activation</th>
            <th className={cls.th}>MQL’s</th>
            <th className={cls.th}>Budget</th>
            <th className={cls.th}>CPL</th>
          </tr>
        </thead>
        <tbody className={cls.tbody}>
          {list.map((item, index) => (
            <tr className={cls.tr} key={index}>
              <td className={cls.td}>{item.activation}</td>
              <td className={cls.td}>{item.mql}</td>
              <td className={cls.td}>{moneyFormatter.format(item.budget)}</td>
              <td className={cls.td}>{moneyFormatter.format(item.cpl)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </InitialCard>
  );
};
