import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';

interface Props {
  title: string | JSX.Element;
  fullWidth?: boolean;
}

export const CreditRow = ({ children, title, fullWidth }: PropsWithChildren<Props>) => {
  const cls = useStyles();

  return (
    <Row className={cls.row}>
      <Col sm={fullWidth ? 24 : 10} className={cls.title}>{title}</Col>
      <Col sm={fullWidth ? 24 : 14} className={cls.content}>{children}</Col>
    </Row>
  );
};

const useStyles = makeStyles({
  box: {
    marginTop: 15,
    fontFamily: 'Outfit, sans-serif',
    color: '#000',
  },
  row: {
    marginTop: 18,
    fontSize: 15,
    lineHeight: 1.1,
    fontWeight: 400,
    minHeight: 24,
    color: '#000',
  },
  title: {
    fontSize: 15,
    fontWeight: 500,
    color: '#000',
  },
  content: {
    color: '#000',
  },
});
