import { Button } from '@mui/material';
import { useNotify } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { RcFile } from 'antd/lib/upload';
import { Form, Input, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';

import { showAxiosError } from '@utils';
import { CreditRow } from './credit-row';
import { Credit, CreditFarm } from '@types';
import { creditFarmHttp, creditHttp, s3http } from '@network';
import { FileControlled, FileUpload, ManyTagsControlledInput } from '@components';

interface FormProps {
  totalArea: string | number | null;
  documentId: number | null;
  pictureId: number | null;
  cropIds: number[];
}

interface Props {
  credit: Credit;
  farm: CreditFarm;
  onUpdateFarm: (farm: CreditFarm) => void;
  onPlusFarm: (e: any) => void;
  setCredit?: (newCredit: Credit) => void;
}

export const FarmForm = ({ credit, setCredit, farm, onPlusFarm, onUpdateFarm }: Props) => {
  const cls = useStyles();
  const [form] = Form.useForm<FormProps>();
  const [loading, setLoading] = useState(false);
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [cropIds, setCropIds] = useState<number[]>(farm.cropIds || []);
  const totalArea = Form.useWatch('totalArea', form);
  const notify = useNotify();
  const creditId = credit.id;

  useEffect(() => {
    setDocumentUrl(farm.document?.adminUrl);
    setCropIds(farm.cropIds || []);
    form.setFieldValue('totalArea', farm.totalArea || 0);
    form.setFieldValue('cropIds', farm.cropIds || []);
  }, [farm]);

  const totalAreaChange = (value: string | number | null) => {
    form.setFieldValue('totalArea', value || 0);
    farm.totalArea = value || 0;
    onUpdateFarm(farm);
  };

  const uploadDocument = async (rcFile: RcFile) => {
    setLoading(true);
    try {
      const file = await s3http.uploadFile(rcFile as File);
      setDocumentUrl(file.adminUrl);
      form.setFieldValue('documentId', file.id);

      return file.adminUrl;
    } catch (err: any) {
      showAxiosError(err);

      return '';
    } finally {
      setLoading(false);
    }
  };

  const removeDocument = () => {
    setDocumentUrl(undefined);
    form.setFieldValue('documentId', null);
    notify('PDF document is removed');
    farm.documentId = null;
    onUpdateFarm(farm);
  };

  const updateFarm = async () => {
    let values: FormProps;
    try {
      values = await form.validateFields();
    } catch (e) {
      return;
    }

    setLoading(true);
    try {
      const params = { ...farm, ...values, cropIds, creditId };
      farm.id > 0
        ? await creditFarmHttp.updateFarm(params)
        : await creditFarmHttp.addFarm(params);
      notify('Saved');
      const credit = await creditHttp.getCredit(creditId);
      setCredit && setCredit(credit);
    } catch (err: any) {
      showAxiosError(err);
    } finally {
      setLoading(false);
    }
  };

  const updateCropIds = (nextIds: number[]) => {
    const filteredIds = nextIds.filter(id => Number.isInteger(id));
    form.setFieldValue('cropIds', filteredIds);
    setCropIds(filteredIds);
    farm.cropIds = filteredIds;
    onUpdateFarm(farm);
  };

  return (
    <Form form={form} initialValues={{
      documentId: farm.documentId || null,
      totalArea: farm.totalArea || 0,
      cropIds: farm.cropIds || [],
    } as FormProps}>
      <Form.Item name="documentId" hidden><Input type="hidden" /></Form.Item>
      <CreditRow title="Credit:">{credit.code}</CreditRow>
      <CreditRow title="Crops:">
        <ManyTagsControlledInput placeholder="pick crops..."
          ids={cropIds} setIds={updateCropIds} reference="crop" />
      </CreditRow>
      <CreditRow title="Coordinate:">
        {farm.latitude ? `${farm.latitude}, ${farm.longitude}` : '–'}
      </CreditRow>
      <CreditRow title="State:">{farm.state || '–'}</CreditRow>
      <CreditRow title="Total area:">
        <Form.Item name="totalArea" className={cls.inputItem}>
          <div className={cls.inputRow}>
            <InputNumber value={totalArea} onChange={totalAreaChange} />
            <div>&nbsp;Ha</div>
          </div>
        </Form.Item>
      </CreditRow>

      {farm.id > 0 ? (
        <CreditRow title="PDF report">
          {documentUrl ? (
            <FileControlled
              adminUrl={documentUrl}
              downloadFilename={'PDF report.pdf'}
              onDelete={removeDocument}
              wrapClass={cls.fileWrap}
            />
          ) : (
            <FileUpload uploadAction={uploadDocument} type="pdf" />
          )}
        </CreditRow>
      ) : null}

      <div className={cls.buttons}>
        <Button variant="contained" color="secondary" disabled={loading}
          onClick={updateFarm} className={cls.btn}>
          <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'inline-block' }}
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none">
            <g clipPath="url(#clip0_3843_4772)">
              <path d="M14.8964 1.75077L13.2321 0.10257C13.1643 0.0389059 13.075 0 12.9786 0H2.25C2.21429 0 2.18571 0 2.15 0C2.15 0 2.14643 0 2.14286 0H0.357143C0.160714 0 0 0.159161 0 0.35369V14.6463C0 14.8408 0.160714 15 0.357143 15H14.6429C14.8393 15 15 14.8408 15 14.6463V2.00189C15 1.90639 14.9607 1.81797 14.8964 1.75077ZM7.5 11.8486C6.11786 11.8486 5 10.7416 5 9.37279C5 8.00401 6.11786 6.89696 7.5 6.89696C8.88214 6.89696 10 8.00401 10 9.37279C10 10.7416 8.88214 11.8486 7.5 11.8486ZM11.4286 3.90474C11.4286 4.49187 10.95 4.96581 10.3571 4.96581H4.64286C4.05 4.96581 3.57143 4.49187 3.57143 3.90474V1.40769C3.57143 1.22377 3.72143 1.07522 3.90714 1.07522L7.91071 1.06814C8.07857 1.06814 8.21429 1.20255 8.21429 1.36878V3.47324C8.21429 3.70667 8.40357 3.89413 8.63929 3.89413H9.575C9.81071 3.89413 10 3.70667 10 3.47324V1.36171C10 1.19547 10.1357 1.06107 10.3036 1.06107H11.0929C11.2786 1.06107 11.4286 1.20962 11.4286 1.39354V3.90474Z"
                fill="#E1E5ED" />
            </g>
            <defs>
              <clipPath id="clip0_3843_4772">
                <rect width="15" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span className={cls.btnText}>Save</span>
        </Button>
        <Button variant="contained" color="primary" disabled={loading}
          onClick={onPlusFarm} className={cls.btn}>
          <span className={cls.btnText}>+ Add Farm</span>
        </Button>
      </div>
    </Form>
  );
};

const useStyles = makeStyles({
  buttons: {
    marginTop: 25,
    display: 'flex',
  },
  btn: {
    marginRight: 15,
    '& svg': {
      marginRight: 10,
    },
  },
  btnText: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 400,
    color: '#E1E5ED',
  },
  inputItem: {
    margin: '-8px 0 0 -12px',
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      border: 'none',
      width: 70,
    },
    '& input': {
      background: '#E1E5ED !important',
    },
  },
  fileWrap: {
    justifyContent: 'flex-start',
  },
});
