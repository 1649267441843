import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { makeStyles } from '@mui/styles';
import ChatIcon from '@mui/icons-material/Chat';
import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { clsx } from 'clsx';

import { useSettingsContext } from '@providers';
import { UnreadedChat } from '@network';
import { ChatMessages, CreditChat } from '@components';
import { ChatMessage } from '@types';
import { Sidepanel } from '../Sidepanel';

export const ChatHeader = () => {
  const location = useLocation();
  const cls = useStyles();
  const { unreaded, panelName, setPanelName } = useSettingsContext();
  const [selectedCredit, setSelectedCredit] = useState<number | null>();

  useEffect(() => {
    setPanelName(null);
    setSelectedCredit(null);
  }, [location.pathname]);

  const isChatOpened = !!selectedCredit;
  const count = unreaded ? unreaded.unreadedChats : 0;
  const closeChat = () => setSelectedCredit(undefined);

  const sidepanelName = 'Chats';
  const togglePanel = () => setPanelName(panelName === sidepanelName ? null : sidepanelName);

  const readedChats: UnreadedChat[] = [];
  const unreadedChats: UnreadedChat[] = [];

  if (unreaded && unreaded.chats) {
    for (const chat of Object.values(unreaded.chats)) {
      chat.unreaded > 0 ? unreadedChats.push(chat) : readedChats.push(chat);
    }
  }

  const chats = unreadedChats
    .concat(readedChats)
    .sort((a, b) => {
      const date1 = new Date(a.createdAt);
      const date2 = new Date(b.createdAt);

      return date2.getTime() - date1.getTime() > 0 ? 1 : -1;
    });

  if (unreaded === false) {
    return null;
  }

  return (
    <>
      <div className={cls.icon} onClick={togglePanel}>
        <ChatIcon style={{ fill: '#002a77' }} />
        {count > 0 && (
          <div className={cls.counter}>{count > 9 ? '9+' : count}</div>
        )}
      </div>

      <Sidepanel name={sidepanelName} className={clsx(isChatOpened ? cls.formOpen : cls.formClose)}>
        {chats.length > 0 ? (
          <div className={cls.chats}>
            {chats.map(chat => (
              <Button variant="text" key={chat.creditId} className={cls.chatBtn}
                onClick={() => setSelectedCredit(chat.creditId)}>
                <div className={cls.chatTitle}>{chat.title}</div>
                <ChatMessages messages={[chat.lastMessage as ChatMessage]} />
                {chat.unreaded > 0 && (
                  <div className={clsx(cls.counter, cls.counterUnreaded)}>
                    {chat.unreaded > 9 ? '9+' : chat.unreaded}
                  </div>
                )}
              </Button>
            ))}
          </div>
        ) : (
          <div className={cls.empty}>You have no new messages</div>
        )}
      </Sidepanel>

      <Modal
        title="Chat"
        open={isChatOpened}
        footer={false}
        onCancel={closeChat}
        closable
        className={cls.modal}
        closeIcon={<Close style={{ marginTop: 14 }} />}
      >
        {isChatOpened && (
          <CreditChat creditId={selectedCredit} height={338} isPopup closeChat={closeChat} />
        )}
      </Modal>
    </>
  );
};

const useStyles = makeStyles({
  chats: {
    display: 'flex',
    flexDirection: 'column',
  },
  formOpen: {
    // right: 15,
    // transition: 'right ease 0s',
  },
  formClose: {
    // right: 0,
    // transition: 'right 0s ease 0s',
  },
  chatBtn: {
    display: 'block',
    textAlign: 'left',
    textTransform: 'none',
    paddingLeft: 40,
  },
  chatTitle: {
    marginBottom: -10,
    fontSize: 20,
    color: '#000',
  },
  icon: {
    width: '27.5px',
    height: '27.5px',
    borderRadius: '50%',
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 30,
    position: 'relative',
  },
  counter: {
    position: 'absolute',
    top: -5,
    right: -10,
    color: '#fff',
    background: 'red',
    width: 20,
    height: 20,
    lineHeight: '20px',
    textAlign: 'center',
    fontSize: 12,
    borderRadius: '50%',
  },
  counterUnreaded: {
    top: 34,
    right: 42,
    width: 28,
    height: 28,
    lineHeight: '28px',
    fontSize: 14,
  },
  empty: {
    marginTop: 40,
    textAlign: 'center',
    color: '#042E6B',
  },
  modal: {
    color: 'red',
  },
});
