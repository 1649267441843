import { clsx } from 'clsx';
import { Button } from '@mui/material';
import MaskedInput from 'antd-mask-input';
import SaveIcon from '@mui/icons-material/Save';
import { Select, Col, Form, Input, Row, FormInstance } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Crop, State } from '@types';
import { ActivationIdCpl, ConfirmDelete, Loader } from '@components';
import { cropHttp, leadHttp, LeadOptionsProps, stateHttp } from '@network';
import { FinanceActivation, financeSettingsHttp } from '@network/finance-settings-http';
import cls from './LeadForm.module.css';

const { Option } = Select;

interface Props {
  form: FormInstance;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isEdit?: boolean;
  onDelete?: () => void;
}

interface OptionProps {
  id: number;
  name: string;
}

export const LeadForm = ({ form, loading, isEdit, onDelete }: Props) => {
  const labelCol = { md: 5, lg: 5, xl: 7, xxl: 5 };
  const PHONE_CODE_REG_EXP = /^\+52|^\+7/;
  const [pseudoLoader, setPseudoLoader] = useState(true);
  const [states, setStates] = useState<State[]>([]);
  const [managers, setManagers] = useState<OptionProps[]>([]);
  const [crops, setCrops] = useState<Crop[]>([]);
  const [stages, setStages] = useState<string[]>([]);
  const [activations, setActivations] = useState<FinanceActivation[]>([]);
  const activationId = Form.useWatch('activationId', form);

  const getActivations = async () => {
    try {
      const nextActivations = await financeSettingsHttp.getActivations();
      if (!nextActivations) {
        throw new Error('The list of Activations has not been received');
      }
      setActivations(nextActivations);
    } catch (e) {
      console.error(e);
    }
  };

  const getCrops = async () => {
    try {
      const list: Crop[] = await cropHttp.getAll();
      if (!list) {
        throw new Error('The list of crops has not been received');
      }
      setCrops(list);
    } catch (e) {
      console.error(e);
    }
  };

  const getStates = async () => {
    try {
      const list: State[] = await stateHttp.getAll();
      if (!list) {
        throw new Error('The list of states has not been received');
      }
      setStates(list);
    } catch (e) {
      console.error(e);
    }
  };

  const getOptions = async () => {
    try {
      const ops: LeadOptionsProps = await leadHttp.options();
      if (!ops) {
        throw new Error('The list of options has not been received');
      }
      setManagers(ops.responsibleAdmins);
      setStages(ops.stages);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      await Promise.all([getActivations(), getOptions(), getCrops(), getStates()]);
      setTimeout(() => {
        setPseudoLoader(false);
      }, 1000);
    })();
  }, []);

  return (
    <div className={cls.formWrap}>
      <Row gutter={{ xl: 80, xxl: 120 }} className={cls.infoWrapper}>
        {pseudoLoader ? (
          <div className={cls.overlay}>
            <Loader center />
          </div>
        ) : null}
        <Col xs={24} sm={12} className={clsx(cls.infoCol, pseudoLoader && cls.blur)}>
          <Form.Item
            label="Name*"
            name="name"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[
              { required: true, message: '' },
              { max: 40, message: 'Name should be less then 40 characters' },
            ]}
          >
            <Input bordered={false} className={cls.inputField} disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Stage"
            name="stage"
            className={cls.formItem}
            labelCol={labelCol}
          >
            <Select
              className={cls.selectField}
              allowClear
              bordered={false}
              disabled={loading}
            >
              {stages.map((item, idx) => (
                <Option value={item} label={item} className={cls.selectOption} key={idx}>
                  <div className={clsx(cls.selectOptionWrap, cls.capitalize)}>{item}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Phone*"
            name="phone"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[
              () => ({
                validator(_, value) {
                  const phone = value?.replace(PHONE_CODE_REG_EXP, '').replace(/\D/g, '') || '';
                  if (phone.length === 0) {
                    return Promise.reject(new Error(''));
                  }
                  if (phone.length !== 10) {
                    return Promise.reject(new Error('Type in a correct phone'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <MaskedInput
              mask="+52 (000) 000-0000"
              className={clsx(cls.inputField, cls.maskedField)}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[
              () => ({
                validator(_, value) {
                  if (!value) return Promise.resolve();
                  const reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/gi;
                  if (!reg.test(value)) {
                    return Promise.reject(new Error('Type in a correct email'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input bordered={false} className={cls.inputField} disabled={loading} />
          </Form.Item>
          <Form.Item
            label="State"
            name="stateId"
            className={cls.formItem}
            labelCol={labelCol}
          >
            <Select
              className={cls.selectField}
              allowClear
              bordered={false}
              disabled={loading}
              showSearch
              optionFilterProp="children"
              filterOption={(input: string, option: any) =>
                (option?.label || '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(a, b) =>
                (a?.label || '').toLowerCase().localeCompare((b?.label || '').toLowerCase())
              }
            >
              {states.map(item => (
                <Option
                  value={item.id}
                  label={item.name}
                  className={cls.selectOption}
                  key={item.id}
                >
                  <div className={cls.selectOptionWrap}>{item.name}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Crops"
            name="cropIds"
            className={cls.formItem}
            labelCol={labelCol}
          >
            {crops.length > 0 ? (
              <Select
                mode="multiple"
                className={cls.selectField}
                allowClear
                bordered={false}
                disabled={loading}
                showSearch
                optionFilterProp="children"
                filterOption={(input: string, option: any) =>
                  (option?.label || '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(a, b) =>
                  (a?.label || '').toLowerCase().localeCompare((b?.label || '').toLowerCase())
                }
              >
                {crops.map(crop => (
                  <Option
                    value={crop.id}
                    label={crop.nameMx}
                    className={cls.selectOption}
                    key={crop.id}
                  >
                    <div className={cls.selectOptionWrap}>{crop.nameMx}</div>
                  </Option>
                ))}
              </Select>
            ) : null}
          </Form.Item>
          <Form.Item
            label="Activation"
            name="activationId"
            className={cls.formItem}
            labelCol={labelCol}
          >
            <Select
              className={cls.selectField}
              allowClear
              bordered={false}
              disabled={loading}
              showSearch
              optionFilterProp="children"
              filterOption={(input: string, option: any) =>
                (option?.label || '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(a, b) =>
                (a?.label || '').toLowerCase().localeCompare((b?.label || '').toLowerCase())
              }
            >
              <Option
                value={null}
                label=""
                className={cls.selectOption}
              >
                <div className={cls.selectOptionWrap}></div>
              </Option>
              {activations.map(item => (
                <Option
                  value={item.id}
                  label={item.activation}
                  className={cls.selectOption}
                  key={item.id}
                >
                  <div className={cls.selectOptionWrap}>{item.activation}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Manager"
            name="responsibleAdminId"
            className={cls.formItem}
            labelCol={labelCol}
          >
            <Select
              className={cls.selectField}
              allowClear
              bordered={false}
              disabled={loading}
              showSearch
              optionFilterProp="children"
              filterOption={(input: string, option: any) =>
                (option?.label || '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(a, b) =>
                (a?.label || '').toLowerCase().localeCompare((b?.label || '').toLowerCase())
              }
            >
              {managers.map(item => (
                <Option
                  value={item.id}
                  label={item.name}
                  className={cls.selectOption}
                  key={item.id}
                >
                  <div className={cls.selectOptionWrap}>{item.name}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Hubspot ID"
            name="hubspotId"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[
              { max: 11, message: 'Hubspot ID should be up to 11 characters' },
            ]}
          >
            <Input bordered={false} className={cls.inputField} disabled={loading} maxLength={11} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} className={cls.rightCol}>
          <ActivationIdCpl activationId={activationId} className={cls.activationCpl} />
        </Col>
      </Row>

      <div className={clsx(cls.actions, isEdit && cls.editActions)}>
        <Button variant="contained"
          color="secondary"
          className={cls.save}
          type="submit"
          disabled={loading}>
          <SaveIcon /> Save
        </Button>
        {isEdit && onDelete ? (
          <ConfirmDelete
            title="Are you sure?"
            cb={onDelete}
            overlayClassName={cls.overlayClassName}
            placement="right"
          >
            <Button variant="contained" className={cls.delete} onClick={undefined}>Delete</Button>
          </ConfirmDelete>
        ) : null}
      </div>
    </div>
  );
};
