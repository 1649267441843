import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props {
  max?: boolean;
  className?: string;
}

export const Container = ({ children, max, className }: PropsWithChildren<Props>) => {
  const maxWidth = max ? 'unset' : '800px';

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ width: '100%', maxWidth }}
      className={className}
    >
      {children}
    </Box>
  );
};
