import { clsx } from 'clsx';
import { FunctionField, FunctionFieldProps } from 'react-admin';

import Chip from '@mui/material/Chip';
import cls from './status-field.module.css';

export type StatusTypes = 'success' | 'info' | 'warning' | 'error';

interface StatusProps {
  color: string;
  border: string;
}

export type StatusSettings = {
  [key in StatusTypes]: StatusProps
}

const statusSettings: StatusSettings = {
  success: { color: '#B7EB8F', border: '#52C41A' },
  info: { color: '#91CAFF', border: '#1677FF' },
  warning: { color: '#FFE58F', border: '#FAAD14' },
  error: { color: '#FFCCC7CC', border: '#FF4D4F' },
};

export interface StatusFieldOptions {
  id: string; name: string; type: StatusTypes;
}

interface Props {
  autoWidth?: boolean;
  options: StatusFieldOptions[];
}

export const StatusField = (props: Props & Partial<FunctionFieldProps>) => {
  const { autoWidth, options, ...restProps } = props;

  return (
    <FunctionField {...restProps} render={(record: any) => {
      const status = record[props.source || 'status'];
      const option = options.find(o => o.id === status);

      if (!option) {
        return <Chip label={status} size="small" className={cls.chip}/>;
      }

      const { name, type } = option;
      const settings: StatusProps | undefined = statusSettings[type];

      if (!settings) {
        return <Chip label={status} size="small" className={cls.chip}/>;
      }

      const { color, border } = settings;

      return record && color ? (
        <Chip
          label={name || status}
          size="small"
          style={{
            backgroundColor: color ?? undefined,
            width: autoWidth ? 'auto' : '100%',
            padding: autoWidth ? '5px 15px' : undefined,
            color: `${border}!important` ?? undefined,
            border: border ? `1px solid ${border}` : undefined,
          }}
          className={clsx(cls.chip, cls[type])}
        />
      ) : null;
    }} />
  );
};
