import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined,
    height: number | undefined,
  }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      let timer: any;
      const handleResize = () => {
        // Debouncing - механизм предотвращения повторного срабатывания в интервале 10мс
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        }, 10);
      };
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowSize;
};
