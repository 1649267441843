import { FunctionField, FunctionFieldProps } from 'react-admin';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';

export const activationStatusOptions = [
  { id: 'Active', name: 'Active', color: '#36BE29' },
  { id: 'Planning', name: 'Planning', color: '#F07F11' },
  { id: 'Paused', name: 'Paused', color: '#FFA426' },
  { id: 'Closed', name: 'Closed', color: '#74706B' },
  { id: 'Deleted', name: 'Deleted', color: '#DD482E' },
  { id: 'Delivered', name: 'Delivered', color: '#39A1FF' },
  { id: 'Inactive', name: 'Inactive', color: '#673AB7' },
  { id: 'Completed', name: 'Completed', color: '#002A77' },
  { id: 'Archived', name: 'Archived', color: '#97BDDA' },
];

interface Props {
  autoWidth?: boolean;
  value?: string;
}

export const FinanceActivationStatusField = (props: Props & Partial<FunctionFieldProps>) => {
  const cls = useStyles();
  const { autoWidth, ...restProps } = props;

  return (
    <FunctionField {...restProps} render={(record: any) => {
      const status: string = props.value || record[props.source || 'status'];
      const statusName = activationStatusOptions.find(o => o.id === status)?.name;
      const color: any = activationStatusOptions.find(o => o.id === status)?.color;

      return record ? (
        <div className={cls.wrap}>
          <Chip
            label={statusName || status}
            size="small"
            style={{
              backgroundColor: color ?? undefined,
              width: autoWidth ? 'auto' : '100%',
              padding: autoWidth ? '5px 15px' : undefined,
            }}
            className={cls.chip}
          />
        </div>
      ) : null;
    }}
    />
  );
};

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  chip: {
    color: 'white !important',
    width: 'auto !important',
    lineHeight: 16,
    display: 'inline-flex',
    '& *': {
      color: 'white !important',
      lineHeight: 'normal',
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
});
