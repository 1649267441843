import { clsx } from 'clsx';
import { Tooltip } from 'antd';

import cls from './sent-by.module.css';

interface Params {
  type: 'user' | 'admin' | 'manager';
}

export const SentBy = ({ type }: Params) => {
  const isUser = type === 'user';
  const isAdmin = type === 'admin';
  const isManager = type === 'manager';

  return isAdmin ? null : (
    <Tooltip placement="top" overlayClassName={cls.overlay} title={
      <div className={cls.content}>
        <div className={cls.contentTop}>Uploaded by</div>
        <div className={cls.contentBottom}>
          {isAdmin ? ' admin' : ''}
          {isManager ? ' partner' : ''}
          {isUser ? ' farmer' : ''}
        </div>
      </div>
    }>
      <div className={clsx(cls.badge,
        isUser && cls.isUser,
        isAdmin && cls.isAdmin,
        isManager && cls.isManager,
      )} />
    </Tooltip>
  );
};
