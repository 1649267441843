import { useController, useWatch } from 'react-hook-form';
import { clsx } from 'clsx';
import { Row, Col, Input, Alert } from 'antd';
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Container } from '@components';

import cls from './Question.module.css';

const MAX_OPTIONS_COUNT = 6;

interface Props {
  source: string;
  setDisabled: Dispatch<SetStateAction<boolean>>;
}

type AnswerType = 'text' | 'number' | 'money' | 'option' | 'percent';

interface AnswerOption {
  nameMx: string;
  nameEn?: string;
}

interface Answer {
  id?: number | string;
  nameMx: string;
  nameEn?: string;
  position: number;
  isEnabled?: boolean;
  isRequired?: boolean;
  isFixed?: boolean;
  percent?: number;
  type?: AnswerType;
  options?: AnswerOption[];
}

export const PlannerInput = ({ source, setDisabled }: Props) => {
  const {
    field: { value, onChange },
  } = useController({ name: source });
  const isPlanner = useWatch({ name: 'isPlanner' });

  const [answers, setAnswers] = useState<Answer[]>(value || []);

  const totalPercent = useMemo(() => {
    return answers.reduce((acc, answer) => {
      const value = answer.isFixed ? answer.percent || 0 : 0;

      return acc + value;
    }, 0);
  }, [answers]);

  const hasAnswersLimit = answers?.length >= MAX_OPTIONS_COUNT;

  useEffect(() => {
    onChange(answers);
    setDisabled(answers.some(answer => answer.nameMx.trim() === ''));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  const onPercentChange = (answer, value) => {
    let newValue = parseInt(value);

    if (value > 100) {
      newValue = 100;
    } else if (value < 0) {
      newValue = 0;
    }

    updateAnswer(answer, 'percent', newValue);
  };

  const updateAnswer = (editAnswer: Answer, field: keyof Answer, value: any) => {
    const nextAnswers: Answer[] = answers.map((answer => {
      if (answer.position === editAnswer.position) {
        (editAnswer as any)[field] = value;

        return editAnswer;
      }

      return answer;
    }));
    setAnswers(nextAnswers);
  };

  const addAnswer = () => {
    if (answers?.length >= MAX_OPTIONS_COUNT) {
      return;
    }

    const maxPos = Math.max.apply(this, answers.map(a => a.position));
    const answer: Answer = {
      nameMx: '',
      nameEn: '',
      position: answers?.length > 0 ? maxPos + 1 : 1,
      isEnabled: true,
      isRequired: false,
      isFixed: false,
      percent: 0,
    };

    if (isPlanner) {
      answer.type = 'percent';
    }
    setAnswers([...answers, answer]);
  };

  const removeAnswer = (answer: Answer) => {
    const nextAnswers = answers.filter(a => a.position !== answer.position);
    setAnswers(nextAnswers);
  };

  useEffect(() => {
    if (setDisabled) {
      setDisabled(totalPercent > 100);
    }
  }, [totalPercent]);

  const withError = totalPercent > 100;

  return (
    <Container max>
      <>
        {answers.map((answer, index) => (
          <Row key={answer.position} gutter={24} className={cls.wrap}>
            <Col xs={24} md={18} lg={20} xl={14} xxl={16} className={cls.colAnswer}>
              <label className={cls.inputLabel}>Option {index + 1}</label>
              <Input
                placeholder="Write the option"
                className={cls.input}
                value={answer.nameMx || ''}
                onChange={e => updateAnswer(answer, 'nameMx', e.target.value)}
                autoFocus
              />
              {!answer.nameMx.trim() && <div className={cls.error}>Option can not be empty</div>}
            </Col>
            <Col xs={24} md={6} lg={4} xl={3} xxl={3} className={cls.colAnswer}>
              <label className={cls.inputLabel}>Fixed Percentage</label>
              <Input
                placeholder="%"
                className={cls.input}
                value={answer.percent || ''}
                onChange={e => onPercentChange(answer, e.target.value)}
                autoFocus
                disabled={!answer.isFixed}
              />
            </Col>
            <Col xs={24} xl={7} xxl={5} className={clsx(cls.colAnswer, cls.actions)}>
              <div className={cls.row}>
                <div className={cls.col}>
                  <FormControlLabel className={cls.enabled} label="Is fixed" control={
                    <Checkbox
                      checked={answer.isFixed}
                      onChange={() => updateAnswer(answer, 'isFixed', !answer.isFixed)}
                      name="checkedB"
                      color="primary"
                    />
                  }/>
                </div>
                <div className={cls.col}>
                  <IconButton className={cls.remove} onClick={() => removeAnswer(answer)}>
                    <DeleteIcon className={cls.removeIcon}/>
                  </IconButton>
                  <span className={cls.removeText}>Remove</span>
                </div>
              </div>
            </Col>
          </Row>
        ))}
        <Row>
          <Col className={cls.actionCol}>
            {!hasAnswersLimit && (
              <Button
                onClick={addAnswer}
                variant="outlined"
                startIcon={<AddIcon/>}
                className={cls.btn}
              >Add answer</Button>
            )}
          </Col>
        </Row>

        {withError && <Alert message={`The total percentage cannot be more than 100%. Total percentage is ${totalPercent}%.`} type="error" />}
      </>
    </Container>
  );
};

