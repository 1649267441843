import { makeStyles } from '@mui/styles';
import { Link } from 'react-admin';
import { useEffect, useState } from 'react';

import { Credit, CreditReportFile } from '@types';
import { BlueButton, InitialCard } from '@components';
import { creditHttp, CreditReportTypes } from '@network';
import { useRequest } from '@hooks';

export const CreditIncomeVerification = ({ credit }: {credit: Credit}) => {
  const cls = useStyles();

  const [reports, setReports] = useState<CreditReportFile[]>([]);

  useEffect(() => {
    (async () => {
      const nextReports = await creditHttp.getReportFiles(credit.id);
      setReports(nextReports.EKATENA_REPORT);
    })();
  }, [credit.id]);

  const { submit: downloadReports, loading: downloadLoading } = useRequest(async () => {
    await creditHttp.downloadReportFile({
      type: CreditReportTypes.EKATENA_REPORT,
      creditId: credit.id,
      filename: `${credit.code} – SAT report`,
    });
  });

  return (
    <InitialCard left="Income verification" autoHeight extraPadding>
      <div className={cls.box}>
        <Link to={`/user/${credit.userId}?moffin`}>
          <BlueButton className={cls.btn}>
            Go to Credit Bureau
          </BlueButton>
        </Link>
        <BlueButton
          className={cls.btn}
          counter={reports.length}
          disabled={reports.length === 0 || downloadLoading}
          onClick={reports.length > 0 ? downloadReports : undefined}
        >Go to SAT</BlueButton>
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  btn: {},
});
