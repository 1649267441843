import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-admin';
import { clsx } from 'clsx';

import { reportsHttp } from '@network';
import { ButtonLinkWidget, InitialCard } from '@components';
import { Credit } from '@types';
import { useSettingsContext } from '@providers/settings-provider';
import { dateFormatter, querySerialize } from '@utils';

interface Props {
  range: string;
  setLoader: (num: number, value: boolean) => void;
}

export const NewDocumentsUploadedReport = ({ range, setLoader }: Props) => {
  const { admin } = useSettingsContext();
  const [credits, setCredits] = useState<Credit[]>([]);
  const cls = useStyles();

  const loadCredits = async () => {
    setLoader(13, true);
    try {
      const nextCredits = await reportsHttp.creditsLastModification({ range });
      setCredits(nextCredits);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(13, false);
    }
  };

  useEffect(() => {
    if (admin) {
      loadCredits();
    }
  }, [range, admin]);

  const linkTo = '/credit?' + querySerialize({
    order: 'DESC',
    sort: 'modificationAt',
  });

  return (
    <InitialCard left="New Documents Uploaded" extraPadding fullHeight>
      <br />
      <TableContainer className="RaDatagrid-tableWrapper">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={cls.thead}>Code</TableCell>
              <TableCell className={clsx(cls.thead, cls.theadUser)}>User</TableCell>
              <TableCell className={cls.thead}>Hubspot Link</TableCell>
              <TableCell className={cls.thead}>Last update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {credits.map((credit) => (
              <TableRow key={credit.id}>
                <TableCell className={cls.value}>
                  <Link to={`/credit/${credit.id}?initial`} className={cls.link}>
                    {credit.code}
                  </Link>
                </TableCell>
                <TableCell className={cls.value}>
                  <Link to={`/user/${credit.user.id}?initial`} className={cls.link}>
                    {credit.user.fullName}
                  </Link>
                </TableCell>
                <TableCell className={clsx(cls.value, cls.center)}>
                  <ButtonLinkWidget text="Hubspot" link={credit.user.hubspotLink} />
                </TableCell>
                <TableCell className={clsx(cls.value, cls.center)}>
                  {credit.modificationAt ? dateFormatter.toDateSlash(credit.modificationAt) : '--'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Link to={linkTo}>
        <Button variant="contained" color="secondary" className={cls.btn}>
          View All
        </Button>
      </Link>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  thead: {
    width: '20%',
    minWidth: 'auto !important',
  },
  theadUser: {
    width: '40% !important',
  },
  value: {
    color: '#74706B',
    fontSize: 11,
    fontWeight: 400,
    height: 39,
  },
  center: {
    textAlign: 'center',
  },
  link: {
    color: '#74706B',
    fontSize: 11,
    fontWeight: 400,
  },
  btn: {
    marginTop: 20,
    padding: '10px 95px !important',
    height: 40,
    color: '#FFF',
    fontSize: 15,
    fontWeight: 400,
    borderRadius: 8,
    textTransform: 'none',
  },
});
