import { clsx } from 'clsx';
import { Packer } from 'docx';
import { Col, Row } from 'antd';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { useController, useWatch } from 'react-hook-form';

import { Geography } from '@pages/credit-risk/analysis/components/Geography';
import { RevenueTable } from '@pages/credit-risk/analysis/components/RevenueTable';
import { AddemVariablesTable } from '@pages/credit-risk/analysis/components/AddemVariablesTable';
import { TotalVariablesTable } from '@pages/credit-risk/analysis/components/TotalVariablesTable';
import { DownloadZone } from '@pages/credit-risk/analysis/components/DownloadZone';
import { DropdownTable } from '@pages/credit-risk/analysis/components/DropdownTable';
import { DownloadBlock } from '@pages/credit-risk/analysis/components/DownloadBlock';
import { TaxCropTable } from '@pages/credit-risk/analysis/components/TaxCropTable';
import { DropdownInfoWidget } from '@pages/credit-risk/analysis/components/DropdownInfoWidget';
import { ScoreTable } from '@pages/credit-risk/analysis/components/ScoreTable';
import { CreditBureau } from '@pages/credit-risk/analysis/components/CreditBureau';

import { CreditRisk, SurveyQuestion } from '@types';
import { ExportRisk } from '@pages/credit-risk/export';
import { RiskGeneral } from '@pages/credit-risk/RiskGeneral';
import { FinancialTable } from './components/FinancialTable';
import { SuppliersTable } from './components/SuppliersTable';
import { useDebounce, useIsFirstRenderTimeout, useRequest } from '@hooks';
import { DocumentCreator } from '@pages/credit/CreditSurvey/document-creator';
import { Container, InitialCard, PageHeader, ScoreWidget } from '@components';
import { creditHttp, CreditReportTypes, creditRiskHttp, surveyHttp } from '@network';
import cls from './legacy-analysis.module.css';

export const LegacyAnalysis = ({ risk }: {risk: CreditRisk}) => {
  const [isReportOpened, setReportOpened] = useState(false);
  const openReport = risk ? () => setReportOpened(true) : undefined;
  const [questions, setQuestions] = useState<SurveyQuestion[] | null>(null);
  const score = risk.riskScore || 0;
  const backTo = `/credit-risk/${risk.id}`;
  const leftCardsLabelGrid = { xs: 12, lg: 24, xl: 8, xxl: 7 };
  const leftCardsValueGrid = { xs: 12, lg: 24, xl: 16, xxl: 17 };
  const {
    field: { value: financialPastTable, onChange: changeFinancialPastTable },
  } = useController({ name: 'financialPastTable' });
  const {
    field: { value: financialProjectedTable, onChange: changeFinancialProjectedTable },
  } = useController({ name: 'financialProjectedTable' });
  const {
    field: { value: taxExpenditures, onChange: changeTaxExpenditures },
  } = useController({ name: 'taxExpenditures' });
  const {
    field: { value: taxCrop, onChange: changeTaxCrop },
  } = useController({ name: 'taxCrop' });
  const {
    field: { value: incomeSuppliers, onChange: changeIncomeSuppliers },
  } = useController({ name: 'incomeSuppliers' });
  const {
    field: { value: cropTechnification, onChange: changeCropTechnification },
  } = useController({ name: 'cropTechnification' });
  const {
    field: { value: offTakerBusinessAge, onChange: changeOffTakerBusinessAge },
  } = useController({ name: 'offTakerBusinessAge' });
  const {
    field: { value: offTakerPaymentsDso, onChange: changeOffTakerPaymentsDso },
  } = useController({ name: 'offTakerPaymentsDso' });
  const {
    field: { value: offTakerLegalIncidences, onChange: changeOffTakerLegalIncidences },
  } = useController({ name: 'offTakerLegalIncidences' });
  const {
    field: { value: offTakerLegalTimeWorking, onChange: changeOffTakerLegalTimeWorking },
  } = useController({ name: 'offTakerLegalTimeWorking' });
  const {
    field: { value: supplierScore, onChange: changeSupplierScore },
  } = useController({ name: 'supplierScore' });
  const {
    field: { value: supplierDpo, onChange: changeSupplierDpo },
  } = useController({ name: 'supplierDpo' });
  const {
    field: { value: supplierLegalIncidences, onChange: changeSupplierLegalIncidences },
  } = useController({ name: 'supplierLegalIncidences' });
  const {
    field: { value: supplierTimeWorking, onChange: changeSupplierTimeWorking },
  } = useController({ name: 'supplierTimeWorking' });
  const {
    field: { value: addemVariables, onChange: changeAddemVariablesTable },
  } = useController({ name: 'addemVariables' });
  const {
    field: { value: totalVariables, onChange: changeTotalVariablesTable },
  } = useController({ name: 'totalVariables' });
  const {
    field: { value: creditBureau, onChange: changeCreditBureau },
  } = useController({ name: 'creditBureau' });
  const {
    field: { value: scoreAmount, onChange: changeScoreAmount },
  } = useController({ name: 'scoreAmount' });
  const {
    field: { value: scoreCashRatio },
  } = useController({ name: 'scoreCashRatio' });
  const {
    field: { value: scoreTerm, onChange: changeScoreTerm },
  } = useController({ name: 'scoreTerm' });
  const {
    field: { value: replyOther, onChange: changeReplyOther },
  } = useController({ name: 'replyOther' });
  const {
    field: { value: replyScore, onChange: changeReplyScore },
  } = useController({ name: 'replyScore' });
  const isFixedReplies = useWatch({ name: 'isFixedReplies' }) as boolean;

  const debouncedFinancialPastTable = useDebounce(financialPastTable, 500);
  const debouncedFinancialProjectedTable = useDebounce(financialProjectedTable, 500);
  const debouncedTaxExpenditures = useDebounce(taxExpenditures, 500);
  const debouncedCreditBureau = useDebounce(creditBureau, 500);
  const debouncedScoreCashRatio = useDebounce(scoreCashRatio, 500);
  const debouncedScoreAmount = useDebounce(scoreAmount, 500);
  const debouncedTotalVariables = useDebounce(totalVariables, 500);
  const isFirstRender = useIsFirstRenderTimeout(3000);

  const [riskCalculated, setRiskCalculated] = useState<CreditRisk>(risk);

  const {
    submit: downloadBureauReport, loading: downloadBureauReportLoading,
  } = useRequest(async () => {
    await creditHttp.downloadReportFile({
      type: CreditReportTypes.BUREAU_REPORT,
      creditId: risk.creditId,
    });
  });

  const {
    submit: downloadSinectaReport, loading: downloadSinectaReportLoading,
  } = useRequest(async () => {
    await creditHttp.downloadReportFile({
      type: CreditReportTypes.SINECTA_REPORT,
      creditId: risk.creditId,
    });
  });

  const {
    submit: downloadEkatenaReport, loading: downloadEkatenaReportLoading,
  } = useRequest(async () => {
    await creditHttp.downloadReportFile({
      type: CreditReportTypes.EKATENA_REPORT,
      creditId: risk.creditId,
    });
  });

  const generateDocx = () => {
    if (questions) {
      const documentCreator = new DocumentCreator();
      const doc = documentCreator.createFromQeustions(questions, risk.credit);

      Packer.toBlob(doc).then(blob => {
        saveAs(blob, `${risk.credit.user.fullName}. Questionario of Credit ${risk.credit.code}.docx`);
        console.log('Document created successfully');
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const loadedSurvey = await surveyHttp.getCreditSurvey(risk.creditId);

        if (loadedSurvey) {
          setQuestions(loadedSurvey.surveyQuestions);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [risk]);

  useEffect(() => {
    !isFirstRender && (async () => {
      try {
        const result = await creditRiskHttp.calculateLegacy({
          financialPastTable,
          financialProjectedTable,
          taxExpenditures,
          creditBureau,
          taxCrop,
          scoreCashRatio,
          scoreAmount,
          totalVariables,
          id: risk.id,
        });

        if (!result) {
          throw new Error('No result');
        }

        setRiskCalculated(result);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    debouncedFinancialPastTable,
    debouncedFinancialProjectedTable,
    debouncedTaxExpenditures,
    debouncedCreditBureau,
    debouncedScoreCashRatio,
    debouncedScoreAmount,
    debouncedTotalVariables,
    isFirstRender,
  ]);

  return (
    <>
      <PageHeader
        title="Analysis"
        linkText="Back to risk page"
        linkUrl={backTo}
      />

      <Container max>
        <Row gutter={24} className={cls.row}>
          <Col xs={24} xl={16} className={cls.leftCol}>
            <Row className={cls.row}>
              <Col xs={24}>
                {risk ? <RiskGeneral
                  risk={risk}
                  isFixed={true}
                  labelGrid={leftCardsLabelGrid}
                  valueGrid={leftCardsValueGrid}
                /> : null}
              </Col>
            </Row>

            <Row className={cls.row}>
              <Col xs={24}>
                <InitialCard left="Financial variables">
                  <Row gutter={16} className={cls.row} style={{ marginTop: 22 }}>
                    {financialPastTable ? (
                      <Col xs={24} xxl={12} className={cls.tableCol}>
                        <FinancialTable
                          data={financialPastTable}
                          calcData={riskCalculated.financialPastTable}
                          onChange={changeFinancialPastTable}
                        />
                      </Col>
                    ) : null}
                    {financialProjectedTable ? (
                      <Col xs={24} xxl={12} className={cls.tableCol}>
                        <FinancialTable
                          data={financialProjectedTable}
                          calcData={riskCalculated.financialProjectedTable}
                          onChange={changeFinancialProjectedTable}
                        />
                      </Col>
                    ) : null}
                  </Row>
                </InitialCard>
              </Col>
            </Row>
          </Col>

          <Col xs={24} xl={8} className={cls.rightCol}>
            <div className={clsx(cls.colored, cls.bordered, cls.aside, cls.padding)}>
              <InitialCard center="Score Total">
                <ScoreWidget
                  value={score}
                  percents={score > risk.successPercent ? 90 : 10}
                  className={cls.scoreWidget}
                  onClick={openReport}
                />
              </InitialCard>

              <DownloadZone>
                <DownloadBlock
                  title="Report - Bureau"
                  onClick={downloadBureauReport}
                  isLoading={downloadBureauReportLoading}
                />
                <DownloadBlock
                  title="Report - Sinecta"
                  onClick={downloadSinectaReport}
                  isLoading={downloadSinectaReportLoading}
                />
                <DownloadBlock
                  title="Report - Ekatena"
                  onClick={downloadEkatenaReport}
                  isLoading={downloadEkatenaReportLoading}
                />
                <DownloadBlock
                  title="Survey"
                  onClick={generateDocx}
                  isLoading={false}
                />
              </DownloadZone>
            </div>
          </Col>
        </Row>

        <Row className={clsx(cls.row, cls.marginTop)}>
          <Col xs={24} className={cls.row}>
            <InitialCard left="Revenue/ Overall tax expenditures">
              <RevenueTable
                data={taxExpenditures}
                calcData={riskCalculated.taxExpenditures}
                onChange={changeTaxExpenditures}
              />
            </InitialCard>
          </Col>
        </Row>

        <Row className={clsx(cls.row, cls.marginTop)} gutter={24}>
          <Col xs={24} xl={12} className={clsx(cls.col, cls.colFirst)}>
            <InitialCard left="Tax revenues from the crop to be financed">
              <TaxCropTable
                data={taxCrop}
                onChange={changeTaxCrop}
                title={risk.buyer || 'Buyer'}
              />
            </InitialCard>
          </Col>
          <Col xs={24} xl={12} className={cls.col}>
            <InitialCard left="Revenue/ Overall tax expenditures">
              <SuppliersTable
                data={incomeSuppliers}
                onChange={changeIncomeSuppliers}
                title={risk.supplier || 'Supplier'}
              />
            </InitialCard>
          </Col>
        </Row>

        <Row className={clsx(cls.row, cls.marginTop)}>
          <Col xs={24} className={cls.row}>
            <InitialCard left="Credit bureau">
              <CreditBureau
                data={creditBureau}
                calcData={riskCalculated.creditBureau}
                onChange={changeCreditBureau}
              />
            </InitialCard>
          </Col>
        </Row>

        <InitialCard left="Score" boxClass={cls.marginTop}>
          <Row className={cls.row} gutter={{ lg: 24, xl: 54 }}>
            <Col xs={24} className={cls.col}>
              <ScoreTable data={riskCalculated.scoreCashRatio} />
            </Col>
            <Col xs={24} xl={12} className={cls.col}>
              <DropdownInfoWidget
                title="Credit Term"
                data={scoreTerm}
                onChange={changeScoreTerm}
              />
            </Col>
            <Col xs={24} xl={12} className={cls.col}>
              <DropdownInfoWidget
                title="% Amount of credit on agreed sale"
                data={scoreAmount}
                calcData={riskCalculated.scoreAmount}
                onChange={changeScoreAmount}
              />
            </Col>
          </Row>
        </InitialCard>

        <Row className={clsx(cls.row, cls.marginTop)} gutter={24}>
          <Col xs={24} xl={12} className={clsx(cls.col, cls.colFirst)}>
            <InitialCard left="Geography">
              <Geography geo={risk.geography} />
            </InitialCard>
          </Col>
          <Col xs={24} xl={12} className={cls.col}>
            <InitialCard left="Crop technification">
              <DropdownInfoWidget
                title="Farm technification"
                data={cropTechnification}
                onChange={changeCropTechnification}
              />
            </InitialCard>
          </Col>
        </Row>

        <Row className={clsx(cls.row, cls.marginTop)}>
          <Col xs={24} className={cls.row}>
            <InitialCard left="Off taker variables">
              <Row className={cls.row} gutter={{ lg: 24, xl: 54 }}>
                <Col xs={24} xl={12} className={cls.row}>
                  <DropdownInfoWidget
                    title="Business age"
                    data={offTakerBusinessAge}
                    onChange={changeOffTakerBusinessAge}
                  />
                  <DropdownInfoWidget
                    title="Payments DSO"
                    data={offTakerPaymentsDso}
                    onChange={changeOffTakerPaymentsDso}
                  />
                </Col>
                <Col xs={24} xl={12} className={cls.row}>
                  <DropdownInfoWidget
                    title="Legal Incidences"
                    data={offTakerLegalIncidences}
                    onChange={changeOffTakerLegalIncidences}
                  />
                  <DropdownInfoWidget
                    title="Time working with the accredited"
                    data={offTakerLegalTimeWorking}
                    onChange={changeOffTakerLegalTimeWorking}
                  />
                </Col>
              </Row>
            </InitialCard>
          </Col>
        </Row>

        <Row className={clsx(cls.row, cls.marginTop)}>
          <Col xs={24} className={cls.row}>
            <InitialCard left="Supplier variables">
              <Row className={cls.row} gutter={{ lg: 24, xl: 54 }}>
                <Col xs={24} xl={12} className={cls.row}>
                  <DropdownInfoWidget
                    title="Supplier Score"
                    data={supplierScore}
                    onChange={changeSupplierScore}
                  />
                  <DropdownInfoWidget
                    title="DPO"
                    data={supplierDpo}
                    onChange={changeSupplierDpo}
                  />
                </Col>
                <Col xs={24} xl={12} className={cls.row}>
                  <DropdownInfoWidget
                    title="Legal Incidences"
                    data={supplierLegalIncidences}
                    onChange={changeSupplierLegalIncidences}
                  />
                  <DropdownInfoWidget
                    title="Time working with the acreddited"
                    data={supplierTimeWorking}
                    onChange={changeSupplierTimeWorking}
                  />
                </Col>
              </Row>
            </InitialCard>
          </Col>
        </Row>

        <Row className={clsx(cls.row, cls.marginTop)}>
          <Col xs={24} className={cls.row}>
            <InitialCard left="Other variables">
              <Row className={cls.row} gutter={{ lg: 24 }}>
                <Col xs={24} xl={12} className={cls.row}>
                  <DropdownTable
                    data={replyOther}
                    onChange={changeReplyOther}
                    isFixedReplies={isFixedReplies}
                  />
                </Col>
                <Col xs={24} xl={12} className={cls.row}>
                  {risk.showAddemVariables && (
                    <AddemVariablesTable
                      data={addemVariables}
                      onChange={changeAddemVariablesTable}
                    />
                  )}

                  <TotalVariablesTable
                    data={totalVariables}
                    calcData={riskCalculated.totalVariables}
                    onChange={changeTotalVariablesTable}
                  />

                  <div className={clsx(cls.colored, cls.bordered, cls.padding, cls.marginTop)}>
                    <ScoreWidget
                      value={score}
                      percents={score > risk.successPercent ? 90 : 10}
                      className={cls.scoreBottomWidget}
                      onClick={openReport}
                    />
                  </div>
                </Col>
              </Row>
            </InitialCard>
          </Col>
        </Row>
      </Container>
      <ExportRisk creditId={risk.creditId} isOpened={isReportOpened} setOpened={setReportOpened} />
    </>
  );
};
