import { Http } from './http';
import { SupportCreateTicket, SupportTicket } from '@types';

class TicketHttp extends Http {

  async createTicket(params: SupportCreateTicket): Promise<SupportTicket> {
    const res = await this.post<SupportTicket>('support-ticket', params);

    return res.data;
  }

  async getTicketList(): Promise<SupportTicket[]> {
    const res = await this.get<SupportTicket[]>('support-ticket');

    return res.data;
  }

  async sendEmail(id: number): Promise<string> {
    const res = await this.get<string>('/support-ticket/send-email/' + id);

    return res.data;
  }
}

export const ticketHttp = new TicketHttp();
