import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

interface Props {
  text: string;
  shrink?: boolean;
}

export const ErrorMessage = ({ text, shrink }: Props) => {
  const cls = useStyles();

  return (
    <div className={clsx(cls.error, shrink && cls.shrink)}>
      {text}
    </div>
  );
};

const useStyles = makeStyles({
  error: {
    width: '100%',
    color: 'red',
    fontSize: 13,
    lineHeight: 1,
    padding: '2px 4px 0',
  },
  shrink: {
    lineHeight: 0.8,
  },
});
