import { Http } from './http';
import { AdminComment, Admin, LoginTry } from '@types';

class LoginTryHttp extends Http {
  prefix = 'login-try';

  async findByUser(userId: number): Promise<LoginTry[]> {
    const res = await this.get<LoginTry[]>(`/user/${userId}`);

    return res.data;
  }
}

export const loginTryHttp = new LoginTryHttp();
