import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from '@mui/material';

import { surveyHttp } from '@network';
import { Credit, SurveyQuestion } from '@types';
import { SurveyTableAnswer } from './SurveyTableAnswer';
import { SurveyCustomAnswer } from './SurveyCustomAnswer';
import { SurveyMultipleAnswer } from './SurveyMultipleAnswer';
import { ExportIcon, InitialCard, InitialPage, Loader } from '@components';
import cls from './CreditSurveyView.module.css';

interface Props {
  credit: Credit;
  hide?: boolean;
  btnText?: string | JSX.Element;
  btnClass?: string;
  loading?: boolean;
  cb?: () => void;
}

const Header = ({ code, name }: { code: string, name: string }) => (
  <div className={cls.header}>
    <div className={cls.headerRow}>
      <div className={cls.headerCol}>
        <div className={cls.headerLogo}/>
        <div className={cls.headerTitle}>Aplicación de crédito</div>
      </div>
      <div className={cls.headerCol}>
        <div className={cls.headerLine}>
          <div className={cls.headerLineLabel}>Credit code:</div>
          <div className={cls.headerLineValue}>{code}</div>
        </div>
        <div className={cls.headerLine}>
          <div className={cls.headerLineLabel}>Accredited:</div>
          <div className={cls.headerLineValue}>{name}</div>
        </div>
      </div>
    </div>
  </div>
);

export const CreditSurveyView = (
  { credit, hide, btnText, btnClass, loading, cb }: Props,
) => {
  const [isExporting, setExporting] = useState(false);
  const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
  const pdfFilename = `credit_${credit?.id}_risk_survey.pdf`;

  const getSurvey = async () => {
    if (credit) {
      const { surveyQuestions } = await surveyHttp.getCreditSurvey(credit.id);

      setQuestions(surveyQuestions.map((q, index) => {
        q.position = index + 1;
        return q;
      }));
    }
  };

  const exportPdf = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setExporting(true);
    await new Promise((resolve) => setTimeout(resolve, 250));
    const div = document.getElementById('export-survey');

    try {
      div && html2canvas(div).then((canvas) => {
        const imgWidth = 212;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jsPDF('p', 'mm');
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }

        doc.save(pdfFilename);
      });
    } finally {
      cb && cb();
      setExporting(false);
    }
  };

  useEffect(() => {
    getSurvey();
  }, []);

  return (
    <>
      <InitialPage
        title="Risk Survey"
        backTo={`/credit-risk/${credit?.risk?.id || ''}`}
        backText="Back to Risk summary"
        className={clsx(cls.initialPage, hide && cls.hide)}
        headerRight={
          <Button
            variant="outlined"
            color="inherit"
            className={cls.exportBtn}
            onClick={isExporting ? undefined : exportPdf}
          >{isExporting ? <Loader mini /> : <ExportIcon />}Export</Button>
        }
      >
        <InitialCard fullHeight boxClass={cls.box} wrapperClass={cls.wrap} id="export-survey">
          <div className={cls.pdfWrap}>
            <Header code={credit.code} name={credit.accreditedName || '---'} />
            <div className={cls.body}>
              {questions?.length
                ? (<div className={cls.bodyRow}>
                  {questions.sort(q => q.position).map(q => (
                    <div className={clsx(
                      cls.bodyCol,
                      q.isTable && cls.table,
                      q.isMultiple && cls.multiple,
                      q.isNumber && cls.number,
                      q.isMoney && cls.noney,
                    )} key={q.position}>
                      <div className={cls.questionHeader}>
                        <div className={cls.questionHeaderCnt}>{q.position}</div>
                        <div className={cls.questionHeaderTitle}>{q.nameMx}</div>
                      </div>
                      {q.isTable ? <SurveyTableAnswer value={q}/> : null}
                      {q.isPlanner ? <SurveyTableAnswer value={q}/> : null}
                      {q.isMultiple ? <SurveyMultipleAnswer value={q} /> : null}
                      {!(q.isTable || q.isMultiple || q.isPlanner)
                        ? <SurveyCustomAnswer value={q} />
                        : null
                      }
                    </div>
                  ))}
                </div>) : <div className={cls.empty}>Questions list is empty</div>}
            </div>
          </div>
        </InitialCard>
      </InitialPage>
      <Button
        variant="outlined"
        color="inherit"
        className={clsx(cls.secondExportBtn, !hide && cls.hide, btnClass)}
        onClick={isExporting ? undefined : exportPdf}
      >
        {btnText ? btnText : (
          isExporting || loading ? <Loader mini className={cls.icon} /> : <>
            <ExportIcon />Export PDF
          </>
        )}
      </Button>
    </>
  );
};
