import { useEffect, useState } from 'react';
import { SelectArrayInputProps } from 'react-admin';

import { http } from '@network';
import { SelectArrayInput } from '../inputs';

interface SelectOption {
  id: number,
  name: string,
}

export const ManyFilter = (props: SelectArrayInputProps & {reference?: string}) => {
  const [options, setOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    (async () => {
      const url = props.reference || props.source;
      const response = await http.get<any>(url);
      if (!response.data || !Array.isArray(response.data)) {
        return;
      }
      const nextOptions: SelectOption[] = [];
      (response.data || []).forEach((model: any) => {
        nextOptions.push({
          id: model['id'],
          name: model['nameMx'] || model['name'] || model['title'],
        });
      });
      setOptions(nextOptions);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectArrayInput
      source={props.source}
      choices={options}
      {...props}
      style={{ width: 180 }}
      className="MS-field"
    />
  );
};
