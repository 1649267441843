import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Select, Tag } from 'antd';
import { useNotify } from 'react-admin';
import { useController } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { http } from '@network';
import { useIsMounted } from '@hooks';

const regex = /^[0-9]+$/;
const MAX_NAME_LENGTH = 60;

interface Props {
  source: string;
  reference?: string;
  optionKey?: string;
  optionLabel?: string;
  placeholder?: string;
  mainSource?: string;
}

export const ManyTagsInput = ({
  source, reference, optionKey, optionLabel, placeholder, mainSource,
}: Props) => {
  const isMounted = useIsMounted();
  const notify = useNotify();
  const cls = useStyles();

  const {
    field: { value, onChange },
  } = useController({ name: source });
  const {
    field: { value: mainSourceValue, onChange: mainSourceOnChange },
  } = useController({ name: mainSource || 'mainSource' });

  const [options, setOptions] = useState<CheckOption[]>([]);
  const [forceOpened, setForceOpened] = useState<boolean>();
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const response = await http.get<any>(reference || source, {
        params: { _start: 0, _end: 9999999 },
      });
      const nextOptions: CheckOption[] = [];
      if (response.data && Array.isArray(response.data)) {
        (response.data ?? []).forEach((model: any) => {
          nextOptions.push({
            value: `${model[optionKey ?? 'id']}`,
            label: model[optionLabel ?? 'nameMx'] || model['name'] || model['title'],
          });
        });
      }
      isMounted.current && setOptions(nextOptions);
    })();
  }, [value]);

  useEffect(() => {
    setSelectedOptions((value || []).map((v: number) => `${v}`));
  }, [options]);

  const handleChange = (ids: string[]) => {
    if (Array.isArray(ids)) {
      const numberIds = ids.filter(id => id);
      onChange(numberIds);
    }

    setSearchInput('');
  };

  const handleSearch = (value: string) => {
    setSearchInput(value);
    if (value.length > MAX_NAME_LENGTH) {
      notify(`${MAX_NAME_LENGTH} characters length is maximum.`, { type: 'error' });
    }
  };

  const filterOption = (input: string, option?: CheckOption) => {
    return option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false;
  };

  const showAddButton =
    options.find(option => !option.label.toLowerCase().startsWith(searchInput.toLowerCase()))
    && !!searchInput.length
    && searchInput.length <= MAX_NAME_LENGTH;

  const handleSelect = (value: string) => {
    const isNewOption = options.findIndex(option => option.value === value) < 0;

    if (regex.test(value) && isNewOption) {
      notify('Only numbers are not allowed. Please use letters or a combination of letters and numbers.', {
        type: 'error',
      });
      setSelectedOptions(prevTags => prevTags.filter(option => {
        return option !== value;
      }));
    }
  };

  return (
    <div className={cls.box}>
      <Select
        mode="tags"
        className={cls.select}
        placeholder={placeholder}
        value={selectedOptions}
        onChange={handleChange}
        onSearch={handleSearch}
        onSelect={handleSelect}
        options={options}
        filterOption={filterOption}
        open={forceOpened}
        style={{ maxWidth: '100%' }}
        onInputKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          const value = e.currentTarget.value;
          if (value.length >= MAX_NAME_LENGTH && e.key !== 'Backspace') {
            e.preventDefault();

            notify(`Company name with more than ${MAX_NAME_LENGTH} characters is not allowed.`, {
              type: 'error',
            });
          }
        }}
        tagRender={(props) => {
          const mainSourceSelected = `${props.value}` === `${mainSourceValue}`;
          const isNewOption = options.findIndex(option => option.value === props.value) < 0;

          return (
            <Tag
              defaultValue={props.value}
              closable={props.closable}
              onClose={props.onClose}
              className={cls.tag}
              style={{ borderColor: ((regex.test(props.value) || props.value.length > MAX_NAME_LENGTH) && isNewOption) ? 'red' : '#d9d9d9' }}
            >
              {mainSource && (
                <Checkbox
                  checked={mainSourceSelected}
                  onChange={(e) => {
                    e.target.checked && mainSourceOnChange(`${props.value}`);
                    setForceOpened(true);
                    [200, 300, 400].map(t => setTimeout(() => {
                      isMounted.current && setForceOpened(undefined);
                    }, t));
                  }}
                  className={cls.checkbox}
                />
              )}
              <span className={cls.content}>{props.label}</span>
            </Tag>
          );
        }}
        dropdownRender={(menu: any) => (
          <>
            {menu}
            {showAddButton
              ? (
                <Button type="text" className={cls.btn}>
                  +Add
                </Button>
              )
              : null
            }
          </>
        )}
      />
      <ArrowDropDownIcon className={cls.suffixIcon} />
    </div>
  );
};

interface CheckOption {
  value: string,
  label: string,
}

const useStyles = makeStyles({
  box: {
    position: 'relative',
  },
  select: {
    width: '100%',
    maxWidth: '600px !important',
    '& .ant-select-selector': {
      border: 'none !important',
      borderBottom: '1px solid rgba(0, 42, 119, 0.10) !important',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
  },
  suffixIcon: {
    display: 'inline-block',
    position: 'absolute',
    color: '#777',
    top: 2,
    right: -6,
  },
  tag: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 5,
    marginBottom: 3,
    maxWidth: '100%',
  },
  checkbox: {
    marginRight: 5,
  },
  content: {
    display: 'inline-block',
    maxWidth: '100%',
    webkitLineClamp: 1,
    webkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  btn: {
    position: 'absolute',
    top: 3,
    right: 0,
    color: '#888',
  },
  error: {
    color: 'red',
  },
});
