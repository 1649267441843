import { useState } from 'react';
import { Create, CreateProps, maxLength, required, SimpleForm } from 'react-admin';
import { Typography } from '@mui/material';

import { useCreateProps, useRbacCreate } from '@hooks';
import { Col, Container, Forbidden, Row, TextInput } from '@components';

import cls from './seo.module.css';

const initialValues = {
  page: '',
  titleEn: '',
  titleEs: '',
  descriptionEn: '',
  descriptionEs: '',
  keywordsEn: '',
  keywordsEs: '',
};

export const SeoCreate = (props: CreateProps) => {
  const [form, setForm] = useState(initialValues);
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();

  if (!rbacCreate) {
    return <Forbidden />;
  }

  const onChange = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div style={{ marginBottom: 40 }}>
      <Create {...props} {...createProps}>
        <SimpleForm className={cls.form}>
          <Container max>
            <Typography variant="h5" component="h2" style={{ marginBottom: 20 }}>New page SEO
              data</Typography>
            <Row>
              <Col>
                <TextInput
                  name="page"
                  source="page"
                  label="Page identifier"
                  validate={[required(), maxLength(50, 'Maximum length is 50 symbols.')]}
                  onChange={onChange}
                />
              </Col>
              <Col />
            </Row>
            <Row marginTop={20}>
              <Col>
                <TextInput
                  name="titleEn"
                  source="titleEn"
                  validate={[required(), maxLength(100, 'Maximum length of the title is 100 symbols.')]}
                  label="Page title EN"
                  helperText={`Page title template in English / ${form.titleEn.length}`}
                  onChange={onChange}
                />
              </Col>
              <Col>
                <TextInput
                  name="titleEs"
                  source="titleEs"
                  validate={[required(), maxLength(100, 'Maximum length of the title is 100 symbols.')]}
                  label="Page title ES"
                  helperText={`Page title template in Spanish / ${form.titleEs.length}`}
                  onChange={onChange}
                />
              </Col>
            </Row>
            <Row marginTop={20}>
              <Col>
                <TextInput
                  name="descriptionEn"
                  source="descriptionEn"
                  multiline
                  label="Description EN"
                  validate={[required(), maxLength(200, 'Maximum length of the description is 200 symbols.')]}
                  maxRows={3}
                  rows={3}
                  helperText={`Page description template in English / ${form.descriptionEn.length}`}
                  onChange={onChange}
                />
              </Col>
              <Col>
                <TextInput
                  name="descriptionEs"
                  source="descriptionEs"
                  multiline
                  label="Description ES"
                  validate={[required(), maxLength(200, 'Maximum length of the description is 200 symbols.')]}
                  maxRows={3}
                  rows={3}
                  helperText={`Page description template in Spanish / ${form.descriptionEs.length}`}
                  onChange={onChange}
                />
              </Col>
            </Row>
            <Row marginTop={20}>
              <Col>
                <TextInput
                  name="keywordsEn"
                  source="keywordsEn"
                  label="Keywords EN"
                  validate={[maxLength(250, 'Maximum length of the keywords phrases list is 250 symbols.')]}
                  helperText={`Keywords list divided by the comma in English / ${form.keywordsEn.length}`}
                  onChange={onChange}
                />
              </Col>
              <Col>
                <TextInput
                  name="keywordsEs"
                  source="keywordsEs"
                  label="Keywords ES"
                  validate={[maxLength(250, 'Maximum length of the keywords phrases list is 250 symbols.')]}
                  helperText={`Keywords list divided by the comma in Spanish / ${form.keywordsEs.length}`}
                  onChange={onChange}
                />
              </Col>
            </Row>
            <Row marginTop={20}>
              <Col>
                <div className={cls.result}>
                  <h3>Google search preview in English</h3>
                  <div className={cls.title}>{form.titleEn || 'Page title'}</div>
                  <div className={cls.description}>{form.descriptionEn || 'Page description'}</div>
                </div>
              </Col>
              <Col>
                <div className={cls.result}>
                  <h3>Google search preview in Spanish</h3>
                  <div className={cls.title}>{form.titleEs || 'Page title'}</div>
                  <div className={cls.description}>{form.descriptionEs || 'Page description'}</div>
                </div>
              </Col>
            </Row>
          </Container>
        </SimpleForm>
      </Create>
    </div>
  );
};
