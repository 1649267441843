import { Credit } from '@types';
import cls from './credit-invoice-user.module.css';

interface CreditInvoiceUserProps {
    credit: Credit;
}

export const CreditInvoiceUser = ({ credit }: CreditInvoiceUserProps) => {
  return (
    <>
      <div className={cls.image}>
        {credit.user?.avatar ? (
          <img src={credit.user?.avatar} alt="" />
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="102" height="102" viewBox="0 0 102 102" fill="none">
            <path d="M51 0C22.8407 0 0 22.8407 0 51C0 79.1593 22.8407 102 51 102C79.1593 102 102 79.1593 102 51C102 22.8407 79.1593 0 51 0ZM51 25.5C62.475 25.5 71.8007 34.8257 71.8007 46.3007C71.8007 57.7757 62.475 67.1014 51 67.1014C39.525 67.1014 30.1993 57.7757 30.1993 46.3007C30.1993 34.8257 39.525 25.5 51 25.5ZM51 95.4429C40.6543 95.4429 31.11 91.8729 23.5329 85.8986C26.2286 82.0371 30.6729 79.7057 35.4086 79.7057H66.5914C71.3271 79.7057 75.7714 82.0371 78.4671 85.8986C70.89 91.8729 61.3457 95.4429 51 95.4429Z" fill="#042E6B"/>
          </svg>
        )}
      </div>
      <div>
        <div className={cls.name}>{credit.user?.fullName || ''}</div>
        <div className={cls.state}>{credit.user?.state?.name || ''}</div>

        <div className={cls.credit}>
          <div className={cls.creditLabel}>Credit</div>
          <div className={cls.creditCode}>{credit.code}</div>
        </div>
      </div>
    </>
  );
};
