import { Box, InputLabel, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props {
  title?: string;
  label?: string;
  center?: boolean;
  flex?: number;
  inline?: boolean;
  className?: string;
}

export const Col = ({
  children, title, label, center, flex = 1, className,
}: PropsWithChildren<Props>) => {
  return (
    <Box flex={flex} mr={1} className={className}>
      {title && <Box mb={1}><Typography variant="h6">{title}</Typography></Box>}
      {label && <Box mb={0.5}><InputLabel shrink>{label}</InputLabel></Box>}
      {center ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {children}
        </div>
      ) : <>{children}</>}
    </Box>
  );
};
