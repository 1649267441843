import React from 'react';
import { ListProps, TextField } from 'react-admin';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  MoneyField,
  DateField,
  NumberField,
  SelectArrayInput,
  DateRangeInput,
} from '@components';
import { useExporter, useRbacList } from '@hooks';
import { FinanceActivationStatusField } from './FinanceActivationStatusField';
import { useFinanceActivationSettings } from './use-finance-activation-settings';

export const FinanceActivationList = (props: ListProps) => {
  const rbacList = useRbacList();
  const settings = useFinanceActivationSettings();
  const exporter = useExporter({
    filename: 'Activations',
    props: {
      'id': {
        label: 'ID',
      },
      'activation': {
        label: 'Activation',
      },
      'statusName': {
        label: 'Status',
      },
      'budget': {
        label: 'Budget',
        transform: 'money',
      },
      'launchDate': {
        label: 'Launch Date',
        transform: 'date',
      },
      'closeDate': {
        label: 'Close Date',
        transform: 'date',
      },
      'mql': {
        label: 'Number of MQL\'s',
      },
      'originName': {
        label: 'Origin',
      },
      'cpl': {
        label: 'CPL',
        transform: 'money',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props} exporter={exporter} filters={[
      <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
      <TextInput source="activationName" key="activationName" variant="outlined"
        label="Activation" />,
      <SelectArrayInput
        label="Status"
        key="status"
        source="status"
        choices={settings?.activationStatuses || []}
        alwaysOn
        className="MS-field"
        style={{ width: 280 }}
      />,
      <SelectArrayInput
        label="Origin"
        key="origin"
        source="origin"
        choices={settings?.originOptions || []}
        alwaysOn
        className="MS-field"
        style={{ width: 280 }}
      />,
      <DateRangeInput
        key="launchDate"
        source="launchDate"
        label="Launch Date"
        defaultValue={false}
      />,
      <TextInput source="description" key="description" variant="outlined"
        label="Description" />,
    ]}>
      <Grid
        rowClick="edit"
        defaultColumns={'activation statusName budget launchDate closeDate mql originName cpl'.split(' ')}
      >
        <TextField source="activation" name="Activation" autoWidth />
        <FinanceActivationStatusField source="statusName" label="Status" autoWidth />
        <MoneyField source="budget" label="Budget" />
        <DateField source="launchDate" label="Launch Date" />
        <DateField source="closeDate" label="Close Date" />
        <NumberField source="mql" label="Number of MQL's" />
        <TextField source="originName" label="Origin" />
        <MoneyField source="cpl" label="CPL" />
        <TextField source="description" name="Description" />
        <TextField source="campaignName" label="Campaign" />
      </Grid>
    </GridList>
  );
};
