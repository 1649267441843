import { Select, SelectProps } from 'antd';
import { makeStyles } from '@mui/styles';

export const SelectControlledInput = (props: SelectProps) => {
  const cls = useStyles();

  return (
    <Select
      {...props}
      className={cls.select}
    />
  );
};

const useStyles = makeStyles({
  select: {
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#FFF',
    minWidth: 200,
    height: 36,
    '& > div': {
      borderRadius: '8px !important',
      height: '34px !important',
      border: 'none !important',
      paddingTop: '2px !important',
      paddingLeft: '16px !important',
    },
    '& svg': {
      color: '#042E6B',
    },
  },
});
