import { CreateProps } from 'react-admin';
import { useCreated } from './use-created';

interface UseCreatePropsParams {
  label?: string;
}

export const useCreateProps = (params: UseCreatePropsParams = {}) => {
  const created = useCreated({ label: params.label });

  const editProps: Partial<CreateProps> = {
    mutationOptions: { onSuccess: created },
    transform: transform,
  };

  return editProps;
};

const transform = (obj: any) => Object.keys(obj).reduce((acc, key) => {
  acc[key] = obj[key] === '' ? null : obj[key];

  return acc;
}, {} as any);
