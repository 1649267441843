import React from 'react';

import { Credit } from '@types';
import { InitialCard, Scroll } from '@components';
import { DealerContracts } from './dealer-contracts';
import cls from './credit-contracts.module.css';

export const CreditContracts = ({ credit }: {credit: Credit}) => {
  const dealers = credit.user.dealers.sort((a, b) => a.id - b.id);
  const contracts = credit.user.contracts || [];

  return (
    <InitialCard left="Contracts">
      <Scroll height={260}>
        <div className={cls.box}>
          {dealers.map(dealer => (
            <div key={dealer.id} className={cls.dealer}>
              <div className={cls.dealerName}>{dealer.name}</div>
              <div className={cls.dealerLabel}>Contract documents</div>
              <DealerContracts
                className={cls.dealerContracts}
                dealerContracts={contracts}
                dealerId={dealer.id}
                userId={credit.userId}
                editable={true}
              />
            </div>
          ))}
        </div>
      </Scroll>
    </InitialCard>
  );
};
