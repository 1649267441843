import { useEffect } from 'react';
import { Button, Input } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { useController, useWatch } from 'react-hook-form';

import { FinanceCostSubcategory } from '@types';
import { InitialRow } from '@components';
import { clsx } from 'clsx';
import { MAX_COST_SUBCATEGORIES } from '@pages/finance-cost-category/FinanceCostCategoryForm';
import { FinanceRemoveInactive } from '@pages/finance-settings/components/FinanceRemoveInactive';

export const SubcategoriesForm = ({ readonly }: {readonly?: boolean}) => {
  const cls = useStyles();
  const {
    formState: { errors },
    field: { value: valueSubcategories, onChange: setSubcategories },
  } = useController({ name: 'subcategories' });
  const code = useWatch({ name: 'code' }) as string | undefined;

  const subcategories = (valueSubcategories || []) as FinanceCostSubcategory[];
  const { message: subcategoriesError } = (errors?.subcategories?.message as any) || {};
  const newCode = `${code || ''}${`${subcategories.length + 1}`.padStart(2, '0')}`;

  useEffect(() => {
    const nextCategories = subcategories.map((sub, i) => ({
      ...sub,
      code: `${code || ''}${`${i + 1}`.padStart(2, '0')}`,
    }));
    setSubcategories(nextCategories);
  }, [code]);

  const disableAdd = subcategories.length >= MAX_COST_SUBCATEGORIES;

  const addSubcategory = () => {
    if (disableAdd) return;
    const currPos = subcategories[subcategories.length - 1]?.position || 0;
    const newSub: FinanceCostSubcategory = { name: '', code: newCode, position: currPos + 1 };
    setSubcategories([...subcategories, newSub]);
  };

  const onRemoveSubcategory = (position: number) => {
    setSubcategories(subcategories.filter(sub => sub.position !== position));
  };

  const onChangeName = (name: string, position: number) => {
    const nextSubcategories = subcategories.map((sub, i) => ({
      ...sub,
      name: sub.position === position ? name : sub.name,
    }));
    setSubcategories(nextSubcategories);
  };

  return (
    <>
      <InitialRow title="Description options:" fontLg labelGrid={{ sm: 9 }}>
        <span>{subcategories.length} {subcategories.length === 1 ? 'Element' : 'Elements'}</span>
      </InitialRow>
      <div className={cls.box}>
        {subcategories.map(sub => (
          <div className={cls.line} key={sub.position}>
            <div className={cls.lineLeft}>
              <Input
                error={!sub.name}
                defaultValue={sub.name}
                inputProps={{ maxLength: 32 }}
                fullWidth
                onBeforeInputCapture={readonly ? e => e.preventDefault() : undefined}
                onChange={e => onChangeName(e.target.value, sub.position)}
              />
            </div>
            <div className={cls.lineRight}>
              <span>{sub.code}</span>
              <FinanceRemoveInactive disabled={(sub?.linked || 0) > 0} hidden={readonly}>
                <IconButton onClick={() => onRemoveSubcategory(sub.position)}>
                  <svg width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#D32F2F" />
                    <path d="M9.38832 8.04606L10.7027 9.36236C11.0734 9.73362 11.1071 10.3749 10.7196 10.7462C10.5342 10.9149 10.2983 10.9993 10.0624 10.9993C9.82645 10.9993 9.57368 10.9149 9.38832 10.7293L8.04022 9.37924C7.7369 9.07547 7.26506 9.07547 6.96174 9.37924L5.61364 10.7293C5.42828 10.9149 5.19236 10.9993 4.93959 10.9993C4.70368 10.9993 4.46776 10.9149 4.2824 10.7462C3.89482 10.3749 3.91167 9.73362 4.29925 9.36236L5.61364 8.04606C5.91696 7.7423 5.91696 7.26979 5.61364 6.96602L4.33295 5.64973C3.96222 5.27846 3.92852 4.68782 4.2824 4.29968C4.65312 3.91154 5.25977 3.89467 5.63049 4.2828L6.97859 5.63285C7.28191 5.93661 7.75375 5.93661 8.05707 5.63285L9.40517 4.2828C9.7759 3.91154 10.3994 3.91154 10.7533 4.29968C11.1071 4.67094 11.0734 5.27846 10.7027 5.64973L9.38832 6.96602C9.10185 7.26979 9.10185 7.7423 9.38832 8.04606Z"
                      fill="white" />
                  </svg>
                </IconButton>
              </FinanceRemoveInactive>
            </div>
          </div>
        ))}
        <div className={clsx(cls.line, subcategories.length > 0 && cls.lineAdd)}>
          {!readonly && (
            <Button className={cls.addBtn}
              variant="contained"
              color="secondary"
              onClick={addSubcategory}
              disabled={disableAdd}
            >
              <div>Add Option</div>
              <div className={cls.addRight}>
                <div className={cls.addCode}>{newCode}</div>
                <svg xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none">
                  <circle cx="10.6066"
                    cy="10.9995"
                    r="7.5"
                    transform="rotate(-45 10.6066 10.9995)"
                    fill="#042E6B" />
                  <path d="M12.3313 10.0504L14.1915 10.0517C14.7161 10.0521 15.1934 10.4817 15.1819 11.0183C15.1701 11.2687 15.063 11.4952 14.8961 11.662C14.7293 11.8288 14.4909 11.9479 14.2286 11.9477L12.3207 11.9463C11.8914 11.946 11.5578 12.2797 11.5581 12.7089L11.5595 14.6168C11.5597 14.8792 11.4525 15.1056 11.2738 15.2844C11.107 15.4512 10.8805 15.5583 10.6301 15.5701C10.0935 15.5816 9.65198 15.1163 9.66352 14.5797L9.66217 12.7195C9.66186 12.2902 9.32774 11.9561 8.89847 11.9558L7.06212 11.9306C6.53745 11.9302 6.09597 11.5364 6.07175 11.0117C6.05943 10.4751 6.47646 10.0342 7.01306 10.0466L8.92094 10.0479C9.35021 10.0482 9.68385 9.7146 9.68354 9.28533L9.68216 7.37745C9.68178 6.85279 10.1227 6.41191 10.6473 6.43614C11.1601 6.44843 11.5658 6.90185 11.5662 7.42651L11.5676 9.28669C11.5798 9.70405 11.9139 10.0382 12.3313 10.0504Z"
                    fill="white" />
                </svg>
              </div>
            </Button>
          )}
        </div>
      </div>
      {subcategoriesError && <div className={cls.error}>{subcategoriesError}</div>}
    </>
  );
};

const useStyles = makeStyles({
  box: {
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#FFF',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  lineAdd: {
    marginTop: 5,
    borderTop: '1px solid #042E6B',
  },
  lineLeft: {
    flex: 1,
    padding: '0 30px 0',
  },
  lineRight: {
    padding: '5px 10px 0 15px',
    borderLeft: '1px solid #042E6B',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 130,
  },
  addBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 34,
    paddingRight: 8,
    borderRadius: 8,
    background: '#F0F3FB',
    textTransform: 'none',
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    width: '100%',
    margin: '4px 6px 6px',
    '&:hover': {
      color: 'white',
    },
  },
  addRight: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addCode: {
    marginRight: 50,
  },
  error: {
    padding: '4px 8px',
    fontSize: 16,
    color: 'red',
  },
});
