import { TextField, ListProps, ReferenceInput, useResourceContext } from 'react-admin';

import {
  PhoneField,
  TextInput,
  Grid,
  GridList,
  BooleanField,
  SelectInput,
  EmailField,
  Forbidden,
  DateField, DateRangeInput,
} from '@components';
import { useRbacList } from '@hooks';
import { checkSuperadmin } from '@providers';
import { ManagerLoginBtn } from '../ManagerLoginBtn';
import { useDealerReference } from '@pages/manager/use-dealer-reference';
import cls from './ManagerList.module.css';
import { genderOptions } from '@types';
import React from 'react';

export const ManagerList = (props: ListProps) => {
  const isSuperadmin = checkSuperadmin();
  const rbacList = useRbacList();
  const dealerReference = useDealerReference();
  const resource = useResourceContext();

  if (!rbacList) {
    return <Forbidden />;
  }

  const buyerName = resource === 'manager' ? 'Buyer'
    : resource === 'manager-retailer' ? 'Retailer' : 'Company name';

  const filters = [
    <TextInput source="q" key="q" variant="outlined" label="Search" style={{ width: 300 }} inputProps={{ maxLength: 40 }} alwaysOn />,
    <ReferenceInput
      source="buyerId"
      name="buyerId"
      key="buyerId"
      reference={dealerReference}
      label={buyerName}
      allowEmpty
      alwaysOn
      perPage={1000}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText="name" label={buyerName} />
    </ReferenceInput>,
    <SelectInput alwaysOn key="gender" source="gender" choices={genderOptions} />,
    <DateRangeInput
      key="createdAt"
      source="createdAt"
      label="Registration Date"
      defaultValue={false}
      alwaysOn
    />,
  ];

  return (
    <GridList {...props}
      filters={filters}
      sort={{ field: 'id', order: 'DESC' }}
      className={cls.list}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'dealer.name associate.name name email phone position isEnabled isSuperadmin createdAt'.split(' ')}
        className={cls.grid}
      >
        <TextField source="dealer.name" label={buyerName} />
        <TextField source="name" label="User name" />
        <EmailField source="email" wrap />
        <PhoneField source="phone" />
        <TextField source="gender" />
        <TextField source="position" />
        <TextField source="associate.name" label="Associate name" />
        <BooleanField source="isEnabled" label="Enabled" />
        <BooleanField source="isSuperadmin" label="Supervisor" />
        {isSuperadmin && <ManagerLoginBtn source="login" isSmall color="#74706B" />}
        <DateField source="createdAt" label="Registered at" />
      </Grid>
    </GridList>
  );
};
