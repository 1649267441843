import cls from './SurveyTableAnswer.module.css';
import { SurveyQuestion } from '@types';
import { moneyFormatter } from '@utils';

export const SurveyTableAnswer = ({ value }: { value: SurveyQuestion }) => {
  return (
    <div className={cls.wrap}>
      {value.rows.length ? value.rows.map((table, idx) => (
        <div className={cls.table} key={idx}>
          {table.cols.length ? table.cols.map((row, rid) => (
            <div className={cls.row} key={rid}>
              <div className={cls.col} key={rid}>{row.nameMx}</div>
              <div className={cls.col}>{row.answerType === 'money'
                ? moneyFormatter.format(row.customAnswer ? +row.customAnswer : 0)
                : row.customAnswer}</div>
            </div>
          )) : <div className={cls.empty}>No answers</div>}
        </div>
      )) : <div className={cls.empty}>No table</div>}
    </div>
  );
};
