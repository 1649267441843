import { useResourceContext } from 'react-admin';
import { rbac } from '@utils/rbac';

export const useRbacCreate = (): boolean => {
  const resource = useResourceContext();
  const localAdmin: string | null = localStorage.getItem('admin');
  const admin = localAdmin ? JSON.parse(localAdmin) : null;

  return rbac.canCreate(admin, resource);
};

export const useCreateAccess = (resource: string): boolean => {
  const localAdmin: string | null = localStorage.getItem('admin');
  const admin = localAdmin ? JSON.parse(localAdmin) : null;

  return rbac.canCreate(admin, resource);
};
