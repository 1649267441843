import React, { MutableRefObject, ReactNode, useState } from 'react';
import { makeStyles } from '@mui/styles';

import { CreditRisk } from '@types';
import { BlueButton, InitialCard } from '@components';
import { ExportRisk, ExportRiskLegacy } from './export';

interface Props {
  risk: CreditRisk;
  isPrepared?: boolean;
  reportNodeRef: MutableRefObject<ReactNode>;
}

export const RiskCreditReport = ({ risk, isPrepared, reportNodeRef }: Props) => {
  const cls = useStyles();
  const [isReportOpened, setReportOpened] = useState(false);
  const openReport = () => setReportOpened(true);

  return (
    <>
      <InitialCard autoHeight extraPadding>
        <div className={cls.box}>
          <div className={cls.title}>Credit Report</div>
          <BlueButton extraPadding className={cls.btn} onClick={openReport}>Open Report</BlueButton>
        </div>
      </InitialCard>
      {risk.isLegacy ? (
        <ExportRiskLegacy
          creditId={risk.creditId}
          isOpened={isReportOpened}
          setOpened={setReportOpened}
          isPrepared={isPrepared}
          reportNodeRef={reportNodeRef}
        />
      ) : (
        <ExportRisk
          creditId={risk.creditId}
          isOpened={isReportOpened}
          setOpened={setReportOpened}
          isPrepared={isPrepared}
          reportNodeRef={reportNodeRef}
        />
      )}
    </>
  );
};

const useStyles = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: '#000',
    fontWeight: 600,
    fontSize: 20,
  },
  btn: {
    marginTop: 5,
  },
});
