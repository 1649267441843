import { clsx } from 'clsx';
import { Col, Row, Form } from 'antd';
import { CreateProps, Create, useNotify, useRedirect } from 'react-admin';
import { CreateFinanceAccountParams, FinanceAccountUserOption } from '@types';

import { axiosErrorToString } from '@utils';
import { financeAccountHttp } from '@network';
import React, { useEffect, useState } from 'react';
import { useCreateProps, useRbacCreate } from '@hooks';
import { Container, Forbidden, PageHeader } from '@components';
import { FinanceAccountForm } from '@pages/finance-account/FinanceAccountForm/FinanceAccountForm';
import cls from './finance-account-create.module.css';

export const FinanceAccountCreate = (props: CreateProps) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const rbacCreate = useRbacCreate();
  const createProps = useCreateProps();
  const [form] = Form.useForm<CreateFinanceAccountParams>();
  const [loading, setLoading] = useState(false);
  const [formValues] = useState<CreateFinanceAccountParams>({
    userId: null,
    userCode: '',
    code: '',
    addressee: '',
    bank: '',
    type: '',
    account: '',
    branch: '',
    reference: null,
    phone: '',
    email: '',
    accountSetup: false,
    balance: 0,
  });
  const [selectedUser, setSelectedUser] = useState<FinanceAccountUserOption | null>(null);

  const submit = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const params = form.getFieldsValue();
      params.userId = selectedUser?.id;
      params.accountSetup = false;
      await financeAccountHttp.create(params);
      form.resetFields();
      notify('Account has been created');
      redirect('/finance-account');
    } catch (e) {
      // @ts-ignore
      if (e.errorFields?.length) {
        notify('Form is wrong', { type: 'error' });
      } else {
        notify(axiosErrorToString(e), { type: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldValue('email', selectedUser ? selectedUser.email : '');
    form.setFieldValue('phone', selectedUser ? selectedUser.phone : '');
    form.setFieldValue('addressee', selectedUser ? selectedUser.fullName : '');
    form.setFieldValue('reference', selectedUser ? selectedUser.code : '');
  }, [selectedUser]);

  if (!rbacCreate) {
    return <Forbidden/>;
  }

  return (
    <Create {...props} {...createProps} className={clsx(cls.page, 'details-page-wrapper')}>
      <PageHeader
        title="Account"
        linkText="Back to all information of banking manager"
        linkUrl="/finance-account"
      />

      <Container max>
        <Row gutter={24} className={cls.wrapper}>
          <Col xs={24} className={cls.wrapperCol}>
            <div className={cls.block}>
              <div className={cls.blockHeader}>Complete information</div>
              <Form
                form={form}
                layout="horizontal"
                onFinish={submit}
                initialValues={formValues}
              >
                <FinanceAccountForm
                  user={selectedUser}
                  setUser={setSelectedUser}
                  loading={loading}
                  setLoading={setLoading}
                />
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Create>
  );
};
