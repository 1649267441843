import { Button, Modal, Select, SelectProps } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { clsx } from 'clsx';

import { dealerHttp, leadHttp, userHttp } from '@network';
import { useDebounce, useRequest } from '@hooks';
import { ActivationTableType } from '@pages/finance-activation/FinanceActivationTables';
import cls from './FinanceActivationAddForm.module.css';

interface Props {
    type: ActivationTableType;
    submit: (ids: number[]) => void;
    cancel: () => void;
}

export const FinanceActivationAddForm = ({ type, submit, cancel }: Props) => {
  const [data, setData] = useState<SelectProps['options']>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<number[]>([]);


  const handleSearch = async (newValue: string) => {
    setSearchValue(newValue);
  };

  const debouncedValue = useDebounce(searchValue, 1000);

  const { loading: isSearchUsersLoading, submit: searchUsers } = useRequest(async () => {
    return userHttp.getAll(searchValue, 100);
  });

  const { loading: isSearchLeadsLoading, submit: searchLeads } = useRequest(async () => {
    return leadHttp.getAll(searchValue, 100);
  });

  const { loading: isSearchAssociatesLoading, submit: searchAssociates } = useRequest(async () => {
    return dealerHttp.getAssociateList(searchValue);
  });

  const { loading: isSearchRetailersLoading, submit: searchRetailers } = useRequest(async () => {
    return dealerHttp.getRetailerList(searchValue);
  });

  const { loading: isSearchBuyersLoading, submit: searchBuyers } = useRequest(async () => {
    return dealerHttp.getBuyerList(searchValue);
  });

  const selectOptions = (ids: number[]) => {
    setSelectedIds(ids);
  };

  const getData = useCallback(async () => {
    let loadedData: SelectProps['options'];

    if (type === 'users') {
      const result = await searchUsers();
      if (result) {
        loadedData = result.map(d => ({
          value: d.id,
          label: d.fullName || d.email,
        }));
      }
    }

    if (type === 'leads') {
      const result = await searchLeads();
      if (result) {
        loadedData = result.map(d => ({
          value: d.id,
          label: d.name,
        }));
      }
    }

    if (type === 'associates') {
      const result = await searchAssociates();
      if (result) {
        loadedData = result.map(d => ({
          value: d.id,
          label: d.name,
        }));
      }
    }

    if (type === 'retailers') {
      const result = await searchRetailers();
      if (result) {
        loadedData = result.map(d => ({
          value: d.id,
          label: d.name,
        }));
      }
    }

    if (type === 'buyers') {
      const result = await searchBuyers();
      if (result) {
        loadedData = result.map(d => ({
          value: d.id,
          label: d.name,
        }));
      }
    }

    setData(loadedData);
  }, [searchValue, type]);

  const onSubmit = async () => {
    submit(selectedIds);
  };

  useEffect(() => {
    if (searchValue === '') {
      setData([]);

      return;
    }

    void getData();
  }, [debouncedValue, getData]);

  const isLoading =
    isSearchUsersLoading
    || isSearchLeadsLoading
    || isSearchAssociatesLoading
    || isSearchRetailersLoading
    || isSearchBuyersLoading;

  return (
    <div className={cls._}>
      <Modal title={`Add ${type}`} open onOk={onSubmit} onCancel={cancel} footer={null}>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder={`Select ${type}`}
          onSearch={handleSearch}
          notFoundContent={null}
          loading={isLoading}
          filterOption={false}
          onChange={selectOptions}
        >
          {(data || []).map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>

        <div className={cls.actions}>
          <Button className={clsx(cls.action, cls.actionSubmit)} onClick={onSubmit}>Add</Button>
          <Button className={clsx(cls.action, cls.actionCancel)} onClick={cancel}>Cancel</Button>
        </div>
      </Modal>
    </div>
  );
};
