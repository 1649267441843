import {
  Col,
  Form,
  FormInstance,
  InputNumber,
  Select,
  Row,
  DatePicker,
  UploadFile,
} from 'antd';
import { clsx } from 'clsx';
import moment from 'moment-timezone';
import { Button } from '@mui/material';
import { useNotify } from 'react-admin';
import SaveIcon from '@mui/icons-material/Save';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import {
  CreateFinanceCostParams,
  FinanceCost,
  FinanceCostCategoryOption,
  FinanceCostOptions,
  FinanceCostSubCategoryOption,
  FinanceDocument,
} from '@types';
import { s3http } from '@network';
import { showAxiosError } from '@utils';
import { ConfirmDelete } from '@components';
import { financeCostHttp } from '@network/finance-cost-http';
import { FinanceDocuments } from '@pages/finance-withdrawal/FinanceDocuments';
import cls from './FinanceCostForm.module.css';
import { CheckCircleFilled, MinusCircleFilled } from '@ant-design/icons';

const { Option } = Select;

interface Props {
  financeCost?: FinanceCost;
  initial: CreateFinanceCostParams;
  form: FormInstance<CreateFinanceCostParams>;
  onSubmit: (params: CreateFinanceCostParams) => void;
  options: FinanceCostOptions;
  onDelete?: () => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

interface OptionProps {
  value: number;
  label: string;
  key: number;
}

const CodeField = ({ code }: {code?: string}) => {
  const codeArr = code ? code.split('-') : [];
  return (
    <div className={clsx(cls.code, !code && cls.codeEmpty)}>
      <div className={clsx(cls.codeContent, cls.codeContentLeft)}>{codeArr.length ? codeArr[0] : ''}</div>
      <div className={clsx(cls.codeContent, cls.codeContentMiddle)}>{codeArr.length ? codeArr[1] : ''}</div>
      <div className={clsx(cls.codeContent, cls.codeContentRight)}>{codeArr.length ? codeArr[2] : ''}</div>
    </div>
  );
};

const Activation = ({ activationId }: {activationId: number | null}) => {
  return (
    <div className={cls.activation}>
      <div className={cls.activationText}>Activation</div>
      <div className={cls.activationWrapper}>
        <div className={cls.activationIcon}>
          {!activationId
            ? <MinusCircleFilled className={cls.iconEmpty} />
            : <CheckCircleFilled className={cls.iconActive} />}
        </div>
      </div>
    </div>
  );
};

export const FinanceCostForm = (
  { initial, form, options, onSubmit, loading, onDelete, setLoading, financeCost }: Props,
) => {
  const notify = useNotify();
  const labelCol = { xs: 24, sm: 24, md: 8, lg: 6, xl: 8, xxl: 6 };
  const [category, setCategory] = useState<FinanceCostCategoryOption | undefined>(undefined);
  const [subcategory, setSubcategory] = useState<number | null>(null);
  const [subcategoryOptions, setSubcategoryOptions] = useState<OptionProps[]>([]);
  const [date, setDate] = useState<string>(initial.paymentDate);
  const [validate, setValidate] = useState(false);
  const usedFiles = useRef<Record<string, boolean>>({});
  const [invoices, setInvoices] = useState<FinanceDocument[]>(financeCost?.documents || []);
  const [dateKey, setDateKey] = useState('1');
  const [activationId, setActivationId]
    = useState<number | null>(financeCost?.activationId || null);

  const getCategoryById = (id?: number): FinanceCostCategoryOption | undefined => {
    return (id !== undefined)
      ? options.categories.find(cat => cat.id === id)
      : undefined;
  };

  const onChangeCategory = (id?: number) => {
    const cat = id ? getCategoryById(id) : undefined;
    setCategory(cat);
  };

  const onDateChange = async (dt: moment.Moment | null) => {
    setDate(moment(dt).format('DD-MM-YYYY'));
  };

  const onUpload = () => async (uploadFiles: UploadFile[]) => {
    if (!financeCost) {
      return notify('Please save Finance Cost first!');
    }
    // check unique
    const files: UploadFile[] = [];
    uploadFiles.forEach(file => {
      const uid = file.uid;
      if (!usedFiles.current[uid]) {
        usedFiles.current[uid] = true;
        files.push(file);
      }
    });
    if (files.length === 0) {
      return;
    }

    // upload files
    try {
      const s3files = await Promise.all(
        files.map(file => s3http.uploadFinanceFile(file.originFileObj as File)),
      );
      const updatedDocuments = await financeCostHttp.addDocument({
        id: financeCost.id,
        fileIds: s3files.map(file => file.id),
        type: 'invoice',
      });
      setInvoices(updatedDocuments);
    } catch (err: any) {
      console.error(err);
      showAxiosError(err);
    } finally {
      setLoading(false);
    }
  };

  const onRemove = () => async (documentId: number) => {
    if (!financeCost) {
      return notify('Please save Finance Cost first!');
    }
    try {
      const updatedDocuments = await financeCostHttp.removeDocument({
        id: financeCost.id,
        documentId,
      });
      setInvoices(updatedDocuments);
    } catch (err: any) {
      console.error(err);
      showAxiosError(err);
    }
  };

  const getSubcategoryOptions = (list: FinanceCostSubCategoryOption[]): OptionProps[] => {
    return list.map((c: FinanceCostSubCategoryOption, key) => (
      { value: c.id, label: c.name, key }
    ));
  };

  const submit = async () => {
    setValidate(true);
    try {
      setLoading(true);
      await form.validateFields();
      const params = form.getFieldsValue();
      params.paymentDate = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
      params.costSubcategoryId = subcategory;
      onSubmit(params);
    } catch (e) {
      // @ts-ignore
      if (e.errorFields?.length) {
        notify('Please fill in all required fields of the form', { type: 'error' });
      }
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (financeCost) {
      const cat = getCategoryById(financeCost.costCategory.id);
      if (cat) {
        const ops = getSubcategoryOptions(cat.subcategories);
        setSubcategoryOptions(ops);
        setCategory(cat);
        setSubcategory(financeCost.costSubcategoryId);
      }
    }
  }, []);

  useEffect(() => {
    if (financeCost) {
      setInvoices(financeCost.documents);
    }
  }, [financeCost]);

  return (
    <div className={cls.formWrap}>
      <Row gutter={{ xl: 80, xxl: 120 }} className={cls.infoWrapper}>
        <Col xs={24} xl={12} className={cls.infoCol}>

          <div className={cls.customInputRow}>
            <div className={cls.customInputLabelCol}>Code:</div>
            <div className={cls.customInputValueCol}>
              <CodeField code={financeCost?.code || undefined} />
            </div>
          </div>
          <Form.Item
            label="Expense*"
            name="expense"
            className={clsx(cls.formItem)}
            labelCol={labelCol}
            rules={[
              { required: true, message: '' },
              { type: 'number', message: 'Only numbers are allowed' },
              () => ({
                validator(_, value) {
                  if (value === 0 || value === undefined) {
                    return Promise.reject(new Error(''));
                  }
                  if (value > 999999999) {
                    return Promise.reject(new Error('Max amount is $999,999,999'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              formatter={(val) => `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(val) => {
                return val ? +val.replace(/\$\s?|(,*)/g, '') : 0.00;
              }}
              bordered={false}
              className={clsx(cls.inputField, cls.moneyField)}
              controls={false}
              autoFocus
            />
          </Form.Item>
          <Form.Item
            label="Category*"
            name="costCategoryId"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[{ required: true, message: '' }]}
            required
          >
            <Select
              className={clsx(cls.selectField, validate && !category && cls.error)}
              status={validate && !category ? 'error' : undefined}
              allowClear
              bordered={false}
              onChange={onChangeCategory}
            >
              {options.categories.map((item, key) => (
                <Option
                  value={item.id}
                  label={item.fullName}
                  className={cls.selectOption}
                  key={key}
                >
                  <div className={clsx(cls.selectOptionWrap)}>{item.fullName}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Description*"
            name="costSubcategoryId"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[{ required: true, message: '' }]}
            required
            shouldUpdate={(prev, current) => {
              if (prev.costCategoryId !== current.costCategoryId) {
                const cat = getCategoryById(current.costCategoryId);
                const ops = getSubcategoryOptions(cat?.subcategories || []);
                setSubcategoryOptions(ops);
                current.costSubcategoryId = null;
                return true;
              }
              return false;
            }}
          >
            <Select
              className={clsx(cls.selectField, validate && !subcategory && cls.error)}
              status={validate && !subcategory ? 'error' : undefined}
              onChange={(e?: number) => setSubcategory(e || null)}
              allowClear
              bordered={false}
              disabled={!category}
              options={subcategoryOptions}
              showSearch
              filterOption={(input: string, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA: any, optionB: any) => (optionA?.label ?? '')
                .toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            label="Type"
            name="costTypeId"
            className={cls.formItem}
            labelCol={labelCol}
            required
          >
            <Select
              className={cls.selectField}
              allowClear
              bordered={false}
              onChange={onChangeCategory}
              showSearch
              filterOption={(input: string, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA: any, optionB: any) => (optionA?.label ?? '')
                .toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            >
              {options.types.map((item, key) => (
                <Option
                  value={item.id}
                  label={item.name}
                  className={cls.selectOption}
                  key={key}
                >
                  <div className={clsx(cls.selectOptionWrap)}>{item.name}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div className={cls.customInputRow}>
            <div className={cls.customInputLabelCol}>Date*:</div>
            <div className={cls.customInputValueCol}>
              <DatePicker
                key={dateKey}
                format="DD-MM-YYYY"
                placeholder="DD-MM-YYYY"
                onChange={onDateChange}
                defaultValue={moment(date, 'DD-MM-YYYY')}
                className={cls.dateInput}
                bordered={false}
                allowClear={false}
              />
            </div>
          </div>

          <Form.Item
            label="Payment*"
            name="paymentBank"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[{ required: true, message: '' }]}
            required
          >
            <Select
              className={cls.selectField}
              allowClear
              bordered={false}
            >
              {options.paymentBanks.sort().map((item, key) => (
                <Option
                  value={item}
                  label={item}
                  className={cls.selectOption}
                  key={key}
                >
                  <div className={clsx(cls.selectOptionWrap)}>{item}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Activation"
            name="activationId"
            className={cls.formItem}
            labelCol={labelCol}
          >
            <Select
              className={cls.selectField}
              allowClear
              bordered={false}
              onChange={setActivationId}
              showSearch
              filterOption={(input: string, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA: any, optionB: any) => (optionA?.label ?? '')
                .toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            >
              {options.activations.map((item, key) => (
                <Option
                  value={item.id}
                  label={item.activation}
                  className={cls.selectOption}
                  key={key}
                >
                  <div className={clsx(cls.selectOptionWrap)}>{item.activation}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} xl={12} className={cls.infoCol}>
          <div className={cls.fieldCol}>
            <div className={cls.fileFieldWrap}>
              <div className={cls.fileFieldLabel}>Invoices</div>
              <div className={cls.fileField}>
                <FinanceDocuments
                  code={financeCost?.code}
                  documents={invoices}
                  onUpload={onUpload()}
                  onRemove={onRemove()}
                  canSave={!!financeCost}
                />
              </div>
            </div>
          </div>

          <Activation activationId={activationId} />
        </Col>
      </Row>

      <div className={cls.actions}>
        <Button
          variant="contained"
          color="secondary"
          className={cls.save}
          disabled={loading}
          onClick={submit}
        >
          <SaveIcon /> Save
        </Button>
        {onDelete ? (
          <ConfirmDelete title="Are you sure?" cb={onDelete}>
            <Button variant="contained" className={cls.delete}>Delete</Button>
          </ConfirmDelete>
        ) : null}
      </div>
    </div>
  );
};
