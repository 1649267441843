import React from 'react';
import { clsx } from 'clsx';
import {
  ListProps,
  TextField,
  RichTextField,
  NumberField,
} from 'react-admin';

import {
  BooleanField,
  ImageField,
  TextInput,
  Grid,
  GridList,
  Forbidden,
  ButtonLinkField,
  DateField,
} from '@components';
import { useRbacList } from '@hooks';
import cls from './WebinarList.module.css';

export const WebinarList = (props: ListProps) => {
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      className={cls.list}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'titleMx link startsAt url image isEnabled'.split(' ')}
        className={cls.grid}
      >
        <TextField source="id" />
        <TextField source="titleMx" />
        <ButtonLinkField source="link"
          text="Link"
          label="Website link"
          className={clsx(cls.btn, cls.btnLink)} />
        <ButtonLinkField source="url"
          text="Link"
          label="Zoom link"
          className={clsx(cls.btn, cls.btnUrl)} />
        <RichTextField source="announceMx" />
        <RichTextField source="descriptionMx" />
        <TextField source="speaker" />
        <DateField source="startsAt" label="Starts" showTime />
        <NumberField source="durationMinutes" />
        <ImageField source="image" width={120} maxHeight={40} />
        <DateField source="createdAt" label="Created" />
        <BooleanField source="isEnabled" label="Enabled" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
];
