import { useEffect, useState } from 'react';

import { InitialPage, InitialCard } from '@components';
import { Credit, User } from '@types';
import { creditHttp } from '@network';
import { CreditFarms } from '@pages/credit';

export const UserFarmsPage = ({ user }: {user: User}) => {
  const [credits, setCredits] = useState<Credit[]>([]);

  useEffect(() => {
    (async () => {
      const nextCredits = await creditHttp.getCreditsByUser(user.id);
      setCredits(nextCredits.sort((a, b) => b.id - a.id));
    })();
  }, [user.id]);

  const backTo = `/user/${user.id}?initial`;

  return (
    <InitialPage title="Farms" backTo={backTo} backText="Back to Individual User">
      {credits.map(credit => (
        <InitialCard key={credit.id} mt={24}>
          <CreditFarms credit={credit} editable={true} />
        </InitialCard>
      ))}
    </InitialPage>
  );
};
