import {
  ListProps,
  TextField,
  RichTextField,
  ReferenceField,
} from 'react-admin';

import {
  BooleanField,
  TextInput,
  Grid,
  GridList,
  PhoneField,
  EmailField,
  Forbidden,
  NullableBooleanInput,
  DateField,
} from '@components';
import { useRbacList } from '@hooks';
import { checkSuperadmin } from '@providers';
import cls from './WebinarSignupList.module.css';

export const WebinarSignupList = (props: ListProps) => {
  const isSuperadmin = checkSuperadmin();
  const rbacList = useRbacList();

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      className={cls.list}
    >
      <Grid
        rowClick="edit"
        defaultColumns={
          'id webinarId fullName phone company createdAt visited'.split(' ')
        }
        className={cls.grid}
      >
        <TextField source="id" />
        {isSuperadmin ? (
          <ReferenceField reference="webinar-list" source="webinarId" label="Webinar">
            <TextField source="titleMx" />
          </ReferenceField>
        ) : (
          <TextField source="webinar-list.titleMx" label="Webinar" />
        )}
        <TextField source="fullName" />
        <PhoneField source="phone" isText />
        <EmailField source="email" isText wrap />
        <EmailField source="company" isText />
        <BooleanField source="visited" disabled />
        <DateField source="visitedAt" showTime />
        <RichTextField source="questions" />
        <DateField source="createdAt" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  <NullableBooleanInput source="visited" key="visited" alwaysOn />,
];
