import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Input, Select } from 'antd';
import { useRequest } from '@hooks';
import { useNotify } from 'react-admin';
import { useListContext } from 'ra-core';
import { DollarOutlined } from '@ant-design/icons';

import { loanPaymentHttp } from '@network';
import { BlueButton, BlueOutlineButton, AntdDateControlledInput } from '@components';
import { useModalContext } from '@providers';

enum BulkProps {
  'Expected Date', // date
  'Moratory Interests', // money
  'Ordinary Interests', // money
  'Guarantee Deposits', // money
  'Principal Amortization', // money
  'Total Payment' // money
}

type BulkProp = keyof typeof BulkProps;

const bulkPropsList = Object.keys(BulkProps).filter(k => isNaN(+k) || isNaN(parseFloat(k)));
const MAX_MONEY = 1000000000;

export const LoanPaymentListActions = () => {
  const notify = useNotify();
  const cls = useStyles();
  const { hideModal } = useModalContext();
  const { selectedIds, refetch } = useListContext();
  const dateRef = useRef<any>();
  const amountRef = useRef<any>();

  const [prop, setProp] = useState<BulkProp>();
  const [value, setValue] = useState<string | number | null>(null);

  useEffect(() => {
    resetValues();
    prop && setTimeout(() => {
      dateRef.current?.focus();
      amountRef.current?.focus();
    }, 300);
  }, [prop]);

  const resetValues = () => {
    setValue(null);
  };

  const onCancel = () => {
    setProp(undefined);
    resetValues();
    hideModal();
  };

  const { loading, submit } = useRequest(async () => {
    if (!Array.isArray(selectedIds) || selectedIds.length === 0) {
      return notify('No records are selected');
    }
    if (!prop) {
      return notify('No property is selected');
    }

    const updatedTotal = await loanPaymentHttp.updateBulk({ selectedIds, prop, value });

    notify(`Updated records: ${updatedTotal}`);
    onCancel();
    refetch();
  });

  const isDate = prop === 'Expected Date';
  const isMoney = prop === 'Moratory Interests'
    || prop === 'Ordinary Interests'
    || prop === 'Guarantee Deposits'
    || prop === 'Principal Amortization'
    || prop === 'Total Payment';

  return (
    <div className={cls.box}>
      <div className={cls.title}>Properties to update</div>
      <Select value={prop} onChange={v => setProp(v)} className={cls.select}
        placeholder="Select a property to edit">
        {bulkPropsList.map(propName => (
          <Select.Option key={propName} value={propName}>
            {propName}
          </Select.Option>
        ))}
      </Select>

      <div className={cls.content}>
        <div className={cls.title}>{prop}</div>

        {isDate ? (
          <AntdDateControlledInput
            valueStr={typeof value === 'string' ? value : null}
            onUpdate={date => setValue(date)}
            className={cls.textarea}
            pickerRef={dateRef}
          />
        ) : null}

        {isMoney ? (
          <Input
            type="number"
            className={cls.textarea}
            prefix={<DollarOutlined className={cls.inputIcon} />}
            value={typeof value === 'number' ? value : undefined}
            onChange={e => e.target.value && +e.target.value > MAX_MONEY
              ? setValue(MAX_MONEY)
              : setValue(+e.target.value)}
            ref={amountRef}
            min={0} max={MAX_MONEY}
          />
        ) : null}
      </div>

      <div className={cls.controls}>
        <BlueButton className={cls.btn} onClick={submit} disabled={loading}>
          Update
        </BlueButton>
        <BlueOutlineButton className={cls.btn} onClick={onCancel}>
          Cancel
        </BlueOutlineButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  box: {},
  group: {
    marginTop: 15,
  },
  title: {
    fontSize: 20,
    lineHeight: 1,
    fontWeight: 500,
  },
  select: {
    marginTop: 10,
    width: '100%',
    borderRadius: 8,
    border: '1px solid #E0E7EE',
    backgroundColor: '#F3F7F9',
    '& .ant-select-selector': {
      backgroundColor: 'transparent !important',
    },
  },
  content: {
    marginTop: 15,
  },
  controls: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    margin: '0 7px',
    padding: '5px 29px !important',
    height: 34,
    borderRadius: 8,
    textTransform: 'none',
  },
  textarea: {
    marginTop: 10,
    padding: '10px 19px 12px',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#E1E5ED',
    '& input': {
      background: '#E1E5ED',
    },
    '& .ant-picker-input': {
      paddingLeft: 10,
      paddingRight: 10,
      background: '#E1E5ED',
      borderRadius: 8,
      border: '1px solid #042E6B',
    },
  },
  inputIcon: {
    color: '#333',
    marginRight: 10,
  },
});
