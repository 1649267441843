import { clsx } from 'clsx';
import MaskedInput from 'antd-mask-input';
import SaveIcon from '@mui/icons-material/Save';
import { Button, MenuItem, Select } from '@mui/material';
import { AutoComplete, Checkbox, Col, Form, Input, Row } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { financeAccountHttp } from '@network';
import { FinanceAccountOptions, FinanceAccountUserOption } from '@types';
import cls from './finance-account-form.module.css';

interface Props {
  user: FinanceAccountUserOption | null;
  setUser: (data: FinanceAccountUserOption | null) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isEdit?: boolean;
  onDelete?: () => void;
}

interface OptionProps {
  key: number;
  value: string;
  label: JSX.Element;
}

export const FinanceAccountForm = ({ user, setUser, loading, isEdit, onDelete }: Props) => {
  const PHONE_CODE_REG_EXP = /^\+52|^\+7/;
  const labelCol = { md: 5, lg: 5, xl: 7, xxl: 5 };
  const [banks, setBanks] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const [users, setUsers] = useState<FinanceAccountUserOption[]>([]);
  const [usersOptions, setUsersOptions] = useState<OptionProps[]>([]);
  const [codesList, setCodesList] = useState<OptionProps[]>([]);

  const renderOption = (idx: number, code: string, disabled = false): OptionProps => ({
    key: idx,
    value: disabled ? '' : code,
    label: <span className={clsx(cls.option, disabled && cls.disabledOption)}>{code}</span>,
  });

  const getOptions = async () => {
    try {
      const ops: FinanceAccountOptions = await financeAccountHttp.options();

      if (!ops) {
        throw new Error('The list of options has not been received');
      }

      setBanks(ops.banks);
      setTypes(ops.types);
      setUsers(ops.users);
      setUsersOptions(ops.users.map((u, idx) => renderOption(idx, u.code, !!u.financeAccountId)));
      setCodesList(ops.users.map((u, idx) => renderOption(idx, u.code, !!u.financeAccountId)));
    } catch (e) {
      console.error(e);
    }
  };

  const onUserSearch = (searchText: string) => {
    setCodesList(
      !searchText
        ? usersOptions
        : usersOptions.filter(o => o.value.includes(searchText)),
    );
  };

  const onUserSelect = (data: string) => {
    const user = users.find(u => u.code === data);
    setUser(user ? user : null);
  };

  useEffect(() => {
    (async () => {
      await getOptions();
    })();
  }, []);

  return (
    <div className={cls.formWrap}>
      <Row gutter={{ xl: 80, xxl: 120 }} className={cls.infoWrapper}>
        <Col xs={24} xl={12} className={cls.infoCol}>
          <Form.Item
            label="Code*"
            name="code"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[
              { required: true, message: '' },
              { max: 40, message: 'Code should be less then 40 characters' },
            ]}
          >
            <Input bordered={false} className={cls.inputField}/>
          </Form.Item>
          <Form.Item
            label="Addressee*"
            name="addressee"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[
              { required: true, message: '' },
              { max: 40, message: 'Addressee should be less then 40 characters' },
            ]}
          >
            <Input
              bordered={false}
              className={cls.inputField}
              disabled={!!user}
            />
          </Form.Item>
          <Form.Item
            label="User code"
            name="userCode"
            className={cls.formItem}
            labelCol={labelCol}
          >
            <AutoComplete
              options={codesList}
              className={clsx(cls.selectField, cls.autocompleteField)}
              popupClassName={cls.popup}
              notFoundContent="Code is not found"
              allowClear
              onSelect={onUserSelect}
              onSearch={onUserSearch}
              onChange={(e) => !e && setUser(null)}
              onClear={() => setUser(null)}
              placeholder="Search User code"
            />
          </Form.Item>
          <Form.Item
            label="Bank*"
            name="bank"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[
              { required: true, message: '' },
              { max: 40, message: 'Bank should be less then 40 characters' },
            ]}
          >
            <Select name="bank" className={cls.selectField}>
              {banks.map(value =>
                <MenuItem key={value} value={value}>{value}</MenuItem>,
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Type*"
            name="type"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[
              { required: true, message: '' },
              { max: 40, message: 'Type should be less then 40 characters' },
            ]}
          >
            <Select name="type" className={cls.selectField}>
              {types.map(value =>
                <MenuItem key={value} value={value}>{value}</MenuItem>,
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Account*"
            name="account"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[
              { required: true, message: '' },
              { max: 18, message: 'Account should be less then 18 characters' },
              { min: 18, message: 'Account should be more then 18 characters' },
              () => ({
                validator(_, value) {
                  if (!value) return Promise.resolve();
                  const reg = /^\d+$/;
                  if (!reg.test(value)) {
                    return Promise.reject(new Error('Only numbers are allowed'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              bordered={false}
              className={cls.inputField}
            />
          </Form.Item>
          <Form.Item
            label="Branch"
            name="branch"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[
              { max: 40, message: 'Branch should be less then 40 characters' },
            ]}
          >
            <Input bordered={false} className={cls.inputField}/>
          </Form.Item>
        </Col>

        <Col xs={24} xl={12} className={cls.infoCol}>
          <Form.Item
            label="Reference"
            name="reference"
            className={cls.formItem}
            labelCol={labelCol}
          >
            <Input bordered={false} className={cls.inputField} maxLength={40}/>
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[
              { required: false, message: '' },
              () => ({
                validator(_, value) {
                  const phone = value?.replace(PHONE_CODE_REG_EXP, '').replace(/\D/g, '') || '';
                  if (phone.length === 0) {
                    return Promise.resolve();
                  }
                  if (phone.length !== 10) {
                    return Promise.reject(new Error('Type in a correct phone'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            {!user ? (
              <MaskedInput
                mask="+52 (000) 000 00 00"
                className={clsx(cls.inputField, cls.maskedField)}
              />
            ) : (
              <Input bordered={false} className={cls.inputField} disabled/>
            )}
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            className={cls.formItem}
            labelCol={labelCol}
            rules={[
              { required: false, message: '' },
              () => ({
                validator(_, value) {
                  if (!value) return Promise.resolve();
                  const reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/gi;
                  if (!reg.test(value)) {
                    return Promise.reject(new Error('Type in a correct email'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              bordered={false}
              className={cls.inputField}
              disabled={!!user}
            />
          </Form.Item>
          <Form.Item
            name="accountSetup"
            valuePropName="checked"
            className={clsx(cls.formItem, cls.checkboxItem)}
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox
              className={clsx(cls.checkboxField, !isEdit && cls.disabled)}
              disabled={!isEdit}
            >Account Setup</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <div className={clsx(cls.actions, isEdit && cls.editActions)}>
        <Button variant="contained" color="secondary" className={cls.save} type="submit" disabled={loading}>
          <SaveIcon /> Save
        </Button>
        {isEdit && onDelete ? (
          <Button variant="contained" className={cls.delete} onClick={onDelete}>Delete</Button>
        ) : null}
      </div>
    </div>
  );
};
