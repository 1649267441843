import { clsx } from 'clsx';
import { useRefresh } from 'react-admin';
import { useEffect, useState } from 'react';
import { Button, Col, message, Popconfirm, Row, Tabs } from 'antd';

import { FarmMap } from './farm-map';
import { FarmForm } from './farm-form';
import { creditFarmHttp } from '@network';
import { Credit, CreditFarm } from '@types';
import { FarmAddForm } from './farm-add-form';
import { FarmPicture } from './farm-picture';

import './credit-farms.css';
import { useQuery } from '@hooks';
import cls from './credit-farms.module.css';

interface Props {
  credit: Credit;
  editable: boolean;
  setCredit?: (newCredit: Credit) => void;
}

const MAX_FARMS = 9;
const emptyFarm: CreditFarm = { id: 0, latitude: '', longitude: '' };

export const CreditFarms = ({ credit, editable, setCredit }: Props) => {
  const refresh = useRefresh();
  const query = useQuery();
  const [farms, setFarms] = useState<CreditFarm[]>([]);
  const [activeKey, setActiveKey] = useState<string | false | undefined>(
    farms[0] && farms[0].id.toString(),
  );
  const activeFarm = farms.find(farm => `${farm.id}` === activeKey);

  const loadFarms = async (autoSetFirst?: boolean) => {
    const nextFarms = await creditFarmHttp.getFarmsOfCredit(credit.id);
    if (nextFarms.length === 0) {
      nextFarms.push({ ...emptyFarm });
    }
    setFarms(nextFarms);
    if (autoSetFirst && nextFarms[0]) {
      setActiveKey(nextFarms[0].id.toString());
    }
    const farmId = query.get('farm');
    if (farmId) {
      setActiveKey(farmId);
    }
  };

  useEffect(() => void loadFarms(true), [credit.id]);

  const onTabChange = (nextKey: string) => {
    setActiveKey(nextKey);
  };

  const onAddFarm = (farm: CreditFarm) => {
    setFarms([...farms, farm].filter(f => f.id > 0));
    setActiveKey(farm.id.toString());
    message.success('Guardar');
    refresh();
  };

  const removeFarm = async (farmId: number) => {
    if (farmId !== 0) {
      await creditFarmHttp.removeFarm(farmId);
    }
    const nextFarms = farms.filter(farm => farm.id !== farmId);
    if (nextFarms.length === 0) {
      nextFarms.push({ ...emptyFarm });
    }
    setFarms(nextFarms);
    setActiveKey(nextFarms[0] && nextFarms[0].id.toString());
    refresh();
  };

  const onPlusFarm = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (farms.length >= MAX_FARMS) {
      return;
    }
    let blankFarm: CreditFarm = farms.find(f => f.id === 0) as CreditFarm;
    if (!blankFarm) {
      blankFarm = { ...emptyFarm };
      setFarms([...farms, blankFarm]);
    }
    setActiveKey(blankFarm.id.toString());
    document.getElementById('farm-latitude')?.focus();
  };

  const onMapClick = (lat: number, lng: number) => {
    if (activeFarm) {
      const updatedFarm: CreditFarm = { ...activeFarm, latitude: `${lat}`, longitude: `${lng}` };
      const nextFarms = farms.map(farm => farm.id === activeFarm.id ? updatedFarm : farm);
      setFarms(nextFarms);
    }
  };
  const compactTabs = farms.length > 5;

  const onUpdateFarm = (updatedFarm: CreditFarm) => {
    if (activeFarm) {
      const nextFarms = farms.map(farm => farm.id === activeFarm.id ? updatedFarm : farm);
      setFarms(nextFarms);
    }
  };

  return (
    <Row gutter={[20, 20]} className={cls.wrap}>
      <Col xs={24} sm={24} md={8}>
        <div className={cls.info}>
          <div className={cls.title}>General Information | {credit.code}</div>
          {activeFarm ? (
            <FarmForm
              credit={credit}
              setCredit={setCredit}
              farm={activeFarm}
              onPlusFarm={onPlusFarm}
              onUpdateFarm={onUpdateFarm}
            />
          ) : null}
        </div>
      </Col>
      <Col xs={24} sm={24} md={16}>
        <div className={cls.farms}>
          <FarmAddForm
            creditId={credit.id}
            onAddFarm={onAddFarm}
            activeFarm={activeFarm}
            editable={editable}
            onChange={() => loadFarms(false)}
          />
          <Tabs
            className={clsx('CreditFarmTabs', cls.tabs)}
            activeKey={`${activeKey}`}
            onChange={nextKey => onTabChange(nextKey)}
            items={farms.map((farm, idx) => {
              const isLast = idx === (farms.length - 1);
              return {
                label: (
                  <div className={clsx(
                    cls.tab,
                    compactTabs && cls.tabCompact,
                    idx === 0 && cls.tabFirst,
                    isLast && cls.tabLast,
                  )}>
                    <span className={cls.tabName}>Farm #{idx + 1}</span>
                    {editable ? (
                      <Popconfirm
                        title="¿Eliminar?"
                        onConfirm={() => void removeFarm(farm.id)}
                        okText="Sí"
                        cancelText="No"
                      >
                        <button className={cls.btnRemove} type="button" title="Eliminar">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none">
                            <path d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
                              fill="#FD8A32" />
                          </svg>
                        </button>
                      </Popconfirm>
                    ) : null}
                    {editable && isLast ? (
                      <Button type="ghost" onClick={onPlusFarm} className={cls.btnPlus}>
                        +
                      </Button>
                    ) : null}
                  </div>
                ),
                key: farm.id.toString(),
                children: activeFarm && (
                  <FarmMap farm={activeFarm} onMapClick={onMapClick} />
                ),
              };
            })}
          />
          {activeFarm && activeFarm.id > 0 ? (
            <FarmPicture farm={activeFarm} onChange={() => loadFarms(false)} />
          ) : null}
        </div>
      </Col>
    </Row>
  );
};
