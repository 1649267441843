import { clsx } from 'clsx';
import { Button, Toolbar } from '@mui/material';
import { Edit, EditProps, Link, SimpleForm } from 'react-admin';

import { rbac } from '@utils';
import { TicketView } from './TicketView';
import { SupportFaq, SupportTicket } from '@types';
import { TicketForm, validate } from './TicketForm';
import { InitialPage } from '@components';
import { useEditProps, useQuery, useRbacEdit, useRbacReadonly, useRecord } from '@hooks';
import cls from './Ticket.module.css';

const Title = ({ record }: {record?: SupportFaq}) =>
  record ? (<span>Ticket: {record.id}</span>) : null;

export const SupportTicketEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const record = useRecord<SupportTicket>(props);
  const rbacEdit = useRbacEdit();
  const query = useQuery();
  const showView = query.get('view') === '';
  const rbacReadonly = useRbacReadonly();
  const isViewPage = rbacReadonly || showView;

  const noEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const ViewActions = () => {
    return rbacEdit ? (
      <Toolbar className={clsx(cls.toolbar, cls.viewToolbar)}>
        <div className={cls.rightBtns}>
          <Link to={`/support-ticket/${rbacEdit && rbac.isSuperadmin() ? record.id : ''}`}>
            <Button variant="outlined" color="secondary" className={cls.viewBtn}>Return</Button>
          </Link>
        </div>
      </Toolbar>
    ) : null;
  };

  return (
    <Edit {...props} title={<Title />} {...editProps} className={cls.page}>
      <InitialPage
        title="Ticket Solver"
        backTo="/support-ticket"
        backText="Back to all tickets"
      >
        {isViewPage ? (
          <SimpleForm validate={validate} toolbar={<ViewActions/>} onKeyPress={noEnter}>
            <TicketView record={record}/>
          </SimpleForm>
        ) : (
          <SimpleForm validate={validate} toolbar={false}>
            <TicketForm />
          </SimpleForm>
        )}
      </InitialPage>
    </Edit>
  );
};
