import { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import { Credit } from '@types';
import { useModalState } from '@hooks';
import { dateFormatter, moneyFormatter } from '@utils';
import { ExportPositionsResponse, ordersHttp } from '@network';
import cls from './export-orders-button.module.css';

export interface BankPaymentButtonProps {
  credit: Credit;
  buttonClass?: any;
}

export const ExportOrdersButton = ({ credit, buttonClass }: BankPaymentButtonProps) => {
  const { is: isModalOpened, hide: hideModal, show: showModal } = useModalState();
  const [result, setResult] = useState<ExportPositionsResponse>();

  useEffect(() => {
    (async () => {
      const nextResult = await ordersHttp.exportPositionsByUser(credit.userId);
      setResult(nextResult);
    })();
  }, [credit.userId]);

  const downloadPdfDocument = () => {
    const input = document.getElementById('export-modal');
    input && html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png') as string;
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`Verqor receipt for credit ${credit.code} of ${credit.user.fullName}.pdf`);
      });
  };

  return (
    <>
      <Button
        icon={<DownloadFileIcon />}
        block
        type="ghost"
        onClick={showModal}
        className={buttonClass}
      >Download receipt</Button>

      <Modal
        open={isModalOpened}
        title={(
          <Button
            icon={<DownloadFileIcon />}
            block
            type="ghost"
            onClick={downloadPdfDocument}
            className={buttonClass}
          >Download receipt</Button>
        )}
        footer={null}
        onCancel={hideModal}
        width={760}
      >
        <div id="export-modal">
          <div className={cls.box}>
            <div className={cls.header}>
              <div className={cls.logo}>
                <img alt="Verqor logo" src="/verqor-logo.png" className={cls.logoImg} />
              </div>
              <div>
                <img
                  alt="unlocking agro potential"
                  src="/verqor-unlocking.png"
                  className={cls.logoText}
                />
              </div>
            </div>

            <div className={cls.info}>
              <div className={cls.code}>
                <div>{credit.code}</div>
                <div className={cls.details}>
                  <span className={cls.orange}>DETALLE DE PEDIDOS</span>
                </div>
              </div>
              <div className={cls.name}>
                <div>{credit.user.fullName}</div>
                <div>Fecha: {dateFormatter.toDateSlash(new Date())}</div>
              </div>
            </div>

            <div className={cls.subtotal}>
              <div className={cls.subtotalRow}>
                <div>Monto del crédito:</div>
                <div>{moneyFormatter.format(credit.bankApproval || 0)}</div>
              </div>
              <div className={cls.subtotalRow}>
                <div>Efectivo:</div>
                <div>{moneyFormatter.format(credit.manual || 0)}</div>
              </div>
              <div className={cls.subtotalRow}>
                <div>Membresía Verqor:</div>
                <div>{moneyFormatter.format(credit.membership || 0)}</div>
              </div>
              <div className={cls.subtotalRow}>
                <div className={cls.green}>Comisión por apertura:</div>
                <div className={cls.green}>
                  {moneyFormatter.format(credit.refunds || 0)}
                </div>
              </div>
            </div>

            <div className={cls.saldoLine}>Saldo para insumos:</div>
            <table className={cls.orders}>
              <thead>
                <tr key="header">
                  <th className={cls.ordersLabel}>
                    <span className={cls.orange}>PRODUCTO</span>
                  </th>
                  <th className={cls.ordersLabel}>UNIDADES</th>
                  <th className={cls.ordersLabel}>PRECIO UNITARIO</th>
                  <th className={cls.ordersLabel}>
                    <span className={cls.green}>SUBTOTAL</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {(result?.positions || []).map(position => (
                  <tr key={position.name}>
                    <td>{position.name}</td>
                    <td>{position.qty}</td>
                    <td>{moneyFormatter.format(position.price)}</td>
                    <td>{moneyFormatter.format(position.cost)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr key="total" className={cls.totalRow}>
                  <td colSpan={3}><span className={cls.orange}>TOTAL</span></td>
                  <td><span>{moneyFormatter.format(result?.totalCost || 0)}</span></td>
                </tr>
                <tr key="balance" className={cls.balanceRow}>
                  <td colSpan={3}><span className={cls.green}>Saldo final:</span></td>
                  <td>
                    <span className={cls.green}>{moneyFormatter.format(credit.balance || 0)}</span>
                  </td>
                </tr>
              </tfoot>
            </table>

          </div>
        </div>
      </Modal>
    </>
  );
};

const DownloadFileIcon = () => (
  <svg className={cls.downloadIcon}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none">
    <g clipPath="url(#clip0_3168_6602)">
      <path d="M13.9198 3.25872H17.3957C17.3521 3.18539 17.2941 3.11914 17.2251 3.06289L13.6635 0.192057C13.5671 0.113724 13.4507 0.0566406 13.3237 0.0253906L13.3851 2.79872C13.3903 3.05289 13.6275 3.25706 13.9198 3.25872Z"
        fill="#042E6B" />
      <path d="M13.9198 3.9475C13.1972 3.94375 12.6093 3.44083 12.5925 2.81292L12.5302 0H1.78394C0.797334 0.0025 -0.000957471 0.699167 8.61942e-07 1.55708V18.4429C-0.000957471 19.3008 0.797334 19.9975 1.78394 20H15.7056C16.6923 19.9975 17.4905 19.3008 17.4896 18.4429V3.9475H13.9198Z"
        fill="#042E6B" />
      <path d="M11.9434 12.1964L9.84413 14.0164C9.60743 14.2214 9.29357 14.3443 8.96007 14.3627C8.93084 14.366 8.90161 14.3677 8.87238 14.3681C8.84699 14.3693 8.82159 14.3693 8.7962 14.3681C8.46222 14.3552 8.14549 14.2368 7.90447 14.0356L5.77026 12.3764C5.47078 12.1448 5.44395 11.746 5.71036 11.4856C5.97678 11.2252 6.43534 11.2018 6.73482 11.4335L8.15459 12.5314V7.29393C8.1407 6.9456 8.45407 6.6531 8.85513 6.64101C9.25572 6.62893 9.59209 6.90143 9.60599 7.25018C9.60647 7.26476 9.60647 7.27935 9.60599 7.29393V12.4443L10.9213 11.3006C11.2059 11.0552 11.6654 11.0564 11.9477 11.3039C12.2299 11.5514 12.228 11.951 11.9438 12.1964H11.9434Z"
        fill="#E1E5ED" />
    </g>
    <defs>
      <clipPath id="clip0_3168_6602">
        <rect width="17.4896" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
