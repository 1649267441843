import { SurveyQuestion } from '@types';
import cls from './SurveyCustomAnswer.module.css';

export const SurveyCustomAnswer = ({ value }: { value: SurveyQuestion }) => {
  return (
    <div className={cls.wrap}>
      {value.customAnswer
        ? <div className={cls.answer}>{value.customAnswer}</div>
        : <div className={cls.empty}>No answers</div>}
    </div>
  );
};
