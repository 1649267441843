import { Link } from 'react-admin';
import PlusIcon from '@mui/icons-material/Add';
import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';

import { BlueOutlineButton, InitialCard } from '@components';
import { FinanceCostCategory } from '@types';

const CreateBtn = () => (
  <Link to={'/finance-cost-category/create'}>
    <BlueOutlineButton size="small">
      <PlusIcon />Create
    </BlueOutlineButton>
  </Link>
);

interface Props {
  categories: FinanceCostCategory[];
  readonly?: boolean;
}

export const FinanceCostCategories = ({ categories, readonly }: Props) => {
  const cls = useStyles();

  return (
    <InitialCard
      left="Cost Categories"
      right={readonly ? undefined : <CreateBtn />}
      extraPadding
    >
      <Row gutter={[50, 20]}>
        {categories.map(category => (
          <Col key={category.id} xs={24} sm={12}>
            <Link to={`/finance-cost-category/${category.id}`} className={cls.link}>
              <div className={cls.linkCode}>{category.code}</div>
              <div className={cls.linkName}>{category.fullName}</div>
            </Link>
          </Col>
        ))}
      </Row>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  link: {
    width: '100%',
    height: 59,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    border: '5px solid #042E6B',
    position: 'relative',
    margin: '12px auto 10px',
    '&:hover': {
      borderColor: '#205299',
    },
  },
  linkCode: {
    position: 'absolute',
    top: -20,
    left: -10,
    backgroundColor: 'white',
    width: 80,
    height: 33,
    textAlign: 'center',
    color: '#000',
    fontSize: 25,
    fontWeight: 600,
  },
  linkName: {
    color: '#000',
    fontSize: 22,
  },
});
