import { Popconfirm, Upload, UploadFile, Typography } from 'antd';

import { FinanceDocument } from '@types';
import { FileHtml, FileImage, FilePdf, FileWithoutPreview } from '@components';
import { beforeUploadFlex, FileTypesOptions } from '@utils';
import cls from './finance-documents.module.css';
import { Button } from '@mui/material';

const MAX_DOCUMENTS = 10;
const { Dragger } = Upload;
const { Text } = Typography;

interface UploadFinanceDocumentProps {
  onUpload: (files: UploadFile[]) => Promise<void>;
}

const UploadFinanceDocument = ({ onUpload }: UploadFinanceDocumentProps) => {
  const options: FileTypesOptions = {
    types: [
      'application/pdf',
      'image/jpeg',
      'image/jpg',
      'image/png',
      'application/vnd.google-earth.kml+xml',
      'application/html',
      'text/html',
      '', // fix for kml-files on MacOS (unrecognized kml mimetype)
    ],
    typeError: 'You can only upload JPG/PNG/PDF/KML/HTML file!',
  };

  return (
    <Dragger
      maxCount={10}
      multiple
      listType="picture-card"
      showUploadList={false}
      beforeUpload={(file) => beforeUploadFlex(file, options)}
      customRequest={async ({ onSuccess }) => setTimeout(() => {
        onSuccess && onSuccess('ok');
      }, 0)}
      onChange={({ fileList }) => onUpload(fileList)}
      onPreview={() => {}}
      className={cls.creditReportUpload}
    >
      <div className={cls.uploadText}>+</div>
    </Dragger>
  );
};

interface FinanceDocumentsProps {
  isWithdrawal?: boolean;
  code?: string | null;
  documents?: FinanceDocument[];
  onUpload: (files: UploadFile[]) => Promise<void>;
  onRemove: (documentId: number) => Promise<void>;
  canSave: boolean;
}

export const FinanceDocuments = ({
  isWithdrawal, code, documents, onUpload, onRemove, canSave,
}: FinanceDocumentsProps) => {
  return (
    <div className={cls.creditReport}>
      {canSave ? null : <div className={cls.warning}>
        <Text type="danger">The files upload will be available when you have saved the new record</Text>
      </div>}
      <div className={cls.creditReportFiles}>
        {(documents || []).map((doc, i) => {
          const model = isWithdrawal ? 'Withdrawal' : 'Deposit';
          const title = code ? ` ${code}` : '';
          const type = doc.type === 'receipt' ? 'Receipt' : 'Invoice';
          const counter = `${i + 1}`;
          const downloadFilename = `${model}${title} – ${type} ${counter}.${doc.extension}`;

          const isPdf = doc.extension === 'pdf';
          const isHtml = doc.extension === 'html';
          const isKml = doc.extension === 'kml';
          const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(doc.extension);

          return (
            <div key={doc.id} className={cls.creditReportFile}>
              <div className={cls.creditReportFileContent}>
                {isPdf && (
                  <FilePdf url={doc.adminUrl} downloadFilename={downloadFilename} />
                )}
                {isKml && (
                  <FileWithoutPreview url={doc.adminUrl} downloadFilename={downloadFilename} />
                )}
                {isImage && (
                  <FileImage url={doc.adminUrl} downloadFilename={downloadFilename} />
                )}
                {isHtml && (
                  <FileHtml url={doc.adminUrl} downloadFilename={downloadFilename} />
                )}
                <Popconfirm
                  title="¿Eliminar?"
                  onConfirm={() => void onRemove(doc.id)}
                  okText="Sí"
                  cancelText="No"
                >
                  <Button className={cls.creditReportFileClose}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none">
                      <path
                        d="M7 0C3.13461 0 0 3.13461 0 7C0 10.8654 3.13461 14 7 14C10.8654 14 14 10.8654 14 7C13.9938 3.13461 10.8623 0.00310973 7 0Z"
                        fill="#002A77" />
                      <path
                        d="M9.63374 4.36244C9.51246 4.24116 9.31655 4.24116 9.19527 4.36244L6.9998 6.55791L4.80433 4.36244C4.68305 4.24116 4.48714 4.24116 4.36586 4.36244C4.24458 4.48372 4.24458 4.67964 4.36586 4.80092L6.56133 6.99638L4.36586 9.19185C4.24458 9.31313 4.24458 9.50905 4.36586 9.63032C4.48714 9.7516 4.68305 9.7516 4.80433 9.63032L6.9998 7.43486L9.19527 9.63032C9.31655 9.7516 9.51246 9.7516 9.63374 9.63032C9.75502 9.50905 9.75502 9.31313 9.63374 9.19185L7.43827 6.99638L9.63374 4.80092C9.75502 4.67964 9.75502 4.48372 9.63374 4.36244Z"
                        fill="white" />
                    </svg>
                  </Button>
                </Popconfirm>
              </div>

              <div className={cls.creditReportFileStatus}>Uploaded</div>
            </div>
          );
        })}

        {(documents || []).length < MAX_DOCUMENTS && (
          <UploadFinanceDocument onUpload={onUpload} />
        )}
      </div>
    </div>
  );
};
