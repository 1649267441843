import { useRecordContext } from 'react-admin';
import { makeStyles } from '@mui/styles';

import { Dealer } from '@types';

interface Props {
  source: string;
  reference?: string;
  optionKey?: string;
  optionLabel?: string;
  noload?: boolean;
  label?: string;
  sortable?: boolean;
}

export const UserDealersField = ({ source }: Props,
) => {
  const cls = useStyles();
  const record = useRecordContext();
  const data: any = record[source];

  return record ? (
    <>
      {data.length ? data.map((i: Dealer, idx: number) => (
        <span className={cls.name} key={idx}>
          {i.name}{idx === (data.length - 1) ? '' : ', '}
        </span>
      )) : null}
    </>
  ) : null;
};

const useStyles = makeStyles({
  name: {
    color: '#74706BFF!important',
  },
});
