export interface State {
  id: number;
  name: string;
  countryId: number;
  stateCode: string;
  latitude: string;
  longitude: string;
  grade?: StateGrade | '';
}

export interface StateWithScore extends State{
  environmental: number;
  economics: number;
  governance: number;
  financially: number;
  socially: number;
  ponderation: number;
}

export type StateScore = Pick<StateWithScore, 'environmental' | 'economics' | 'governance' | 'financially' | 'socially' | 'ponderation'>

export const stateGrades = ['A', 'B', 'C', 'D'] as const;
export type StateGrade = typeof stateGrades[number];
