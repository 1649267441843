import { makeStyles } from '@mui/styles';
import { Col, Row } from 'antd';

import {
  Container,
  TextInput,
  InitialCard,
  InitialRow,
  SelectInput,
} from '@components';
import { SubcategoriesForm } from './SubcategoriesForm';
import { FinanceCostSubcategory } from '@types';
import { useEffect, useState } from 'react';
import { FinanceSettingsData, financeSettingsHttp } from '@network/finance-settings-http';
import { useWatch } from 'react-hook-form';

export const MAX_COST_SUBCATEGORIES = 50;

export const validate = (values: any) => {
  const errors: any = {};

  ['name', 'code'].forEach((key: string) => {
    if (!values[key]) {
      errors[key] = 'This field is required';
    } else if (values[key]?.length > 100) {
      errors[key] = 'Max length is 30 characters';
    }
  });

  const subcategories = (values.subcategories || []) as FinanceCostSubcategory[];
  if (Array.isArray(subcategories) && subcategories.length > 0) {
    const uniqueNames: Record<string, boolean> = {};
    subcategories.forEach(subcategory => {
      const name = `${subcategory.name || ''}`.trim();
      if (!name && !errors['subcategories']) {
        errors['subcategories'] = 'Description is required';
      }
      if (name && uniqueNames[name] && !errors['subcategories']) {
        errors['subcategories'] = `Description is duplicated: ${name}`;
      }
      uniqueNames[name] = true;
    });
    if (subcategories.length > MAX_COST_SUBCATEGORIES) {
      errors['subcategories'] = `Maximum Description records is ${MAX_COST_SUBCATEGORIES}`;
    }
  }

  return errors;
};

export const FinanceCostCategoryForm = ({ readonly }: {readonly?: boolean}) => {
  const cls = useStyles();
  const [settings, setSettings] = useState<FinanceSettingsData>();
  const [usedCodes, setUsedCodes] = useState<Record<string, boolean>>({});
  const positionRoman = useWatch({ name: 'positionRoman' }) as string | undefined;

  useEffect(() => {
    (async () => {
      const nextSettings = await financeSettingsHttp.getSettings();
      const nextUsedCodes: Record<string, boolean> = {};
      nextSettings.costCategories.forEach(category => {
        nextUsedCodes[category.code] = true;
      });
      setSettings(nextSettings);
      setUsedCodes(nextUsedCodes);
    })();
  }, []);

  const alphabet = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];
  const nextPos = positionRoman || settings?.costCategoryNextPosition || null;

  return (
    <Container max>
      <InitialCard left="Category" extraPadding>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={11}>
            <InitialRow title="Name*" fontLg labelGrid={{ sm: 9 }}>
              {nextPos && <div className={cls.prefix}>{nextPos}.</div>}
              <TextInput source="name" label="" inputProps={{ maxLength: 32 }}
                onBeforeInputCapture={readonly ? e => e.preventDefault() : undefined } />
            </InitialRow>
            <SubcategoriesForm readonly={readonly} />
          </Col>
          <Col xs={0} sm={2} />
          <Col xs={24} sm={11}>
            <InitialRow title="Code*" fontLg labelGrid={{ sm: 9 }}>
              <SelectInput
                label=""
                source="code"
                disabled={readonly}
                choices={alphabet.map(letter => ({
                  id: letter,
                  name: letter,
                  disabled: !!usedCodes[letter],
                }))}
              />
            </InitialRow>
          </Col>
        </Row>
      </InitialCard>
    </Container>
  );
};

const useStyles = makeStyles({
  form: {
    padding: 0,
  },
  prefix: {
    position: 'absolute',
    top: 0,
    left: -40,
  },
  actions: {
    padding: 0,
    minHeight: 'initial',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  action: {
    width: 200,
    height: 38,
    borderRadius: 8,
    textAlign: 'center',
    fontFamily: 'Outfit, sans-serif',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 'normal',
  },
  actionSave: {
    color: '#FFF',
    background: '#042E6B',
  },
  actionIcon: {
    marginRight: 10,
    fontSize: 18,
  },
});
