import { Col, Empty, Row } from 'antd';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { InitialCard, Loader } from '@components';
import { reportsHttp, SourceReportData } from '@network';
import cls from './CategoriesGraph.module.css';
import { clsx } from 'clsx';

const colors = ['#B44436', '#204BCE', '#1E2C5C', '#225236', '#FD8A32',
  '#3E8B5B', '#400E14', '#B54446', '#214BDE', '#1F2C6C', '#235246', '#FE8A42', '#3F8B6B', '#410E14',
  '#B64456', '#224BEE', '#1A2C7C', '#245256', '#FF8A52', '#3A8B7B', '#420E24',
  '#B61156', '#2211EE', '#1A117C', '#241156', '#FF6652', '#3A667B', '#421124',
  '#B64456', '#2244EE', '#1A447C', '#244456', '#FF4452', '#3A447B', '#424424',
  '#B61E56', '#221EEE', '#1A1E7C', '#241E56', '#FF6E52', '#3A6E7B', '#421E24',
  '#B64E56', '#224EEE', '#1A4E7C', '#244E56', '#FF4E52', '#3A4E7B', '#424E24',
  '#B67E56', '#227EEE', '#1A7E7C', '#247E56', '#FF7E52', '#3A7E7B', '#427E24',
  '#B67E56', '#227EEE', '#1A7E7C', '#247E56', '#FF7E52', '#3A7E7B', '#427E24'];

interface Props {
  src: string;
  title: string;
  setLoader: (num: number, value: boolean) => void;
  loadKey: number;
}

interface NumbersProps {
  key: string;
  value: number;
}

export const CategoriesGraph = ({ src, title, setLoader, loadKey }: Props) => {
  const [numbers, setNumbers] = useState<NumbersProps[]>([]);
  const [loading, setLoading] = useState(true);

  const loadNumbers = async () => {
    setLoader(loadKey, true);
    setLoading(true);
    try {
      const { sources }: SourceReportData = await reportsHttp[src]();
      const result: NumbersProps[] = [];
      Object.keys(sources).forEach((key: string) => {
        if (key !== 'TOTAL') {
          result.push({ key: key.replaceAll('_', ' '), value: sources[key] });
        }
      });
      setNumbers(result);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(loadKey, false);
      setLoading(false);
    }
  };

  useEffect(() => void loadNumbers(), []);

  useLayoutEffect(() => {
    if (!numbers?.length || loading) {
      return;
    }
    const root = am5.Root.new(src);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
        innerRadius: 80,
      }),
    );

    const amColors = colors.map(color => am5.color(color));
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'key',
        radius: 40,
        colors: am5.ColorSet.new(root, {
          colors: amColors,
        }),
      }),
    );

    series.slices.template.setAll({
      strokeWidth: 2,
      stroke: am5.color(0xffffff),
      cornerRadius: 4,
    });

    series.slices.template.states.create('hover', {
      shadowOpacity: 1,
      shadowBlur: 10,
    });

    series.ticks.template.setAll({
      strokeOpacity: 0.4,
      strokeDasharray: [2, 2],
    });

    series.states.create('hidden', {
      endAngle: -90,
    });

    series.data.setAll(numbers);
    series.labels.template.set('visible', false);
    series.ticks.template.set('visible', false);
    series.slices.template.setAll({
      tooltipText: '[bold]Channel:[/] {key}\n[bold]Value:[/] {value}',
    });

    // center text
    chart.seriesContainer.children.push(am5.Label.new(root, {
      textAlign: 'center',
      centerY: am5.p50,
      centerX: am5.p50,
      text: numbers && numbers.length > 0 ? `[fontSize:15px]Channels[/]\n[bold fontSize:28px]${numbers.length}[/]` : undefined,
    }));

    // show/dispose
    series.appear(1000, 100);

    return () => void root.dispose();
  }, [numbers]);

  return (
    <InitialCard left={title} extraPadding boxClass={cls.box} wrapperClass={cls.wrap} mt={22}>
      {loading ? <Loader className={cls.loader} center /> : !numbers?.length ? <Empty /> : <Row>
        <Col sm={24} md={9} xl={15} xxl={12}>
          <div className={clsx(cls.labels, numbers.length < 7 ? cls.oneRow : cls.multiRows)}>
            {numbers.map((row, idx) => (
              <div key={idx} className={cls.label}>
                <div
                  className={cls.labelColor}
                  style={{ backgroundColor: colors[idx] || colors[0] }}
                />
                <div className={cls.labelText}>{row.key}</div>
                <div className={cls.labelValue}>{row.value}</div>
              </div>
            ))}
          </div>
        </Col>
        <Col sm={24} md={15} xl={9} xxl={12}>
          <div className={cls.chart}>
            <div id={src} style={{ height: 300 }}/>
          </div>
        </Col>
      </Row>}
    </InitialCard>
  );
};
