import { useController } from 'react-hook-form';
import { useEffect } from 'react';
import { clsx } from 'clsx';

import { useQuery } from '@hooks';
import { FormRow, ReferenceInput, SelectInput, TextInput } from '@components';
import cls from '@pages/manager/ManagerCreate/ManagerCreate.module.css';
import _cls from './AssociateCompany.module.css';
import { dealerHttp } from '@network';

export const AssociateCompany = ({ autoUpdate }: {autoUpdate: boolean}) => {
  const query = useQuery();
  const queryDealerId = query.get('dealerId');

  const {
    field: { value: dealerId, onChange: setDealerId },
  } = useController({ name: 'buyerId' });
  const {
    field: { value: associateId },
  } = useController({ name: 'associateId' });
  const {
    field: { onChange: setName },
  } = useController({ name: 'name' });
  const {
    field: { onChange: setPhone },
  } = useController({ name: 'phone' });
  const {
    field: { onChange: setEmail },
  } = useController({ name: 'email' });

  useEffect(() => {
    autoUpdate && associateId && (async () => {
      const dealer = await dealerHttp.getById(associateId);
      if (dealer) {
        setName(dealer.name);
        setPhone(dealer.phone || '');
        setEmail(dealer.email || '');
      }
    })();
  }, [autoUpdate, associateId]);

  useEffect(() => {
    if (queryDealerId) {
      setTimeout(() => void setDealerId(+queryDealerId), 0);
      setTimeout(() => void setDealerId(+queryDealerId), 1000);
      setTimeout(() => void setDealerId(+queryDealerId), 2000);
      setTimeout(() => void setDealerId(+queryDealerId), 5000);
    }
  }, [queryDealerId]);

  const mainColGrid = {
    xxl: { title: 7, children: 17 },
    sm: { title: 10, children: 14 },
    xs: { title: 24, children: 24 },
  };
  const showBuyer = dealerId || queryDealerId;

  return (
    <>
      <FormRow
        title="Company name:"
        width={mainColGrid}
        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
        colTitleClassName={clsx(cls.formLabelCol, _cls.title)}
        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
      >
        {showBuyer ? (
          <ReferenceInput
            name="buyerId"
            source="buyerId"
            reference="dealer"
            isRequired
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}
            disabled
          >
            <SelectInput optionText="name" required label={false} disabled />
          </ReferenceInput>
        ) : (
          <TextInput source="nocompany" label={false} disabled className={_cls.nocompany}
            defaultValue="No Company" value="No Company" />
        )}
      </FormRow>
      <FormRow
        title={`Associate name${queryDealerId ? '' : '*'}:`}
        width={mainColGrid}
        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
        colTitleClassName={cls.formLabelCol}
        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
      >
        <ReferenceInput
          name={'associateId'}
          source={'associateId'}
          reference={'dealer-associate'}
          isRequired={!queryDealerId}
          perPage={1000}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name" required label={false} />
        </ReferenceInput>
      </FormRow>
    </>
  );
};
