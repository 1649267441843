import { Link } from 'react-admin';
import IconButton from '@mui/material/IconButton';

import { Guarantor } from '@types';
import { dateFormatter } from '@utils';
import { ConfirmDelete } from '@components/ConfirmDelete';
import cls from './guarantor-card.module.css';

interface GuarantorCardProps {
  guarantor: Guarantor;
  onDelete: (guarantorId: number) => Promise<void>;
  linkTo: string;
  isDisabled?: boolean;
}

interface GuarantorCardContentProps {
  guarantor: Guarantor;
  onCancel: (e: any) => void;
  removeGuarantor: (e: any) => void;
}

const GuarantorCardContent = ({
  guarantor, onCancel, removeGuarantor,
}: GuarantorCardContentProps) => (
  <div className={cls.card}>
    <div className={cls.title}>{guarantor.type || 'Guarantor'}</div>
    <div className={cls.name}>{guarantor.fullName}</div>
    {guarantor.relation && (
      <div className={cls.info}>Relation with creditor: {guarantor.relation}</div>
    )}
    {guarantor.profession && (<div className={cls.info}>Profession: {guarantor.profession}</div>)}
    {guarantor.workSince && (<div className={cls.info}>
        Work in this since: {dateFormatter.toDate(guarantor.workSince)}
    </div>)}

    <IconButton className={cls.deleteBtn}>
      <ConfirmDelete
        title="Are you sure?"
        cb={removeGuarantor}
        onCancel={onCancel}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M9 0C4.03021 0 0 4.03021 0 9C0 13.9698 4.03021 18 9 18C13.9698 18 18 13.9698 18 9C17.992 4.03021 13.9658 0.00399822 9 0Z"
            fill="#002A77" />
          <path
            d="M12.3863 5.60962C12.2303 5.45369 11.9785 5.45369 11.8225 5.60962L8.99978 8.43237L6.17704 5.60962C6.0211 5.45369 5.76922 5.45369 5.61329 5.60962C5.45736 5.76555 5.45736 6.01744 5.61329 6.17337L8.43603 8.99612L5.61329 11.8189C5.45736 11.9748 5.45736 12.2267 5.61329 12.3826C5.76922 12.5385 6.0211 12.5385 6.17704 12.3826L8.99978 9.55987L11.8225 12.3826C11.9785 12.5385 12.2303 12.5385 12.3863 12.3826C12.5422 12.2267 12.5422 11.9748 12.3863 11.8189L9.56353 8.99612L12.3863 6.17337C12.5422 6.01744 12.5422 5.76555 12.3863 5.60962Z"
            fill="white" />
        </svg>
      </ConfirmDelete>
    </IconButton>
  </div>
);

export const GuarantorCard = ({
  guarantor, onDelete, linkTo, isDisabled,
}: GuarantorCardProps) => {
  const removeGuarantor = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(guarantor.id);
  };

  const onCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const scrollToTop = () => {
    if (window !== undefined) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      {isDisabled ? (
        <div className={cls.wrap}>
          <GuarantorCardContent
            guarantor={guarantor}
            onCancel={onCancel}
            removeGuarantor={removeGuarantor}
          />
        </div>
      ) : (
        <Link to={linkTo} onClick={scrollToTop} className={cls.wrap}>
          <GuarantorCardContent
            guarantor={guarantor}
            onCancel={onCancel}
            removeGuarantor={removeGuarantor}
          />
        </Link>
      )}
    </>

  );
};
