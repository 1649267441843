import { useEffect, useState } from 'react';

import { loanContractHttp, LoanContractOptions } from '@network';

export const useLoanContractOptions = () => {
  const [options, setOptions] = useState<LoanContractOptions>();

  useEffect(() => {
    (async () => {
      const nextOptions = await loanContractHttp.getOptions();
      setOptions(nextOptions);
    })();
  }, []);

  return options;
};
