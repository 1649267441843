import { StatusFieldOptions } from '@components/StatusField';

export interface FeedbackStatusOptions {
  id: 'opened' | 'processing' | 'closed';
  name: 'Opened' | 'Processing' | 'Closed';
}

export const feedbackStatusOptions: FeedbackStatusOptions[] = [
  { id: 'opened', name: 'Opened' },
  { id: 'processing', name: 'Processing' },
  { id: 'closed', name: 'Closed' },
];

export const feedbackStatusFieldOptions: StatusFieldOptions[] = [
  { id: 'opened', name: 'Opened', type: 'error' },
  { id: 'processing', name: 'Processing', type: 'warning' },
  { id: 'closed', name: 'Closed', type: 'success' },
];
