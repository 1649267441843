import { clsx } from 'clsx';
import { Upload } from 'antd';
import React, { useState } from 'react';
import { RcFile } from 'antd/lib/upload';
import { FunctionField, FunctionFieldProps } from 'react-admin';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { ImageZoom, ImageWithAuth, PdfIcon } from '@components';
import { Delivery, DeliveryDocument, DeliveryDocumentType } from '@types';
import { deliveryHttp, s3http } from '@network';
import { showAxiosError, beforeUpload } from '@utils';
import { ConfirmDelete } from '@components/ConfirmDelete';
import cls from './delivery-documents.module.css';

interface ExtraProps {
  type: DeliveryDocumentType;
  editable: boolean;
  downloadIcon?: JSX.Element;
}

export const DeliveryDocuments = (props: Partial<FunctionFieldProps> & ExtraProps) => {
  const { editable, type, downloadIcon, ...restProps } = props;

  return (
    <FunctionField
      sortable={false}
      {...restProps}
      render={(record: any) => (
        <Widget
          delivery={record}
          type={type}
          editable={editable}
          downloadIcon={downloadIcon}
        />
      )}
    />
  );
};

interface WidgetProps {
  delivery: Delivery;
  type: DeliveryDocumentType;
  editable: boolean;
  downloadIcon?: JSX.Element;
}

const Widget = ({ delivery, type, editable, downloadIcon }: WidgetProps) => {
  const [documents, setDocuments] = useState<DeliveryDocument[]>(
    (delivery.documents || []).filter(doc => doc.type === type),
  );
  const [loading, setLoading] = useState(false);

  const onUpload = async (rcFile: RcFile) => {
    setLoading(true);
    try {
      const { id: s3id, adminUrl } = await s3http.uploadFile(rcFile as File);
      const s3Ids = documents.map(document => document.fileId);
      const nextDocuments = await deliveryHttp.saveDocuments({
        deliveryId: delivery.id,
        fileIds: [...s3Ids, s3id],
        type,
      });
      setDocuments(nextDocuments);

      return adminUrl;
    } catch (err: any) {
      showAxiosError(err);

      return '';
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (s3FileId: number) => {
    const s3Ids = documents.map(doc => doc.fileId).filter(id => id !== s3FileId);
    try {
      const nextDocuments = await deliveryHttp.saveDocuments({
        deliveryId: delivery.id,
        fileIds: s3Ids,
        type,
      });
      setDocuments(nextDocuments);
    } catch (err: any) {
      showAxiosError(err);
    }
  };

  return (
    <div className={clsx(cls.files, 'dd-widget')}>
      {documents.map((file, i) => {
        const ext = file.isPdf ? 'pdf' : 'jpg';
        let downloadFilename = `Delivery#${delivery.id} ${type}`;
        if (documents.length > 1) {
          downloadFilename = `${downloadFilename} #${i + 1}`;
        }
        downloadFilename = `${downloadFilename}.${ext}`;

        return (
          <div className={clsx(cls.fileBox, 'dd-fileBox')} key={file.id}>
            <div className={clsx(cls.file, 'dd-file')}>
              {file.isPdf ? (
                <div className={clsx(cls.pdfBox, 'dd-pdfBox')} title="download">
                  <PdfIcon
                    url={file.adminUrl}
                    className={cls.pdfIcon}
                    downloadFilename={downloadFilename}
                    downloadIcon={downloadIcon}
                  />
                </div>
              ) : (
                <ImageZoom>
                  <ImageWithAuth
                    url={file.adminUrl}
                    className={clsx(cls.fileImg, 'dd-fileImg')}
                    width={80}
                    height={80}
                    downloadFilename={downloadFilename}
                  />
                </ImageZoom>
              )}
              {editable ? (
                <ConfirmDelete title="Are you sure?" cb={() => onDelete(file.fileId)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    className={cls.fileCloseIcon}
                  >
                    <path d="M7 0C3.13461 0 0 3.13461 0 7C0 10.8654 3.13461 14 7 14C10.8654 14 14 10.8654 14 7C13.9938 3.13461 10.8623 0.00310973 7 0Z" fill="#002A77"/>
                    <path d="M9.63423 4.36284C9.51295 4.24156 9.31704 4.24156 9.19576 4.36284L7.00029 6.55831L4.80482 4.36284C4.68354 4.24156 4.48763 4.24156 4.36635 4.36284C4.24507 4.48412 4.24507 4.68003 4.36635 4.80131L6.56182 6.99678L4.36635 9.19225C4.24507 9.31353 4.24507 9.50944 4.36635 9.63072C4.48763 9.752 4.68354 9.752 4.80482 9.63072L7.00029 7.43525L9.19576 9.63072C9.31704 9.752 9.51295 9.752 9.63423 9.63072C9.75551 9.50944 9.75551 9.31353 9.63423 9.19225L7.43876 6.99678L9.63423 4.80131C9.75551 4.68003 9.75551 4.48412 9.63423 4.36284Z" fill="white"/>
                  </svg>
                </ConfirmDelete>
              ) : null}
            </div>
          </div>
        );
      })}

      {editable ? (
        <div className={clsx(cls.file, 'dd-file')}>
          <Upload
            listType="picture-card"
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={async ({ onSuccess }) => setTimeout(() => {
              onSuccess && onSuccess('ok');
            }, 0)}
            action={onUpload}
            onPreview={() => {}}
          >
            <div className={clsx('dd-uploadText-box')}>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className={clsx(cls.uploadText, 'dd-uploadText')}>Upload</div>
            </div>
          </Upload>
        </div>
      ) : null}
    </div>
  );
};
