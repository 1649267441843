class PhoneFormatter {
  format(str: string | null | undefined): string {
    if (typeof str !== 'string') {
      return '';
    }

    const cut = str.replace('+52', '');
    const cleaned = ('' + cut).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    return match ? '+52 (' + match[1] + ') ' + match[2] + '-' + match[3] : str;
  }
}

export const phoneFormatter = new PhoneFormatter();
