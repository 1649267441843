import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import { App } from './App';

if (process.env.NODE_ENV === 'production' && process.env.CYPRESS_RUN !== 'true') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN || process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api\.verqor\.com/,
      /^https:\/\/api-dev\.verqor\.com/,
    ],
  });
}

const rootDom = document.getElementById('root');
rootDom && ReactDOM.createRoot(rootDom).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
