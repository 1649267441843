import { Button } from 'antd';

import cls from './FinanceActivationAdd.module.css';

interface Props {
    add: () => void;
}

export const FinanceActivationAdd = ({ add }: Props) => {
  return (
    <Button className={cls.button} onClick={add}>
      <span className={cls.icon}>+</span> Add
    </Button>
  );
};
