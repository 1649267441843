import { EditProps, Edit, SimpleForm } from 'react-admin';
import {
  Row,
  Col,
  Container,
  ImageInput,
  TextInput,
  BooleanInput,
  NumberInput,
  EditorInput,
  Forbidden,
} from '@components';
import { useEditProps, useRbacEdit, useRbacView } from '@hooks';

const Title = ({ record }: {record?: any}) =>
  record ? (<span>Bank: {record.nameMx}</span>) : null;

export const JobEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();

  if (!rbacView) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <SimpleForm toolbar={rbacEdit ? undefined : false}>
        <Container max>
          <Row>
            <Col><TextInput source="title" required /></Col>
            <Col><TextInput source="location" /></Col>
            <Col><TextInput source="employment" /></Col>
            <Col><TextInput source="department" /></Col>
          </Row>
          <Row>
            <Col flex={1}>
              <EditorInput source="whatWeOffer" label="What we offer" />
            </Col>
            <Col flex={1}>
              <EditorInput source="aboutUs" label="About us" />
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <EditorInput source="lookingFor" />
            </Col>
            <Col flex={1}>
              <EditorInput source="responsibleFor" />
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <EditorInput source="requiredSkills" />
            </Col>
            <Col flex={1}>
              <EditorInput source="desiredSkills" />
            </Col>
          </Row>
          <Row>
            <Col><BooleanInput source="isEnabled" defaultValue={true} /></Col>
            <Col><BooleanInput source="isRemote" defaultValue={false} /></Col>
            <Col flex={2}><ImageInput source="image" /></Col>
          </Row>
        </Container>
      </SimpleForm>
    </Edit>
  );
};
