import { FunctionFieldProps, FunctionField } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { resolvePath } from './resolve-path';
import { clsx } from 'clsx';

export const EmailField = (
  props: Partial<FunctionFieldProps> & { isText?: boolean, wrap?: boolean },
) => {
  const fieldProps = { ...props };
  delete fieldProps.isText;
  delete fieldProps.wrap;

  return (
    <FunctionField
      {...fieldProps}
      render={(record: any) => <EmailLink
        email={resolvePath(record, props.source || '')}
        isText={props.isText}
        wrap={props.wrap}
      />}
    />
  );
};

// EMAIL
const EmailLink = (
  { email, isText, wrap }: {email?: string | null; isText?: boolean, wrap?: boolean},
) => {
  const classes = useStyles();
  if (!email) {
    return null;
  }

  const href = `mailto:${email}`;

  return isText ? (
    <span className={wrap ? classes.wrap : ''}>{email}</span>
  ) : (
    <a
      href={href}
      className={clsx(classes.link, wrap ? classes.wrap : '')}
      onClick={e => e.stopPropagation()}
      target="_blank"
      rel="noreferrer"
    >
      {email}
    </a>
  );
};

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: '#002A77',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  wrap: {
    overflowWrap: 'anywhere',
  },
});
