import { makeStyles } from '@mui/styles';

import { CreditRisk } from '@types';
import { InitialCard, TimerWidget } from '@components';

export const RiskTimeTracker = ({ risk }: {risk: CreditRisk}) => {
  const cls = useStyles();

  return (
    <InitialCard center="Analysis time tracker" contentClass={cls.content} wrapperClass={cls.wrapper}>
      <TimerWidget
        title="From “Send to Analysis” to Analysis completed"
        url={`/credit-risk/${risk.id}/timer/analysis-to-completed`}
      />
    </InitialCard>
  );
};

const useStyles = makeStyles({
  wrapper: {
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  content: {
    display: 'flex',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  info: {
    borderRadius: 7,
    backgroundColor: '#042E6B',
    marginTop: -44,
    height: 87,
    width: '100%',
    padding: 25,
    paddingRight: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoText: {
    color: '#FFF',
  },
});
