import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

interface Props {
  title: string | React.ReactNode;
  right?: string | React.ReactNode;
  full?: boolean;
  titleClass?: string;
  contentClass?: string;
  widthTitle?: number;
  widthContent?: number;
  widthRight?: number;
  compact?: boolean;
  wrapClass?: string;
}

export const InitialLine = ({
  children, title, right, full, titleClass, contentClass,
  widthTitle, widthContent, widthRight, compact, wrapClass,
}: PropsWithChildren<Props>) => {
  const cls = useStyles();

  return (
    <Row className={clsx(cls.row, compact && cls.rowCompact, wrapClass)}>
      <Col sm={widthTitle || (full ? 8 : 6)} className={clsx(cls.title, titleClass)}>
        {title}
      </Col>
      <Col sm={widthContent ? widthContent : widthTitle ? (24 - widthTitle) : (full ? 16 : 10)}
        className={clsx(cls.content, contentClass)}>
        {children}
      </Col>
      <Col sm={widthRight || (full ? 0 : 8)} className={cls.right}>
        {right}
      </Col>
    </Row>
  );
};

const useStyles = makeStyles({
  box: {
    marginTop: 15,
    fontFamily: 'Outfit, sans-serif',
    color: '#272727',
  },
  row: {
    marginTop: 15,
    fontSize: 15,
    lineHeight: 1.1,
    fontWeight: 400,
    minHeight: 24,
    alignItems: 'center',
  },
  rowCompact: {
    marginTop: 0,
  },
  title: {
    fontSize: 15,
    fontWeight: 700,
  },
  content: {
    paddingLeft: 5,
    color: '#000',
    fontSize: 16,
    fontWeight: 500,
  },
  right: {
    paddingLeft: 20,
  },
});
