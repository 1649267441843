import { Http } from './http';

class ManagerHttp extends Http {
  prefix = 'manager';

  async loginAdmin(userId: number): Promise<string> {
    const res = await this.get<string>(`/${userId}/login-admin`);

    return res.data;
  }
}

export const managerHttp = new ManagerHttp();
