import { Http } from './http';
import { CreditCall } from '@types';

class CreditCallHttp extends Http {
  prefix = 'credit-call';

  async getCallsByCredit(creditId: number): Promise<CreditCall[]> {
    const res = await this.get<CreditCall[]>(`/by-credit/${creditId}`);

    return res.data;
  }

  async removeCalls(callIds: number[]): Promise<number> {
    await this.post<CreditCall>('/remove', { callIds });

    return callIds.length;
  }
}

export const creditCallHttp = new CreditCallHttp();
