import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

import { InfoTooltip } from '@components/InfoTooltip';

interface Props {
  tooltip?: NonNullable<React.ReactNode>;
  className?: string;
}

export const InitialLabel = ({ children, tooltip, className }: PropsWithChildren<Props>) => {
  const cls = useStyles();

  return (
    <div className={clsx(cls.upperLabel, className)}>
      {tooltip && <InfoTooltip title={tooltip} inline />}
      {children}
    </div>
  );
};

const useStyles = makeStyles({
  upperLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#272727',
    fontFamily: 'Outfit, sans-serif',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1,
    minHeight: 46,
  },
});
