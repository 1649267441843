import cls from './TicketView.module.css';
import { SupportTicket } from '@types';
import { dateFormatter } from '@utils';
import React from 'react';
import { Container, InitialCard, RawHtml } from '@components';
import { Col, Row } from 'antd';
import { clsx } from 'clsx';
import { SupportFiles } from '@pages/support/components';

interface Props {
  record: SupportTicket;
}

export const TicketView = ({ record }: Props) => {
  return (
    <Container max>
      <Row gutter={[24, 24]} className={cls.topRow}>
        <Col xs={24}>
          <InitialCard
            extraPadding
            left={`Issue #ID: ${record.id}`}
            right={<span className={cls.subTitle}>
              {record.admin?.name ? `Created by ${record.admin.name} ` : ''}
              {dateFormatter.toDateSlash(record.createdAt || '') + ' ' + dateFormatter.toTime(record.createdAt || '')}
            </span>}
          >
            <Row gutter={[24, 24]} className={cls.rowTopIn}>
              <Col xs={24} sm={24} lg={12}>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={24} md={12} lg={24} xl={12}>
                    <Row gutter={[24, 24]}>
                      <Col xs={8} sm={6} className={cls.label}>Status:</Col>
                      <Col xs={16} sm={18} className={cls.value}>{record.statusEn}</Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={24} xl={12}>
                    <Row gutter={[24, 24]}>
                      <Col xs={8} sm={6} className={cls.label}>Platform:</Col>
                      <Col xs={16} sm={18} className={cls.value}>{record.platform}</Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24}>
                    <Row>
                      <Col xs={24} sm={24} className={cls.label}>Issue:</Col>
                      <Col xs={24} sm={24} className={cls.value}>{record.issue}</Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24}>
                    <Row>
                      <Col xs={24} sm={24} className={cls.label}>Links:</Col>
                      <Col xs={24} sm={24} className={cls.value}>{record.links.length
                        ? record.links.map((link: string, idx: number) => (
                          <span key={idx}>
                            <a
                              href={link.includes('https://') ? link : `https://${link}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={cls.link}
                            >{link.replace('https://', '').replace('http://', '').replace('www.', '')}</a>
                            {idx + 1 !== record.links.length ? ', ' : ''}
                          </span>
                        )) : <></>}</Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                {record.documents?.length ? <SupportFiles
                  files={record.documents}
                  setFiles={() => {}}
                  setDocuments={() => {}}
                  className={clsx(cls.files, cls.issueFiles)}
                  previewOnly
                /> : <span>No files</span>}
              </Col>
            </Row>
          </InitialCard>
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24}>
          <InitialCard
            extraPadding
            left="Answer"
          >
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} lg={12}>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={24}>
                    <Row>
                      <Col xs={24} sm={24} className={cls.value}>
                        <RawHtml>{record.answer}</RawHtml>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                {record.answerDocuments?.length ? <SupportFiles
                  files={record.answerDocuments}
                  setFiles={() => {}}
                  setDocuments={() => {}}
                  className={clsx(cls.files, cls.issueFiles)}
                  previewOnly
                /> : <span>No files</span>}
              </Col>
            </Row>
          </InitialCard>
        </Col>
      </Row>
    </Container>
  );
};
