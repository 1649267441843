import { Http } from './http';

class CommentHttp extends Http {

  async moderateStatus(record: any) {
    const res = await this.put(`/product_comment/${record.id}`, record);

    return res.data;
  }
}

export const commentHttp = new CommentHttp();
