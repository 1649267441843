import React, { useEffect, useLayoutEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5percent from '@amcharts/amcharts5/percent';
import * as am5 from '@amcharts/amcharts5';

import { GenderReportData, reportsHttp } from '@network';
import { InitialCard } from '@components';
import { Col, Row } from 'antd';

interface Props {
  title: string;
  range: string;
  dataSource: 'usersGender' | 'clientsGender';
  setLoader: (num: number, value: boolean) => void;
}

export const RegistrationsGenderReport = ({ title, range, dataSource, setLoader }: Props) => {
  const [numbers, setNumbers] = useState<GenderReportData>();
  const cls = useStyles();
  const chartId = `${dataSource}-report`;
  const gender = (numbers && numbers[dataSource]) || {};

  const dataValueLabel: {value: number, label: string, color: string}[] = [
    { value: gender['Mujer'] || 0, label: 'Female', color: '#B44436' },
    { value: gender['Hombre'] || 0, label: 'Male', color: '#042e6b' },
    { value: gender['NULL'] || 0, label: 'Non specified', color: '#FD8A32' },
    { value: gender['Prefiero no decir'] || 0, label: 'I prefer not to say', color: '#74706b' },
  ];

  const loadNumbers = async () => {
    setLoader(dataSource === 'clientsGender' ? 17 : 18, true);
    try {
      const nextNumbers = await reportsHttp.genderReport({ range });
      setNumbers(nextNumbers);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(dataSource === 'clientsGender' ? 17 : 18, false);
    }
  };

  useEffect(() => void loadNumbers(), [range]);

  useLayoutEffect(() => {
    if (!numbers) {
      return;
    }
    const hasRecord = dataValueLabel.find(data => data.value > 0);

    const root = am5.Root.new(chartId);
    root.setThemes([am5themes_Animated.new(root)]);
    const chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      innerRadius: 80,
    }));
    const series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: 'value',
      legendValueText: '',
      categoryField: 'label',
      radius: 40,
      colors: am5.ColorSet.new(root, {
        colors: [
          am5.color(0xB44436),
          am5.color(0x042e6b),
          am5.color(0xFD8A32),
          am5.color(0x74706b),
        ],
      }),
      alignLabels: false,
    }));
    series.ticks.template.set('forceHidden', true);
    series.labels.template.set('forceHidden', true);
    hasRecord && series.data.setAll(dataValueLabel);

    series.get('colors')?.set('colors', [
      am5.color(0xdf8a32),
      am5.color(0x042e6b),
      am5.color(0xf65434),
      am5.color(0x74706b),
    ]);

    const total = gender['TOTAL'] || 0;
    chart.seriesContainer.children.push(am5.Label.new(root, {
      textAlign: 'center',
      centerY: 35,
      centerX: am5.p50,
      text: `[fontSize:15px]Total user[/]:\n[bold fontSize:28px]${total}[/]`,
    }));

    series.appear(1000, 100);

    return () => void root.dispose();
  }, [chartId, numbers]);

  if (!numbers) {
    return null;
  }

  return (
    <InitialCard left={title} extraPadding fullHeight boxClass={cls.box}>
      <Row>
        <Col sm={24} md={9}>
          <div className={cls.labels}>
            {dataValueLabel.map(row => (
              <div key={row.label} className={cls.label}>
                <div className={cls.labelColor} style={{ backgroundColor: row.color }} />
                <div className={cls.labelText}>{row.label}</div>
              </div>
            ))}
          </div>
        </Col>
        <Col sm={24} md={15}>
          <div className={cls.chart}>
            <div id={chartId} style={{ height: 300 }} />
          </div>
        </Col>
      </Row>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    paddingBottom: '0 !important',
  },
  labels: {
    marginTop: 30,
    padding: 0,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
  },
  labelColor: {
    width: 20,
    height: 20,
    borderRadius: 5,
  },
  labelText: {
    marginLeft: 10,
  },
  chart: {
    marginTop: -40,
    marginRight: -30,
  },
});
